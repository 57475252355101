import { ChainId } from '@pancakeswap/chains'
import { ManipulateType } from 'dayjs'

export const v3InfoPath = `info/v3`

export const POOL_HIDE: { [key: string]: string[] } = {
  // TODO: update to our own
  [ChainId.ETHEREUM]: [
    '0x86d257cDB7bC9c0dF10E84C8709697F92770b335',
    '0xf8DBD52488978a79DfE6ffBd81A01Fc5948bF9eE',
    '0x8Fe8D9bb8Eeba3Ed688069c3D6b556C9ca258248',
    '0xA850478aDAACe4c08fc61DE44d8CF3b64f359bEc',
    '0x277667eB3e34f134adf870be9550E9f323d0dc24',
    '0x8c0411F2ad5470A66cb2E9C64536CFb8dcD54d51',
    '0x055284A4CA6532ECc219Ac06b577D540C686669d',
    '0xB078BF211E330b5F95B7114AE845188CC36B795D',
    '0x7778797342652bd27B365962Ffc7f6eCE356EB57',
    '0xe9825d867e3BeF05223bDA609fA8aB89AeF93797',
  ],
  [ChainId.BSC]: [
    '0x87196a3BCeC98116307bdc8B887c3074E8b5bc96',
    '0x4b2C510Fe0b6a50D117220cd6D66a9C714C83dFb',
    '0xf0b579380cA08e75441DC73c2Da9f27CE23725F2',
    '0x9829f916C617e1b269b410dfD84c7F22ad479417',
    '0x9829f916C617e1b269b410dfD84c7F22ad479417',
    '0xA0E4442DC4aDDba0A57be757fbb802b48BA8051C',
    '0xBAdBEdaFe2cA7318a25B665bfA307e91A3EEb88d',
    '0xa7619D726F619062d2d2BCAdbb2ee1FB1952d6d7',
    '0x5FB5EB2c8Ecbf712115007990C70B79F6B256f9b',
  ],
}

export const TOKEN_HIDE: { [key: string]: string[] } = {
  [ChainId.ETHEREUM]: [
    '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
    '0x7dFB72A2AAd08C937706f21421B15bFC34Cba9ca',
    '0x12B32f10A499Bf40Db334Efe04226Cca00Bf2D9B',
    '0x160dE4468586B6B2F8a92FEB0c260Fc6cFC743B1',
    '0xD84787a01B0cad89fBCa231E6960cC0f3f18df34',
    '0xdb19f2052D2B1aD46Ed98C66336A5dAADEB13005',
  ],
  [ChainId.BSC]: ['0xdb19f2052D2B1aD46Ed98C66336A5dAADEB13005', '0x57a63C32CC2aD6CE4FBE5423d548D12d0EEDdfc1'],
}

export const TimeWindow: {
  [key: string]: ManipulateType
} = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
}

export const ONE_HOUR_SECONDS = 3600
export const ONE_DAY_SECONDS = 86400
export const MAX_UINT128 = 2n ** 128n - 1n

export const SUBGRAPH_START_BLOCK = {
  [ChainId.BSC]: 26956207,
  [ChainId.ETHEREUM]: 16950686,
  [ChainId.POLYGON_ZKEVM]: 750149,
  [ChainId.ZKSYNC]: 8639214,
  [ChainId.ARBITRUM_ONE]: 101028949,
  [ChainId.LINEA]: 1444,
  [ChainId.BASE]: 18942139,
  [ChainId.OPBNB]: 1721753,
}

export const NODE_REAL_ADDRESS_LIMIT = 50

export const DURATION_INTERVAL = {
  day: ONE_HOUR_SECONDS,
  week: ONE_DAY_SECONDS,
  month: ONE_DAY_SECONDS,
  year: ONE_DAY_SECONDS,
}

export const ALL_STATS_REWARD_DATA = [
  {
    address: '0x897E1Ac1806814B49de9372A4DfEcE830f2aDB81',
    minters_reward: 40909.09,
    lp_providers_reward: 108802.31,
    swapers_reward: 34563.08845720927,
    holders_reward: 27361.39,
    hoaxs_reward: 0,
    total_rewards: 211635.88,
  },
  {
    address: '0xF4Cb73CfEE5a4C82c3919Bc98Ed1c3698a3bf5b6',
    minters_reward: 6075.61,
    lp_providers_reward: 38880.79,
    swapers_reward: 9374.314888293075,
    holders_reward: 0,
    hoaxs_reward: 44444.44,
    total_rewards: 98775.15,
  },
  {
    address: '0x50e9001695DD7C57c01DE75Cbcf150e90045c272',
    minters_reward: 31593.16,
    lp_providers_reward: 31447.8,
    swapers_reward: 1716.8434981726668,
    holders_reward: 21672.39,
    hoaxs_reward: 925.93,
    total_rewards: 87356.12,
  },
  {
    address: '0x919F69F0cc187a9B0b8c2AaFCF4968928D40cE90',
    minters_reward: 40909.09,
    lp_providers_reward: 0,
    swapers_reward: 264.93155385394243,
    holders_reward: 27361.39,
    hoaxs_reward: 0,
    total_rewards: 68535.41,
  },
  {
    address: '0x130fF493Df73fD6Ea77C268d32D4044533447c75',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 27090.48,
    hoaxs_reward: 925.93,
    total_rewards: 68520.46,
  },
  {
    address: '0xb6D867c8e3655Fb28954496258DE3A89c6878A6C',
    minters_reward: 40909.09,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 27361.39,
    hoaxs_reward: 0,
    total_rewards: 68270.48,
  },
  {
    address: '0x9970619bD2B7dA17e8EAc8d7C0eea4385d120ed7',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 292.2212297128985,
    holders_reward: 27090.48,
    hoaxs_reward: 0,
    total_rewards: 67886.75,
  },
  {
    address: '0xba879508Fd68628Bcb317d8cFA67bCafA5C92622',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 5.451813448583983,
    holders_reward: 27090.48,
    hoaxs_reward: 0,
    total_rewards: 67599.98,
  },
  {
    address: '0x885E9a0627cC5E60a8923Da4Eb9B4b8E66443C75',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 27090.48,
    hoaxs_reward: 0,
    total_rewards: 67594.53,
  },
  {
    address: '0x3FC37eA4365542685d3bCAf87fFc1948b0544CD0',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 27090.48,
    hoaxs_reward: 0,
    total_rewards: 67594.53,
  },
  {
    address: '0x030147C315224D1eC19aFEAd0C135B17112B488F',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 22485.1,
    hoaxs_reward: 0,
    total_rewards: 62989.15,
  },
  {
    address: '0x7dB28F89F5b932fCE111a134f5Bd9700f72ab24D',
    minters_reward: 35643.56,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 24110.53,
    hoaxs_reward: 0,
    total_rewards: 59754.09,
  },
  {
    address: '0x8a29A62a19185374D1f8BB95e6289FB6Da40814b',
    minters_reward: 33618.36,
    lp_providers_reward: 691.42,
    swapers_reward: 141.85778703240175,
    holders_reward: 23297.81,
    hoaxs_reward: 0,
    total_rewards: 57749.45,
  },
  {
    address: '0x70D50867373331acDa3513FD353ec4d394F2331C',
    minters_reward: 5265.53,
    lp_providers_reward: 38380.35,
    swapers_reward: 6545.341681869733,
    holders_reward: 3521.76,
    hoaxs_reward: 1851.85,
    total_rewards: 55564.83,
  },
  {
    address: '0xB3291b0A7044a3418C2535400fd201d48cfD409b',
    minters_reward: 33213.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 18692.43,
    hoaxs_reward: 0,
    total_rewards: 51905.75,
  },
  {
    address: '0x2589eCe7a6E4d35a3b21C8d60036026DD06247C5',
    minters_reward: 22277.23,
    lp_providers_reward: 13862.95,
    swapers_reward: 0,
    holders_reward: 14899.77,
    hoaxs_reward: 0,
    total_rewards: 51039.95,
  },
  {
    address: '0xC9Bc5D2D7FC83874339fCD084d79109aE1857147',
    minters_reward: 29567.96,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 19776.05,
    hoaxs_reward: 0,
    total_rewards: 49344.01,
  },
  {
    address: '0xceB54Ea393413D805A56349673856EadA5fb6089',
    minters_reward: 31188.12,
    lp_providers_reward: 10108.35,
    swapers_reward: 993.1161299297398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42289.59,
  },
  {
    address: '0xD3DADd55f478B05dB486Ca46d2568f4F3529D5C4',
    minters_reward: 24302.43,
    lp_providers_reward: 0,
    swapers_reward: 133.67016861404556,
    holders_reward: 16254.29,
    hoaxs_reward: 0,
    total_rewards: 40690.39,
  },
  {
    address: '0x49E8D12d5574F58897f6084105F60B661eD3Fb08',
    minters_reward: 24302.43,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 16254.29,
    hoaxs_reward: 0,
    total_rewards: 40556.72,
  },
  {
    address: '0xe3De35272dcC00A849190f121835e282567F2001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38838.98158575764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38838.98,
  },
  {
    address: '0xBdc3922244608bDb27f619616e6AdeD5Cc0C4e6A',
    minters_reward: 23087.31,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 15170.67,
    hoaxs_reward: 0,
    total_rewards: 38257.98,
  },
  {
    address: '0x46455f2FEc29f31f058A50CC70621FFAcCFd030A',
    minters_reward: 0,
    lp_providers_reward: 8168.61,
    swapers_reward: 26146.553606861256,
    holders_reward: 541.81,
    hoaxs_reward: 2777.78,
    total_rewards: 37634.75,
  },
  {
    address: '0xb38A90f14b24ae81Ec0B8f1373694f5B59811D8A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35305.48608030681,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35305.49,
  },
  {
    address: '0x0450875EBE4932979C42bAA43DaBd134FB67efA8',
    minters_reward: 0,
    lp_providers_reward: 34847.12,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34847.12,
  },
  {
    address: '0xCa5be144d8d6579a9a99cE34960241751e031D23',
    minters_reward: 20252.03,
    lp_providers_reward: 596.45,
    swapers_reward: 176.62103590262828,
    holders_reward: 13545.24,
    hoaxs_reward: 0,
    total_rewards: 34570.34,
  },
  {
    address: '0xB9dF77770111F477F66F0855cFD0c48CCbCf0d57',
    minters_reward: 12961.3,
    lp_providers_reward: 6221.96,
    swapers_reward: 6530.917099401918,
    holders_reward: 8668.95,
    hoaxs_reward: 0,
    total_rewards: 34383.13,
  },
  {
    address: '0xf1bF6179D17265c75875695720a110eaAAF2c637',
    minters_reward: 0,
    lp_providers_reward: 18826.15,
    swapers_reward: 15202.908861611983,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34029.06,
  },
  {
    address: '0x6b4b86183DDAB2d5aB9d13aB2334C01631F617B2',
    minters_reward: 20252.03,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 13545.24,
    hoaxs_reward: 0,
    total_rewards: 33797.27,
  },
  {
    address: '0xb7311802fE062000e15FB1E68B4CC66D311Db8F3',
    minters_reward: 22682.27,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 10836.19,
    hoaxs_reward: 0,
    total_rewards: 33518.46,
  },
  {
    address: '0x60445F1cD286A88631543cA1322bFe2070d50abC',
    minters_reward: 0,
    lp_providers_reward: 32621,
    swapers_reward: 872.5005205634601,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33493.5,
  },
  {
    address: '0x331349DD8b8D8ff68A68f85F6628742DF30bFF81',
    minters_reward: 20252.03,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 13003.43,
    hoaxs_reward: 0,
    total_rewards: 33255.46,
  },
  {
    address: '0xf8C427669273956Dc623C647346B39077a08C466',
    minters_reward: 14986.5,
    lp_providers_reward: 2678.84,
    swapers_reward: 1649.0619562025945,
    holders_reward: 10023.48,
    hoaxs_reward: 3703.7,
    total_rewards: 33041.58,
  },
  {
    address: '0x7c76f4DB70b7E2177de10DE3e2f668daDcd11108',
    minters_reward: 18226.82,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 12732.53,
    hoaxs_reward: 0,
    total_rewards: 30959.35,
  },
  {
    address: '0x2D72827B55805eb1b8990b52B9CEF9f9B84d5E94',
    minters_reward: 17011.7,
    lp_providers_reward: 0,
    swapers_reward: 189.0511588193924,
    holders_reward: 11919.81,
    hoaxs_reward: 0,
    total_rewards: 29120.56,
  },
  {
    address: '0xa27Eea97aFf8415Ec2d6Bfbb7dc7fB68Cbf5638f',
    minters_reward: 28352.84,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28352.84,
  },
  {
    address: '0xfBDC4e31bFdE31A99479ef642f152333903C2e4B',
    minters_reward: 0,
    lp_providers_reward: 17760.43,
    swapers_reward: 9556.592542258983,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 28242.95,
  },
  {
    address: '0x7E2f09781614b814914BcC1D43fD7eE9DA9F8452',
    minters_reward: 24302.43,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 25656.95,
  },
  {
    address: '0x677dCf56a2Ed4a75399CE0AF79A51a6039126d46',
    minters_reward: 24707.47,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24707.47,
  },
  {
    address: '0x63f324025dab9EC0093C29eD43F2c6b2b6B5092a',
    minters_reward: 17416.74,
    lp_providers_reward: 1767.04,
    swapers_reward: 1219.6093998335482,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 24466.96,
  },
  {
    address: '0xBaAbE72573778f34D201733B0735718be9076614',
    minters_reward: 24302.43,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24302.43,
  },
  {
    address: '0x15Eea6a7Aef6569B6a186672e68017ae0C1C4Ef9',
    minters_reward: 0,
    lp_providers_reward: 22299.29,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22299.29,
  },
  {
    address: '0xe9C9979628e8afb0103F1A6EBb1C130df45a6e63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21910.37304812962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21910.37,
  },
  {
    address: '0xBDaB095BfdfF77eaE0E44e3fa42b9DA7632BBD7b',
    minters_reward: 0,
    lp_providers_reward: 15593.59,
    swapers_reward: 6310.388510093504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21903.98,
  },
  {
    address: '0xEEced771C782fa648C2A6902fdf5fa572C49964d',
    minters_reward: 0,
    lp_providers_reward: 12320.44,
    swapers_reward: 8835.433676031886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21155.87,
  },
  {
    address: '0x83060Dc624b0E761A8f1dB59627f20256d65B1e8',
    minters_reward: 12556.26,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 8398.05,
    hoaxs_reward: 0,
    total_rewards: 20954.31,
  },
  {
    address: '0xc6C9c7928821415F29c192e2f2a5813956dfA82a',
    minters_reward: 405.04,
    lp_providers_reward: 17557.09,
    swapers_reward: 522.0028080325155,
    holders_reward: 541.81,
    hoaxs_reward: 1851.85,
    total_rewards: 20877.79,
  },
  {
    address: '0x198e040505559C459C2ea49ca4Fe7F8A21AF1546',
    minters_reward: 0,
    lp_providers_reward: 20648.37,
    swapers_reward: 227.3219447406803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20875.69,
  },
  {
    address: '0xFFD570aed9EA8317960e9dDab7D84Da3E249B86D',
    minters_reward: 12151.22,
    lp_providers_reward: 0,
    swapers_reward: 341.0832478481973,
    holders_reward: 8127.14,
    hoaxs_reward: 0,
    total_rewards: 20619.44,
  },
  {
    address: '0xa90Cca9F7C88ffcA698B51cc6dd21d4a9EBEE7D4',
    minters_reward: 0,
    lp_providers_reward: 17824.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 20602.71,
  },
  {
    address: '0x0917C74b5007a71e8181cCA933ea03BD26132B78',
    minters_reward: 12151.22,
    lp_providers_reward: 0,
    swapers_reward: 67.7222654000984,
    holders_reward: 8127.14,
    hoaxs_reward: 0,
    total_rewards: 20346.08,
  },
  {
    address: '0xdC2e4B1804974fa8F17ccF159d7cA2103E888137',
    minters_reward: 12151.22,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 8127.14,
    hoaxs_reward: 0,
    total_rewards: 20278.36,
  },
  {
    address: '0xf181B3E384f98277Bc75AD26978ceb723418c707',
    minters_reward: 12151.22,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 8127.14,
    hoaxs_reward: 0,
    total_rewards: 20278.36,
  },
  {
    address: '0xDc919CC69583000A168d6c493DD82d69b2cc95E2',
    minters_reward: 0,
    lp_providers_reward: 12414.72,
    swapers_reward: 7224.5975547468415,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 19910.22,
  },
  {
    address: '0x914b815e5BcbA5A9B268c37e837281Ea7FC61170',
    minters_reward: 0,
    lp_providers_reward: 17442.5,
    swapers_reward: 1543.9239215980301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18986.42,
  },
  {
    address: '0x74D440064A673Aa4Bd2D30a35B3e26eB9d271DD7',
    minters_reward: 0,
    lp_providers_reward: 16406.23,
    swapers_reward: 1004.9798657049026,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 18608.04,
  },
  {
    address: '0x596707dbbaB6c15510530d1BEc210B25A7EdB664',
    minters_reward: 10936.09,
    lp_providers_reward: 0,
    swapers_reward: 147.19223673672326,
    holders_reward: 7314.43,
    hoaxs_reward: 0,
    total_rewards: 18397.71,
  },
  {
    address: '0xf181844d76676D1FeC22e22568Ec33160B86e67A',
    minters_reward: 10936.09,
    lp_providers_reward: 0,
    swapers_reward: 87.48676392120647,
    holders_reward: 7314.43,
    hoaxs_reward: 0,
    total_rewards: 18338.01,
  },
  {
    address: '0xaC94e00848aAf38E64d2fe74D902C593cA02bBd4',
    minters_reward: 0,
    lp_providers_reward: 0.39,
    swapers_reward: 15601.995900891174,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 18311.44,
  },
  {
    address: '0xf6e2adaBc0f69a7f1161A60A48e52214F533b839',
    minters_reward: 0,
    lp_providers_reward: 3619.17,
    swapers_reward: 14059.73613673514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17678.91,
  },
  {
    address: '0xBFF81C71193d824e849D45446cD71628987Add95',
    minters_reward: 0,
    lp_providers_reward: 8254.39,
    swapers_reward: 9414.636566062392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17669.03,
  },
  {
    address: '0x10b1c5c58b055dA354e948fC909Dbb5353A014BB',
    minters_reward: 0,
    lp_providers_reward: 16428.33,
    swapers_reward: 1017.6071128267371,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17445.94,
  },
  {
    address: '0x639214d233654f9c86EDfC74D7AE38b0E05d0b3C',
    minters_reward: 0,
    lp_providers_reward: 17040.67,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 17311.57,
  },
  {
    address: '0x2E048257f28A7dfF64D71AE9Fa2E967A1c28CF8b',
    minters_reward: 10531.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6772.62,
    hoaxs_reward: 0,
    total_rewards: 17303.67,
  },
  {
    address: '0x38D2cea869ade70Fe6C3555f0b4bEa77aE7008eD',
    minters_reward: 0,
    lp_providers_reward: 17174.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17174.32,
  },
  {
    address: '0x33d43dD551F5Ea41aB95F37cdd019Be2A7A8c403',
    minters_reward: 0,
    lp_providers_reward: 7656.69,
    swapers_reward: 3887.0251969723663,
    holders_reward: 0,
    hoaxs_reward: 5555.56,
    total_rewards: 17099.28,
  },
  {
    address: '0x025d8388F1D15c9c668A4fa456F9561169a32EC9',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 950.4202674569201,
    holders_reward: 5689,
    hoaxs_reward: 1851.85,
    total_rewards: 16997.12,
  },
  {
    address: '0x98F8e25087C2Bbe7fD1BE9bA0a28D69c09db81f9',
    minters_reward: 10936.09,
    lp_providers_reward: 199.53,
    swapers_reward: 351.6954003968036,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 16905.42,
  },
  {
    address: '0xAdfB2939bB0aE00EE444A53Cb61Bc54107c68E4c',
    minters_reward: 10126.01,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6772.62,
    hoaxs_reward: 0,
    total_rewards: 16898.63,
  },
  {
    address: '0x423ab0e736794616bC391ae3AE60085c5f21AA24',
    minters_reward: 10126.01,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6772.62,
    hoaxs_reward: 0,
    total_rewards: 16898.63,
  },
  {
    address: '0x4eeEd052E258C30E5bc7BE292F071E4C4f39a514',
    minters_reward: 0,
    lp_providers_reward: 11919,
    swapers_reward: 1869.3856863606027,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 16768.34,
  },
  {
    address: '0xc61505D452f0b1B2b74f30eE8F7275e90140284D',
    minters_reward: 405.04,
    lp_providers_reward: 13922.6,
    swapers_reward: 2127.253977697697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16454.89,
  },
  {
    address: '0x831c8db621027e92b0a968861ab9E87f781365F0',
    minters_reward: 0,
    lp_providers_reward: 11171.31,
    swapers_reward: 5096.536495794387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16267.85,
  },
  {
    address: '0xD5a30E086B78d9e9414a3aeB9f6F401218787A86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 16254.29,
    hoaxs_reward: 0,
    total_rewards: 16254.29,
  },
  {
    address: '0xbd031abA22fd2310A63AaAe03c704dF985a0Da64',
    minters_reward: 0,
    lp_providers_reward: 8017.5,
    swapers_reward: 7943.831750856462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15961.33,
  },
  {
    address: '0xC6f75fA54B00690Af372330FF90f490A41BceC22',
    minters_reward: 0,
    lp_providers_reward: 9581.56,
    swapers_reward: 6376.600853336685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15958.16,
  },
  {
    address: '0x1e0e5134BcB606032F58EC0924873f8c321f6ecB',
    minters_reward: 0,
    lp_providers_reward: 15128.88,
    swapers_reward: 652.2088020781603,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15781.09,
  },
  {
    address: '0xFd6D049d010582d9e6b41B9648CD4F8277581e38',
    minters_reward: 0,
    lp_providers_reward: 15736.47,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15736.47,
  },
  {
    address: '0xF2C6C9Aa52095e52f769BcD589BF2432Ce8dD1b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 15712.48,
    hoaxs_reward: 0,
    total_rewards: 15712.48,
  },
  {
    address: '0x83d7b30aE520B46374a78b3e2A408feb37F032B6',
    minters_reward: 9315.93,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6230.81,
    hoaxs_reward: 0,
    total_rewards: 15546.74,
  },
  {
    address: '0xdd95dfD64F0814654A1E901f80e98B6CDA0701E9',
    minters_reward: 0,
    lp_providers_reward: 15396.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15396.15,
  },
  {
    address: '0x4336bC4884B1c1e3CB6F7e07b1A1dc5fB1C208aF',
    minters_reward: 0,
    lp_providers_reward: 14881.79,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14881.79,
  },
  {
    address: '0x22d36A792EB4cBF68862e27ee7B066922213732e',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 0.7380604161645512,
    holders_reward: 5147.19,
    hoaxs_reward: 925.93,
    total_rewards: 14579.71,
  },
  {
    address: '0xD6243B13AD17000B9c041dbBd15C5DB838Bd78F6',
    minters_reward: 0,
    lp_providers_reward: 8428.52,
    swapers_reward: 5860.241977164898,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 14559.66,
  },
  {
    address: '0x1f69CE9F2850025009AE506F72b2512fF8e8c545',
    minters_reward: 0,
    lp_providers_reward: 14507.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14507.92,
  },
  {
    address: '0x0AAf93A46845c7Afd2377d40FAE73f3c44b8fc7d',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 306.3984579254117,
    holders_reward: 5689,
    hoaxs_reward: 0,
    total_rewards: 14501.25,
  },
  {
    address: '0x1b29ee80ba7316F65d496CcbDF66245a8FEF363e',
    minters_reward: 4050.41,
    lp_providers_reward: 1587.83,
    swapers_reward: 1062.8781510676865,
    holders_reward: 4876.29,
    hoaxs_reward: 2777.78,
    total_rewards: 14355.19,
  },
  {
    address: '0x85286c35061499180c3507B9E6cE78f45A9978D6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14343.156318812005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14343.16,
  },
  {
    address: '0xcBC1070e400C832E62B6D7776cad08aE2c64d305',
    minters_reward: 0,
    lp_providers_reward: 9644.71,
    swapers_reward: 4075.555312070295,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 14262.08,
  },
  {
    address: '0x61284F29fF05886991b994385e53CdD05d70088f',
    minters_reward: 0,
    lp_providers_reward: 6988.15,
    swapers_reward: 7213.410752682958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14201.56,
  },
  {
    address: '0x03a5087497580671b37e9fC97796DdFE609fD593',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5689,
    hoaxs_reward: 0,
    total_rewards: 14194.85,
  },
  {
    address: '0xd4B55c039213729a642a4AEcc3689b0A9cCd92B8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 3881.87169173904,
    holders_reward: 541.81,
    hoaxs_reward: 9259.26,
    total_rewards: 14087.98,
  },
  {
    address: '0xa510F25fcD0dCC7ec212c5d0248690C7Bbe65F5C',
    minters_reward: 7290.73,
    lp_providers_reward: 0,
    swapers_reward: 59.685295385654314,
    holders_reward: 5689,
    hoaxs_reward: 925.93,
    total_rewards: 13965.35,
  },
  {
    address: '0x49c1250A747505FBA4dC16CAefC38BD4A0Bf64FF',
    minters_reward: 0,
    lp_providers_reward: 13713.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13713.62,
  },
  {
    address: '0x29f654aF010a4F20c7DCCe94779A0E8FE2e30CC9',
    minters_reward: 0,
    lp_providers_reward: 5413.95,
    swapers_reward: 8247.374308225078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13661.32,
  },
  {
    address: '0xDB878025203BDCDD4E47c1a05Cf73a22654Eb3E0',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0xA3F078c0B6EEc775F5D3b1b7c426a3d4616080B2',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0xc5DF2ddA3b4cA6FD7b1044D85B0E91A12cf266Ee',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0x38f6Dcbc8bfBE4CD185527f2Ab767fF8fED44Ad9',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0x00911f70415ad0a17f398Aa65398f6a51B2581f7',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0xbB6407FE044bc874c6ABE484054891D7F23E7DA8',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0x35EfCe5f4bd52356e8215BCB9dC7687aDe6B6400',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0x7FD87c027652cc1Eb1f9fCCB333264a89c2BEc42',
    minters_reward: 0,
    lp_providers_reward: 13283.86,
    swapers_reward: 112.76282442192819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13396.62,
  },
  {
    address: '0x81E99C200B79B2EC05F9F6d7B5c52AaB1864Aa2f',
    minters_reward: 4860.49,
    lp_providers_reward: 4470.23,
    swapers_reward: 3283.778990882226,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 13156.31,
  },
  {
    address: '0x43BC7667BF508cE3905DC0F492f106eaE2800536',
    minters_reward: 810.08,
    lp_providers_reward: 11783.95,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 13135.84,
  },
  {
    address: '0x8c5cC6aDb769aB499B545c70fFf50b1697972d18',
    minters_reward: 0,
    lp_providers_reward: 11655.43,
    swapers_reward: 111.85998940499792,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 13121.81,
  },
  {
    address: '0x64951C98e0eBa153E78eD6Be7c95f55304B13eb9',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 13111.23,
  },
  {
    address: '0xa67D792b3861Ff23b1196dFB3BF4FD2A4152f128',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 12962.96,
    total_rewards: 12962.96,
  },
  {
    address: '0xe2e0f5dC2E3317ecfdd43deF0f3E740b6A77c16C',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 8398.05,
    hoaxs_reward: 0,
    total_rewards: 12853.5,
  },
  {
    address: '0x5f8A0e75354c032510289bed3ed4a6ADC1D4BA6B',
    minters_reward: 7695.77,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5147.19,
    hoaxs_reward: 0,
    total_rewards: 12842.96,
  },
  {
    address: '0x5164463772b6Ab5A984FC7A35c5A4E76977A594D',
    minters_reward: 7695.77,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5147.19,
    hoaxs_reward: 0,
    total_rewards: 12842.96,
  },
  {
    address: '0xFCd01a3332492F7c3CC076F96493281dC0b5376c',
    minters_reward: 0,
    lp_providers_reward: 9388.82,
    swapers_reward: 553.139741954607,
    holders_reward: 1896.33,
    hoaxs_reward: 925.93,
    total_rewards: 12764.22,
  },
  {
    address: '0x434327FCd2210A5996b1D95dFBd62cd61CE2FBB9',
    minters_reward: 0,
    lp_providers_reward: 5019.07,
    swapers_reward: 7598.545513469397,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12617.62,
  },
  {
    address: '0xa2Ca08e5b1d3cF033d4dB98e26aDffC7eD8DF862',
    minters_reward: 0,
    lp_providers_reward: 9356.2,
    swapers_reward: 3244.255328688845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12600.46,
  },
  {
    address: '0xF0721ffdFF9818C6dcC5E1bC9F3B2a63c3AA65D9',
    minters_reward: 12556.26,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12556.26,
  },
  {
    address: '0x05A05073ef3F2AeBCA8e6630341fB1b60cA5E538',
    minters_reward: 7290.73,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5147.19,
    hoaxs_reward: 0,
    total_rewards: 12437.92,
  },
  {
    address: '0x60e39B27f5ebf4F85627857222024ff6cB534E01',
    minters_reward: 7695.77,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 12301.15,
  },
  {
    address: '0xF363CcF2F2bF10F197E27409fDAFa20a615CB0ee',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3792.67,
    hoaxs_reward: 2777.78,
    total_rewards: 12241.02,
  },
  {
    address: '0x3dB55A59cccb7d8126698F8c447FE39a5f4A3DCD',
    minters_reward: 7290.73,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4876.29,
    hoaxs_reward: 0,
    total_rewards: 12167.02,
  },
  {
    address: '0xED86B19BC819974F044BD18fC87eCFCd0944BaDF',
    minters_reward: 0,
    lp_providers_reward: 11206.58,
    swapers_reward: 73.37716859144577,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 12092.67,
  },
  {
    address: '0x11Fe1A4Fc7f140850ff72881a53eEA24A3d02911',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 4839.227922902518,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 12003.73,
  },
  {
    address: '0xba3620B8a00E925154B45591577Fe52fbCc514F5',
    minters_reward: 11746.17,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11746.17,
  },
  {
    address: '0xE3d0c0Cb8B464100faEE514f88e127Ff55730e56',
    minters_reward: 0,
    lp_providers_reward: 11705.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11705.62,
  },
  {
    address: '0xD9Fc64e32abe6DBaD449BF242bF9E790782bAc93',
    minters_reward: 6885.69,
    lp_providers_reward: 0,
    swapers_reward: 290.3123507289939,
    holders_reward: 4334.48,
    hoaxs_reward: 0,
    total_rewards: 11510.48,
  },
  {
    address: '0x606d2C436A07be40C276a6176bb1376C34e49Ee9',
    minters_reward: 6885.69,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 11491.07,
  },
  {
    address: '0xC8cc1796513d88193191D3585801e88743fEC35F',
    minters_reward: 0,
    lp_providers_reward: 11359.35,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11359.35,
  },
  {
    address: '0xC1B3a919cd84eB29717A641A86926c3184b0a1AF',
    minters_reward: 0,
    lp_providers_reward: 11346.07,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11346.07,
  },
  {
    address: '0x6487839dc4F4BEa26A3Cd187d99Ea31CcAfF451f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11245.57925795928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11245.58,
  },
  {
    address: '0xEABFCD03392AC20A1622A1F79B95C847b269F64F',
    minters_reward: 0,
    lp_providers_reward: 11207.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11207.52,
  },
  {
    address: '0x61E396E8856e22f348763F80f23F67EDe50b8Cc9',
    minters_reward: 6480.65,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 11086.03,
  },
  {
    address: '0xeC097c2296e217f38FaC23CfBE2b12aD2D96379C',
    minters_reward: 7290.73,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 11083.4,
  },
  {
    address: '0xB890f3b9378f6F7Cb428F5231cE6A2905Cc3f978',
    minters_reward: 0,
    lp_providers_reward: 10993.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10993.84,
  },
  {
    address: '0xFB3F66eFb26fe232bf5C9aB5EC29d21A958faB1e',
    minters_reward: 2025.2,
    lp_providers_reward: 3880.43,
    swapers_reward: 3712.932331097964,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 10973.08,
  },
  {
    address: '0xA0F510582cA57E61c907853f7e8145F75f41bE56',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 750.9900388701267,
    holders_reward: 0,
    hoaxs_reward: 10185.19,
    total_rewards: 10936.18,
  },
  {
    address: '0x3AdC5d795ab9B2f7e06814dbCB1cf838A27EA82c',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 615.2792637531489,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 10893.87,
  },
  {
    address: '0xFfc86F4940E60bb5f20984Fc6C88021D2cf7f1F2',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3250.86,
    hoaxs_reward: 2777.78,
    total_rewards: 10889.13,
  },
  {
    address: '0x3d3993fc70F1E64522861594875B1b3DC9baa6B6',
    minters_reward: 0,
    lp_providers_reward: 9576.15,
    swapers_reward: 1302.9297732404793,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10879.08,
  },
  {
    address: '0x37CE07C03Af4Ce3635d5FD0A8c302d283A25504D',
    minters_reward: 0,
    lp_providers_reward: 10191.71,
    swapers_reward: 652.4349209024911,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10844.14,
  },
  {
    address: '0x5B080612ed975700Cadc86174775f739Cb213864',
    minters_reward: 0,
    lp_providers_reward: 10827.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10827.66,
  },
  {
    address: '0x7ACF64fFd848e0b18F25E9D0F9f712F7099433cB',
    minters_reward: 4860.49,
    lp_providers_reward: 850.08,
    swapers_reward: 924.265468098033,
    holders_reward: 3250.86,
    hoaxs_reward: 925.93,
    total_rewards: 10811.63,
  },
  {
    address: '0x385FA52944e8041438a75949E07A780351F4a56d',
    minters_reward: 2430.24,
    lp_providers_reward: 594.61,
    swapers_reward: 140.98444478295983,
    holders_reward: 7585.34,
    hoaxs_reward: 0,
    total_rewards: 10751.17,
  },
  {
    address: '0x91E63c37157e71B7450C52247D35139E238710Fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10724.258796690976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10724.26,
  },
  {
    address: '0x59CB670AD42314DCBA7059a3727adc6719bC198D',
    minters_reward: 2835.28,
    lp_providers_reward: 3602.12,
    swapers_reward: 2335.680790441483,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 10669.41,
  },
  {
    address: '0xA648a010f9033DbbCb37deFD550be6D3036c5AA8',
    minters_reward: 810.08,
    lp_providers_reward: 9209.24,
    swapers_reward: 6.657615726935605,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 10567.79,
  },
  {
    address: '0x163aD4Ed1Ae9CbBc58F5A964d0cd57EfFd74B888',
    minters_reward: 7695.77,
    lp_providers_reward: 0,
    swapers_reward: 68.15158335216643,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 10472.97,
  },
  {
    address: '0x7F8B71606A01FfbE3aa8D8B64DDE5Ce4E38D2CCF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10383.213323823937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10383.21,
  },
  {
    address: '0x0b71bd3191e57700D14E7b696E3aA0a0aB2A4979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10341.750381891397,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10341.75,
  },
  {
    address: '0xc82C9dD2510C7df55fD777561E15056bDEbE4B5B',
    minters_reward: 0,
    lp_providers_reward: 9411.59,
    swapers_reward: 924.0206892134732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10335.61,
  },
  {
    address: '0x399D1Ff383992F4748c97aDd0D697F216a6a3348',
    minters_reward: 0,
    lp_providers_reward: 3431.32,
    swapers_reward: 6863.902532056619,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10295.22,
  },
  {
    address: '0xB996203ca2940ED236B5C1D1AD5dF6EAb1D659AA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 10294.38,
    hoaxs_reward: 0,
    total_rewards: 10294.38,
  },
  {
    address: '0x45a6A49dcE7032bdF9bF08F287c466C820f731d1',
    minters_reward: 2025.2,
    lp_providers_reward: 3617.98,
    swapers_reward: 1105.2637742020943,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 10270.2,
  },
  {
    address: '0xbcc0e6580B2c04eA45757a964eCB9fFcdBe28b99',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 28.571561500565622,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10167.75,
  },
  {
    address: '0xbb2361C9aAB674b7a8B019105F46E8200fDD4112',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10139.18,
  },
  {
    address: '0x8ec0159B686197FA06c1653379BCc80Cde1F6A51',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10139.18,
  },
  {
    address: '0x0450591FF9929dF0383E9d01D06f7Ee7d5ABA3b7',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10139.18,
  },
  {
    address: '0xbBa637AB80AAEce8Eb97cC1C680E873aaaeAE1BE',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10139.18,
  },
  {
    address: '0xEF6AEd099865ad66E63706dA6f8cB83E00841bAa',
    minters_reward: 0,
    lp_providers_reward: 5134.75,
    swapers_reward: 4879.954982507545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10014.7,
  },
  {
    address: '0xc1DB89B97B45953C0EF51295aB6826076D347dCe',
    minters_reward: 3240.32,
    lp_providers_reward: 2715.36,
    swapers_reward: 2035.4369189875654,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 9887.45,
  },
  {
    address: '0x49fC4e231c9c3C4B5E49fD9aDb6Fa3e9cACE7586',
    minters_reward: 0,
    lp_providers_reward: 7169.2,
    swapers_reward: 2648.836424786472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9818.04,
  },
  {
    address: '0x93695A34c1535Ab8abcbBE6c65fA00c6ddb9beb5',
    minters_reward: 2835.28,
    lp_providers_reward: 2354.45,
    swapers_reward: 807.1797635081446,
    holders_reward: 1896.33,
    hoaxs_reward: 1851.85,
    total_rewards: 9745.09,
  },
  {
    address: '0xf5df1e44433557420FB4Fe1C077ac92442dc0787',
    minters_reward: 4455.45,
    lp_providers_reward: 1061.73,
    swapers_reward: 1231.4593791309885,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 9728.59,
  },
  {
    address: '0xEf750a119b3dB807b93bb422fDB798dc0785eF8C',
    minters_reward: 2430.24,
    lp_providers_reward: 3658.69,
    swapers_reward: 2002.5553744163547,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 9716.92,
  },
  {
    address: '0x0Ce61DC009669C4691491263dCa7c30209924Ed3',
    minters_reward: 5670.57,
    lp_providers_reward: 190.93,
    swapers_reward: 31.271879031042843,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 9685.44,
  },
  {
    address: '0xf760fee18f76E167aCE7C177F390d5Dd4051Cc12',
    minters_reward: 5265.53,
    lp_providers_reward: 579.15,
    swapers_reward: 310.5279776743151,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 9676.97,
  },
  {
    address: '0xB083379747fcE720cDCdA680CE34105990Ffdbd9',
    minters_reward: 4455.45,
    lp_providers_reward: 1394.61,
    swapers_reward: 796.2604988578021,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 9626.27,
  },
  {
    address: '0x47033eB2f11ed928a92E1D105d0f86A76635E2Bd',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 2763.7793582447257,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 9523.24,
  },
  {
    address: '0xE6822eFB072201005f10eEbC666173DFdEE0F278',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0.1541407065182402,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 9463.39,
  },
  {
    address: '0x28107811DA51900990D1d4f16832736c5e32f950',
    minters_reward: 3240.32,
    lp_providers_reward: 28.17,
    swapers_reward: 1884.997415381552,
    holders_reward: 2438.14,
    hoaxs_reward: 1851.85,
    total_rewards: 9443.48,
  },
  {
    address: '0x97a290A02Eb3a7f8B39Dac8f251F4718fD6e7F00',
    minters_reward: 0,
    lp_providers_reward: 9419.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9419.49,
  },
  {
    address: '0x3BAC8B648a3B7F4851F8dCC5C95fA7c105F198d0',
    minters_reward: 0,
    lp_providers_reward: 9237.93,
    swapers_reward: 152.92280032877926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9390.85,
  },
  {
    address: '0xb39994C92910832C5a2dF2bF2AeAaB400d017338',
    minters_reward: 2430.24,
    lp_providers_reward: 3912.38,
    swapers_reward: 1052.8725489668866,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 9291.82,
  },
  {
    address: '0x32c36164ecadA33179fc0eed80De0b70784CA478',
    minters_reward: 0,
    lp_providers_reward: 4485.21,
    swapers_reward: 1955.9894768345523,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 9218.98,
  },
  {
    address: '0xc537295d0A7bBCb3484719f7d9Eee775d80b1976',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 9192.33,
  },
  {
    address: '0x3ECe9b9e81573aBff3138b1995D5650aC8F4Cd5B',
    minters_reward: 0,
    lp_providers_reward: 4839.99,
    swapers_reward: 4267.064380469421,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9107.05,
  },
  {
    address: '0xC327b471064692067c5E747A05D0717DCBb169Ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.39060422086888,
    holders_reward: 541.81,
    hoaxs_reward: 8333.33,
    total_rewards: 9021.53,
  },
  {
    address: '0x6a7fBF07A0aca2f7b46fD6CeA0cb52A39830D831',
    minters_reward: 0,
    lp_providers_reward: 9016.44,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9016.44,
  },
  {
    address: '0x5A0950581AA641EA0C4B50bF5a465a99f35F1679',
    minters_reward: 0,
    lp_providers_reward: 8895.38,
    swapers_reward: 41.90647526861081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8937.29,
  },
  {
    address: '0xA5294016f13Fb0015C1d22BD521Eb65a123d34dc',
    minters_reward: 0,
    lp_providers_reward: 8851.17,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8851.17,
  },
  {
    address: '0x2D7640eada432B2459517C29969454543305B7B9',
    minters_reward: 0,
    lp_providers_reward: 19.58,
    swapers_reward: 3946.334399757094,
    holders_reward: 2979.95,
    hoaxs_reward: 1851.85,
    total_rewards: 8797.71,
  },
  {
    address: '0x108A36c39a0993E70E08b04639f6246160A45cc7',
    minters_reward: 5265.53,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 8787.29,
  },
  {
    address: '0xa12740090585af67808821991c4434cAAF6D6f3E',
    minters_reward: 0,
    lp_providers_reward: 8780.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8780.81,
  },
  {
    address: '0x7dB40142A78D6b3C3C4DC1888e87C7cbf63Dd375',
    minters_reward: 0,
    lp_providers_reward: 7135.87,
    swapers_reward: 1629.7266839241747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8765.6,
  },
  {
    address: '0x842A95E35dDe023764195f6555bf295AF4F79c89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8738.45720129089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8738.46,
  },
  {
    address: '0xc910E715fb40E291AD817AD2C40bfC87CCa0aB6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8623.898278687053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8623.9,
  },
  {
    address: '0x5157b58E80A7651af3125e3355cF8143EFCe186b',
    minters_reward: 0,
    lp_providers_reward: 1217.17,
    swapers_reward: 3090.152437357,
    holders_reward: 541.81,
    hoaxs_reward: 3703.7,
    total_rewards: 8552.83,
  },
  {
    address: '0xFbD9a4040E100DcDB6C2B7C45085B8a7045f5e9b',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 259.3383885434389,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 8507.46,
  },
  {
    address: '0xba8C789d80eCA2d2DAbE1C6934dfb487D7ab2595',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8505.85,
  },
  {
    address: '0x471601f3071CE057b0ddd539DC0E0C78450E73f0',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 115.05874016521237,
    holders_reward: 2979.95,
    hoaxs_reward: 925.93,
    total_rewards: 8476.39,
  },
  {
    address: '0xB7039fbd301CfAa7C66BA43fc10e2c60aa18F785',
    minters_reward: 0,
    lp_providers_reward: 801.48,
    swapers_reward: 7618.036391876424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8419.52,
  },
  {
    address: '0xaa3548d23D7F230E9fFe99362Db4E26d684c4346',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 8382.25,
  },
  {
    address: '0x28f4b1bB56842E5aD4Ea37dA69A9d5a68C570a70',
    minters_reward: 0,
    lp_providers_reward: 8314.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8314.46,
  },
  {
    address: '0x5eF588e38f08C9457f8EE2Ed6b1a1d0c904037FF',
    minters_reward: 0,
    lp_providers_reward: 8009.91,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 8280.81,
  },
  {
    address: '0x366a96930D05C72F2AAfb0Ea3162d95225575607',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3250.86,
    hoaxs_reward: 0,
    total_rewards: 8111.35,
  },
  {
    address: '0xd724bE86224BcBb0400f844CCf21e5C3869bcEb4',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3250.86,
    hoaxs_reward: 0,
    total_rewards: 8111.35,
  },
  {
    address: '0xD840aA64F5B3Ac2F40A63987DEce10D2a4bBa679',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3250.86,
    hoaxs_reward: 0,
    total_rewards: 8111.35,
  },
  {
    address: '0x5BffE7AeBD0f08CA05a5E8103C84931aF9acCc52',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8100.81,
  },
  {
    address: '0xf47750d2B1491fE0566d9E07a9A6e6c3639b10c1',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8100.81,
  },
  {
    address: '0x0855f08E143E1db081cC3c571F40787dcFb69b9d',
    minters_reward: 0,
    lp_providers_reward: 8083.6,
    swapers_reward: 3.3737655926582986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8086.97,
  },
  {
    address: '0xC6EEABAb3b6058E562639916EFCA10b4f2bE0616',
    minters_reward: 0,
    lp_providers_reward: 7593.78,
    swapers_reward: 445.5832689749827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8039.36,
  },
  {
    address: '0xba1BF6D162b0006363E4381e776025515a5915d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4334.48,
    hoaxs_reward: 3703.7,
    total_rewards: 8038.18,
  },
  {
    address: '0x0683727dE070564029387B8BbD9790b9803a596a',
    minters_reward: 0,
    lp_providers_reward: 7046.67,
    swapers_reward: 967.2989982344819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8013.97,
  },
  {
    address: '0x7493AEf34f3b3B3385E71b6df7CF63E0388cae02',
    minters_reward: 0,
    lp_providers_reward: 7916.52,
    swapers_reward: 86.97705237030468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8003.5,
  },
  {
    address: '0x353c6B0d9d6c7Bb8c8B1979d284dFf23f71909cD',
    minters_reward: 1215.12,
    lp_providers_reward: 3645.93,
    swapers_reward: 2320.7813086210285,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 7994.54,
  },
  {
    address: '0x3D630F40DE36D06603ad329cC1Ba7Da86faE7f3B',
    minters_reward: 0,
    lp_providers_reward: 7930.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7930.59,
  },
  {
    address: '0xfa11BC97fA3EC3c4654ae75259E3E1c0A2A72dbA',
    minters_reward: 0,
    lp_providers_reward: 6971.13,
    swapers_reward: 836.2611052373882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7807.39,
  },
  {
    address: '0xB5410e3f3569BE268cA4f12B325e61C9d78fFaA5',
    minters_reward: 0,
    lp_providers_reward: 7794.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7794.58,
  },
  {
    address: '0xeb2b21fF36bF9E836CB6EE44FC4d052E5EF3377a',
    minters_reward: 0,
    lp_providers_reward: 3083.11,
    swapers_reward: 2534.712771660308,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 7785.06,
  },
  {
    address: '0x10A6a0aF5DdDD44B742D344e736999112f413c73',
    minters_reward: 0,
    lp_providers_reward: 5392.57,
    swapers_reward: 2363.4653682073044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7756.04,
  },
  {
    address: '0x4b81ee4f2E85b847d01aaa31a41b88e906e2d854',
    minters_reward: 2025.2,
    lp_providers_reward: 2429.99,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 1851.85,
    total_rewards: 7661.56,
  },
  {
    address: '0x7Ba70E4DB89fc75f18b1fD6a7b86fDb10A2f7E38',
    minters_reward: 0,
    lp_providers_reward: 7640.23,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7640.23,
  },
  {
    address: '0x96fb02339316f1c9f109f3305828F8611B6a193a',
    minters_reward: 0,
    lp_providers_reward: 7208.98,
    swapers_reward: 353.727129490583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7562.71,
  },
  {
    address: '0x560835d8899c1bbF71A2340F234C708792A1b205',
    minters_reward: 0,
    lp_providers_reward: 7552.02,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7552.02,
  },
  {
    address: '0x3c0f8CF0fa024330e96cb473ecD6941B49C93b07',
    minters_reward: 0,
    lp_providers_reward: 4383.76,
    swapers_reward: 1513.1872877370904,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 7522.38,
  },
  {
    address: '0x182c828895CC3BCb6455DBC3Ccb606019dE32dd4',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 7435.4,
  },
  {
    address: '0x078f9867982C1CE4B6d01ABB6152f6f10F0196e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.253085914091535,
    holders_reward: 7314.43,
    hoaxs_reward: 0,
    total_rewards: 7377.68,
  },
  {
    address: '0x9D0AA44aCCb5573CA15f964fC8a7A24624B31202',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 7314.43,
    hoaxs_reward: 0,
    total_rewards: 7314.43,
  },
  {
    address: '0x472a6459F23a7C298612FcFdB4Cf0431eFA14B0F',
    minters_reward: 810.08,
    lp_providers_reward: 3592.51,
    swapers_reward: 2354.0053979286254,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 7298.41,
  },
  {
    address: '0x89a48aEAB1c1975860660f2d1521dA54bd01e215',
    minters_reward: 0,
    lp_providers_reward: 1909.32,
    swapers_reward: 5332.250592277112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7241.57,
  },
  {
    address: '0x8eEd32Aed28d6169243235d7A207E3C35cD4eD93',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 721.6199630178704,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 7210.17,
  },
  {
    address: '0xD39023AEEc560cc50707CE4c193957006c93699D',
    minters_reward: 0,
    lp_providers_reward: 7037.75,
    swapers_reward: 147.61430613876314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7185.36,
  },
  {
    address: '0x0FeF8E435A72F79D5E0316a7028137f9407002F9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.00076859333632,
    holders_reward: 7043.53,
    hoaxs_reward: 0,
    total_rewards: 7119.53,
  },
  {
    address: '0xC133d9bC08FE8Cc22Ccb973d659A67De66EA252a',
    minters_reward: 4050.41,
    lp_providers_reward: 215.74,
    swapers_reward: 92.02746382608206,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 7067.23,
  },
  {
    address: '0x25043A0Bed7386D1dA7571dE3bF5b283e4a7Ed9d',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 274.5817954213248,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 7034.04,
  },
  {
    address: '0x5d649D87F709972db586F4E1928A59cF57e74b22',
    minters_reward: 0,
    lp_providers_reward: 7033.08,
    swapers_reward: 0.034226257239643794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7033.11,
  },
  {
    address: '0xc02a104F9cF3a1143c06Eab296e9d224b457E170',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 7025.09,
  },
  {
    address: '0x705C053d69eB3B8aCc7C404690bD297700cCf169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6985.216046381334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6985.22,
  },
  {
    address: '0x951fc0065ae7F28E1BC867dE7e68E6F9109F8380',
    minters_reward: 0,
    lp_providers_reward: 2294.87,
    swapers_reward: 1469.5125379442886,
    holders_reward: 1354.52,
    hoaxs_reward: 1851.85,
    total_rewards: 6970.75,
  },
  {
    address: '0x2085d3Fe77e135bBf852e58de48413A26CC62E00',
    minters_reward: 0,
    lp_providers_reward: 3667.78,
    swapers_reward: 3257.96619671355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6925.75,
  },
  {
    address: '0xfb14a2A025a74125d807338B96f075678058a216',
    minters_reward: 4050.41,
    lp_providers_reward: 55.92,
    swapers_reward: 18.918877488265096,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6834.3,
  },
  {
    address: '0x2B7F8ede13C845a3Cbc61afa6823b93De333DDAE',
    minters_reward: 0,
    lp_providers_reward: 6789.43,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6789.43,
  },
  {
    address: '0x7B2069a910e033937b69fA00abfb83b4D2998B45',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 14.04165448080299,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6773.5,
  },
  {
    address: '0xb93A768f5F2C8BE0e7D4528b7bd4734a5703834a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6772.62,
    hoaxs_reward: 0,
    total_rewards: 6772.62,
  },
  {
    address: '0xad2292ae5Db2821eA9B6e0564FF074041579Ec08',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x2A433035ae7eC396C48BEF726AE62F959c9151A4',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x68674FAF97b37c8c13eA3557CD931FcFaDf9d13b',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x72B7bbbB816787E857FE6c73386098B7Ac408b70',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x4B87D3237610F7bF52c836C479D83008dcc65342',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x34D111d921750A2785d64697838bDbc91045f528',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x6149e004211852a0300a44A1Dd07c442E7A53BB2',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x436DFa7D4aeb9e25D7CcCA4486Cc052328B8a4A6',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 6756.82,
  },
  {
    address: '0x39fEA4ccf4ADc93cBAa7b626606b9b2043bc813b',
    minters_reward: 0,
    lp_providers_reward: 2011.68,
    swapers_reward: 4658.455532701536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6670.14,
  },
  {
    address: '0xF07dA2F0d5DdB7dae824Ebe830C3f742117e6387',
    minters_reward: 0,
    lp_providers_reward: 3163.03,
    swapers_reward: 3485.1592791011717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6648.19,
  },
  {
    address: '0x4fD102Ad7aA70600719b11fc122A3085b128F5b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1085.9511372797685,
    holders_reward: 0,
    hoaxs_reward: 5555.56,
    total_rewards: 6641.51,
  },
  {
    address: '0xbC104E3e61b2Afe7fCa264e92031Ac23910aa913',
    minters_reward: 0,
    lp_providers_reward: 6303.47,
    swapers_reward: 289.38867329764673,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6592.86,
  },
  {
    address: '0xaEF969Eb47DF8689850F826D2A3e0F6Cd87a2C38',
    minters_reward: 0,
    lp_providers_reward: 4255.8,
    swapers_reward: 2280.0633898290052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6535.86,
  },
  {
    address: '0x486058f0D5c838454a7b5905c3B1eCE98b76972c',
    minters_reward: 2025.2,
    lp_providers_reward: 1014.55,
    swapers_reward: 1622.5941937695816,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 6514.19,
  },
  {
    address: '0x8623F2A1D1f179878c6dE05D011d522b2d05d9Ef',
    minters_reward: 6480.65,
    lp_providers_reward: 0,
    swapers_reward: 31.27993238246097,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6511.93,
  },
  {
    address: '0xD720F3a80c92f16C7A94545162a96cC674C51D4d',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 6488.55,
  },
  {
    address: '0x2Ea0286b54EF0d79bEAAf12AAd574F7B101029b1',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 6488.55,
  },
  {
    address: '0x5662045AE117a02B48cb0b9E645B7E8D9f615ffc',
    minters_reward: 0,
    lp_providers_reward: 4503.79,
    swapers_reward: 1969.783331458504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6473.57,
  },
  {
    address: '0x0971Ab754625fF40457f77deceB55cAe915D78b1',
    minters_reward: 0,
    lp_providers_reward: 3796,
    swapers_reward: 2596.384440478955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6392.38,
  },
  {
    address: '0x3F1dd1C270f6E08d825696e652Bb4Ac48F304C4e',
    minters_reward: 3645.36,
    lp_providers_reward: 0,
    swapers_reward: 2.560331364653293,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6356.97,
  },
  {
    address: '0x4B69fF56B0601C7d898Cdb2F2d2883d1077Fdf99',
    minters_reward: 0,
    lp_providers_reward: 3428.31,
    swapers_reward: 2926.1344062537064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6354.44,
  },
  {
    address: '0xc78B20e3B6F2E106cA5b112b96B38ad2a49B76fc',
    minters_reward: 3645.36,
    lp_providers_reward: 558.79,
    swapers_reward: 486.32883542938305,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 6315.91,
  },
  {
    address: '0xE8CD45e550F5FDeC5694f6D12aC02ED68dAd609B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6300.69112413138,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6300.69,
  },
  {
    address: '0x48CE3b9C869Fc5cb0C6cd7feC5A1BBB629359E08',
    minters_reward: 1620.16,
    lp_providers_reward: 1496.05,
    swapers_reward: 494.51582845791995,
    holders_reward: 812.71,
    hoaxs_reward: 1851.85,
    total_rewards: 6275.29,
  },
  {
    address: '0x1BD717CcB1946e07D5800D3cc01602c5927Ed5df',
    minters_reward: 0,
    lp_providers_reward: 5102.16,
    swapers_reward: 343.2621341080972,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 6258.13,
  },
  {
    address: '0x233E5064d4E94304e3BA89FBD486555E601fab5B',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 296.26777834797934,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6245.64,
  },
  {
    address: '0x08Db5102166AbBaE0cdD1e1e917df0eee81A86b2',
    minters_reward: 3240.32,
    lp_providers_reward: 2688.82,
    swapers_reward: 0.9422625468181935,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 6200.98,
  },
  {
    address: '0x42536063689537A0942BCB814529DcFb5953541C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5085.692797251546,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 6169.31,
  },
  {
    address: '0x3b69ec2221Ede0eA01D01b7A346F5a02f8CC6518',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6161.171737661352,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6161.17,
  },
  {
    address: '0x63f67a2e42352c693E8D7C88dFF0AD6C58e29Bb1',
    minters_reward: 2835.28,
    lp_providers_reward: 383.69,
    swapers_reward: 1015.641234438112,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 6130.94,
  },
  {
    address: '0xa2DB216821ba2A33C0f15CB6f8aDFAb13Df41036',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 5555.56,
    total_rewards: 6097.37,
  },
  {
    address: '0xcD180Eca01740098321951e9698a43520C3B6fAB',
    minters_reward: 0,
    lp_providers_reward: 5682.56,
    swapers_reward: 130.66871056421596,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 6084.13,
  },
  {
    address: '0x2D0d7b9Bb3a0B998FE24ee1eCC23C068c7c8e8F1',
    minters_reward: 3645.36,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 6083.5,
  },
  {
    address: '0x788157B1397D6334618593890a911277f62541Eb',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6075.61,
  },
  {
    address: '0xaDa4389661B5aa5756636Ca7D3C051C3D04EdabB',
    minters_reward: 0,
    lp_providers_reward: 2370.23,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 3703.7,
    total_rewards: 6073.93,
  },
  {
    address: '0x0850A184714490B7EF2204c83e69532080F4845f',
    minters_reward: 0,
    lp_providers_reward: 1961.92,
    swapers_reward: 4002.137947048465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5964.06,
  },
  {
    address: '0x00E84c0dCC2Eb8BE3B36DeEa3Ec2b569e482eE3a',
    minters_reward: 810.08,
    lp_providers_reward: 2695.67,
    swapers_reward: 1098.9359953942555,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 5959.21,
  },
  {
    address: '0x4ab5899d8E9a87e457e6dB1393E96588BD12D689',
    minters_reward: 0,
    lp_providers_reward: 5951.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5951.11,
  },
  {
    address: '0xE6EA3F27e7e9Dad9eF1D7dee64E82bd414e8B5aD',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 5946.74,
  },
  {
    address: '0xCa585E09DF67E83106c9bCD839C989aCe537BF95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5919.575398094543,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5919.58,
  },
  {
    address: '0xA328813626A965D4B606692BF86B4a83760436D4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 702.5908950777601,
    holders_reward: 5147.19,
    hoaxs_reward: 0,
    total_rewards: 5849.78,
  },
  {
    address: '0x0A1263F2c94C61AF166f58258b31EB18F8B86A15',
    minters_reward: 5670.57,
    lp_providers_reward: 37.21,
    swapers_reward: 123.64825248409859,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5831.43,
  },
  {
    address: '0xe88A6D6632D5eA8a219fc2C802290221177B6664',
    minters_reward: 2430.24,
    lp_providers_reward: 283.98,
    swapers_reward: 1491.420327620511,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 5831.07,
  },
  {
    address: '0xfE95b7a015AF9dD500E9107104f33C94529032fF',
    minters_reward: 2025.2,
    lp_providers_reward: 652.23,
    swapers_reward: 1459.281544073726,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 5762.14,
  },
  {
    address: '0x51b77A2dEF34100aF465bD1F3C658A96A92b6A3e',
    minters_reward: 0,
    lp_providers_reward: 5326.26,
    swapers_reward: 433.11167091795414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5759.37,
  },
  {
    address: '0xc190f054B6eA68592F8673A70DEC932271cFb728',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 501.1299864014008,
    holders_reward: 1354.52,
    hoaxs_reward: 1851.85,
    total_rewards: 5732.7,
  },
  {
    address: '0x3458d5CBAC485bDda4B7439a18152195E93d134f',
    minters_reward: 0,
    lp_providers_reward: 5459.75,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 5730.65,
  },
  {
    address: '0x21C93e25ca2F29c62d1ca78c0D885bc07109BABd',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 5673.2,
  },
  {
    address: '0xB892808cAf35929D6D965670A5A4c0f52Da8e2B1',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5670.57,
  },
  {
    address: '0xc2595965b280DE29Cf054a0B05d8211DE3Fcc095',
    minters_reward: 2430.24,
    lp_providers_reward: 1267.18,
    swapers_reward: 871.2107717876853,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 5652.25,
  },
  {
    address: '0xc6aB34235e4b2B7F42A3F6D78830E83760881667',
    minters_reward: 0,
    lp_providers_reward: 5633.72,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5633.72,
  },
  {
    address: '0xB8E6890FAb931A27B98dE3Cc55E270e8C2ca2121',
    minters_reward: 0,
    lp_providers_reward: 53.36,
    swapers_reward: 132.13317624000854,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 5603.59,
  },
  {
    address: '0x1d4e7Abdd3Ea7B553576C392B33629da52250321',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 5555.56,
    total_rewards: 5555.56,
  },
  {
    address: '0x6398b4743D3cf953D5D68BEd5DaeeefB77Cb448a',
    minters_reward: 3240.32,
    lp_providers_reward: 80.43,
    swapers_reward: 31.288597309863803,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5519.28,
  },
  {
    address: '0xE9F963a68c34B4528fC8D2E51c0ACA438993B5B9',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 109.14976538268287,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5516.71,
  },
  {
    address: '0xb3bdE72b6dc0c54Cd9D20e1900bf4637B46c4eDD',
    minters_reward: 0,
    lp_providers_reward: 5481.02,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5481.02,
  },
  {
    address: '0xcEFA4d1feeafFc599eF9a76Fe07b826F2d0b293d',
    minters_reward: 0,
    lp_providers_reward: 5479.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5479.96,
  },
  {
    address: '0xB329237d45bE5f8Eb5edEaE1C3640B07300906A5',
    minters_reward: 2430.24,
    lp_providers_reward: 1208.77,
    swapers_reward: 184.80339359252497,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 5449.24,
  },
  {
    address: '0x0503dA89b9AC2140afAd0918E3d434619044E285',
    minters_reward: 0,
    lp_providers_reward: 4201.96,
    swapers_reward: 1220.1078987560252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5422.07,
  },
  {
    address: '0x8c33A5E7A05fc66c91b7c9bd77A2f53D8Ca86728',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 5418.1,
  },
  {
    address: '0x5d1555e2685e42c7b2De76B78119E37fc35Fd724',
    minters_reward: 0,
    lp_providers_reward: 1810.87,
    swapers_reward: 3599.257622891609,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5410.13,
  },
  {
    address: '0xfcA75B06257706996ECFD49db1d6382E3fF4eddE',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5407.56,
  },
  {
    address: '0x36C736fcd2e8786BfE03ED50583Cb238e7076A3F',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5407.56,
  },
  {
    address: '0x6d0d932e0bC8503740364Da8eDf0c9c9E1B8C2e8',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5407.56,
  },
  {
    address: '0x4efdE34c54965F956BF6834f0e7dC4286f6a490d',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5407.56,
  },
  {
    address: '0x15cC4931AC9650b17f4A2347841D999DcB61c350',
    minters_reward: 0,
    lp_providers_reward: 654.44,
    swapers_reward: 1431.6106201753907,
    holders_reward: 541.81,
    hoaxs_reward: 2777.78,
    total_rewards: 5405.64,
  },
  {
    address: '0xd1173c7b20d26f248a7D04F349EfD195b7EA09CE',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 5402.3,
  },
  {
    address: '0x5763623a45C0243daE7326C021F334e41950A226',
    minters_reward: 0,
    lp_providers_reward: 2904.52,
    swapers_reward: 2496.7908263747604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5401.31,
  },
  {
    address: '0x9CD5618C08F20BfBA4646bCf39E624E1086B70E5',
    minters_reward: 0,
    lp_providers_reward: 5315.41,
    swapers_reward: 71.64635282174831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5387.06,
  },
  {
    address: '0x81cb0f36e5b144dB75f29439AF9aAdd9cA083A53',
    minters_reward: 1215.12,
    lp_providers_reward: 1982.15,
    swapers_reward: 145.72823616888863,
    holders_reward: 1083.62,
    hoaxs_reward: 925.93,
    total_rewards: 5352.55,
  },
  {
    address: '0xd867fD8D61Fa8e2245dAF1161086020ab3826472',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 1078.9908781290828,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 5274.06,
  },
  {
    address: '0x0336beC3E4C06A8c355e73FE4fc67Af32075d019',
    minters_reward: 5265.53,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5265.53,
  },
  {
    address: '0xB6Ae3AdcCf77e7B6aBE93FECd97d9E4A8c326599',
    minters_reward: 5265.53,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5265.53,
  },
  {
    address: '0x2cb2600B3be433C8C73E702FDE928a57183074Cb',
    minters_reward: 5265.53,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5265.53,
  },
  {
    address: '0x41fc820ee3FeACA518C66C1246Fd7adb9c06D1Cd',
    minters_reward: 0,
    lp_providers_reward: 3568.67,
    swapers_reward: 1693.1925631154236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5261.86,
  },
  {
    address: '0xf5dB653Ace1976099b4032B41b41dF33240Bdf83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5225.2777747368555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5225.28,
  },
  {
    address: '0x7f4e6A872904dA76522ae579E4f7a6C625576f84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5220.838213030017,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5220.84,
  },
  {
    address: '0x5C05960853aE3dfBdFd4f747E6307958F5724C00',
    minters_reward: 0,
    lp_providers_reward: 1742.88,
    swapers_reward: 3450.827482722824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5193.71,
  },
  {
    address: '0xc834F90bC27E9Ac5E9EEA4927F3Dce0465De8a2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5149.598237084114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5149.6,
  },
  {
    address: '0x0f7f17b3635e7d53f0B1B7b6beEC9f52Ea6451E5',
    minters_reward: 1215.12,
    lp_providers_reward: 1919.52,
    swapers_reward: 1199.074398615518,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 5146.42,
  },
  {
    address: '0xbE7a950A2F630cC9FA03008F149F1Ae3EEfca7A4',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 5131.39,
  },
  {
    address: '0x847C2B158a8893F03b04e4c43aB0603074B21fAA',
    minters_reward: 0,
    lp_providers_reward: 2120.59,
    swapers_reward: 224.48713480605738,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 5122.86,
  },
  {
    address: '0xb58Eb8F96E61039710259F78ff0670CaE8d0bC1a',
    minters_reward: 0,
    lp_providers_reward: 4114.58,
    swapers_reward: 438.10537468858155,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 5094.5,
  },
  {
    address: '0x6d67B62D129F2Fd423Eb867686A1a48303D10bcd',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 22.594668051881023,
    holders_reward: 541.81,
    hoaxs_reward: 3703.7,
    total_rewards: 5078.18,
  },
  {
    address: '0x35c6432EBc042F0EC6490A6ef5F40423FD06019F',
    minters_reward: 0,
    lp_providers_reward: 3628.9,
    swapers_reward: 1435.497080740638,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5064.4,
  },
  {
    address: '0x22C67265037fA9Dd907900DBcC5Ab8E7a7529bf0',
    minters_reward: 0,
    lp_providers_reward: 2516.19,
    swapers_reward: 2471.4854119148745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4987.68,
  },
  {
    address: '0xc8d46C87757B9012C7E21fDd21B903499B293EA5',
    minters_reward: 0,
    lp_providers_reward: 687.38,
    swapers_reward: 2436.4190376792494,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 4975.65,
  },
  {
    address: '0x283c4C08Df792484f0417564BF14BE0629b4fbF7',
    minters_reward: 2025.2,
    lp_providers_reward: 538.95,
    swapers_reward: 124.26307624869943,
    holders_reward: 1354.52,
    hoaxs_reward: 925.93,
    total_rewards: 4968.86,
  },
  {
    address: '0x651C2dAd2C14c0E474A730C526a95B68292dD3b7',
    minters_reward: 0,
    lp_providers_reward: 4847.96,
    swapers_reward: 74.01533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4921.98,
  },
  {
    address: '0x372a30cD91D78B129767be232d9F283d9BABCb69',
    minters_reward: 0,
    lp_providers_reward: 2133.79,
    swapers_reward: 2754.8190108565764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4888.61,
  },
  {
    address: '0x4C8503E721E7e87b62b0D4f09d600f557CE6633b',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 9.440907504228862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4869.93,
  },
  {
    address: '0xb4765A1537de84AC8A9CE1AaFa0aAB39ED2C59C7',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4860.49,
  },
  {
    address: '0xfBe7735916E73fcb46f5f66545C5f1D6C69C43c0',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4860.49,
  },
  {
    address: '0x1a33250A0bAF81A73a5607cE528409517D95c2b6',
    minters_reward: 0,
    lp_providers_reward: 3267.69,
    swapers_reward: 1547.1214917785016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4814.81,
  },
  {
    address: '0xc2ef1173a4B051BbCc4474c407BBb2ac7E02e363',
    minters_reward: 2430.24,
    lp_providers_reward: 12.57,
    swapers_reward: 178.89962787060725,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 4788.95,
  },
  {
    address: '0x293c3Fc7cbaDeF92beF7Fc3c16751107A2DdF59a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4784.135458335499,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4784.14,
  },
  {
    address: '0x325cA972863f1E977F01810d280fff12b82ee68e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4756.530811774126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4756.53,
  },
  {
    address: '0x53d750B47d26750ebF2D7Aaa0AEC6d37CEFf7393',
    minters_reward: 0,
    lp_providers_reward: 126.14,
    swapers_reward: 4626.998150694495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4753.14,
  },
  {
    address: '0x79C805907Bc383F19C9b9716C56d34dAa1ccEF41',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 4731.61,
  },
  {
    address: '0x0DFc982BB98317f94392C252082F0bA7944fD4eB',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 4731.61,
  },
  {
    address: '0xD37ec4C923605Bd14cd83c818531610c1DC8082a',
    minters_reward: 810.08,
    lp_providers_reward: 1766.63,
    swapers_reward: 1603.1898126749822,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 4721.71,
  },
  {
    address: '0xD2878d866d325e0cD919426C8fF7de2d51Fa12A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 2777.78,
    total_rewards: 4674.11,
  },
  {
    address: '0xC1aE49D59f3557c6eC4F2398831C72B623885338',
    minters_reward: 3240.32,
    lp_providers_reward: 620.79,
    swapers_reward: 811.6472186706318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4672.76,
  },
  {
    address: '0xa4B39E41f0422Ea144a5878691F3c5DC06c8d707',
    minters_reward: 0,
    lp_providers_reward: 460.77,
    swapers_reward: 2977.2337987792744,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 4634.83,
  },
  {
    address: '0x9A631439DA1CD18b86cC10009D7EB40B1631CEcE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 4629.63,
    total_rewards: 4629.63,
  },
  {
    address: '0x04B188AB85f1Df6951d46E98F6AE875918fACe99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.07445096006790906,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 4605.45,
  },
  {
    address: '0x35f3FFA936003495c9517D59cC57f7322cd8646A',
    minters_reward: 10126.01,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 14731.39,
  },
  {
    address: '0xef3dDf16475678B7B040E87746f2A9D942361e27',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 4594.84,
  },
  {
    address: '0xD0b8743372085A086E4F86eCA8508C159a4df3B8',
    minters_reward: 1215.12,
    lp_providers_reward: 2564.94,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 4592.77,
  },
  {
    address: '0x3Db9a60cbd652600657343Fcb61785b0a5211C56',
    minters_reward: 0,
    lp_providers_reward: 3454.64,
    swapers_reward: 1126.035423632787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4580.68,
  },
  {
    address: '0x27DA921c3868376eD300c8e6bf9a36E163253Fc8',
    minters_reward: 0,
    lp_providers_reward: 4572.18,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4572.18,
  },
  {
    address: '0xa9E063572492a4AbE5E910500E4415539800cbF0',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 2262.534600978026,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 4558.63,
  },
  {
    address: '0x244CB8455263a00BBC682c99C08199641868AC25',
    minters_reward: 0,
    lp_providers_reward: 4536.01,
    swapers_reward: 6.011266505518887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4542.02,
  },
  {
    address: '0x027a9187b61e709a89C81040812cd3Fe0d86e755',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4532.878180712427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4532.88,
  },
  {
    address: '0xFB42F6E97f0FD1AFf4a3d5D1713e6D8447517d4C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4489.1848000999025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4489.18,
  },
  {
    address: '0xa392E60Caeea390Acb9bCC60509Cd211D9e2Da6e',
    minters_reward: 0,
    lp_providers_reward: 3213.74,
    swapers_reward: 1255.3070010812883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4469.05,
  },
  {
    address: '0xAED36a1347A3C417d292915936e75543364C0076',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4455.45,
  },
  {
    address: '0x2d006DF7a95121a3b8Ebe9A8b8E858CA687492c9',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4455.45,
  },
  {
    address: '0x48b06BBE93D09b6F14B5a2fF3772a0bA8721293b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4439.428546839037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4439.43,
  },
  {
    address: '0xcB4d3814384fdDb0d1e88DB6b9EBebB3894b85A4',
    minters_reward: 0,
    lp_providers_reward: 3220.95,
    swapers_reward: 1207.5599873586648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4428.51,
  },
  {
    address: '0xD8005230ba3F3f361fb5bF288E9440Cff46aDc44',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 346.16825122631514,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4401.84,
  },
  {
    address: '0xE975b410C3864f1819Af1e662098790A4cF9b04D',
    minters_reward: 810.08,
    lp_providers_reward: 2746.44,
    swapers_reward: 297.727043497205,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 4396.06,
  },
  {
    address: '0xf88487dac9e937c2ac804d8b1EbC7B2FcF8c25b5',
    minters_reward: 0,
    lp_providers_reward: 3697.93,
    swapers_reward: 417.6365685563453,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 4386.47,
  },
  {
    address: '0x7BA8174C0624c314e1d0dEb4FC8A9b59aE70BFE3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4374.18282548842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4374.18,
  },
  {
    address: '0xa684ec3a92423593D294d1A5247F8b98BE68B3a1',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 296.1389404089498,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4346.55,
  },
  {
    address: '0x7A680eFe9B478B5125564DBB4d26216c9FD4f0c1',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 293.3261546367316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4343.74,
  },
  {
    address: '0xeae527BBb284c5E514Dea821Ee900Ec216E2e944',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4335.930280628802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4335.93,
  },
  {
    address: '0xcC5FafCeBBfe8Cc40e8821b82dbcC7069A94B6c4',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 4326.57,
  },
  {
    address: '0x10c6274A3f9bFe5B794999B8dcA1d46df6ff93e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4321.867478465986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4321.87,
  },
  {
    address: '0x5644cac0dFbAcFDf6aB85578c3E0faa41819eE6D',
    minters_reward: 2025.2,
    lp_providers_reward: 933.24,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 4312.96,
  },
  {
    address: '0xd4D350e0e7730660E6CDF532d613CDfe775d6513',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 255.52569295908896,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4311.2,
  },
  {
    address: '0xc54f4B1F3E6E0b9ab4900a0D73B17Dae6d186F55',
    minters_reward: 0,
    lp_providers_reward: 4307.22,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4307.22,
  },
  {
    address: '0x53062Aa6df1142Ea0Fb7E38fE02C3f1d0c741BBf',
    minters_reward: 0,
    lp_providers_reward: 1473.63,
    swapers_reward: 2828.452908483314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4302.08,
  },
  {
    address: '0x8edb6B5D273E75C9218006e5e96F85fCB31E7A78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1519.0553395415366,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 4296.84,
  },
  {
    address: '0x0733a6E17c208EC23c738Bd18fD88BD5E7bee191',
    minters_reward: 0,
    lp_providers_reward: 315.16,
    swapers_reward: 459.7563176559796,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 4296.68,
  },
  {
    address: '0x92ddf198b2E5cFDdf9bE7B52F6Fb88aE36BfD514',
    minters_reward: 1215.12,
    lp_providers_reward: 101.21,
    swapers_reward: 0,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 4296.28,
  },
  {
    address: '0xacFA0066B4f2321116d6aAa571aAf176fEd1a89c',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 203.50780581759147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4253.92,
  },
  {
    address: '0x5C49B554F7bdF02c9640f19b38232c8228E7a777',
    minters_reward: 0,
    lp_providers_reward: 4234.51,
    swapers_reward: 9.317943432206526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4243.83,
  },
  {
    address: '0x1024B9459355E47DD2D8f803cCfa72d7A19Ef5E7',
    minters_reward: 0,
    lp_providers_reward: 2180.01,
    swapers_reward: 2059.060356281073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4239.07,
  },
  {
    address: '0x9098cdd6bc85bE58f3477aCe504d5d38BfCbf4B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4195.0560337378665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4195.06,
  },
  {
    address: '0xab2A9dDAa3F914353b0D6cB1a97Dff1c0d6Bbd09',
    minters_reward: 3645.36,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 4187.17,
  },
  {
    address: '0x6d3855A882f9E86993E410E350461Cfee96E6624',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 217.05829515622324,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 4138.59,
  },
  {
    address: '0x2B25e8d77642E6b8bE906C66254538d52611d0Ba',
    minters_reward: 2025.2,
    lp_providers_reward: 657.56,
    swapers_reward: 91.29298804656122,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 4128.57,
  },
  {
    address: '0x1448F01301E814E68008e1bE6260D4f801d71f76',
    minters_reward: 0,
    lp_providers_reward: 3802.68,
    swapers_reward: 320.90646459673025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4123.59,
  },
  {
    address: '0xb7f4547Af2C705aE90e26a4386C5C45A92a0a99F',
    minters_reward: 0,
    lp_providers_reward: 4119.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4119.11,
  },
  {
    address: '0x6298b696e5307cCcc8b01c996eE3291f261D0D7c',
    minters_reward: 0,
    lp_providers_reward: 2790.45,
    swapers_reward: 1324.485834297122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4114.94,
  },
  {
    address: '0x6C1aFC5Cc455AA9EC59C2237e282331058669429',
    minters_reward: 0,
    lp_providers_reward: 3475.77,
    swapers_reward: 633.5366331650097,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4109.31,
  },
  {
    address: '0xD2764FCA2253b9Dff244a69442C5DE342bDF1BD7',
    minters_reward: 0,
    lp_providers_reward: 3880.92,
    swapers_reward: 225.67389051492216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4106.59,
  },
  {
    address: '0x870400210760EE543aD04F70b00E66215F6d1cb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 4063.57,
  },
  {
    address: '0x25390e0C9037F301ff57749662ee38d0c6bF429a',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0x0887504aB79c74E806E1558ADb5223Cb92a77484',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0xF870D21122C7F519caf6d09a4F81Fe1E19f2Ec3E',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0xeb4B18605c4AEbcaA7B0C844cFBA1b9a6fa988bD',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0x7CD1820f9Cf4EBEe5e0114EA2594E5502aE39166',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0xA6BbFb8b81E82B5dF07C42f11583d0a6Fc330241',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0x9f2e3d5C4d2B0651BF5A81Da54Ca2B7138a5420a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4054.5130570400634,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4054.51,
  },
  {
    address: '0xA8716fAda2049Ea1941560167358d61e5D7f47D2',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0xe845e1496ffD073C9C075Da56FB7016c1f0E5200',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0x3A4BC5174fBc3dE9313FCa034D9e0E4AB86D6ECc',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0xc0eE3f4a3b898A7404487cB931a734C6e9a6FaD5',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0xF1C3977242F926BaC8Da0aFED41A3aF4fA916696',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0xf861Cb0563620baF2B5De119914d2ae828855dC3',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 2670.090570989196,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 4021.98,
  },
  {
    address: '0x646C52cDc855672fBb1d394C9A9b16f67904a053',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 1851.85,
    total_rewards: 4019.09,
  },
  {
    address: '0x3D1B3888917EC9353C3bA0b14765c294803Bd970',
    minters_reward: 0,
    lp_providers_reward: 170.28,
    swapers_reward: 3839.2830347687304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4009.56,
  },
  {
    address: '0xdF0A38e12c927a436Fe93Bb82eC95Ed6FFF27611',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 3703.7,
    total_rewards: 3974.6,
  },
  {
    address: '0xBAA99Cc903864f1e8384f67CF22fB01db68e67EF',
    minters_reward: 0,
    lp_providers_reward: 1572.83,
    swapers_reward: 2376.934170498161,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3949.76,
  },
  {
    address: '0xb8EbBe55B5C18ad0075B3B247b50Ea82385BDA0D',
    minters_reward: 0,
    lp_providers_reward: 960.2,
    swapers_reward: 587.4918522408863,
    holders_reward: 541.81,
    hoaxs_reward: 1851.85,
    total_rewards: 3941.35,
  },
  {
    address: '0x3255db9aDFe3D2c95118D58d94C6Ee98d4c98933',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 689.6480093061926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3929.97,
  },
  {
    address: '0x74Db2Ce1Aff791732E8167d105C41F754B39cd58',
    minters_reward: 0,
    lp_providers_reward: 3014.12,
    swapers_reward: 913.0564670656504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3927.18,
  },
  {
    address: '0x215A20f91Fadf2F99C285f66080642D0439bd984',
    minters_reward: 0,
    lp_providers_reward: 1622.04,
    swapers_reward: 2303.9220875702517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3925.96,
  },
  {
    address: '0xa35900D4D6124179C66Cc2caefB9DFb6268ffCeA',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 3.372546717454194,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3922.27,
  },
  {
    address: '0xA4937Fe46085b5EC73a91D380412c5777D56c0C9',
    minters_reward: 0,
    lp_providers_reward: 1685.58,
    swapers_reward: 1376.974586740776,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 3875.26,
  },
  {
    address: '0x9be283Faae3f4792740D96D677fC6c4666769420',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1073.7761729757717,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 3851.56,
  },
  {
    address: '0xe7D9d0623A5dC7807B1D7436E47AE62255dD064d',
    minters_reward: 0,
    lp_providers_reward: 3849.78,
    swapers_reward: 0.2943683931710472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3850.07,
  },
  {
    address: '0x9F010A1583F7eD30d6cD46937c023e9FB4a5F847',
    minters_reward: 405.04,
    lp_providers_reward: 2175.15,
    swapers_reward: 992.0387934779731,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3843.13,
  },
  {
    address: '0xEf16Ed3E3b6CA4b43fd7cB3363be21772Eb90F44',
    minters_reward: 0,
    lp_providers_reward: 3811.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3811.71,
  },
  {
    address: '0x9c9423fB544ED248C9d456ED85422d861D3Bd0BA',
    minters_reward: 2835.28,
    lp_providers_reward: 346.05,
    swapers_reward: 84.36669329900033,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 3807.51,
  },
  {
    address: '0x1346408290b7042b9e434096067F17153ABBc968',
    minters_reward: 0,
    lp_providers_reward: 2721.22,
    swapers_reward: 1071.9717641894144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3793.19,
  },
  {
    address: '0xAec0FAd25847188b2ef9A36ADc2033930A500c77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 3792.67,
  },
  {
    address: '0x5390d29Db2C20bE45D74e58fC012A4f3DF3347E8',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3784.76,
  },
  {
    address: '0xd3b1c15f3459c391A0159eb1aB5E918dce3d9b4A',
    minters_reward: 0,
    lp_providers_reward: 3633.03,
    swapers_reward: 142.43204413390683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3775.46,
  },
  {
    address: '0xd395AAD4dBCFcc34D7eF0619A3581754f44eed49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3770.135590676272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3770.14,
  },
  {
    address: '0xc1e1a16a9800977f778e99f72e3DA10427d8E68E',
    minters_reward: 0,
    lp_providers_reward: 1218.89,
    swapers_reward: 1066.640635609558,
    holders_reward: 541.81,
    hoaxs_reward: 925.93,
    total_rewards: 3753.27,
  },
  {
    address: '0xEF37F48ec243eD3936596A2ab443E5ae301458f3',
    minters_reward: 1620.16,
    lp_providers_reward: 54.13,
    swapers_reward: 172.58600339731947,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 3743.21,
  },
  {
    address: '0x7f684f944844c7E04294c846d88e20925b1cdDAa',
    minters_reward: 0,
    lp_providers_reward: 3714.59,
    swapers_reward: 17.47271389260097,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3732.06,
  },
  {
    address: '0x7083b1d4730f22b4A80FB38C0C94546df663e668',
    minters_reward: 2835.28,
    lp_providers_reward: 316.88,
    swapers_reward: 14.803066882861925,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 3708.77,
  },
  {
    address: '0xC80A02D9775FCd7248351D9Ca6886174DF049F9f',
    minters_reward: 0,
    lp_providers_reward: 3694.85,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3694.85,
  },
  {
    address: '0x00e2F5AC83da949D8BFaA80E822462096E0f0b66',
    minters_reward: 0,
    lp_providers_reward: 1277.42,
    swapers_reward: 1594.7599041312285,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 3684.89,
  },
  {
    address: '0xc6c79e5d759aA576a49B00A57402Be439C7B0735',
    minters_reward: 0,
    lp_providers_reward: 3672.4,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3672.4,
  },
  {
    address: '0xdFdE9A033f395Dd68adF16d8AFcaaccBDa04DE69',
    minters_reward: 0,
    lp_providers_reward: 3666.16,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3666.16,
  },
  {
    address: '0xc356219335bdfAB476317D733eD29482659F720B',
    minters_reward: 2025.2,
    lp_providers_reward: 277.47,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3657.19,
  },
  {
    address: '0xA9F282f6949C13b05EaF63D559A69f700D8dCc29',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 3647.99,
  },
  {
    address: '0x4faE2690E17f94635cC70aD4Acd42dF09136b0CF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3640.5312066261813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3640.53,
  },
  {
    address: '0x49D7dBca1b55654E9B9AF4A5a00CA337885F2B05',
    minters_reward: 0,
    lp_providers_reward: 3633.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3633.19,
  },
  {
    address: '0x5260A84A08EA84456E81668212e422dC1847c8EF',
    minters_reward: 0,
    lp_providers_reward: 3627.24,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3627.24,
  },
  {
    address: '0xAEa35d1F19473Bd0cFB126C5dbd880Ab5cfF8344',
    minters_reward: 0,
    lp_providers_reward: 1150.36,
    swapers_reward: 1373.8795678067047,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3607.86,
  },
  {
    address: '0x5fD31E1cBA20B6264C2a9f60839E95427332BeCf',
    minters_reward: 0,
    lp_providers_reward: 724.03,
    swapers_reward: 2874.748461645346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3598.78,
  },
  {
    address: '0x8056e36E003e26de864116B3F26d775996FD1A84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3572.838994715721,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3572.84,
  },
  {
    address: '0xCEc754b3c254A1d9c8176AFa5e3C977f7d5d99f9',
    minters_reward: 2025.2,
    lp_providers_reward: 189.99,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3569.71,
  },
  {
    address: '0xF0109a5B04EF6212003631C3f33D2b1CbFf96f1C',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 180.79536911605237,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3560.52,
  },
  {
    address: '0x6103A798CA2543fA72e44DAa33B209f39B858251',
    minters_reward: 0,
    lp_providers_reward: 3560.37,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3560.37,
  },
  {
    address: '0xDCFd3DBD657b4C013329E5a923312fA216f7A06b',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 179.14444654615622,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3558.86,
  },
  {
    address: '0x5D0F37d44969ac70932d16E5bA5efE7340730D4c',
    minters_reward: 2430.24,
    lp_providers_reward: 3.39,
    swapers_reward: 2.081262437890187,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3519.33,
  },
  {
    address: '0x8a3cC3463F94211acd9A2c2B1Ac6954a3a31B1EC',
    minters_reward: 0,
    lp_providers_reward: 3472.61,
    swapers_reward: 34.08126986462363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3506.69,
  },
  {
    address: '0x0D5ED9F1505dbD8940d4fB1F41227515bA7dCaa8',
    minters_reward: 2025.2,
    lp_providers_reward: 39.1,
    swapers_reward: 76.08614144571395,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3494.91,
  },
  {
    address: '0x020483457161737fB818F86c848Bde3d87F335F6',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 101.99295571367115,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3481.71,
  },
  {
    address: '0xfb935A55b0B67A6504a2afAbb198ae5F882BeF44',
    minters_reward: 0,
    lp_providers_reward: 1254.12,
    swapers_reward: 2208.425021876777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3462.55,
  },
  {
    address: '0x9D2d3e7e634c4777DCF4D40aA90612d0dD7383E4',
    minters_reward: 0,
    lp_providers_reward: 431.34,
    swapers_reward: 3006.421973274853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3437.76,
  },
  {
    address: '0x89f6B520a1715373fA21e311D6833D19b3CEC5DC',
    minters_reward: 0,
    lp_providers_reward: 2304.6,
    swapers_reward: 206.88842463705532,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 3437.42,
  },
  {
    address: '0x8f4c42482C6D11d3f82655A3658D3d4d6FD6abF4',
    minters_reward: 0,
    lp_providers_reward: 3395.67,
    swapers_reward: 40.83808455875602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3436.51,
  },
  {
    address: '0x065c6d15cbe85232aEE7300e5e3cdC6cf8420482',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3429.9756489155898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3429.98,
  },
  {
    address: '0x03b035A7B0f2dB1c647260c02f6A80E33E500f19',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 48.91944979021019,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3428.64,
  },
  {
    address: '0x3BdE68F42d8C77bB4F4A78145C0944766B84b2CC',
    minters_reward: 0,
    lp_providers_reward: 1522.32,
    swapers_reward: 1903.1793127786393,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3425.5,
  },
  {
    address: '0x6261a906C95eD6048431cFbC59c620Bce77eD9E6',
    minters_reward: 0,
    lp_providers_reward: 1854.55,
    swapers_reward: 1560.9666032891087,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3415.52,
  },
  {
    address: '0xe6BB18AeefC7e8027fa69272edA3FcF81A07581C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3403.4744524859507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3403.47,
  },
  {
    address: '0x061AD0e704847ab4ecE9A870f9C81332b7EaFe1c',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x9Ac0dE067A5971A1B3a35d86379bb42fC35bC8E5',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x58149d7AEd0d311E0AdAc9dc8a4Eb5B45f1f6aEe',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xaACEBB9982475f5eeaB2Ad240d26818e0100e04d',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xe20a37E484aC1BAa6b404Fe006770A83b4A59cb2',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x9afe62e177D10d294c0a80BbcC719B7F4Ef0af52',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x8C93eD4a308854afD21bE387570B5622caCa9169',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x9AB80F940353392eF1393f38350BFcc59DD6cBce',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x35615Dc3488C9657a537313bCAbb4D43662B9C5d',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x4264b8e4486959163B14330622362206c3d275C7',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x9Eaa0072d10fCaF6DCdabf09091C88a64eB4215A',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xE880B0d34F3AF49Fb8503Ad4971Ff882882ab12c',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x960b79AfCfc89Ece532ffF64A158c63592AcA9EB',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xDe671D2bFbDe1751f431bf7BF55009ABa642AC2A',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xe5B8D74c9ebC33CeF1911F646211Ca52213fA793',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x86F5B90934b372130b403d87BB82c4c238452454',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x4c3403dd0f426a983C405889dfC4E0aB3d5fAFb6',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xC79BAB87c2ead914A29e394Fbe28E1ef78b57115',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x8a1e82145eab037Bd4b66f9273a495fDAE3449E1',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x75eb5f87f72C2c42609Fbe83ef7436a22dCdD756',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x5ce1AC043657cE8742cfCd6EF4703854aad02E3D',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x678caE398Cb2fB96F06b05018473B8a383B329c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 158.48674537205056,
    holders_reward: 1354.52,
    hoaxs_reward: 1851.85,
    total_rewards: 3364.86,
  },
  {
    address: '0x924D868847ddb4149fd26D50D95c460176be04F7',
    minters_reward: 1215.12,
    lp_providers_reward: 434.86,
    swapers_reward: 895.6107050186959,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 3358.3,
  },
  {
    address: '0xCAAaeC0429ecbAfD882EBB7093aB768082a58e51',
    minters_reward: 0,
    lp_providers_reward: 2195.58,
    swapers_reward: 889.7598298828615,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3356.24,
  },
  {
    address: '0xE743b1760eA8a597A866f8c9C689Ea58a50aaDf0',
    minters_reward: 405.04,
    lp_providers_reward: 2669.8,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3345.74,
  },
  {
    address: '0x5bEF79De1c0CCd1c9629f92A57959F4df99D0245',
    minters_reward: 1215.12,
    lp_providers_reward: 327.13,
    swapers_reward: 47.26107978123943,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 3328.15,
  },
  {
    address: '0x7e4c1256A446D2A42DC6c26343eaC510C955C4D4',
    minters_reward: 0,
    lp_providers_reward: 533.03,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 3310.81,
  },
  {
    address: '0xC3a744584EC29001799322e7119eD99E70Eb6B42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3306.9513380054464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3306.95,
  },
  {
    address: '0x8eecF7EbAB3FA3A3443C71b3F46fdC2B5C9FDE78',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 322.32218336878464,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3297,
  },
  {
    address: '0x10494ba8B148675BE0b269bC1Fd76AD3543b19b0',
    minters_reward: 0,
    lp_providers_reward: 1616.91,
    swapers_reward: 1650.1345445832878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3267.04,
  },
  {
    address: '0x592aeF450Db8b4D9C39a4cBfcD0137b2FA70fE31',
    minters_reward: 0,
    lp_providers_reward: 1365.64,
    swapers_reward: 696.5988422786274,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 3259.07,
  },
  {
    address: '0xB951dE20f84486a9469ffF484ddaa4A7e0B5b9d7',
    minters_reward: 0,
    lp_providers_reward: 3249.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3249.46,
  },
  {
    address: '0xC19e51A53fD2457Aa4d3E3E811b5823750C9429B',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3240.32,
  },
  {
    address: '0x7c014808C2552871eD6A16fe900E851F64c1C3b5',
    minters_reward: 0,
    lp_providers_reward: 417.62,
    swapers_reward: 2797.4323490736974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3215.05,
  },
  {
    address: '0xFc139eA7A13B403b5600704da881a550A333Ed2D',
    minters_reward: 0,
    lp_providers_reward: 285.63,
    swapers_reward: 2910.3440344242067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3195.97,
  },
  {
    address: '0xD8706fC02717362A28984Cbe9B271C99D051d3C8',
    minters_reward: 0,
    lp_providers_reward: 3180.69,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3180.69,
  },
  {
    address: '0xBe95bbd10aD74733b3188A9CCE15F83f3Dc00A5d',
    minters_reward: 810.08,
    lp_providers_reward: 1000.86,
    swapers_reward: 1364.4524864249222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3175.39,
  },
  {
    address: '0xeE0443C7eF021bb299Ea5e4eB81bd6fF0945424C',
    minters_reward: 0,
    lp_providers_reward: 3171.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3171.84,
  },
  {
    address: '0xE1191342e673bA423639ad095F9D079b398f65CC',
    minters_reward: 0,
    lp_providers_reward: 3170.99,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3170.99,
  },
  {
    address: '0xe89b6D860194d1C2A0b329e959bf9445Ee77D899',
    minters_reward: 810.08,
    lp_providers_reward: 1977.8,
    swapers_reward: 110.82854448137779,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3169.61,
  },
  {
    address: '0xac8fBCD7F6C3553cc58A40a3bE4Bfb93FA073f0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3165.49622166499,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3165.5,
  },
  {
    address: '0x88b751a0AB7E91c471eACDf584ab75BD8a92EC26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3145.367883980434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3145.37,
  },
  {
    address: '0xE74623F58F75A0696B933AF7e0DFEf3dd5c94E2A',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 31.553239606943055,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3140.37,
  },
  {
    address: '0xb54Bd60d96E8f87B2A2a34F3B2b4eaB6905d7786',
    minters_reward: 0,
    lp_providers_reward: 2552.81,
    swapers_reward: 581.0599185720984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3133.87,
  },
  {
    address: '0x6D318E12972Eae289409344BFD7c18599caEFDD4',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 424.1503879589494,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3127.93,
  },
  {
    address: '0xC3a324Af00b66140f7D869cb74Eb9990b0933e59',
    minters_reward: 0,
    lp_providers_reward: 2087.44,
    swapers_reward: 764.444265929175,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3122.78,
  },
  {
    address: '0x134DbCB57a92c4dE1b19F5482dd7dc1e085B084c',
    minters_reward: 0,
    lp_providers_reward: 935.9,
    swapers_reward: 2186.82671155577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3122.73,
  },
  {
    address: '0x50F1C74fF0934e0915a277E02A1438812a45Bd85',
    minters_reward: 0,
    lp_providers_reward: 2769.01,
    swapers_reward: 342.05973452790806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3111.07,
  },
  {
    address: '0x910dB2813DF3BCaf370290736a4696D9ffFB6d65',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0.32993690109106844,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3109.15,
  },
  {
    address: '0x5D5b0B967a9e006Af9737FFC37902f80BB23CEb3',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3108.82,
  },
  {
    address: '0x0c17144e3fb17a7f6AEE82008386f53B498D37Cb',
    minters_reward: 0,
    lp_providers_reward: 3098.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3098.71,
  },
  {
    address: '0x960448De8c3D33AEd56E29b3d71C54606dBE2c09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3098.2870659142304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3098.29,
  },
  {
    address: '0x14c0E0b5eC526f8B42C4E84e0118646Fe5E5FD44',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3097.8098594630064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3097.81,
  },
  {
    address: '0x808DA8Bb95C2e3282eD35077143706fCF5e03722',
    minters_reward: 0,
    lp_providers_reward: 1373.01,
    swapers_reward: 1704.9297337460691,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3077.94,
  },
  {
    address: '0xFC968a2eb2185eD98e8C4f2535FBbc4A2460E443',
    minters_reward: 0,
    lp_providers_reward: 3057.17,
    swapers_reward: 1.7003446704545535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3058.87,
  },
  {
    address: '0xf5d0F1182996faA744346b5a0680821d4ca1D187',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 2777.78,
    total_rewards: 3048.68,
  },
  {
    address: '0x6925DB439640E52EB2449C0411117A3050a3De9C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3033.5945066998106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3033.59,
  },
  {
    address: '0x59889B1835C9B4cfCbb9aeC1264bAba201F13D75',
    minters_reward: 0,
    lp_providers_reward: 3029.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3029.15,
  },
  {
    address: '0x51559d8186CA93e8A5cbA0d28De9EB722afFDbc3',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 726.3194319890866,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3022.42,
  },
  {
    address: '0xAF9EeFDf361063301c58625387e88C3421dBA49f',
    minters_reward: 0,
    lp_providers_reward: 1843.85,
    swapers_reward: 1176.426003283576,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3020.28,
  },
  {
    address: '0xe35Ea80a9D9FeE2C52Df187527709572D5D4B37B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.64686126430361,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 3007.6,
  },
  {
    address: '0xFbbD3a8DA2022db08d11D443528F7B5f09534313',
    minters_reward: 0,
    lp_providers_reward: 2983.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2983.94,
  },
  {
    address: '0xd9998Ce41599FFE232fcED928e82a0c6f70ADCDb',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 2977.32,
  },
  {
    address: '0x3FC3d51abCF05d61fe3D67B2Ce613Bb617a93C9A',
    minters_reward: 0,
    lp_providers_reward: 1781.89,
    swapers_reward: 100.13103923447623,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2965.64,
  },
  {
    address: '0xad61f4E0445025895e7A2C9E527da6E498828Fef',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 257.8848373221531,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2961.66,
  },
  {
    address: '0x14Fb8e37533fC1A49ebF477caAa8D41c765b3f2F',
    minters_reward: 0,
    lp_providers_reward: 2952.14,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2952.14,
  },
  {
    address: '0x2dB062D7d4d8CDf6E9D169EF61Ec81Bbd3E0748e',
    minters_reward: 0,
    lp_providers_reward: 2948.67,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2948.67,
  },
  {
    address: '0xA3f7deEF9dFE7c10117d836237d173eA20302A5d',
    minters_reward: 0,
    lp_providers_reward: 2942.64,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2942.64,
  },
  {
    address: '0xb4Aab4AE3C36AD9A8Ab84e57954F6723149Bb4AB',
    minters_reward: 0,
    lp_providers_reward: 24.87,
    swapers_reward: 2913.142937732179,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2938.01,
  },
  {
    address: '0xDe696a15143EcAC5f5BCD56cc717d544B1a56C07',
    minters_reward: 0,
    lp_providers_reward: 2888.29,
    swapers_reward: 48.34675060728067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2936.64,
  },
  {
    address: '0xA4bD6a9220FF24287a74e99d116f0CFF24d212aE',
    minters_reward: 0,
    lp_providers_reward: 1696.64,
    swapers_reward: 1239.0120575377862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2935.65,
  },
  {
    address: '0xf0183578D77F70a61C2b9462458f737bc4c1C3F4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 1851.85,
    total_rewards: 2935.47,
  },
  {
    address: '0xf450cf54324457B1764e36618a613819355b77D4',
    minters_reward: 0,
    lp_providers_reward: 2764.51,
    swapers_reward: 163.5688862170653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2928.08,
  },
  {
    address: '0x530e444938759Be600cd21337b357B9B71ef46BA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 132.83007000112312,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 2910.61,
  },
  {
    address: '0x0695f17bA74BD06Ae6c7F576F2AAd2BA82164BB7',
    minters_reward: 0,
    lp_providers_reward: 783.07,
    swapers_reward: 2125.885335756699,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2908.96,
  },
  {
    address: '0x46F66240Fc9a9a5fCb279c0F23eda3C73234E958',
    minters_reward: 1215.12,
    lp_providers_reward: 432.39,
    swapers_reward: 428.4373349939522,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2888.66,
  },
  {
    address: '0x95d3b4C79c1B947B960EAac89D9d7861BD4436DD',
    minters_reward: 0,
    lp_providers_reward: 2824.51,
    swapers_reward: 53.691376538529234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2878.2,
  },
  {
    address: '0x311674E34D19787f3d5B31950039C8fd718b7146',
    minters_reward: 0,
    lp_providers_reward: 2874.05,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2874.05,
  },
  {
    address: '0x04c3157913E5cbD35CB9CB293731dE8463691E9D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2054.3846246581325,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2867.09,
  },
  {
    address: '0xFFF409DB8A6Df5317859B2eEc93ceB5c32819855',
    minters_reward: 1620.16,
    lp_providers_reward: 110.07,
    swapers_reward: 53.101107045652,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2866.95,
  },
  {
    address: '0x69C96785c6cb97d2b319662C7182488918Fb7fcC',
    minters_reward: 0,
    lp_providers_reward: 2653.87,
    swapers_reward: 198.24540962513638,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2852.12,
  },
  {
    address: '0xF13C3a62257A3350EB7f2826153AD54404481B96',
    minters_reward: 0,
    lp_providers_reward: 1445.52,
    swapers_reward: 1396.5189588958203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2842.04,
  },
  {
    address: '0xC31f7f55Ec62Ed6d9A628E2deEc8174EF57c0915',
    minters_reward: 0,
    lp_providers_reward: 441.83,
    swapers_reward: 547.9117596044691,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 2841.59,
  },
  {
    address: '0xa087365bc8c99836aA5134a0C1F00C258cf62eE8',
    minters_reward: 0,
    lp_providers_reward: 1223.03,
    swapers_reward: 1613.4527840235191,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2836.48,
  },
  {
    address: '0x84843B0114090fd22Edf908b78D20262437e4096',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2835.28,
  },
  {
    address: '0x2538Dc2a54214f5A25C4ce7EFf4f03765E18244F',
    minters_reward: 0,
    lp_providers_reward: 2831.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2831.1,
  },
  {
    address: '0x2B63Ef5D5E7d3B9c6939010e89fF9487c454EcF2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2821.5062520474594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2821.51,
  },
  {
    address: '0x665f3964A9841bb510a7fE861bA05EcF24f695E6',
    minters_reward: 0,
    lp_providers_reward: 2076.92,
    swapers_reward: 453.09647940199505,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 2800.92,
  },
  {
    address: '0xa1952db7D21E45C49A768C7cA143167abDa54660',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 76.7340884653281,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2780.51,
  },
  {
    address: '0x7723000de847d13856Aa46993e6D1d499D13af1B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 2777.78,
  },
  {
    address: '0x7dC5467C0911755496b9343E81Af716014a784f2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 67.98634528024347,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2771.77,
  },
  {
    address: '0xC4FD0E63C863bA9f6Cb1dF9AB218060b94a25F91',
    minters_reward: 0,
    lp_providers_reward: 2179.85,
    swapers_reward: 582.0755803749394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2761.93,
  },
  {
    address: '0x5802e1F830D0E460e07544923c39C0Bb538d090b',
    minters_reward: 0,
    lp_providers_reward: 2738.68,
    swapers_reward: 0.04197195301403278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2738.72,
  },
  {
    address: '0x3728c0D4D11D3076ae5EA01360ef62217a25FBDA',
    minters_reward: 0,
    lp_providers_reward: 2627.63,
    swapers_reward: 102.98426558570894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2730.61,
  },
  {
    address: '0x12B91287560e8f5CE6eBB8740085B62F83a51FeA',
    minters_reward: 0,
    lp_providers_reward: 2729.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2729.94,
  },
  {
    address: '0xBee1261D36B2CC89452e5ED270Eac282adea8123',
    minters_reward: 0,
    lp_providers_reward: 11.75,
    swapers_reward: 6.979550141388677,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 2727.78,
  },
  {
    address: '0x490ae54C76c09e59De9e7F2cEc8F3d428963D633',
    minters_reward: 0,
    lp_providers_reward: 118.68,
    swapers_reward: 2604.030157575698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2722.71,
  },
  {
    address: '0x9E667903C73884064FC7BcE6bE6B9967644262eB',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 15.24932774572979,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2719.03,
  },
  {
    address: '0xb69813d917bd8e7B0d016742D346081A930647A1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 2709.05,
  },
  {
    address: '0x2a7EA4A0C834371b0338Dc663EEB95C85344f81b',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 4.9797808295486234,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2708.76,
  },
  {
    address: '0xB599AF4106270937544072Bc3F498AA47E555735',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0.36782808806500117,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2704.15,
  },
  {
    address: '0x2Ec2330045D5Ef04bE19DB405B07FDc9d2E178a2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x51d845648FC85ef7cC90fEdd20D86872b17aE7a7',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0xf1c26989591031f6Eb38792C540d60eDc4d52972',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x90229e01D680eb5285f0B6341aa222d720bD2314',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x4bddF582a245d2eABB1Bb335d922c7eD9FAD19B2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x8e025267A659adc863bC7B0A52a1bA74AcBAadAA',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x30cD700ab29F52786bd9Bfd8a4a5e1587878d11e',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0xf7a1b59819C3cE8efC5D71927E19C9e159D80ADa',
    minters_reward: 0,
    lp_providers_reward: 2676.65,
    swapers_reward: 9.771880513067023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2686.42,
  },
  {
    address: '0x8d196504c6007153ae74655964327EfF5744123C',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 109.87521993008207,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 2679.52,
  },
  {
    address: '0x8383297413893c7c3952282455068fBF4Fa1bcc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2655.911164546972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2655.91,
  },
  {
    address: '0xe9B8Ba5a96D92a42b82DCe057C4c7E904174AA4c',
    minters_reward: 0,
    lp_providers_reward: 2391.59,
    swapers_reward: 256.9887011627693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2648.58,
  },
  {
    address: '0xF793950eF6405f8e3c293C0ca81608ab7A527D32',
    minters_reward: 0,
    lp_providers_reward: 886.2,
    swapers_reward: 562.038496294485,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 2645.07,
  },
  {
    address: '0x4858DC291Cf11808271c07236200D4302868dD09',
    minters_reward: 0,
    lp_providers_reward: 846.75,
    swapers_reward: 1788.3464188847483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2635.1,
  },
  {
    address: '0x1F7158Fa98FFd75AdD995254efd4a345e1b65207',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 603.2560519093522,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2631.09,
  },
  {
    address: '0x2C7e8EF43c64982CDFd9dc1b78b7a8eAeD1D7677',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2623.0815407269843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2623.08,
  },
  {
    address: '0x9684b216D18c3862025EA28877017393a3f0fC14',
    minters_reward: 0,
    lp_providers_reward: 1571.7,
    swapers_reward: 1047.887828948203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2619.59,
  },
  {
    address: '0x30B18f0cB0c3a0E83f1442f215578C4Fc8802C6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2619.2161413797444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2619.22,
  },
  {
    address: '0x4500E166BA2C9FA90003E3c044Aea3b17fCacf53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2609.9510000225728,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2609.95,
  },
  {
    address: '0x457aa5441cc5bF2F6439f7CA842B7a1b52227fa4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2600.5136251007184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2600.51,
  },
  {
    address: '0xBEfd314fAe2F56ddb9B8e6Edbd3Ece9ec7acB4D9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 966.8024965813473,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 2592.23,
  },
  {
    address: '0xa6fE60F9cBCAE2556ef6faCF3b1a9c5042b9ef34',
    minters_reward: 0,
    lp_providers_reward: 1252.08,
    swapers_reward: 1337.3624464126613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2589.44,
  },
  {
    address: '0xc151381195F8baEAb85fe5DBae5D0E35b5c2C3cb',
    minters_reward: 0,
    lp_providers_reward: 930.11,
    swapers_reward: 1645.2429581826996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2575.35,
  },
  {
    address: '0x4aA15d4E00D8661cF1031D3403376eB6b0f77F95',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 2569.64,
  },
  {
    address: '0x8A0113c1C9B0b0FB4529f1F7E7Ce40410158f56a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2566.915337893086,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2566.92,
  },
  {
    address: '0x9A4040F4f23D4958ca2DF55f864D6e7F3C3579a5',
    minters_reward: 0,
    lp_providers_reward: 5.9,
    swapers_reward: 2545.994890290751,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2551.89,
  },
  {
    address: '0x12F34C50F7dbeCdbCBE65AAf297DcCd9e56a8E0F',
    minters_reward: 0,
    lp_providers_reward: 2513.91,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2513.91,
  },
  {
    address: '0x0b4F59F204f6011bB2cAe02f3562252f10eb3802',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 483.65228035169554,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2511.48,
  },
  {
    address: '0x2Ee6e04683Fbf399852387046886677c1A5739c3',
    minters_reward: 1620.16,
    lp_providers_reward: 85.95,
    swapers_reward: 797.6959005924906,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2503.81,
  },
  {
    address: '0xa2067165dBC7e4aE89Fc9C476376C1fC6d8B50a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 486.660142630649,
    holders_reward: 1083.62,
    hoaxs_reward: 925.93,
    total_rewards: 2496.21,
  },
  {
    address: '0x4F03615E1775c2173Ad483CbB38E63c5B8984f47',
    minters_reward: 0,
    lp_providers_reward: 2492.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2492.9,
  },
  {
    address: '0xFb89b7582DD4B971a6a7DA1d052EEA6990E636b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2488.883714154514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2488.88,
  },
  {
    address: '0x7A8653571A0f36f837aa475bc3d6AC083531d7D3',
    minters_reward: 0,
    lp_providers_reward: 2446.68,
    swapers_reward: 15.147649070420439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2461.83,
  },
  {
    address: '0xfDD57cEB9d40A847CD7A3c06D331aaF3A1BfCcC1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2461.4558593171705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2461.46,
  },
  {
    address: '0xC53e70A0DBA5fC94d9fb5e14b84a5B3EA36fc856',
    minters_reward: 0,
    lp_providers_reward: 2457.55,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2457.55,
  },
  {
    address: '0x54c219F0f24AA795f6a18e8fD99A84C858252Bc0',
    minters_reward: 0,
    lp_providers_reward: 2039.61,
    swapers_reward: 410.84085330304174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2450.45,
  },
  {
    address: '0x6730Cb283805E3F4907C69c7e6De9C1bEFd27835',
    minters_reward: 0,
    lp_providers_reward: 643.5,
    swapers_reward: 66.58218481345048,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 2448.72,
  },
  {
    address: '0xe2eE9c5e9Cdc329e7eC12f0Dc53aC0dc3a06cB98',
    minters_reward: 1620.16,
    lp_providers_reward: 13.74,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2446.61,
  },
  {
    address: '0x26AeCC46D6689ba43a41d1429e93896562216FE0',
    minters_reward: 0,
    lp_providers_reward: 2443.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2443.42,
  },
  {
    address: '0x5Ef993d9EBEAC17baAf0e6B37c5e54c756d867B6',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 13.065754821404196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2443.31,
  },
  {
    address: '0x96379898ADAfb0bff44dDDcC014B805b44D347b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 2438.14,
  },
  {
    address: '0xE041cFDD86ba24eA14d6b2328b443e826A0969aC',
    minters_reward: 0,
    lp_providers_reward: 2435.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2435.84,
  },
  {
    address: '0xda1f41F246Fd7DdC2BdE3582e3a0AA45139aF1B2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2432.87,
  },
  {
    address: '0x5Caac568a988cb43B5C92aB32b53c58b4ab02Ff2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2432.87,
  },
  {
    address: '0x9A794Df76C2d1992F04C37bD5E02Cf74f435a5Bc',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 1.63916886632315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2431.88,
  },
  {
    address: '0xe098843E1C6Afda7b90D10AC4eB7bC7063cd186d',
    minters_reward: 0,
    lp_providers_reward: 1.9,
    swapers_reward: 2428.3494686301597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2430.25,
  },
  {
    address: '0x5D5bADf0C09Cc5a14E6FCCbA1a0fA54Eb1a3fdbB',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2430.24,
  },
  {
    address: '0xECf0cE6b1f7506544b7965c28E6cc27367680017',
    minters_reward: 1620.16,
    lp_providers_reward: 213.12,
    swapers_reward: 587.1343724835577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2420.41,
  },
  {
    address: '0xEEfca716307f3472D3d3eAeb1bDeCe025188e593',
    minters_reward: 1215.12,
    lp_providers_reward: 225.06,
    swapers_reward: 147.79788582698066,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2400.69,
  },
  {
    address: '0x0734d15428e6f93DBE65b886Eccf05B7fc151F6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2389.3508489827436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2389.35,
  },
  {
    address: '0x61f1230db5d1709A021358baC7541374DF4b5DD2',
    minters_reward: 0,
    lp_providers_reward: 978.16,
    swapers_reward: 1404.1011778631546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2382.26,
  },
  {
    address: '0x1E923007687CFd53a5e3314B76e30AfAedB03cf0',
    minters_reward: 0,
    lp_providers_reward: 2379.77,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2379.77,
  },
  {
    address: '0xAa6Ee5f1ad07E532711E7f38f79313B5397e034a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2106.996333040613,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 2377.9,
  },
  {
    address: '0x1b6c7d9Bccf3f6B2B1c6303ACB556526C4b7107d',
    minters_reward: 0,
    lp_providers_reward: 2377.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2377.25,
  },
  {
    address: '0x92010c09E38125C4b40E69aEfCbA318d84Db6250',
    minters_reward: 0,
    lp_providers_reward: 1406.79,
    swapers_reward: 969.4880313705961,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2376.28,
  },
  {
    address: '0x4Bb66561E90F677a378B898C2DDAF57E4534F513',
    minters_reward: 0,
    lp_providers_reward: 2370.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2370.66,
  },
  {
    address: '0x1a4446C2ffB3d6CF2Adfe96d26cC9f326DF881E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2353.836410644376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2353.84,
  },
  {
    address: '0x59b3Ac671Fe63b3749c5dB934cECe7d3363F0D4a',
    minters_reward: 0,
    lp_providers_reward: 652.21,
    swapers_reward: 1696.5151698857806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2348.73,
  },
  {
    address: '0x71E9f694C2e6cce05D55f6e2152f45bd74b88044',
    minters_reward: 2025.2,
    lp_providers_reward: 104.58,
    swapers_reward: 216.2133195422183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2345.99,
  },
  {
    address: '0x49D8aa21b3382BCfcBEB49C11451B7435404F0bf',
    minters_reward: 810.08,
    lp_providers_reward: 1502.26,
    swapers_reward: 1.2947215965791234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2313.63,
  },
  {
    address: '0x26217D520485aE1A9F8Cb8418847Ae2cFBF9188c',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 282.14830548613725,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2309.98,
  },
  {
    address: '0x3Ed32657c263B9e38375F5DC155E844335d486c8',
    minters_reward: 1215.12,
    lp_providers_reward: 59.77,
    swapers_reward: 222.35373207676403,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2309.95,
  },
  {
    address: '0x272C7831D8df272ac1413CAeD1bE6385BEDf8606',
    minters_reward: 1215.12,
    lp_providers_reward: 273.96,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2301.79,
  },
  {
    address: '0x1b0f26664C1a1E2cE014270Bb2EbC23A18f021F0',
    minters_reward: 0,
    lp_providers_reward: 2300.21,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2300.21,
  },
  {
    address: '0xa4a30711fBc0905573D68378E2005F2390eFF30F',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2298.74,
  },
  {
    address: '0xc26A1e619D956Fda64B6bDD7c76efa8582828EDf',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2298.74,
  },
  {
    address: '0x010c9F17DC4d0Ef8A140B59b1301C01298f4b1b7',
    minters_reward: 0,
    lp_providers_reward: 1116.14,
    swapers_reward: 1172.2413808552894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2288.38,
  },
  {
    address: '0x241c7809E31ae711805fEB8082A926c708be13D2',
    minters_reward: 0,
    lp_providers_reward: 1726.39,
    swapers_reward: 560.8169247827417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2287.21,
  },
  {
    address: '0xc046EE83741C5cF638dc3C3fD52862db7bc6bCd7',
    minters_reward: 0,
    lp_providers_reward: 995.55,
    swapers_reward: 1290.279459137316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2285.83,
  },
  {
    address: '0x7558cd06F3f53391e50D093eE000266B685FC875',
    minters_reward: 0,
    lp_providers_reward: 503.11,
    swapers_reward: 567.8779129789249,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 2267.82,
  },
  {
    address: '0xDD7A10CD48044C3a724Ba73e143F410Cc4Ef34f5',
    minters_reward: 810.08,
    lp_providers_reward: 913.51,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2265.4,
  },
  {
    address: '0x9DEDa34f2302c96372e1D634bEAB5b2bBd308536',
    minters_reward: 0,
    lp_providers_reward: 1908.17,
    swapers_reward: 356.28028255853366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2264.45,
  },
  {
    address: '0xb4596dF3F7469Bc117922DE384fc7410Ce9b370d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2263.1994001589587,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2263.2,
  },
  {
    address: '0xE9b42E395E375bB65D38403eeC2A353D9e28E0d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2262.879935518319,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2262.88,
  },
  {
    address: '0x8279b1D1c8aa23e2dc7c99f657679DdDA3F433f6',
    minters_reward: 0,
    lp_providers_reward: 1010.38,
    swapers_reward: 1251.960030132123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2262.34,
  },
  {
    address: '0xd784E0c2A104685B6E66924c2C2AA723ceFb9514',
    minters_reward: 0,
    lp_providers_reward: 485.74,
    swapers_reward: 567.7611156421258,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 2250.33,
  },
  {
    address: '0x8251A2ee15fb7E9D87a822B1e0F3E2d7fa36F5fb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2244.264055850227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2244.26,
  },
  {
    address: '0xE058034A671f6732A38499994AD14A80eb8FC93C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2237.2569656500837,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2237.26,
  },
  {
    address: '0x950bd21Ab0D2911288cA37D6B94B26A826713357',
    minters_reward: 0,
    lp_providers_reward: 979.97,
    swapers_reward: 1256.7630161308002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2236.73,
  },
  {
    address: '0xf2490a9b571d18B1B84A3C22EeadcB8D6891af3a',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 205.62081175546686,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2233.45,
  },
  {
    address: '0x0c363081A03842781ECF96350a81dAc6C1dd2c9e',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 201.67936811094128,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2229.51,
  },
  {
    address: '0xF6D64C3f1d48dc73b179DEb8b29D3EB9BF4A68d0',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 465.12087052568364,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2222.05,
  },
  {
    address: '0xa31C98406897120a669D9c168F4600d616D92c3f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1291.147277988865,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 2217.08,
  },
  {
    address: '0xCF628285969171060fc5B86BeF9367c19c6c3F6F',
    minters_reward: 0,
    lp_providers_reward: 1621.88,
    swapers_reward: 587.7383991089548,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2209.62,
  },
  {
    address: '0xbAD4A7e54D283d3f7c821e98C1AAcAf41113ca5E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.760425227131556,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 2204,
  },
  {
    address: '0x331065Fc55F5eE0896FB1aBc712fF0f0C4e839F7',
    minters_reward: 0,
    lp_providers_reward: 850.81,
    swapers_reward: 1080.7222096965256,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 2202.43,
  },
  {
    address: '0x9217AAdA66F94C3D0B6041bc86b90DB8998c6C97',
    minters_reward: 0,
    lp_providers_reward: 799.44,
    swapers_reward: 1390.5604148531088,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2190,
  },
  {
    address: '0x195B2554DDA2d0D39E3eEF94fbf819c107484fa1',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 158.86045648795462,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2186.69,
  },
  {
    address: '0x037AfD9B6d7b05beED5324f601fd2757acF540C9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2184.0792348797127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2184.08,
  },
  {
    address: '0x561eb00cEC8Af529e21e40b2846847A3e8A6A7F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 327.6893259186624,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 2179.54,
  },
  {
    address: '0x2D963CB19EeeAbc23eaf2F4aE6b5Cc90B7BD9Bc7',
    minters_reward: 0,
    lp_providers_reward: 2162.82,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2162.82,
  },
  {
    address: '0xd793e05Ea4a48B8fb4Eca4Ff9B4a676011DAC64D',
    minters_reward: 0,
    lp_providers_reward: 1643.01,
    swapers_reward: 243.82551985252803,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 2157.74,
  },
  {
    address: '0x74E3262fC95280eB9e22a21Ac668359A86dBe9a6',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 129.34292764180515,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2157.17,
  },
  {
    address: '0xfaAcFf69d0a13cA156c8740B8bD37453456461b9',
    minters_reward: 0,
    lp_providers_reward: 1968.66,
    swapers_reward: 183.25619097684324,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2151.92,
  },
  {
    address: '0x4D7C8F733cDe83AE7AC2511CB940933599370f62',
    minters_reward: 0,
    lp_providers_reward: 1839.59,
    swapers_reward: 310.6206423354066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2150.21,
  },
  {
    address: '0x93552122D90C30cd013D2Dfe574e3C156396f591',
    minters_reward: 0,
    lp_providers_reward: 2148.97,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2148.97,
  },
  {
    address: '0x1000513c90c42DA43413CF060578702cc74Aaacb',
    minters_reward: 0,
    lp_providers_reward: 435.69,
    swapers_reward: 1166.0089742388095,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2143.51,
  },
  {
    address: '0x41c540320AA7040aEa649845bd0fb7E49deEa8c0',
    minters_reward: 1215.12,
    lp_providers_reward: 135.25,
    swapers_reward: 238.03226717055645,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2130.21,
  },
  {
    address: '0x27c523Cfa09cE654E706077b46C57EEf9C9e5880',
    minters_reward: 0,
    lp_providers_reward: 498.84,
    swapers_reward: 1624.5306276095653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2123.37,
  },
  {
    address: '0xeeb6357f68a5ccBfd8c8D564FD88aFC8DbFcc648',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2118.7940883168935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2118.79,
  },
  {
    address: '0xAC2D528eD9ADd1ED761AC06BaB3dD8fB40792189',
    minters_reward: 0,
    lp_providers_reward: 571.3,
    swapers_reward: 450.9618774580983,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2105.88,
  },
  {
    address: '0xc534f8110086725809ce7F9205F150779609a151',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2105.5571886748535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2105.56,
  },
  {
    address: '0xB8A16e7ad99A7c05692f11f2AbB5C0370521bF41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2102.7709041580747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2102.77,
  },
  {
    address: '0x9F49b14950CC6B52e25a633ABC646C13A603fa97',
    minters_reward: 0,
    lp_providers_reward: 2097.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2097.86,
  },
  {
    address: '0xCb951993b70057de4151446e573073Be23f7B9BF',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 69.4829496642661,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2097.31,
  },
  {
    address: '0x25e73Ad99d960c1f96D2609A9d9862bc89072ea6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2096.686695664932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2096.69,
  },
  {
    address: '0x3953106a34ae4e656d8A2AA1038967B8122095dD',
    minters_reward: 0,
    lp_providers_reward: 1852.91,
    swapers_reward: 229.84646791751035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2082.76,
  },
  {
    address: '0x6714625C3308a33b9A767dAb49a34844CDef0107',
    minters_reward: 0,
    lp_providers_reward: 2081.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2081.76,
  },
  {
    address: '0xD69b9F96177ee1534C9295C4Ef4eB70bDA4dD7F7',
    minters_reward: 0,
    lp_providers_reward: 2079.18,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2079.18,
  },
  {
    address: '0x7DFb326e6D44D2c9e296C1BAAc22fF81f8310003',
    minters_reward: 0,
    lp_providers_reward: 1403.57,
    swapers_reward: 668.2192210194514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2071.79,
  },
  {
    address: '0x1fdEbE3C40972E6a7838B3d3eB72881E5FDF18E8',
    minters_reward: 0,
    lp_providers_reward: 1777.11,
    swapers_reward: 286.5275407877692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2063.64,
  },
  {
    address: '0x783DBAaf1fa8E5Ac404e576cC038a0012749f043',
    minters_reward: 0,
    lp_providers_reward: 2056.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2056.25,
  },
  {
    address: '0xcBE9bD7B1357Ba350F521526A786D7dD6Dd37BFb',
    minters_reward: 0,
    lp_providers_reward: 1418.96,
    swapers_reward: 634.0964234976916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2053.06,
  },
  {
    address: '0xAd503ECCD35724a6f463bFD47C58E57eDA7892cb',
    minters_reward: 0,
    lp_providers_reward: 2053.05,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2053.05,
  },
  {
    address: '0x31EBEDebb0AaC3ebAaC8230E2fcF59E7deD62E2b',
    minters_reward: 0,
    lp_providers_reward: 1273.42,
    swapers_reward: 775.6579385000898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2049.08,
  },
  {
    address: '0xaF7aA4cF4E10941C285817DF14EDf247a00a80fc',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 15.292287182665143,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2043.12,
  },
  {
    address: '0xa26f68aC2A90e6363E64Bc55960f2D75d2dDf730',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 12.283211788581228,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2040.11,
  },
  {
    address: '0x21A590A6ca71A19918c009579beB7e551aC43F6A',
    minters_reward: 1215.12,
    lp_providers_reward: 7.35,
    swapers_reward: 3.534154509161336,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2038.71,
  },
  {
    address: '0x1798F42CAd4e53913046e6C62E75C218258686f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2033.3647327763306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2033.36,
  },
  {
    address: '0xed99CC7d9D491B26966F05329FEadf5F49D2C160',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 7.2480638348043165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2032.45,
  },
  {
    address: '0x6f2c40252c301648393816A1fBdCCb7A19b7702f',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 2.846812866322788,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2030.68,
  },
  {
    address: '0x7De1dF3e283Bf920f1c4574141feCac0462384B2',
    minters_reward: 810.08,
    lp_providers_reward: 678.56,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2030.45,
  },
  {
    address: '0xA8d2C1178A9b6B98De5530bBF8DBfCB6fddf8f93',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x834fe96456115029871860eE88db1d4e12d8Eb1B',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xAfFB16ed9Da2e415E0f1A9e4d2EE065B4E3cCe9B',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x2C1f2767670645e1d7f0Da8aB9fe3AC7875AF00B',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xCB00d822323B6f38d13A1f951d7e31D9dfDED4AA',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xfc241EbB1186fEA483a0d4E5EC1476F0ba368Ea0',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xF05B327E5a49Bbc83eDe53749313f7E61a3a05F3',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x09EBd3F96BDE4ecEa73c906E81BDf858fD15e508',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x5b5121690b9C72283205A62CeDDe81fE84CB1A9b',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x762D5548ea30BbF09ADb153d0a0ceaE75AB418cf',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xEF24B9D608A81613BEA24B67ad7DE190Ccf606Be',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x0e39ae909E395debB589c639257aabb36CF670B6',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x689Ec53984503bda7dbcCA4ce2EA006A3839928a',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x72B784428623413855e6bE85C7EFCe0a4Ec38B54',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xDF861b73cDe3f062b3e7D6A4e42b5A745b758191',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xBCc00Ee6D420943B675AEd5B3bb52558dDd7Ca1b',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xE545BE4690D6d39A9E2301889f181fcc41eB1b1C',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x5cA1699468880Bef5f8A56cdC97a9BD2de11A7D6',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x1172e85dfB32FD7f418699cDE854f542a0254443',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x08fA7B7451D57D7Abd57a76587cC1EA14Ca14a1E',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x9dabc608F8aa8cfEbB7f81663804A0932a91c95c',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x8a69d9278D6ff6aF5aC0a534D6103862Bb2D342E',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xD200ffeEB88D88cDaDaaba9F3DD9f5E31c77d54a',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xaBe44eF1548A92424165371123d41E21146f14e0',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0x9D7B96A8c10d53b226a9d924c3dEd37D9d21C0C8',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0x0DC13FeFc0b21c139a12C849421c16E6bb9A70d4',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0x57e388eD18360c11BedFF63a5aC461704FE9A8E5',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0x9F607bC8Cba9d6678d32Ab4fc2C0259daD6e03c1',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0xA461a0F6598767ce55DeA88AC51F3ff510bF55ad',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0xA087C1638e9bf6BB55fdf642FA0d53DE87aA1A06',
    minters_reward: 810.08,
    lp_providers_reward: 564.29,
    swapers_reward: 107.10534588510453,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2023.29,
  },
  {
    address: '0x1ED68F3a345be78C43613762076A4fAB69132c9a',
    minters_reward: 810.08,
    lp_providers_reward: 920.89,
    swapers_reward: 282.09203709234623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2013.06,
  },
  {
    address: '0xeB40F3185A08278141b12cf20A9d0bEA6447Bda9',
    minters_reward: 0,
    lp_providers_reward: 1328.18,
    swapers_reward: 681.4357594026957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2009.62,
  },
  {
    address: '0xf7Bf76d355f1ecc958030497E08f3c3De78b208F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2001.3775121973508,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2001.38,
  },
  {
    address: '0x672EAfBddd3CfE88eb85cfa899bBB525E039e5EC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1995.6676712831784,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1995.67,
  },
  {
    address: '0x5B43DFEc0b2D938660cd9E22F0415E8767A42F69',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 234.8462124985087,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1991.78,
  },
  {
    address: '0x91B5A622d6C97a850CDEa11DB2262a46ecbDdCB9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1985.7003407107168,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1985.7,
  },
  {
    address: '0xcDa7fb02af0b66a46CA3BE20E2f289290Eb470d8',
    minters_reward: 0,
    lp_providers_reward: 1879.02,
    swapers_reward: 103.22489584647478,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1982.24,
  },
  {
    address: '0x203a2b4c94Bb375Cf1F5e51Be1711922615f2ab6',
    minters_reward: 810.08,
    lp_providers_reward: 627.44,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1979.33,
  },
  {
    address: '0x1609BA15025caa185Ed284d6Cdd71C71d3bEc360',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.20402206573971,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 1951.05,
  },
  {
    address: '0x75ECe9B12e18B1D01F87c0ee2204e4BF160a9036',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1931.2325631510316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1931.23,
  },
  {
    address: '0xf9861B7eb028251a017a465081Df75fD14AAaAec',
    minters_reward: 0,
    lp_providers_reward: 1920.83,
    swapers_reward: 4.40930511385512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1925.24,
  },
  {
    address: '0x6EFD09a691A36D6B0643e935682b2C453a5d32B1',
    minters_reward: 0,
    lp_providers_reward: 1923.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1923.59,
  },
  {
    address: '0x4D92e12de2aDa7284187ee7EBC1ceAA06EBEA73b',
    minters_reward: 0,
    lp_providers_reward: 1914.51,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1914.51,
  },
  {
    address: '0x39Bb73Bd6BE81C192B64b390fCD08F42525339e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1914.1544988632947,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1914.15,
  },
  {
    address: '0xFa7c7C77Ef7B5ac65714450b3ffCf148E19eD77d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1910.9251786584975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1910.93,
  },
  {
    address: '0x6237C3e5A234596Dc5704ae3940a0929A03a2935',
    minters_reward: 405.04,
    lp_providers_reward: 952.98,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1899.83,
  },
  {
    address: '0x2ab516e124a669841654Acb1403ab42c8154702A',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 4.040331702543529,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1897.74,
  },
  {
    address: '0x00cA04c45da318d5b7E7B14D5381Ca59F09C73f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0xa37822AB0F6a59B55ecFbbFBcE524864EF2EBD74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0xB33066b5B075C368fd7Ab24c630a784C04b5b461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0x6D459A03Cc8373644C90F930E6BAC80C1966d187',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0x9fFd84B8ea294fc4927A627016897877FcA23808',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0x8e87e306a72b8cb47E10C01C9715E340C6b8781F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0x1C4570f5Bc159fe9Fe296c5180a5803433139D3D',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1891.06,
  },
  {
    address: '0xdA66D8F641Ff6449f5bd06013C44d9F876dDd9B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1890.1277315290902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1890.13,
  },
  {
    address: '0x2dbDEF5D941A45e5D6dce0184B9cd024A8a88e5a',
    minters_reward: 0,
    lp_providers_reward: 1885.08,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1885.08,
  },
  {
    address: '0xE28e213E71177F19E34403801332AB77CE1071f6',
    minters_reward: 0,
    lp_providers_reward: 1526.67,
    swapers_reward: 348.5005752163473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1875.17,
  },
  {
    address: '0x5C97DAC2Ec021cE2A1f9e859237356c017E92285',
    minters_reward: 0,
    lp_providers_reward: 1390.21,
    swapers_reward: 484.87581948555356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1875.09,
  },
  {
    address: '0x80B620062a0Ef9613c487a2e8c0Fa0c72aae9b76',
    minters_reward: 0,
    lp_providers_reward: 1854.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1854.92,
  },
  {
    address: '0x7CB678F8EF03b71410F1290b43A76619A2123A88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 1851.85,
  },
  {
    address: '0xE18ff21F1EDea06b7AF475A1B7A81Baeef8fFc25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 1851.85,
  },
  {
    address: '0xDe446893252B9AF11a88Ae1A9976dD83A5F0b9A3',
    minters_reward: 405.04,
    lp_providers_reward: 888.64,
    swapers_reward: 285.89298854428876,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1850.47,
  },
  {
    address: '0x9Bc739e1617e07E975A1cEb9ffa51a19d260C72C',
    minters_reward: 0,
    lp_providers_reward: 1847.47,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1847.47,
  },
  {
    address: '0x04f5885c2c6a24f89121b1747f1f53B87992e270',
    minters_reward: 0,
    lp_providers_reward: 1845.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1845.81,
  },
  {
    address: '0xD263B093076130d5C2Db662e2237532b6Eb8cc87',
    minters_reward: 0,
    lp_providers_reward: 389.8,
    swapers_reward: 1455.875178095612,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1845.68,
  },
  {
    address: '0xB691B4B6d0eD845A306b5feF8BE9A80dfCA4fDfA',
    minters_reward: 0,
    lp_providers_reward: 1823.78,
    swapers_reward: 21.668090146435194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1845.45,
  },
  {
    address: '0x5aAEC7b9dec7Bd01b3C65A97a8762f10F6fF126F',
    minters_reward: 0,
    lp_providers_reward: 1841.91,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1841.91,
  },
  {
    address: '0x1B9165619615989EBBcaBa0b60469d10ADC95EBF',
    minters_reward: 0,
    lp_providers_reward: 1836.98,
    swapers_reward: 4.644113570507153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1841.62,
  },
  {
    address: '0x7000ca306393F73f7F6b6d53e1CA59eEF0c46732',
    minters_reward: 0,
    lp_providers_reward: 127.52,
    swapers_reward: 1712.9794081131608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1840.5,
  },
  {
    address: '0x8A84cBa1A6e036BBC98D6AB2Bb07608176F52bA2',
    minters_reward: 0,
    lp_providers_reward: 1838.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1838.42,
  },
  {
    address: '0xb0d82D2fdFeb39ba1d1562F54e4d93B10f21CC3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1834.6489483158327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1834.65,
  },
  {
    address: '0xc191EBE4f01864bBb3974bD36AAF8D4b47eE67a7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 1426.1943165492776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1831.23,
  },
  {
    address: '0xc5181A5aaab262C85199E97e49994D7996f15A92',
    minters_reward: 0,
    lp_providers_reward: 1437.56,
    swapers_reward: 388.1679218792222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1825.73,
  },
  {
    address: '0xB482982dE7e57021a40dA0b02D5F9f73021F8338',
    minters_reward: 0,
    lp_providers_reward: 1821.04,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1821.04,
  },
  {
    address: '0xfC4407fE5828bD19B3955eB9A3DaD3D9427D7002',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 352.7474435475284,
    holders_reward: 541.81,
    hoaxs_reward: 925.93,
    total_rewards: 1820.49,
  },
  {
    address: '0xaaD6653Ec072a6F0D18023844F4dd4d9664A80FB',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 1143.0349325680636,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1818.97,
  },
  {
    address: '0x7fcE412F0e4860036AE16bA0A9a1F28F4A8C7AA0',
    minters_reward: 0,
    lp_providers_reward: 1786.57,
    swapers_reward: 30.0617686348043,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1816.63,
  },
  {
    address: '0xDd91EbF26F8a3334b17B53a4B2C65FdB6A6a2d9d',
    minters_reward: 0,
    lp_providers_reward: 1193.4,
    swapers_reward: 611.7086344243417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1805.11,
  },
  {
    address: '0x9F54598363AdA26717656c11dCC66A96BF856b5A',
    minters_reward: 0,
    lp_providers_reward: 1801.22,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1801.22,
  },
  {
    address: '0x88b6E74056BB26e3Eb84fa300108e9E3b1217cb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1790.7807678729923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1790.78,
  },
  {
    address: '0xe2d717740CF727a0D017b4C7b35F3C2612C2d13d',
    minters_reward: 0,
    lp_providers_reward: 1764.59,
    swapers_reward: 25.075605072088052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1789.67,
  },
  {
    address: '0xF161a5ea83fD2fbFDc8CaC89e60361910F30238D',
    minters_reward: 0,
    lp_providers_reward: 1785.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1785.71,
  },
  {
    address: '0xD40E4876a1b731b3B83e2Fd661e1757abB389224',
    minters_reward: 0,
    lp_providers_reward: 1784.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1784.49,
  },
  {
    address: '0xF612CBfb72A27124384d5De202C3ED414cD028f1',
    minters_reward: 0,
    lp_providers_reward: 1530.96,
    swapers_reward: 249.0431822489708,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1780,
  },
  {
    address: '0xf9D02CF8aB6E782aAE86d9067A43BA7723999d37',
    minters_reward: 0,
    lp_providers_reward: 1633.28,
    swapers_reward: 144.9911935557147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1778.27,
  },
  {
    address: '0x4e388D31f6Fa5EC1AF464b4F6db60714A8d6D8fF',
    minters_reward: 0,
    lp_providers_reward: 1772.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1772.84,
  },
  {
    address: '0x4565e08505ae3a7a49Eb1AC416FD421b523d6D60',
    minters_reward: 0,
    lp_providers_reward: 1765.44,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1765.44,
  },
  {
    address: '0xBBA2F283f86460Bb19832A668C07310045933445',
    minters_reward: 0,
    lp_providers_reward: 1117.1,
    swapers_reward: 373.6687715602147,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1761.67,
  },
  {
    address: '0xAeC539A116fa75E8BdcF016D3C146a25bC1af93b',
    minters_reward: 0,
    lp_providers_reward: 908.41,
    swapers_reward: 852.2568691253895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1760.67,
  },
  {
    address: '0xD58077b50C07AEF5b6963186FaC044f9C6C86f44',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1756.93,
  },
  {
    address: '0xbfdD5Fb1911dbFca5E77B99B5726776a603E8fdF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1754.321444883126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1754.32,
  },
  {
    address: '0x91B69467F3E6D3EACAafe45a98C966B8B54c80CE',
    minters_reward: 0,
    lp_providers_reward: 1744.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1744.45,
  },
  {
    address: '0x543CA818620FEdea6B780054D7108Aa1FFa23a76',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1471.3324552134723,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1742.23,
  },
  {
    address: '0xaD3ed58F28F1AE43eF59fb25fc4e2Ad7b05bff15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 1738.64,
  },
  {
    address: '0x5Dd50a20022Bb03ABd4f307aF2f9768BeeD71113',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 1738.64,
  },
  {
    address: '0xD4793089EBa24a81e8E1261C9Fd9A281b9316B66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 1738.64,
  },
  {
    address: '0x5bCf1B386034156856277E960e32007FaCAF7382',
    minters_reward: 0,
    lp_providers_reward: 1738.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1738.1,
  },
  {
    address: '0x4A55fb9D92C9Bfe67a79A7db944CFD86ecD60960',
    minters_reward: 0,
    lp_providers_reward: 1723.69,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1723.69,
  },
  {
    address: '0x605bA11c879aa90D4fC66239C187228D72f08eE1',
    minters_reward: 0,
    lp_providers_reward: 900.72,
    swapers_reward: 822.0161078380415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1722.74,
  },
  {
    address: '0xcdC5d3626EcFb46b27e1f12d21d1eaC32d7C42F6',
    minters_reward: 0,
    lp_providers_reward: 1436.44,
    swapers_reward: 284.2656294619937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1720.71,
  },
  {
    address: '0xBd431a188d82854C337B63eda6DA2D84e46864f6',
    minters_reward: 0,
    lp_providers_reward: 1718.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1718.11,
  },
  {
    address: '0xfbE4C84825eDF4644776C0b50C4D5c15910De501',
    minters_reward: 0,
    lp_providers_reward: 172.53,
    swapers_reward: 1541.0068435444596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1713.54,
  },
  {
    address: '0xaA0Ce039181B14d2B12f609A518447025EB729Dc',
    minters_reward: 0,
    lp_providers_reward: 1705.57,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1705.57,
  },
  {
    address: '0xf39eDa37727a4240AB3a172950E54d7d55420633',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1700.8139865324308,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1700.81,
  },
  {
    address: '0xf68f4f3Df51602A6909E22D37c94BD2EcC935012',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 342.6913607481927,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1694.58,
  },
  {
    address: '0x02517B50e8c3AD6f77E8D23A16b417489d41918d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1674.7502127433359,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1674.75,
  },
  {
    address: '0x515F12ba6a464523102A8c0Ed5Ed2099c77e7c74',
    minters_reward: 0,
    lp_providers_reward: 1659.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1659.9,
  },
  {
    address: '0xF56F0687dc5dFc7a5246F40E289b920c3eC24CEF',
    minters_reward: 810.08,
    lp_providers_reward: 197.56,
    swapers_reward: 101.03447404309483,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1650.48,
  },
  {
    address: '0x9Be83826AFDf22a88027f8e5b79f428178bd9635',
    minters_reward: 0,
    lp_providers_reward: 1578.95,
    swapers_reward: 64.8126928045967,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1643.76,
  },
  {
    address: '0xF727BDEF5Fe302943cD6cBa0c2131a332A458F02',
    minters_reward: 810.08,
    lp_providers_reward: 12.29,
    swapers_reward: 7.449369481679475,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1642.53,
  },
  {
    address: '0x280b015A37d3F315EBB1a7E2Fc503f2f16162e0b',
    minters_reward: 0,
    lp_providers_reward: 1638.77,
    swapers_reward: 0.2689884456508537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1639.04,
  },
  {
    address: '0xEdE4C452478B2821869b4CD80621a65AaDfF068f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 1625.43,
  },
  {
    address: '0xB2FF9CeA3A81cB1EdD5EB078BC966207085708E4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 1625.43,
  },
  {
    address: '0x8E60E2844C6277FB88a76bDCB75eF6cC5f435c0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 1625.43,
  },
  {
    address: '0x2D3CEc958A8862caa65C9573029eaBDE3Fa7bFC8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 1625.43,
  },
  {
    address: '0x6C983F58674b7cAd6d81d67F2026656BA28e0871',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1622.79,
  },
  {
    address: '0x5E1Ab7EAd251122925751534d0DD839b51a6F609',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1622.79,
  },
  {
    address: '0xfFc44564A82f2FA44E900341E330f3c5692D6902',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1622.79,
  },
  {
    address: '0xfEe9c69dB5A73FD86426A62A27B7739c8E96fF40',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x50218e2EBC2e8233154C9a49aa9c0F980109981D',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0xF16b0Ec639a6AA31c7372122985100155c12E68D',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x8f03410896fB3C2a517206B04c9A3d0b0b5A83B3',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x7417FB40DFb97BF16AB1c32114D4586D3a832F3C',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x491cd10ABeB9697284705f2Cf93cAa7feaD45598',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0xfb54E5B023d73308f70e7CEbeaE093791750899E',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0xB2012aa647521EF624173403731f8ce8Fb6273A2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x8b2b9F4af4AE984894901EA61A3D436b1b8b47B3',
    minters_reward: 0,
    lp_providers_reward: 860.68,
    swapers_reward: 758.8003853501742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1619.48,
  },
  {
    address: '0x32C5E2cfcBE602c07f5fA2a8fb3D6B81b82EF06a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1614.8047359153024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1614.8,
  },
  {
    address: '0x9FB3520bD0Bde83231d1723Dab8c143CB1e1D178',
    minters_reward: 0,
    lp_providers_reward: 1203.4,
    swapers_reward: 410.71192958112135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1614.11,
  },
  {
    address: '0x3BD57B0F7Bd94189d7bf8f0051B3d6e6191c073a',
    minters_reward: 810.08,
    lp_providers_reward: 124.02,
    swapers_reward: 136.56145828654184,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1612.47,
  },
  {
    address: '0x2F9994048EE63271f5beB337C902B74B93A0136A',
    minters_reward: 0,
    lp_providers_reward: 882.41,
    swapers_reward: 717.0631629366453,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1599.47,
  },
  {
    address: '0xca39CAd98604338792d145218e109ba57dCaf5B8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1596.1010580905072,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1596.1,
  },
  {
    address: '0x2F4397662Fd3CbD08271aa53AE9696Fe2629d491',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1593.6079858322132,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1593.61,
  },
  {
    address: '0xD27B569617d47e83E587aFDbA45cbBb384f93EC0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 665.1801050578333,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1591.11,
  },
  {
    address: '0x9BA5aa36d74004A2988863a0242b200305d225c5',
    minters_reward: 0,
    lp_providers_reward: 1578.77,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1578.77,
  },
  {
    address: '0xe15f20F3E34dAf19DB195C6Df295B1F1680A3e9c',
    minters_reward: 0,
    lp_providers_reward: 1572.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1572.49,
  },
  {
    address: '0x6Ac526FCeFb0306fA1D1b70b23D7218611636A10',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 219.9946159775487,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1571.88,
  },
  {
    address: '0x6363936C384D45689257384c06c9a7d6340BF6d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1570.7676318205918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1570.77,
  },
  {
    address: '0xD662bdAA93f1FF0Bc6D9173c2042b943cEE9CF0D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 1161.2897583501156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1566.33,
  },
  {
    address: '0xd20d4241f3592dcc1C8715C9ff1d50fBf69e684B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1563.2744683144854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1563.27,
  },
  {
    address: '0xF758136491B5E2CFA0C574B0D0A8969AbE59cece',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1284.8613971784791,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1555.76,
  },
  {
    address: '0x89A99e24209d1A8B82EEA8ce0Da83323f69cEe59',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1550.9696981685565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1550.97,
  },
  {
    address: '0xb60F94395b9798c884F5d35E0247B6DEB7db1b34',
    minters_reward: 0,
    lp_providers_reward: 28.58,
    swapers_reward: 1521.9693035490936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1550.55,
  },
  {
    address: '0x41B0B01f4d82a2dfb8f14f349EbF3e43aE5A8543',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1549.4573635106392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1549.46,
  },
  {
    address: '0x25D94453D72E68dcf5592B52A5da15DD3C3A6bBE',
    minters_reward: 0,
    lp_providers_reward: 333.93,
    swapers_reward: 18.641371041353548,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1549.4,
  },
  {
    address: '0xecF863709cDC0D6092b39c0Fd8BFAa3b016005F5',
    minters_reward: 0,
    lp_providers_reward: 1524.77,
    swapers_reward: 20.480898866961986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1545.25,
  },
  {
    address: '0xFD25D661E28F684E72C484Dfe7Cceb0Af43A12f9',
    minters_reward: 0,
    lp_providers_reward: 251.95,
    swapers_reward: 1286.674527257051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1538.62,
  },
  {
    address: '0x97330028855659Fa4b4E21298e4388876d1463ac',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 183.40060662461138,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1535.29,
  },
  {
    address: '0x31e5Cd82dE5Bbddf053477530e7c0e00b6dFbAE6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1530.5479977005903,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1530.55,
  },
  {
    address: '0x733FE79e57460a470e3926cF1C7f2bbF7B67E1E8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1529.12045285787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1529.12,
  },
  {
    address: '0x84C5a3AD16cE2f2c18F1306EC65c7BbcD3C1F9Ad',
    minters_reward: 0,
    lp_providers_reward: 1407.74,
    swapers_reward: 112.11084828445615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1519.85,
  },
  {
    address: '0x9da44337d17E026B417D51Ca8663f303Ecc0F52B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 165.03132586608544,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1516.92,
  },
  {
    address: '0xA5C2a69538aB1199892FC18f7089E27Fa438Aa7E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1514.7519223340296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1514.75,
  },
  {
    address: '0x5880AD155Eddab6Ea413f8c8b243C11F737573f9',
    minters_reward: 810.08,
    lp_providers_reward: 137.5,
    swapers_reward: 21.330001436625647,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1510.72,
  },
  {
    address: '0x3441dEAd1690134A75268B5055dF9602e7Fb6cE6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1508.7396767411344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1508.74,
  },
  {
    address: '0x05ecad2C639BF305AcB2a55757D068adED1b0329',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1233.4496966835866,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1504.35,
  },
  {
    address: '0x667fd03A7c8f61954874CB1b67A6465f166d30f0',
    minters_reward: 0,
    lp_providers_reward: 992.52,
    swapers_reward: 511.26612606770135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1503.79,
  },
  {
    address: '0xfBc55D59133480C958e6D6cb5a0B9d35A40f160B',
    minters_reward: 0,
    lp_providers_reward: 139.03,
    swapers_reward: 436.68991094280636,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1501.65,
  },
  {
    address: '0x101664e7451fc83f7B56AB6f04c832AC07dE78B5',
    minters_reward: 0,
    lp_providers_reward: 816.34,
    swapers_reward: 683.5897066450242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1499.93,
  },
  {
    address: '0x5e5Ba519c7439f2a0a6a6c6902603c3673669c07',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 144.65458817477165,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1496.54,
  },
  {
    address: '0x7094072f97d04c0784fFB95677c52c605254152D',
    minters_reward: 810.08,
    lp_providers_reward: 93.54,
    swapers_reward: 43.962110858483655,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1489.39,
  },
  {
    address: '0x6d4F84D8779B0BFf9d4AB187Cf57014fA0727777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1488.0627066246884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1488.06,
  },
  {
    address: '0xb80Cdc02f00a73E9739Bf1419C0d4696604b794C',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1486.02,
  },
  {
    address: '0x5c61531863D28Db04Aa311cDC2673596656eb489',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1486.02,
  },
  {
    address: '0x0c1dafe341E8c13865C594255952EA924D98c5B2',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1486.02,
  },
  {
    address: '0x5ab0fd33350D2348A5F623C405F3f047e4058488',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1480.9200728511296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1480.92,
  },
  {
    address: '0x9e7cb9F87f008093227c60B0842a0723FbeCe98f',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 117.82294814978438,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1469.71,
  },
  {
    address: '0xe707Cdf33ca67493f899c605075645B1321ca520',
    minters_reward: 810.08,
    lp_providers_reward: 61.96,
    swapers_reward: 55.42402731071224,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1469.27,
  },
  {
    address: '0x9D18FEF6314Eab60DeF6cbFAC071aFcce9a0b2D7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 925.93,
    total_rewards: 1467.74,
  },
  {
    address: '0xc65a883f0ee8dE66968F07d1439c852D3b20D829',
    minters_reward: 0,
    lp_providers_reward: 1449.88,
    swapers_reward: 17.23719539375975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1467.12,
  },
  {
    address: '0x7d95d7B8c0D5E0225ac33A59276D51a729591CE7',
    minters_reward: 810.08,
    lp_providers_reward: 47.96,
    swapers_reward: 66.79131561155359,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1466.64,
  },
  {
    address: '0xa018F6Ca170eeF1469c53b345fAEB94C81b3F95C',
    minters_reward: 0,
    lp_providers_reward: 1447.04,
    swapers_reward: 16.000559151722072,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1463.04,
  },
  {
    address: '0xddAE97C79Bde1923fA644005f59578d502ed4dFB',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 110.98041292379008,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1462.87,
  },
  {
    address: '0xbbf5252d7b1B95B179fC073C29C5d7FF2A35655a',
    minters_reward: 405.04,
    lp_providers_reward: 95.61,
    swapers_reward: 690.8548112307744,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1462.4,
  },
  {
    address: '0x9Ce7Fb226d968Bd947C28Efbb3fe0bD1181EEB8A',
    minters_reward: 0,
    lp_providers_reward: 418.7,
    swapers_reward: 1042.5446135468878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1461.24,
  },
  {
    address: '0xcEcF94D543134150F6F489fbD934fbdfECef18af',
    minters_reward: 0,
    lp_providers_reward: 955.48,
    swapers_reward: 502.4302942624519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1457.91,
  },
  {
    address: '0x4846D969F441d32E400b58EEa494543570F96D4C',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 104.73991667292977,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1456.63,
  },
  {
    address: '0xc4F32536edf1995aaC548B5674446E5c9c3665d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 637.8493056549032,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1450.56,
  },
  {
    address: '0x653C6C4859C69E5Df0F86184f3203aEb2b3A3773',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 95.56844908634957,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1447.46,
  },
  {
    address: '0x283A84057399aC1Dfa38fbf902e3350C2e7E64c0',
    minters_reward: 0,
    lp_providers_reward: 1406.42,
    swapers_reward: 37.307398492018024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1443.73,
  },
  {
    address: '0xCaF88361edD46a25d7CC09F3330321B8E4e8ad95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1442.985489382174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1442.99,
  },
  {
    address: '0xb2cfa150A56d3B8Eee9f1e3e217CE21e2b132167',
    minters_reward: 405.04,
    lp_providers_reward: 764.89,
    swapers_reward: 1.3321951257533693,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1442.16,
  },
  {
    address: '0x867127f8BdC9b5b1091cA2A70FAC3696492120c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1436.0778471563608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1436.08,
  },
  {
    address: '0x2bB129060Eb397EF4Ee53aA14AE386a62Aa210a2',
    minters_reward: 810.08,
    lp_providers_reward: 80.74,
    swapers_reward: 2.9172274580247857,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1435.55,
  },
  {
    address: '0xc546B485ef243ED1D5Db40C0da3C353B545De6B2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1431.831329770101,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1431.83,
  },
  {
    address: '0xE853d53d9Dd5B0266ddA2B2475B638664669C64c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1430.8786705585298,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1430.88,
  },
  {
    address: '0xb8b8d22515CF41c01049e7A67991EE09FC12f3BB',
    minters_reward: 0,
    lp_providers_reward: 413.61,
    swapers_reward: 1015.2203191043865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1428.83,
  },
  {
    address: '0x91a7a51470815dA75E597923dc415ad84d01c671',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 74.13888087669254,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1426.03,
  },
  {
    address: '0x62a764Df03E4D2C14f6938221e54b8FC3D6fe3ed',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 72.81541201309533,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1424.71,
  },
  {
    address: '0xBbad1747486Ec253601F4ee23a1a7139fB15f8d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 497.7636733974561,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1423.69,
  },
  {
    address: '0xaEfA9A2AD4A1bc31C92736D555009D996175d66e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 495.30960733490974,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1421.24,
  },
  {
    address: '0xe29f436Db1DC49E3251DC80710de5983a6074c55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1415.2716322195354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1415.27,
  },
  {
    address: '0x21f0DFb065D1a8eD2cDBc684fEE7CD1808d6be1c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 489.1052222979589,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1415.04,
  },
  {
    address: '0xc7c633fA121Dab5968D95AD7200e410fB8460cc9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 62.53266187275841,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1414.42,
  },
  {
    address: '0x31105BD518476Cd0ba38E601625C417Fa6D1FB47',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 198.02186440229073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1413.14,
  },
  {
    address: '0xB2E6a26240046F3B7126476b94130EEa51CEE65e',
    minters_reward: 810.08,
    lp_providers_reward: 5.04,
    swapers_reward: 56.02409951842945,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1412.95,
  },
  {
    address: '0xc271c1fbBB9Db5870B986D4fb8686C65b01E21A6',
    minters_reward: 0,
    lp_providers_reward: 1064.14,
    swapers_reward: 344.14876218944875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1408.29,
  },
  {
    address: '0x7bB39A6F8B8d5c5be67174e16CD8b1B5441B8693',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1408.0428888414422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1408.04,
  },
  {
    address: '0x880B496e1a5DBc63162c30E8946C514df637C90E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 477.6043534670929,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1403.53,
  },
  {
    address: '0x2A91a261B8E9D916dd059708f248e60b94EF7a0e',
    minters_reward: 0,
    lp_providers_reward: 1321.59,
    swapers_reward: 77.47772077058812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1399.07,
  },
  {
    address: '0x14FADC70494734b1AC2436417F1B2654c206C41a',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 46.11935973588448,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1398.01,
  },
  {
    address: '0x9B7b7Ae3f219f27785D7Bd6686FBB77cA0385235',
    minters_reward: 0,
    lp_providers_reward: 1391.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1391.19,
  },
  {
    address: '0x0408002921bE35823ae79783c18D39b1019185de',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 35.77578313808755,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1387.67,
  },
  {
    address: '0x3A098F56C1Cae95B5B1B2e3E6E79B5821ED7386e',
    minters_reward: 810.08,
    lp_providers_reward: 20.03,
    swapers_reward: 13.795830528193305,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1385.72,
  },
  {
    address: '0x1A494BA32a54ec1657b3D0655B40D578DD5e40B7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1379.5519550630895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1379.55,
  },
  {
    address: '0xBbF1c13a936293c801DDb6EEdb7a47D1bB55a4D9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 27.65466842837663,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1379.54,
  },
  {
    address: '0xf74B27E2fd2AF5739590798502c15ae1b18bb1a6',
    minters_reward: 810.08,
    lp_providers_reward: 26.37,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1378.26,
  },
  {
    address: '0xEd4DAaF5E86F6edDBD358266369A804563f434e1',
    minters_reward: 0,
    lp_providers_reward: 1376.8,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1376.8,
  },
  {
    address: '0x2B930738eDBc7192EBdf547c9baFb915e3727EC0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 22.664807564421743,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1374.55,
  },
  {
    address: '0xC76d9ae13CafA270a83cA7FbB8D7221F3a3B2Fd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1372.9635771537248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1372.96,
  },
  {
    address: '0xeB3CD99Dd5F8A5488aaaE9e6a3221b9BE179dE4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 443.4792434777165,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1369.41,
  },
  {
    address: '0xcF302Aa6c760EF536f882998726509dF606b522b',
    minters_reward: 0,
    lp_providers_reward: 1366.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1366.81,
  },
  {
    address: '0x106714adC33faDB9c4dD11EB0b97798d6e38Ff69',
    minters_reward: 0,
    lp_providers_reward: 479.05,
    swapers_reward: 887.491770626359,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1366.54,
  },
  {
    address: '0x83a39f87819476D03A9623323438dC0FB178d608',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 14.294649427214436,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1366.18,
  },
  {
    address: '0xC0877993530258dbF4Be8EC2d3e2afC6A48d2235',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 13.905354545383831,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1365.8,
  },
  {
    address: '0x1FCA17c185277FA5FC096658f07138aeEF68E952',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 13.867588677837803,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1365.76,
  },
  {
    address: '0x38030470d4e0F2f0DA89e13A7ac4facfE71B01E7',
    minters_reward: 0,
    lp_providers_reward: 1356.06,
    swapers_reward: 5.735039787856228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1361.8,
  },
  {
    address: '0xc773e47707511481147a7Bf2Ec508F89a08Cb9B8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1359.7009497610218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1359.7,
  },
  {
    address: '0x2E4C405568c517421062d7525E8D896cE8E2f6b1',
    minters_reward: 0,
    lp_providers_reward: 67.37,
    swapers_reward: 1292.1287496693192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1359.5,
  },
  {
    address: '0xD51EB2EffcBa4122ca390aD16a7d0aba666e7D03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 1354.52,
  },
  {
    address: '0x099fa0D80F7d6eADD0C7fE5d0e27E33F641c3b98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 1354.52,
  },
  {
    address: '0x21E8b39e42804945f1730050a07e4b425a4193f2',
    minters_reward: 810.08,
    lp_providers_reward: 1.62,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1353.51,
  },
  {
    address: '0xa102fb6cb2780Edc6385d01aCD8FA3D155461193',
    minters_reward: 0,
    lp_providers_reward: 153.8,
    swapers_reward: 2.692870049229977,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1353.32,
  },
  {
    address: '0x632D39503270670e2cd85794B78f9E094b12b272',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0.12535377020384897,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1352.02,
  },
  {
    address: '0x09086b82f5B6af82617e2943de764f0b5d2153BE',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xF7dA792597B4167158c6593a8b1717783c7d0DE0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x5CD64DDFD9F25CFD2C678eB9f54eDa1A69a0083e',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x4A08a585bAB0416eb4873e3682A5A6CDAe707ce0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xDA8681acE25181131C48b4705586bcf0c3b7A3D9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xf484701c70569984B33Dd7d5CA749291F51dE56d',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xc848b58c616146b8A37474F35F57a62111B3Dc8c',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x382B4d40034858956207498AAE03E088989c13A4',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x4FDeE0D34381b620d91B76A440e373a83f7c2e1B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x1184fA9A6f73A5789c0f0D0618C31E0Fe7ef459b',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x2f1782E63cD639298Df15Ac4FfEe7F5523D6C150',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x6dCF5927d14De19Dd948E1f6A6E8B96813fC6F37',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xE5d16550c3Ab4fc21C35fa50BA58BdcD28Fd1dEa',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xe70115e55A3B609FF90945A9cad06c3813d661FC',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8CcA26F56c856878C39993Ff03ecCB577D47BD0A',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x2D21B7b9d93427c123909D8B79e420dBA27A530c',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x7FdfeB7E9dc80CD2e7B5d21A77c8609a29b644b7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x51Ca0dF61aaF61cD7f33405395516E407B33b7dD',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xd079cf93aC0B9FB8732948030EC937F72D5a81F0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xEb67ffF9A0Ca472D57679504A3667B35eB9Fff17',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xE50f4698977813dC5Df1FA4b9344CeD81086f78d',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xFCF35b5aa4159BabFf6c741CF140cA32c01CBC39',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xc5d32f74BC31B9881149f21439A148f27549C038',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x7650C7e6fe529eEaf892aC552f51A05501774462',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xB173BdeED203582c409d7e6171dDCf9E5A54e259',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xA4195FC6651684545Dc18Ef7097B13A043A91592',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x159b136c2ABc83bFdF85956cBA9c3BB13651b42C',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x7C9Cec7093695630EAAd197FAcd01f99032D5052',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xDf244FB6474536AC48E3836dad0468325c63862A',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x1c97b6F3D07f5c3ee22816bc231141bBFb3B47bc',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x3f043123198d5C7f1A6C58CD3d96dd6970dBD52B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xA897cE842f8347dFeFD9F0DADE08A2A5aC38523a',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xb253881d582071abeDBA53c58A9Ef8403d6409d7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xE04D0f2D0BfDD39B75501720fe7e3168365233e5',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x3798F2Fc298241266d06e7A6240dFA5647971aFA',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x45ce08C2CA9092095241a14c083911b5ab226B5B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xC800316a38dEbd1a22f851B18267e46A52Fce117',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x6A74a76A95696709543407D60C143D5b0e04f57E',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xf6c87096aCAAcB524d8BD5DD0026218231179201',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xC6dA8506F1390aDC9F3cdc7D9A47eAd1F43fDB16',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xE9eAf4a560C229A44673645cD400a7897145DEba',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xb8c9305e84ea95E4b6787A74831D4D23494a0e35',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xDfA0D14432321B5160B07904ee7048aadE9A4E65',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x38B9Dd991a205D7cF2F7f47028539cf4718eb217',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x315685232Db9bD7c236780f0ba8C14052E4bFB93',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8C7BF092a8BD79ba99AD415AE8274E2D4E06d1f8',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x37306947f60D1E24F422842C185173fcbcaBAF83',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x548eAe65E7Cf3A37A5aC7C019d393b9438B548Db',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x909B70f2FE7A279EeC393f4C75e234deC2a30754',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xC1fcAc038d5805f45f057f8A8829B779A4772c7B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x86175DD67D96FA2e1Bf30B7230C83519d1BfdC85',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x1B49C12f300b89B1996108Cb51e6794314fF6db1',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x6Ed015F3E3981a5502FAc71dd6aece8178b08367',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x94e4AcDc2799Ccad0292AE2157D75e547C33dAe9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x68254e13066B13d35402BFBca4F73D1e97120b6c',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8106832dd1a5135DcA4c70cbd9B9CCb481FcA8f7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x7821803172A92163ACc1b95B45b10e0f21994DAB',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x01dE132EdF9deb2cBd4Ef6A5bd7E3b66d852B261',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8360F6F182FCb5d0E32F3e01FE54A32487a45785',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x03dAF590730C3323CFeA618439a0088a217eF735',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xCc486DC9d97CaF6152c965a820daa08d062065C8',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x757F75757a020d01aA2D55f74d664211DD657DF6',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x911Fe644658dE707916D84B504D7BfD317b987B9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x23417B682fB63A828dd1d6Ca24a9C16bB3432ECC',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x65fB2868Ff97272DEA91D2E901B4673f57223bd7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x01A5440db6Eb2d60E2221a84CdB90b1820216dC3',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x0A5a19bf94607657cb708394Fb981AFC754E2D93',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8C4d7d482780CCB799e2415F25b767250887e580',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x3aa50ebFA6cc0C532c6ff901f8C96C4F913add99',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x510E2fD691443FfA7237245934e0aa15918023B9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xf16236B180E18DF4C4179B2010063f113D99A254',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xda860733370d972D4825Fe36C93602AAa4fD2193',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8EF4014B4110C95f9Fa5e1030eF143137be995f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1349.0190486054607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1349.02,
  },
  {
    address: '0xDf6172c72c2a6CF92d65fc0D46Ba006986914ddf',
    minters_reward: 0,
    lp_providers_reward: 992.49,
    swapers_reward: 352.7922753507465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1345.28,
  },
  {
    address: '0x0a451489f8C4b59aa4C84d2f53EBf11E984d5c39',
    minters_reward: 0,
    lp_providers_reward: 813.58,
    swapers_reward: 257.7310707622224,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1342.21,
  },
  {
    address: '0xb9ad57e76227f37c167CacBfEb6a1f9803ac3D82',
    minters_reward: 0,
    lp_providers_reward: 267.44,
    swapers_reward: 1073.3695699757336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1340.81,
  },
  {
    address: '0x6C3138380D98c366a181F2b57785af8F96B46D70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 414.48587272013407,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1340.42,
  },
  {
    address: '0xFD70D777769F7B354b757a6E99BDc3bea69Ead11',
    minters_reward: 0,
    lp_providers_reward: 176.82,
    swapers_reward: 1162.3973385972845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1339.22,
  },
  {
    address: '0x1749458360B1AB82F5491c19da16B3CB0ec8C594',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1337.8312627191888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1337.83,
  },
  {
    address: '0xB164423E1e26dfB5A25F38ffa57359b325ef8025',
    minters_reward: 0,
    lp_providers_reward: 1103.15,
    swapers_reward: 231.08787338606874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1334.24,
  },
  {
    address: '0x9866F56B785d38E67eefED374821d1d7aE9EF18a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1329.4023967309427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1329.4,
  },
  {
    address: '0x7cc941f730672908e859B7F94981c9d44d08F722',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1327.1046386174924,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1327.1,
  },
  {
    address: '0x01C9627514bbDde7D332b17BF7e4A837bD43EF51',
    minters_reward: 0,
    lp_providers_reward: 532.35,
    swapers_reward: 522.742104422252,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1325.99,
  },
  {
    address: '0x672424390D004c33455E7a51A10374f34e636734',
    minters_reward: 0,
    lp_providers_reward: 1294.37,
    swapers_reward: 29.539378720449896,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1323.91,
  },
  {
    address: '0x0c597C0Cc7f7DAa52D2fcbFd8b6cB9Ec1410a51A',
    minters_reward: 0,
    lp_providers_reward: 1320.3,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1320.3,
  },
  {
    address: '0xcd7dbaCE50A4d1bb4EDeCd098c04Fc7CBB0d4D04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1311.123870410356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1311.12,
  },
  {
    address: '0x82AD12518E43F80834096A5B3f9623F3F0b64A32',
    minters_reward: 0,
    lp_providers_reward: 1307.07,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1307.07,
  },
  {
    address: '0xf358cE3134E5A318039914d04e1fd27faEf5806c',
    minters_reward: 0,
    lp_providers_reward: 572.72,
    swapers_reward: 731.7417048727085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1304.46,
  },
  {
    address: '0xAea762e1927a2126880068c89144adaD8A9A15a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1300.0746654623888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1300.07,
  },
  {
    address: '0xBe2cC75e5a27d497dE0CB33aea74Fec5563bdF6e',
    minters_reward: 0,
    lp_providers_reward: 465.47,
    swapers_reward: 831.8630947037237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1297.33,
  },
  {
    address: '0x2d790605D7cEC5AF244b08CF4C465513E145b7cB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1292.7639371244404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1292.76,
  },
  {
    address: '0x74A83BcA375B76222ca0Bfc077e3aAB0257462C0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1291.6118318751703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1291.61,
  },
  {
    address: '0x14D37FE469EE37dD2cff713Df7eDDBae13FCdE63',
    minters_reward: 0,
    lp_providers_reward: 1291.24,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1291.24,
  },
  {
    address: '0xe057e73c28F71d0634a0379A9E89086396d29148',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.58851334761741,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1278.42,
  },
  {
    address: '0x36771BD622d53498c29C0eaB49B1B7fabc7C67B4',
    minters_reward: 0,
    lp_providers_reward: 1276.89,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1276.89,
  },
  {
    address: '0x956bccf2C2E08D5203C011Fc06D81a0ce2641A93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1275.4381870680413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1275.44,
  },
  {
    address: '0xf7B5054c0B8b67E7b0f6454747d98452f736787D',
    minters_reward: 405.04,
    lp_providers_reward: 852.64,
    swapers_reward: 16.181059709572118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1273.86,
  },
  {
    address: '0xB329d753b9c9e2DbE6Fcf2559569E87Fd128933b',
    minters_reward: 0,
    lp_providers_reward: 1272.18,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1272.18,
  },
  {
    address: '0xaF59cA501Dae42433dA19F44885821A400483110',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1268.2978380417812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1268.3,
  },
  {
    address: '0x4f437BFa3542c76E7f96b9287d3883AF2ac41cDa',
    minters_reward: 0,
    lp_providers_reward: 978.84,
    swapers_reward: 286.078177913381,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1264.92,
  },
  {
    address: '0xA6982180D45c07076238C87E7e5a29fD9251bEc9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1263.5310211655456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1263.53,
  },
  {
    address: '0x9f783ED7f7Fd4c268d7f2Ed187C683B5210f6a8D',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 46.79960664114371,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1261.92,
  },
  {
    address: '0xBE47F78b18584A69ccAee6228630C66c382516f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1259.7832317175237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1259.78,
  },
  {
    address: '0x89099A8bdeb074c02CE44fCa1235dfd50A9c7675',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1257.9160314401122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1257.92,
  },
  {
    address: '0x1EC03C63a022241f40EEe3A908A6540559329dBb',
    minters_reward: 0,
    lp_providers_reward: 687.82,
    swapers_reward: 569.4424016097576,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1257.26,
  },
  {
    address: '0x7ad2cB81aF992b0EC215BEAD9de9c8Be5E35f24b',
    minters_reward: 0,
    lp_providers_reward: 984.72,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1255.62,
  },
  {
    address: '0xb52FFB2fC5DA23E3F51Eb1E9DF1C76a314700aF1',
    minters_reward: 0,
    lp_providers_reward: 1200.91,
    swapers_reward: 49.47681419861171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1250.39,
  },
  {
    address: '0xEc092481A452E90Da952508eB005f8064EEa3Ee4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1250.1494911041732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1250.15,
  },
  {
    address: '0xAcEA9f4F939FD0E3eF5966134A70A2E5B415f1b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 322.68452072573916,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1248.61,
  },
  {
    address: '0xE9B48D7C57B84d7b7C2ee2bf4092B90730f5E4f1',
    minters_reward: 0,
    lp_providers_reward: 81.27,
    swapers_reward: 1164.2661238620751,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1245.54,
  },
  {
    address: '0x33267F6aF8Edf9Fcf11208195D8127e73c74A66F',
    minters_reward: 0,
    lp_providers_reward: 250.93,
    swapers_reward: 68.34359155753847,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1245.2,
  },
  {
    address: '0x4E8d25d7B6A0EE67CcDa7417eeE8309aD27e5FD1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1244.1087568936625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1244.11,
  },
  {
    address: '0xdd4612980ac565449C94aDF542d8F74dcae543Cc',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 28.39649306340267,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1243.52,
  },
  {
    address: '0x6fe57be471903b55B7DBd13329299531384bDF68',
    minters_reward: 0,
    lp_providers_reward: 1107.87,
    swapers_reward: 135.4445308001968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1243.31,
  },
  {
    address: '0x9b0C3D34D0C3e841a720981B8839ACA780dC1C58',
    minters_reward: 0,
    lp_providers_reward: 1242.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1242.42,
  },
  {
    address: '0x4d5b5c0ac5dDF8aBb362eFbB7196F30D41D75FdB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1240.565368636455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1240.57,
  },
  {
    address: '0x4A612BdA56b0CF4AeDD7f0a69C2882Ca8Bc02cd7',
    minters_reward: 405.04,
    lp_providers_reward: 2.68,
    swapers_reward: 17.110242568827026,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1237.54,
  },
  {
    address: '0x9509565921cf36877B75b60B243f98E25c5DB22E',
    minters_reward: 0,
    lp_providers_reward: 35.2,
    swapers_reward: 657.4377985949861,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1234.45,
  },
  {
    address: '0x13Ce66a3099132049DdA8fA92d861a32225BfF31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1232.0772841395355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1232.08,
  },
  {
    address: '0x4a33f85847E27ffE3b4604D07260F1a3A3643B08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1225.913951110973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1225.91,
  },
  {
    address: '0x5ab9cdb305E57491dA476F18cE648821338AA3d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1220.3815624332522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1220.38,
  },
  {
    address: '0xaCE7538e64ac6451761ccFF471d5Aa976fe25725',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1217.75,
  },
  {
    address: '0x7Ea06865B2C157aa5e9777857e46d2e9ee78314D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1217.75,
  },
  {
    address: '0xac1e2Ce7095cbd0064759f1A19AC001bd005b84e',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x980d48f66A427CD9469C0921fB8333AabDf980B3',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xf853856620C9eB2e9a43F6585bC45DE6B2a24055',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xe79cf3912Ac84F099F64372F9A4007166fD4D293',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xCE6027b575744203558F9461A0DA212F1E189058',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xcf716f078A7a03264c6b7e3b60a771cd26F23DCf',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xC47F3B3447FBD374009E3245af8Cdf824380A549',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xbDC0956A83448Eb90A1dD2417D9154101DBd3314',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x8Fd434B82D13497C25b33F2E91eb19CC826e33c0',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x1B8364a3b4437d95A2856e6A5D0aEE5A185B7179',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x905c0E8BD5E3008f2bD48374A50CB65FE24Df55A',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xCA46e8Ed7eB1581C11cC705503EE3c73F6e3F7EB',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xa8c63c19C1eF6149340476eAA841a57A78cf9c1e',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xb48E3AcE947973a55889F927A0118Dd2279df9E3',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x206d19BAaf59cDf548567718eA3Cb4C3411B4C84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.397874753383855,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1212.23,
  },
  {
    address: '0xA2EAD40e5366d520F6c72568bA4ECF7ac31ee9d6',
    minters_reward: 0,
    lp_providers_reward: 1006.48,
    swapers_reward: 203.550563079163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1210.03,
  },
  {
    address: '0x404D59092556B340c2FF6b7Cdbc7129E53301dBa',
    minters_reward: 0,
    lp_providers_reward: 1209.29,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1209.29,
  },
  {
    address: '0xF03D6Ca15c55cE3F5eD62F2Aaad6dA09c1FAfD82',
    minters_reward: 0,
    lp_providers_reward: 640.56,
    swapers_reward: 567.6025247564503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1208.16,
  },
  {
    address: '0xec0812afe1E26626F18BCbd5f4Bd595F272c200B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1201.2654064021972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1201.27,
  },
  {
    address: '0xc21AA060f1576292eCbCE713b9D1aC0808B91a2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1198.640917506923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1198.64,
  },
  {
    address: '0x5785bf3864C04Fe724Bd6b1b8EE5cFD1DD6BeBdD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1196.9954359328149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1197,
  },
  {
    address: '0xF09C36CF186D50ABcAE1BfD9421423a2f2342C95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.022248360728958368,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1196.85,
  },
  {
    address: '0xAe26364c1b9984864af0C759576DED1c8f85bD04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1196.83,
  },
  {
    address: '0xc379596438998F917D5937B256EA833b3cCDbe6B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1196.83,
  },
  {
    address: '0x5EbDc38e13D51Fb85015aDf832aB771496f64391',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1192.4864340862387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1192.49,
  },
  {
    address: '0x08912CBFf6A1491e1A64F5A7BDAcbf77D279D830',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1190.1367666816338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1190.14,
  },
  {
    address: '0x754a5F0A76E005F77aaCE7F25E8c24dfc94536C1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1184.0658452101156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1184.07,
  },
  {
    address: '0x49d002b74517bDB05FF218964645d439469ACc01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1182.1462396558104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1182.15,
  },
  {
    address: '0x033cE511712A0b75Dc46d7d80DeeBC939fAe99a6',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 97.27202791770951,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1178.25,
  },
  {
    address: '0xE00334041f856dDBfa693895bdcbff54D5Bff536',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1177.4313020469883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1177.43,
  },
  {
    address: '0xE94396191532B45c9fA27b085Be8BF73E968a698',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.53818453587664,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1176.47,
  },
  {
    address: '0xD34EFe13287503446DC7ebC2AaC2d9515435eBB8',
    minters_reward: 0,
    lp_providers_reward: 200.75,
    swapers_reward: 161.02982622022208,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1174.49,
  },
  {
    address: '0x1948a8679633FD36AE2Deb3Fbe6C8CE63E5A0366',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1169.9601313960038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1169.96,
  },
  {
    address: '0x00f8430E22793e5f6Ee76de97DB94aC601a810e2',
    minters_reward: 0,
    lp_providers_reward: 1168.37,
    swapers_reward: 0.11716376243220897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1168.49,
  },
  {
    address: '0x0E2eC742c343D006169fb59866cAaE50A113983F',
    minters_reward: 0,
    lp_providers_reward: 991.29,
    swapers_reward: 171.5665339562078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1162.86,
  },
  {
    address: '0x3A3a6a721EA1a58efF9763F022819E0Cc0A0B911',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1162.256247817105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1162.26,
  },
  {
    address: '0xA458e7ce53fc2EfA711A3738797985D852E9520d',
    minters_reward: 0,
    lp_providers_reward: 551,
    swapers_reward: 610.5478060162346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1161.55,
  },
  {
    address: '0x46038EeaFF60C4A3e62aE39eaa1FAFeAF99e6Bd3',
    minters_reward: 0,
    lp_providers_reward: 353.52,
    swapers_reward: 259.5043780251056,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1154.83,
  },
  {
    address: '0xdc5bC0ccC7fFd05AECe6231373844F3d86cCb114',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 608.5168219167625,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1150.33,
  },
  {
    address: '0x95c648B967cDb0D2A2e94EDb5ad0151e9e3fB7D8',
    minters_reward: 0,
    lp_providers_reward: 1149.7,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1149.7,
  },
  {
    address: '0x18ADe2F07b0e4362bc93a980e92fEA741c5E468E',
    minters_reward: 0,
    lp_providers_reward: 64.81,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1148.43,
  },
  {
    address: '0x689dCb6c727a18C60c42635F99C78BeF67367ff0',
    minters_reward: 0,
    lp_providers_reward: 1148.06,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1148.06,
  },
  {
    address: '0x9Cf1750d7fb783DB8d1229476F83055c04D91e38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1143.375058538257,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1143.38,
  },
  {
    address: '0x02A4496aFD423B2B36a34D7F6E918e71Ca88898B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1139.2938519753066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1139.29,
  },
  {
    address: '0x19e20bf96360c4CE93896248121B64DeaC071702',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1138.9716434904549,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1138.97,
  },
  {
    address: '0x5f8B65DDC91c74ee169c7EbEb0603824AD09F39B',
    minters_reward: 405.04,
    lp_providers_reward: 102.94,
    swapers_reward: 357.24128343865084,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1136.12,
  },
  {
    address: '0x25A9401E0483956aAa678173f0c4Dc0efD7d9AfC',
    minters_reward: 0,
    lp_providers_reward: 134.26,
    swapers_reward: 75.3220862925766,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1135.51,
  },
  {
    address: '0xD3527934cc6bb4C0cB2C876a582af313C57B7D4f',
    minters_reward: 0,
    lp_providers_reward: 61.78,
    swapers_reward: 139.8112041337322,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1127.52,
  },
  {
    address: '0x1a3497d51d7ae5886D4073fea1A1936CC03f4803',
    minters_reward: 0,
    lp_providers_reward: 233.88,
    swapers_reward: 892.4934664303145,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1126.37,
  },
  {
    address: '0xEE18AEe21136BE4aBa7f88aE4772ecf7D40DE20a',
    minters_reward: 0,
    lp_providers_reward: 493.78,
    swapers_reward: 626.2453082918473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1120.03,
  },
  {
    address: '0x24A401fC934e1902061027AcB271f7a33dE6e453',
    minters_reward: 0,
    lp_providers_reward: 524.31,
    swapers_reward: 591.7132067791714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1116.02,
  },
  {
    address: '0x7De9728EbFd310A8a8Cf627c0576aE970Bbd8165',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1112.1217066214947,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1112.12,
  },
  {
    address: '0x1863829EBdb673c5c2A5095B046F9Ae624ca139b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1110.210391932976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1110.21,
  },
  {
    address: '0x2b8f61B570897412325a45f85F434D96F545AeaF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1109.414219619293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1109.41,
  },
  {
    address: '0x730BBC6Ad00eaFB67e2695DDd96c7B6BABe25266',
    minters_reward: 0,
    lp_providers_reward: 1108.73,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1108.73,
  },
  {
    address: '0x2f9a817B4c45936F1AdC3bC954cD005418bEd420',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1108.455858727653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1108.46,
  },
  {
    address: '0xEC4C15765C88090d6fD5f11251700ffF6Be12b4c',
    minters_reward: 0,
    lp_providers_reward: 690.89,
    swapers_reward: 415.73467888958885,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1106.62,
  },
  {
    address: '0x68F1C7005ce21131E02106645b4b24996B3b12Ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1103.6132943990488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1103.61,
  },
  {
    address: '0x360607854C01F434362ebAdb398C13906Fc293aA',
    minters_reward: 0,
    lp_providers_reward: 1102.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1102.76,
  },
  {
    address: '0x76617E8Bd106d8803F5D37a99Ab83A2b787fB0fc',
    minters_reward: 405.04,
    lp_providers_reward: 375.2,
    swapers_reward: 48.650767232713825,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1099.79,
  },
  {
    address: '0x9a1cDF51831C7ae5234f94D3C07Da7083Ba3C4fE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1098.4844086387104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1098.48,
  },
  {
    address: '0x29efEAEdcC990E3cE1b02eDd0560628162845EB9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1091.4650479666466,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1091.47,
  },
  {
    address: '0x4E476c0602a8B2e97D5592CcE6bfd0816D8EEEA6',
    minters_reward: 0,
    lp_providers_reward: 794.01,
    swapers_reward: 296.5411672745664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1090.55,
  },
  {
    address: '0xD292109e1C72d34fB6A8c2514A4B30C86B07af0B',
    minters_reward: 0,
    lp_providers_reward: 1088.78,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1088.78,
  },
  {
    address: '0x299712f19BaFfc479805b7E5C181120B354A5D67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.259473959510402,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1087.88,
  },
  {
    address: '0x10FE21f989584b3a065bDE150E1Ef5a44116879c',
    minters_reward: 0,
    lp_providers_reward: 1045.92,
    swapers_reward: 40.58354118876518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1086.5,
  },
  {
    address: '0x6562369a978AD4633947CDc71df7B59d7dC6bB57',
    minters_reward: 0,
    lp_providers_reward: 507.52,
    swapers_reward: 578.8640875431366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1086.38,
  },
  {
    address: '0x104819a74ce1b62dAe64b9cF42217638759638A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0xD771313365148cB61ed746Bd58829714b8A638ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x97db70d3301aBDE7Ac36BDCFAb513bF8F4C44A75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x846ce84839317AB4440f4D0AF129dD1943bFbD00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x73f48FA8a8f258826Cf8Ba3B3B883dbd65a4830a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x0089E2273D6AF5A7Befd785e0C744d493A6e11E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x30A7c51564b73F5386e48E1d0c52744604BD875C',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1080.98,
  },
  {
    address: '0xF6589e10d2583f3D52fE6aF0620BC5052a70272c',
    minters_reward: 0,
    lp_providers_reward: 856.55,
    swapers_reward: 224.27260575867675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1080.82,
  },
  {
    address: '0xD1DCe76c9ec4743736db4df7011008b8a5a12640',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1080.7176399375817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1080.72,
  },
  {
    address: '0xef4bB99Df1CD3f87e6f7B084adEF80c0B0926D96',
    minters_reward: 0,
    lp_providers_reward: 80.91,
    swapers_reward: 999.2003258630307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1080.11,
  },
  {
    address: '0x0077Bc388a22D264786483BE9a6bAD89Ea94d21D',
    minters_reward: 0,
    lp_providers_reward: 1055.53,
    swapers_reward: 22.18970847284776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1077.72,
  },
  {
    address: '0xe4F8348F8d2942f8fb0C53fF680299c68Be77795',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1077.6580991051185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1077.66,
  },
  {
    address: '0x2924F08742e6951455b2f5E625Df5844DA601bF2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1076.3425080719935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1076.34,
  },
  {
    address: '0x55be672b97F08E05eE9DCEE7c216a929a9dDE55C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1074.0590943839425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1074.06,
  },
  {
    address: '0xA714baC0905A1EceC18594CF78a59a8b6eeDE254',
    minters_reward: 0,
    lp_providers_reward: 1069.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1069.25,
  },
  {
    address: '0x8864935f43D38d4A513F300ceB41C2523C368966',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1068.3963808196766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1068.4,
  },
  {
    address: '0xc8B8975147fa3679F01AEC2c02C9a8a97B58d6df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 792.9097397054815,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1063.81,
  },
  {
    address: '0x4F64524189f5d87B5B0468E5648692DadD396661',
    minters_reward: 0,
    lp_providers_reward: 196.14,
    swapers_reward: 866.9284604328127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1063.07,
  },
  {
    address: '0x9609415480650b28d6CE4389242f4eAdCB4aC4A4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1061.2279445928973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1061.23,
  },
  {
    address: '0x527c43EE99c162D4377907542c2CBad84741a823',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1059.6469189693235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1059.65,
  },
  {
    address: '0x25C0f883A30F291870c0af8e3bE7fDb5E17b5C46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1058.66306540306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1058.66,
  },
  {
    address: '0xc45Eb03DD2dFb266ABd48fC56E38132aa3C00f98',
    minters_reward: 0,
    lp_providers_reward: 336.98,
    swapers_reward: 715.7569953827211,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1052.74,
  },
  {
    address: '0xeBC46e505b928bfb09114dd0434faC9A23FC23A3',
    minters_reward: 0,
    lp_providers_reward: 1032.7,
    swapers_reward: 17.706444397441217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1050.41,
  },
  {
    address: '0xb5A2ceb2d46AdBf09AD108bE6305C45eF1330b5B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1044.4511392056763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1044.45,
  },
  {
    address: '0x38F888Ea34FAeD56de7e6366Ad3c6870ba026633',
    minters_reward: 0,
    lp_providers_reward: 963.57,
    swapers_reward: 80.44282031546739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1044.01,
  },
  {
    address: '0x25e5f328e7675F5DC1d22296150C98D8D9cc1b15',
    minters_reward: 0,
    lp_providers_reward: 1043.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1043.9,
  },
  {
    address: '0x2F9E91b4993c769baAA91229A839604681e110Ae',
    minters_reward: 405.04,
    lp_providers_reward: 53.82,
    swapers_reward: 312.4520564560953,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1042.21,
  },
  {
    address: '0x8f5C464E99aAe0C1314e0dDbB9de626AE7a2512D',
    minters_reward: 0,
    lp_providers_reward: 1041,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1041,
  },
  {
    address: '0xBC7248B4Bda899A516F348e7b6E2930d57931ce1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1039.4352761306127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1039.44,
  },
  {
    address: '0x2953adf0baC7e9E507e01541dB0870056a2449e6',
    minters_reward: 0,
    lp_providers_reward: 1021.93,
    swapers_reward: 16.884255838067862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1038.81,
  },
  {
    address: '0x44b285D081cAAC83D83300e6cc3D864aF1bf108F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 767.4344188922836,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1038.33,
  },
  {
    address: '0xAf9df16C45E28a2ad96699cc11B104c12897F625',
    minters_reward: 0,
    lp_providers_reward: 194.66,
    swapers_reward: 842.5860632650706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1037.25,
  },
  {
    address: '0x64f17Da84F3f9305adD04070ae5D2816b4C95727',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1034.3173604226686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1034.32,
  },
  {
    address: '0x91B29526b0D89760D7852A1be4207F961EC6dCd0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1030.120614328661,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1030.12,
  },
  {
    address: '0xeC78a5c8Cd9145e42189802679CbdE8640f57679',
    minters_reward: 0,
    lp_providers_reward: 870.98,
    swapers_reward: 158.8939317200149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1029.87,
  },
  {
    address: '0x22dD1870778ED7C351609a05d4E9dF42Dc60201b',
    minters_reward: 0,
    lp_providers_reward: 322.41,
    swapers_reward: 707.4464105474005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1029.86,
  },
  {
    address: '0x508906f7358C75E5938f147f06bC938eA5704E73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.5522407454985,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1027.48,
  },
  {
    address: '0x526263F13b8894327264997dd7EaB2471B25926B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1024.5693225828318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1024.57,
  },
  {
    address: '0x6C5c90b46048f71e9C0A8B231e5ddC9726aD7D1D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1023.8687392755753,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1023.87,
  },
  {
    address: '0x3124a0E29BB77B2bAC9eFF8189c4E3F6882D2efB',
    minters_reward: 0,
    lp_providers_reward: 263.41,
    swapers_reward: 485.57631988379995,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1019.89,
  },
  {
    address: '0xA22C9199Bc31Db595562460bE306E50B0906244C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1018.8478403671254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1018.85,
  },
  {
    address: '0x3bE9aA1b2D19A48C4D58328Ffb5137Da45ff3dd8',
    minters_reward: 0,
    lp_providers_reward: 167.69,
    swapers_reward: 851.0138787941834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1018.7,
  },
  {
    address: '0xdd2dEe340Ab423Fbd6069F1D34F0DCC31B4A1aE2',
    minters_reward: 0,
    lp_providers_reward: 91,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1016.93,
  },
  {
    address: '0xe9Aa6073c8690361a70760179c7a1E2A2E6dE8D9',
    minters_reward: 0,
    lp_providers_reward: 877.51,
    swapers_reward: 132.59397028213945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1010.1,
  },
  {
    address: '0xd9a8c19D4A1b34Ecc4C49D4eBbB825288903d3D2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1007.6334473316259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1007.63,
  },
  {
    address: '0x815800461b4106Da899b895E4B61f0a116e7408A',
    minters_reward: 405.04,
    lp_providers_reward: 325.53,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1001.47,
  },
  {
    address: '0xa18aFD6913F600Ea56f73c5776b9250c3511D323',
    minters_reward: 0,
    lp_providers_reward: 1000.67,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1000.67,
  },
  {
    address: '0xed710A9Ff215623571EBdc9e50eE944da2736F2E',
    minters_reward: 0,
    lp_providers_reward: 998.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 998.39,
  },
  {
    address: '0xdB5209FF5Bb6037AF30b27d26Be710Cdf8821325',
    minters_reward: 0,
    lp_providers_reward: 591.92,
    swapers_reward: 403.1847980832833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 995.1,
  },
  {
    address: '0xc3439F8E9711dB0398381AccA6C42A7641904921',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 990.9645344627015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 990.96,
  },
  {
    address: '0x5E96B067719495190ca0A23BFB5EfA3843263f53',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 308.70044414418885,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 984.64,
  },
  {
    address: '0x9A1e5e92761b6e8555f0799B52912475cB91260d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 712.3213546786252,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 983.22,
  },
  {
    address: '0x65a68741693c705EA557fb8E0b4806E3D78B9844',
    minters_reward: 0,
    lp_providers_reward: 438.22,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 980.03,
  },
  {
    address: '0xEBAe1C16FA9D286CD987B7f0F25f9F45e879e520',
    minters_reward: 0,
    lp_providers_reward: 979.02,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 979.02,
  },
  {
    address: '0x7e389171D8e54cd337423C41fd0A06a08d3A6F0e',
    minters_reward: 0,
    lp_providers_reward: 674.14,
    swapers_reward: 303.68240034251727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 977.82,
  },
  {
    address: '0x7DEfCe30068F78be21020691fCD4E8f3A0001c55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 974.3570440335079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 974.36,
  },
  {
    address: '0x45880BDB90DDdec7Ba6205f09Ce26376f0A15C8E',
    minters_reward: 0,
    lp_providers_reward: 466.1,
    swapers_reward: 506.565960027994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 972.67,
  },
  {
    address: '0x4D2A94EaB88Adb86d15d997427f9f8476ceaA3a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.908638114733705,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 971.84,
  },
  {
    address: '0x5aAC0AdD617F759e2009301e08fD4c558cd560C8',
    minters_reward: 0,
    lp_providers_reward: 704,
    swapers_reward: 267.723119671892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 971.72,
  },
  {
    address: '0x0BAFf65719b8C7014F15968b0Ad6308C1eBf707E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 971.6327878050113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 971.63,
  },
  {
    address: '0x1D901545299EA95c06fbDfc3ebA40419d77aB2b6',
    minters_reward: 0,
    lp_providers_reward: 745.56,
    swapers_reward: 225.59226432830866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 971.15,
  },
  {
    address: '0xb6E92D077591b064F48Ef68E2074d6Fa4a0C2cC6',
    minters_reward: 0,
    lp_providers_reward: 58.34,
    swapers_reward: 641.4968013635153,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 970.74,
  },
  {
    address: '0x131bEB5521112346486537280d2f2804F2018476',
    minters_reward: 0,
    lp_providers_reward: 119.27,
    swapers_reward: 849.6227949762321,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 968.89,
  },
  {
    address: '0x8f7A57Aa1DD18D32201bb153079657C0fC3bc7B7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 966.5497008387989,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 966.55,
  },
  {
    address: '0x0a281387b85cF32C709D427B237a55AC695c6e64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 963.1437210375329,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 963.14,
  },
  {
    address: '0x4f8c4C203c17Cb46C4DF960d7802C9b2A09B36cB',
    minters_reward: 0,
    lp_providers_reward: 410.87,
    swapers_reward: 280.90723886046425,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 962.68,
  },
  {
    address: '0x6e629B824024759924a26c666845A9BD573Ba1DE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 961.8917939823644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 961.89,
  },
  {
    address: '0x0598e8bd32b95b29C390F84497458Ec758D879d6',
    minters_reward: 0,
    lp_providers_reward: 513.78,
    swapers_reward: 447.8233555961074,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 961.6,
  },
  {
    address: '0x2A636E33807D46461f59BDbFC38e365f0D80027f',
    minters_reward: 0,
    lp_providers_reward: 3.11,
    swapers_reward: 955.9819864210244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 959.09,
  },
  {
    address: '0x463F438C5c9cA9DD2dd9ABeA0aE6Dc2E86e32058',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.52656855315759,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 958.46,
  },
  {
    address: '0xEE336adb8341f600385dB80d6A3AF53EfA72e6d4',
    minters_reward: 0,
    lp_providers_reward: 762.76,
    swapers_reward: 194.55263159833405,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 957.31,
  },
  {
    address: '0xA8288F0Dd028639875db739Ca3f6B60CE26036dA',
    minters_reward: 0,
    lp_providers_reward: 870.77,
    swapers_reward: 85.99610684816238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 956.77,
  },
  {
    address: '0x379f2332A198AF10d357e9737a795768B297d3D1',
    minters_reward: 0,
    lp_providers_reward: 806.24,
    swapers_reward: 149.75316935738888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 955.99,
  },
  {
    address: '0xEB9E7B9aA7b51001bE4917C79CcaA9502b85a00c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 8.540341548352817,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 955.39,
  },
  {
    address: '0xd062C2aEd5fF8A416816324619e072bED4bb4f2F',
    minters_reward: 0,
    lp_providers_reward: 87.43,
    swapers_reward: 867.6590601940155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 955.09,
  },
  {
    address: '0xFdFF57BFd8d188E05116Ec58A6827B490E29DCcb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.925272470212885,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 954.86,
  },
  {
    address: '0xaBC12Db87571036629e32853e4281c7a9906B309',
    minters_reward: 0,
    lp_providers_reward: 22.6,
    swapers_reward: 6.285628100201823,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 954.82,
  },
  {
    address: '0x063d99Dd0DDDc863FDa82368988A11E1ff173282',
    minters_reward: 0,
    lp_providers_reward: 26.54,
    swapers_reward: 1.4480640823909041,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 953.92,
  },
  {
    address: '0xCE08f30183a6718FD611D854218843a46dc09916',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 5.8665230927346315,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 952.72,
  },
  {
    address: '0x6330A2dE4f89D851b424908C5F19f9e546c76e2e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 951.8156319534372,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 951.82,
  },
  {
    address: '0xc23f8417A9d1847a6322f5251F10a817F4afb9CD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 950.3411787261481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 950.34,
  },
  {
    address: '0x2F84A6483B82b32d4739eb07407d45a6358c0aE6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 946.85,
  },
  {
    address: '0xC71B9c97a27fb2Cd0d98D449e75a63cBA312466D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 945.5398219366602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 945.54,
  },
  {
    address: '0x403DE1Fb3D8efBDD368e41EcD1A2FbB0005d4B79',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 941.0084860873346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 941.01,
  },
  {
    address: '0xCD79d915aa11D10298e87590e5025E7d84cA4BBe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 936.5411206329908,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 936.54,
  },
  {
    address: '0xA46Bc32d2e264aF53f12902fb1a31b32e969f93F',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 125.29220757400404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 935.37,
  },
  {
    address: '0xca1eFf5BcED8327CA3B32e64D6E3a724a1743675',
    minters_reward: 0,
    lp_providers_reward: 817.27,
    swapers_reward: 116.99098212212994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 934.26,
  },
  {
    address: '0x62B280C2F99924918cF72B3dCd6dd6141183CB7C',
    minters_reward: 0,
    lp_providers_reward: 930.16,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 930.16,
  },
  {
    address: '0x5CD5ec5734850eE78D5CA7663d05E2A8989e529f',
    minters_reward: 0,
    lp_providers_reward: 922.44,
    swapers_reward: 6.854027058515561,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 929.29,
  },
  {
    address: '0x9E33c04F049EE53dBADd61F3eB19761141BE5140',
    minters_reward: 0,
    lp_providers_reward: 0.04,
    swapers_reward: 657.5192167973502,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 928.46,
  },
  {
    address: '0xb2c1efD7A113dB46AfF345bbdB64F13ef45C1507',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 927.2613613464472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 927.26,
  },
  {
    address: '0xD91A3a9e976677F7757560A619aF486e195A528E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 926.4405906733462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 926.44,
  },
  {
    address: '0x296c89CCF9397B4d9538f1b57f9962b4a174Ce92',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.06847950705888828,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 926,
  },
  {
    address: '0x1C0bE90ADC787d6920503Ec0339f635B92898445',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x911317ad74EF53A0FF4392A42Def888F120b4281',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x0619D378d2FA4400f416CAD586DC173F0694e4b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x30E832FdeB09d88FEd4E84b68a58F181CEa4AdF9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x71a1215486C24fDb4078bdeBcBAFE9a3f371b059',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xc634bA7E7f831247Ff801db3c7932c35620ecf78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x26ee3073Cd3D3bfe756CbC24758843D1ceA17331',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xb6B8824E702897f763829ac54EA52974c0a91CFb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x19E42891B437edFB2cb2a0D3Df247Ed0e0d36142',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x46e6cEa9Ba3b65C64F95A3F8De47A1B4c6667909',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xA28fbe2838e7B88fC134DFbdee11e705c90279eC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xB15e1fc18AD797e0ff365C44bf6DA72F1dbFb372',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x8F096CE48FE24Cd448E9B6d17CB187A3e5431418',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x2753882f6a60d12641781B056E0d861f28629e8F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xaB014280DEF32938d8C2A1fF22fb31c1aE9ecCc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x16D67609F4cd7D6b388778fD0881D86622463dFB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x4D5908064EE2862B9B1C8b3676107b914BA57cEA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x67f8Bf2c521Af007328B74C8ae7416badc512357',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x81B09954C58901fd885a74239409a0F86973DCa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x44C1f4990c28Fded152454e35EA4c1C912bBd343',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x9DE911D489e0701D01f55536757dC730665eD8D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x3D67F4Ea34F70aD862EE64310e99b3cdaaB41Ef3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x6582b7C80dF319553a988F62436b8A1be6b2B24C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xef275666f19183d0817D860147F44995152E08dA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x4A03d9852CAd039BE7ee33cBbECA5Db868Caf06A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xCA68193e07DA540a36B9d81fe19670029a50bcdA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x801A89edaB69d7264A30C746e82AE06cAff877fE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xBc2AC17aBd69F598896c83705126BA549b5Cc8Be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xA8dE85b9282Cff30d5C4cDfe3dF80315Eea45301',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x6962d791a0a6Df1B9C8759D9751544C99a621f05',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x44ab2D6310fe054A0b43E9bc3CEd6f88c66582fB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xf685e39152a2bb62719Ba0c5E8685FDa776362A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xb62fe1E5520f10396B09732CA04B8Ffc5fFa4020',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xe7bd7e5f4A598716e2AE5F3cDc4C62dD5ba250d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x28fBa57B0b257019bc9f4ad2707ec19e724AC6C9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x3C77428E76872D392Cc104F24De869158Dc69A98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xF0315EF0C714bEfe01C01A8785af94D1273BA6d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xa8afC6BDb6918991E9D1239a10db63E497C39353',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 923.4995185361363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 923.5,
  },
  {
    address: '0xfA22325f59c7C5152C0C3d2fA2028920cB65472a',
    minters_reward: 0,
    lp_providers_reward: 470.34,
    swapers_reward: 179.38561462537973,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 920.63,
  },
  {
    address: '0x8a2fFdd15DBb40Cac30D9567b3d5C58FC198CB5B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 911.4367625063816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 911.44,
  },
  {
    address: '0x2806Ac1F682BF529B52C078f89deb4E029090756',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 910.1018216058108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 910.1,
  },
  {
    address: '0x054f9062D8e9b1F52BC5e351d083dec8a521fF35',
    minters_reward: 0,
    lp_providers_reward: 265.84,
    swapers_reward: 637.9408465001161,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 903.78,
  },
  {
    address: '0x0cDE5644d88eB4Bb1cD58602237B73EAbD0A7A9b',
    minters_reward: 0,
    lp_providers_reward: 902.97,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 902.97,
  },
  {
    address: '0x30F10b84389c08DD370a0769BB447051BDC581ad',
    minters_reward: 810.08,
    lp_providers_reward: 84.57,
    swapers_reward: 7.147324713607218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 901.8,
  },
  {
    address: '0xfe2CDAEa682699352d8F25E0E08207896551BE53',
    minters_reward: 0,
    lp_providers_reward: 901.35,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 901.35,
  },
  {
    address: '0x02C7767b63AB793f66740D41004b36A4a8AAE399',
    minters_reward: 0,
    lp_providers_reward: 898.55,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 898.55,
  },
  {
    address: '0x21c9a14C2d79327F8a90F6b71E66D9B4F01665D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 896.859475334305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 896.86,
  },
  {
    address: '0xFeb6EDa97A7407aBB0ad42Dd26D9ddC7670d8391',
    minters_reward: 0,
    lp_providers_reward: 659.31,
    swapers_reward: 234.12772996157625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 893.44,
  },
  {
    address: '0x9DDd573b5510a1FC96B77281512C10E079FEb23C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 892.0134364523415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 892.01,
  },
  {
    address: '0x695968B7B43e8Cf9483D4bc2a6017892aEf3cEa3',
    minters_reward: 0,
    lp_providers_reward: 591.03,
    swapers_reward: 29.928695713882323,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 891.86,
  },
  {
    address: '0x932ceCB858c5E7C0CA50164e5d9761e85fbfB44e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 890.3635804352883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 890.36,
  },
  {
    address: '0x59ad85D51Ce6f1A82239C6f83eA8A44F915E9179',
    minters_reward: 405.04,
    lp_providers_reward: 27.3,
    swapers_reward: 186.43481932104808,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 889.67,
  },
  {
    address: '0x281f26955e457480D4d7645dDA572B3dF786531b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 888.1840129717157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 888.18,
  },
  {
    address: '0xC1a0D1a66A495aa83Da81092e7Abc86076809bB2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 886.4912219863529,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 886.49,
  },
  {
    address: '0xf1672308647459F62Af7E4cF3FAbe48c09e872dB',
    minters_reward: 0,
    lp_providers_reward: 872.32,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 886.03,
  },
  {
    address: '0x5b3427b93B0122E1f2FC69793ccF0ac52cE9A3bc',
    minters_reward: 810.08,
    lp_providers_reward: 46.89,
    swapers_reward: 28.561174226063873,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 885.53,
  },
  {
    address: '0xC7cCcf4f9F46B32C439aa4D39B86a4b061cD8333',
    minters_reward: 0,
    lp_providers_reward: 785.49,
    swapers_reward: 95.873980861643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 881.36,
  },
  {
    address: '0xdB1b978bB967cCB964fB0963a6a46c6f31AC79AA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 333.26060175903643,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 875.07,
  },
  {
    address: '0x70cBAFCa3c3d14380d27Bb90C1c2104FdA3e06b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 871.0058096713476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 871.01,
  },
  {
    address: '0xf8540110bbDd77e9a056A784B424aC1eF6Dea66C',
    minters_reward: 0,
    lp_providers_reward: 167.96,
    swapers_reward: 700.0365819326588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 868,
  },
  {
    address: '0x7b276f5997A8Bd45b38dEA932c839bb34c1F55dE',
    minters_reward: 0,
    lp_providers_reward: 569.11,
    swapers_reward: 296.03098163421214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 865.14,
  },
  {
    address: '0x7Fe65FA57fEfCA3Ad0BF8c879E32ef604647f369',
    minters_reward: 0,
    lp_providers_reward: 796.25,
    swapers_reward: 68.5139830206775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 864.76,
  },
  {
    address: '0xb72Fc84E8Ff88b1bF3aD37273D8616B60C0A24e0',
    minters_reward: 0,
    lp_providers_reward: 862.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 862.98,
  },
  {
    address: '0x232694F42b13875881aA17FFA23aDD31631e728f',
    minters_reward: 0,
    lp_providers_reward: 843.44,
    swapers_reward: 16.13293850502024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 859.57,
  },
  {
    address: '0x0C40f93af171Bb9ccD5D30A4E5a70C52f0E287EE',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 181.63770210755075,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 857.58,
  },
  {
    address: '0xF848D2DEd87E7A1E5FE4dcA043C0FdaCbDEf6bf3',
    minters_reward: 0,
    lp_providers_reward: 335.92,
    swapers_reward: 521.1145195796902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 857.03,
  },
  {
    address: '0xa48b270aa26481733668feF373c9104eFA19c0f5',
    minters_reward: 0,
    lp_providers_reward: 336.88,
    swapers_reward: 518.9971337431605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 855.88,
  },
  {
    address: '0x097e9917e1f767617246E2654f60C1181456E69b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 852.7603711645557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 852.76,
  },
  {
    address: '0x1CC79E3869D447dFf713Eed92e2A802a4F83E93b',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 40.31180637340565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 850.39,
  },
  {
    address: '0x691F714e6468b6599c2e69e8Fdf9DF6415aA13B6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 848.6219764103204,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 848.62,
  },
  {
    address: '0x70E750D18f53A961001883ffE3Bc1D9351aF8774',
    minters_reward: 0,
    lp_providers_reward: 848.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 848.34,
  },
  {
    address: '0x02126616A46a349A0BC9C0694E478A9445375A53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 843.8335970841281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 843.83,
  },
  {
    address: '0x92B7cA6982D1D8b5b335Cf98cc2552Dda0A1205C',
    minters_reward: 0,
    lp_providers_reward: 159.51,
    swapers_reward: 684.0535738075523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 843.56,
  },
  {
    address: '0x7caEe6616FD03486C5e4662059F0EEFF663F7840',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 841.9921684834522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 841.99,
  },
  {
    address: '0xCbF98866183ab7a7923D3bCd1C956AeCb606AfA9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 840.5982981189163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 840.6,
  },
  {
    address: '0xeA49DF790cfC84b31A43c5c6A97E70e4D32E264f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 839.0715793210305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 839.07,
  },
  {
    address: '0xed1EcFf1A8C24262aBBEAeb9E472FEedf60DB6A2',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 28.69132497919336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 838.77,
  },
  {
    address: '0xE49C21B809c6122D662a6Ffe0DB02548a53FB224',
    minters_reward: 0,
    lp_providers_reward: 838.22,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 838.22,
  },
  {
    address: '0x31b8f9a85FA9B9258B5b5F1875DBD863999dCA76',
    minters_reward: 0,
    lp_providers_reward: 153.43,
    swapers_reward: 683.5202788115686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.95,
  },
  {
    address: '0x5348c39803e67377546908e0867eC31893fCdB34',
    minters_reward: 0,
    lp_providers_reward: 836.6,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.6,
  },
  {
    address: '0x91D01CCb5AB7221cE23C386D74Bb7B1D09E28D1D',
    minters_reward: 0,
    lp_providers_reward: 636.23,
    swapers_reward: 200.29606256722238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.53,
  },
  {
    address: '0xC2245A6B067AbDEa1B4b8A389De6D202370D1FA2',
    minters_reward: 0,
    lp_providers_reward: 635.8,
    swapers_reward: 200.57490142382525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.37,
  },
  {
    address: '0x3EEFe846Aa0B85cA6Fd9DD7f124d2A71e6525CC0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 836.0930553102079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.09,
  },
  {
    address: '0x53397b3edF74a1876087FA436eDC42534138D8dE',
    minters_reward: 405.04,
    lp_providers_reward: 143.52,
    swapers_reward: 16.234974762701633,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 835.69,
  },
  {
    address: '0xb7025FBFB77Ec6Ea7764161E6B274f19b3aF22Ab',
    minters_reward: 0,
    lp_providers_reward: 580.55,
    swapers_reward: 254.81385222300966,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 835.36,
  },
  {
    address: '0x51b9eba64aEEfA0dF99ED9092751cC083528250E',
    minters_reward: 0,
    lp_providers_reward: 592.42,
    swapers_reward: 242.58759918900958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 835.01,
  },
  {
    address: '0xb65270f4C1B1af0BB11829bC6BCA7AC740744619',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.505801974629215,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 834.22,
  },
  {
    address: '0xb9531b2243c0055121337838Fe6C057107a61d59',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 833.9881902073076,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 833.99,
  },
  {
    address: '0x8e6fEc5bc2451870C0a09813b0B7d11d9ecA2e70',
    minters_reward: 0,
    lp_providers_reward: 602.78,
    swapers_reward: 229.07074887381253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 831.85,
  },
  {
    address: '0x7EF0500d7B663Aa396b1Ee7609fc768799f0bdA8',
    minters_reward: 0,
    lp_providers_reward: 831.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 831.42,
  },
  {
    address: '0x98366d84e4323ba6BfE73D07c8BD02241dCa80f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 831.1387389349806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 831.14,
  },
  {
    address: '0x4A4bF7046C9782F46d6a7697fAfdC4acF5767709',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 20.615949024249883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 830.7,
  },
  {
    address: '0x82A8e300C5bC6A6E7918D3E18B344daB715E2A8C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 829.884740688572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 829.88,
  },
  {
    address: '0xC0D0A509926cCd9F167C5B7fa8272A47CF3215CA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 825.7784235052844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 825.78,
  },
  {
    address: '0x69C988BfaaF42d83d7bE0b7c82E1FcD21C5c776B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 824.0714022502245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 824.07,
  },
  {
    address: '0xE1dc8450E71D2317150A6103a24698c0Ce00dD2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.914911712760166,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 820.62,
  },
  {
    address: '0xdB7bA05196b052bD754E2A6Fa67efe05631eE8c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 820.2673469725703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 820.27,
  },
  {
    address: '0x7709faCa08b6Bd254C540F74Fe6174B01cBc5321',
    minters_reward: 810.08,
    lp_providers_reward: 0.98,
    swapers_reward: 8.565480483185866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 819.63,
  },
  {
    address: '0x513E518406F5733ed42723e1524b3bfCEda71064',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 818.8413880555952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 818.84,
  },
  {
    address: '0xe7C3A759083FCB1BFBE920B001C0a07751aA49A8',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 4.588030740918899,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 814.67,
  },
  {
    address: '0x513561D5AecD5666D70eD011e043006627aC3200',
    minters_reward: 0,
    lp_providers_reward: 287.53,
    swapers_reward: 526.0725183521153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 813.6,
  },
  {
    address: '0x1B4305D6a02E09E4EA6AC43E1CDf95B0c336B77C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x71eF8b9F1A234194EdA7165eDe366f45f33D469b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x121f49454216d1746cadd24910605BCc18dACEC5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x1b70f7ca3b3342cdcc45aa2D9233143f0279FE87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xA6296C3A9a066ba70F9d0F7d69C77eF22C7d26D8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x8f36b72bAD8F621757b4A870E7385A19D985aD0B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x6524e16af07e601FA3028787baa95bB589feD25b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xb4F387cc8B5aE416d42f854367e1343d95f9d359',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x4FeD9A7EaC6e8A2a4CFA5758502D85021Bea1ba6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xFE1C6127C6C8d1aCDA1e883e6A146CDd075F6491',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x4D39083a277c4908844dfDE44a368BA4d26BeDaF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x3329219a9E9A64c564a959dEaA013F47B3947ccB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xC64C31394Dd31eC600bed0D0cFD09036Ed500f48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x98B8d32D3AfFC75436Fb535252Ea1FF763458019',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x43aFDDCcb8088Ba252f1552964ef7F2adcA0e2cE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xd6897b3BBbdf00DAdA3A5CEEAf09B193b41a526e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xa267D3d317096Fa6CceAeba190Cb3fF6Cd082CC9',
    minters_reward: 0,
    lp_providers_reward: 811.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 811.92,
  },
  {
    address: '0x5E9a1EE447314CE02c4348C90c33F0e3bAfc5Ab0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 1.7234456831901677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 811.8,
  },
  {
    address: '0x81d51e5af90C882eE6F88fBC9464D6ed123BEa70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 810.639909755851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.64,
  },
  {
    address: '0x3D426728E82ac282653A9779b988acA414b62AC8',
    minters_reward: 0,
    lp_providers_reward: 736.48,
    swapers_reward: 73.68480179207478,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.16,
  },
  {
    address: '0xebAFD9B5183859884E2C5b99e8D934c70a71B63e',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x8356C59f9d01570a3875556b9C63E9B3c9B2d3dC',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x55F1D12A9De414c6eb62511756Ef08f82C1a33Bd',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x7BC41C0718197eC1C78709e708240eB262F50005',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xCe2628Bf21389183a0E9270DA13cA6B8D669b6B5',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x8B9a2CbF4E301c99bD513BBa7c622AFDED972c6e',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xcAf056F1382a1909A0c058EED164e150713B06E4',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xcF8fAeaCd63f606dEaD28d0499c7CAB379803a57',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x7fbFd5527C99C079DFCE04D9b7a50F9878853986',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x1be084D17aa360CBA1fAa357a023C90ddCda103B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xbB8A7a7761C75EacfD0C3f4E0e6807582A93c270',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x31884bcF23b59575d81E1FF535aD0fbb1a9AD508',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x53382DBF6d60bd60c40c8C880B335075d41D0557',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x535dE601a341228aAA78F3c57Db1d29b7146207E',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x3B6BA6D745C37E45a7c89BD32250D4517583bE97',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x65E67F4b986902905fcf8Cc89acCF1441bC1193a',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xf8534Cb39a2479f71cb18327bA8080d9451148C7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x944FDF30EBA69d6A7bbFd7512AC8eF899D1Fd3F0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x18e6c344eedB55E56f0281d6E35Be6C1aB57EeC4',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xB37bb503C39F1657e501F3a7B725350E226a1c4d',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xD226b4c30Bcab63b5F6147CD13243b1bE707865b',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x0019E3a60cdbBb49c1c01c6507fe565AF176da59',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xa24D0e2688AD9344c4413d55768e84904abacc3A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 806.2595855452714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 806.26,
  },
  {
    address: '0xa5f207F0cd4A665c82443c07033a03F1F5E9500E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 806.2450616709114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 806.25,
  },
  {
    address: '0xe7317216e893F1bBC214DF9FcAc49B9cAffAEe99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 801.1453704343467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 801.15,
  },
  {
    address: '0xB13E086d5F328E38237cc0170Ae4323635572Fa5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 800.7613274156765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 800.76,
  },
  {
    address: '0x84B4f95c32af5A3382683234Fbd03759d6B8B4Ab',
    minters_reward: 0,
    lp_providers_reward: 800.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 800.32,
  },
  {
    address: '0x90faC5B6b2C5BdC1c094041A00A647CD7c14dAaf',
    minters_reward: 0,
    lp_providers_reward: 656.13,
    swapers_reward: 143.23142708565962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 799.36,
  },
  {
    address: '0x989C6B3A2406D3EE8F85994C7f2b9478e3448359',
    minters_reward: 405.04,
    lp_providers_reward: 394,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 799.04,
  },
  {
    address: '0x3cFF508EcE5B14463C24BC1EFb0737ECe1a1D534',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 798.7903543738698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 798.79,
  },
  {
    address: '0x7691ad52e974B9663A75B11d2B80c69cE60A2bCD',
    minters_reward: 0,
    lp_providers_reward: 797.29,
    swapers_reward: 1.3173832924770525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 798.61,
  },
  {
    address: '0x73ebE702b4fb5D9C28cf035D7F3eE28Bf10C4A9A',
    minters_reward: 0,
    lp_providers_reward: 754.83,
    swapers_reward: 43.552511716095715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 798.38,
  },
  {
    address: '0x38C1649b72fD245e434B9Db29f6148A90Aa7CA12',
    minters_reward: 0,
    lp_providers_reward: 797.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 797.71,
  },
  {
    address: '0xC696065e2E24C62d4d62122B80af7a6779B74EE3',
    minters_reward: 0,
    lp_providers_reward: 298.78,
    swapers_reward: 497.33509973346395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 796.12,
  },
  {
    address: '0x12b2850c7154115c8B70C2f345eA951cB107A02D',
    minters_reward: 0,
    lp_providers_reward: 781.81,
    swapers_reward: 11.697836011910011,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 793.51,
  },
  {
    address: '0xb5178acF39a06839D9277Dfe5b8fA94d7f492b3D',
    minters_reward: 0,
    lp_providers_reward: 198.61,
    swapers_reward: 321.4749132930815,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 790.98,
  },
  {
    address: '0x15cf4B9377e7E11032D9cE3402Db9dcC700Ea7C9',
    minters_reward: 0,
    lp_providers_reward: 790.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 790.1,
  },
  {
    address: '0x1a61c7E5c30AE1B5dB61Fc174bF8a3108632fEF2',
    minters_reward: 0,
    lp_providers_reward: 401.53,
    swapers_reward: 387.989469075828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 789.52,
  },
  {
    address: '0xf829B907E05820A111954899f61702bedCC31397',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 789.4632050053856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 789.46,
  },
  {
    address: '0x26D6B5E0F2F7E21ce4822324F2A749Fc41ea89a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 788.750810225279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 788.75,
  },
  {
    address: '0x01064a1606d13921d4f31F9c6B728d0aE1EEACD3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 788.3005195082361,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 788.3,
  },
  {
    address: '0x7705a444adbC784a651A7012b2ff9eBA480F7904',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 787.7957280628073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 787.8,
  },
  {
    address: '0xA0Ce0C71Aa6b7F419D66Ba80Ea293C63C5cfC036',
    minters_reward: 0,
    lp_providers_reward: 504.29,
    swapers_reward: 282.38426054821275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 786.67,
  },
  {
    address: '0xf1E27D078dE19569b45dC6a1419a9c8f31824AE7',
    minters_reward: 0,
    lp_providers_reward: 783.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 783.1,
  },
  {
    address: '0x9cE0ed21E486Dc492088c40C81fdcb1FDCe36461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 781.3344010139667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 781.33,
  },
  {
    address: '0x7C2513e242055708334EF56C626693d5D4AF60AF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 775.9854251145242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 775.99,
  },
  {
    address: '0xF1D4772342b05a27FcE6F8C86fEdEf721808DC4E',
    minters_reward: 0,
    lp_providers_reward: 774.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 774.46,
  },
  {
    address: '0xD6D474C4b07deC796990B8c2d588867ED10FF4EC',
    minters_reward: 0,
    lp_providers_reward: 773.61,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 773.61,
  },
  {
    address: '0x7B1E3662689776d9cF46c2D960eca0b86c105953',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 770.8719220243262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 770.87,
  },
  {
    address: '0x30E4cA18ae8F9fA4993663608Edb1DfBB74D7aFf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 767.686735345523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 767.69,
  },
  {
    address: '0x97044CD4f5B68D73202472aF6B96fE57f74Cbdb4',
    minters_reward: 0,
    lp_providers_reward: 117.97,
    swapers_reward: 648.965843709891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 766.94,
  },
  {
    address: '0xF45d7dc442E9455674D0019324165fFf1383Daa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 764.2837263739915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 764.28,
  },
  {
    address: '0xAC695A79Fd53ee3324Fc9110E5182f311A2F7482',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 87.60159701138481,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 763.54,
  },
  {
    address: '0x00DF6a29109FC7A247f8F54E761e5f5DccC0Ddb6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 762.5962777336312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 762.6,
  },
  {
    address: '0x3cC221896aA3EEa547bfaF98bc894f39cDc9A393',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 762.5103795652087,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 762.51,
  },
  {
    address: '0x196Ecc76EDcA2b081AaC3A5F64b1e27d72de15BE',
    minters_reward: 405.04,
    lp_providers_reward: 85.68,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 761.62,
  },
  {
    address: '0xcA126d8fABBEf6fDBe10c6082AF53BB02c759E3e',
    minters_reward: 0,
    lp_providers_reward: 760.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 760.52,
  },
  {
    address: '0xd1a5AF86A0E064F01b4ECe578C0FCB57BFE73983',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 756.898279231954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 756.9,
  },
  {
    address: '0x062A3f0c9926237d54aD0047f43Acdfc19dAA65f',
    minters_reward: 0,
    lp_providers_reward: 756.7,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 756.7,
  },
  {
    address: '0x2b65099bF02977bE63f44D3C1740321aC9bE154D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 755.625160128189,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 755.63,
  },
  {
    address: '0x3Ae9942F2d95467ce1A0D6B47dC6175ba494A213',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 79.33443325680666,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 755.27,
  },
  {
    address: '0xBf52864FeB04CEc892F4368BB7A285955AFfFcE3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 79.20603089940909,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 755.15,
  },
  {
    address: '0x2bE26273F94554c54d974B86cCB0F078e7F2aCed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 755.0255742069468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 755.03,
  },
  {
    address: '0x3Fda54a292c47e0CB4E93BaD6Ff917db224628EB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 754.9169593981779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 754.92,
  },
  {
    address: '0x03675F2f2343776cDb919E20E3d92C09aD32ba65',
    minters_reward: 405.04,
    lp_providers_reward: 78.97,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 754.91,
  },
  {
    address: '0xA62A7a20c38FD87d0ba3f06bfC84a3BcE7596120',
    minters_reward: 0,
    lp_providers_reward: 754.07,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 754.07,
  },
  {
    address: '0x3cc19dE595605E17c466d05d66c078D213D9256E',
    minters_reward: 0,
    lp_providers_reward: 752.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 752.96,
  },
  {
    address: '0xc4b7321926E12B0Ad24b9cffE707bF15bb225Ad5',
    minters_reward: 0,
    lp_providers_reward: 752.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 752.48,
  },
  {
    address: '0x0C8f0C3e8C3695848a0AA4eDe18EFA6101dc935A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 752.427719469625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 752.43,
  },
  {
    address: '0xf01F4510F4311f3607B186936b2D5a072C27bbC8',
    minters_reward: 0,
    lp_providers_reward: 216.78,
    swapers_reward: 259.78485469049366,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 747.46,
  },
  {
    address: '0xBC5a40C920963541aBAa5F8C2C2639BEe32E7d12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 745.2913257087215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 745.29,
  },
  {
    address: '0xea1420c79A940B2eCFE694ea76aC6A21eeFac410',
    minters_reward: 405.04,
    lp_providers_reward: 28.08,
    swapers_reward: 40.60789692154541,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 744.63,
  },
  {
    address: '0x24b601b11188946547b9B5a339163aDda767D504',
    minters_reward: 0,
    lp_providers_reward: 420.07,
    swapers_reward: 324.23931829088747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 744.31,
  },
  {
    address: '0x9Cb5230d4f3Bf11B32b292af4081705609C71c20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 740.4187882897688,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 740.42,
  },
  {
    address: '0x219aff82A0a6056c74d394F5b748167640DEC2D0',
    minters_reward: 0,
    lp_providers_reward: 417.17,
    swapers_reward: 320.98590856669153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 738.16,
  },
  {
    address: '0xb05185f9Aa0727bdDe3BcBfb8afdCE059af5fD00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 737.8209140559727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 737.82,
  },
  {
    address: '0x4E8644349BfD39e8d4b0c49475bf5e9D37A33981',
    minters_reward: 0,
    lp_providers_reward: 435.12,
    swapers_reward: 297.88326730267863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 733,
  },
  {
    address: '0x9b0760bcA562A450a6eBcfb3D1aeC37eb53D7127',
    minters_reward: 0,
    lp_providers_reward: 732.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 732.45,
  },
  {
    address: '0xda3c0338F9b8A081EFd1b0Dfa0AfC2b317Cd23d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 730.4353055008455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 730.44,
  },
  {
    address: '0xA6DD5f927D07C85170f33ecD25e7B03cF02289FE',
    minters_reward: 0,
    lp_providers_reward: 307.92,
    swapers_reward: 149.83312262719954,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 728.65,
  },
  {
    address: '0xa48978cE39AC8526cD840AA303203Ce93079FbaF',
    minters_reward: 0,
    lp_providers_reward: 169.84,
    swapers_reward: 287.706240896658,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 728.45,
  },
  {
    address: '0x7BD36Fe9dBFF09192fca6F58D5C59d40918564b0',
    minters_reward: 405.04,
    lp_providers_reward: 42.14,
    swapers_reward: 8.73376665913563,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 726.81,
  },
  {
    address: '0xdeF11402e62d0a36772e0D767756A9d00132DB15',
    minters_reward: 0,
    lp_providers_reward: 724.17,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 724.17,
  },
  {
    address: '0x847b06988E4937DA3f827BA9BC06b4aad6bbadF5',
    minters_reward: 0,
    lp_providers_reward: 474.9,
    swapers_reward: 247.92429227145948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 722.82,
  },
  {
    address: '0xF88f69104c13b16F2567937b580782607D96C7fc',
    minters_reward: 0,
    lp_providers_reward: 722.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 722.66,
  },
  {
    address: '0x8682dd163520F546dd29C872a98FFF373d834f5A',
    minters_reward: 0,
    lp_providers_reward: 534.46,
    swapers_reward: 187.5641444652874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 722.02,
  },
  {
    address: '0x660669E6017B826C67616dCDCF300e28EF2c1bC6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 720.0385099428482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 720.04,
  },
  {
    address: '0x80B0d3dFF4207cB30F148acB3DCd637172bC7855',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 714.6507862418064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 714.65,
  },
  {
    address: '0x515E46FaB034F14274Bf71A7D524CDCdCa155Af3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 38.514120646341425,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 714.45,
  },
  {
    address: '0x9EA738148255E3C176DC3575576acf738a481767',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 712.5640095546082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 712.56,
  },
  {
    address: '0x571B57D382b082340cDf80c6A37254E82d0e529b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 712.5242400882868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 712.52,
  },
  {
    address: '0x05A3081464acD5cAf4e40F4480933b802176C2D1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 712.4687504302931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 712.47,
  },
  {
    address: '0x6F73A9D2070AeD7D1748980D4dDee8E2Cefc273a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 710.9277400869294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 710.93,
  },
  {
    address: '0xa401C24EfC87ecF2725aF266E18E874584327bb2',
    minters_reward: 405.04,
    lp_providers_reward: 22.03,
    swapers_reward: 10.832552608040642,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 708.8,
  },
  {
    address: '0x8A449F3d25cd625decc073Ca67Cf60b561Ba193d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 31.598259386418075,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 707.54,
  },
  {
    address: '0xb5602aD7bBf1E1aADE67702F70a97Ea7947bd576',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 28.29907874968423,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 704.24,
  },
  {
    address: '0x18169D0136477d7BD7Aa540457932139D0951682',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 703.7646697612578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 703.76,
  },
  {
    address: '0x133830B3D5b6A6229DbeC2Ee7F3cf71995B682A0',
    minters_reward: 0,
    lp_providers_reward: 702.82,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 702.82,
  },
  {
    address: '0x01757bcD7aeD10edcC764c67de65E67f548cA0C9',
    minters_reward: 405.04,
    lp_providers_reward: 10.84,
    swapers_reward: 14.666307731836582,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 701.45,
  },
  {
    address: '0xC5D35cC6260a291fb4B23979CE8BdA6e3a76EE12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 430.0607387669521,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 700.96,
  },
  {
    address: '0x02cbB284b26abeE12EEb3E5A7e91bfE9762d5560',
    minters_reward: 0,
    lp_providers_reward: 357.76,
    swapers_reward: 341.3800345332608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 699.14,
  },
  {
    address: '0x4f27C877B20607C5a681C2c6668F0695aD2d2AC7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 699.0489856749572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 699.05,
  },
  {
    address: '0x5Fcc31b973cf5E9609cFC792d30299A3d6670D78',
    minters_reward: 0,
    lp_providers_reward: 546.58,
    swapers_reward: 151.69729455893398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 698.28,
  },
  {
    address: '0x0d5d19Cd361A021894ca37312B4c11832c211777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 697.8961778064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 697.9,
  },
  {
    address: '0x576D166b1Ad26255cb9A3FB788385C0DC0a39C56',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 697.3649024699642,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 697.36,
  },
  {
    address: '0xD67D81E07B18De7e643480CD9F56BC1A498Fba3c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 20.49733962111166,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 696.44,
  },
  {
    address: '0xd9268C48b13Fd3389FBf9186b38b1a32025ba372',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 19.880182211643575,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 695.82,
  },
  {
    address: '0xE33D44ee5AA3A5E7Ac44aAF4B5959d23D7a1F9Dd',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 17.943787127716025,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 693.88,
  },
  {
    address: '0x4Eb3CAfd85e2c9d4247C9A29804B55bfF0a5A5E4',
    minters_reward: 0,
    lp_providers_reward: 519.52,
    swapers_reward: 174.15019545887023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 693.67,
  },
  {
    address: '0x224167FB7ac323e9cdAdfAC7DA1bEa84Ab68ceC7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 693.3723405525737,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 693.37,
  },
  {
    address: '0x6392ee6eA843e70a30ca4B21978f5f2fd5A94827',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 15.396015508499751,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 691.34,
  },
  {
    address: '0x7a69Ea4d0BB04452C9f54aCfd258a9d855554340',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 689.5506554048882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 689.55,
  },
  {
    address: '0x209b1DAC73A95bbcb735B2Ed22B7C34319072BE0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 688.9439757032659,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 688.94,
  },
  {
    address: '0x8A3F2D114e12FD377ba17082D93B60751731DC7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 688.1165772759332,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 688.12,
  },
  {
    address: '0xf90b156E8c094A2348D66B669B6e79A407F9A067',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 11.652179152852584,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 687.59,
  },
  {
    address: '0x77aDa9CDcC8094afA5c8d06D74cF7caB7c494E30',
    minters_reward: 0,
    lp_providers_reward: 687.18,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 687.18,
  },
  {
    address: '0x33b3C4CEa42A869Af8EA9f9A2321AfEFAF9969B2',
    minters_reward: 0,
    lp_providers_reward: 56.72,
    swapers_reward: 628.441817290464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 685.16,
  },
  {
    address: '0xEe02dB235e130EbD34AC9ec10ae036434a88e372',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 682.7681542300504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 682.77,
  },
  {
    address: '0xC353b211E3537e39bdaBf7469e07b0a6d3Aaa92c',
    minters_reward: 405.04,
    lp_providers_reward: 6.5,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 682.44,
  },
  {
    address: '0xb6773Bce5418AEa4cd00F62572f4e5EbA8Bf2972',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 6.347012983976937,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 682.29,
  },
  {
    address: '0xE832f15e797b92Fc0938bE2f5e4513ED2734E2F7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 6.315764169741034,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 682.26,
  },
  {
    address: '0x987517E3d429F428157996838b8CB33017d4F680',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.5940539121032,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 681.4,
  },
  {
    address: '0xF8091823e14d51B0B4Ab9f5eFA4f7075eb5ca1F8',
    minters_reward: 0,
    lp_providers_reward: 681.31,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 681.31,
  },
  {
    address: '0x9B0F7296cEa3314c6cc224002A929CE14e856739',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 2.692140919513858,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 678.63,
  },
  {
    address: '0x38EfEd32B291321178B4F1e54c4fD5716B673443',
    minters_reward: 0,
    lp_providers_reward: 193.84,
    swapers_reward: 484.2471688574982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 678.09,
  },
  {
    address: '0x80Ce10bbc76361018DC40d0A7591ff760Da0822d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 1.8511329723731067,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 677.79,
  },
  {
    address: '0xE10E2b542A5CcAa880cA2AB3B6D3C60F0B69EaC4',
    minters_reward: 0,
    lp_providers_reward: 53.14,
    swapers_reward: 624.3207923316337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 677.46,
  },
  {
    address: '0x6FDFf6Ed330f7caFE633bA5813C80691757aB45c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 677.0268932212006,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 677.03,
  },
  {
    address: '0xB1e9CC9b2A7EfFA6A980B7Bc22Dc79F1Ae9828A1',
    minters_reward: 0,
    lp_providers_reward: 49.38,
    swapers_reward: 627.39835721523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 676.78,
  },
  {
    address: '0x76bE46ed1C0ca46A2c137AF3a8A230d46fa6F6E2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 676.5623598017718,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 676.56,
  },
  {
    address: '0xacB69430EF831609cf6FDb4F113Bc72eB6A0CD32',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xD2B29dc4dDAEe4D2aA030004561400Ae562C42a5',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x2449630a5A933aA7435b8049b9A5435Cc7af3701',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE74d90762d7219ecBC828f0A775247299df4A696',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB2981424dE323D821815958E418c506D01FC557F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe9dc18BA182d2124e995FA4BDb708f1aC348CA34',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xEDe3c709E14D023B68FdBFC1F98A394E72822a8a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xD34cEb3Cc534cc854A29B93F3630c014519Ca6a1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xAEcA69863E59F99f4f348F55f73c3998FBA1Df5B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xd65e7EB1eF529c26455de82e0F37808D04Fe89a2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB42b45dbc6Bb29D2B4a17eB2B0Ad19877C2f962A',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1D08C120eA9B4eD9ac992B93470C9fb7ad1C033D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x09955409a79996e12929B4469Ab94Ed8A6970108',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6F5A7dE7b0Aa5Ca1B362F1CfE57C8ad48491a309',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7A04E1D5CB5b5F62919901e0591A9Ac3543D326D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x4f4cE98753e2722B4f7877425Fd5dC76553e6BBc',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6Ad13E784F52Cd57D5407bA25dC6Ad001508ede1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xb11247f62Ac393dDd106472654C5fC84D80c7014',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9A4A3324e8DB172a0fDe2167326784F7370209C4',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xb459e3b87CeA22568351E57C65b9B4cBc09807b8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe112E469A3114cb16673046fb934592eF277Fb52',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xa5A2dbf79AE114C5A4026dA8dc53Af8bD17c9298',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe5af0f47cFc7C8324325Bbfc93C27517DeCDE23B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x81d96D76e102f628fC2a4ce149DdAB2c7ceEf04D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x03F11d30318d0a4F7b61fB29da5cf35393DE4d6a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x2Ee112B83Ba3Cb7351506cd096bc78dFD0f66EF7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xC7791a20d6Ca4b35DD23Ef9c5c97f939FA504642',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6602A52c33C9B5B7E9bCfa26396F612Da22Bc17d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1F771289de82aFB60951a2567C857de0586df4D3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xc9BF461E4C8c5Ef9050abbB42e1DEe090A094535',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7be76f25757C7B79c599F5283A251B843F89c061',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x483d2daBC87A6679eef31B8683d47A62eE795676',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x41cfae546b190C48D435Ec09ccb24E9834AFB598',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x25eB0a6CDd32C6162759d86E7fDbCacb715C838b',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x3eF573342F845e2DD87182B00f10B7bd6757d344',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xfe2d3a1e84c18051D0fDFf16eEF27906d2cf0CD5',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xAACF4f71E6c6E7A58E00e66aBddeC66Cb9Fba242',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7c1B93200576a0F8D21333d8E1f59d065d6a7683',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x66d7327Bb4487099F69EC90574f8BB6dAeE64285',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9C1C1cbD67f6071eF835442731f4E37D89eea52c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe0d0c3b0b80E11E622FC361377add6fA9DaD0C9C',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x3B2E5A6e81789e0DEBB8906419Ddeef9968f0528',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9Fe711926391ADf52aC21D466A6147C55CBCCD9B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x407c2aC63e0959e718f10E8D2114514db1DEFE89',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9f69e8649aBEEAf52Bf02aF5A880c8C35552F0B0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7f721A4b83350fEc8683577C1f314c0A0a849470',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x5490D6abc8da43C631fdC4B03190CC9433571595',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB769877330daE37882cE6ACF392866195621a36F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB2C3c27bf78aB22e116172036aa38f5AD80ed937',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xc13C68e5b3183D9F5EEB2d111E413ea79286810c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xD4036b9AF3a4ed77fc3edbc03e0a2dbcb3aaDc85',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xC1eB3c55C9FD35a9A43F977fd497965d394028E4',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x05Be96bdC6756ebd03b94110bD83fB0EF70472f7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE6B503225773Db5491eE1bf988e502ef6D6FaBBF',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe2A451B623dA5e1bB6D2f582A48E152F6F6ab522',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xaa2C2FEFaf30cc5E6558Fd9d657396d32FdAE03E',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xFE33Cd204C811aFA6342A93c392649a1b547c818',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xEcFf9d177809060D282A3fC0f628C7F0a11c3744',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x184778C8e48A9DEDA8D887442750E74Aa1faf5Df',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xF10a07179723C54B9f43FCc6E145B026D3F58E7f',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0486DAE41EDd3Bc89D85e9bBd438037311453aC6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xa1044e442AB81FcD803A10AF9F7dc98c7d0d4e14',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xfbf57a674b59F0A7C883FB0E6c436C68204f3D9A',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0De0049a21b4355c5B47F549b5AF909477f7F5d9',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x94f82C9236Ef214813009F32a74ce674eBBE37Ae',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x765b0f292a2202932D0de7f8f58F7B91fF7768eB',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xFc1F1c04F2593731fD096ef0f882b3325768a844',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7BeF9FF54535608f8051a9D187C3B841bAb97709',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x427E5697747B64097864bC681D59ae4AAb3DcCb6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xAc69faDBACD44BF75532E19ED8E57eE912102bfD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x72254ddBf4dFc87a5eFd6606d3a01B59331d2cf2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x8924DAE31f9c793Cd01a600Ddbf166ae1cCc3Bc1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xcca732684d36403BA0285CfF524e543d8e3699D3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xD4C7e2cCe998FB8c48a3aB95eEd796f993D99D87',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xEF30d12D7BC47Efa87749Ed037890044bdCC9DA4',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x19D6Ab1745adC0F0B99548ccDF96AA3979A7B329',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x5CC5E677Bf5329e80cF758EfCBbEE96D7Ac95073',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x81695cf52Ef8E1A35Bee61Ed150E412CF8A51d59',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x700603B3a0D7E6737dF302BC6632688627ca1bcA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x94af50af8d11fbc732030e63AE29451f770F7939',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xf0C6C0bb16ad5bCf150064985A80fEB99bBA2CA0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x56FA03a2Fd0Cef1fA4e048b2c584430eA653bD74',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x77E12B3a6c569Bf9f4b7F6b773cA0878941D0Dcd',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xf9a8834FC6CA50e9987Ee29E48F7CB7EFcACd509',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0E851c6d324Fd4552162C8bd7452678106D3dcc5',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x591ED444782fEf715B741ac51aCA130035136E8c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6dd261E14FCF8E701A98059F96E289b3c9736781',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xc2679002744FD321b8dEc2483B6b32758a4E2D7e',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x110FBE24AC9d1B5287C7e700f68efD2d228Fa3BE',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0fE0f56dbBa181c3297F023e4Bc91bA903f55bBD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE264c8D12B50F5f9E89F6F6852C3dA4c6E8102ef',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xb9EEE49D5D6F187fc9FbE26885Cd5B4AcAE07E28',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xa1C34D579BDf994c1cDF733d3B2DC04624eB8351',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xc489f5366Fb596b1E708258bfff029105fB4ea8e',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xF484004F923480e1E4596BC7b20b3B4637889A2a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x03E068f509fC8d18Fcd13Eec39a729A318A042b7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xCa0D19E774984b86df05A9BD936370fa7cBeD25C',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE6b409E11A87aB837264B15717008CfF08B6A628',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x4D8aF6d5637D1c564556eebC3c13F0dea509b9F0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x8eF0cDd76818D55EfD2BACa386734C520263b5b3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x483a2a394c228beD5eb29d0557C182896781Ff67',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x52c063fF28c20e1fCFE2049A19C4B9BaC7f8E313',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1600389a91a1c481aEcE27BE9F400495Db39DDbc',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xEDe74E99C15ED8e2bbA8Db7cBa3B4466Cf4c9BC3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x8d41466ec04D2E7Ce82BdcEC0a5eA4341d5F2F09',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0255ECD9c775A7361a6d3928B35B8F819163Be08',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x81a434F395B6525106DCBFF6FD071F1F8ce6Cd1C',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xCc3c0bDF1746F1b199313d1ff5b13aA29A3bBDf8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xeC8fA904D5ECF8F012f0Aa1b30F650e5b69Be657',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB0725DFDBE20FeECcA9454588B8894CC0a3e96e3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x744b44c2b0f01649eb65b8C3D8c2Bd825403985a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x018e727CE6ED7754e9cD9DF9Ac1e9EAb1b444cdD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0179f665e46e456954F00d5475B306E613A9fCdb',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xA23c07A701eee997d8Dc34E9eeE77962b7E222C6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x52e8236D55a11c1Be5CD01A7818657914B5e357F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x269854AC2F9AE3c35898Cef6DEB40f1711cbaC37',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE3a5F2856A6747ac7Dc5c4f1afae65700d3Cd8EA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe5f81c417Cef1b578151F75D8B4443516173D7a0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x828D7f8554546A6328c58050ADb83F87f72662FC',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xf15bfC92DaB10e206Cc5CC1405703F20A69eF5a2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE344Bb1b5b76cBe395DFf923230266dD04232C79',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1F7D61146756F57e4F52002F754B93A02BacdF3c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE1eA6c8242df83E5f2DCa6cf9aF83fdf9E06D503',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x8D98bf87b40C0265AEabAB1D246D968FAbf5781e',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x35799Bb2B931C881e10DE14B724ae2075DF96A5b',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1218ae63bEbAF211b4A3eBa2Db80A1944F88fCC3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7Cf8377c8cE6dECBB86B4Cc2cc5C7b8DFDFb4165',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xdAC2E69A3313B173c551667C2Ba7b4c272F306E1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7959Bc163A20DA08e85e3d34487d14a6Cc630664',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xeBC1AD03D8ea7402692b759DEd8e8CC537884659',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xDDb868B6d64471c89999c3affBEc49FDa291B6e1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7eDcdF9aA7411CF395988396c50E1D2B7E9715Ad',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1349E61596bDEfF11d6efb4a1DE8bC9B17a39d4F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xA0277871A5cD0ca82c0CB1eBD507Af49392E0629',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xcc67763AE87f84376254De87c3CE313425fcbDa0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7439a0B214c2507e1f333c8Fe865f1D2b61d6c96',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xF897eB82C8360367CdFA7398A2bE620FbC3e8eb9',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1797078687B258264d74158caCFAfaFC71FC69a3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x73686B7D232669469BE284ef9810b52255bF14C1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x22E27e1814325fC3c3c4Dc04DA738D25395c97eF',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x588f8305BA37EF6B187CC6601779382f629D7025',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x85527009b8d527CF4799500F0485c2e5EE52452F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xF507E035b9243AE54b9bcA48dC5448D2888f50bD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6331d2C75BaffFC76672B60556DEec15DfeE86a6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7574B7e7620F233dC90d8B4A04db3c32cb81381f',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x3431D6D5D53b1159bFebB282A811C8E03D827030',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x49E7125146bC8BEfb55125BB07f901e1F607B371',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x71C7684B52bE0F3Bc29E420De36623bAd625d519',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xa85F3d2f44A30Cef635b4d29f00dE5081eb977dD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xb15Cdd0084e492eBE16a1858e8c578c3c369CD99',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9d780c88DfcD23C92d96F1d7514f7739a2313f4A',
    minters_reward: 0,
    lp_providers_reward: 675.87,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 675.87,
  },
  {
    address: '0x809Df8E77aD18Bb25a0d33E3259a987b11587df1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 675.8190957630437,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 675.82,
  },
  {
    address: '0x81D5604292076a00308733df68453eB6B497888F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 674.1671175991303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 674.17,
  },
  {
    address: '0x2A0a93411878B1079BBCd9F2f46fbC8Aa7Dd3278',
    minters_reward: 0,
    lp_providers_reward: 518.54,
    swapers_reward: 155.6190310640577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 674.16,
  },
  {
    address: '0x2b82e1b9Cc3a0FfeD2CBa1F8fd24FFAF7B9a1123',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 669.8260636221232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 669.83,
  },
  {
    address: '0x9Cf4865275CB5d966DC48305EEE9C747b75aB8dB',
    minters_reward: 0,
    lp_providers_reward: 668.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 668.15,
  },
  {
    address: '0x0B897Bc0944bf6BE31d4DAeB1561424f0EDdBB91',
    minters_reward: 0,
    lp_providers_reward: 221.6,
    swapers_reward: 444.1262177673586,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 665.73,
  },
  {
    address: '0xCf479aAF2B34A49C080c390Dd344fe3ea9Ec3193',
    minters_reward: 0,
    lp_providers_reward: 665.36,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 665.36,
  },
  {
    address: '0xC0330348d5E8a391bc7cBc9F7C8e112522E2E8aD',
    minters_reward: 0,
    lp_providers_reward: 664.06,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 664.06,
  },
  {
    address: '0x9811aF77E51Ea3C09B2ca203eCe0ad2C0E43F17E',
    minters_reward: 0,
    lp_providers_reward: 632.92,
    swapers_reward: 26.486867488645846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 659.41,
  },
  {
    address: '0x054B6D111a54eea555644801E53c67b6CEd98ba9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 659.1910474217415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 659.19,
  },
  {
    address: '0xC509BC0D2F6631691Ff5F619eB11fC34Ccb1f22d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 657.0820215111601,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 657.08,
  },
  {
    address: '0x7B73e8cC7Bc4Fca54759a2390343F730d5D6AC3A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 656.4444596044926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 656.44,
  },
  {
    address: '0xD2769D97d2d3f133D98f43703EebeFbCD5abdD27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 653.7745759047939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 653.77,
  },
  {
    address: '0x5C3EDBEef66275288B79C171d8087c4F40501D5A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 652.6531980528208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 652.65,
  },
  {
    address: '0xC2fa2920BA5D871CB7C54C303dD16D777e14026B',
    minters_reward: 0,
    lp_providers_reward: 429.85,
    swapers_reward: 222.24651341182405,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 652.1,
  },
  {
    address: '0x0dF4211BfadeBB7e592261c7764fDcb62b653e3f',
    minters_reward: 0,
    lp_providers_reward: 328.39,
    swapers_reward: 322.93436487978494,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 651.32,
  },
  {
    address: '0xF37f3D5F77DA1D183Fab2e47ec6CfC3BF288C927',
    minters_reward: 405.04,
    lp_providers_reward: 198.28,
    swapers_reward: 47.80697895603813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 651.13,
  },
  {
    address: '0x801318E3772dAD4011e79e31D91b7480f76D0abB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 650.4301265483299,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 650.43,
  },
  {
    address: '0x8c39e0A768B692F2bb196e9EC45f0d710420c609',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 649.2394977444429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 649.24,
  },
  {
    address: '0xd53cBb50E42918DA2fa1818D6367C3Fb518a34aB',
    minters_reward: 0,
    lp_providers_reward: 648.03,
    swapers_reward: 1.1926639268504993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 649.22,
  },
  {
    address: '0x53a3c047A652063de9836a1f4B0a6dE80A65C81f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 647.2180536273651,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 647.22,
  },
  {
    address: '0x935e9b501e535011edeef728D93c3ae059026B0c',
    minters_reward: 0,
    lp_providers_reward: 410.17,
    swapers_reward: 235.0403224293869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 645.21,
  },
  {
    address: '0x2C8cDdB4Ed5Aa7d28fcfCc75313dE4286e0b5e32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 643.8191388222125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 643.82,
  },
  {
    address: '0x67fcbFFCE6a5E03E3c8b3907407549989673512F',
    minters_reward: 0,
    lp_providers_reward: 456.85,
    swapers_reward: 186.33334166792858,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 643.18,
  },
  {
    address: '0xDC30659df09f979C77bE97e5193de5d0138872F4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 642.3381567859831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 642.34,
  },
  {
    address: '0xb0683953C8bbC925B8DCd449FFC043f0BbdB20C0',
    minters_reward: 0,
    lp_providers_reward: 642.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 642.25,
  },
  {
    address: '0x0276aba641ab5a8d3D41B0Ed98eA17F64e287A37',
    minters_reward: 0,
    lp_providers_reward: 641.95,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 641.95,
  },
  {
    address: '0x6D427b95b5bFe92d54e6877F81f03Ac29b1F023B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 639.6600775840238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 639.66,
  },
  {
    address: '0xAB063f52b380237579D357F82ADCF9F69e3563A6',
    minters_reward: 0,
    lp_providers_reward: 585.48,
    swapers_reward: 52.40025679486327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 637.88,
  },
  {
    address: '0xE501C7a3694D5016b6d00f33A6d2712AC97293a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 636.7599545635929,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 636.76,
  },
  {
    address: '0x9bA395C6c466aAD1A0A064bA51C76c9cc7325aa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 636.6324367437311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 636.63,
  },
  {
    address: '0xA1B3B143104a02489e1d1Ce69F1C97Ee3DB5D1E0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 635.351548684989,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 635.35,
  },
  {
    address: '0xA7a9B1940Ba555f11876E3cF74aCa48C84B0c63F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 633.4638916780824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 633.46,
  },
  {
    address: '0x7Ee27B531C804bae74294254416B45EB891CA39B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 632.7042392285106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 632.7,
  },
  {
    address: '0x6BD667f118fdF3BB3316F6F74A0e5009f85734D3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 632.1556740494395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 632.16,
  },
  {
    address: '0x54d25506984fB1E73484F523e02951ac4e253D1e',
    minters_reward: 0,
    lp_providers_reward: 631.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 631.94,
  },
  {
    address: '0xF43e8C65956aef41f53b2Be01845E01880897116',
    minters_reward: 0,
    lp_providers_reward: 371.49,
    swapers_reward: 256.6984740357372,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 628.19,
  },
  {
    address: '0x2aE4AFa2dd52C7a9d34E75dd8900bd44E5315A03',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 220.82226929403564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 625.86,
  },
  {
    address: '0x183A60AB7f1B91F56518974e462C7eb58e917E20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 625.6625964251813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 625.66,
  },
  {
    address: '0x78c3c32938D311DC8598F91D3EDa3753d9D583de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 622.8133908638703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 622.81,
  },
  {
    address: '0xa1212d6D46E35402d7362d2b9141AB80440b36D5',
    minters_reward: 0,
    lp_providers_reward: 84.45,
    swapers_reward: 535.8227032327671,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 620.27,
  },
  {
    address: '0xd549d3aBd0CA4aFE1c3dF9F6F8da05497CCFa1CE',
    minters_reward: 0,
    lp_providers_reward: 618.12,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 618.12,
  },
  {
    address: '0x513B21a65Fc2CeB8E1Bab3D07f8871fBdC7516a2',
    minters_reward: 0,
    lp_providers_reward: 617.74,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 617.74,
  },
  {
    address: '0x68F701Cb658B409d11236f987713f63a854a822B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 617.6824643307771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 617.68,
  },
  {
    address: '0x7D804F283E54f9D7EF5f314EC74C56F503AdA4B9',
    minters_reward: 0,
    lp_providers_reward: 52.58,
    swapers_reward: 565.0433309770901,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 617.62,
  },
  {
    address: '0xe5f40c99E569D891B052964c689836461305Bdec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 617.0671407040658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 617.07,
  },
  {
    address: '0x119A9A3E0993A187F1bC7e30cE6cd967710D2d86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 616.4508413782079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 616.45,
  },
  {
    address: '0x1E021509548093a7073c7B3bC07EE154c18e26Ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 614.6680191263814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 614.67,
  },
  {
    address: '0xAAa4eDB369DC8f5F0b63EFdaa7dd9f934F29643e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 613.7409782744076,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 613.74,
  },
  {
    address: '0x86a593DF74cE5637FbD8b88daDF1bC84B99C7E03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 612.495882574851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 612.5,
  },
  {
    address: '0x896d93F1DfFc0313BE17e9eB17566be1fF41D284',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 610.5049778559877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 610.5,
  },
  {
    address: '0x2056d6adA53f9daac2850ffb29b4ECAbb1AFd0d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 610.2419770778406,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 610.24,
  },
  {
    address: '0x135f845fc7B133EAc6202b5c7f5036e0A0E77d42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 606.619564549725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 606.62,
  },
  {
    address: '0xC37108B03A83eB9c8ebb5a14b506B2659a50956B',
    minters_reward: 0,
    lp_providers_reward: 458.25,
    swapers_reward: 147.36111873481855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 605.61,
  },
  {
    address: '0xbcC0c3b5065649A3DD3641B4812d285494FCEdf0',
    minters_reward: 0,
    lp_providers_reward: 376.76,
    swapers_reward: 227.924561241531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 604.68,
  },
  {
    address: '0x0d06ebB762a1E0BAF67fdB878D5C58863248AC01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 602.8734770277937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 602.87,
  },
  {
    address: '0x9cd004B52898EB4928156c1D67b4Fb31Ab7f4c9e',
    minters_reward: 0,
    lp_providers_reward: 600.08,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 600.08,
  },
  {
    address: '0x94A900d08D349e602ADf137412F64095aA548022',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 596.7665200872794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 596.77,
  },
  {
    address: '0xbE76b20576D152d1Ad01BA9f218a54004e9B739a',
    minters_reward: 0,
    lp_providers_reward: 143.36,
    swapers_reward: 451.87017720069144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 595.23,
  },
  {
    address: '0x4cceA44106ED8aF5AB884c33008263195C105903',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 593.7438914639099,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 593.74,
  },
  {
    address: '0x09Bc324BC5978695E1cee0bb2b072d7911959394',
    minters_reward: 0,
    lp_providers_reward: 265.53,
    swapers_reward: 324.85433183911806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 590.38,
  },
  {
    address: '0x6ac969407E803Fcd83Db727892b49eeBa19479B2',
    minters_reward: 0,
    lp_providers_reward: 245.49,
    swapers_reward: 342.8683489847914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 588.36,
  },
  {
    address: '0xc29fB3e79e8d72326f288dfc677226b889329621',
    minters_reward: 0,
    lp_providers_reward: 588.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 588.33,
  },
  {
    address: '0x23F7ebe0C643d657D920D3e2C0f438Cd0A673Ec3',
    minters_reward: 0,
    lp_providers_reward: 302.11,
    swapers_reward: 285.7413322118732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 587.85,
  },
  {
    address: '0xFE4A679d8ee7390057010EeC5E24d6427E1c3686',
    minters_reward: 0,
    lp_providers_reward: 587.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 587.48,
  },
  {
    address: '0xBfACE1F1b4e4B457E1046bcCCB5D574B3d802c75',
    minters_reward: 0,
    lp_providers_reward: 107.72,
    swapers_reward: 478.5069000664697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 586.23,
  },
  {
    address: '0x4164196895087191e5d9E292022225cA72dEbaB5',
    minters_reward: 0,
    lp_providers_reward: 585.87,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 585.87,
  },
  {
    address: '0xf8Ca55E19603De02a361Dae0A892E469a36694a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 585.713301279694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 585.71,
  },
  {
    address: '0xfD16c55176faC6032991E71C03e929E7355C6d52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 584.7649474603071,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 584.76,
  },
  {
    address: '0xE80F69ec5a1a0850Bd0834CF44fBc90Ae7A8F324',
    minters_reward: 0,
    lp_providers_reward: 414.83,
    swapers_reward: 169.08905066819037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 583.92,
  },
  {
    address: '0xbe812066F2c61234159B58a5E88BEC38DcBB1D11',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 583.2391284950721,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 583.24,
  },
  {
    address: '0x43917c252FB50Ceb284e3f6b1386aC151d495b2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 583.0782691751125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 583.08,
  },
  {
    address: '0xf2569dcE6e002517A9aD1c7a69e44C47E9fb08D0',
    minters_reward: 0,
    lp_providers_reward: 581.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 581.66,
  },
  {
    address: '0x5fc115803064995b493170cF9194cBA7dF3c29d7',
    minters_reward: 0,
    lp_providers_reward: 107.08,
    swapers_reward: 473.9388183738694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 581.02,
  },
  {
    address: '0x0C50a367f97f824E5192E2F992dB799A929d347C',
    minters_reward: 0,
    lp_providers_reward: 541.24,
    swapers_reward: 39.197264464212594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 580.44,
  },
  {
    address: '0x2A06f5fCFBeF774c4d546A81f37698A3F9659921',
    minters_reward: 0,
    lp_providers_reward: 2.87,
    swapers_reward: 35.48510782185151,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 580.17,
  },
  {
    address: '0x40922917102c812fa3Ba55Aa365277D1d3f703D0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 579.8682788282154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 579.87,
  },
  {
    address: '0x5a12c8c32cb99Ad5827B6b067b9097C0a592201e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 577.7625813772637,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 577.76,
  },
  {
    address: '0x8aD7Ac916662B69450A4390E245965eC4F49ED11',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 577.5500481312429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 577.55,
  },
  {
    address: '0xAd1e671C7BD93a264c4bbE0F32bC57deE3d11E7B',
    minters_reward: 0,
    lp_providers_reward: 215.04,
    swapers_reward: 361.1356351915297,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 576.18,
  },
  {
    address: '0x3487865E5761dc90eDcd8e019eAF35C47699663e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 575.3560885513785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 575.36,
  },
  {
    address: '0x5004B6422A9D0c6Ed4202C9B63478E812993190e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 574.6117657317128,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 574.61,
  },
  {
    address: '0x5C3dEc60919E1De594AaA896428f39e0C5a9d069',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 572.9121081439491,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 572.91,
  },
  {
    address: '0x3F51dEC8F9F7d2F24dd100Cb6d7F9d636236f167',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 572.1847292027104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 572.18,
  },
  {
    address: '0x7316CB0eF81f650a51c329c9eC067e2A63757890',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 572.0681191492914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 572.07,
  },
  {
    address: '0x126B9e299553Ef3e4Acf92B85bd5C34d539d6eC1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 571.0567624752773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 571.06,
  },
  {
    address: '0x97f5d8C4A947604d932b651130599422e2BFe43e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 569.7916344536715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 569.79,
  },
  {
    address: '0x635a28fb5f9612D7d99B335968ace9A9462EA333',
    minters_reward: 0,
    lp_providers_reward: 413.65,
    swapers_reward: 154.83736099927333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 568.49,
  },
  {
    address: '0x91fb2a4716B58f571F616f175e5260CECC542D3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 566.9665403400608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 566.97,
  },
  {
    address: '0x5dF58c7BA2e6Df33A740E91C57795655526C3B0a',
    minters_reward: 0,
    lp_providers_reward: 566.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 566.84,
  },
  {
    address: '0x9d65D25Aa774AB2AFbdbA2A04ad071484366D44A',
    minters_reward: 0,
    lp_providers_reward: 566.26,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 566.26,
  },
  {
    address: '0xF1d827D71013C847A1d81458b7e2EFBe48672431',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 566.0806335262414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 566.08,
  },
  {
    address: '0xc25D7F9A1EB1ED68603B7Fd4aE682Ef7294B6947',
    minters_reward: 0,
    lp_providers_reward: 433.91,
    swapers_reward: 132.0230029847781,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 565.93,
  },
  {
    address: '0xbCdD6C529b9bEaacf8b3F89192F65744C3ff1589',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 565.4944534654819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 565.49,
  },
  {
    address: '0xcd9958A8632d87A4AcAa1aeB4CE0280A2EAC92E5',
    minters_reward: 0,
    lp_providers_reward: 279.06,
    swapers_reward: 285.91289437501786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 564.97,
  },
  {
    address: '0x9320327EFE158888ec86011cf254B569aD021e7b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 564.7262421503832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 564.73,
  },
  {
    address: '0x7Ea06282dA102dEcdC7f2B6Bb252CaA19ea03428',
    minters_reward: 0,
    lp_providers_reward: 564.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 564.46,
  },
  {
    address: '0xC8DF103556D0c43F51DF62d4111964AD5A4cd79F',
    minters_reward: 0,
    lp_providers_reward: 559.77,
    swapers_reward: 3.7810510147361187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 563.55,
  },
  {
    address: '0xD11A5981E469e6F2f7fBd0f035F17e001a76497F',
    minters_reward: 0,
    lp_providers_reward: 563.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 563.33,
  },
  {
    address: '0xc3052ACF246f25B35C81026FE7E0Ba049538F2b8',
    minters_reward: 0,
    lp_providers_reward: 114.81,
    swapers_reward: 448.3728732614809,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 563.18,
  },
  {
    address: '0x106331266906126C204ACF2a247ACC925fcb0C60',
    minters_reward: 0,
    lp_providers_reward: 448.6,
    swapers_reward: 113.0607017925079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 561.66,
  },
  {
    address: '0x35a926580Fd42D5C2b03451147CaFedBD37F82Fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 561.5559995784474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 561.56,
  },
  {
    address: '0x49b012e4730AA493aF51Ade0B1622a116c9De268',
    minters_reward: 0,
    lp_providers_reward: 558.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 558.84,
  },
  {
    address: '0x01C5CC528523F5e758Ff0Eb401593E53b7e4927F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 558.4290838823928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 558.43,
  },
  {
    address: '0x85A4b6129f7BFA5d17528CaFF919635E10df393E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 557.7191283176675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 557.72,
  },
  {
    address: '0xcF58FeaCEA6330638602c6C08Bb1b7ecE59495cD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 557.4501018046028,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 557.45,
  },
  {
    address: '0x120AA77A859EdcCbd95029f54e81914E4d854A37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.228244240669948,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 556.04,
  },
  {
    address: '0x622347b90808F948849272251C6C4c6f0572BcE8',
    minters_reward: 0,
    lp_providers_reward: 417.33,
    swapers_reward: 137.4043975590675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 554.73,
  },
  {
    address: '0xCCF03c7d60a056d94829653845CE941DbfAF85B9',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 149.05915997895514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 554.1,
  },
  {
    address: '0xcE26544358af1Ee403536f079605623DD9b919Dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 550.7466950473415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 550.75,
  },
  {
    address: '0xCC5742318328892B0cEc63e2777499faCcBAfe92',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 550.2132120710224,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 550.21,
  },
  {
    address: '0xE20D4495Ad1eEad206A6A01C62d551B1259C55EC',
    minters_reward: 0,
    lp_providers_reward: 539.58,
    swapers_reward: 7.168355326133121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 546.75,
  },
  {
    address: '0x47e54D41D2F8785615C49917D1F2c660C113C864',
    minters_reward: 0,
    lp_providers_reward: 370.55,
    swapers_reward: 175.79383588299345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 546.34,
  },
  {
    address: '0xD45738fdB3deF21404A6c489fa514749102a6c01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.350647931117321,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 546.16,
  },
  {
    address: '0xBa31E2f7E3789383082327874ce03d93905eC962',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 544.4411066278248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 544.44,
  },
  {
    address: '0xFA77790988D6A344B94c29b58A748CC7EC0729D1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 543.5697184043024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 543.57,
  },
  {
    address: '0x1BdbF63d173adE5836944B981bbFeFCF9C62C337',
    minters_reward: 0,
    lp_providers_reward: 0.25,
    swapers_reward: 0.26373480160856555,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 542.32,
  },
  {
    address: '0x9eaC5785efb5E54e72e5d0938f7A263c2643E2Fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xd86d489308c5016B64a1Ebae203751a691674D77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xE8c161e642FC7Cd68A75076991a179D016227b1D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xA7508d08F63dD93CfD69f05EF70204FC3aB88c7E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xaD7E94cd588e7Cb44787650Efed60bC87b2fF15A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xA27aD9463E6c12e119af3E6d3952489f84a6747F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xDf2aE1D86aBf5A37397537b04a3ae9b0962BEf8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x56925bccAC196297F54BD1b6409C0B51401f6885',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xBBAf87c5c309E2d5BF89293B9159D7CFcE2D2d26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x908e8C97785598ca713AdA941C83D6A5542542ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x19dd9AB025051146A50DF90680c0446Cd021697e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x50C9EbA869A6b971458f8e54680A43B7d83fBa52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xC296004Bc4d20103AB900B72d4263BC6A84038aC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x1C59F234acE21c1a5E640b1D645a04922C070075',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x6E7dd5e17aa3c4f3368F71c2bcDbb16256B10C1B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x235F65C197913659baD357Cd4a3717919A6da4fD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xf50DB813C751b0B3e780397E9E6F1d28b11d219c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x0E546fD08B7B7B342c7adC1153919c325ee4c36A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xD21Cea7B5B29ebBA99Dd8D9faBFac735DCD3131c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x559342bFFa06172C9CD47bD49634D916C17FeC2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x51E524A6d6C496eF9c11452EB77C8bcDFff8e62f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x8e4536C9706680d63Ba17E7280a734A4a7D2fc9d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x6Af34c2d2754EA6c0Ab88399f3c6A9FB6B66DbdC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x19Ab49AE5dA267d70550d181A3fa31B8E6407E64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x3FEa28d3c9A65bfFF5c317d067cb829AC23365bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xA59f6130E3edF3918ef146aD8AE9EfE344F08725',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x4e3b0a44f5678c400d37105D4E0A5AaB79C18c14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x7Bc69C22c400420Cd310FF284017b4E3e989F823',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x0E4Eb7bADa4B56b81310Dba13ec95FdCA6358E1d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x3553ed2C3557DB4b5fC563cA4BaEa385EFb83F1c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x718d3744Dd4E297365b9C6d9b9F33B6A865689D3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x54458729Da30642B5eCCBBacF5dD07a51BD4ae28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xcB59a647c18DE85E5b04008fDdd4b9D21dBc4834',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x0F30D63F28DD322C8d9d2E37C83A6bd474634984',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x8e8f84b84102Dbe31Fd8Fcec07d8eFE8cA66e79d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 537.3889270567928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 537.39,
  },
  {
    address: '0xeBc8b84AAE52eB5E93b137b054f3D597F3B660fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 536.3902520199358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 536.39,
  },
  {
    address: '0xE299fa5f79f0B1c875fEEf1c516ECE9C95cA6382',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 535.2097367911798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 535.21,
  },
  {
    address: '0x4e6203442764866862B2b91261844518c07167f6',
    minters_reward: 0,
    lp_providers_reward: 535.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 535.19,
  },
  {
    address: '0x17Df0B2920d63F4BFb703e341Ca0edbe2bE31077',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 534.549247609529,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 534.55,
  },
  {
    address: '0x812b4B8b0e0De30E64E8aE5BFAFf28D6a9Df273A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 533.7179873688801,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 533.72,
  },
  {
    address: '0xBE3FF62E3dBcE9d38Eac5029b5f977ec6531Cdd4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 530.7472603306852,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 530.75,
  },
  {
    address: '0xfbbf9063f347d8f08aD7525FCF98bbB3Ffe11caE',
    minters_reward: 0,
    lp_providers_reward: 530.17,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 530.17,
  },
  {
    address: '0x69357C13130cC4417F1910C2bc6516d65979F945',
    minters_reward: 0,
    lp_providers_reward: 529.83,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 529.83,
  },
  {
    address: '0x4C96a0e85359708e10da3916143D2d18C3F3Cc01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 527.630980097432,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 527.63,
  },
  {
    address: '0x1ADCF8Ec739FF0dF6a8A63298018a01179f3cf11',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 526.9929293965316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 526.99,
  },
  {
    address: '0x011349Ad2eA488fC57Ed42708C8aa0d6598F64E1',
    minters_reward: 0,
    lp_providers_reward: 444.23,
    swapers_reward: 81.26671848011809,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 525.5,
  },
  {
    address: '0xd6632d3CCdE18be02e2977aef3667A699187F0e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 525.4073583359458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 525.41,
  },
  {
    address: '0x2179E2cBFFA7C9d90fC520e363089c1Fe2c9774b',
    minters_reward: 0,
    lp_providers_reward: 521.18,
    swapers_reward: 2.9995377025730123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 524.18,
  },
  {
    address: '0x8375eb237C8CcAFEcaA8e220b21dF3F549F81240',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 523.6450213565041,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 523.65,
  },
  {
    address: '0x80f1536bc45445298D6D5d305AB4eb9CA57D5A6f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 522.9609712124242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 522.96,
  },
  {
    address: '0x87D28f6CBbac5AFcDf2a6BbB369548B02e726162',
    minters_reward: 0,
    lp_providers_reward: 219.13,
    swapers_reward: 302.5985257191921,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 521.73,
  },
  {
    address: '0x0EfeF56D7717755e98a2a292ddF4d33fD95B8bFc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 521.2400929193896,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 521.24,
  },
  {
    address: '0xAEf87b30637F177A4Db561D95895BA5F3aA2705F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 519.5085846796773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 519.51,
  },
  {
    address: '0xA06D0DC0F00d1dD3eb83477E8788Dbf89F30bb09',
    minters_reward: 0,
    lp_providers_reward: 116.65,
    swapers_reward: 400.98858398117767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 517.64,
  },
  {
    address: '0x5a7eb7aFd532F06761484F8aDf9B91BEad3fBc36',
    minters_reward: 0,
    lp_providers_reward: 349.85,
    swapers_reward: 166.95640684505065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 516.81,
  },
  {
    address: '0xbbC6E1c2fb494dE9d0B6D88AcAf61Da00b021123',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 516.4522508380516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 516.45,
  },
  {
    address: '0xD147bFd8d38E97115F2b748FEF821a7c6590c907',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 512.1901834925822,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 512.19,
  },
  {
    address: '0xEAAB8389CA8fa6AeE66C587aE5Cc7B7DA44b0023',
    minters_reward: 0,
    lp_providers_reward: 5.44,
    swapers_reward: 234.87786310830415,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 511.22,
  },
  {
    address: '0x25a30BD02b8923Aac802B6b91A42dEc0E00fad5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 511.1734562714629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 511.17,
  },
  {
    address: '0xAeB5eb89fd1f7e42548193B8A766a205e5e93e35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 511.1668895890632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 511.17,
  },
  {
    address: '0x43898807Be33aE07f04eE354357f26cde55d7e5E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 511.12207379401696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 511.12,
  },
  {
    address: '0x2ea518a71B52461B013460e72CA7E02008b5FE64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 509.43524239986635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 509.44,
  },
  {
    address: '0x95719Cd8eca117A9f133D6205648b307C22580C2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 509.1393121549523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 509.14,
  },
  {
    address: '0x9ABd1869167b5EF728fBb2031cf420f830C75c70',
    minters_reward: 0,
    lp_providers_reward: 67.65,
    swapers_reward: 440.0332899213225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 507.68,
  },
  {
    address: '0x890F47813857b1830BB234Ec48B3F491610e8F98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 507.5917310051554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 507.59,
  },
  {
    address: '0x5AAFD848ac4484e9Cb4619d83C2686042b5F4A35',
    minters_reward: 0,
    lp_providers_reward: 349.95,
    swapers_reward: 157.26286058966318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 507.21,
  },
  {
    address: '0x36C5EDCf698CcD913c6de49F3B925c94bE1ee5cC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 506.46539633319327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 506.47,
  },
  {
    address: '0x866A8B312dDD9a583D80fe1CEa055a565c1D9848',
    minters_reward: 0,
    lp_providers_reward: 407.8,
    swapers_reward: 97.9403631378296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 505.74,
  },
  {
    address: '0x94F1D9b8687b89b89cbA4Aa0A10Aa591459A3684',
    minters_reward: 0,
    lp_providers_reward: 505.37,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 505.37,
  },
  {
    address: '0x8CCB1aD5fD4Be6F6fC292a7dD46e2F37675B4f21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 504.98358214019794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 504.98,
  },
  {
    address: '0xCd032A1eeEf94544E57A7a2fc4c98bD0B317Baaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 504.68154318716654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 504.68,
  },
  {
    address: '0xEb798045ac1E0303870e59a5215A525759710C05',
    minters_reward: 405.04,
    lp_providers_reward: 5.7,
    swapers_reward: 93.71608616566212,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 504.46,
  },
  {
    address: '0x57d3e3542bDeabF2d6079934B288e306ef902306',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 504.32997252310224,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 504.33,
  },
  {
    address: '0x21D0e0Ef02af3733831Df26De0335B59Ce92d5C2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 502.4037414143339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 502.4,
  },
  {
    address: '0x7f2b7478230DEd365308ec9871e6BB3188bD075e',
    minters_reward: 0,
    lp_providers_reward: 401.84,
    swapers_reward: 100.39337658434805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 502.23,
  },
  {
    address: '0x8e48470F264Fd5c2a0F113B4F28128159D935b6F',
    minters_reward: 0,
    lp_providers_reward: 282.33,
    swapers_reward: 216.42464539184803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 498.75,
  },
  {
    address: '0x7afaCFcAcc0806BDf296d9bFf7fE0295A47C801f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 496.39124161025336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 496.39,
  },
  {
    address: '0x49F2AA9785afD915De8BbD630766f74d093F453a',
    minters_reward: 0,
    lp_providers_reward: 372.51,
    swapers_reward: 122.11940421802106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 494.63,
  },
  {
    address: '0xB1018A1a919468D213e3059d951E00C000bcE76f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 494.505593437814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 494.51,
  },
  {
    address: '0xFf03ABFa1d70f200c536C759812FA0255Bf87A1b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 493.99180281329495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 493.99,
  },
  {
    address: '0x93631f5AED49014A11652e1f94EE724357d62118',
    minters_reward: 0,
    lp_providers_reward: 125.96,
    swapers_reward: 367.3229371486182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 493.28,
  },
  {
    address: '0xCda948A4F0C36713e37B00655ef5377cAD825c51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 493.06082358979825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 493.06,
  },
  {
    address: '0xcF9654E4a14c5BAc95e0e10483F79332D2Aac128',
    minters_reward: 0,
    lp_providers_reward: 399.31,
    swapers_reward: 93.21781964920933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 492.53,
  },
  {
    address: '0x73ABd7734719Bc898Ea167FA048C450Dee15C8BC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 491.8975372956318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 491.9,
  },
  {
    address: '0x109d8604cD6cE0A64BF6121312F127FcB19ABd5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 490.16534486213794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 490.17,
  },
  {
    address: '0x5d668c5724beFFF96797CA506EB8da7672081f9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 489.35343339913055,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 489.35,
  },
  {
    address: '0xCCAe1a6eEa4a77732B3801D0A835F109B07229C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 489.30283412071,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 489.3,
  },
  {
    address: '0xcF4c5E5D22f55eBeD330aC95801a685480723b45',
    minters_reward: 0,
    lp_providers_reward: 277.39,
    swapers_reward: 210.34042125096866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 487.73,
  },
  {
    address: '0x6bb34D86B1f0c832E4ec5C4f7831414875C4630C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 487.4384708019833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 487.44,
  },
  {
    address: '0x915a3ac81dB68025C591996CA237b16df9b97638',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 81.57282024457952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 486.61,
  },
  {
    address: '0x8e86D356A64Bf1D78791282CD30e46245fcb6261',
    minters_reward: 0,
    lp_providers_reward: 486.5,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 486.5,
  },
  {
    address: '0x85B2dBdd2E4A2967BacD5EF346a2F340571D4A68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 485.10606602404465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 485.11,
  },
  {
    address: '0x23088d60f27DEC692Aa062B03ed3B8356364397d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 483.720585632198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 483.72,
  },
  {
    address: '0x473C737fC98B335DFd8965fA5F6C33882547D9AB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 481.7507796078443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 481.75,
  },
  {
    address: '0x5C9FcE1fb626b27BCF154c2bD8C87c5fCdf0b1C6',
    minters_reward: 0,
    lp_providers_reward: 352.14,
    swapers_reward: 129.40891254253592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 481.55,
  },
  {
    address: '0xCfcf848816B64191C4C7688A8B42a2C1487dAE96',
    minters_reward: 0,
    lp_providers_reward: 480.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 480.93,
  },
  {
    address: '0x0a893c5Dbd7285FDa239C48A01D488F476D356AC',
    minters_reward: 0,
    lp_providers_reward: 474.76,
    swapers_reward: 3.317545190339864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 478.08,
  },
  {
    address: '0x9d08E2ADbE1078E86c7460418390781dd7d757cc',
    minters_reward: 0,
    lp_providers_reward: 150.14,
    swapers_reward: 56.533457861610906,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 477.57,
  },
  {
    address: '0x5850BFc214b2b207CC5FA314370bdD1486dF7FFd',
    minters_reward: 0,
    lp_providers_reward: 477.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 477.15,
  },
  {
    address: '0x60B30Bb57ad93e2d5d746B61dEa28dF450F4C7f0',
    minters_reward: 0,
    lp_providers_reward: 159.92,
    swapers_reward: 316.9793362994328,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 476.9,
  },
  {
    address: '0x25C62635bD32ca156F141132b955eC6e23982C48',
    minters_reward: 0,
    lp_providers_reward: 304.48,
    swapers_reward: 171.87725493591483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 476.36,
  },
  {
    address: '0x57b778FbD5F6D82852e3D4677F091cc91F48fd8C',
    minters_reward: 0,
    lp_providers_reward: 221.32,
    swapers_reward: 254.5120786319365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 475.83,
  },
  {
    address: '0x6CF9A4602F7C1EC74b2ddeaE41c1eAD560E253e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 475.77031955316585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 475.77,
  },
  {
    address: '0x8202092fDC93961A4ba12d411C3FF163677E814f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 475.23352723663794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 475.23,
  },
  {
    address: '0x43d06ce861383642461c45CF5540D5E51D054f8e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 474.4242432495744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 474.42,
  },
  {
    address: '0xA8392A2bF587160cE3d9383ed89c63A06Ee3298d',
    minters_reward: 0,
    lp_providers_reward: 473.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 473.94,
  },
  {
    address: '0x14436B9e5A65E220433628990dDA323e775E61Bd',
    minters_reward: 0,
    lp_providers_reward: 393.13,
    swapers_reward: 79.89138758091616,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 473.02,
  },
  {
    address: '0x55ce38736E19FDd7dE11d48a7378AA7118144464',
    minters_reward: 405.04,
    lp_providers_reward: 67.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 472.15,
  },
  {
    address: '0xe04a517d23BDC8b9b227246Abe33fAB10B682763',
    minters_reward: 0,
    lp_providers_reward: 113.84,
    swapers_reward: 356.0421974924834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 469.88,
  },
  {
    address: '0x90cD7F3661f8cCc582F186601Bf806a6691c7a44',
    minters_reward: 0,
    lp_providers_reward: 13.89,
    swapers_reward: 455.9089218683321,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 469.8,
  },
  {
    address: '0xf898A2435a48Aa054F579eD6957CF702D4b32785',
    minters_reward: 0,
    lp_providers_reward: 356.38,
    swapers_reward: 113.35142380214216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 469.73,
  },
  {
    address: '0xdFa04C5464E7915215002f2d77602435cb3245F7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 468.17784332809964,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 468.18,
  },
  {
    address: '0x1E65C6Ded3A739215BF0bAF060C9ed50B3bf43Bc',
    minters_reward: 0,
    lp_providers_reward: 272.46,
    swapers_reward: 195.44578308121112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 467.91,
  },
  {
    address: '0xb685178Bf8741E59eACe8af0B54e65eafA031c8c',
    minters_reward: 0,
    lp_providers_reward: 370.09,
    swapers_reward: 95.35307834600923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 465.44,
  },
  {
    address: '0xd0F431701A33d7289Cf3d37eC2402A0d65dd13f8',
    minters_reward: 0,
    lp_providers_reward: 92.88,
    swapers_reward: 371.9974722162509,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 464.88,
  },
  {
    address: '0xA644b1afFE99746Ab346Fa52722df69f8C16f8b2',
    minters_reward: 405.04,
    lp_providers_reward: 54.71,
    swapers_reward: 4.0269030766194644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 463.78,
  },
  {
    address: '0xDc6Aa9d473d863B84629fA9d061E4eA8429997D8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 463.3813480069331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 463.38,
  },
  {
    address: '0x2fDA033349B651524daa77981A9F9E2ee28BeB10',
    minters_reward: 405.04,
    lp_providers_reward: 55.03,
    swapers_reward: 2.263373307079631,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 462.33,
  },
  {
    address: '0x2e8220d93Aa42839352800d1b06e9A3Cc25E4f61',
    minters_reward: 0,
    lp_providers_reward: 162.11,
    swapers_reward: 299.6177710017776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 461.73,
  },
  {
    address: '0x07A4177fEA57456a84CeD84459953CfbBe881A4A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 461.26043993698727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 461.26,
  },
  {
    address: '0x22b917e5025a36bD9f4D2377c43007F2903D2B6B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 459.86301697426194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 459.86,
  },
  {
    address: '0x804C7d435a2b4b5F6Ed5c8e9F47bB3b4Ee1Bc897',
    minters_reward: 0,
    lp_providers_reward: 151.35,
    swapers_reward: 306.72152926397564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 458.07,
  },
  {
    address: '0xF22BF13A914Fc10A7376934a3027f0Ca74322c95',
    minters_reward: 0,
    lp_providers_reward: 86.39,
    swapers_reward: 371.6402962453319,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 458.03,
  },
  {
    address: '0x4CF7b09De2e74d68e72518B95E2E898E28ccE917',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 457.94263149072015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 457.94,
  },
  {
    address: '0xC973b9D9B07576B860e42d828551870D28f3631F',
    minters_reward: 0,
    lp_providers_reward: 49.05,
    swapers_reward: 408.53691039891817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 457.59,
  },
  {
    address: '0x1fA25bC9D71A5f1df87D26D4401E56462c7D4288',
    minters_reward: 0,
    lp_providers_reward: 317.68,
    swapers_reward: 139.90651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 457.59,
  },
  {
    address: '0x30D6DD7A12DdCD1EE0B39c36b6E0C4eE6A0D2136',
    minters_reward: 0,
    lp_providers_reward: 457.41,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 457.41,
  },
  {
    address: '0xbf54BFd4dC431B1f8B3F6501c20009278d330d55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 455.068970916217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 455.07,
  },
  {
    address: '0x03838F2A3aF95a4485bab46585945448F444E728',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 454.2152545725936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 454.22,
  },
  {
    address: '0xF98B332149cfEab68748bBD6a361f529798B27f7',
    minters_reward: 0,
    lp_providers_reward: 33.91,
    swapers_reward: 418.97982272112955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 452.89,
  },
  {
    address: '0x3C99c3cb1719287765EAadB109d30e74e76D095b',
    minters_reward: 405.04,
    lp_providers_reward: 47.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 452.66,
  },
  {
    address: '0x09275039D5725318227914a358ed761eD0FFAA1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 452.53113884641914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 452.53,
  },
  {
    address: '0x83934737BE4724C0C930520b3F51D399e7153346',
    minters_reward: 0,
    lp_providers_reward: 170.61,
    swapers_reward: 10.394545491492106,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 451.9,
  },
  {
    address: '0x136A87BBe1f138e6e16fcDEF360799A0a0c57C4f',
    minters_reward: 405.04,
    lp_providers_reward: 5.08,
    swapers_reward: 41.165872270219104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 451.29,
  },
  {
    address: '0x5bABA585C3551C9D14fB6bE49f22C15aF1fe8C9f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.4853332479135,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 450.39,
  },
  {
    address: '0xb0AaB6f0929777aF5d3f51450b708BAEb22cda46',
    minters_reward: 0,
    lp_providers_reward: 177.23,
    swapers_reward: 272.1593368530993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 449.39,
  },
  {
    address: '0x686DEF69Ce32f1fAdD0Cd6336fE85B3972f41214',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 447.2788019719168,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 447.28,
  },
  {
    address: '0xdA01cD180AbFF9943428dc327eDa849daDC83aDD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 446.6275060489451,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 446.63,
  },
  {
    address: '0x9d7b92321810400881939736975a11314D013955',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 444.51022100915503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 444.51,
  },
  {
    address: '0x7435Af923c222e9113cA404803FbEab99BB4Fa70',
    minters_reward: 0,
    lp_providers_reward: 444.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 444.32,
  },
  {
    address: '0x97F73496063395DDAa21faEeA78F5Aa5c3908bA4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 441.54127913536604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 441.54,
  },
  {
    address: '0x8E9777071dcf4Ae1E0b7D8EBcE5344c377FB4f9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 441.14736841804626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 441.15,
  },
  {
    address: '0x89D7b42b2748D050D5Cb5FDc413071E3b4AEE6e2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 34.327476157141355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 439.37,
  },
  {
    address: '0xff0D8388104621f9fD416c7D35c4CBcF30C1f566',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 439.00034801516784,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 439,
  },
  {
    address: '0x4F26078dC9F7Bc6c616B6bB625C5034f0500DBD1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 436.26184310999633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 436.26,
  },
  {
    address: '0x1beD8319Ad56780F303B226BfcA60BAd29db9e66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 435.734748671058,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 435.73,
  },
  {
    address: '0xf2Dd1b6c1a93782DE9171c1Aa203C0Ab833eDD84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 434.1502168506264,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 434.15,
  },
  {
    address: '0x1D79914F1DbDE55506c7de892F765644AF3c529b',
    minters_reward: 0,
    lp_providers_reward: 141.72,
    swapers_reward: 21.502351032075207,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 434.12,
  },
  {
    address: '0x48Fc7B4e0A0eFFb77eC21553f9311174918604C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 432.3410096731172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 432.34,
  },
  {
    address: '0xeF874F7Fd0c262b7cd6324b4C537DDEC6B19aaEC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 431.63636559949236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 431.64,
  },
  {
    address: '0xE233D8CEd8a3a43Fc5F53e546975852f313148af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 430.6224379283443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 430.62,
  },
  {
    address: '0xd0B3DFB9019F7986661fA3E3969CbCa4d8fbe08d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 430.06219822183306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 430.06,
  },
  {
    address: '0x66AF5882264A47443b1a736134db38012A7c6b6A',
    minters_reward: 0,
    lp_providers_reward: 170.77,
    swapers_reward: 259.2196357716182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.99,
  },
  {
    address: '0x7cAF39086CA10EfEbb830EdEEa9759169eB99875',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 429.89549155165315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.9,
  },
  {
    address: '0xE3910d12538f744c775660Ae190B7D35f579Ab93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 429.5217298155414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.52,
  },
  {
    address: '0x76C1d1C8053Be7E731A97E96CC0a4F256a1CEfD1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 429.43442840326844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.43,
  },
  {
    address: '0x2CAa523dd5D280f9bB753e6e69C817Cb6E6475Fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 429.3522295038378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.35,
  },
  {
    address: '0xe4EeB9fa30556e2439d48C9f37F01350d1f0a977',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 427.941024124051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 427.94,
  },
  {
    address: '0xE70037dee17c7b16D4589dA381DA5DCF05Aa4A50',
    minters_reward: 0,
    lp_providers_reward: 378.05,
    swapers_reward: 49.76113234345485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 427.81,
  },
  {
    address: '0xDfac9660ae53e5691ceFDb1280e8bE8705e770a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 426.25914063046235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 426.26,
  },
  {
    address: '0x2BfE8e92cD4687CcDC98D231A8c8cF3eDDf5E93d',
    minters_reward: 0,
    lp_providers_reward: 426.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 426.19,
  },
  {
    address: '0xFE4BA08Cf4d59BF2C3f3902Fc6d0863D54aA6859',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 426.0874065238787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 426.09,
  },
  {
    address: '0xCD0347158d7254109E3984868e67F7C4e60426AE',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 18.741022081711982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 423.78,
  },
  {
    address: '0x50E61c13eCE09C31316B2821b4DE3A752Ae4Bc80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 423.25680525806035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 423.26,
  },
  {
    address: '0x3FC1Ab40a314f7964200E9629C7189c0A05D3C5B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.01748830390432,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 422.92,
  },
  {
    address: '0x3193E8aF09FC3ebDbF1608fd8226d4c659F3f737',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 422.4926135589166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 422.49,
  },
  {
    address: '0x82275591710603843fECC08a28F1014Cf8d73C85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 422.2000333325677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 422.2,
  },
  {
    address: '0xE26Ae45C7201Ba2dd7FD0369601775EB5a32C3e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 421.7180593593168,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 421.72,
  },
  {
    address: '0x6f648F391756dF107f95bF4ff7dD6d80e1c80cDE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 421.2452780542413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 421.25,
  },
  {
    address: '0xFD58304a56E19E3A5AE55310824420eD4e0fbAD0',
    minters_reward: 0,
    lp_providers_reward: 421,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 421,
  },
  {
    address: '0x08e40997D588afC066e66926d2A91850492f9676',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 148.53553111985696,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 419.44,
  },
  {
    address: '0xEa3ee4b011b28297d7752153229E7BC5D915e646',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 14.23242921271519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 419.27,
  },
  {
    address: '0x2839805E004b64e4e3189802F1efB06C02506f8d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 419.0609333294391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 419.06,
  },
  {
    address: '0xd693cB2307cA32FE18A2f9E2F902023287944596',
    minters_reward: 405.04,
    lp_providers_reward: 13.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 418.85,
  },
  {
    address: '0x4c25DB89E0A717ae69EA615db3E5a41ccCb66F0e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 417.71111794212436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 417.71,
  },
  {
    address: '0xd5E51b168822D51EA71B134BAD4Ea6C4e0d08C2C',
    minters_reward: 0,
    lp_providers_reward: 411.04,
    swapers_reward: 6.571223217669793,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 417.61,
  },
  {
    address: '0x0380FeF903ECC9b92316D1aCd4671Aa7916436d1',
    minters_reward: 0,
    lp_providers_reward: 384.91,
    swapers_reward: 32.32136935889552,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 417.23,
  },
  {
    address: '0x6B40625fB110Dbc837E6d93Ff067ec00FC5e5988',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 416.85427080524124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 416.85,
  },
  {
    address: '0x2EdcD98B168ddC71ABd806E00896c0C152dB6550',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 416.7863544492508,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 416.79,
  },
  {
    address: '0x1769E88b8C5A0b3496A790E185e1053465F2F7F5',
    minters_reward: 0,
    lp_providers_reward: 210.53,
    swapers_reward: 205.7832589230818,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 416.31,
  },
  {
    address: '0x235AC229b1C0FAA0FDcbbE996A2774E85035928f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 415.4603386688232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 415.46,
  },
  {
    address: '0xDAE95c7736FDc20783CC0E45A2d1a9A76DE821e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 414.45203431662895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 414.45,
  },
  {
    address: '0x369423430B37BE416B76Dd7dC54d17dDE2dC2D15',
    minters_reward: 0,
    lp_providers_reward: 209.3,
    swapers_reward: 204.60753431891646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 413.91,
  },
  {
    address: '0xeF5DF91452FFFE48FeB52b7da46f2b1963b2654b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 413.719810405812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 413.72,
  },
  {
    address: '0x6cD944595193c73F6B57ae896F5A0aF45A984FAF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 413.2778077927259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 413.28,
  },
  {
    address: '0x4B79A5D5039E3531df1db42b86AAf45A13A74645',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 413.12894836345305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 413.13,
  },
  {
    address: '0x5Cec273BCF27773fF7AFbC7CB79f6B6137142e03',
    minters_reward: 0,
    lp_providers_reward: 233.98,
    swapers_reward: 178.60808253040702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 412.59,
  },
  {
    address: '0x7EDB193AE389682008C112c40673d88502bC4a98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 412.5269397820916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 412.53,
  },
  {
    address: '0xE2B630c54b60afeB3053a13a9aF83DD0815A2847',
    minters_reward: 0,
    lp_providers_reward: 26,
    swapers_reward: 385.39917638155544,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 411.4,
  },
  {
    address: '0x78499636380635792342D6bBA936a31e4222f56b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.3644617772631,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 411.26,
  },
  {
    address: '0xCbD4946Af66d9451d7AcFAFEE1234F8925747ebb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 411.2416235109337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 411.24,
  },
  {
    address: '0x06114F14F690CD3516EFA36fcCB7715BA030D9C6',
    minters_reward: 0,
    lp_providers_reward: 118.59,
    swapers_reward: 290.34500302466245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 408.94,
  },
  {
    address: '0xA0c11E5Ffc0199fAd5d957Ea4c8dae7e79041617',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 408.0740589241869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 408.07,
  },
  {
    address: '0xC7F88E349EE75301e5A0e6ab7278a69091444e97',
    minters_reward: 0,
    lp_providers_reward: 3.8,
    swapers_reward: 404.0649909281863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 407.86,
  },
  {
    address: '0xfC0C23A038a99364Ff77e1ab967132165e8bf706',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 407.08246349144576,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 407.08,
  },
  {
    address: '0x243582c099F4069269ee4083B54fD8fD482c8b5e',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0.6816306692171901,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.72,
  },
  {
    address: '0xE74Ac06850dDAff64E08a2c0820F80E9aEA7d706',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x8945d2cA9f2e4d052487d3c773f48d590b98B875',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x80532A4b84EcD47039c1374693898fc6D4f81b2d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x566AE4163853C13375879Db2474002B6b8384730',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x27aC16aF301A8b00c839D68B2A5Aee8B8750e88F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x030583A8B6880FD09269D95624C8a69f0cEBab9B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x0F4bC1ec151b27a50d268aEb057D43B9cC357022',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x94B4DF81b1Fa30895c8e0B1e35357A7711667B63',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xC9b375c222C97bFFD2d019C62d198ee6abC6D13C',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xDD3BF3a100B594E72265ce19078BeC9996D049FA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xffd6abF4577174eFC0Ba4c703D95D270F0e74333',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x269DF84C8FA6D83E498b96B329cb9Fa659E45dA5',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xEfeEE4F347f7f8283Cb87Cf1B6e053Ea01366601',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x8BBd0B963E9Ac04ba5399c0805D7022f4580e952',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x6C2809a27a77ddF0ceC882C2B9c63c24E0ea996d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x51403a396851116e61F5221900Ec3C8ebBa6270c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x05F369DC5B5db200959352D39A483E1452598aDD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x3010c64487473F95A3830C2fa45deF54d7b9028D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x6F6fDE1CCdB84b708BdF473360b6032045a22d0F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x95807c8D68abD45AFf7956F856Cd17765df2503B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xb6100ce65C1D30B29C06a6B0836eB554152bB57d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x3Ae639376F848F1829B10854E1664Acac9852dd0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x8ee54944b46cE419092f964B1ba8628E0c0E11F6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xa935fBA289c74eFE7c23aD73f3b1BB060b7530B3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x3Bf3512E48Cd3215a3f8240810309B09bb3a613E',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x8f3449B871E3B4c75463fb3316C506f7aFbCf248',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x6F34c4198E1d8C3D97ed223FEBc032Ac892d4BaA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xe1ffEB90F543b3eAbe5Eca17a524F21e451E50b6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xB4610b5227fB6B80F73AB9826b45110a9E9d8FDe',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x3D87Cb40FB8fFe4B05eCCe3c3eFD54406B23a586',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x94Ac45bd88293A82F1e46caD2D91da3ffb6e977b',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xFa0275ef8B2Ba4480E4EAe78C89B8277e25d3180',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x20712cCD7DdFF9d8eaFa932004855b26729CA437',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xe10A337549a4E2DEA26188FbF6d9CA9F56b3f19D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x047eA31614FC085cE7B80412FBaB65fD51a8bb7a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xddF104bc8E618f9f55b0E8480705dA081c97c921',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x80d2E52BBA0b72cEF696ea9f43D1B8573e9b933f',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x1C79BaD30a75f0Be25898Be75aF758A8F6Eeb0eb',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x238Cc3eA0228dA2dD8dE8fe7d90bf50c2B136700',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x5C2a2B3EC02C1468d6E9ff4c3aC585fd0EA39f59',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xeDF6661C0ad8d6E1f23F2d65fAd39426309b5003',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xB05dD7893EFa54796ed0D57F993B1C8Ec922293d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x07423A6D459B8215599538b57b81F26EC9613C29',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xBf223e3800F80BeFbF9F1026d8FFB0E9636395a8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xdB298bB50fa4FaBcadECF5512c2b3b398C07d5a8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x72E35B2a6260e52f034dd08FA68B3933c78880Dc',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xd49AC6C4753Dac8a9C5944f8335FBC68a18BFAba',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x1A520A3cD5C73069E0c39c34c2d68f15156b47fA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x2216F54f7da436D74f0d96f8FeB9C6C2ebc1f617',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x5cE7aA5337d45deE2e89DEEc34DE2C838dC5EEcE',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xe6686Bc1f037ba02525a22B4360719Dcd21D2eE2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xd3058732Fb3825035B6d5FC72dE279133d93e3A1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x6D743D27C0A88195165D7F731DaC03a681C25f24',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xA84885a2451272e133815a2d586A84e4b8fa6Cba',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x75EFb8143e9F5F48382784DC5B2cE58E9B7A8604',
    minters_reward: 0,
    lp_providers_reward: 403.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 403.9,
  },
  {
    address: '0x080Af17a23638Ba8CEa376F657dC866e522b8BEd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 403.40051025237005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 403.4,
  },
  {
    address: '0xeF2CfEC535c1506Fe807f1c90EA74669c5Fc9104',
    minters_reward: 0,
    lp_providers_reward: 239.69,
    swapers_reward: 162.83373571148124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 402.52,
  },
  {
    address: '0xb6Dfa7E5a9a4dBE333744c95F8B3764a352c5faA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 402.4771081027412,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 402.48,
  },
  {
    address: '0x8997023f133eDFb50Af47E950fe38f7601166E78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 401.4557874924435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 401.46,
  },
  {
    address: '0xF67B73D5EC727C212bcF3BD3b4d38D899181A16D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 401.3675815600473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 401.37,
  },
  {
    address: '0x8928F06712B9fF8a876A4f0b5DC0f656a80ED68A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 400.9901392296074,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 400.99,
  },
  {
    address: '0x1D1ceF60Dea30BB01dF247E6F4243A28E6a7fE2B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 400.95871845521583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 400.96,
  },
  {
    address: '0x2D8f43bd124f5111681f00C71767bF97b15fa314',
    minters_reward: 0,
    lp_providers_reward: 37.27,
    swapers_reward: 363.5668986529657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 400.84,
  },
  {
    address: '0x7612F52Caf99aA1fC06876183C78241502F9443e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 399.22705241380817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 399.23,
  },
  {
    address: '0x718967a47702E0C5AD8D36bb4976Ef89D6aE7eaF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 398.1391030523872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 398.14,
  },
  {
    address: '0xde179013000f2398d73841cfa4415e36726493dB',
    minters_reward: 0,
    lp_providers_reward: 75.87,
    swapers_reward: 322.15569870245344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 398.03,
  },
  {
    address: '0x47aD10A05b72d19be2Bcf5061368c461052483d9',
    minters_reward: 0,
    lp_providers_reward: 255.46,
    swapers_reward: 142.42984077641722,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 397.89,
  },
  {
    address: '0x194620353a9BC757a023777205c8e948FA0D8538',
    minters_reward: 0,
    lp_providers_reward: 397.64,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 397.64,
  },
  {
    address: '0x9d1427c2A7cFEc2DF77ec67a8D54Ff7C69a8A679',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 126.24582230836452,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 397.15,
  },
  {
    address: '0x264402bEd6779BC2054f027a0cab5fA13E312d6B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 397.00470843100555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 397,
  },
  {
    address: '0x15bea5bf2105ce38a6E15e72708EEB7A6C281d84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 396.77434288585425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 396.77,
  },
  {
    address: '0x06b185deC9db453ab6331930e1d850C991eD1334',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 396.4354369420748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 396.44,
  },
  {
    address: '0x196B064dAAb7A98d2e256193BdC0beC37BeFFcBD',
    minters_reward: 0,
    lp_providers_reward: 273.56,
    swapers_reward: 122.87745470422036,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 396.44,
  },
  {
    address: '0x4DFfb6d5cB89602969F6E549dCBf7dE8d0cA0C83',
    minters_reward: 0,
    lp_providers_reward: 395.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 395.48,
  },
  {
    address: '0x3D8dcD0848C10De61666Ba830a5f107b01276A03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 394.2205403337345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 394.22,
  },
  {
    address: '0xc70765ce72945f22E2D08a6Fd5D31d5533612915',
    minters_reward: 0,
    lp_providers_reward: 318.23,
    swapers_reward: 75.65185378745252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 393.88,
  },
  {
    address: '0x2B0c4825d9E94eBd8fDCEE7af1e8bbed96bc3BC3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 392.7837101058339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 392.78,
  },
  {
    address: '0x2ae8636a5e447B93905480ae7e59401E65888a32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 392.19015339810807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 392.19,
  },
  {
    address: '0x79a9891d3c23bce0a9bcfEF5ca00757D4dC4aFdd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 392.126110112451,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 392.13,
  },
  {
    address: '0x52cfC5041E0d1EF89eFa72101d45c8b2d95B47bC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 391.62973037221536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 391.63,
  },
  {
    address: '0x81134166c117ae6C8366C36BE9e886B0F7147faE',
    minters_reward: 0,
    lp_providers_reward: 40.47,
    swapers_reward: 346.9492604611431,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 387.42,
  },
  {
    address: '0xAB50b67fAe1a0f8B7a8f5435Ee76b66c599bAE0D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 387.40924975693275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 387.41,
  },
  {
    address: '0x6361C82a61c3e519e9b51B3A823d2bCe31FE1DD1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 385.9691388119505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.97,
  },
  {
    address: '0x5e2E9507C46040E495A8F6B5925d8BD49249194F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 385.72351448370455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.72,
  },
  {
    address: '0xabc7d5c2Dc9C2DD8e9d733CA658A8550bcA7c7F3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 385.5523653987718,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.55,
  },
  {
    address: '0xc89150b64045702BFCaC428C06D89036c2B97ED4',
    minters_reward: 0,
    lp_providers_reward: 272.31,
    swapers_reward: 113.05520135334736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.37,
  },
  {
    address: '0x37E1D44f28CF848d4BA376f0E4f5919f35e18F72',
    minters_reward: 0,
    lp_providers_reward: 280.45,
    swapers_reward: 104.6798820055773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.13,
  },
  {
    address: '0x48d3081995b50627c505b43eaFc1cA4E641127c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 113.01504233004349,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 383.92,
  },
  {
    address: '0x91c1F23b038f35fe00cbb46361D52350acf360B8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 383.5536567691119,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 383.55,
  },
  {
    address: '0x625348bdCbbbf11767BbAb2cDced4e428717Ad87',
    minters_reward: 0,
    lp_providers_reward: 287.63,
    swapers_reward: 95.53937408504487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 383.17,
  },
  {
    address: '0x1b9E67274E44b8EDDd3F51d6426D6437ba176882',
    minters_reward: 0,
    lp_providers_reward: 382.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 382.33,
  },
  {
    address: '0x2518b311dabA439f36d44531c473da06eB8C1AB7',
    minters_reward: 0,
    lp_providers_reward: 312.41,
    swapers_reward: 68.94476002530908,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 381.35,
  },
  {
    address: '0x9d3d61828a9cd998663fc5c5a09134a435ad6F58',
    minters_reward: 0,
    lp_providers_reward: 238.03,
    swapers_reward: 143.2281681589224,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 381.26,
  },
  {
    address: '0x2b4473A435aC75aad81d5Fd7651f07ee79765bA3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 380.9201174580211,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 380.92,
  },
  {
    address: '0x8dAcbE823F333bb021018E7a49d170910e1749E6',
    minters_reward: 0,
    lp_providers_reward: 261.79,
    swapers_reward: 118.63524311777455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 380.43,
  },
  {
    address: '0x8008ef3b1878cb97Dd271D788B484538446b4Da9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 379.7590991539562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 379.76,
  },
  {
    address: '0xF715fCA60230dDb83f9c7d3e30Ff61b9325b1a39',
    minters_reward: 0,
    lp_providers_reward: 377.76,
    swapers_reward: 0.40656841900883306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 378.17,
  },
  {
    address: '0x8feBFf3c103B32C61fa8c7401185595F4E1D8aA9',
    minters_reward: 0,
    lp_providers_reward: 272.17,
    swapers_reward: 105.77689730489227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 377.95,
  },
  {
    address: '0xA97adf616D1d15F241cc9B32Dab7930BD6798443',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 377.91438131442834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 377.91,
  },
  {
    address: '0x09F4a65115447aD68Cd85214F28c1a4aFEf3E05b',
    minters_reward: 0,
    lp_providers_reward: 377.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 377.39,
  },
  {
    address: '0xb8B70A510Cc3753F71A652f9e786f11584617443',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 377.2433814405184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 377.24,
  },
  {
    address: '0x42Ffdc8DFd16636523d444285272C0BA4aF7Eb15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 375.57462523349767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 375.57,
  },
  {
    address: '0x4F6C295ED73FebB7C3B51751c90A2e8d95a42279',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 374.8529605076525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 374.85,
  },
  {
    address: '0xED525eb9A67b666fcb095A7bD1089614F31bc7E6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.83436472879282,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 374.73,
  },
  {
    address: '0x217bC9ebD4027D6c135dDFD85D518857679aa8A2',
    minters_reward: 0,
    lp_providers_reward: 374.69,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 374.69,
  },
  {
    address: '0xb288CeC5783B23F50113fA8F4794b01E2Bd7134F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 374.3744519175942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 374.37,
  },
  {
    address: '0xe421cD0e681Ef71784d1095C7b42B3B07E1D7d43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 374.2292977978453,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 374.23,
  },
  {
    address: '0x54b0aEF17149F05f056243D21aB380BD1FF7c412',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 373.42066966442496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 373.42,
  },
  {
    address: '0x846C325dE144A2cd121FAaC409d0B4DC35525C06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 372.0805647494228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 372.08,
  },
  {
    address: '0xfEe94F9D2589A619Bb0c440d6a22f245F3c89C75',
    minters_reward: 0,
    lp_providers_reward: 371.77,
    swapers_reward: 0.013012115878176281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 371.78,
  },
  {
    address: '0x51978Fff0B9367A146FedeAdD49C71BeF939f77A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 371.6662849106311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 371.67,
  },
  {
    address: '0x275EaD7b3EDF2d15bD3DA3e8947399F63A4d6a9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.77238032394153,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 371.67,
  },
  {
    address: '0x8827A21eD5E90c81E07685c06AEccb4FA65e0736',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.6021861826236,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 371.5,
  },
  {
    address: '0xBbe7C42A2b464fd44F63eF0fB47F01698C9B4472',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 371.35894639937493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 371.36,
  },
  {
    address: '0xD91295CA68A990c15ef5ca3Fde18B7D29eBd6224',
    minters_reward: 0,
    lp_providers_reward: 21.51,
    swapers_reward: 348.5413530997568,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 370.05,
  },
  {
    address: '0x661698a5D0B06dd768E31657101829a3c1dAccba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 369.5039152925141,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 369.5,
  },
  {
    address: '0x40eb995bA33059268a7a64BD97A6e3a8D49abD9f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 368.87093123972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 368.87,
  },
  {
    address: '0x539ad134A97a807F4573e5f77d312303cF278dBa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 368.5932855476971,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 368.59,
  },
  {
    address: '0xB65B73C3084A3D1c2313D8faEF20206B9d8EF14a',
    minters_reward: 0,
    lp_providers_reward: 368.41,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 368.41,
  },
  {
    address: '0xF0505BcCC5c05f56b9d8EA3d3151a499Bc3a9903',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 367.6398515554531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 367.64,
  },
  {
    address: '0x2A306e31331793958dBA60cd38671928CC85B78b',
    minters_reward: 0,
    lp_providers_reward: 6.15,
    swapers_reward: 359.1241579825599,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 365.27,
  },
  {
    address: '0xbEA24893F2D8516Dd21a3BC04CC30F8Ea064fC9B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 364.89469303417934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 364.89,
  },
  {
    address: '0xe572df78813859E2F69654348eA815Ac2a335284',
    minters_reward: 0,
    lp_providers_reward: 111.24,
    swapers_reward: 253.46051163834812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 364.7,
  },
  {
    address: '0x009FC65E1c322683b64832e46b55FBf67e7aAE32',
    minters_reward: 0,
    lp_providers_reward: 355.14,
    swapers_reward: 9.481053414207162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 364.62,
  },
  {
    address: '0xa76312F85eAd80ad66133A8678a9A906a5E25851',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 362.58201016772387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 362.58,
  },
  {
    address: '0x9177C804431a26002C8FcD2E3444190217427756',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 362.28627916396084,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 362.29,
  },
  {
    address: '0xC79773c6b7724e77Adec72061d1F2B2614917e16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 361.29900360653176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 361.3,
  },
  {
    address: '0x007375550555D7Bd471CE650C4443327d4705e52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.11904975058897,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 361.02,
  },
  {
    address: '0xD7cb5a80426Bd2bC243639F02a7811904EB3bd89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 360.75845251269146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 360.76,
  },
  {
    address: '0x244379242FCdaffbca1aE5e9039B5191BaaBd35c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 359.86763751091075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.87,
  },
  {
    address: '0x6Ac8A78DcdB8B8167D487A9D341589D0878923b8',
    minters_reward: 0,
    lp_providers_reward: 142.14,
    swapers_reward: 217.35512565905447,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.5,
  },
  {
    address: '0x556e2B71E0d12c62Eddef902CFa9392B0C1c1Bc0',
    minters_reward: 0,
    lp_providers_reward: 90.01,
    swapers_reward: 269.3902922579959,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.4,
  },
  {
    address: '0xe31858c0a228522a8A9E20210A799F970F4B0Cf9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 359.1787085696407,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.18,
  },
  {
    address: '0xE40D2eAaebB8C11589f0fA75dD79e9822236ed54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 359.17334798346025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.17,
  },
  {
    address: '0x6eB69Cc8dD62Af1e69C4ff73AFD6E399BBBb8dbe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 358.4502835885868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 358.45,
  },
  {
    address: '0x8E96a887a621D74AAdC1aF1Fd2CEca2163F05ED2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 357.85003002221777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 357.85,
  },
  {
    address: '0xF682c90f28B5BDb5Cbc548CC2ba572b0B294FF24',
    minters_reward: 0,
    lp_providers_reward: 307.41,
    swapers_reward: 50.31861936718857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 357.73,
  },
  {
    address: '0xA509f3289efbC4E511dcc779322904a426018b2e',
    minters_reward: 0,
    lp_providers_reward: 27.02,
    swapers_reward: 330.07377654019973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 357.09,
  },
  {
    address: '0x8e6aDc358feDd9C3C2fd153532659762a987F621',
    minters_reward: 0,
    lp_providers_reward: 322.64,
    swapers_reward: 34.28744317988673,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 356.93,
  },
  {
    address: '0x96490ECAea837Af2aeEC6967D61f4BaB30b58b2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 356.51683722087404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 356.52,
  },
  {
    address: '0x94abA9F81aEF828aBe63905fDb0E95e321FEE531',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 355.8371137928205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 355.84,
  },
  {
    address: '0x2dbB80cF69ABA7a65E8a0B98Ef0209916E7dEA26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 351.5103896114591,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 351.51,
  },
  {
    address: '0x19c8ed9d43d194bC8A64F963E84C4C4cc2eFCa60',
    minters_reward: 0,
    lp_providers_reward: 350.95,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 350.95,
  },
  {
    address: '0xA99777F92473Af87385eb1700f71bAE2ef78af8C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 350.739616461715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 350.74,
  },
  {
    address: '0x957aB1B8Ea6D44AD0e6B4908B042E18CF4E4FCab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 350.6538159055582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 350.65,
  },
  {
    address: '0x5c34dC139676707a3979FdBcA0C7F711AA67E14F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 350.5108751706124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 350.51,
  },
  {
    address: '0x3A53d3F78a72675885A7Affe72CFfe1329945291',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 349.9238313637593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 349.92,
  },
  {
    address: '0xF115BDA7758698f582c81C389ba0DD01277cd732',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 349.3139451412559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 349.31,
  },
  {
    address: '0xa0A575b5a784bf9296a00E41ffb238741F8533f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 349.3061191555113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 349.31,
  },
  {
    address: '0xE46eBa5dA6bD3E58EB1f527180852d4EF48cDd43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 348.63416798144436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 348.63,
  },
  {
    address: '0x41B3D76Fca518E63dec89680F943126a900B5F00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 348.6327658299026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 348.63,
  },
  {
    address: '0xC5a35fcAF3Ebd46F2F062ae42B7C2948285548A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 348.2735136705759,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 348.27,
  },
  {
    address: '0xcE027a4aBE227B6420594841B1a1FF953d334181',
    minters_reward: 0,
    lp_providers_reward: 347.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 347.94,
  },
  {
    address: '0xB7B42cf2e3Ef393cd0FA723A28f2FE63515189ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 347.8604100055219,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 347.86,
  },
  {
    address: '0x58d45cdB0f580E4d0186700531290117c4A065Ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 347.57978904287654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 347.58,
  },
  {
    address: '0x5B35Ac359eD7C56128b37E2A84b8DA6993Ef7441',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 346.0941752824623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 346.09,
  },
  {
    address: '0xB020589F511152cba2CCA755985c33f37c3494a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 345.4238971763098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 345.42,
  },
  {
    address: '0x7d6ec015351B6D7ec055082188A83A0d4b8094fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 344.91145837068296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 344.91,
  },
  {
    address: '0x6E4d8aBDa42cAff7Cd3E177A962d4e6100Ae58b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 344.0715983334339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 344.07,
  },
  {
    address: '0xB10be1F7a1c546f28BB6cb0C58dF768B9c553337',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 343.638064837717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 343.64,
  },
  {
    address: '0x5D98C365659E8911AA2Aa095fED3687918be0e6F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 342.7013529257781,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 342.7,
  },
  {
    address: '0xf5353A299Dfa7a7205CE6850B191F8E461f85A1a',
    minters_reward: 0,
    lp_providers_reward: 342.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 342.68,
  },
  {
    address: '0x827457CBd88Db442235EA7F75a8892b9885b36A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 342.4352348544038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 342.44,
  },
  {
    address: '0xBAAc21177b8E9696DDE47E353a2bDC2673e1bCB1',
    minters_reward: 0,
    lp_providers_reward: 8,
    swapers_reward: 62.911660998386594,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 341.81,
  },
  {
    address: '0x4C6E33027E247BcD3d0352d23e10FD3A38F5B557',
    minters_reward: 0,
    lp_providers_reward: 29.51,
    swapers_reward: 311.04650524709797,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 340.56,
  },
  {
    address: '0xFB30Fa4771c0bb35672772D42C6e2da5be3fa55b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 340.5170899281871,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 340.52,
  },
  {
    address: '0xAeABEb1cAF66CeCBE56d2e7834f7Dc69005545Ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 339.7947331087572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 339.79,
  },
  {
    address: '0x69F912c1D4974b5B71dcB9773C39d68E13e50E95',
    minters_reward: 0,
    lp_providers_reward: 64.43,
    swapers_reward: 3.4845511787698142,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 338.81,
  },
  {
    address: '0x2E706bc783789703089143A03033780f3D330eCe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 338.5923938051707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 338.59,
  },
  {
    address: '0xa7660698077D3a5ee23E06752f38dedBe7875971',
    minters_reward: 0,
    lp_providers_reward: 338.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 338.59,
  },
  {
    address: '0x38E87ae49B255B6D1F8626b09c0915f4b2660A20',
    minters_reward: 0,
    lp_providers_reward: 250.75,
    swapers_reward: 86.81836729799564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 337.57,
  },
  {
    address: '0x0034F3aa89857F803C7F9f4fC1f12c06E3bac3cE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 337.069535290862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 337.07,
  },
  {
    address: '0xEf5dF86f34ef40758eaaDCAAF5FC5426Ccf8dfC9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.51725656812171,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 336.42,
  },
  {
    address: '0x4EB5E0C928b5347A9C631C2a1e41eF2629c9F458',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 335.7750635308277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 335.78,
  },
  {
    address: '0xe0c885e235328b91847021F947D967FB38116D2D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 334.40420503095817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 334.4,
  },
  {
    address: '0x1e0165B582ccCf0A921ae51434D935CC0b2EdF74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 333.84405633504707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 333.84,
  },
  {
    address: '0xf027402e9aE9acD1564A7378dB0C2B7E594546B8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 333.3859420153325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 333.39,
  },
  {
    address: '0x053fD69755e63F90045440Edf0e38fEbeAA9BA01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 333.11238452171125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 333.11,
  },
  {
    address: '0x1ed9cA18075e224c507F6578a683188691B4f4D2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 332.75206245826894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 332.75,
  },
  {
    address: '0x88aa59bfcB7Ea5A2203EF25D99C5DCa1CE7c4f4A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 332.5537030651398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 332.55,
  },
  {
    address: '0x8d79FaB7d593D97681956556328d1A70689FeB46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 331.8948780336733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.89,
  },
  {
    address: '0x0d91b63FcE198203b2b7dBcc5323284f39d3Dc61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 331.6961087270439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.7,
  },
  {
    address: '0xC41860f7ea0b1375350c73C197D7caDE13A4a460',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 331.5642868372447,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.56,
  },
  {
    address: '0x3887214822D66118eb8F69185beb3E62116E9cBB',
    minters_reward: 0,
    lp_providers_reward: 3.14,
    swapers_reward: 328.2252951746732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.37,
  },
  {
    address: '0x4f9CC66F4D0D41e6dc20aBA1B781a529FF948628',
    minters_reward: 0,
    lp_providers_reward: 317.8,
    swapers_reward: 13.367869123976238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.17,
  },
  {
    address: '0x3d80D094221c0f2A5594336c0F08C857dd1b2DA4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 331.12753158433065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.13,
  },
  {
    address: '0x94698DFCbDCBC681EC1287E902c7ba06b77AAFAA',
    minters_reward: 0,
    lp_providers_reward: 158.65,
    swapers_reward: 171.9755811557046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 330.63,
  },
  {
    address: '0xd2C4d475F4130805cFf1651DC731794508a452d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 329.32496390085186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 329.32,
  },
  {
    address: '0x87250c1fFe62f528BBF7EDe4E59E58F8f6970b29',
    minters_reward: 0,
    lp_providers_reward: 151.57,
    swapers_reward: 177.33342696075164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.9,
  },
  {
    address: '0x1FB895c5Be6edDAf7aaBB9bD961E2b336161A029',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 328.8147587315172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.81,
  },
  {
    address: '0x8b16e9AEDC6f5282b6A3db3200754C7926FBc1bB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 328.71521955851335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.72,
  },
  {
    address: '0xF8a427Db4b7e05557a9012e1829Ef346b3aB2726',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 328.6717395932011,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.67,
  },
  {
    address: '0xffB3219b266Dcd245706C90EFBCA50106e9ACb90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 328.1924391574583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.19,
  },
  {
    address: '0xA6E99534c771CA6D9d5237CeC07f706C9cB3d991',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 327.9555737577638,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 327.96,
  },
  {
    address: '0x6184027092776673FBCcA4f97CAAA92b078e88c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 326.91451954831456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 326.91,
  },
  {
    address: '0x807456AdCF923E84D3789e2C96376Ac1Fac91FA6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 326.2913886625202,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 326.29,
  },
  {
    address: '0x4dc47eA9693e7F748be1C63BBb3124ED86A45071',
    minters_reward: 0,
    lp_providers_reward: 50.59,
    swapers_reward: 3.279735594459073,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 324.77,
  },
  {
    address: '0x5F7499Fb9a03bAb7CCa6240c504eC5edc0b7503C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 324.46570304523374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 324.47,
  },
  {
    address: '0xe1869A511a7B1cBB5Bf621e69D40A485EC19C2bF',
    minters_reward: 0,
    lp_providers_reward: 323.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 323.98,
  },
  {
    address: '0xe0984E199015ACfa434E39731FD89B7E65137981',
    minters_reward: 0,
    lp_providers_reward: 200.41,
    swapers_reward: 123.47543465270448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 323.89,
  },
  {
    address: '0x72eEB46998BE64543A604Eb93cca0C1BFAF07F2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 323.61343193264685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 323.61,
  },
  {
    address: '0xc2E88618Dbe0C2E4de76b6Bc95Cb66Ee0262ec7E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 323.2284493463914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 323.23,
  },
  {
    address: '0xB1d2d4B34D52AAA0159Bd94f89663DAe90712e33',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 322.9826337311232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 322.98,
  },
  {
    address: '0xB424cf1fC0D8426Ccbc4A3871F832d4D87fc6aeE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 322.7948752641563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 322.79,
  },
  {
    address: '0xda380541A3766Aa2004f6C8662F0480D8355f171',
    minters_reward: 0,
    lp_providers_reward: 321.24,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 321.24,
  },
  {
    address: '0x6Ac3D5965Bb8930b77e9a72A944152D0b8628065',
    minters_reward: 0,
    lp_providers_reward: 320.54,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.54,
  },
  {
    address: '0x305B9cC345Ce267d4D854509425ECc54a5919c58',
    minters_reward: 0,
    lp_providers_reward: 270.25,
    swapers_reward: 50.26284312545661,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.51,
  },
  {
    address: '0xa5Ca3eb29780681332cFc1eCEadff3B3479cF976',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 320.17851167733096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.18,
  },
  {
    address: '0xA192C1d7fb69d995827DF83b37e76F1287D8dcA1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 320.06175631641315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.06,
  },
  {
    address: '0xfC910aA9278fC6DA68a27B8438b588c0727cEa58',
    minters_reward: 0,
    lp_providers_reward: 320.06,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.06,
  },
  {
    address: '0xC266a20bae8D5B4006672a2B1dA3fB990ef7ff25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 320.05416849159747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.05,
  },
  {
    address: '0x7965cc44101916274C049c5C1d80aeB5fFA41F3e',
    minters_reward: 0,
    lp_providers_reward: 48.44,
    swapers_reward: 0.3034735949054698,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 319.64,
  },
  {
    address: '0x729AE9598A43A1F22365AC0079043910388e44c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 319.56555032366464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 319.57,
  },
  {
    address: '0x4705B613D8C211AeA1a4FDdc6a10C102e57caa3D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 318.9624570711363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 318.96,
  },
  {
    address: '0x3e1349eb312D901F8ce8bd3311347b728a602b81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 318.6499002737982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 318.65,
  },
  {
    address: '0x92692c124c825e0C7173e4462CD61526B8C2F67F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 318.00152442454436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 318,
  },
  {
    address: '0x6b6f740e440E42D48b6a419FE0145670787Ca722',
    minters_reward: 0,
    lp_providers_reward: 316.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 316.86,
  },
  {
    address: '0xBD953a191a942622134793eD80b17D17db1EF2DE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 316.73708891349935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 316.74,
  },
  {
    address: '0xEf88Bc8cBB4Ff20aE175A989ee30798C5E33Fbb4',
    minters_reward: 0,
    lp_providers_reward: 62.55,
    swapers_reward: 253.3735923780455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 315.92,
  },
  {
    address: '0x89eD66A0f628B9c8e967F700B2Ed6C7B769874b5',
    minters_reward: 0,
    lp_providers_reward: 87.47,
    swapers_reward: 227.93697527219092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 315.41,
  },
  {
    address: '0xec9dd597616Ecb4B60064649c98466a470c79244',
    minters_reward: 0,
    lp_providers_reward: 314.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 314.81,
  },
  {
    address: '0x66466574d659a1898B9eC8051f2b7a851eF5d0Ba',
    minters_reward: 0,
    lp_providers_reward: 314.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 314.76,
  },
  {
    address: '0x63AaDaC623a1F6E5881a5306061B3339E1223D21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 314.1981286940588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 314.2,
  },
  {
    address: '0x4131A8EC70eA208Ec4326341D291ad21CAeAA8d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 314.03736691102426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 314.04,
  },
  {
    address: '0xE735c727c891f0d2b1c6C46D588E5ef807612e34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 313.6238512450035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 313.62,
  },
  {
    address: '0x48541c7b6fA4B8276683F09411Dc8297CAdc5058',
    minters_reward: 0,
    lp_providers_reward: 306.96,
    swapers_reward: 6.657615726935605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 313.62,
  },
  {
    address: '0x5b49977F9C4F68B6BC2b55bC90b06cd68892EF95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 313.4853705512975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 313.49,
  },
  {
    address: '0x8d5B31D866C87DEc0Cf7C291efc106695a56ec71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 313.2694022414108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 313.27,
  },
  {
    address: '0x7fbaD080bf99dA212c866d1fC214b7a764aA9362',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 312.6378630606054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 312.64,
  },
  {
    address: '0xD737715A4fdFCC7A33498Cb2cdE37b81B74be288',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 312.58847406805626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 312.59,
  },
  {
    address: '0x8F08F62C3E1f9879B1d4CD598aC54e6272ee83CD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 312.2852663345712,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 312.29,
  },
  {
    address: '0x5cFc7B94Ad7257Bc84851129c41D54Cbb058D2cD',
    minters_reward: 0,
    lp_providers_reward: 311.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 311.62,
  },
  {
    address: '0x5CA1d4be7126F51E50B5aac0aAF0417B2F7EFd32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 311.2004595529365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 311.2,
  },
  {
    address: '0x064E184A5897F2AA491297A124c51dB83c32a707',
    minters_reward: 0,
    lp_providers_reward: 26.44,
    swapers_reward: 284.5385191436408,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 310.98,
  },
  {
    address: '0x7F71D62Ca3d45B51390BC24a706FAe2f284c000a',
    minters_reward: 0,
    lp_providers_reward: 310.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 310.1,
  },
  {
    address: '0x7921101127b3904c305A9AEfe463eD3c859B29DD',
    minters_reward: 0,
    lp_providers_reward: 256.54,
    swapers_reward: 53.27978476989317,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 309.82,
  },
  {
    address: '0xFd8A7a27aE4Fb591Bd467A300654002517324C52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 308.4758042898214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 308.48,
  },
  {
    address: '0x773da58a3a93D0B57F1f2BB3BE3324c33D8b5c38',
    minters_reward: 0,
    lp_providers_reward: 285.77,
    swapers_reward: 22.263440394118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 308.03,
  },
  {
    address: '0xCE6D3B176B02f37668504BD4c291c1334520DFAC',
    minters_reward: 0,
    lp_providers_reward: 25.53,
    swapers_reward: 282.3777680247501,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 307.91,
  },
  {
    address: '0xf55a7bE60e1c3CdCE50d2167cCAbF5cd4277Aaf6',
    minters_reward: 0,
    lp_providers_reward: 24.73,
    swapers_reward: 11.834052055816878,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 307.46,
  },
  {
    address: '0xE2bc2B985553eCb7C98954cD50399C38454c4fB9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 307.4531594086891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 307.45,
  },
  {
    address: '0x56F6D5648b698945e71584718d772fa2ca1DFdec',
    minters_reward: 0,
    lp_providers_reward: 26.24,
    swapers_reward: 281.00761989422904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 307.25,
  },
  {
    address: '0x905D0460C3b2C4514AE81c4FA02374ce19b9ae55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.656176018604555,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 306.56,
  },
  {
    address: '0x2895b934af1ffEBdc3435357deFc70f3D13D4013',
    minters_reward: 0,
    lp_providers_reward: 213.93,
    swapers_reward: 91.7957840320098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 305.73,
  },
  {
    address: '0x15a321e60D918bc21605db59e6738EC8BfF094d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 305.2668752273939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 305.27,
  },
  {
    address: '0x69dD1f8b03a8ef898d68e99d0554243788A826d6',
    minters_reward: 0,
    lp_providers_reward: 159.9,
    swapers_reward: 145.18510549037305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 305.09,
  },
  {
    address: '0x8AC2Edc61bb6e3577Ada2a08e16C54fB90295701',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 303.75202235878686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 303.75,
  },
  {
    address: '0x569bb55aC4a1512c342933B1FadCfCEc52db82D9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 303.446039875009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 303.45,
  },
  {
    address: '0x19eD9F06c9244699FD1FF19558220BFAc64b9c31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 302.3920108352452,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 302.39,
  },
  {
    address: '0xFAcE74D5E15E9e0701F5e8e57a74e2d1A471ff0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 302.24632777124464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 302.25,
  },
  {
    address: '0xE71bF5e1122Fbd2E6B09b0653Dce74a151a9ff92',
    minters_reward: 0,
    lp_providers_reward: 67.18,
    swapers_reward: 234.29598919007498,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 301.48,
  },
  {
    address: '0x7E4e48097DB478ECdb7D4bE2C5780BB5B585208e',
    minters_reward: 0,
    lp_providers_reward: 104.32,
    swapers_reward: 197.08780090789486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 301.41,
  },
  {
    address: '0x3BC52a196bab833947F47CE24600eFa360530698',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.98641536012997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.99,
  },
  {
    address: '0xF60866090E059AFb6751F71bC40b833d05819bEe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.963120590659877,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 300.86,
  },
  {
    address: '0x88bDd6B27Bd4dF05f0A570c4a4c9d56A0f202cEd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.8425277918625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.84,
  },
  {
    address: '0xb74fFa55311b61E9ed437325498d133F9d18Ff23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.48102461798305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.48,
  },
  {
    address: '0xC34c74d264c6130034E03ec1Be39cc3ecAe3063E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.40759070804813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.41,
  },
  {
    address: '0xFE9059742b9F5d9AF0A73Ce5A8D68b183CE33198',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.1752927800312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.18,
  },
  {
    address: '0x7b977230B194B020A983f42A013bD4F10E98FE07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 299.1659016525946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 299.17,
  },
  {
    address: '0xb4Ae787cd77782D78D04D04d5F90570cECDa9756',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 299.06387712699734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 299.06,
  },
  {
    address: '0x2c3620bC6898B5bcC825e30CCBE36dDe0C423A89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 298.9022096226516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.9,
  },
  {
    address: '0x035a79B456E1945c7eC431AfA0c0bA15E38fC39b',
    minters_reward: 0,
    lp_providers_reward: 28.32,
    swapers_reward: 270.25644370508587,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.58,
  },
  {
    address: '0x12bbcFAe385cF4838860B416e41B48e8bf269A27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 298.47534964147206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.48,
  },
  {
    address: '0x7dbec91c5dF027706211DBb33a5005420FFCc15c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 298.3584377004978,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.36,
  },
  {
    address: '0x437DC79Ae608C9c4B41258728181f89029D4DB1A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 298.16164970963126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.16,
  },
  {
    address: '0xe8a210DD8d9E32403a74625DcEc398E51687E1E8',
    minters_reward: 0,
    lp_providers_reward: 298.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.1,
  },
  {
    address: '0xC943f587c2598fa8adBCb6439b954d1Ae1747614',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 297.977967760433,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 297.98,
  },
  {
    address: '0x2215e45A8b0d47a21c503C651c93fa6c39856bc7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 297.07125286838794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 297.07,
  },
  {
    address: '0x13267Ca893975873cd617114D99CB0816cFFDb4f',
    minters_reward: 0,
    lp_providers_reward: 213.13,
    swapers_reward: 83.30180296414798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 296.43,
  },
  {
    address: '0xAeD8F743548A544DC501CAF597F61864e13BD68b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 296.17490083385644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 296.17,
  },
  {
    address: '0x5171adb7C75D10C1a452253d7F30B7e31A670A9E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 295.11516370698274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 295.12,
  },
  {
    address: '0x4c8CA58562d765Cc0d5bD7FDdd38b3cA6Ad5Cce3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 294.93120186733984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 294.93,
  },
  {
    address: '0xbd9232fFEd64752312e078B9cA7287279d57D21e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 294.62693044726683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 294.63,
  },
  {
    address: '0xD917e87EBF1C861e064De42cA053a07f3B26F69d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 294.23415376647677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 294.23,
  },
  {
    address: '0x514FbfE81A7D27cc7a9C06f34C277741cda5a438',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.038268608536747,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 293.94,
  },
  {
    address: '0x3bEbb9A0075C356ad5cae9d1704547ab8e84dd45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 293.7251346619958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.73,
  },
  {
    address: '0xdD2aC1E0f28DD7FA35ea053a54f2D7201C937C20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 293.6247235084996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.62,
  },
  {
    address: '0x994E477E6ad1712cc732fbF79b5De39af7e63c39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 293.4721214043484,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.47,
  },
  {
    address: '0x51daC3344A8806b2178b9EBb4083BcEa5f6768aC',
    minters_reward: 0,
    lp_providers_reward: 286.11,
    swapers_reward: 7.293068645061963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.4,
  },
  {
    address: '0x1035b321B7de7555861000E551d4CFcB409B6e1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 293.32615463673164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.33,
  },
  {
    address: '0x0ea141316b956e5037fc2715329BdAE44F2247dd',
    minters_reward: 0,
    lp_providers_reward: 292.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.96,
  },
  {
    address: '0x66757e827D390D0c805891D8B5D9d7f095Da96c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 292.6758782368558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.68,
  },
  {
    address: '0xDaf05f3E95B647a5C229a5496048c1A7Ec23ACb6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 292.5694863224768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.57,
  },
  {
    address: '0x6eaB56B994cfC38fE4b02cBa0E19D1b2D377D406',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 292.53011499921575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.53,
  },
  {
    address: '0x866023278E5e55D1543bf9B63bE1d5678CF5E5b3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 292.435083250246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.44,
  },
  {
    address: '0xa800bFe2fd71710cBEAfC64c344A5F46F30Ea7BA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 291.6394972345261,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 291.64,
  },
  {
    address: '0x632Dc52E750E7a380B9FCEd40Bd2466978966E32',
    minters_reward: 0,
    lp_providers_reward: 20.44,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 291.34,
  },
  {
    address: '0xDAD764D2eafB7Fcf5201FF992f9f93B1288EcAcd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.17488119781569,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 291.07,
  },
  {
    address: '0x603f1ac5d697A5D26effbAdc70fA21f7fC811003',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.153254305664053,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 291.05,
  },
  {
    address: '0x170D2370b773D56016a14311104Eab7086846F6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.718986416736954,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 288.62,
  },
  {
    address: '0x81413FF630133742dB50b9A82dC1aa5647847223',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 288.13056005038976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 288.13,
  },
  {
    address: '0x49f8da0dd3D3a05b4bE5fc937eeda8Ad72428213',
    minters_reward: 0,
    lp_providers_reward: 287.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 287.71,
  },
  {
    address: '0x341431098e31fe869FCA30BaE789A081248C7F4a',
    minters_reward: 0,
    lp_providers_reward: 116.59,
    swapers_reward: 170.88573650071248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 287.48,
  },
  {
    address: '0xb358702f80eEf30c00f1c6018107984d32B205d6',
    minters_reward: 0,
    lp_providers_reward: 255.13,
    swapers_reward: 32.29471236801532,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 287.42,
  },
  {
    address: '0xd32780faC7822A575fE8367D20402127fF96e7c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 287.3304914021248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 287.33,
  },
  {
    address: '0xAbA88F4Ba9a4af03756b0Db788dfd593E7e36170',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.981594954069017,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 286.88,
  },
  {
    address: '0xDfb542fFee4a7Ccd52ea996c6ca530e2Cd428EB8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.48793074100916,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 286.39,
  },
  {
    address: '0xEcE7f58C319C0E8aeD9F9716e3a0Bb5c001B80A9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 286.3282347403538,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 286.33,
  },
  {
    address: '0xd3D07F6F026E6fF480d9a06dF0C7fC0f88bDc48A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 286.222743108216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 286.22,
  },
  {
    address: '0xD22F41D9c1172f8BE4BeAC2b4910959F90e6B8aB',
    minters_reward: 0,
    lp_providers_reward: 286.14,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 286.14,
  },
  {
    address: '0xfEfcCd113e74DC10a98c676536B60F72884a09c4',
    minters_reward: 0,
    lp_providers_reward: 203.47,
    swapers_reward: 82.27722387583131,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 285.75,
  },
  {
    address: '0xB65e31A1Bc3cEb31dCc52dD164b5C22c4CA64020',
    minters_reward: 0,
    lp_providers_reward: 285.72,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 285.72,
  },
  {
    address: '0x20d802Ef407928f02b43B3948A4F70f7D11fdb4f',
    minters_reward: 0,
    lp_providers_reward: 226.16,
    swapers_reward: 59.37152582088841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 285.53,
  },
  {
    address: '0x82b6a54205E2987760952ef0DC670C17b5678529',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 284.72320574032113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 284.72,
  },
  {
    address: '0x6B6Cfa0cbcAd745E5Ca383319Ce8A784D955d840',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 284.7058003896721,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 284.71,
  },
  {
    address: '0x90e9e84414D5A094Cf525001ceeBe76c249cad26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 284.4819919798897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 284.48,
  },
  {
    address: '0x7E2136B0992Ef7899e9993B208807Bd7e0de27d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 284.0024623830707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 284,
  },
  {
    address: '0x3Ea41c5Da4e782DAD354F16B6a546110211FFAac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 283.8515375744925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 283.85,
  },
  {
    address: '0xA691699e5B1F6Fee42AE90591625ee747AB67c32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 283.8456308898038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 283.85,
  },
  {
    address: '0x4ba8455a308031a0dc7f129008dCec2c0544aF5B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.855574814380367,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 283.76,
  },
  {
    address: '0x2b4cca706c4B2fba22AF57aED953C447d877cF28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.585521317650947,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 283.49,
  },
  {
    address: '0x2d20370699CeF0b48486Bd97a590f9E349d198eA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 283.0909354646068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 283.09,
  },
  {
    address: '0x11D7593692B3eeac1045d47b9a71c0BF697452b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 282.92963182802504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 282.93,
  },
  {
    address: '0xC55c670a0B8ab44eA66F7de88D43730C47861b99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 282.63280566810334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 282.63,
  },
  {
    address: '0x71E1BcD783d3397F4D82B70bc5DCeB194ecA7112',
    minters_reward: 0,
    lp_providers_reward: 39.27,
    swapers_reward: 243.3534711630821,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 282.62,
  },
  {
    address: '0xC14615ad68e6d13284faEE70E20E67600eC40809',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.981895155402746,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 281.88,
  },
  {
    address: '0x0Dd94f9CEAfce9F3B1637b3Fe6B1F966E5A12fAe',
    minters_reward: 0,
    lp_providers_reward: 150.45,
    swapers_reward: 131.2096580368559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 281.66,
  },
  {
    address: '0x677939b16594147369C82247F8e5b29E5ef23814',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 281.36400677412007,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 281.36,
  },
  {
    address: '0x70923478bb62A1F2eA24466345BB934e73d632BA',
    minters_reward: 0,
    lp_providers_reward: 281.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 281.34,
  },
  {
    address: '0x7AB7f1bcaAfF0f198Bb93A088642D1d2BdCFa609',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 280.7433108575521,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 280.74,
  },
  {
    address: '0x2641036636982D55FDdCA6D970Ee4B5D3a26BBab',
    minters_reward: 0,
    lp_providers_reward: 278.97,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 278.97,
  },
  {
    address: '0xfFCd1aAc7fe8Bb859a397849Aa3A84533C8305De',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.918038326165407,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 278.82,
  },
  {
    address: '0x92e63682dcEdA91EbFB14178Cbb20CAF172A9297',
    minters_reward: 0,
    lp_providers_reward: 278.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 278.66,
  },
  {
    address: '0x120Ad2d604F4F3436C1dc2b3514404646c809557',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 277.95946816076196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 277.96,
  },
  {
    address: '0x14882f9a40db4253ed3c8EE8CAac2C422B64AB33',
    minters_reward: 0,
    lp_providers_reward: 19.4,
    swapers_reward: 258.2014767735177,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 277.6,
  },
  {
    address: '0x81a210eE3b8b72BB78A811f0c41781Db2464C2AF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.565201209912495,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 277.47,
  },
  {
    address: '0xCf8D793641f07f5bA3d0A35fF5f6d1F9cfb25F85',
    minters_reward: 0,
    lp_providers_reward: 112.18,
    swapers_reward: 164.61917573267726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 276.8,
  },
  {
    address: '0xF6C2D2f2d876d44EDaE9aA1e21563AC685370c74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 276.27052061271155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 276.27,
  },
  {
    address: '0xdB1dd374510D660291ddd53C37b868f9f16F29f0',
    minters_reward: 0,
    lp_providers_reward: 83.87,
    swapers_reward: 192.19005618894823,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 276.06,
  },
  {
    address: '0xcBA9cedD2e43A030086899264ad3e767d8732da2',
    minters_reward: 0,
    lp_providers_reward: 172.15,
    swapers_reward: 103.53719584310022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 275.69,
  },
  {
    address: '0x3D897Dd41C1f96044Eb74f33ea1BFfA57e07164F',
    minters_reward: 0,
    lp_providers_reward: 0.57,
    swapers_reward: 2.3340925026936565,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 273.8,
  },
  {
    address: '0x236A0824154C3D2c991aC25c7467eaDfc2D5888d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 272.95084500389953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 272.95,
  },
  {
    address: '0xA54e218732dc1768544BC69ba726F930704AB5CE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 272.87744370751085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 272.88,
  },
  {
    address: '0xe71712dc0508f9c4374416C0254e6C5B76Cb628C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6488352062624372,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 272.55,
  },
  {
    address: '0xb10E87949F0559Fc98df339f73b049f051a36Ca6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 272.44344813660194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 272.44,
  },
  {
    address: '0x5FbdC0B05E1f923784b8Fd1e82b0BaC983e32eE4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5790212245020189,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 271.48,
  },
  {
    address: '0xB3EdcBa97A97a248A9a3D518360793E2127fb421',
    minters_reward: 0,
    lp_providers_reward: 271.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 271.42,
  },
  {
    address: '0x37eF759eBb55c9d45DDfeA8A6fcb71d95E1cEa36',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 271.37497280189683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 271.37,
  },
  {
    address: '0x3394341A3337bc2e67A5f262d75b3377342E51f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2FD79EA8536b7b65d848f62627793c95e35Cf2D6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x24565745BaFfD490D93d0d4Fd0048003aa830ACA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xE65e65f08ca1Ffed4ac9896BEc50986E91751bA1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xF2181ff45fB48A5a2E25DF13614a10f000326762',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x285973D3A973Ed4a7326c89ea68DC19E271B5aC3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xeA6e1E9cc5e37AD104e9f9A44F41F5B9A0dA727F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0721655D679eAE155d6117E1E46Ca219b8Ef03C3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6709aDf3381e06eE5c65F20A561f0168E1F8114A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0725F71c6f91C6a6C5C1cBC303ECB0B25BbEC4bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xaBf3853c2608ea58A7DF0A0A39f01126AD0CDaA1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x08189DD62C7bAc430De62beC4b5957d866aef074',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9c3Dcf5be9a76D3d1cf4152ADCe071D50d30f24e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5272f160E83132AEf4Bf9309503E518cce2cd584',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xa63A35237441eFaC01C0F5baC0031BFF7628853E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xDc3e396708Ce9D29E940dF1c7D5fE6c4f602696b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6869A8bbBB4E5adaF82beeaF7e7c1EFEf671B27d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe2EF17a5973b63f8a4B0B1C550a74157aeF12186',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x23109DB4867159Fbe08445e830c6d7cAA78cC99B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xF74E9D381B7E22b3695D82D6A7B4395C6C75f935',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xbAd1616f50e4a1348d3439c1514698db580e0428',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe6C6E0B1C1D4876D1Fd32BCa11b21D23653b5D20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe3f395eA63F28962A0c65f476F3dEF9B6068F7Cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xAC89D5dcBe6387D012f3A61A9F33a1f0D9C2B30e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x7251f278940C3685EdFe93e72cbfE22C53F60E43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0b675F4C96fB985e136f43E5b227ff8578d7647A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x488B3Ae9B55bac6622D6cB60871e97979F404DC9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xD2B67dA2B5E1585Bf4D7E8522Ac00D4485bb872c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2828aFBB84624DbE434795978A7470A76a9860Ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9a0B59BE0B2220d6B788A316770Ed08E0Fe02c3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x599367b0cfdCf3a15897dA54C058C867F7FdD723',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x658116983aD239d2f62c6E73AfD687358d690AF3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xC41659691C6AB6b15777f57cB96be2a8b78BaB94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x37339f47563fb644e7add3CCB11Ae78F4C05f135',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1650a5fEbB72BD0BB3598BF80bcAB3F326A8C924',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xfd3e8B3A92852F0244894537189C2E374468f195',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xb75aE7cE7Ab4192311FD7b292aDd4aAe112C9657',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0e73a15EE0fec3d96ee971bf1aA61fDB81215231',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x702C1A9032e5265dC86642F3BB2F4DfCda740Cf5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x8c9D703e779FAcFe5F0F47ab1B810AEDba38C9F5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x31104f41a389F48d7AC36B8328DE65782EB6097f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x991eCaDDDEaA1B1DBf22BBcECd5D32273a6c416e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x82F793f736d0a695f5215bA56D9965D0105A684D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x4B7Bc0032ACCC405701324d72FCfBFcB7c3cD763',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3321C62a92aEfa6106cCb8104A29887557166eD9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xbE49D2765E3A39A5a8fcB80EDa3e6269599bf51b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x98b1Cf6a0C79aDaE4401fa53DB7e5Fa944b56e62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x4E0dF3eEaF199D3855309F560bf9C3b6897b6dDb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x48060Fe79144DbC33297140682363231c4bf23E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3726A5baBeb64bdAE0f10FCCF25433FF4F0d6032',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xFA59Ae0Fe58b72634fd175c3E090019118f1B62D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xE35B77396A8c0cF1E4c52262474b4ae60Af5Ef74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x12f5A519562D58a3B835becb36BDBcE600c9A0b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc11ABe6C23b6fdC94571d3653BaDa0c744a058D4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1DA5E004fEC584df83CeDc16BBB137DC403643BF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x700759b15d4e0e60f21d3F825a31d6089A6279e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x7844728981e84a0dD70A3F387e40E77854b92eE8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x82f12b36374bF5c5bf349b24Affc54fA958C81cD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3738213B53eAE5dcDC9292D49Fa178DEd7556c90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xA3A29d2A820E6E538A1Aae125fF326795Ed80Fc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xAd77558986Aa6ce4E8E32Fc7dA0519851D398b00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3100b56FC4929a52f69FC5695a7cc1e96dbCd779',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x525Dea51CE6f0d014229ff5b99E01D162649fB68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2A3cB8Ba552D8FB11c53fD115074200DdD679173',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xBD4fdFAaD69221C842c3c6E8e1868FD8d69E4B03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x8006DbBA42dAaA9274ff4bBac12c2567B606daC2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x555a5E1f1FB49Dca68D9d64BfDe7444aF6b67ba0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x4F5E94Efda61B4b86Bf039eb6cf9faDE24f5D7BC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xEDFd0d5cA89497339ad86a122eB2139700C3D741',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xdE6896b9E7B5814c42aD97661a1F602522519763',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x061C328717d7aF95161900011c9A3d87acB98d40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x10D8Db6bE4DE3b63fb04A18aC6a8bfF6437D5bBA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x03e315F3DBa2820999DC157fC26948AB0C368eB4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe3a31DDF8f127Be8170b1aAC0d01F367c13C76dB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc42189c9E334D201A903fad4F4F3822Ac60d2A93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xCd7e75FB8240bcBC54ec97fe916659C4B5993fB4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6556b7e8319B2832959a55e2E9D32d3293EC07C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9F5C28FfBFB2c14834cb37f6fC2D04e6A71406DF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x212c5AE33F8668B700C62EE90b146f043a1EAeE8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2112F392078826011fEB8820D356272Fd6474da8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x14F2BcE33f7123e96fA25Ba9052d374c7883dAe7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xf5C7Ac359C869e1099Ac128eaf276b9783D4B4aD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5476DA5dcedd31D253e601CaE092027e88E84648',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xDE17C98CA4f9ff6208d73571F7cCFEd0A4Ae592c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xB303e12445B5aa5cB79AB18cD85aC70FB9920851',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x94006CC7abF94f8f2281ab267Aaf3eF5aFFd2DD5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x081AdA89f25751602ef7a6BB0B72209F7Bb104B3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x7ac255F64CdDAeb0385787eb4852ea9F749913b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xf058393b96fC9FD6831dC12b4c85eeeD856687e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xC37530d22352C9543E0152548831e62852CF69A4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x63525592be593899c98327eeE05b46ad2B7F4DD3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x71f3707263887dAafc777232B089353FE9222E3B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6775376958c5e77Fd599965A851b71E02337E62B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x88613e5007fD19e6DF9ac6Dccfbe90d40572562e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xD27A48c902ef3E24f924ecD50eBF89507d850cbB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1d9886Ea8b73096AF9381841d5b9157Ba6127e2E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc4EDE909e938F41Efb8E0F967e2493F2446f39f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x045ECb2CD982d0d39646D46B637d35FDeAf22505',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xE1C5D7868f0B2aB2852a472d6f3B28F2a55a3106',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xeFe2202a932296253a1Ba88226167173c4ec20f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x90AF5C53A466ca247d8E0f7d2A4971Aa2decB4Ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5B1275a387d58f95ea8252E48fD5214e74241992',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x57091E551ba4eB7544194682fd9824e5e46B166f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x30F9D19F76EAe695D95540795bb3C8De9A4760c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6e2992233A09Be53CDEfD3b45DF37B1E28A3dFeE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xd2253fdab45E8fB8DE8B6bdCc61Cf7F23602A3f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x31218F8e530c37d7b6Ae83C86dc186a964f2E5a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6a48e631EFac49CcF20C04EDa61d4F0a02E6f60A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5a22e15cBa6820814CFc51EC16951CcD9b0bc169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0eE7c96F48656f676141608133909cF5ADe42CBf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x80874002A8C51D749458E1fdB60853396D2C2D85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5F6830EF70c83D3A709F47674ca0407D2b9a694d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x47DE48B0669fDdf9d1bC9d09DCc7F601fc079875',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x8a50Bebe04E4B474dFD39bddc9bd01152d17Abbc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x97923bFbd1D21Bc22984C6e511C2580fB0fd4215',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2a46F764b071215B45c648f5905B3bE0f55A07FC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xdF7165a0d847e3B150599652040d2b1f06A2263F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xDb2fBdB5d721Eb4775252EeC86Dd76df13b7C5e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6B5DEDC648Ab8450FdEed77adb77DC3E7A4E35df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x483f56647E9078E7b6a1d85Ed0584967D4A5037c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9E0E24739B3a6FD92c4CC275788e6a57b8e7d925',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x67A6789E42E889326931CFcFFae2be527893f7A9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe8C4ACcA1Cd6A725cAaF6d16f720d486bf119481',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1539725A7412FF554e7D1De816f300B4a0099Da9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xD4E7218b50F06E8D769F55D06113c0984aBc036e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x34799927733B20C3Ab7528434734899f18E43E58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x59aFEf0b7F5830fcaE9c2b6a9D0bec481667E82e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xC0e73754cDC735Dfe89ae811C85Ee8a87178a565',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x03Ea64a5CEd21E2CccFaEa0Aec283d0A0afE9424',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x82a456c6D533d21c328E2eBc4994d468C90099fB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xCC8C4772575E4f55Dd8e63DF32962Cfdddd42B7F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6AD289996Fd8058C58A1843691F90fCBA9360908',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x93aFaBb905bc69Dccb244626AeB452f99307Ab86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3d7dcEC65e6bA91d339B984DB67f0E71E5e32C29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5dA7b2e9d4A8584646bBB03d9F54F84eD9Fa18fE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xdC71E56c86D8cDC66aA3c9aA315c7cCfB564Bef6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x53Af577749043A682c8397250F111C8E375E212c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x79f38e6506D85D05942526A214b4188bd9d160f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xD4730E3786025c9B203c4ddac0B1526676B7093F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x60511Ee15267DFF727Eba88B5C86e5e5d7060Ba0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc411e09D9787C1A4cB3c691176e5659eafcfa1D1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x909d9cb593EEb611F2C6270cc33dC11cf6D2502B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc540c43c123cA49F64f516DEa10E2A0550491b98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x51655E31c56DdC67F9afbC35860fF8797ddc576a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xbD255260436E0d0b2942e5BDc7ad0c7B98CEE4d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1d02049CfC74FA1b8cD3444C2Aa754aE80f435C0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xCb3473B401d90159E0d5e6f204c52a6b3Ad49380',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2500344038fAfBA8b003c3E64F41d4b1b06238C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xa1dd9d774a71CDE9B54Aa353c2a417deD4485E23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xE447481b91bAD4e968e6E7588ad8FECA1E312f6F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x8EB168d87d75cEF13434A5a332D6149F290FecF0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9548e1eF72797238D6C0DF5bdFA54E703327B051',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x72209B28715Ef1328fF9fFA7F1E53B3F9a685F6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5712f106A3261dfa5AEA1A8e104F78bd48B45CEC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x507cca3E94AFCB77640956De61Fa7A48C018a189',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xeBd3A85c56DAFaf0843103c4e0464F814D764b69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xa7176D756e57f4cDe70a8CcBb8A4E82b4C9CA3bB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 270.8890635658342,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.89,
  },
  {
    address: '0x1B99131391DbA73EB133E0903417703F01937AC5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 270.8890616003937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.89,
  },
  {
    address: '0xb907328F4A48AF7961d905DD194b790a8C6EC405',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 270.8890616003936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.89,
  },
  {
    address: '0x40aDfd76Ed120E1a76a18eb27BAc1771b9d572A7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 270.5792135194191,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.58,
  },
  {
    address: '0x2DB6ceFc56199372ba25fbCA87539A2AF0087483',
    minters_reward: 0,
    lp_providers_reward: 270.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.52,
  },
  {
    address: '0xe522E3B379839E1bd1fF0730a66A5cd7aa8f8090',
    minters_reward: 0,
    lp_providers_reward: 270.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.46,
  },
  {
    address: '0xb5f03558B566c14F08CF34EF94F933DF5d99331E',
    minters_reward: 0,
    lp_providers_reward: 137.61,
    swapers_reward: 132.665399326356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.28,
  },
  {
    address: '0xE96a1eebeA027e3847C65f211B6b8110C11D7b93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 269.6731083013908,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 269.67,
  },
  {
    address: '0x320b6EF3F24825Fa9e7f67207c15e68D3107F95e',
    minters_reward: 0,
    lp_providers_reward: 269.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 269.33,
  },
  {
    address: '0xC814da727A5503260d651a2b0d001181B35E043f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 269.1596724260808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 269.16,
  },
  {
    address: '0x09c1B97F01Ab7b10B0c8BFC1e37ea8fadee5b138',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 268.716255610009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 268.72,
  },
  {
    address: '0x443ceb9053e4d6ceb165Bb1AC19d9af8A1fc4E97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 268.4415637051001,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 268.44,
  },
  {
    address: '0x2815d444A80eaD8C689B7e82A0F6F30F41e11b4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.78833434642354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.79,
  },
  {
    address: '0x1Ed110a638AdA082EC18aCf0467Ee467B52ed815',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.7147858907938,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.71,
  },
  {
    address: '0x1b0dc9788702d2904D2B0Fa755e53BEb97b5579B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.70910701834214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.71,
  },
  {
    address: '0x9c71BbfAB09391b04E64e60fC6642D0D829583DD',
    minters_reward: 0,
    lp_providers_reward: 224.13,
    swapers_reward: 43.562325342730496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.69,
  },
  {
    address: '0xfB142F1d22502FA6f0f5A10A33ec2c54097B163c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.66421264921735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.66,
  },
  {
    address: '0x6767818b37dF5Ff1b486f696eeDD086a5CB51c7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.0547820301958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.05,
  },
  {
    address: '0xf35163d7E95cFaA1288dc35d59FE8fD9A1C4bA39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.0399966627162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.04,
  },
  {
    address: '0x2c0bB594C1A676046Ca091a177FfD2d0c6b200b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 266.1970391828634,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 266.2,
  },
  {
    address: '0xB93962C70d5C4738FF32D4378Ed7a32cfF1199cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 266.0433830949334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 266.04,
  },
  {
    address: '0x084Fe17a1FE64D42431A30ce67f0D6dCD670e97A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 265.6072483090635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 265.61,
  },
  {
    address: '0xfdd1481Db6D28EabB98Eb008Ea4d6297FF3473E5',
    minters_reward: 0,
    lp_providers_reward: 143.44,
    swapers_reward: 121.87143855713593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 265.31,
  },
  {
    address: '0x0cc1366666586AfF380C36A7fA36a0AB543e8112',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 263.8190829052552,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 263.82,
  },
  {
    address: '0x987f74b2e1820A4c543c892C1ac5fB7eD0aCE5cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 263.53640829606815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 263.54,
  },
  {
    address: '0x3a6D77a176591d0e272f36daB3cBd295a4a3bFb7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 263.2721412715483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 263.27,
  },
  {
    address: '0x670Bb2fA1231550FCcaec308E6ED03B98D2f90FA',
    minters_reward: 0,
    lp_providers_reward: 177.72,
    swapers_reward: 85.27962445687716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 263,
  },
  {
    address: '0xC8146C07C81A25A07D0cAAa5fdda41E4c1bBe97C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 262.375421310187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 262.38,
  },
  {
    address: '0x75CeEbb0bd4a85596d06a2e134a0F3117629C13E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 262.1462112850352,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 262.15,
  },
  {
    address: '0x73C825FCA9B682b6A289b49fD0A15bCa364fDf21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 261.86378903528794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 261.86,
  },
  {
    address: '0x0A12e3e57406A0160d6109FE9Bcc4851C9162601',
    minters_reward: 0,
    lp_providers_reward: 154.23,
    swapers_reward: 107.43001190702002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 261.66,
  },
  {
    address: '0xdAFB73fEA6AcAD9820806A02bAC39e284fd6f2Ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 260.0754403389725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 260.08,
  },
  {
    address: '0x3E332910FF4cBf78A22FFD8FD24D8401dBC45033',
    minters_reward: 0,
    lp_providers_reward: 114.65,
    swapers_reward: 145.25023345425015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 259.9,
  },
  {
    address: '0x96dCD03687025B43c49Af9AE7EdF2678f3798A8F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 259.0687623611106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 259.07,
  },
  {
    address: '0x73Bc39121e1aDc48A3085D0347823a9052d506c8',
    minters_reward: 0,
    lp_providers_reward: 258.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.76,
  },
  {
    address: '0xcE9bc50255dbA933E8b9BfC5cB9DC174e2c67E4B',
    minters_reward: 0,
    lp_providers_reward: 258.74,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.74,
  },
  {
    address: '0x8ce2185f2e694dE58AB54d8aDDa9a9FA727cA122',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 258.52687492843916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.53,
  },
  {
    address: '0xfB4CecED5B403Be91A3b6cfd51783F6A5c0bb98b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 258.42809792908736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.43,
  },
  {
    address: '0x350fE458119CFB86e2932480f52FA04163B31481',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 258.4016861002465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.4,
  },
  {
    address: '0x745C38cd894F613756ff081F3c3C66C2c92dC905',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 258.0001099149458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258,
  },
  {
    address: '0x01D916526296adAa85F31b097d1B9A91bE90532F',
    minters_reward: 0,
    lp_providers_reward: 85.53,
    swapers_reward: 172.40060499006162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 257.93,
  },
  {
    address: '0x08c225E8De3403573aB9784f4F5e6207e8518123',
    minters_reward: 0,
    lp_providers_reward: 257.55,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 257.55,
  },
  {
    address: '0xB371F060EE392939C0ACEdBa81c85dF7E8dda6B4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 256.7888089182653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 256.79,
  },
  {
    address: '0x6DEEb0E31CdDCFB062Cf8F202a624177CC7A5D5E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 256.2403404584047,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 256.24,
  },
  {
    address: '0xf7de9bc54951dA3D1FA0CD34D70f02D86175d142',
    minters_reward: 0,
    lp_providers_reward: 182.81,
    swapers_reward: 72.9604136384389,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.77,
  },
  {
    address: '0x5DaadD995EE0009Abdba40183E936a13A30795c9',
    minters_reward: 0,
    lp_providers_reward: 255.57,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.57,
  },
  {
    address: '0x7cB3068c7CC54d29b290bDd1A4A2189083A07626',
    minters_reward: 0,
    lp_providers_reward: 207.44,
    swapers_reward: 47.87324344885746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.31,
  },
  {
    address: '0x10985aBB97fee7bE33c13bb62644B1A4c9C9AEcf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 255.05131512528195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.05,
  },
  {
    address: '0x19aca5cA56627dC5FC3C962E2D056A0672E52F5B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 255.0219621177195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.02,
  },
  {
    address: '0xe0de5624517d7eF9909892D4dfA50e77fDfe9310',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 254.3897311304305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 254.39,
  },
  {
    address: '0xa7850DF2207d063EF8AEbcd3fDfBF3277B6370bc',
    minters_reward: 0,
    lp_providers_reward: 192.93,
    swapers_reward: 60.75076707102358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 253.68,
  },
  {
    address: '0x60F431728dE4B39143C71E9cbf8BDc3c17af4A60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 253.67484526831063,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 253.67,
  },
  {
    address: '0xE5b8c7d87252Bb5cA372e9913a82392dac1fda5A',
    minters_reward: 0,
    lp_providers_reward: 219.16,
    swapers_reward: 34.13935587030642,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 253.3,
  },
  {
    address: '0x6d1e980C11F625E2E70EA168a8b8546db49248D4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 253.10211299671695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 253.1,
  },
  {
    address: '0x6c2F7744EFb00A741c3cf48f15b5Df78d08bDf5A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 252.92035078748998,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 252.92,
  },
  {
    address: '0x6CAB467f51bC59e7dA04Fa0d4d38dEe692Bb0862',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 252.76267053824836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 252.76,
  },
  {
    address: '0xbc29D432454B674EFF5270DC31FCdB335B3888f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 252.36024916151425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 252.36,
  },
  {
    address: '0x41dd121AFFdEc12041e4b706A3dAC4dD3EcfE35b',
    minters_reward: 0,
    lp_providers_reward: 251.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 251.46,
  },
  {
    address: '0xd98F762b0F9934215d996A3feCe61acA3A2dA0cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.8783315712165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 250.88,
  },
  {
    address: '0x6Cadbeb02DC796c6CF61162F3a387385e1D2d321',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.4712997270279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 250.47,
  },
  {
    address: '0x4DB23dBECDfDfc26a5c0377aaB8ceDAec0635864',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.21313419539007,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 250.21,
  },
  {
    address: '0x5664C36bb6715a0fbd76f56890c3dA9EdfFA2025',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.12813313701622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 250.13,
  },
  {
    address: '0xB2cdAf6A7eF976125a206476ba611b9b6032BAeD',
    minters_reward: 0,
    lp_providers_reward: 249.77,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 249.77,
  },
  {
    address: '0xc452612e9201bB7402199a86215F4d0E3Cf05121',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 249.07328152079683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 249.07,
  },
  {
    address: '0x4D1B657dD4Cb41e0791EE30728C27b80B37FF3d3',
    minters_reward: 0,
    lp_providers_reward: 122.41,
    swapers_reward: 126.5669232533463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 248.98,
  },
  {
    address: '0xEED4AeD0ae6d86D467e66744E94B5503f69Ed8A4',
    minters_reward: 0,
    lp_providers_reward: 248.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 248.52,
  },
  {
    address: '0x78bB49929bDCEA744AB337d808FE13F734c63278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 248.32330895245633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 248.32,
  },
  {
    address: '0x60E8fBd2e5DF4A7C7A1178Dc9a49BE21CFe27d85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 247.51373271277765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 247.51,
  },
  {
    address: '0x4Bc07270bC4BFE67104d65aB75D0A1Af02B46a70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 246.99204941843877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 246.99,
  },
  {
    address: '0x4136a4E102c25f6225DA8288B23dbd52308981aC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 246.56454749852142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 246.56,
  },
  {
    address: '0xAf162bF5C9A8413A8628e630f961273D7f3270aB',
    minters_reward: 0,
    lp_providers_reward: 69.31,
    swapers_reward: 177.2226017275832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 246.53,
  },
  {
    address: '0x893C049d6Ab920d60B1838be662b3ed8b1510878',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 246.24467341799064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 246.24,
  },
  {
    address: '0x040CEd25A4f5A2D24A8B031d0cde9485F082f5a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 245.28151371341707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 245.28,
  },
  {
    address: '0xcF41E98b455010A43388D5082e2AA49280a7cA98',
    minters_reward: 0,
    lp_providers_reward: 243.61,
    swapers_reward: 1.422794522728178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 245.03,
  },
  {
    address: '0x98305ba0Defb14Ea0c15dE2e9677203Ac7833CA4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 244.9447189492306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 244.94,
  },
  {
    address: '0xD8D57a592147164d700D9745bCDdF80293E261BF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 244.74141030866227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 244.74,
  },
  {
    address: '0x85A183B2e67be0CFfD609a352c62c701e3DEe4Db',
    minters_reward: 0,
    lp_providers_reward: 51.89,
    swapers_reward: 192.83883373306298,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 244.73,
  },
  {
    address: '0x42c2c7dc0121091250B6AEBb08d81e88590431dC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 244.5389720404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 244.54,
  },
  {
    address: '0x2fEf00eAfB8AE0ef81CB3028f25Db56d8B8153aE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 243.63581953843416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 243.64,
  },
  {
    address: '0x6c024A7f5919b78144823a2aaC5aeE184e1F8Bb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 243.2794608494225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 243.28,
  },
  {
    address: '0x71ca5D5aD11107FbAaDf1c394C3CFF8e2EdF1e0E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 243.24571143123126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 243.25,
  },
  {
    address: '0x3Ad1ed2A65efE652B5499E03CcFadC72B16Ff517',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 242.0436676988986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 242.04,
  },
  {
    address: '0xa458ADc94ADC7953F176cf52005D1654fc3b9876',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 241.7583754773678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 241.76,
  },
  {
    address: '0xBF12dB7DB9658F88Ea5deD2C9A6EcCd12b707Faf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 241.180202680458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 241.18,
  },
  {
    address: '0x4608e0f70Ae80E4a85b83cD47477fc5a0C606BF5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 240.8079737419648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 240.81,
  },
  {
    address: '0x16B2E0235DDd6392C89eca4F46A49ef31c45c5e6',
    minters_reward: 0,
    lp_providers_reward: 219.19,
    swapers_reward: 21.445667616816436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 240.64,
  },
  {
    address: '0x18E368d3F9DE886753381E8b74C29cE2E90B82cC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 240.52340972059383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 240.52,
  },
  {
    address: '0x4055541AA65922A6E0c6D7B38d1775CB7B7FD18f',
    minters_reward: 0,
    lp_providers_reward: 171.57,
    swapers_reward: 68.58730927559266,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 240.16,
  },
  {
    address: '0x747A7659Ff76c9c8B980198f545234e5Fa499300',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 235.81249019839163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 235.81,
  },
  {
    address: '0xd986067a1CFAF0c7f56153E35F551e2D968011Ee',
    minters_reward: 0,
    lp_providers_reward: 169.33,
    swapers_reward: 66.42135847158731,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 235.75,
  },
  {
    address: '0x36f76616B07FfC1188075Dcb65D53E9cdC54FBdb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 235.4378660477521,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 235.44,
  },
  {
    address: '0xA5b8a0365213FDA3b77A7B9d7FD95dBa44e36395',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 233.9942503529545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.99,
  },
  {
    address: '0x40A265ac676A459c98469251C59B695EBD9E3158',
    minters_reward: 0,
    lp_providers_reward: 233.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.45,
  },
  {
    address: '0x99070d47fCE8A9682b8A598f00ba3753269A7789',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 233.32860312512312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.33,
  },
  {
    address: '0x49E997265Ac1F6319C8D28601FEd6a4233e68d01',
    minters_reward: 0,
    lp_providers_reward: 218.74,
    swapers_reward: 14.541643245649555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.28,
  },
  {
    address: '0x05Ad154674369599021EA6D9ED5469760902aDe8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 233.13698394108974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.14,
  },
  {
    address: '0x81eAc02E7D4bA5F3217c6F9Da2eA8b858e5B394E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 233.1306234400293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.13,
  },
  {
    address: '0x5b6dDaCd4364BcC78Beb89244e45caDA97f261AD',
    minters_reward: 0,
    lp_providers_reward: 227.67,
    swapers_reward: 4.65301951076334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 232.32,
  },
  {
    address: '0xf74Fbd09a7ae9d2A00D3396519e92D4b125859C0',
    minters_reward: 0,
    lp_providers_reward: 139.79,
    swapers_reward: 92.07535714193277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 231.87,
  },
  {
    address: '0x3EeD652f4e8851684611565C35096d3F8978B3A3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 231.48996006334366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 231.49,
  },
  {
    address: '0xC634eEBDC530345Df8a9642aB2786072922c5AD7',
    minters_reward: 0,
    lp_providers_reward: 177.37,
    swapers_reward: 53.76531262276701,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 231.14,
  },
  {
    address: '0x4F1439549970D88751268c28B141267C528c03Ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 231.01013068401994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 231.01,
  },
  {
    address: '0x45d90F575770A4Ce59E34BD1B8e102B5dFCC483f',
    minters_reward: 0,
    lp_providers_reward: 133.4,
    swapers_reward: 97.58322573765416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 230.98,
  },
  {
    address: '0xFC346d5bBfca224C256A64C72C6cdAACc32d5bc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 230.43711780823082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 230.44,
  },
  {
    address: '0xfd566A7f718a43CBA9E1eBC0e9497F58f9191807',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 230.13188469003438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 230.13,
  },
  {
    address: '0x8ee8826aCA04Af9832081a627eDFa05AF4F5A373',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 229.78029562696543,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 229.78,
  },
  {
    address: '0x51933b68c855573dFf6B1Ab8895ce95aB45E3f30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 229.57834324689986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 229.58,
  },
  {
    address: '0x7016C87E714fa8F4EfB4f7F5Eb4ceDA0Bd0945a5',
    minters_reward: 0,
    lp_providers_reward: 213.8,
    swapers_reward: 14.910140386267779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 228.71,
  },
  {
    address: '0xce394f85BDd16EbE1422033A845C40b4aB3BCDF4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 228.3342669891561,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 228.33,
  },
  {
    address: '0x34e9691c0Ea9870AbB0153F8A8CD95f5fE930832',
    minters_reward: 0,
    lp_providers_reward: 164.22,
    swapers_reward: 64.05871420308125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 228.28,
  },
  {
    address: '0x46a44660d4099dE2aef0070F3B1D1f661ed3d05A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 228.22232452401704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 228.22,
  },
  {
    address: '0x02Cd3a738E628736b3e1FE912f226573Cbb2A48a',
    minters_reward: 0,
    lp_providers_reward: 142.35,
    swapers_reward: 85.62253777445042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 227.97,
  },
  {
    address: '0x7748c62dB0a5F5D66FF65d7D9dd3098e6647aDA6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 227.60476514805262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 227.6,
  },
  {
    address: '0x1F926D9218731ed9A5162016F374b664B2e2a2f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 227.3319404006827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 227.33,
  },
  {
    address: '0x03a9486d44B48F135bE4aD98aA27Fe20f18a8DE6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 226.7070630715436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.71,
  },
  {
    address: '0x8793e80e0a14fF34f4Ba908656e40FEEb713eB85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 226.54718615554194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.55,
  },
  {
    address: '0x08CbA700Bd246eaD2EA3F039180bBbCDBB376839',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 226.43526251330402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.44,
  },
  {
    address: '0xF508d42dfA00369887910e3Dd0879D53dD5257Bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 226.16794445552483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.17,
  },
  {
    address: '0x9aF1d3DEd9c4a2dDBa23aa16Aaf8d229E508610E',
    minters_reward: 0,
    lp_providers_reward: 62.72,
    swapers_reward: 163.28870224673537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.01,
  },
  {
    address: '0x1769a619abCE8Bd107ed8Dd4752D41366426e35D',
    minters_reward: 0,
    lp_providers_reward: 225.85,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 225.85,
  },
  {
    address: '0xe322cD0397EFaf736dD2CF94Da09c58231a7E5aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 225.51651473547395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 225.52,
  },
  {
    address: '0x518324bBAb3385f985c12a2cf9FFDDF7A9717555',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 224.44303131087244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 224.44,
  },
  {
    address: '0xa149AAa4d5065288a2Af6f4c2717A3351621cA47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 224.38501411699283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 224.39,
  },
  {
    address: '0xB1976EF2d411cE816d9402db8Fce8BDd0Cea0C02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 223.9347641938627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 223.93,
  },
  {
    address: '0x99620E6F7D0bbbFA8B324e9Ec8E618df9E2A3297',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 223.60129356016856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 223.6,
  },
  {
    address: '0xBcBDaFef250b042Cc3E3f6821F02fd31A25dBbFF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 222.97717215035522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 222.98,
  },
  {
    address: '0x648f73b09DDEA378D4Ad859B939A346adb35A566',
    minters_reward: 0,
    lp_providers_reward: 69.08,
    swapers_reward: 153.50059178628322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 222.58,
  },
  {
    address: '0xcb7adf7a4FfCCd8Bdc407F43606aBf6C8624E9D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 222.43316411458804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 222.43,
  },
  {
    address: '0x2FC2a37f4f75Fb1e8C220292C1a1a669e6E3d6d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 221.61276527793484,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 221.61,
  },
  {
    address: '0xEbfB5eBfe94B1e1Dd0FA4f60124B846754b9d3A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 221.24557359035182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 221.25,
  },
  {
    address: '0xfDb5C2e0f6A94BD79b2Bd7E60bC404ba7Caa2c82',
    minters_reward: 0,
    lp_providers_reward: 221.13,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 221.13,
  },
  {
    address: '0x18DaceB616D48681c94b58eC78bdb5DbBAD581e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 221.0637103307033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 221.06,
  },
  {
    address: '0xf48bE36275f0eCa5f2Da8B3947b942c3e6E2E52c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 220.7762948782854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 220.78,
  },
  {
    address: '0xE44CB5De3F501b4754fB093BB4a9f30e20E582aF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 220.4001697347411,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 220.4,
  },
  {
    address: '0xaFED63a5A031a82ae7DDb1907656E71e18cf63c6',
    minters_reward: 0,
    lp_providers_reward: 140.52,
    swapers_reward: 79.34048487329717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 219.86,
  },
  {
    address: '0x0FC61Cf9b083cfDDD46daD81c6A854Ae4AE3e600',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 219.54604211064165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 219.55,
  },
  {
    address: '0xe7d4719ab0abA0c65F235fd70c494F5D0a8BEa1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 219.1431619768109,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 219.14,
  },
  {
    address: '0x5F70b53F43160CF39c76890488FEBac5eE33Bee7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 219.0550307531417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 219.06,
  },
  {
    address: '0x6D7e8F61c7eE5d8cF31b8BE4cA05A14daCEfDe9F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 218.64201014751762,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 218.64,
  },
  {
    address: '0x576fA4818714b0414B035FEF0075d57C2391B2a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 218.53717669210943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 218.54,
  },
  {
    address: '0xE4c90Bd22E454f854e950fbcC565A513AcF0C096',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 218.5145100643292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 218.51,
  },
  {
    address: '0x174cF68D6549dC681065AB554D4dEeB1376D1523',
    minters_reward: 0,
    lp_providers_reward: 22.6,
    swapers_reward: 195.65805587432175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 218.26,
  },
  {
    address: '0x8087b6B9c24a040f51570F08F1DE92f6c9CaEF39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 217.25797568500832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 217.26,
  },
  {
    address: '0x584a9Dd5bd53cFB9dc01b65bF7b684d2727140aD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 216.86173423643677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 216.86,
  },
  {
    address: '0x8d0247ab8e65384Ba25E879500678799948E6d08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 216.24450659224945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 216.24,
  },
  {
    address: '0xBC0B29Ce067921D84a790FA4fb8f1D7a55392067',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 216.06410194989098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 216.06,
  },
  {
    address: '0x8BC03736c78D9Cf8EEe4efA9f197F9ff5Ad331b3',
    minters_reward: 0,
    lp_providers_reward: 215.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 215.68,
  },
  {
    address: '0x7E7D42B3aE3F8B8fAcDa72bB1e642Cac158ebfCE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 215.01735051583552,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 215.02,
  },
  {
    address: '0xFF7Ef885D761d05233045086a17eE2e4f37c134d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 214.15621019430844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 214.16,
  },
  {
    address: '0x294d59F2e809FfC7b8b1f4aEB3817D50891A15a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 214.00783603272825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 214.01,
  },
  {
    address: '0xF90f56AFa1DaE825Db57fc305B2F8F1Ccf337531',
    minters_reward: 0,
    lp_providers_reward: 67.21,
    swapers_reward: 146.3982207853902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 213.61,
  },
  {
    address: '0x90ebb05fE594C651aEFC8BD111De2704E4D69249',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 213.5109649742091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 213.51,
  },
  {
    address: '0x6d3a70b6afCC365EDe63B2A50FffD57BBdF047C6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 213.3567089198246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 213.36,
  },
  {
    address: '0xecea48244E01747F4036B021243FC6D9AFD40eda',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 212.56755274676522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 212.57,
  },
  {
    address: '0xecD748402a8B2728B15Ce9BD4701e871B3D63A00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 212.54065385491052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 212.54,
  },
  {
    address: '0x3C71a16CDD6642145487847976B6E1E952220ffa',
    minters_reward: 0,
    lp_providers_reward: 119.79,
    swapers_reward: 92.67081484978159,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 212.46,
  },
  {
    address: '0xB7BF92a77b5032F216dfbd0D9D0461b79535C6bC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 211.27318814361055,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 211.27,
  },
  {
    address: '0xf3C7dc4c8f3150d77b766991f3Ae5B855Ee5dBF6',
    minters_reward: 0,
    lp_providers_reward: 150.1,
    swapers_reward: 61.02039924794418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 211.12,
  },
  {
    address: '0xFd44555955432858778aF4C8ccEC80c2E0a63278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 210.75634053230053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 210.76,
  },
  {
    address: '0x85f67CF442768B56C0FFf44F5367C06A60BbbF39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 210.63658354617291,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 210.64,
  },
  {
    address: '0xB4f82349532FA1C3E408D4691122d5860f434549',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 210.14871160793456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 210.15,
  },
  {
    address: '0x89D963698Dec3dBFC094B57ed00e909E603dBE7F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 209.66039887635537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 209.66,
  },
  {
    address: '0xfeb23cBBE5d83E906768e890eeB1C16431a70C00',
    minters_reward: 0,
    lp_providers_reward: 105.24,
    swapers_reward: 102.93047521707483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 208.17,
  },
  {
    address: '0xB29a6B2e18f663aA6DCDB2D05A26D299e2F00E54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 207.91092601552174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 207.91,
  },
  {
    address: '0x44f9Ae13CeE696468f5fbd8c9E1bCCc253C99c72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 207.50756377435386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 207.51,
  },
  {
    address: '0x796142461c6a538f58C76Ebb2855d4E7fB896C84',
    minters_reward: 0,
    lp_providers_reward: 200.03,
    swapers_reward: 7.084970635707407,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 207.11,
  },
  {
    address: '0xF183946296cb00ED45233B101c3E1d5DC321dAa6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 207.07878788315378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 207.08,
  },
  {
    address: '0x35831dD1B909058c06d1A81d652bAE40C10F70Df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 206.60818891618334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 206.61,
  },
  {
    address: '0xAFdA904638150600c714adf5C55d688d6BAeA929',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 206.14934322823734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 206.15,
  },
  {
    address: '0xF4E85d0D08aEB968c096dB6ea226B06880a8635D',
    minters_reward: 0,
    lp_providers_reward: 200.24,
    swapers_reward: 5.825861860329114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 206.07,
  },
  {
    address: '0x4EC544C1114d5eA6f316a5B0d1174718D6F8C7bE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 205.51378022901798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 205.51,
  },
  {
    address: '0x2a8538A2335eA7448b615007D0E9c6b8FD79Eb96',
    minters_reward: 0,
    lp_providers_reward: 105.89,
    swapers_reward: 99.2514046378521,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 205.14,
  },
  {
    address: '0xe9dE1027c793B566A9a11Ae4297EFde47fe0D3f7',
    minters_reward: 0,
    lp_providers_reward: 204.41,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.41,
  },
  {
    address: '0x03e50a3D1EEDFB7A32D480730BC1844241C179F3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 204.35504573000077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.36,
  },
  {
    address: '0xD1CB18B48F8Ffc3D6AE454dB575f52897545BCE0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 204.23183273288694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.23,
  },
  {
    address: '0x69168d4233808C7a2c2b87001ae097a63163B642',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 204.22285240619257,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.22,
  },
  {
    address: '0x7CEe7755ABBD4D1eD71Ca47a4d313f8B2a760D02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 204.0477125262256,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.05,
  },
  {
    address: '0xdF0566b8649997095Df6375AD42bB37f532F7e09',
    minters_reward: 0,
    lp_providers_reward: 130.93,
    swapers_reward: 72.60075995208423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 203.53,
  },
  {
    address: '0xeca8191B8A0e8953D56D28c784b7a8309bC35ADe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 203.0993628341597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 203.1,
  },
  {
    address: '0x7f6D4Ac53A0d9D25Ff5AB451265FbEb81dE0A8F6',
    minters_reward: 0,
    lp_providers_reward: 108.24,
    swapers_reward: 94.6994444190449,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.94,
  },
  {
    address: '0x2b3B4Ffdc51872F58313dEcfF390604A58DA65E1',
    minters_reward: 0,
    lp_providers_reward: 202.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.9,
  },
  {
    address: '0xef260091a4b62D0e7B3026614efB335838F9a997',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 202.4547622252486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.45,
  },
  {
    address: '0x9d9C5DEEB58d02A60350cd8A97cb9D91d6D60AAf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 202.42458228882833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.42,
  },
  {
    address: '0x0d02E5E6448fB2990981d3EF8EEF0100F57B019E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 202.1908119711062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.19,
  },
  {
    address: '0x2FaCe1ec0F39C7d3628620eB58C33A6F11069b14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 201.67759202350382,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 201.68,
  },
  {
    address: '0x7CB24A92Eb6BbBb69eb55A4705A9dE427841E8dF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 201.59174627158308,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 201.59,
  },
  {
    address: '0xac60d837E6137cE79B1B8daF73cb780d797b6638',
    minters_reward: 0,
    lp_providers_reward: 53.12,
    swapers_reward: 147.55024288257601,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 200.67,
  },
  {
    address: '0xe51b8bF2948d52c6Cc0f385028ae92bAff2e9a4B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 200.6250822828026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 200.63,
  },
  {
    address: '0xDEbb2871D459C9A052BE3742fd126B79B1D102a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 200.3354788736246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 200.34,
  },
  {
    address: '0x8551212E4849AfAa0F9590C6666bDeab91c863EF',
    minters_reward: 0,
    lp_providers_reward: 99.88,
    swapers_reward: 100.41387194006401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 200.29,
  },
  {
    address: '0x739d646C7F31e9698D348B6f63490D9E09cb8D60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 199.72846895229034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.73,
  },
  {
    address: '0x17855737a5036A9e84007aFf94e30c2cc5839621',
    minters_reward: 0,
    lp_providers_reward: 196.69,
    swapers_reward: 2.893630665167994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.58,
  },
  {
    address: '0x179d8379c1D6fC2f23c5d8e3005E797F5D90C2b1',
    minters_reward: 0,
    lp_providers_reward: 186.3,
    swapers_reward: 13.067265208136243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.37,
  },
  {
    address: '0x80E896512c3B6507b7704B02c7441758caEd87c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 199.3433167401179,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.34,
  },
  {
    address: '0x165E2114D488496C916b289823Ae64f10e473B09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 199.24604975668203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.25,
  },
  {
    address: '0x16019a4fDDe1856c6aFc4f4A71c9665F2cCf825D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 198.94128854300365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.94,
  },
  {
    address: '0x9Afbc1C8922396eB9EB328CA51186ca603b7b41B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 198.87440554856872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.87,
  },
  {
    address: '0x92C94Cb1c9bcf5E7c0EB42B8123A96c1108eD71d',
    minters_reward: 0,
    lp_providers_reward: 198.77,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.77,
  },
  {
    address: '0xe3e9bb45d3dAD20fd305dd6C299973d90738459c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 198.20499784233454,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.2,
  },
  {
    address: '0x1b96354C37Df67dB8F58852A64c2fc27A5797679',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 198.0473075375614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.05,
  },
  {
    address: '0x419cA45A734D1db6288e5A9A31663B51Ef851eCE',
    minters_reward: 0,
    lp_providers_reward: 194.9,
    swapers_reward: 3.090268346056633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 197.99,
  },
  {
    address: '0xd3Cfb037c8D196aB7763d1605d28c8bE0cE6280C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 197.12543835531977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 197.13,
  },
  {
    address: '0xFCF750d7a9201A0979694033A96865E44f963D0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 196.76263257327878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.76,
  },
  {
    address: '0xECBBADab24F861aa4F6fCA8Fafb9c3CC74280F5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 196.48255504921164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.48,
  },
  {
    address: '0xca042E76f58D75Dca399E2514fC791F5D6546508',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 196.4095949015468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.41,
  },
  {
    address: '0x72810529c30Fd63Ec681E9D54d35c8c203A81E2D',
    minters_reward: 0,
    lp_providers_reward: 123.05,
    swapers_reward: 73.32277456518781,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.37,
  },
  {
    address: '0x435d3C3EFA623B79aA2282B265dd7ffcdfAa7791',
    minters_reward: 0,
    lp_providers_reward: 71.48,
    swapers_reward: 124.85190443833078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.33,
  },
  {
    address: '0x04CDe7FB3B21898eF71fc1b4Ea2a7A0542944Bd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 196.20451311157714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.2,
  },
  {
    address: '0xFbB63B2AC72421238e1ad625Ddd1933689fe1E20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 195.98224625942424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 195.98,
  },
  {
    address: '0xA6C2A89944a81eb26BFCCA57F99cE4B8ec0c9e7B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 194.91155913679665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.91,
  },
  {
    address: '0xe09B97EF6A3360793E9217478a15Cc5CB6eF9955',
    minters_reward: 0,
    lp_providers_reward: 181.9,
    swapers_reward: 12.90477573319812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.8,
  },
  {
    address: '0x8A116A4AA75b999A9695963f9C57E40C5C97b72b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 194.77845850776808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.78,
  },
  {
    address: '0xd91aA1140154D9dB7674697F94ece9C9845f1c6C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 194.57687009390511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.58,
  },
  {
    address: '0xA10B43767FF8f797290727e5eAAd60F794173eb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 194.27450833850185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.27,
  },
  {
    address: '0xB9AbD5980a3aE88d088E3b43bF1006C5d8C835d5',
    minters_reward: 0,
    lp_providers_reward: 193.78,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 193.78,
  },
  {
    address: '0x050931785b6F2D5c6143D05E63AfF7623d92C180',
    minters_reward: 0,
    lp_providers_reward: 168.99,
    swapers_reward: 24.166539089656204,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 193.16,
  },
  {
    address: '0x941477Af54159770Dcf2493EB10731603266a9C0',
    minters_reward: 0,
    lp_providers_reward: 183.3,
    swapers_reward: 9.840734089256891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 193.14,
  },
  {
    address: '0x78b788f7b1D5f652ED336980c4E0A45C1531Fa32',
    minters_reward: 0,
    lp_providers_reward: 7.65,
    swapers_reward: 185.37954323350186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 193.03,
  },
  {
    address: '0x66882bc2e33ae15780EFdef9D880286e9560362a',
    minters_reward: 0,
    lp_providers_reward: 43.17,
    swapers_reward: 149.74407478472057,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 192.91,
  },
  {
    address: '0x177ebeBB0a642C087cf6f992650bBd3216489B01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 192.64069594767645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 192.64,
  },
  {
    address: '0xB90f5BbDBbaCC9080aA9CC8AEB95F2f047431BdB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 191.39077968734472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 191.39,
  },
  {
    address: '0x9478c744401bb8B130d542257dCf632930883b1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 191.09055301623465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 191.09,
  },
  {
    address: '0x775ba05C6E4b1A08818dF3BC3C2BDBD056198846',
    minters_reward: 0,
    lp_providers_reward: 190.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 190.93,
  },
  {
    address: '0x8F33acCD15FFAc27F049eD0e78b2F41f8eE5ddb1',
    minters_reward: 0,
    lp_providers_reward: 79.97,
    swapers_reward: 110.55546279895248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 190.53,
  },
  {
    address: '0xa913C0e4F21a83a6Fe698f25c0C20A7A871c3b15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 190.48921388224488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 190.49,
  },
  {
    address: '0x4E2C6e6B307bE9c8536DE4eC2d6e137c7cd71af8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 189.91858317811628,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.92,
  },
  {
    address: '0x22878c6563059DD72A7c17d761aBe3329A66cad8',
    minters_reward: 0,
    lp_providers_reward: 189.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.52,
  },
  {
    address: '0xF24291e6D7e9b91bd9D83fe288D6E009d8906671',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 189.37156959431462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.37,
  },
  {
    address: '0xD1D2F6dD89c3B869Cfd600b838287c3d26f5Ff62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 189.2299174364587,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.23,
  },
  {
    address: '0x194446a1358BcB4fd8AD46FFb9dcAA62E4a4462B',
    minters_reward: 0,
    lp_providers_reward: 79.53,
    swapers_reward: 109.66205487493303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.19,
  },
  {
    address: '0x31ce0717fC2b9A4Cfc33d6451e3aD4FBf4849608',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 186.94198584941353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.94,
  },
  {
    address: '0xb6b7B63ae53c41bdDE3704AE028A13D4BBADdC07',
    minters_reward: 0,
    lp_providers_reward: 186.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.59,
  },
  {
    address: '0xD4444e2bb3Da3896B9F5df4937725A114353D0f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 186.47213789037713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.47,
  },
  {
    address: '0x97aE37C64e6Ef216327cFd648E485EDD84d3abbD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 186.44572148594094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.45,
  },
  {
    address: '0x863A3039596eD84CBE21a9bB2A26aD411CA7Fe60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 186.26895314831847,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.27,
  },
  {
    address: '0x032749a665F5c74837BcAD5fCeD8Ab2c8a720D41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 185.7763301081045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 185.78,
  },
  {
    address: '0x621EBCCCB1206fDbAFCce3d1a2B7f691086c1658',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 185.7210668088387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 185.72,
  },
  {
    address: '0xb3Bdc2c5a7a7304151E2D1536daC2B20727C50f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 185.6679057591645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 185.67,
  },
  {
    address: '0xa687392aBC0A37efe9128341610e79fbc47F0F4E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 184.90043486802483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 184.9,
  },
  {
    address: '0x159ee973f9D76cA8AECb42a7b7bd5cc65A016329',
    minters_reward: 0,
    lp_providers_reward: 184.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 184.86,
  },
  {
    address: '0xfdA6D99eaED6c254c0c40EE27dd3692bf6Dc8992',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 184.73463897612567,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 184.73,
  },
  {
    address: '0xCaCCf474116f42f1706D78f790F623a07157D135',
    minters_reward: 0,
    lp_providers_reward: 184.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 184.49,
  },
  {
    address: '0x0A9Ba3910C0e772D370C7dbd3bC9bD8bE89c341b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 183.95798387069613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 183.96,
  },
  {
    address: '0xAD0d2e81018eB882B7A376bBD2bb993f7491aF2D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 183.53476472631198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 183.53,
  },
  {
    address: '0xA7a3F35a1be897AFdB6770D7dC16dFe71E951862',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 183.33595239954698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 183.34,
  },
  {
    address: '0x3f2A533314086c414BEc46498A7426977C92f5F6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 182.97042941616004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.97,
  },
  {
    address: '0x3d13a3CA8F5378F45cbce6BC85106cb4944562c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 182.88057173554748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.88,
  },
  {
    address: '0xfDeEedE672e05F662FA5979C283A5106e34e8A7E',
    minters_reward: 0,
    lp_providers_reward: 158.54,
    swapers_reward: 24.21549857040562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.76,
  },
  {
    address: '0x6d04c86482266a83Ea09668BD95bE70bE4ff48c4',
    minters_reward: 0,
    lp_providers_reward: 150.19,
    swapers_reward: 32.496856743382054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.69,
  },
  {
    address: '0x6F389Cdd00Fc17dee90101830Ae35F8b64f7494A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 182.31005252377605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.31,
  },
  {
    address: '0x269Cf3CE8D052184f20C2169305ebA9017B33667',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 182.20419219894612,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.2,
  },
  {
    address: '0x3b682532275006ed935f69ae3cf12FC57ea8cd9B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.66745803996275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.67,
  },
  {
    address: '0xf25c47cA3AEEC22A7e030a0d80AF29ae31A243C6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.65920651309446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.66,
  },
  {
    address: '0x2Ca8a723E3e435e035C1d426CBc37b1E92b7B7Eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.64344371726105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.64,
  },
  {
    address: '0xB5B9cB4B86bc12AF37a5D2F0746665701adf2004',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.6102148670665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.61,
  },
  {
    address: '0x365Eb86628BB36140F7A6d8691E96067C7222dEC',
    minters_reward: 0,
    lp_providers_reward: 117.46,
    swapers_reward: 63.993918481623055,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.45,
  },
  {
    address: '0x9E2a16AD7098b0aA23a07E49c42fe4987B6496e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.18830927216993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.19,
  },
  {
    address: '0x0c056Ca5Af238E04811A1F4838D476FF7BCeF00e',
    minters_reward: 0,
    lp_providers_reward: 180.79,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.79,
  },
  {
    address: '0xF97eb20765fFF93b1480730a7C7Ea75828e73ED4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 180.69565077444489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.7,
  },
  {
    address: '0x12BB07E3d2e1e97E3C7bfDa3aFBbDbd37202B337',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 180.69565077444489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.7,
  },
  {
    address: '0x510D47eD4C51975f77af2A086794Ef2B0D4B0EE7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 180.69565077444489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.7,
  },
  {
    address: '0xb7AF19f005009a6826f9afE8114f12F028A42458',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 180.396425706221,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.4,
  },
  {
    address: '0x079fF3E381D0D99B961E28Fb470C246c88ac4Ca0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.96209001127946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.96,
  },
  {
    address: '0xC3149dB635B8261ed5ae79C60712FF2F276550b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.63639413647968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.64,
  },
  {
    address: '0x00Bd1B07e63573CbFf617f860D0dE5AAE017dA99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.5102293834841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.51,
  },
  {
    address: '0x10630ca132AB6216Cb296872CE1947088157Af94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.36229401852435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.36,
  },
  {
    address: '0xBd3bf05625538c9DDD85982CeE5226784A698E63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.30651957017682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.31,
  },
  {
    address: '0xB726085d537926c67e35cf0E4dDE6405B898364F',
    minters_reward: 0,
    lp_providers_reward: 95.44,
    swapers_reward: 83.62611052373883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.07,
  },
  {
    address: '0xaB795d6295E420ac6AFF862a503761CB00da2447',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.02739596583345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.03,
  },
  {
    address: '0xD22811115b5107f033324971277e48312647Ba93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 178.85650377960107,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 178.86,
  },
  {
    address: '0xCc0F200282301BDe72d967640FA06bAbe6103D27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 178.67114459335042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 178.67,
  },
  {
    address: '0x3472fa1a1067Bce3c74dd66574b271742a1dF8eF',
    minters_reward: 0,
    lp_providers_reward: 86.54,
    swapers_reward: 91.35910431099863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 177.9,
  },
  {
    address: '0xbBd085033473568cb424969f7b28AE23bbAdf45B',
    minters_reward: 0,
    lp_providers_reward: 97.36,
    swapers_reward: 80.53959741450062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 177.9,
  },
  {
    address: '0x9FAe8d3C7cdad7aa4400d9b9e5f1C67F09B49126',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 177.67687428338786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 177.68,
  },
  {
    address: '0xa1E0a34aD57F6d2D34ae4Bf4B6fAf4692907786D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 177.3815949169303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 177.38,
  },
  {
    address: '0x00f5481262d4845b292Ba0c41752dE258b3bBEEC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 176.14020932006346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 176.14,
  },
  {
    address: '0xD9f50115BF7ee0DFC81E65145BB484bF3ad6DbF8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 175.89629867450392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.9,
  },
  {
    address: '0x17DAFA7C3f4BBBF51f133ADAd6E32eD073c7bd42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 175.8123643372317,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.81,
  },
  {
    address: '0xf8e36fAd98AB3edA56480445Af3F8A5b1e4444De',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 175.73221097447558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.73,
  },
  {
    address: '0x5416905249699380c7a37EBFB35924C7b22C6524',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 175.65085043845463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.65,
  },
  {
    address: '0xd37D3Bf75B112A31E4d1C3c30382ea5E9c572239',
    minters_reward: 0,
    lp_providers_reward: 175.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.1,
  },
  {
    address: '0x28d01ffC2628368903C86220C14aa18d69d8e467',
    minters_reward: 0,
    lp_providers_reward: 24.83,
    swapers_reward: 150.14394842375333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.97,
  },
  {
    address: '0x91eEE6DC3696e862a2E17DF79B621ccEcAde78fF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.8766782493457,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.88,
  },
  {
    address: '0xaC5CF01a7123b121b24F4e30AE7784980F95A11E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.86156823811166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.86,
  },
  {
    address: '0xe42c90B0167D2040de374D4c655307feE99a3Dc1',
    minters_reward: 0,
    lp_providers_reward: 57.66,
    swapers_reward: 117.08019320450563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.74,
  },
  {
    address: '0xDc0011e205585d8c5B1488bC580139e4398F08Bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.594657632647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.59,
  },
  {
    address: '0x090AF172a0c1575aF4222A49c3582a6356d29E63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.48345817887153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.48,
  },
  {
    address: '0x3b4F3fB912798571c4461eBaf9F97deCDD254BC4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.20052544042156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.2,
  },
  {
    address: '0x5c2be8e2D70C7608F8F24EA0d5661fD8831C2E99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 173.9467544509715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 173.95,
  },
  {
    address: '0xcA4aF533276282E14d938cd8B34186c12f2AB908',
    minters_reward: 0,
    lp_providers_reward: 173.35,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 173.35,
  },
  {
    address: '0xC418A29cfc0ec7Fc1c06E61fDafe49b318C43F4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 173.2167017684857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 173.22,
  },
  {
    address: '0xf46FfEa2203Cc1eab3b1d13768a04e1e24C7CA93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 173.1021221287029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 173.1,
  },
  {
    address: '0x09aB231d7164F32cC1c5C2f90229D1395Ad9147f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 172.94427345098993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 172.94,
  },
  {
    address: '0xD348f5dEf2281E3bDcF37708D13F11c3C7841f8D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 172.41198733266933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 172.41,
  },
  {
    address: '0x62381D7F95ad72fB07b29c5D2413431C3fB25A55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 172.02312901729394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 172.02,
  },
  {
    address: '0xFCD7B93A7b3F4f1276F1E89b63d22f1Ef4B4BF37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 171.98237743577855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 171.98,
  },
  {
    address: '0xE37FfC9451D525c9949465E722946dc4dB1C0662',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 171.74073467653184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 171.74,
  },
  {
    address: '0x84c93731Bbb106E5BF01fe93B8Ab47b4548ECfc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 171.6117550802202,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 171.61,
  },
  {
    address: '0xb883A61590A31dA2d2069d1C8d5450970758e5F7',
    minters_reward: 0,
    lp_providers_reward: 54.55,
    swapers_reward: 116.36730134613653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.92,
  },
  {
    address: '0xD490133e72DCe5a80e5Cfa151184ece73cd6F16A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.914616706833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.91,
  },
  {
    address: '0x66Ba0e376dF0f87b0fd1458354D41083046B1E1A',
    minters_reward: 0,
    lp_providers_reward: 170.75,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.75,
  },
  {
    address: '0x9200beb7858275bAf7231E08a912B17aaF2B0B93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.29970084805686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.3,
  },
  {
    address: '0x0DB3Bb7f2D8F539C3D60e63a0295d3fbF655A158',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.27126258584715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.27,
  },
  {
    address: '0x07F6c3aB4db889dCd5c268EE5Ab00717cAF95a61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.11941284020483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.12,
  },
  {
    address: '0xA1D80A9e42EC86E90bb732c2e1c62408BF1BCdFD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.03532058264636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.04,
  },
  {
    address: '0x9Dc8081da2012c6f1f66B01598769A46AA2C7ba6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.57247873733036,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.57,
  },
  {
    address: '0xAF906309E154FcbE7ff92B1ADA8bCcb8653cbb09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.55477717006426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.55,
  },
  {
    address: '0x12b3fEe3e3860A22BB2191a08c1170Bf066c77e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.43113115960483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.43,
  },
  {
    address: '0x8E0108Bb48aAaeEd86c0f0654E85090c9EBef638',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.19060432375724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.19,
  },
  {
    address: '0x29a82F3fA67867D287B472DaE09C91A54906416f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.15750641629165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.16,
  },
  {
    address: '0x38C213a1614012f6a6c394296c09606Fb61b6E1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.88792484281294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.89,
  },
  {
    address: '0x9db87f12423e5d2117710D4C9c8A698A84BefAB1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.88709212057717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.89,
  },
  {
    address: '0xEE91725EbC479102c89221aE0e36672423bCDb4A',
    minters_reward: 0,
    lp_providers_reward: 0.49,
    swapers_reward: 168.25466528047968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.74,
  },
  {
    address: '0xA529e69a778C2C648DF101a415eC85430566Ab57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.61299852970288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.61,
  },
  {
    address: '0xBeDCE4761bafF63d26fBf3Cc009e4eeC198c4558',
    minters_reward: 0,
    lp_providers_reward: 74.06,
    swapers_reward: 94.5125085417493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.57,
  },
  {
    address: '0x637cA4e60c85dfBE9e3Fc58AC8Fdc8bA968897c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.50025652579302,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.5,
  },
  {
    address: '0x385018e552d732CC2515e8B505F12F3E8C4CB6fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.0362380743114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.04,
  },
  {
    address: '0xb61a8be8F7676C5D2e47af9E2945247c301635ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 167.37083114871928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 167.37,
  },
  {
    address: '0x77d11B095417b4413099f4975A161D06c52083A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 166.78448458414726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.78,
  },
  {
    address: '0x998c0Bfa4776e5e59180BD138aE8b1abB32D8945',
    minters_reward: 0,
    lp_providers_reward: 110.22,
    swapers_reward: 56.442138929414845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.66,
  },
  {
    address: '0xfca719B2f234FCe1760895B2ECb5fB2284BcB227',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 166.33704262641834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.34,
  },
  {
    address: '0x49DcfE2aDEC314C730652CDc3184c962eEf10a82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 166.33452194495823,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.33,
  },
  {
    address: '0xE89F14a82d018eF607460a152697725b94eE37Fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 166.0158318976129,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.02,
  },
  {
    address: '0x7496CC89ee286802b891Bf951Ae47264B0E8498e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 165.9350862318333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.94,
  },
  {
    address: '0xA6980C9fBEA0Cf9b772dFf484d5b6cd36194A7f7',
    minters_reward: 0,
    lp_providers_reward: 165.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.84,
  },
  {
    address: '0xC5F62c4adA53Dfd7f521D32C2E7af520d0F136DE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 165.6563660976759,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.66,
  },
  {
    address: '0x788EeeEf9660aC2a60E9BFCa88f51b50b1df2Cb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 165.59501313632154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.6,
  },
  {
    address: '0x62874C7701415216426A5b45f56012111640f26e',
    minters_reward: 0,
    lp_providers_reward: 165.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.25,
  },
  {
    address: '0x9Cff7da0f0D9399605F50101381dC421948966B5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 165.1619651612694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.16,
  },
  {
    address: '0xDAbd97880702b57F3a713227c1aDBfb72E5C0c08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 164.25941536585387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 164.26,
  },
  {
    address: '0xFD3CdD5cb88344C18d6369A2e61C2203EA6A7d3E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 164.22782184494835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 164.23,
  },
  {
    address: '0x01cFCccc806B42557368A6f64B5Ece0cEE972c90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 163.9167147648163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.92,
  },
  {
    address: '0xa378AB1f57dB0dE82f1948d2D09f4cd303d10082',
    minters_reward: 0,
    lp_providers_reward: 73.94,
    swapers_reward: 89.74093641523227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.68,
  },
  {
    address: '0xEAFd9991d29080AeF7267496C64E9A66187277Cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 163.26741142346572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.27,
  },
  {
    address: '0xbCcc0Ed2074907C3393e3dC14e72AbDb6950f6d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 163.16736277226727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.17,
  },
  {
    address: '0x55958D52B0796C4CFEd38F6c125Df04BE644c428',
    minters_reward: 0,
    lp_providers_reward: 115.84,
    swapers_reward: 47.330117034119844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.17,
  },
  {
    address: '0x3C4980898fF626fB538ce60509B745c6bA9Ec5dC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 162.7166464193963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.72,
  },
  {
    address: '0x6BE5D58E4EA3b6FB59FFE116f34B8e34108B6673',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 162.53343696023617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.53,
  },
  {
    address: '0xeD233283B57abB10395666497804f827ab393500',
    minters_reward: 0,
    lp_providers_reward: 68.46,
    swapers_reward: 93.88786312216565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.35,
  },
  {
    address: '0x6d09b955078C0b023903B390a434fBbF23ad50CD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 162.33295528003427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.33,
  },
  {
    address: '0xba6129d6756f9Cb2515E7454318bB51C5e0b9237',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 162.13096384207648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.13,
  },
  {
    address: '0xd66E1F7035491633A00a87FF851051710168625a',
    minters_reward: 0,
    lp_providers_reward: 161.38,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 161.38,
  },
  {
    address: '0x0730eeD296483E7f19faFd631aCde3c897ce2C72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 161.3279939816365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 161.33,
  },
  {
    address: '0x0d9073c59de3Cecd911de665fd5A52Be70b48eC9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 161.18255296513632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 161.18,
  },
  {
    address: '0x4785334baFE9Dc1dAe8D89D01059D87C3B221e00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 161.0157516670559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 161.02,
  },
  {
    address: '0x85bbd83e7a53A6Cb983a1e6eA19C9e8164d30EED',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 160.96787951971808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 160.97,
  },
  {
    address: '0xCf29484fBED558C4d1a23163Cd8019C56379D203',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 160.8374884476565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 160.84,
  },
  {
    address: '0x65371E819A836A5eD9e782608f500b9DeC54C301',
    minters_reward: 0,
    lp_providers_reward: 93.77,
    swapers_reward: 66.57948955035252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 160.35,
  },
  {
    address: '0x954485caC28388AF1813Ac1ee280bb48218715A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 159.35155824578752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 159.35,
  },
  {
    address: '0xAd4d74CD7f17EdAE3C548EcBc0CBe0791C89A492',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 158.80234642641767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 158.8,
  },
  {
    address: '0x013B89Ae6B2f9a7ACe94C5C6aaBE039517C3E11e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 158.49560297669942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 158.5,
  },
  {
    address: '0x9213Ab87E812E5473233b30Aa2CB2623DC792898',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 158.1747485734106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 158.17,
  },
  {
    address: '0x02F2BAF26b2eceF29a50AA8feF0675Fb5081d3D4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 157.68678680744702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 157.69,
  },
  {
    address: '0x67C2136f0ca4b6696a5027342C7DB5D8Fb59045e',
    minters_reward: 0,
    lp_providers_reward: 127.42,
    swapers_reward: 29.974142652604883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 157.39,
  },
  {
    address: '0x89f5dc461d0a195a5f9AFeA9f2a763F67cd7f4ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 157.35330257857999,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 157.35,
  },
  {
    address: '0x0F546eFE819698e88639680bD894795A6b2aD7E3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 156.95067259381386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 156.95,
  },
  {
    address: '0x9AA9fcC3813dFaeef6BAaE62eFCc00b257797c09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 156.28596535291442,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 156.29,
  },
  {
    address: '0x328a3867cAf19d64232f7115c0AaBa32c0b48616',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 155.71448189145764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.71,
  },
  {
    address: '0xdE85cF5dAb18E07696A6733CD33CB58eab3818Ec',
    minters_reward: 0,
    lp_providers_reward: 70.61,
    swapers_reward: 85.09662350312462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.71,
  },
  {
    address: '0x69e051f04b5b8203387494F39c933988e5076E16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 155.19221105493185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.19,
  },
  {
    address: '0xA6619Cb9612f9ba338cAF668dF73e669798CD41B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 155.1721509961856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.17,
  },
  {
    address: '0x955065690060227c0C27936c627558665d7b38b3',
    minters_reward: 0,
    lp_providers_reward: 155.12,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.12,
  },
  {
    address: '0x140e7AAB562241Ff022e3A366D27AC007AB51124',
    minters_reward: 0,
    lp_providers_reward: 153.04,
    swapers_reward: 2.0223450072727354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.06,
  },
  {
    address: '0xA28737d30B69Ae23e32935EF8A497C6587021055',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 154.74884641617516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 154.75,
  },
  {
    address: '0x6f087e6CD5284F4D4E91b4117724f347ea6677eb',
    minters_reward: 0,
    lp_providers_reward: 123.06,
    swapers_reward: 31.606287406551136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 154.67,
  },
  {
    address: '0xe2304Dd4bA5E9F1bee3b291F920b7f7604666fA0',
    minters_reward: 0,
    lp_providers_reward: 35.45,
    swapers_reward: 119.02465362182026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 154.47,
  },
  {
    address: '0x49c1fb54BF47b95C2c3D505EBB97067A8AC5ffcd',
    minters_reward: 0,
    lp_providers_reward: 106.15,
    swapers_reward: 47.89597651831503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 154.05,
  },
  {
    address: '0x38E3faa8A08839Ec540496BbE035b110Bc975c6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 153.89714706080255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.9,
  },
  {
    address: '0x87090041ebaE950aF919652aeA92253de162F512',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 153.8904518539025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.89,
  },
  {
    address: '0xB411E467C77Be3299c2Dd23354C916A3FfB8b701',
    minters_reward: 0,
    lp_providers_reward: 146.07,
    swapers_reward: 7.4120107526596115,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.48,
  },
  {
    address: '0x570a5b79482976c965b5F13c60cA3F81E853d1Db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 153.1645941237674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.16,
  },
  {
    address: '0x33f2B7cD086FdF24aB73926BaaFc532f840B699C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 153.06139122954244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.06,
  },
  {
    address: '0x3Ed994504a84813cf9414E42CC8f07c16fF5b898',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.7448170766592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.74,
  },
  {
    address: '0xcC566318510E9622cdf34012AB7669d5E3525129',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.53364503206322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.53,
  },
  {
    address: '0x4eeBB31B05272caEc451581B62Bf0C372C9767aB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.53004669356045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.53,
  },
  {
    address: '0xE2FBb1fA5380F14AEaa4f248b05964d0742D122a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.4069995760121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.41,
  },
  {
    address: '0xD3F1070b46E2aAD65733E5A6D1CfD029c67b0791',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.16876446518035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.17,
  },
  {
    address: '0x29d5AC65E839879c87ee5208F9b4b821299492A5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.1475585738595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.15,
  },
  {
    address: '0xe72F93993F71A4b84246f7Cf45be2B3473358461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 150.97035601104724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 150.97,
  },
  {
    address: '0x65ACBe92024C7669cbF8eEA8ee67694e72A77378',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 150.2609681268329,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 150.26,
  },
  {
    address: '0xDd81F86B4e8ab9c512d9EF6940b443C1759e4988',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.90839675622829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.91,
  },
  {
    address: '0xE709238Bb43De71C9E16159bAA67c628fF0d8e7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.74530182626648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.75,
  },
  {
    address: '0xF3195Bd69Dd492fFe2CC3d2D6B6C97566E807458',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.64842571150598,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.65,
  },
  {
    address: '0x97d4bA22EA1cf9d5eF7d090B483C015a0C3d62Ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.62125434749794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.62,
  },
  {
    address: '0x55D9553c7aE565F4E29Da88BfF14aDfc5203fCE3',
    minters_reward: 0,
    lp_providers_reward: 0.39,
    swapers_reward: 149.10669210884117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.5,
  },
  {
    address: '0x15a20CC468676B81d297f05bb31638002d3f210d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.10140386267776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.1,
  },
  {
    address: '0x279F09a177553b55C57a805392fb89c2Eaf271D4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 148.47088009204617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 148.47,
  },
  {
    address: '0xBD4F0F12f218B40Be05D9D5d845B41Dd0F68135D',
    minters_reward: 0,
    lp_providers_reward: 10.16,
    swapers_reward: 138.13031960766767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 148.29,
  },
  {
    address: '0x6d11F00E8E2BdB4508Fc0e04a0E769B7Dea8Da16',
    minters_reward: 0,
    lp_providers_reward: 119.81,
    swapers_reward: 28.38421577029301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 148.19,
  },
  {
    address: '0xB650573D34b2c70a5d5aEc943c18A5a375dF903D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 148.08579692652677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 148.09,
  },
  {
    address: '0xCA2474c8Ce5cc302473CA32da2A3B105989A5fC3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 147.8340119438476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 147.83,
  },
  {
    address: '0x2C4C06abec09e600Fddc406e94Ac7607FFEdFb15',
    minters_reward: 0,
    lp_providers_reward: 83.37,
    swapers_reward: 64.32592242246496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 147.7,
  },
  {
    address: '0xB26338AfA4446603fB5744d14Cd26d541f9f051D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 147.0234324739507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 147.02,
  },
  {
    address: '0xEf89BD5A5e2592bE73bfDE5b94Bf6599FC078570',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.9987555441745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 147,
  },
  {
    address: '0x7b170B939c4bEa5421BB9DBD07f29a967A065ba0',
    minters_reward: 0,
    lp_providers_reward: 87.63,
    swapers_reward: 59.351348204247515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.98,
  },
  {
    address: '0x2c99c1F51bdB04B8fb699c56928f80610328874a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.82780388208678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.83,
  },
  {
    address: '0x13EFddd89fee639878bB3CC0b7f6240E016ba30E',
    minters_reward: 0,
    lp_providers_reward: 3.83,
    swapers_reward: 142.97673539640098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.81,
  },
  {
    address: '0x2Be5c96955Aa755e07eDcC647f52480e762981d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.73693923006485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.74,
  },
  {
    address: '0x63f01e5EbE287BCB84A5A68FA87201C6d8120Ee5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.60984195388698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.61,
  },
  {
    address: '0xb03C3E3a0639D699E69Ffd50Be0350a1a89c6a13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.36839028831633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.37,
  },
  {
    address: '0x00ff10CcFC480Bc40b34f1f747aE66C710Ce2AF5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.36000848754702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.36,
  },
  {
    address: '0x82eD5174CFa1131f6A3479D1DaD5deDc74D22837',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.085774253702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.09,
  },
  {
    address: '0x8CF4906d85cd9C61DEbd85b9fd3C6b838Dce18e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.97712220038645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.98,
  },
  {
    address: '0x19cAc87BB9aa7715d6e7CF06a85ecadc1C9373D7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.92202606865035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.92,
  },
  {
    address: '0x853cD31Fc3FCFa6Ec1B7b6Db600e4896882dEAd9',
    minters_reward: 0,
    lp_providers_reward: 84.22,
    swapers_reward: 61.69861832418458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.92,
  },
  {
    address: '0x7Bc7CfFB988771AAa5F442256f78Bf50EA07202C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.74986872233234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.75,
  },
  {
    address: '0x714dcF97b9dd4bc74F02AEBd762939a97Ed35962',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.61080715049786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.61,
  },
  {
    address: '0x5B49444be2A0c78f643c13E8D468cAe02B3c79dB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.60149387903428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.6,
  },
  {
    address: '0x9b8c064A525F619F5842B40823669d9ff55eaa23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.6006841447431,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.6,
  },
  {
    address: '0x0C5B7ca5f56d2EB4c2cEF7fA3DA049b626BDD12f',
    minters_reward: 0,
    lp_providers_reward: 144.97,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.97,
  },
  {
    address: '0x5EAba6FdE25bf0d7c8d0949EA44CD897a41d5168',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 144.8769186890335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.88,
  },
  {
    address: '0x442e20934fB21f6DF5d1082B49BAEAA0e0089857',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 144.5579468417574,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.56,
  },
  {
    address: '0x1c3B615f3eDa28816CDdCBc0b1A5F7D955Ac473e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 144.22362574513917,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.22,
  },
  {
    address: '0x6e0b018ad27843C290D3b5ddB43C33C3fcBB213C',
    minters_reward: 0,
    lp_providers_reward: 43.15,
    swapers_reward: 101.00392503441529,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.15,
  },
  {
    address: '0x6B06DA77AF6b5BfC842af969BC278Dd3d4675Acb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 144.0277616707239,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.03,
  },
  {
    address: '0x14A008Bce417F66E2bc947686A09633FE224A344',
    minters_reward: 0,
    lp_providers_reward: 26.66,
    swapers_reward: 117.34493551262555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144,
  },
  {
    address: '0xE4808Ab083A1680D8645E779e743d775f859988F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.94649427214438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.95,
  },
  {
    address: '0x6E4E73434886bD5fc9cFE5a83793AD4eEE0128E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.94649427214438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.95,
  },
  {
    address: '0xD576C0600492E463EC84e7810dC07F1aBE0646cF',
    minters_reward: 0,
    lp_providers_reward: 142.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.84,
  },
  {
    address: '0xF0049eD5eF978cF3d152aCe5ABc35a18C231027a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.83080236316468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.83,
  },
  {
    address: '0xa0e3E84077258f41fb8a3FC0a283096b7806ABE5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.63790436778126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.64,
  },
  {
    address: '0x011c64F240195c2Aea0E5b9e14375deF5a6ab0c5',
    minters_reward: 0,
    lp_providers_reward: 142.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.59,
  },
  {
    address: '0xC09eda530bF9F1112A07094574CF3Db04EC6bbd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.53741567419564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.54,
  },
  {
    address: '0x7778Df52Fe8BA70F42901F92333B00ed0A7803c8',
    minters_reward: 0,
    lp_providers_reward: 0.26,
    swapers_reward: 142.23159499345152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.49,
  },
  {
    address: '0xD2F05218E4AbD4CaCab839fb6B10A845a66F2CEC',
    minters_reward: 0,
    lp_providers_reward: 79.97,
    swapers_reward: 62.39376951333629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.36,
  },
  {
    address: '0xA3c3Ba59bA5AB7Adcc3A43cCA7a17F59d5Bb7443',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.10164796110445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.1,
  },
  {
    address: '0xD8384717947AF3018F69c1b641c9236b5Eb3674e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.87967852773542,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.88,
  },
  {
    address: '0x67769D0ca8aC4c6FEc9F0D70BF4A5f54C2a08dA1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.6635515163698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.66,
  },
  {
    address: '0x3200e15B73a5Ab31F6590F7EB5890AEB118b670C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.53799826884918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.54,
  },
  {
    address: '0x698EbcDb4C98554E92cc5d8b3bb3892820Cca090',
    minters_reward: 0,
    lp_providers_reward: 107.42,
    swapers_reward: 34.115927592424065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.54,
  },
  {
    address: '0xF647693FBeC963D59d74B8afebDE985892FD7fAb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.4266325107942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.43,
  },
  {
    address: '0x37588DfAD88C5Af64Cb65Dcf0E8A9f0d50ED4457',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.11917552803487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.12,
  },
  {
    address: '0x3711E796F8D67779B0c44884fC1EEBe9039f3fDc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.00135095958683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141,
  },
  {
    address: '0x47782F8494C090bf5FaF0E79D8b6Cc0B8e774352',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.99643111199825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141,
  },
  {
    address: '0x40586600A136652F6D0a6cc6A62b6bd1beF7AE9A',
    minters_reward: 0,
    lp_providers_reward: 61.27,
    swapers_reward: 79.68806599081073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.96,
  },
  {
    address: '0x237442A803ED17EB5EfAb439836451A4c265791e',
    minters_reward: 0,
    lp_providers_reward: 140.75,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.75,
  },
  {
    address: '0xC517a33Be71D04986Bbe8741422A79e00664D55D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.5558522647822,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.56,
  },
  {
    address: '0x8448AC5375eB88B24F7d14E3508c2F628b980516',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.45226003120885,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.45,
  },
  {
    address: '0x4E5E224acaaA2f8539458464C967CdE6de3Ed94e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.2266270634355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.23,
  },
  {
    address: '0x9048c9Da2cBF41Ea2Fca56CD3c45fF811cf11006',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.11280988602223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.11,
  },
  {
    address: '0x9e01B67B83AA360076dE9803FD68Abd07F95B07f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.9167826606048,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.92,
  },
  {
    address: '0xaf49417a1bFf1684497F206e991021fDAfA9fED9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.90651004677596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.91,
  },
  {
    address: '0x524e2da1F1c6991601326cCb5C35790Ca0cA929e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.90651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.91,
  },
  {
    address: '0xc7A9Dab32c27ba9ACE11Fd61EBe1A73D5fbC577d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.90651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.91,
  },
  {
    address: '0x523169C2F395b61eb5b1D227BfAF7FB003d9EC55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.54236518757685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.54,
  },
  {
    address: '0x14534A7e1F0e1583e45721ba6A3919eE8beD6a96',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.52654388217658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.53,
  },
  {
    address: '0x3B3102F25eD3169267D660e4236dbc1000C19980',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.5249262233621,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.52,
  },
  {
    address: '0xb8e0FbAb7fba8297E58E62c6a532473E062d3e00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.50786672420273,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.51,
  },
  {
    address: '0x0910b60Bc62EC8D486948a2137618C8835eE8388',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.41455166613977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.41,
  },
  {
    address: '0x3B79EEf50F852EeE48F2a0872bFACE2D30f70d17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.37685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.38,
  },
  {
    address: '0x94D4F65f18c0b4d2f4c8544F1a88219202FcC1E8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.37685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.38,
  },
  {
    address: '0xb716f8eB4178ccbC9BF07CD8bD05FEDc262Ce834',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.37685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.38,
  },
  {
    address: '0x033E38a9aC3DE364A8289adA1854Ad9bdFd38075',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.37685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.38,
  },
  {
    address: '0xD5462786709aaCbF9a0721a6be3B87566304A978',
    minters_reward: 0,
    lp_providers_reward: 111.83,
    swapers_reward: 27.41610823406225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.25,
  },
  {
    address: '0xfc6a97490d6bCd9C2Cde8e1B41237b060c73347B',
    minters_reward: 0,
    lp_providers_reward: 139.09,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.09,
  },
  {
    address: '0xAaB61046f260f16693b44d293609B7A3241549A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 138.99655517952186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139,
  },
  {
    address: '0x59478f7aA5FBCCac10845A201bE29e4f8790e335',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 138.91221682289336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 138.91,
  },
  {
    address: '0x48747F28F1BEEB1F13394B623e72532e613FD9E4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 138.37394684531816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 138.37,
  },
  {
    address: '0x135aCAfD0D5a68905c3c689E90e8041d87773053',
    minters_reward: 0,
    lp_providers_reward: 82.16,
    swapers_reward: 55.96260401871038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 138.12,
  },
  {
    address: '0x9369c1DaDA59Ef4D8E66350Bd23Ca215b98cAF19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 138.1000515818232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 138.1,
  },
  {
    address: '0x7546ee77E161ccf57634B8F17ee9B885FCD6b0E9',
    minters_reward: 0,
    lp_providers_reward: 137.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.93,
  },
  {
    address: '0xd250768A3aD3B4534D4D5C0307D0f1501e5fb066',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 137.88588072546634,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.89,
  },
  {
    address: '0xE4e22B3c6fcf4584eE6b27aBb36B7536B764ad88',
    minters_reward: 0,
    lp_providers_reward: 137.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.71,
  },
  {
    address: '0xa2847F9E38236299d7da3f3146b2D728fD60D8cC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 137.6152656333093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.62,
  },
  {
    address: '0x309eb98DEF83dC892f19dDdff63C4009abE3Efc7',
    minters_reward: 0,
    lp_providers_reward: 123.91,
    swapers_reward: 13.612694852918676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.52,
  },
  {
    address: '0x91a207AFF938E9E63De4CD756303D5450E1f4BF4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 137.0741532840851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.07,
  },
  {
    address: '0xFdfF139B421b8036fd760026f8b820Afaf00A0f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 136.84780334637094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 136.85,
  },
  {
    address: '0x23F3191dbefd8Fa43f4fa3b5E258903c7a87BBE9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 136.71081954415507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 136.71,
  },
  {
    address: '0x24cfa7c4154D8411f84F0A0119d1C478ead6D2dD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 136.25692219833945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 136.26,
  },
  {
    address: '0xEeaD7C00F872f14980E6934bD9a5C9a408BD9a48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 136.161996356253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 136.16,
  },
  {
    address: '0xFF95180a37558C675906e76b67bD735f672c2203',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.79557650271823,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.8,
  },
  {
    address: '0xCc751cA90BC6bdbAC814D9034CB6A8E8756b0723',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.77620275416143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.78,
  },
  {
    address: '0x10411a5cC506529B84BcBC54926C3858a5338EBF',
    minters_reward: 0,
    lp_providers_reward: 90.91,
    swapers_reward: 44.874758996245085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.78,
  },
  {
    address: '0x94E95bba2A1a16E9ac9eEBE56dC5f062dC6C8959',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.4445308001968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.44,
  },
  {
    address: '0x6A607d29e528C26CFb02acEc281a7df902707C71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.4445308001968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.44,
  },
  {
    address: '0x421fd272e30597cD708c10C0b71f3Df7C5EFf94E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.3460407289526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.35,
  },
  {
    address: '0x7E33d90b28Cb0E74B65d388e290E4e903e2fd71d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.14315686683952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.14,
  },
  {
    address: '0xe4cadeAA73dF0e049d160ECce9c118D660604094',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.0351052997869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.04,
  },
  {
    address: '0x3168866520430D547eC7796e64Dc2869d7e522c9',
    minters_reward: 0,
    lp_providers_reward: 116.55,
    swapers_reward: 18.239567583023206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 134.79,
  },
  {
    address: '0x57E0a4F12aB9C076df4D5B603cC7B6583579bdAB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 134.42137750636562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 134.42,
  },
  {
    address: '0xAF0e12dbC588a1AE6F1f43BAAE5249207F9003F2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 134.19629135682473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 134.2,
  },
  {
    address: '0xf77a9562Fc7a93645d4d913D26ED4e16D35dB4B7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 134.1334255498188,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 134.13,
  },
  {
    address: '0xea4f7c5A528272C2d878851598168097aa17d5eE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.39876489126436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.4,
  },
  {
    address: '0xc52B5609b0D9B4cA3E45d52FC3Ed7b6879bfAd8D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.30001945860147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.3,
  },
  {
    address: '0x18F9EfA9a60A2f526211Ec46A37e0eB4D4C0d093',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.26542958742323,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.27,
  },
  {
    address: '0x7d2a21CE0485836E4220d95516ef3712dDeda19b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.19829196822684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.2,
  },
  {
    address: '0xf7824E0Fe7feFe8d7fd24B142f24a55c695E1cF0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.18772672059194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.19,
  },
  {
    address: '0x6dBb6785140F231D83D9314aFa59d09861868c99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 132.4931921687683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 132.49,
  },
  {
    address: '0x411fa7dF6243bC50400aA1b0cF8fc556bB9A4dAD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 132.48449043932172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 132.48,
  },
  {
    address: '0xDF97C609bF4f151573fC57566CaBf6d1bcDD015C',
    minters_reward: 0,
    lp_providers_reward: 85.84,
    swapers_reward: 46.55224100678601,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 132.39,
  },
  {
    address: '0x8969c18fd98017A5097F7999F17AA4a8844f41ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 132.10410981501303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 132.1,
  },
  {
    address: '0xf7068CA96194Cb4121a3dEa1A92C50BfAF7f6788',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 131.93086874921477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 131.93,
  },
  {
    address: '0x539AF311938d7EA603769EbEF6d9eC5174652fEa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 131.7463376348619,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 131.75,
  },
  {
    address: '0x036F59770acC9E1ee270086a84a85B03B159dcCD',
    minters_reward: 0,
    lp_providers_reward: 49.48,
    swapers_reward: 81.59241541723247,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 131.07,
  },
  {
    address: '0xed4BaBC9B5aa9202004fDD779E1747D8b07b6B87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 130.99645313448082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 131,
  },
  {
    address: '0xaBefA6E29D218CDb3Ff7697D52fa11c156ABc47c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 130.9436074618814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 130.94,
  },
  {
    address: '0x5d248a35C29695514baAD1Be882B7f8e358B8685',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 130.67660468541814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 130.68,
  },
  {
    address: '0x288303ec4028853b01c813366b4B8c2607afa5A4',
    minters_reward: 0,
    lp_providers_reward: 8.94,
    swapers_reward: 121.30645693390984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 130.25,
  },
  {
    address: '0xF86DeD8a2Ee03b9bC9c0713A59B01948295D539b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.82258549934045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.82,
  },
  {
    address: '0x8156439a29017b20D423a69fc25D4154717acEfa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.806045082023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.81,
  },
  {
    address: '0x58e19acaDB61e452f469daA8c9d301725C5653B2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.76538361731997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.77,
  },
  {
    address: '0xCeE750FD3b90bb7D2e494730b9159A8FDd1Aa15C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.6423861016358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.64,
  },
  {
    address: '0x0a6Fd0a153869CCb6dd16cF3ddf8b50AfF94893F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.6274478043643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.63,
  },
  {
    address: '0x9e5De869d2981C413595117c43D5447642e7c454',
    minters_reward: 0,
    lp_providers_reward: 73.11,
    swapers_reward: 55.94120445254536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.05,
  },
  {
    address: '0x5736982062897D9f4C31B7b20Df680aad072c921',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.98711272252942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.99,
  },
  {
    address: '0xE9091817E8BD3317B53035812D31cae1E902f7da',
    minters_reward: 0,
    lp_providers_reward: 105.92,
    swapers_reward: 22.972800395560537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.89,
  },
  {
    address: '0x7E123bcAa92082C3F2E2b40B943431f5a2AbE3f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.8025178031862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.8,
  },
  {
    address: '0x46e6d3D520C50942139b895AF97Eacd6289ed2Da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.59591738360805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.6,
  },
  {
    address: '0x54487cb9CD4D486436ea6d0c9eDFcac62D973f9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.54371608251617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.54,
  },
  {
    address: '0x6FA48e0836800cc3F304F222082413f0B850BCB2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.4238372931825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.42,
  },
  {
    address: '0xc8AFe22Ca2EC15Bf6a06f6A81976e70480B78220',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.36194445878104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.36,
  },
  {
    address: '0xcB024ddB4fa872A83Ce9b50Ab0f42f0C46099a24',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.3289429137877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.33,
  },
  {
    address: '0x039C7AeE67a86E2Ed79d6F5f8d269A8bBd971b1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.3289429137877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.33,
  },
  {
    address: '0xC103e07f669ba18B1cE8157E0545f08fE1304caD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.3289429137877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.33,
  },
  {
    address: '0xcee961aD444FF252243272Aad7D5e03024dF232D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.01927960300253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.02,
  },
  {
    address: '0x750171c533C900EA65fB20f9D4F52b9a8889c09a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 127.50168203327189,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 127.5,
  },
  {
    address: '0x218905AAC8F9826c88289f882DA2Bb7c22DBdc51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 127.33963460838073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 127.34,
  },
  {
    address: '0x75A95A570AB384FfbE5587F5aBd5eB089aeaee40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 127.28565651842703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 127.29,
  },
  {
    address: '0x601CEd301628029D9D045dd1154F92a724753CB6',
    minters_reward: 0,
    lp_providers_reward: 54.01,
    swapers_reward: 73.11770717712172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 127.13,
  },
  {
    address: '0x0a0F9E3C8f55E903Db9Dfc5E5d1f19Ebbb19a68b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 126.91136600658186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 126.91,
  },
  {
    address: '0x0B416906e539407efa36F149E5d494EAC251f16F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 126.06631614478906,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 126.07,
  },
  {
    address: '0x73208B829e88d92FbE81b5E3d424d99E6Bff0516',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.94547467853351,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.95,
  },
  {
    address: '0x2c579D28826Ec23A29aB4b5fdfFb0dc9cDfa7015',
    minters_reward: 0,
    lp_providers_reward: 124.34,
    swapers_reward: 1.5882608500211144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.93,
  },
  {
    address: '0x618fb476C7d2a6C4619a5984511d2f1deE37D5f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.73933933882374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.74,
  },
  {
    address: '0x15F7BFCc45Df5b46098373d91dA362285eFD5Cb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.73111536479662,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.73,
  },
  {
    address: '0xF3ccaDa6c58a8eD78907281Ec4A97bBf2bBDe3a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.59657754127056,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.6,
  },
  {
    address: '0x4517356E9D01fF88Be1bB90288a32e832ef1944f',
    minters_reward: 0,
    lp_providers_reward: 125.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.59,
  },
  {
    address: '0xc3a6F5343c410B821D0121cB9D531953D8a9C278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.26085182483766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.26,
  },
  {
    address: '0x58c93d10a17CE752D58218918323234acC2ce749',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.91733167292645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.92,
  },
  {
    address: '0xd0201837904A90AA676Fd61AcdDa15830545f58D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.881437139713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.88,
  },
  {
    address: '0x9FD3751938EA8813EE905A5F4B4153608370c473',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.58574499083717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.59,
  },
  {
    address: '0x56f74265e50b4FD9112101569dbF60d30987F79B',
    minters_reward: 0,
    lp_providers_reward: 124.56,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.56,
  },
  {
    address: '0x0ADB4dA404b67975294CeA62E306cE6Ca574DC8B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.53757425047957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.54,
  },
  {
    address: '0x371e7823b64ce4Fd82877Ee64E46f1608fDA4587',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.53738776160236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.54,
  },
  {
    address: '0x36f88AeA238D4a5222c25Df9DbF038c21E345677',
    minters_reward: 0,
    lp_providers_reward: 66.72,
    swapers_reward: 57.818576454305166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.54,
  },
  {
    address: '0x76C09468489127D41B818A1a24D5165Ad2b6c0D3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 123.70876245049419,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 123.71,
  },
  {
    address: '0x56223243befb086cc661302D61725D07DaB62042',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 123.61149955594082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 123.61,
  },
  {
    address: '0x0f1AEB0c1b85FC9d589fc17EffD054a38cbF7AB1',
    minters_reward: 0,
    lp_providers_reward: 63.29,
    swapers_reward: 60.23991933179277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 123.53,
  },
  {
    address: '0xc1dC28dD1a60a39813B87d8bD21cb72395083ae9',
    minters_reward: 0,
    lp_providers_reward: 123.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 123.33,
  },
  {
    address: '0x4014EaEa09810A768501c9536c527764575Fc439',
    minters_reward: 0,
    lp_providers_reward: 112.13,
    swapers_reward: 10.656005020627262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.79,
  },
  {
    address: '0xa220460A376aEfF72D3F15C9d64105351dC3dc6A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.66594994411955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.67,
  },
  {
    address: '0x1D7B0F2514ebc1b370284637098BA0Bd16339c2f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.64798707096517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.65,
  },
  {
    address: '0xe26009A420F21048Eed184fF9146D88FdE00d0d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.59201879383667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.59,
  },
  {
    address: '0x92652829Ee4b95f356904577180f1A11b689d135',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.58683198416912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.59,
  },
  {
    address: '0x19894487ed37b95f69Ee2d8A2930260B3FBA711A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.26364826563974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.26,
  },
  {
    address: '0x82D7F4E5A06007fc5464D0d5f51b8c7f59b33391',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.90007772017715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.9,
  },
  {
    address: '0xe171b15fACFC69E33575f566213EC172db9Ec181',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.57338699986768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.57,
  },
  {
    address: '0xE5afEE501AD5371E4A662131F7DF2Ce85ff061f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.54744654356554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.55,
  },
  {
    address: '0xc6967F0C428D369495F274408BeE3B4F141DcAe1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.51562869920186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.52,
  },
  {
    address: '0xB3841125055757ae716b764837D2dAa681ee87cd',
    minters_reward: 0,
    lp_providers_reward: 18.62,
    swapers_reward: 102.7858720925613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.41,
  },
  {
    address: '0x88eF0E143e6FBCef2952d321B1532C9395303be5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.28681100818113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.29,
  },
  {
    address: '0x0777449aec166F1F9b5858FAb979b7f8DE6edeE7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.22422556525656,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.22,
  },
  {
    address: '0xF7FafF5C982cE9049Bd13f3A82FE2aA429Ed45dF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 120.97367281435669,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.97,
  },
  {
    address: '0xE3494070BAc83b259D5f93982077EdbD12A4F026',
    minters_reward: 0,
    lp_providers_reward: 49.42,
    swapers_reward: 71.43841839473181,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.86,
  },
  {
    address: '0x5d849610482153c7139356c3787bC2b7347BaCB8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 120.76689144475735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.77,
  },
  {
    address: '0xc74f46e2dBdEc674aD71692c53522663A50696Ca',
    minters_reward: 0,
    lp_providers_reward: 94.81,
    swapers_reward: 25.84280979290873,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.65,
  },
  {
    address: '0x9e359EDa89A78488238C5379234155d16CF2dC0C',
    minters_reward: 0,
    lp_providers_reward: 120.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.59,
  },
  {
    address: '0xF01fb6021e8C8F2E7a4e33CF1Fbb05d1bE6E5fe8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 120.4604635346464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.46,
  },
  {
    address: '0x5774b76E9442815474f7345cc9Ca080f1e275e00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 120.1849395660355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.18,
  },
  {
    address: '0xF5cfa7b8B8c4E69dF3f656569CB03491F3298A42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 119.912278690051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 119.91,
  },
  {
    address: '0x53Aa71c6F8c78bd0C15Ff6CF8694Dc8d47c5A819',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 119.8635942036561,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 119.86,
  },
  {
    address: '0xEd4D5412a1e8a565da5dC53712C6aD27c0107402',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 119.57200522581036,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 119.57,
  },
  {
    address: '0xe6Ca7a11E768e05D499182ff9D0696Aa08E89A45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 119.3951093697636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 119.4,
  },
  {
    address: '0x7A52259761a27C3B190e95D82b5bAB8406a79B35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.96737497209499,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.97,
  },
  {
    address: '0xC805Fa6561eCfA676137953Aea92B660307E93c3',
    minters_reward: 0,
    lp_providers_reward: 111.34,
    swapers_reward: 7.590545614534436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.93,
  },
  {
    address: '0xc9250751F45B7E8020199aa2Eeda7f91ca614BaD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.52283252532801,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.52,
  },
  {
    address: '0xFd424dF23832Fcb9b5E14680882C441706d0432a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.39315652657125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.39,
  },
  {
    address: '0xFFeab7cF9Bcb2867248C9f2D75531DC9ae147f55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.16576232533023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.17,
  },
  {
    address: '0xA17237A12495fC04dEfcDD3Fc6B922dd07095ce7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.11743755741213,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.12,
  },
  {
    address: '0xAf1D45f93cD5962e21D764631E7FDbB740DfAe1c',
    minters_reward: 0,
    lp_providers_reward: 110.68,
    swapers_reward: 7.147324713607218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.83,
  },
  {
    address: '0xEE5f2e9970543ca38ae565Db60D302E0e0f0628E',
    minters_reward: 0,
    lp_providers_reward: 38.89,
    swapers_reward: 78.89320088690569,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.78,
  },
  {
    address: '0x58aF59f0CEF8eeeA8B1e53fc73398D4BCb72AaEc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 117.56122020228696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.56,
  },
  {
    address: '0x7D00fbd3a266117428f6fCe6D268CB08693175A9',
    minters_reward: 0,
    lp_providers_reward: 117.53,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.53,
  },
  {
    address: '0x91D72d3b45a24963276264725b9786a28f032d41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 117.43182378777881,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.43,
  },
  {
    address: '0xBa0d6A00a79a3F80D5B3628D7E19D188FcfEf0ee',
    minters_reward: 0,
    lp_providers_reward: 117.4,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.4,
  },
  {
    address: '0x21697E02eb60d186eF347c112D106071851AC0c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 117.32290377820091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.32,
  },
  {
    address: '0xeDDe0E6d9B9da150Fb23336D678efAEEC9c7B11c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 117.25746263416995,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.26,
  },
  {
    address: '0x6820dad7041fb3b51166289b6ecf4b4B08D5f1Ff',
    minters_reward: 0,
    lp_providers_reward: 87.34,
    swapers_reward: 29.850989081905787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.19,
  },
  {
    address: '0xAA18c6487447488959f93A6D91b5b41D5d882fE8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.94945061125905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.95,
  },
  {
    address: '0x8f203daA193B261dd364C7aE0e09E5F94d588ae3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.7190110206303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.72,
  },
  {
    address: '0x7fb2b0bdA60F03C9437A848e348335b9975B02cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.22715463518354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.23,
  },
  {
    address: '0x940707533b82fB289C70057363453c8051138BA5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.17655623920427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.18,
  },
  {
    address: '0xbe0D81990422D1d9D7bC077B19C04Cde725D4b51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.1764201500694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.18,
  },
  {
    address: '0x022381DD65CB5fBaBc68318fCA4C8111aA54a343',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.93627264594576,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.94,
  },
  {
    address: '0x531CDf82e7A2826259D8c8F95429c07764e33aDE',
    minters_reward: 0,
    lp_providers_reward: 2.29,
    swapers_reward: 113.55281427184903,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.84,
  },
  {
    address: '0x530c4D2E3D9EAAB672f918384a093c314d914F29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.80982157584234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.81,
  },
  {
    address: '0xe65db3CFd28216454FdbB9CD3C17440eF2eAFde8',
    minters_reward: 0,
    lp_providers_reward: 100.24,
    swapers_reward: 15.51013441661918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.75,
  },
  {
    address: '0x849e346010af41CB483508FB21a76f2A631eE2C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.4485108532436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.45,
  },
  {
    address: '0x7bc208A1389AAe134944d9d2F1f8E0cAE453d3b8',
    minters_reward: 0,
    lp_providers_reward: 115.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.42,
  },
  {
    address: '0x943fB222612EcC3Fc10ae56b02C7345B00dF302A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.38829056377854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.39,
  },
  {
    address: '0x53c12F0ab159F512d0A9B1552c92822Dec73567a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.17259197579006,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.17,
  },
  {
    address: '0x4037fFB93132AbEe2fC0241A8b358449fe19ACd9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.13298190562062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.13,
  },
  {
    address: '0x3F5caccF3b389af56bC2388274480f925feeDB23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.07969493687607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.08,
  },
  {
    address: '0x3e35a3716026F404CA6Be496737CF388885f0c3B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.98590197014089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.99,
  },
  {
    address: '0xCfFE6865F30DD90D58439751929605D6fA979698',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.91329986514488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.91,
  },
  {
    address: '0x204c722b8398A161923b053f588650352082E396',
    minters_reward: 0,
    lp_providers_reward: 113.92,
    swapers_reward: 0.8739543133228767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.79,
  },
  {
    address: '0x629CC93a55Cd0Ad6D038bb32BE030C4dE7E5bC47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.78475694938153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.78,
  },
  {
    address: '0x84a69E5569F0897b4BAd2CB8B238aB61Ba82bA20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.50393110954218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.5,
  },
  {
    address: '0x88A6DE573ea781Fe41b8dfd7503708dabC354918',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.46042370978094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.46,
  },
  {
    address: '0xd6430009c8663484CEFd1A1a187B4f4c8A3ED9A4',
    minters_reward: 0,
    lp_providers_reward: 60.63,
    swapers_reward: 53.724243591871705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.35,
  },
  {
    address: '0x9d49B4CaA39386aaaC060f971e10Ad784B4C7E7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.29115758604222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.29,
  },
  {
    address: '0x9FA37c9457fFdBd85AFE0B5648B37f560db47a29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.10442698776758,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.1,
  },
  {
    address: '0x6a3b578231fBfcA381A729d51c37Ba4D080Dd715',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.0475852118716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.05,
  },
  {
    address: '0x85B05F4d30A87A49A5Bb4B54Da1dD4875F51f01F',
    minters_reward: 0,
    lp_providers_reward: 58.53,
    swapers_reward: 55.42523891339929,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.96,
  },
  {
    address: '0x48cC6Dc464d0AD78FcD9AA5D1aB49Bc34E93c9d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 113.8213324762103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.82,
  },
  {
    address: '0x6D31eb700b634Cc5138cFcC4d098517d693A8123',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 113.74026652512697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.74,
  },
  {
    address: '0xA183e271d887fAF472E97782555cBc4d17e16469',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 113.30795410568034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.31,
  },
  {
    address: '0x5D43e0F30Cd994c83986a7a264B06605b0B8f479',
    minters_reward: 0,
    lp_providers_reward: 82.97,
    swapers_reward: 30.282562703538336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.25,
  },
  {
    address: '0xd8Fd8B4599a99962ad7347100f949BddcDE0c8C5',
    minters_reward: 0,
    lp_providers_reward: 104.13,
    swapers_reward: 9.110388469644445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.24,
  },
  {
    address: '0x30A6A57Be0DcE02cE3000C16deB30DFc5d01c8eE',
    minters_reward: 0,
    lp_providers_reward: 11.65,
    swapers_reward: 101.54945874897857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.2,
  },
  {
    address: '0xeEeA011e7a0ac8A2d3b0E184c616eC471f252ed2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.94217001817181,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.94,
  },
  {
    address: '0xb8C348584Bdd31269e8bEB949Cc6e4f7e0624485',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.62926569689701,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.63,
  },
  {
    address: '0xE4E281BbB0702643dDf8E97D3eAAcAA0CCaedbc3',
    minters_reward: 0,
    lp_providers_reward: 112.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.62,
  },
  {
    address: '0x048bA2C1F213Ac3A87aB75E0B44492E1Ba502630',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.4295581656799,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.43,
  },
  {
    address: '0x11cec3F0441066188d1B41B46abF51e516bD389e',
    minters_reward: 0,
    lp_providers_reward: 79.44,
    swapers_reward: 32.92241186722149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.36,
  },
  {
    address: '0x2dF19c28C93C18bc2d355088AE8AA6912d5e6D9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.32258903281091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.32,
  },
  {
    address: '0x0609D2f5E90D7cE69BA7a6E749538af0107d07B5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.30383468608403,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.3,
  },
  {
    address: '0xd2a9F5EedB2DC70D3eA8A08a40435a58E26202D2',
    minters_reward: 0,
    lp_providers_reward: 112.16,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.16,
  },
  {
    address: '0x66E7E178ABb465B59569e3f8a9D4491435feb71c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.123700726251,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.12,
  },
  {
    address: '0xa35d2624696C311f6c4CB7dcECFCB30eb7F0980d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.96981824773438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.97,
  },
  {
    address: '0x4a6EE18EcfA209481b6Cf78860e869B5C2C32C3D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.50148069831845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.5,
  },
  {
    address: '0x5BA638AE90C16426b021b4b62Cd2E539944d5Bd8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.50148069831845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.5,
  },
  {
    address: '0x57FC4a71C47A717daD63BAFE4158dc25d1829F91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.47027847287532,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.47,
  },
  {
    address: '0xc1009986E256e6B4Df2f3cA90E0601a15Da47eb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.40144677680496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.4,
  },
  {
    address: '0x49B338B4305960d88Fd5380B67d621A5CC3e4084',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.07621290211898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.08,
  },
  {
    address: '0x231b8b1f054B695A76C8172ACBb145D382AE0963',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 110.83309581724717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 110.83,
  },
  {
    address: '0x8C518c5DF29275415e0D62Fb596C9EBf6aEC24Df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 110.69965765605441,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 110.7,
  },
  {
    address: '0x84779cE7E8882a4752aAc7f4e6680CC5fD439be2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 110.4755046578933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 110.48,
  },
  {
    address: '0xa6aba66039000AdC1783F8AAB76caC78bb1Ff341',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 109.90542162794526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 109.91,
  },
  {
    address: '0x6F5c1C80b2bdC3d1276665AdEfa7B829b02455E2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 109.82155877275358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 109.82,
  },
  {
    address: '0xB6fea6A9FF8696BA017Fa968baF6e9f9514705e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 109.35989713833877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 109.36,
  },
  {
    address: '0x08CA6f5A6F4fd7AB4BD6BB754441e90aF3d12eFf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 109.22696983421531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 109.23,
  },
  {
    address: '0x53Ac078327F74502F30160615732D11997248B32',
    minters_reward: 0,
    lp_providers_reward: 66.26,
    swapers_reward: 42.485687464286116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.75,
  },
  {
    address: '0x1B1e028903aBEB867EFF7b5DbdD5892F50395eCB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.41841604028662,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.42,
  },
  {
    address: '0xc843893F86A904F8198ab97A7956771282B45c03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.38079021656016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.38,
  },
  {
    address: '0x87301f0f7DE054Bb22D9788941b889b9751401C6',
    minters_reward: 0,
    lp_providers_reward: 1.03,
    swapers_reward: 107.33929458014583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.37,
  },
  {
    address: '0xB1A76510A9A229CED8c10C1517BE3AEbD6B90992',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.33278705527319,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.33,
  },
  {
    address: '0x928C9536E4c537414BC754F453eC346F07D84347',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.1785090495711,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.18,
  },
  {
    address: '0x52907d29f24a026C1EEC5B5ca6981eD89981a23B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.07010617859913,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.07,
  },
  {
    address: '0x1FA8aFA13AfFf3A539618A7F526C95Db82543499',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.96029046436736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.96,
  },
  {
    address: '0xC4aA508040c4970ca4317B23EB952992FbC3e8c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.91457273390171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.91,
  },
  {
    address: '0xF655B7f777C2196BfbF2c34F4E65DaA4E3505990',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.67703844328425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.68,
  },
  {
    address: '0x4AA545a0108110DeE6469D4Ac1D9f9d523A86d28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.57927993200852,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.58,
  },
  {
    address: '0xdBDd372Aa9386dde2Ae9c3b1240b95cAff778880',
    minters_reward: 0,
    lp_providers_reward: 92.02,
    swapers_reward: 15.458625841046524,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.48,
  },
  {
    address: '0x49d372ca71b90Eb1bc326a9F1A369BA29686A6dF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.27011132530626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.27,
  },
  {
    address: '0x77Ed0d508fDF1044C6b679201Af899e9d853daD8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.23458533153575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.23,
  },
  {
    address: '0x2D0d08360a8bf358F16F7b75Bde834cdbBD74154',
    minters_reward: 0,
    lp_providers_reward: 106.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.92,
  },
  {
    address: '0x76aA89A959e760f8332C347de4D846E12e9AD18b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.81846201547009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.82,
  },
  {
    address: '0x88737FC61a7a48868589f8EDE2F9c14DEDb28303',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.55420049389846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.55,
  },
  {
    address: '0xC00f158A987593E0E2dE2FF1e773b42af0f35D44',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.4508666121147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.45,
  },
  {
    address: '0x8fA68712e4Ca3DCa3FCf1213B1892c2e35364A9B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.4213499169198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.42,
  },
  {
    address: '0x093ae2e4F1b03E61DC61A1014878CDBe0589A9D8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.19947788937515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.2,
  },
  {
    address: '0x23e31624d86c25247623Fd35A0c158a8334Ee741',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.13208318286522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.13,
  },
  {
    address: '0x7bb8E87CAe5238a2DfA86Edf4ae76691a56d9b4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.86951914122248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.87,
  },
  {
    address: '0x110BBFb43e5c5D6DC8E84202186631f69c0a5c89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.82043340306339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.82,
  },
  {
    address: '0xF32d4D8E8b73c9dc16A7bA2f61196FB893f8f580',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.8122309871493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.81,
  },
  {
    address: '0x0862d5A5Eb4FD5796305d56B562FBF6B9f94Ab64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.74648546913369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.75,
  },
  {
    address: '0xe2684FdffB2DBC24cc8A1f179243F70698e61934',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.74648546913369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.75,
  },
  {
    address: '0x762bB81AC7Bd349e87A18efBF926e15cEC5C4eD5',
    minters_reward: 0,
    lp_providers_reward: 8.54,
    swapers_reward: 97.18054717393191,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.72,
  },
  {
    address: '0x053F6462645cF23437Dcc30926CC4b7c62F143d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.61033635777278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.61,
  },
  {
    address: '0x46D7839384D14Cc66B10DF4fE578F9521e13b32C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.45407822000065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.45,
  },
  {
    address: '0x2Be059cfDF5c6577F4f16b371711b1CefdbD78f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.39066339816421,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.39,
  },
  {
    address: '0x8E0fC335448760205e96337A8785C2BF8c692adf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.3634729441231,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.36,
  },
  {
    address: '0x9a931F1c39F5c823fACf54Bed3b6E0593D1408A1',
    minters_reward: 0,
    lp_providers_reward: 105.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.25,
  },
  {
    address: '0x3254cC0A0E9e9A064938a1528B8637321323E62F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.17921106801867,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.18,
  },
  {
    address: '0x139bd6ADD2Ddb4F78E022Db4B523CF3a66aCD539',
    minters_reward: 0,
    lp_providers_reward: 105.09,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.09,
  },
  {
    address: '0x47c2F23F53a55735684CCB7106F605098aFeE4B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.90211791422107,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.9,
  },
  {
    address: '0x77727cDc38421eA298348B584ee2625C56a424Da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.81821272306733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.82,
  },
  {
    address: '0x64392f8f436343a1c505c29B1e31822DB9095dde',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.77182132973054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.77,
  },
  {
    address: '0x08d8226d65Ad2c6972ada9b181A1bd1Ab399e61A',
    minters_reward: 0,
    lp_providers_reward: 103.08,
    swapers_reward: 1.5521293886645402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.63,
  },
  {
    address: '0x4c9914F5A23d1537C21959445FE4e57313b71498',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.57633848397253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.58,
  },
  {
    address: '0x35dbC758ece4A1F8468a7ffA45ccb5E7C59DF4E7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.26255507706134,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.26,
  },
  {
    address: '0x97c9477ca4DbC8ad1F8F9B617b7A699e400B036B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.22852717712219,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.23,
  },
  {
    address: '0x7f187A248412Dd44C5584ff02FBbFDAC0eA907B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.94758524394886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.95,
  },
  {
    address: '0xc447F2E09e245849feFfe66EAECcbdf809Db61F9',
    minters_reward: 0,
    lp_providers_reward: 58.46,
    swapers_reward: 45.43438890144428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.89,
  },
  {
    address: '0xa47A1d4515212504Ce236DceE128dA8F6f88264a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.47535419900781,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.48,
  },
  {
    address: '0xbd422efBd2e2296F32659e5A94cdc8697Aac8c23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.31555157750154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.32,
  },
  {
    address: '0xB0B734CFC8c50F2742449B3946B2f0eD03E96D77',
    minters_reward: 0,
    lp_providers_reward: 103.3,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.3,
  },
  {
    address: '0x8cE2AdB1C3F9D9959C9dE4D1b495d9368a49FeCE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.28774648953004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.29,
  },
  {
    address: '0xF98f0ee190d9f2E6531E226933f1E47a2890CbDA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.09548560336292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.1,
  },
  {
    address: '0xAe69C49f1ef13fcD1d68d03A135d3f4d383CE96f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.95808290943044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.96,
  },
  {
    address: '0x9Ca44C0Aa1eca0887c7d5309f6339AAFa0a84A6D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.82483605270596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.82,
  },
  {
    address: '0xce77F5DfFfC6903e36b0f675E0bcFCe76C1409CB',
    minters_reward: 0,
    lp_providers_reward: 48.12,
    swapers_reward: 54.51971749719988,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.64,
  },
  {
    address: '0x35a525896bdc864ab9992cb56e5f4e45C0F5fdb1',
    minters_reward: 0,
    lp_providers_reward: 70.76,
    swapers_reward: 31.630882443731096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.39,
  },
  {
    address: '0x93d418348439b24edf78C8242C1De8E2de88e204',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.30824100068358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.31,
  },
  {
    address: '0x4e633198A945ecCF846e16aa498C19f6F75E097F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.29712844751286,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.3,
  },
  {
    address: '0x623ba219Ff26D356fEe00Bcc6796DfF4EDbc51F1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.22277855643443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.22,
  },
  {
    address: '0x659D1489409DC95346247D5E1C1399292dF15Fa1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.07597081791033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.08,
  },
  {
    address: '0xc51c91Ec328F3072b415F73FA37574357d389c76',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.02853473442443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.03,
  },
  {
    address: '0x7b2e479A47430C6f484E1fD3577C87F218E010B5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.99750249610092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102,
  },
  {
    address: '0x71CdCC05E02D3fD35b2358C819a05ACddBa19175',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.84756701876007,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.85,
  },
  {
    address: '0x5F256ea51a68124fb6033F60FF3b1688B5a648F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.82188207642288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.82,
  },
  {
    address: '0x82dE5525B3850e325f44EDf45400700D031f3CeA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.61505054303183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.62,
  },
  {
    address: '0x9c2437fcfd14cE113F72E8E954AdCb3c7d9d1f2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.49201093322252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.49,
  },
  {
    address: '0xf00Ce413e2Efc1aFea4da4Ae73E2c5E4830F5fe0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.4504148716734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.45,
  },
  {
    address: '0x6Ea3C78e0865415b85c84A40e6b37551F77AF1c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.33117250027938,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.33,
  },
  {
    address: '0x240052192e4296811b168805c3dAeC7736dDE1af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.29184629129735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.29,
  },
  {
    address: '0xa325aCdFFb754a0915cfA6bf9135e44Bc1A33BBd',
    minters_reward: 0,
    lp_providers_reward: 101.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.19,
  },
  {
    address: '0xbdD69E2dE91aa45BDDa6Dcd9044A09D428f56b83',
    minters_reward: 0,
    lp_providers_reward: 97.88,
    swapers_reward: 3.3027872191389434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.18,
  },
  {
    address: '0x73BB1D3cd992728bdEeaB7D9581F5d353CC53942',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.94698390804672,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.95,
  },
  {
    address: '0xFC1038205Db71bBa8b9E531E592E849ec2e23914',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.94043621806972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.94,
  },
  {
    address: '0x5db2445c0032FED739E0B616Ade2Ec2FCFB53abf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.84661807925019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.85,
  },
  {
    address: '0xDf4206C8ec5870f5994e409FBE87d9456EfBd93F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.80659541400262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.81,
  },
  {
    address: '0x11cf604e5d827EaFe2850e10494ad8BbbFca5365',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.7274002330034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.73,
  },
  {
    address: '0x70a176536100f6f4b7987F3837524AF84ce05fDd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.50797683762629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.51,
  },
  {
    address: '0x157d2947657a819Fb6399D689D4473892D26717f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.47353591237409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.47,
  },
  {
    address: '0x213F4b5357654ab3b00287Fe8cd50D2fc4CAeeB8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.28850380928883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.29,
  },
  {
    address: '0x194E9b6510bD5Cc452231be20ef887996709610A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.2711509751956,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.27,
  },
  {
    address: '0x873F9f645ff4FdD87Ca52adcc42F5FB4Fa3f05ef',
    minters_reward: 0,
    lp_providers_reward: 83.45,
    swapers_reward: 16.77273692228218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.22,
  },
  {
    address: '0x1bB6B61d4B7C01EE9Bf59340d0A4D38672678967',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.06063789836075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.06,
  },
  {
    address: '0x1a7Bb2D234f96628B614e29ab4E57FCb4196eDb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.97503559421611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.98,
  },
  {
    address: '0xbE0bBdC0ac90c18670Dca058a8fE93Fccc31B1c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.94965819443635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.95,
  },
  {
    address: '0x755C5b9e8e9487d5fa7E322F52FD641977E9968D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.88762415466898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.89,
  },
  {
    address: '0x8cBf40461D57960BdFb8F536C4f186a53c603D9B',
    minters_reward: 0,
    lp_providers_reward: 58.5,
    swapers_reward: 41.3285247623859,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.83,
  },
  {
    address: '0xe52a22BF7768e34253726DE0Ed15065C6845deBD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.71058805410513,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.71,
  },
  {
    address: '0x73fcBecd01912E7f2AB0A708e58B2C059a0f9D90',
    minters_reward: 0,
    lp_providers_reward: 63.04,
    swapers_reward: 36.60805756161349,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.65,
  },
  {
    address: '0x7a0a20Ed593A6e2dd8e3BF05fF82B6Fa1DFF1fD0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.20434957295069,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.2,
  },
  {
    address: '0x1aE71C93756FD2eaCf7b35E9055dC5a67F78b323',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 98.74778618424149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.75,
  },
  {
    address: '0xDDaf9D250db0a94Ca0E186F8C985eB1ED169011B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 98.62030843035046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.62,
  },
  {
    address: '0x4aCba9BFcABb79A7C6CD56175C09f397bE24206B',
    minters_reward: 0,
    lp_providers_reward: 98.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.33,
  },
  {
    address: '0x635dDc18e0591F5856546529acC02bE8A12fE47f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 98.27700078100635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.28,
  },
  {
    address: '0x9aFd3311165b389BE5113F89778493cC25a81DfF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 98.17220223979659,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.17,
  },
  {
    address: '0xB64bb996F124d34c84f1bA60c04D4bf81C043e16',
    minters_reward: 0,
    lp_providers_reward: 98.13,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.13,
  },
  {
    address: '0xE7Ec8823357207165D3D1687Ba617FE41487aB0B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 97.76631061630803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.77,
  },
  {
    address: '0xb1d51c71d88B3B9329e07d970d99fD464117CB3C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 97.75840145678409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.76,
  },
  {
    address: '0x99fD9eB075F582b9e4eB519eC1BE971E88575b80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 97.5983207220426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.6,
  },
  {
    address: '0x5D40F1A1895FAba5bC34aC181Aa90251C34d1405',
    minters_reward: 0,
    lp_providers_reward: 82.89,
    swapers_reward: 14.666307731836582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.56,
  },
  {
    address: '0x9a8A7614F4d47E181A3Be6DF5e66a81C25F231a9',
    minters_reward: 0,
    lp_providers_reward: 97.55,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.55,
  },
  {
    address: '0x91573c2770F50fB33c3C174c489F555c44910C5E',
    minters_reward: 0,
    lp_providers_reward: 97.18,
    swapers_reward: 0.2586507124458794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.44,
  },
  {
    address: '0x2722b249cA5FBB1eb0A031edaE9a7fe86fEbf01c',
    minters_reward: 0,
    lp_providers_reward: 9.84,
    swapers_reward: 87.38920578939978,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.23,
  },
  {
    address: '0x73c1fF8781b5728897e550eb004A0D2d34F8D6d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 97.16509574445496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.17,
  },
  {
    address: '0xA7eC44Cf8A82772C54618c178b55def62dc20A69',
    minters_reward: 0,
    lp_providers_reward: 96.99,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.99,
  },
  {
    address: '0xeCD0EB8ee1AFDC5EEA31238dEbbb457a26e6d7b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 96.9053910760183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.91,
  },
  {
    address: '0x9A57cB830176A4E9a69F87127d09Cf5ED0BA6DB8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 96.59689729009696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.6,
  },
  {
    address: '0x2dc617f2EdCf3aeE85904dE2A4569e9039Da80C8',
    minters_reward: 0,
    lp_providers_reward: 4.49,
    swapers_reward: 91.98840000778166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.48,
  },
  {
    address: '0xc601584978b7c22b3bEe4EAcD1ac2dFfc51382E0',
    minters_reward: 0,
    lp_providers_reward: 21.67,
    swapers_reward: 74.76140593575448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.43,
  },
  {
    address: '0x2771efa07e50b5Ae9EDcb58BEE7316Da981510c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 96.32812473116569,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.33,
  },
  {
    address: '0xA5A8C0fb716a4Ffccf4FA920782E4D2547acd4B6',
    minters_reward: 0,
    lp_providers_reward: 41.58,
    swapers_reward: 54.713576925888965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.29,
  },
  {
    address: '0x2619b96310dEe7B52E26b7e06Bd47EE758024A90',
    minters_reward: 0,
    lp_providers_reward: 20.16,
    swapers_reward: 75.75515459370004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.92,
  },
  {
    address: '0x4fbdDd86CbF8303D1F7E43955f49FB47F2c4ca94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 95.86136550650474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.86,
  },
  {
    address: '0x9d24753a4472C11cBC9F23407A44f90d3A84c961',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 95.82251967436851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.82,
  },
  {
    address: '0x2F7F2C551625daE4D0E9e09d0603F4129854843B',
    minters_reward: 0,
    lp_providers_reward: 28.12,
    swapers_reward: 67.6743337758135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.79,
  },
  {
    address: '0x2A6e929EbAF2A44adFb369453C29d9e0D02DfFD8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 95.69910418925497,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.7,
  },
  {
    address: '0x704bA16CCb74677D71332cb59BDF0280da54708f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 95.2471927585975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.25,
  },
  {
    address: '0x61040BB701E0426Ae0cede39E6c5226d673B8F2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.90067111941897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.9,
  },
  {
    address: '0x4646c1A0bCC00f6c288FbcCD864f7A8712Fe3E80',
    minters_reward: 0,
    lp_providers_reward: 26.46,
    swapers_reward: 68.3417132701682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.8,
  },
  {
    address: '0xb2886Bd691C9a10c4Bb578b9a3b1205DA71e2CBF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.72129991798445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.72,
  },
  {
    address: '0xd32A58C68cAd6DDA6078BBd694Aa4cF720978978',
    minters_reward: 0,
    lp_providers_reward: 19.83,
    swapers_reward: 74.84749015385437,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.68,
  },
  {
    address: '0xE60a4bA82BCC58A1b7a12ec5f472707f988E3B03',
    minters_reward: 0,
    lp_providers_reward: 94.64,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.64,
  },
  {
    address: '0x37C03cf03Ba772306FefdFdd5281F63cC5A101e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.48237743592122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.48,
  },
  {
    address: '0x5A7CE6f3eBEaE6D329613BAF126384f2554e571C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.37163828401354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.37,
  },
  {
    address: '0x7508e979117fD186174d428Bba5C2DF3473E5532',
    minters_reward: 0,
    lp_providers_reward: 81.8,
    swapers_reward: 12.568054405192964,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.37,
  },
  {
    address: '0x9fbB9df14ab913ddb3a49Cf23a4c1992Ba799aBB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.31890762309435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.32,
  },
  {
    address: '0x19aF42eb1735e6a93dd567bbB548E8E318751Ace',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.1323413302535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.13,
  },
  {
    address: '0x651c0693BB36BC81d5152792e976E6C9C89BA091',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.12499472682755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.12,
  },
  {
    address: '0x74CCcA4334B4bBD6582Bfdde01F3843ad9888098',
    minters_reward: 0,
    lp_providers_reward: 93.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.96,
  },
  {
    address: '0x8390e281fB69B16E3c767556B6E419b376d5316C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.9281126978284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.93,
  },
  {
    address: '0x1fdb9cBBff84b732c7476F1076e7f768c5DeF83c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.8764236778458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.88,
  },
  {
    address: '0xd9db4046D0f1DEae1485FC11de6fFaaa6208977a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.48714446670311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.49,
  },
  {
    address: '0x2348EaC507Ea4a175E7ab25d7A3cC6B53720E691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.47377028197528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.47,
  },
  {
    address: '0x1c31c3F5c17e5E17c59bA8536d944Ce8353E9d05',
    minters_reward: 0,
    lp_providers_reward: 8.36,
    swapers_reward: 85.0221941670698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.38,
  },
  {
    address: '0x34EB6947F57389239c1337949eA33B0906cecbe1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.36894145432905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.37,
  },
  {
    address: '0x0B223c421caEFe73812893F387D308Eb7eb0B8ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.22742054834029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.23,
  },
  {
    address: '0xFAcEc1FB75b65AAB04d6A7fB8AB50790A8370ea2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.00467388989391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93,
  },
  {
    address: '0x45DE7c6c1f8263B48693Fb6F4325C704bc1DED49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.95115066686515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.95,
  },
  {
    address: '0x6ea667Ac3e7b84416741a14d1435FeE5a60d8FaE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.68350769780024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.68,
  },
  {
    address: '0x0Ff4c489F379218f6a49788ddA641CF33deB71f6',
    minters_reward: 0,
    lp_providers_reward: 80.62,
    swapers_reward: 12.052982626240288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.67,
  },
  {
    address: '0x0D84d6Dbb26bd12760Aa2E849f0DA259Fe1f2Ff0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.61722152334453,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.62,
  },
  {
    address: '0xE99c2323953A25fCe68c522A613f254D485c537d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.3999896785243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.4,
  },
  {
    address: '0x9b78d534CF7909422Be0d3D893D7F75E2D36D58d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.16976344287374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.17,
  },
  {
    address: '0xeb93Ec49db0Eb825566460bA2a0ECDD160eFc652',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.93210508867165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.93,
  },
  {
    address: '0x4D3fcD28dbd3Daa51c89757B46BF12B3A0291A16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.75978010952437,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.76,
  },
  {
    address: '0x35cDE99FEeEd2c0A7C0CdB108B00b032e525ceF5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.63680774380269,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.64,
  },
  {
    address: '0x17761f02157baE2662Ac48530115805d83F87d83',
    minters_reward: 0,
    lp_providers_reward: 91.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.58,
  },
  {
    address: '0x9e3B62bADbD8a699C27409627007c9fAB0AF71A7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.46449484753671,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.46,
  },
  {
    address: '0xfeE8f0ad71b37244a1F1fF10b0A5B134FE3611bf',
    minters_reward: 0,
    lp_providers_reward: 7.15,
    swapers_reward: 84.21104132689436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.36,
  },
  {
    address: '0x2fbA18FC091Bfa821D6c75c69a75De4c3b7Af8Fa',
    minters_reward: 0,
    lp_providers_reward: 78.49,
    swapers_reward: 12.750195370845992,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.24,
  },
  {
    address: '0x3bbc7Dd3B57Ea725985BF0dA33AdDE731F4C7295',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.11827319221378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.12,
  },
  {
    address: '0x134fbd1249E0515f175046dc1D32F6F9A9C8311a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.73405418770355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.73,
  },
  {
    address: '0x989F2BdaCEc665d696687C023a170153FD23B77F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.54118063239325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.54,
  },
  {
    address: '0x70D3459D5Affb269e4DAC084e8866cc86d201Cfd',
    minters_reward: 0,
    lp_providers_reward: 90.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.46,
  },
  {
    address: '0xfc082E33ef655fEDE2EA4dc341CB950F6B7f3E1b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.4456682215605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.45,
  },
  {
    address: '0x32557753C18ab6Cfc7f991Fb327F7BB0a70FBC9E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.31387917781122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.31,
  },
  {
    address: '0x5CDc503966f6863b8Bc4798265239C2B64557D4c',
    minters_reward: 0,
    lp_providers_reward: 64.27,
    swapers_reward: 25.98222397534441,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.25,
  },
  {
    address: '0xf3e427a1E67D87d63552D6123C53d812d7E931d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.16356246909093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.16,
  },
  {
    address: '0x1F427546b7af7E71099D8292DE7eaef414d1e743',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.12663157038142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.13,
  },
  {
    address: '0x71E0b4EF67145e78892f42be14617bb5a8D50ae7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.0347104636487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.03,
  },
  {
    address: '0xF31e8b7c8a5A219B9057295Fa0DB71802aDE22dA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.02982656720032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.03,
  },
  {
    address: '0x50f588eEe5f466eEA0B70Ba47D60DD9b49CFcd78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.88729101340175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.89,
  },
  {
    address: '0xC31BBea1306004F99C4284F47AcF5cdeeAb5CA84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.84350847828242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.84,
  },
  {
    address: '0x6de001c7F010caaEd07D7f683d288D68e1806d06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.7552262231908,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.76,
  },
  {
    address: '0xB74d8D9c33e6ACa894Cfdf7582376acF91C9b389',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.54248051285826,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.54,
  },
  {
    address: '0x84767Ad8FA20cD8b07f7b3264E3323BcaB774B6D',
    minters_reward: 0,
    lp_providers_reward: 79.88,
    swapers_reward: 9.532090130553039,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.41,
  },
  {
    address: '0xD363807b84697aA60d060aac90239701CaA8d3d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.33846419248808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.34,
  },
  {
    address: '0x54ACA9daD8057234F6ad28B5a2ED7c99F0D5063f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.14133103461742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.14,
  },
  {
    address: '0x0CCA837d6892B36Cda7AfCC12E54d9597825415F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.64283562015004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.64,
  },
  {
    address: '0x806372b31850D9181e4A73f617403e6fd3222f74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.54767618532428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.55,
  },
  {
    address: '0x3696771337598cC13b4c5Ff77B0709EaB968f5eD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.41645400006458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.42,
  },
  {
    address: '0x5445DA1D4D563371998D087181F0f56aC63131e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.31051795131414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.31,
  },
  {
    address: '0xc4C83EE804ebF9AC53BAc3d46613a9b273077827',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.25453179020491,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.25,
  },
  {
    address: '0x08363609F2370936691Dbf14833d2B054608be39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.1259062145154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.13,
  },
  {
    address: '0x1d4bB43c664257aC0527AD9cB791cF7f135a2D0A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.01378595288477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.01,
  },
  {
    address: '0xE691a0a6Ca7e89745aacB1355d768cE0769f10ED',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 87.88052633900777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.88,
  },
  {
    address: '0x26aA35b202601A288142ee3dbEF83EE6b10f8e8f',
    minters_reward: 0,
    lp_providers_reward: 87.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.86,
  },
  {
    address: '0x9F1DF012431eA29B076417Df5979989b7D7c37bF',
    minters_reward: 0,
    lp_providers_reward: 87.05,
    swapers_reward: 0.6709664414264943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.72,
  },
  {
    address: '0xfaD49fc950015934d8598a39B3a5eb622775a57c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 87.50845601768495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.51,
  },
  {
    address: '0x00e28eD6831019ba5Cc0c3a75463FbFF9efB2Bce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 87.48858056669378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.49,
  },
  {
    address: '0x53E74d667117CE268913dA4F3ca791Fd5b62c9d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 87.1301184782301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.13,
  },
  {
    address: '0x58a2B6F883B060eC8371FeEAc02Be8D4c889F0a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.96916408041052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.97,
  },
  {
    address: '0xd844dA86713E5031e14Cf2900e30e18151552BA3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.82824135776535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.83,
  },
  {
    address: '0x224D2bDE4114538589515EF7b3a4295d0430D44c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.8206427381474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.82,
  },
  {
    address: '0x1a89D1F83a7f68CE689c13D3B8eBD17aA4808966',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.64659454547018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.65,
  },
  {
    address: '0xA54D94b20970BC8DE0fA5F7F6F68F681113c743a',
    minters_reward: 0,
    lp_providers_reward: 61.34,
    swapers_reward: 25.07260530789994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.41,
  },
  {
    address: '0x93a0e7c1a19EF1169c186A04E234D71232A3d7E4',
    minters_reward: 0,
    lp_providers_reward: 73.43,
    swapers_reward: 12.867144200578732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.3,
  },
  {
    address: '0x2b83f65570ff488bEb9B5A9575241471b2dE8ba6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.27551389670127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.28,
  },
  {
    address: '0xE6C15Ec355BaFe943233b8bcfC75f4bEF9189412',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.26519641434673,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.27,
  },
  {
    address: '0x2152c4B93C86eAd03AB44A63C4147AD1e6152604',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.23320878667353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.23,
  },
  {
    address: '0x24d10972F53E467c1B152589b9E9e5721195635A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.97763037980091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.98,
  },
  {
    address: '0x5dF29C3748E1c94a2c86A47C0e68b3E0253dB435',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.91951623995182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.92,
  },
  {
    address: '0x6e8508c2bC228E4b67c55B09D6F1aA332d9a35df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.7865243736523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.79,
  },
  {
    address: '0xeb1563E77629A757aeFC4f4E2D4Aa105A9d87f28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.7186273101461,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.72,
  },
  {
    address: '0x68035eE240024B4bc1080C24A266b792c9eB24c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.66087240514565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.66,
  },
  {
    address: '0xCF8E79E5FBA438e67C9f8Aa9c6D22fbf95E67fAb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.50969947987566,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.51,
  },
  {
    address: '0xcD8d79F3479eEd38b5598c5D390B5677206AEA35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.50510570961745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.51,
  },
  {
    address: '0x755857e2A5Dc2706fDa329E690c0fBAE0d8B3B28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.87022167174439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.87,
  },
  {
    address: '0x9D7aD2D86beAE9F82F35B1F3FA9A246a5e6FF7FC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.7901155023253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.79,
  },
  {
    address: '0x138610EEBc312EA632f649b04Ca5B0334A63A104',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.60571189485336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.61,
  },
  {
    address: '0x1E222B01C3A6666028869487D9d6B7fCd2959f63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.55171148543296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.55,
  },
  {
    address: '0xF3f9aA70B0a4745e6E917d7eD3faBfE5f5C2bFF6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.51578372129597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.52,
  },
  {
    address: '0x1A70644ae6C1BC93383E945C14B2798672AEd2A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.50866916444642,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.51,
  },
  {
    address: '0xeEc0591C07000E41e88EfD153801c3fc0A11f7f4',
    minters_reward: 0,
    lp_providers_reward: 84.5,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.5,
  },
  {
    address: '0x3736D31E2aB4e8Cd744709528C7b40d0CD29e079',
    minters_reward: 0,
    lp_providers_reward: 72.14,
    swapers_reward: 12.279363419384065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.42,
  },
  {
    address: '0x8555782b02C2a24CFE0b107273a41aD9c4718F6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.34953341409656,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.35,
  },
  {
    address: '0xc493f754ef5c8B6cCe939dBBEE5b78D25Fd5167c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.32370877386825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.32,
  },
  {
    address: '0x53e5EcEFfA53A7A37a528b4310D6045668cCa893',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.27122898100228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.27,
  },
  {
    address: '0x945255D8c9394834FF002fb02DDE05B10Ec370f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.14582428667914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.15,
  },
  {
    address: '0xa22d0d55bcF894997BbCFe5cd6bD78a8C81Fd3dE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.09950630861262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.1,
  },
  {
    address: '0xc75A3875f881Dd16Ab1118067eB2CBe2F213869c',
    minters_reward: 0,
    lp_providers_reward: 84.03,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.03,
  },
  {
    address: '0xc731d04A647252aD2CF9414cD49d3D967c78e959',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 83.94390602806558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.94,
  },
  {
    address: '0xb32F2FB959C29286E300c1e506601c3dBAaB2ED5',
    minters_reward: 0,
    lp_providers_reward: 61.59,
    swapers_reward: 22.136220748780218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.73,
  },
  {
    address: '0xe668d27636A844c61557F820F712560A27fEE835',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 83.62931219956175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.63,
  },
  {
    address: '0x6873CBCF73EbC8108d76C5cE91BDbb5b8cE71De5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 83.43533828277019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.44,
  },
  {
    address: '0xa090b9Dc4DEC4137fd6B478c93354D6cc1e2821F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 83.2146979089365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.21,
  },
  {
    address: '0x9ab35EA056DeD6faf94C4a467b55a157360D2E83',
    minters_reward: 0,
    lp_providers_reward: 60.04,
    swapers_reward: 22.997340006966937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.04,
  },
  {
    address: '0x796dC4DA7dFe4BA544408a750AD88e2C1097F22F',
    minters_reward: 0,
    lp_providers_reward: 19.15,
    swapers_reward: 63.870514756654245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.02,
  },
  {
    address: '0x2CCa2F3831B0856b1D28e4BBB277A08d65A049f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.97009138978459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.97,
  },
  {
    address: '0x096aD3f43548D94BCE7180608dabA54468ee42b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.94448249323649,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.94,
  },
  {
    address: '0xA3Fcc3958950035d6bb8b46015986118729Fd70F',
    minters_reward: 0,
    lp_providers_reward: 28.35,
    swapers_reward: 54.59461385103411,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.94,
  },
  {
    address: '0xd3e17F5035b5d6bCb22e8Ea7e30412068DB13C42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.6106109557687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.61,
  },
  {
    address: '0x2b4804E01C793B9Be4b6026758137c4b5321aBDE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.60076342512004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.6,
  },
  {
    address: '0x16A5Fe972606A8418adEb9dd33B9A6d1A49ff6cf',
    minters_reward: 0,
    lp_providers_reward: 69.99,
    swapers_reward: 12.488460808227854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.48,
  },
  {
    address: '0x1000aC547AdB0719450cC16074bbc2794FC31DeA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.09212532739089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.09,
  },
  {
    address: '0x6E3500c2E01Ecf16232b283d7D72f6513A7Bb0C9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.03109856570774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.03,
  },
  {
    address: '0x1B169E7b1D625CA011D7398ff6430543185d3C20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.01380915204606,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.01,
  },
  {
    address: '0x1A1947318d09199C6c198892072b1d0F2f306F87',
    minters_reward: 0,
    lp_providers_reward: 81.73,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.73,
  },
  {
    address: '0xD86f0592A0CA9DbB5A5FD26E2AfbA02798B2eB9A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.56186554133245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.56,
  },
  {
    address: '0x5607A49eD17DbA51B397F6444Ea5da50E6d7d9d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.41686785574062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.42,
  },
  {
    address: '0x4EF002f23361EF9D551717baa36D5AdD5F5E4A8E',
    minters_reward: 0,
    lp_providers_reward: 81.12,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.12,
  },
  {
    address: '0x14d10e269e9A99Dd9136465C91B532eE40961C3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.08488811501033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.08,
  },
  {
    address: '0xb0Ef2cDD580f2e89240574334502B850966db63A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.02634867735183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.03,
  },
  {
    address: '0xBb2770C61be9e56402B123D6F7AA53e06388B4F9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.94407142408366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.94,
  },
  {
    address: '0x31bf0D1F8a16ae27E1d36A9c0dd437a7bad6E38F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.78064377772564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.78,
  },
  {
    address: '0x625df685B09333d90B07840267413B87C7f53183',
    minters_reward: 0,
    lp_providers_reward: 80.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.68,
  },
  {
    address: '0x23388046d46c4cfd88a31a1bEd9570dF4C34051E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.56137282348094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.56,
  },
  {
    address: '0x917192B50d9853306Eb9315f9405b745beEBFdbc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.55575673605476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.56,
  },
  {
    address: '0xcF0176644526c385889a30075Cde91bbF6824Bfc',
    minters_reward: 0,
    lp_providers_reward: 78.11,
    swapers_reward: 2.2888765935362407,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.4,
  },
  {
    address: '0x90c2b12b0aac00700af549aE7cC0C33985Fa3063',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.38894161584739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.39,
  },
  {
    address: '0x0Bc401BDf36cB7336580C3BAd25418e70A8bf9Dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.37285376344951,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.37,
  },
  {
    address: '0xeC3185Dc088dbb28f5cB540a812Da28587EFd499',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.1897859059739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.19,
  },
  {
    address: '0xeDF3c6376FDD3a4cbc9B8356659102f8aB17fA18',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.01407497791848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.01,
  },
  {
    address: '0xD1C102E226f1894866D4e8aeBFaFb5239331e58B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.85022727159946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.85,
  },
  {
    address: '0x7ab6C290FF315C0Dd35CD6C84AEdc9BcAb1B6403',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.78943045214962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.79,
  },
  {
    address: '0x37c94DB29398c0c28147a4Ee1bcccBBCEc4bAe9a',
    minters_reward: 0,
    lp_providers_reward: 48.76,
    swapers_reward: 30.882204280764952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.64,
  },
  {
    address: '0xAb9F01F85973a525b49eDdDf1e3aAA6bf660fA53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.56074885522142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.56,
  },
  {
    address: '0x2F282352250e5972CF6774739e88C7E1121f7834',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.4594717378095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.46,
  },
  {
    address: '0x6bCfAbadd1910C62eB3681ADE2d8F16f25571B6C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.4436539016022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.44,
  },
  {
    address: '0xf2b7F7e47E87f4a8c322a0F495d2940E78202663',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.21568124022392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.22,
  },
  {
    address: '0x561F3D1d36599caA326F983305FC74834d5E4A0e',
    minters_reward: 0,
    lp_providers_reward: 79.21,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.21,
  },
  {
    address: '0x2CaF576C5e2781542c65fE2D07EDB176B7DB55eC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.20288805843823,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.2,
  },
  {
    address: '0x8A53DFDf9614d5E78784c3745AF78F4bBe58F800',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.1375575155869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.14,
  },
  {
    address: '0x50224851F49f508418734eFd23919D1DC45f3c4A',
    minters_reward: 0,
    lp_providers_reward: 11.64,
    swapers_reward: 67.34411991924176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.98,
  },
  {
    address: '0xD3b12401a8D772b21EAD56d294f3E935491Cf6f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.80991240176878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.81,
  },
  {
    address: '0xA763C828EC16FcCa59b22DDd53FDd97553B59056',
    minters_reward: 0,
    lp_providers_reward: 35.51,
    swapers_reward: 43.23847084451053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.75,
  },
  {
    address: '0x3f99F9Ba950887648bC340Ab064FDCbBA38398Ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.64112211590404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.64,
  },
  {
    address: '0xdccA2f4C10673c4Aa13B1B7ab820BCc8B66Cbfe8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.54724461286176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.55,
  },
  {
    address: '0x7123E511cFc413FFA9868895e59093B12819c1E7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.48753277254525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.49,
  },
  {
    address: '0x5408e0E31f6CE0E69aD049456512B18Ba9929AA2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.31567884103002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.32,
  },
  {
    address: '0xE35A4eF33104a5B725090752dAdEd78029bA215E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.28497803900459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.28,
  },
  {
    address: '0xaCFA6Dc6f46cAe16573258144E4824e589eae925',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.10032245531482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.1,
  },
  {
    address: '0xA5a67a098925f5eeAbA2BE266d0104553F8B07df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.09597034333531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.1,
  },
  {
    address: '0xb770e1c1d2BE72Be1A8E3e6763f9c8aD540764e0',
    minters_reward: 0,
    lp_providers_reward: 78.09,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.09,
  },
  {
    address: '0x2359f06c3dC523fC03E9f449E69711c0D5fc0a66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 77.67776587447459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 77.68,
  },
  {
    address: '0x87134cC388674E6FC3bFaFdEe3F19603C6818a5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 77.48908694514024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 77.49,
  },
  {
    address: '0xc9a8a3CDbAB8Ba9C31967cb5E6aB5BC14C143Bcc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 77.48261876702834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 77.48,
  },
  {
    address: '0xB3540f40d7429113042dc961c64B194450f3346B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.9643529063322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.96,
  },
  {
    address: '0x106139748408aa6fa8B6c5E21361997f1001fF84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.94307335815547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.94,
  },
  {
    address: '0x4d9Bc3719A5F555EeA0bd22a9D2387c28745dEED',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.89594019074151,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.9,
  },
  {
    address: '0x80fdb88B545c0468A8af653bcE09B1E8d8788FA7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.68453119768218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.68,
  },
  {
    address: '0xb93D160f1800f7DDec1E575927b0dF89401a3936',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.55662450681938,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.56,
  },
  {
    address: '0xa7810019607F043B1e2304EAdC5aA061D47B26fC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.52969320002657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.53,
  },
  {
    address: '0xBc5eBb0aDA6F22B3cd9AcdEB2Bb29f2f6a98C6b9',
    minters_reward: 0,
    lp_providers_reward: 76.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.39,
  },
  {
    address: '0xcC71a71D07a09d4C71FcDFAd8C7FdE3863f98E8A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.19118364974254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.19,
  },
  {
    address: '0x5B627F46F9D350745f4b82A9D004E33D90662266',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.7502713887215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.75,
  },
  {
    address: '0xD9062e7Fd65A53cA74FbD1b0f4c4C8bCF9255CC8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.65592926480522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.66,
  },
  {
    address: '0x2D93c6a0F1071fe8763Ce1c31c07eeB54353e405',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.24592854723693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.25,
  },
  {
    address: '0x402b828671da14167cc570478bf827ed5B707b88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.21955660120267,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.22,
  },
  {
    address: '0x4AD7f30C7F4c878b395D76C160ad1673Bde73447',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.14366691960636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.14,
  },
  {
    address: '0x5724bcA01054E1Ad242E67a4b700Fd208c51e3bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.09779420436364,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.1,
  },
  {
    address: '0x3eCD6D2674e82FF9227022CFfa24854Ea45b0976',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.09214510704246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.09,
  },
  {
    address: '0xe83584775FCA91CCA226d5A145493da333A31bED',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.08310449931369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.08,
  },
  {
    address: '0x7Cea440B7dEa1405Ec2B4A6B1732755D914E7c4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.99116131788283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.99,
  },
  {
    address: '0x77E18355BB9AA0A9e165dFEF33de295bC73FD3Da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.92160449138917,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.92,
  },
  {
    address: '0xDCF83357074d41B43ECeC636cdae47a0b5fd063E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.9068284189645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.91,
  },
  {
    address: '0xF71116868a114D1D4e957C608e405e69A23FD663',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.89837965178832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.9,
  },
  {
    address: '0x1dB52CA38C5305505117A88f3fa4Cdb321B78556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.7969833143571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.8,
  },
  {
    address: '0xD03488092bdD7a98C5E226a366767bC734C6e475',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.54299894507879,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.54,
  },
  {
    address: '0x08539F0fb45Ad907eC89ac1D1e91d2CE25f65094',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.44778188691387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.45,
  },
  {
    address: '0x9eBa1Cf562Aa9A2E3EA43DA99D797E8C0ba49dd2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.16000505865098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.16,
  },
  {
    address: '0xDED6020Db9bEd819B5C8a2D0AD0d5d0615daDf74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.05829444454803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.06,
  },
  {
    address: '0xCB813B7548aF55136E24087ce9bf10a21C012547',
    minters_reward: 0,
    lp_providers_reward: 74.03,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.03,
  },
  {
    address: '0x20B22295c30a6b88b71c27fc79DB6A5f2d29ACa6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.750601617209,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.75,
  },
  {
    address: '0xA64CBA4FA76673FA48Af9269DFB86cFB7faBf737',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.58461062189147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.58,
  },
  {
    address: '0x416C9E906374153C3cD6bE469bCb9C1720b6ABf9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.57091717895001,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.57,
  },
  {
    address: '0x91Ed62877dcBe420cabC87d395c9aAf6bC9edC1b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.42654673559052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.43,
  },
  {
    address: '0x2Fd9bDdE0FEA6De3f7CFAdaF4FD8e853A5119fa3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.36197873366204,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.36,
  },
  {
    address: '0x07cA14121c108031f5497A554a357206920Ae233',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.30325988092939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.3,
  },
  {
    address: '0x767bAf522f22eb8828698d574bF97b9502ecA9F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.27039554875533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.27,
  },
  {
    address: '0x135BB51134f499163D9763240Ebd8fde043534e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.14959952689767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.15,
  },
  {
    address: '0xdA99f4cA0cC3397E4839f9492790c3925f961648',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.05490525174139,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.05,
  },
  {
    address: '0x1d6b00BD824Ffc027de7FDC42F779Cf1D1d8b004',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.03791698236142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.04,
  },
  {
    address: '0x87ED2C0bfF7C33eED7D4b2c8FA42E42de306D0F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.02402118667835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.02,
  },
  {
    address: '0x09Ff98337FFB7893d2ab645666eFe52d9F2c935c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.9712638898647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.97,
  },
  {
    address: '0x7C4ff2c171Ad8D669b4814D41C28b82f546727A5',
    minters_reward: 0,
    lp_providers_reward: 72.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.9,
  },
  {
    address: '0x07e42D1e328cD4281181dBE4fb54A30d15bF380E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.30103984712726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.3,
  },
  {
    address: '0x795B239Dca632ed53f4Aabaa6fa37791C2480Fce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.07040739481279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.07,
  },
  {
    address: '0x570CBCBa146CfcDB271087EB8A8f3e93b08db912',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.06774051645753,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.07,
  },
  {
    address: '0xEc3ffD0f25fAFda3D94a817ac61e7a1447f0e18F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.03852116073463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.04,
  },
  {
    address: '0x3711F721852D5cb0Db99265e15A3dA5FbD6cE086',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.96814637474358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.97,
  },
  {
    address: '0x27cd6bbB44efc8E8f0FB7215d724d68a44376a30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.93170348132976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.93,
  },
  {
    address: '0xe1E2EdA97060717C5bc4314984E9CF9Ddfa1EE0D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.73181998358555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.73,
  },
  {
    address: '0xe1a2f9944EAc71Cd5c285d92cc82161b01a1b366',
    minters_reward: 0,
    lp_providers_reward: 68.73,
    swapers_reward: 2.9886487452789057,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.72,
  },
  {
    address: '0xe48F1c83dF4e846674aDC6d365D750Bf4004e7a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.68779659791862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.69,
  },
  {
    address: '0x64C5503B01e6cCC451687C5392068a0bdedAF959',
    minters_reward: 0,
    lp_providers_reward: 20.39,
    swapers_reward: 51.24990286249706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.64,
  },
  {
    address: '0x9e7b0f95d369A5b7F75150A439E33D2a802098dB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.63198500474401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.63,
  },
  {
    address: '0xFd7b7d7fC92314e1dE347755E1C20CCE4D5Fb9Ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.6299432280307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.63,
  },
  {
    address: '0x4528d65eA9c5Fefb15E7D412ae137b061C447576',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.46703510245517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.47,
  },
  {
    address: '0xEcdb39D546CEfD5d12b8E5104CF241f7EA7744d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.45942735124717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.46,
  },
  {
    address: '0x8b279E231bc97fD644da2acF48463E19D432cBc8',
    minters_reward: 0,
    lp_providers_reward: 43.19,
    swapers_reward: 28.13645779075734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.33,
  },
  {
    address: '0x07465f7a17AaFd010b91c883Ca40743ca7154EFA',
    minters_reward: 0,
    lp_providers_reward: 70.65,
    swapers_reward: 0.6586916462385263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.31,
  },
  {
    address: '0xb7c89Eac464AA44922f5f2A3b5ac20BC4423Dfe6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.24641960372473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.25,
  },
  {
    address: '0xF7c06E6f7357d169B1542EBE536d1c6153125BB6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.1703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.17,
  },
  {
    address: '0x82337cDBaCD042Fcb5Fc5bFD0a1A37AfE471f97c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.09915133641164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.1,
  },
  {
    address: '0x2ECCFDb454dD6B8c95A012d926B515Ac96353a24',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.03613366259897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.04,
  },
  {
    address: '0xDd75929127FEBB087f849665528BeDD77CDf5508',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.00004072473946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71,
  },
  {
    address: '0x6476683f00317Bee0837DaCC2D7d46e82D25F5D2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.87005350584052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.87,
  },
  {
    address: '0x38efA958E10A0634CE05a2B2914e13D2f92D3678',
    minters_reward: 0,
    lp_providers_reward: 14.97,
    swapers_reward: 55.84274608318668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.81,
  },
  {
    address: '0xE2042DAfA10A0d086e9419Fc7136bE5CAF8c4457',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.78827881317652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.79,
  },
  {
    address: '0xBe4E6F733Be757b299a2874DfD17674818FEd8ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.75851466471147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.76,
  },
  {
    address: '0xaA46238993d13a5F5124F3D401aEdc8fCFf708fe',
    minters_reward: 0,
    lp_providers_reward: 61.8,
    swapers_reward: 8.822687111437617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.62,
  },
  {
    address: '0x85F5c6F21dD228290F7E6235A6a612a00D9af63C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.50648037080423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.51,
  },
  {
    address: '0x0DB15E2b5b77F333d12b9BFe7e7a70a5399f52aC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.38659616123122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.39,
  },
  {
    address: '0x539572797b9383a3d55c76a4E6aa993042C0c599',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.38401600079017,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.38,
  },
  {
    address: '0x833Cfe5274eeA9E7C28F40F0339e74C6B9e6dE7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.20503439104264,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.21,
  },
  {
    address: '0x8EB1148b30e1814c770b4312F95a1E03755a52B8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.96860054126641,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.97,
  },
  {
    address: '0x368349b82a08CCB144b1195a7BFE0932D932999d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.95723132041354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.96,
  },
  {
    address: '0x830F0E9B842750C72851b8Aee9cAC1b887060936',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.69292148850306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.69,
  },
  {
    address: '0x49809cc7092f0d7731EAFD7ead4442573425Cf58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.69292148850306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.69,
  },
  {
    address: '0x0796725B77A560B83F072942d382106c892ABCDE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.69292148850306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.69,
  },
  {
    address: '0xd04C6CDE3e74D77b5B3C9E5481511B8C2B55c976',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.68842543644902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.69,
  },
  {
    address: '0xC2151f7ddD92803fADfEfFca01396231c519f293',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.59693171667915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.6,
  },
  {
    address: '0x407457A031ee3057a55E509F2018174889DDf73E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.2016384396954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.2,
  },
  {
    address: '0x0Fec7a1aB1155A466a297ECf0e8B5722fEB713AE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.11452095520876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.11,
  },
  {
    address: '0x4Fb6FA1E0F0848850f116b8A229b41fd53888A7d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.04781270509862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.05,
  },
  {
    address: '0x3e7290D6F5Ee3deB53C35d6656F82AE9e0bebb69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.02320947368409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.02,
  },
  {
    address: '0xBD20E46C85201E1613023b8a8B43b314ECfd1c93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 68.61680889017502,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 68.62,
  },
  {
    address: '0x8be0E213155De86A2B5b4FE6678Bb6E2241badda',
    minters_reward: 0,
    lp_providers_reward: 51.46,
    swapers_reward: 16.99604070138833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 68.46,
  },
  {
    address: '0x27E09006dfb73B643c5e067Fee4192a46Ee5bE7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 68.28762372422071,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 68.29,
  },
  {
    address: '0x87E731CD872C38648b24229dc173844a53Fb66c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 68.12846109916973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 68.13,
  },
  {
    address: '0xd3DeC7C8765fa780E2e7092ec767911c7F82CFC7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.98507972034975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.99,
  },
  {
    address: '0x4E89a6CDd0b13610528cBc37B20AC33d2113471f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.94877204433368,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.95,
  },
  {
    address: '0x8035664870BdfA63cc973a2E8A9740aF2a2bAa68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.86795604026389,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.87,
  },
  {
    address: '0xFaDfd2B8B98FCE100f4Eb8739741220D1D877820',
    minters_reward: 0,
    lp_providers_reward: 27.59,
    swapers_reward: 40.27365996650692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.86,
  },
  {
    address: '0xbC3fe5EcF4e355AfA81f34A43BB29F1d17AdCe43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.72226540009842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.72,
  },
  {
    address: '0x67D60fbeD3035B206F1a5BE78F1b2107867db6a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.72226540009842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.72,
  },
  {
    address: '0x473cd078f1E14B66fDDcE76dBDFE8a974154A57c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.7222654000984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.72,
  },
  {
    address: '0x702337d657c7aab31F1a07340A212EB9aE420B30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.63180461775677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.63,
  },
  {
    address: '0xA9EBDf96f0a8D4886F3Ef3A5d0f4485444c1c9F9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.54600268038887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.55,
  },
  {
    address: '0x2769B845D94Cdfb5cB6E9e2E6A641d22c3fBA1f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.51066541502183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.51,
  },
  {
    address: '0x58782281De8D0cD4d4116C49F8e23397eF7eEDb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.46957346476697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.47,
  },
  {
    address: '0x5795337839759103c57cb7596DFEAdF9157D3561',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.45212173602003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.45,
  },
  {
    address: '0xB7B5A65c94B910A57aa12386180DDac6Fe368668',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.39575306688982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.4,
  },
  {
    address: '0x44d7a5fDacA09FAb012f2eaEd2Add517D2b49C0e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.334836862385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.33,
  },
  {
    address: '0x705032D398791CAE3a3486512656f2B9C77D8A69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.28481627030803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.28,
  },
  {
    address: '0x6232b52872748dcd7ED217AA5aFd5b49a0D0a5a8',
    minters_reward: 0,
    lp_providers_reward: 40.58,
    swapers_reward: 26.520214030317696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.1,
  },
  {
    address: '0x332c82949AC440847f47a5207a7E8e191Ce26A27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.00779387608951,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.01,
  },
  {
    address: '0x7a619e10632ebbD6f627EDD8E67D7BBb777f8da1',
    minters_reward: 0,
    lp_providers_reward: 53.94,
    swapers_reward: 13.014245186439497,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.95,
  },
  {
    address: '0x9c8192EB2b46af8A4914Afd1dacEBf6F37746C57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.76559953717826,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.77,
  },
  {
    address: '0xB91700c6BAAA217917349Ae40A2649236f55dF3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.67624819043343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.68,
  },
  {
    address: '0x8e23691d0B9E6BC55300249b3e134A25A8ed98BD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.51440490238517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.51,
  },
  {
    address: '0x94a489e255D0c5771E6BE54E7655b0dBB813AcF7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.34986782560104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.35,
  },
  {
    address: '0x43C1C8fc8b3AFC90c8b44dd0c328aef10F446e60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.32843906839477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.33,
  },
  {
    address: '0x5D244E23C6b071b410B8b993F58F15Fb2683b84B',
    minters_reward: 0,
    lp_providers_reward: 34.35,
    swapers_reward: 31.82870722397851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.18,
  },
  {
    address: '0x1690fA67CbE0e5a9e868C4AFB74a5490AC09a8f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.14154738907031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.14,
  },
  {
    address: '0xD405eeACcf2336c5a54C50b7fB63241E85379da7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.13957670782679,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.14,
  },
  {
    address: '0xfDe66F24b190ddf5924F9aBD4ADc9D7119A0EC71',
    minters_reward: 0,
    lp_providers_reward: 65.7,
    swapers_reward: 0.3323759519151724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.03,
  },
  {
    address: '0xe95196Afad667Dacb36093563eC5F556180b6f7A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.90817500592931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.91,
  },
  {
    address: '0x8f4624E489E0A809B74e619f13688Bf5355DAA7C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.90817500592931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.91,
  },
  {
    address: '0xeeA2fD517A4eCab4E48BceEd39732a3b62b1b46b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.90817500592931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.91,
  },
  {
    address: '0x9103D91e3AaA96d8f5D255E3C826E880868B333a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.82661120026046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.83,
  },
  {
    address: '0x1cCF911F92E8cBf485Ee1978b3481ed366674302',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.77275025145126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.77,
  },
  {
    address: '0x4ca17e119eDCEEE7e9737fC94eE0D41d04EB4de3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.76647005324568,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.77,
  },
  {
    address: '0x47aCf9688D0eC49238218279DADe696b929bb37e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.75281036388272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.75,
  },
  {
    address: '0xD14CbBE8DA07Bf1A67cc72deB3192dA52F59c5eB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.562836903493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.56,
  },
  {
    address: '0x08eB3BE5f2EA980c70C19E9a541bac6Af4c22Eb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.53439569154531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.53,
  },
  {
    address: '0x12463A6b6572425ffe0c9FcE713dD179959941Ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.52239081470022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.52,
  },
  {
    address: '0xb6117A4883c408c356Eb2A75bE3a046617fe40F2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.48947144142181,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.49,
  },
  {
    address: '0x2bBeFbF621ABd01C9B98c2dEdfF3E6821Cb48AD0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.42923793680546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.43,
  },
  {
    address: '0xFA87fF809e17331b0687d775d005c3Dc2977DEA4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.32877410702099,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.33,
  },
  {
    address: '0x1F4946312574cd1ae9C356190f3a87c7b38FB658',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.31193404582615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.31,
  },
  {
    address: '0x61171257b0627211885bb199D3C69190131C1916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.27099804309026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.27,
  },
  {
    address: '0x48392652402694C84dd64b6FD99B6E99F52014f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.18813189428363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.19,
  },
  {
    address: '0x23916b0ecb4c410cb37de08BDabD2c17d5b54B94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.16052668095625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.16,
  },
  {
    address: '0x7b633140f28A28dd6d78F12E9f3b93261d3888cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.033231608235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.03,
  },
  {
    address: '0xD2479467a5c4958870102204bFe9e682630E1b32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.01337478409447,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.01,
  },
  {
    address: '0xA99dfAb40DEB03951cc7116F134fc316F398534A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.0088442790214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.01,
  },
  {
    address: '0xCda0d390EaFa4011A626d8Ba0ae77478f4706521',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.98281867121725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.98,
  },
  {
    address: '0xC3c5f74a04F2F2810CB0BDA37A1236623FDa363b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.96861181820942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.97,
  },
  {
    address: '0x84b82c36a01e3f9E6f4508a4944D54038e7c2148',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.9255181539372,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.93,
  },
  {
    address: '0xBa9a44E838e127F62481358287e7c9b25F806691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.87352662309901,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.87,
  },
  {
    address: '0xFAA9a5c79275615bE6EDFa5a3461dB2Fe7798aeE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.8211930508179,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.82,
  },
  {
    address: '0x763683e2524a303D3c173A97d9D9e98B664Da5b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.74959174432158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.75,
  },
  {
    address: '0x6B2767A8b2C3A171552b8367A0653C0926f17F62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.74301279270595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.74,
  },
  {
    address: '0xF7cEbc7681e76Fde18f10bCE640790b2F5E7B93D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.60406145055774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.6,
  },
  {
    address: '0x9ba2Eb094BD23b8a9CF0fdfC38181A85ABDdA016',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.57973233413495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.58,
  },
  {
    address: '0x9246525219ef60704020d5a9E12330379fA8b556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.40769665832975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.41,
  },
  {
    address: '0x77FcAB3D842E4f3cB5FAEe3Ba9d538F0be4363e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.36665978353953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.37,
  },
  {
    address: '0x9A52f291c153ba947A6FD4e997A97BBE9380939e',
    minters_reward: 0,
    lp_providers_reward: 52.69,
    swapers_reward: 11.59941371063135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.29,
  },
  {
    address: '0xB54acd00068adF055B6B54d0337A03790E665D15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.15950956103472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.16,
  },
  {
    address: '0x2B1C1222EB359f673bD0ebf23FcE5A060DC0C67A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.04806808141149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.05,
  },
  {
    address: '0xCb6A8782227FFf9b3a338487B1E61E6FD3c171eD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.9878446711616,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.99,
  },
  {
    address: '0x1Fe6Bf6B0160C4A0d7734cb36754D2Af87e9e579',
    minters_reward: 0,
    lp_providers_reward: 63.99,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.99,
  },
  {
    address: '0x3AA697450A735914D39cf282d702c442aD72E36B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.9823088968968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.98,
  },
  {
    address: '0xb41A027C0BA9553F71151B053b4dF6a4018f1795',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.91311006473292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.91,
  },
  {
    address: '0x21F472C8A492b5971F830fA01a9fBaA7f0024a49',
    minters_reward: 0,
    lp_providers_reward: 63.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.9,
  },
  {
    address: '0x416f34Bf0Bb568856F01d146a17c241302551D7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.89210939265602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.89,
  },
  {
    address: '0xd06C591F7D82268c99645e024d372ac1ef4e42a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.809116921489945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.81,
  },
  {
    address: '0x0A4D5CC681a6F08f8feebfaF921D517e2b8B94Bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.720108412564294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.72,
  },
  {
    address: '0xFC21EBf61784C43Fe0Eb10944c77f0bD1aF79f6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.66886091612274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.67,
  },
  {
    address: '0x84571609ff535DEc645Bc6D8Bb6Ae33C2148951e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.6122945055098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.61,
  },
  {
    address: '0xd52aeE97E9424cfb7Ee9FD6497c4b0a62db4a43c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.53621709174972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.54,
  },
  {
    address: '0x536e319880AbDAd53a5CF2365F9fb3fBbF6971bF',
    minters_reward: 0,
    lp_providers_reward: 47.36,
    swapers_reward: 16.148996899056684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.51,
  },
  {
    address: '0xd2e70C68Bc87E7b80446EaA17b2E9011cE0D78ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.453664507980015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.45,
  },
  {
    address: '0x14089168Aaf4FE5C633468E468515ef6Ae859aD8',
    minters_reward: 0,
    lp_providers_reward: 7.09,
    swapers_reward: 56.1496373971965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.24,
  },
  {
    address: '0xdEdF96F8a65B34145F5B71315b271073b2B4336c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.105361869146435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.11,
  },
  {
    address: '0x1365026b7a121220a406CB9AD541cefB4a9715ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.45392826011301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.45,
  },
  {
    address: '0x1745f883fD866104638903227379453829647b6D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.44809056882014,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.45,
  },
  {
    address: '0x5d42b8590386c08F00EfEB540a708b41B9E4D9CF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.37140690410003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.37,
  },
  {
    address: '0x7E3EA601939AD586762f9e375Bd0a79b4B3C5C3B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.34377117385473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.34,
  },
  {
    address: '0x952f0A93543B53052B9564372fE65A261A3d15fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.305343080280686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.31,
  },
  {
    address: '0xCAd40Ac42D90b35D5d2Cf799866Fd0F474420a30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.14804538047692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.15,
  },
  {
    address: '0xc7f7077a2CA6AdAfaBc245172014f3015F876C42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.10327609679856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.1,
  },
  {
    address: '0xEF9284F439B22E49c7B34A736874F5014E79b876',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.10081852518105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.1,
  },
  {
    address: '0xc8389eF24Cc03Acd5eE97F8734a2640FecaBeD88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.8173625659199,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.82,
  },
  {
    address: '0xf0F31Ac0cd7179c13c030CA692954dD991bb9E31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.81603477982116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.82,
  },
  {
    address: '0x9CB167651081C4629B65f74316e9C75724358acD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.707114113905526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.71,
  },
  {
    address: '0xbf8C7d61385c25599ce77E3090Eb853E516E852b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.706512499640446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.71,
  },
  {
    address: '0xA314E8B877d1cFFffF339a8d9282833A43051555',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.686960382892345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.69,
  },
  {
    address: '0x4B5a56F47034EAff9128d73D7D1C4Bc922E6D08f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.55886442058143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.56,
  },
  {
    address: '0x012916eD48b20433B698CB32b488E52C639a2A66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.52633944404372,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.53,
  },
  {
    address: '0xe7037fA62B2BB8B557f4407B9b6B09351fFB92B1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.44709911369436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.45,
  },
  {
    address: '0xD6cAf684fc9bDdb677B5516d93A7710235a34fF8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.35769555271706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.36,
  },
  {
    address: '0x9F00Db1e3374C834D67E9296EBE136E3fB9Ac27C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.10539261361764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.11,
  },
  {
    address: '0x3b15e90C72b6c0e9dB5BEDfbB6430ccE6669dAf8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.94015498139312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.94,
  },
  {
    address: '0x8f7c3ECB9977EF32e386ac7E7AE6717F262b29F3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.78643693123563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.79,
  },
  {
    address: '0x5F39F2F19d75Ce6083Ca88d4b1849c36403E875A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.77908015405272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.78,
  },
  {
    address: '0x0119D914B60fC558F61f2bFCc4736f9B7C3b0256',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.76953770131918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.77,
  },
  {
    address: '0xE924C83f953aE6f2388e94C217C63fe6D6A5223F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.705716033205206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.71,
  },
  {
    address: '0xC75f8E14Cd5fB63BeBfE19bfd6Bc6cCdAC0732D6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.53461054741546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.53,
  },
  {
    address: '0x9a5E3cD9C92C455a1FCF4acd52068F1DD3Ec3998',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.47399233348682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.47,
  },
  {
    address: '0xf64b6c8E86f5Daafb6b206775c3f09b5E40E6D6F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.397983730094545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.4,
  },
  {
    address: '0x2864c45E548d586b6e19a80504697c7FFc0AE9F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.243469914019826,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.24,
  },
  {
    address: '0xc2d17b4F7531217685368cE49915983763A115ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.217018164994414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.22,
  },
  {
    address: '0xFe112D832c2DdA0C1924761AB75e095Efa3dd594',
    minters_reward: 0,
    lp_providers_reward: 60.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.19,
  },
  {
    address: '0xaDB652373ec271FCBf30CA56FC5F534F41BFC704',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.17199998179104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.17,
  },
  {
    address: '0xDBFfB42A0b58d138C21767871F3a95271Aac4c0A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.07710828037528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.08,
  },
  {
    address: '0x07C1eE4f7F959c8Bc8202B2eCC178Ff9006a67E2',
    minters_reward: 0,
    lp_providers_reward: 59.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.98,
  },
  {
    address: '0x0EA7FA87E2E52e2D4D601a9Db276DBe751C17696',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.928570251097604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.93,
  },
  {
    address: '0x4697E4954B0678839Ca2C59F6546f912fbc96081',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.913106888707276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.91,
  },
  {
    address: '0x06D66CEc581D07660c7BAc9C0Fefc1f708a778Ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.88577469341925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.89,
  },
  {
    address: '0x194948092FcfdC954972Fa22A9C76C871bA64714',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.78891866298493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.79,
  },
  {
    address: '0xd26005f8C7e54f01b4afD580Fcf51B5B4efb07Bb',
    minters_reward: 0,
    lp_providers_reward: 59.16,
    swapers_reward: 0.38790955811418076,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.55,
  },
  {
    address: '0x894569337b599113fAD4398Af6d1b775D7C82d99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.54155585713929,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.54,
  },
  {
    address: '0xB2B2CEabdCd6cfF881F3dB519547dCCC72191873',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.44300504960433,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.44,
  },
  {
    address: '0x1Cc659D1885075404682484762AA43943F065E9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.282248161467365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.28,
  },
  {
    address: '0xe1ba0664134294e1f40f5BD150e6886475b33644',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.2122675314477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.21,
  },
  {
    address: '0xA09cA4d6D094996CD8C0e924C9872a7B4Ec5e2f5',
    minters_reward: 0,
    lp_providers_reward: 33.57,
    swapers_reward: 25.573346669480838,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.14,
  },
  {
    address: '0x75324F728135230630365784Ec380b7e8143f7Ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.05267466030939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.05,
  },
  {
    address: '0x249B549C3B711C8882a5c5Ac6ABF084b52012106',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.04939637618607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.05,
  },
  {
    address: '0xa0945cC88DFDC8417d382194ED3F7Ab5e1409682',
    minters_reward: 0,
    lp_providers_reward: 24.36,
    swapers_reward: 34.621458670266094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.98,
  },
  {
    address: '0xDfF871be81DeFed21F33e1720416BFA958D8F96E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.94753473434707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.95,
  },
  {
    address: '0x3c6d4eEcECCe571170353247DdB3d0381328eAa6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.83022646582973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.83,
  },
  {
    address: '0xf36b5370Dcd0bbB0Cd1400B5Cf24493303Ea24cD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.72633048429977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.73,
  },
  {
    address: '0x4A72d64D5437f4Ec6432f23faD410D6802f90B74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.7225950227507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.72,
  },
  {
    address: '0x057d98048bA22F19C6c98b73456804851e65ddA0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.70204684038059,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.7,
  },
  {
    address: '0xCE02F7E8288bDd6e5af2a5352F181D35Af0ABE02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.67419735139969,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.67,
  },
  {
    address: '0xa698A7944a9E49B9f7254e400d6a5F16caB417E7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.636021425284646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.64,
  },
  {
    address: '0xC61E8E336665b207fF237dae3E87626b3aFA01Da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.623941339267965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.62,
  },
  {
    address: '0x796c07cE2e1abe9044F725eFb5033a82Ed6d6Dc9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.59078224727048,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.59,
  },
  {
    address: '0xa7f32bc7864Cf96E13768fB5e478D59ced9f146d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.222683276332496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.22,
  },
  {
    address: '0x28557bcBe0a67abb6348fc303c9dD4A1346a5138',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.2013915714126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.2,
  },
  {
    address: '0xBa9919D75145d61154b10820F1e059E1d6bC4d81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.12516276285358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.13,
  },
  {
    address: '0xF27D6228eeb14FB06C0c22668068d0ac448Ce0e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.08302284952093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.08,
  },
  {
    address: '0x463d9F016495cFa44fD5303B75A492C85a42044C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.0717954148063,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.07,
  },
  {
    address: '0x427888EBC70354DD2406DdA5F7BA6BF991E05966',
    minters_reward: 0,
    lp_providers_reward: 57.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.96,
  },
  {
    address: '0x88f1096045049884Df5B8F3d1F83c3522D4E4D12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.92064329865377,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.92,
  },
  {
    address: '0x138A947Dc14b18a9b92d6506284680F1506D13C7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.91253231757379,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.91,
  },
  {
    address: '0xDC48a98252Ba523c0F62aF92ea566F5F2866882D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.895318808273686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.9,
  },
  {
    address: '0x1d8B40cE7897081CB51b11e0883bA0751161D826',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.58643216767125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.59,
  },
  {
    address: '0x1E5fB0C951706a791E2E2084005B3BC394018E5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.530703899174355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.53,
  },
  {
    address: '0x976C4976889d6ee337e67204c6EfaDcfcf425580',
    minters_reward: 0,
    lp_providers_reward: 57.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.45,
  },
  {
    address: '0x84450d498738BE547DB257E2B53e2738849f1cC2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.35976436401735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.36,
  },
  {
    address: '0x246c09F185d5aF4996080fbA8AC7951b461b230D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.33977181232683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.34,
  },
  {
    address: '0x271a63c294189d80a9B9a5694277D079E4d5D042',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.264924194931595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.26,
  },
  {
    address: '0x65474929eaa3527a9416d31b1914F24926e13e67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.253309863975645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.25,
  },
  {
    address: '0xd32845bAEC193Db60f5F735413a4A685adE41063',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.22184857501698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.22,
  },
  {
    address: '0xD4BB86D99ABFb6a6c412D8d5207B25dC0F152588',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.18626638657707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.19,
  },
  {
    address: '0x79Aa373e4fEa5CAA358bdB7f529833FAb8649e7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.125986900474196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.13,
  },
  {
    address: '0x132477BF1Bf3a530F4ca72D45a8658F3BF3088F0',
    minters_reward: 0,
    lp_providers_reward: 26.11,
    swapers_reward: 31.01400673652752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.12,
  },
  {
    address: '0x9d31407eDd448F8AdA2e52ee90C68f5C5cd2c1DC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.03206141174887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.03,
  },
  {
    address: '0x0f2A864Dba126dcfb18bD0d801E75729627DCF22',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.03112579172277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.03,
  },
  {
    address: '0xeCf0BEe9930Eca4792810e2AE404CFE323D8fF37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.010739349477674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.01,
  },
  {
    address: '0x4f0d766BC67C7AF65FF6E89F791d8B110A2165ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.97104048343465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.97,
  },
  {
    address: '0xC32Fec02c33e2F5f3e98E7e3635037361bFFC364',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.87056951358346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.87,
  },
  {
    address: '0x51ce398E86C84F86E1b49e680bc2a58DD421eab5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.785690972488766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.79,
  },
  {
    address: '0xB31c7927a6FFc931ECe4221083C9d72f9b60BBAd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.75927888249043,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.76,
  },
  {
    address: '0xDD536275C1e7bA94bEE46a76218b5360C6A1054B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.74635096215511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.75,
  },
  {
    address: '0xCDCa2B8a5E6409BbEfC56aF17e0a5bBd091EDDC9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.73786827997802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.74,
  },
  {
    address: '0x44cB7DF573dafcbCdb8E38Bfdc144288F3259436',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.72732161569629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.73,
  },
  {
    address: '0xB4fE8B94A4f88A20980F910284AEB618150d3A1D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.63061742159692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.63,
  },
  {
    address: '0xf9f3d8588D08FB2eE5D7791492A18ee53E1f1772',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.60686136731185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.61,
  },
  {
    address: '0x3075DE6242f2d498D7eb2EE7Ac04187f8D7C8Ae9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.488811845484086,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.49,
  },
  {
    address: '0xeD4363757B2c00479f378DE27848725dBFD17D18',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.484689903470034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.48,
  },
  {
    address: '0x1112fbF1A309c4f7c58E5619A2f61412D3A04293',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.44136982270439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.44,
  },
  {
    address: '0x957C2a714c4e37C825116939797814BaF7f83979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.40637522646119,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.41,
  },
  {
    address: '0x090e97e4449C39608e5D685cCE92BB8c6f037cfA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.37025646182273,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.37,
  },
  {
    address: '0xF78176905fdc1E86Fd5778355abe2D40a2d7F2c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.35180713605651,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.35,
  },
  {
    address: '0xEC3b9C6D5b2587513C011E97E6194F958fDb2d71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.344743200193435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.34,
  },
  {
    address: '0xf5Be442A0Cfc371CBa7E000798BAc106e5609f16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.32944663390086,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.33,
  },
  {
    address: '0xc8A9a26c097d4E0D91Ecb21336ae4c866154Ef1E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.25333041630162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.25,
  },
  {
    address: '0xF852bb4127CFf037C8752311FF21bC87B61E78F9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.25134086966238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.25,
  },
  {
    address: '0x3e5Ba8a2500C59d6489e7924626B950401C112Fa',
    minters_reward: 0,
    lp_providers_reward: 56.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.15,
  },
  {
    address: '0x53Ce41680f44DA411833Fb0Bab2F31d9c1Adb93B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.12969236911495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.13,
  },
  {
    address: '0x196B52b66A424Fc61876258648d6eB7d8A7F481B',
    minters_reward: 0,
    lp_providers_reward: 56.07,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.07,
  },
  {
    address: '0x276D0f2F07Db94E9AA166690866A1e4a0C7FBB66',
    minters_reward: 0,
    lp_providers_reward: 31.38,
    swapers_reward: 24.613458568684937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.99,
  },
  {
    address: '0x4AF3a8ecC4E90B92262028FF6b2e49a935340aA7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.943480571840034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.94,
  },
  {
    address: '0x51E20f70236De385E456b22e9145C7164cc96BE4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.926907889355206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.93,
  },
  {
    address: '0xa07eF9c9119bde8789ed19c1751F4F8D1133CDb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.91185817583847,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.91,
  },
  {
    address: '0x10aB9D355cF3923FEaFfC56Df4d423afd7A72287',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.869046958956226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.87,
  },
  {
    address: '0x1EE57e88C839113E4162000F9DFB7e4B14B12DF1',
    minters_reward: 0,
    lp_providers_reward: 55.14,
    swapers_reward: 0.6863392102255862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.83,
  },
  {
    address: '0x64a05b7a0F4e20745ea270783AF370D76A20537c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.787927770671516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.79,
  },
  {
    address: '0xD01325eB51556e3e44C93dAb69797E0F12206EE6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.750740349159216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.75,
  },
  {
    address: '0xa368D99FEE0562834d44145600ac87dEB5bc6397',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.552720338136304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.55,
  },
  {
    address: '0xF95E25104CCb0bafbb73C1914f78Eef63BABa1E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.34446835872803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.34,
  },
  {
    address: '0x9ACD1776DDDEEAf40a08AeFaA2b9a6068f45c028',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.33756918932246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.34,
  },
  {
    address: '0x68412B4037a033faa27DE5F163b457Db6ddCC7FE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.25077376364875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.25,
  },
  {
    address: '0xDC462Ad94C379a3e9C4Bc341Cc32d86a1b8e5b41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.24646439912787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.25,
  },
  {
    address: '0xcB092E5e16C2C3F95C28883F7036cBa3447710B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.08708854261495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.09,
  },
  {
    address: '0x6841F0D64e41a4dA9a4DC196d6272bBCC0E3Ae62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.027219053742435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.03,
  },
  {
    address: '0xD0665D71a1671DF4059f72e1eAFeD95B6A3e123C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.95324115638061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.95,
  },
  {
    address: '0xcE7CC5b29Ba1cd2bBb7f3Bbd02e5f0583656cC7A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.9182745069904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.92,
  },
  {
    address: '0x884B442CA78ED1B30cf6a0958B6C713898e75006',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.74273006988523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.74,
  },
  {
    address: '0x847B7d78A9eBa2B023f99955d0CD766EA39c6B31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.73694618953459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.74,
  },
  {
    address: '0x153f26c2c7169B1Fc7Dc6703261A9d31c6064f4C',
    minters_reward: 0,
    lp_providers_reward: 54.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.68,
  },
  {
    address: '0xD9BcD446535aAe908b34d62eab601F17593d7968',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.64488051092984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.64,
  },
  {
    address: '0xBbfeFC2662c6E367eE671E956b47a9B58A2BF8fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.49123115888652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.49,
  },
  {
    address: '0x491ec03cBebb539CF46F2030ca92Ac3D2d998b5e',
    minters_reward: 0,
    lp_providers_reward: 31.73,
    swapers_reward: 22.744097683534427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.47,
  },
  {
    address: '0x7DC7D88354B061979440dDEFB370f4A453E5441a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.43686280719706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.44,
  },
  {
    address: '0xb10D4993A92f71AC13F3C279Bc7B1daebD000E34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.30130095593677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.3,
  },
  {
    address: '0xFB3ceD42058878749379f96DB1c0dC685a4b4Fa3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.24447285694956,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.24,
  },
  {
    address: '0x8bFFF4D264cC4FcC33A52F918d980C8a1244E0DA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.14077086548155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.14,
  },
  {
    address: '0xa1EB4271758b8a640F911c7aC79dAbcA9A9c0cD2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.02995664450469,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.03,
  },
  {
    address: '0x63Ec9165Ab74d7Cf628b2e71Dd76d1bD1eD8F492',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.00513157649368,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.01,
  },
  {
    address: '0xD21eE439f35671767E9bC3410F54aA10713850db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.97769648460257,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.98,
  },
  {
    address: '0x1B9888C1C9e0FE406e5Fa2AC2741c2487a20b31B',
    minters_reward: 0,
    lp_providers_reward: 53.95,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.95,
  },
  {
    address: '0x04dA3b81Db7bc5f943d6372456a574309F74982c',
    minters_reward: 0,
    lp_providers_reward: 53.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.94,
  },
  {
    address: '0x67acd25D9b3d4971f9f8EdBD3192d6A0Bf21fBbe',
    minters_reward: 0,
    lp_providers_reward: 11.75,
    swapers_reward: 41.97195301403279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.72,
  },
  {
    address: '0x48871bD8A5Df14C93b3653E009dcdCd1CB9f49d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.6419838095327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.64,
  },
  {
    address: '0x960029bae074337926ED22d6146ecb260997f444',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.5721275285496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.57,
  },
  {
    address: '0x2D6f68153b440011C974E18008d03d20E4CeE57C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.367209270037144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.37,
  },
  {
    address: '0x7014ca26170EC96B9a37FcfcA0fBdAFcc5162850',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.229900730830046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.23,
  },
  {
    address: '0x1Ddd6Cf038FEF900837A6fC480B59eAA8E946107',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.21367401842777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.21,
  },
  {
    address: '0x883DF836f1CE43C786d476FEC2925408cCE9C425',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.21220753140146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.21,
  },
  {
    address: '0x90EE7b35fE92b5e83F5391c7cA8969f08489c4E9',
    minters_reward: 0,
    lp_providers_reward: 53.21,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.21,
  },
  {
    address: '0x367cD9Ff6237B4ddeAaDbe9E3bc33D2B7Df9aa9C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.14342238318631,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.14,
  },
  {
    address: '0xCc15607A9a7919EE93B1eA99C46Ff41F8c957784',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.12167317265728,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.12,
  },
  {
    address: '0xc500C4D3be0582170436D05061C10b5ed31DE41c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.096311623970884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.1,
  },
  {
    address: '0x18E38769507c83e853b46955cfFe6928052D2292',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.02658496811412,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.03,
  },
  {
    address: '0xf6b95CA80c797377Ee91bB92ede70ea317725aBE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.9777600754629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.98,
  },
  {
    address: '0x1141d6c30c39776D75ab97dAd365e921F4ED18c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.95323743545445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.95,
  },
  {
    address: '0xbeFf45771fE7a68573BF998604d4927E3ED3B0bE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.840298007597774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.84,
  },
  {
    address: '0x1f88703a800be53d8a3B0A10a475d6cCb2ff3bE6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.69087063117827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.69,
  },
  {
    address: '0x8d376759452C45Ecff24E8bd13DE1648124b4aef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.611322848566004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.61,
  },
  {
    address: '0x6ed27d3922C8F0Bc7A172805E58D262716C5A417',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.605481821832484,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.61,
  },
  {
    address: '0x84C2513B9E1e726846e6D603f161eA3297F36786',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.59830044040094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.6,
  },
  {
    address: '0x5039C4997580fE39CC26c75cc3c2A2D7C6CAEacf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.57432074151719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.57,
  },
  {
    address: '0xdC61F0EA8F4584FB43ad0e5253054404434F0DbA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.52903640217686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.53,
  },
  {
    address: '0x71AdF68a5831cc7023E637a6bc527C413dB17427',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.52201070218651,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.52,
  },
  {
    address: '0xa030377564c2CA9fEaE9cAE3Dd78940C10f4BD3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.49770454787959,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.5,
  },
  {
    address: '0xd664b771Cf724796275c2d3ded647C509b4286E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.487715168314274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.49,
  },
  {
    address: '0xc5De0F663DCd9fA47c94969539e89DC7f1B92446',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.45220236830997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.45,
  },
  {
    address: '0xde277E98aD9CC2a76Cc7e1d497a96EdA6e600a69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.44495481641424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.44,
  },
  {
    address: '0x96987bb621B272dD588091EF5d26747B4C91AF49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.38841078613887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.39,
  },
  {
    address: '0x3a6f14c89838B9f3FC30a37B475da440A09889B6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.3091018417825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.31,
  },
  {
    address: '0x5861e7Ec5495a0c8FAd9ae73ECB64A67D5E30772',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.265146345796545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.27,
  },
  {
    address: '0x9ae5CC5E93B27E7930abf48f7bcd214af9AaB772',
    minters_reward: 0,
    lp_providers_reward: 30.49,
    swapers_reward: 21.777535504128398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.27,
  },
  {
    address: '0x4EFf73F19e1cC49834d7Cf9C1B29DEb5d4F8E699',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.202181961194995,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.2,
  },
  {
    address: '0x60d6e4feA988dAcfE40f6Af3cf303a3C31203484',
    minters_reward: 0,
    lp_providers_reward: 48.71,
    swapers_reward: 3.492511202489641,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.2,
  },
  {
    address: '0x62De3335A36078F704De048A5fFb4563a53E31E7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.14955028735881,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.15,
  },
  {
    address: '0xAcC8cC7467192f7F44F80b0424467E96d7721e27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.07089206285768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.07,
  },
  {
    address: '0xaDAd958B5F5a106E08445d8dbE651dd5B051a04d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.82401561630142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.82,
  },
  {
    address: '0x04c11ab8869f6077954E02B439eAB6b9d430186F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.813643184023846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.81,
  },
  {
    address: '0x4F73AEB0eF6e795e425F36f0f9E797696a311880',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.79370444956198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.79,
  },
  {
    address: '0xD1F74e6a921BfA62baFC4E2244FEfa420518b076',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.72203536054704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.72,
  },
  {
    address: '0xcdba7997C01eb3F5394CB5b2977727DeB2A2C846',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.62191383899094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.62,
  },
  {
    address: '0x17cc2E9Aac2ADb37EB618F65DEe4B732a4DB61E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.56482446774157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.56,
  },
  {
    address: '0xbdf096031d224b502b5EbD84F08b2be612e564f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.214598165448834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.21,
  },
  {
    address: '0xf740F4dA8E78FFbf5188AaF4Cf9de0fca5a7EeD2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.1615970383119,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.16,
  },
  {
    address: '0x252d88E9d0bD2E4EEE6EDe26E89bf974fBeF1f93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.08861816184108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.09,
  },
  {
    address: '0x0a016afcf1DEFA05cE0b340204ADc26cC3092B3E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.94335166123662,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.94,
  },
  {
    address: '0xDD21610e323cb69535B3dF11Ae553C5412bdAa68',
    minters_reward: 0,
    lp_providers_reward: 37.05,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.76,
  },
  {
    address: '0x965d023d514B2a7cDB4165830712ed6900a6d6b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.70358561151883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.7,
  },
  {
    address: '0xE1E91d9221D3Bd9987C830766390c68576B8842E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.59557050775026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.6,
  },
  {
    address: '0x14aFbF99dd49EE52FD37C7A6f4F85184Fbae1090',
    minters_reward: 0,
    lp_providers_reward: 50.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.58,
  },
  {
    address: '0x33D308cD150A8497e4713375500F50BD7C255420',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.52892700695513,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.53,
  },
  {
    address: '0x5A5D9a3Def04d319cE978fF67416e186B5F296c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.52226642461831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.52,
  },
  {
    address: '0x52c82aB3E7a6F671d44C7Ac3f8Ebe1EeECBc05A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.09867139967304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.1,
  },
  {
    address: '0xc04B40E948561f1EdE5D7e2D4803f41a181cFa8D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.08624107510459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.09,
  },
  {
    address: '0xE0dF1c7d28002362Fe4c5f8c1Fe2E9dd4e1beAc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.0120997129468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.01,
  },
  {
    address: '0x955F13f882B6d9B9ecfaB8c7D5824233Fe152E9e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.99622403863481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50,
  },
  {
    address: '0xDD7abBfBDF889f91dF92ed717c9f4b12AD830827',
    minters_reward: 0,
    lp_providers_reward: 49.95,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.95,
  },
  {
    address: '0x41e9266BafC82a82729F736FF7B02F6e32148e10',
    minters_reward: 0,
    lp_providers_reward: 7.94,
    swapers_reward: 41.938629183981874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.88,
  },
  {
    address: '0xfa7d0CfEE4d881cF8159e0d18c692cCb71E9C0C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.69792897326141,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.7,
  },
  {
    address: '0x7114C34F5bBA989FC4e088B0fd99BE2fE78596d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.67234386517256,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.67,
  },
  {
    address: '0xfC55dF4bD90c01Ead2E766173E98d52ee4F9bbcb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.3847586982379,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.38,
  },
  {
    address: '0x088A5450136fe2711c0591Be5d3e3C2e1e130313',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.33327009989194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.33,
  },
  {
    address: '0x6EbBAF5A69caDC6d3054c11dD305Ed814cE29bAA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.19527733327422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.2,
  },
  {
    address: '0xc5a2795f791B644e43765d0E86A0061e0D6f6555',
    minters_reward: 0,
    lp_providers_reward: 45.09,
    swapers_reward: 4.11046635018424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.2,
  },
  {
    address: '0x442f80859e9E61e48952125fc27dE05233876439',
    minters_reward: 0,
    lp_providers_reward: 15.04,
    swapers_reward: 34.1463492678398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.19,
  },
  {
    address: '0x9Aac2c44a1805028d4306D49473d6eeeb723D25F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.17891187064293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.18,
  },
  {
    address: '0xEc7A7b5B3902e9Bd381b2F94F643d315e9f03f7E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.14883874547253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.15,
  },
  {
    address: '0xA8026BA31Ff91A280310efeC34b74Ad14AC3C987',
    minters_reward: 0,
    lp_providers_reward: 1.59,
    swapers_reward: 47.564240941242964,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.15,
  },
  {
    address: '0x22B2EB3dCE7962Defa23649714a1329ebc8E173b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.06899212901387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.07,
  },
  {
    address: '0x992e63C763b61a94c77757cb5E5Bf24a8056eeFf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.04509859587188,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.05,
  },
  {
    address: '0xC58823F64f27762599fc32bdA3e80B82B40F2eE1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.00295341991468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49,
  },
  {
    address: '0xd5b62D414746629b7E28Ff9564D74D46d0B7412f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.61271377208013,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.61,
  },
  {
    address: '0xb441e1feB44c325e47A2D7528578EE9d57020b87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.59395789188199,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.59,
  },
  {
    address: '0xe5Ad63Dbe47765b7f99C0671962073b24410c16A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.477793444472475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.48,
  },
  {
    address: '0x66E214977e8ABdfa7b2bF5D6d2A95A7ea19A9EaC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.30183280578467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.3,
  },
  {
    address: '0x9448705e9902A99b40DAfBB8e2c5036916657f5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.274825919243845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.27,
  },
  {
    address: '0xD08455537DAB2B369B8C36a4F1CE96aF001675F5',
    minters_reward: 0,
    lp_providers_reward: 8.04,
    swapers_reward: 40.20001351115019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.24,
  },
  {
    address: '0x6b2d890c0eC18506Cf8ce7509cc32CC8f3e76b26',
    minters_reward: 0,
    lp_providers_reward: 48.21,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.21,
  },
  {
    address: '0x218F4992F66a9E4cC4fE77B949768B4E275C1c2E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.088802428107385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.09,
  },
  {
    address: '0x3066ccC533Dd18be48482218f6bB31dd450C6b2F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.06414242438875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.06,
  },
  {
    address: '0xE9022283B2ec250260F3c5635dcbE2C0FbA21fD6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.97163997954466,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.97,
  },
  {
    address: '0xf2e20E91C8E22f72Ba89d0C9e188291e04581dcc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.883092639813476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.88,
  },
  {
    address: '0x6a83DeEb4F5EE9F92eBA24E06b651c77c3A53976',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.85792758358122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.86,
  },
  {
    address: '0x7dE9179020BD40beC1F966eF3Fda903620342a90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.79146136298103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.79,
  },
  {
    address: '0xB83968053449F757A7Ec59D4d37405b0f6d3034b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.6613895442465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.66,
  },
  {
    address: '0x888b6365c6d0949E61A2A6FbbDE168c8FD0F847B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.64443198521536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.64,
  },
  {
    address: '0x1dA285EBb7BCdbae760402AC5680A9C23B401649',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.475487617434744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.48,
  },
  {
    address: '0x1Db3D2E989d9d1c13F37fe5B8063e9aa84EC4fA2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.40641801719235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.41,
  },
  {
    address: '0x9c5B201A5Df1409eE978e9cDE2a5B32892B823E2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.40558578006888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.41,
  },
  {
    address: '0x3A9799DE4aD7decB8eEEF718f3D44cc71D537725',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.4002535685517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.4,
  },
  {
    address: '0xc42cB65Bf4d574c488DE5F1ae2524948820406Eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.39754595333391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.4,
  },
  {
    address: '0xFc1235E6523Becf4b329ed9ADf21750cfAF65Bfb',
    minters_reward: 0,
    lp_providers_reward: 14.11,
    swapers_reward: 33.28260742576448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.39,
  },
  {
    address: '0x3B4A01755E98ea292010C6256e062ff6d6e3c4A4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.355839561934346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.36,
  },
  {
    address: '0x41D15d8C33b178C05C1991F8960B9B7070bA9040',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.347408083190764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.35,
  },
  {
    address: '0x6D8B6d5eAe663D99290267091C1F8b15ecaC2f3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.16803024985243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.17,
  },
  {
    address: '0x3CF404A8C14521d97e767963DEa465e3616DB985',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.16781917947035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.17,
  },
  {
    address: '0x7CD9806DeA5483845B8742AfBA51d3fB26FE280D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.01943975917869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.02,
  },
  {
    address: '0xd7F7A351F7cBBd222D043Bcdb8916aB79D9f85eB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.98623003905327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.99,
  },
  {
    address: '0x2EEE32b4ddAFD9B55461a5Fd6c81485b10339f2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.96881437757559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.97,
  },
  {
    address: '0x1d6b6eB26d7C78B3f899Ba79511F38f28D6EEd6a',
    minters_reward: 0,
    lp_providers_reward: 42.99,
    swapers_reward: 3.9388268659735255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.93,
  },
  {
    address: '0xa8c044Ec218ce9726eF38b43e966d421BaD64E27',
    minters_reward: 0,
    lp_providers_reward: 46.81,
    swapers_reward: 0.0754399072478878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.89,
  },
  {
    address: '0xf6Dc6724235908E5b6a0EFEa55fe6d49fD6E04D0',
    minters_reward: 0,
    lp_providers_reward: 24.08,
    swapers_reward: 22.724894525921684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.8,
  },
  {
    address: '0x50275E4704f7b91a741d07018760719496CCbf0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.793683537887965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.79,
  },
  {
    address: '0xa0Adb444676FBd15ac503AD6d7eBE3877E26877B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.78567328312035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.79,
  },
  {
    address: '0xf29350CB38951D6685042688C7a88B0d442aa0f2',
    minters_reward: 0,
    lp_providers_reward: 46.73,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.73,
  },
  {
    address: '0xe7AA93956CC8C3D8DDeB3e77944D0d63714F7773',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.68438924520463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.68,
  },
  {
    address: '0xd5696D5978b021ea4040D32Ee0c3dFc20078BBd8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.633521005820974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.63,
  },
  {
    address: '0xE19137d091649D5c7A1392B32CaD28742D40E87e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.63165902143603,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.63,
  },
  {
    address: '0x1d2770a5F5402D359d40075d0C27ee340ED4b0c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.59663456657963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.6,
  },
  {
    address: '0x543c40BF447A00885eDe1E010047A103b724014B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.592918595267705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.59,
  },
  {
    address: '0xcB4776bD53Ec9272E3A0267Ff9E139Bb02BcC35E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.58512192329787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.59,
  },
  {
    address: '0xE53d5cC9c8a06548B951e3b86D14555267D7d820',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.58324633408026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.58,
  },
  {
    address: '0x62Ff31ed72B49eE6e4150CBAF6f63ceF0d6758C3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.52187898160857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.52,
  },
  {
    address: '0x3f379517da2ddEE8a1753f440abc3a44D129A3dF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.4200306537648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.42,
  },
  {
    address: '0x3a6778f46EFe7470A62fd310D4482026210C29bF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.327913660702706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.33,
  },
  {
    address: '0x0eb36d2949fa39f974BCa82538A4eb1D693309ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.24221195547206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.24,
  },
  {
    address: '0x20A54B9128D30111cb05A171796B192Aee804082',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.168598851982786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.17,
  },
  {
    address: '0xE9092a46F6Fc322e6C65b6db85386385CCa8a8f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.153922995005686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.15,
  },
  {
    address: '0x973802Eb8D4210fE68f244116F56Cd07cdEc7dca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.08335539431069,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.08,
  },
  {
    address: '0xE16A8c8e950bbb7Fd0A90293a8A6474cCc99AE2c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.04407776980482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.04,
  },
  {
    address: '0x8F5511Aa70F4ddf2d480a135202eB02b4a3797D4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.031608387892796,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.03,
  },
  {
    address: '0x6438a04a5Ac6E6De409dE34B5AB24A61B3624F04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.92320875526322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.92,
  },
  {
    address: '0x0DAEe5712D03bc8ee5d8e28cEd494E5BE700174c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.86532737937547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.87,
  },
  {
    address: '0xF1BA45abEA4Bbe1F6139761bb66E4a7502785D24',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.828623530359536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.83,
  },
  {
    address: '0xb7DA5f820313f24Ca0090C8A16898Fa38d8Cfcc2',
    minters_reward: 0,
    lp_providers_reward: 45.78,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.78,
  },
  {
    address: '0x8aCb7eC57aF88A69E86349dE901e3D567101a2DD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.650489286060974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.65,
  },
  {
    address: '0xF6175e0c79CCA5D50d5e7304fFE1072B13B74E5D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.62766068260381,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.63,
  },
  {
    address: '0x50F194EFA2AF19fAf68B8938d856a796d3cB39F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.494257274851776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.49,
  },
  {
    address: '0x80E25AC6Fb952734cdFC85cA369B462c821d2e39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.40935654408156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.41,
  },
  {
    address: '0x22de1554fC7C1192Eb64f8fF35c50C6A3495A854',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.372248463312374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.37,
  },
  {
    address: '0x44B25eC156875Ed3398425fc6DDC9b807249Ac6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.25898419342899,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.26,
  },
  {
    address: '0xf942742a158C3b73b12B251768C012Cd9D770B8d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.25529338414486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.26,
  },
  {
    address: '0xEB3Aa6D11cCE103323a1A5DFdAC44e939F9f7B0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.249366071347374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.25,
  },
  {
    address: '0xEaAEDc4Ad87a89eCd6d2267c6364C0482Bc59335',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.24450272432486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.24,
  },
  {
    address: '0x411cbe244BA96A375A27e74dC68746beA169a6CC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.06515107186225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.07,
  },
  {
    address: '0xa70c0032fE069D91a52ef5d094490A0F03e970Fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.98426916116064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.98,
  },
  {
    address: '0x7889086911668fb6e780425eb926B6B32c090ADa',
    minters_reward: 0,
    lp_providers_reward: 44.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.98,
  },
  {
    address: '0x8087e3bB33fC3282D93bb40F232B882Cc286cf6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.929019651237176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.93,
  },
  {
    address: '0xAFD8FE62739088c2ae58E5F13A9D8a67Dfd6304a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.82652711699215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.83,
  },
  {
    address: '0x26CbF43651326cEdd24a272783100e401640E69b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.82571861269899,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.83,
  },
  {
    address: '0xF2A836987B055e0821Fd9B824DFA8Ff4114a7807',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.81431285506675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.81,
  },
  {
    address: '0x067833fd1d72e2f2780625b59D59b8f20b088ebB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.7856752129522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.79,
  },
  {
    address: '0x1E46702abF33986a2047412C13653159224854C3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.75500018196763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.76,
  },
  {
    address: '0xA50aF704feD5c065Bf344e5eDcF20C195f55aAb5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.69978662268834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.7,
  },
  {
    address: '0xb3A91f46BeC551b457E4C67b984dfcE69393deE0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.68708483893138,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.69,
  },
  {
    address: '0xe60b9539b454B4A5B58EAb76635edc766f15A14f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.6865754351401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.69,
  },
  {
    address: '0x8BC4E9d8170cB36a390fe2a36A1f328411ea9405',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.68641181935728,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.69,
  },
  {
    address: '0xa38b5394F84A29C7EAc52B4A67914446C8C4F3cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.6300418671293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.63,
  },
  {
    address: '0x9a13D649a10C4155a7ff56467E3ccD5F63AeaD8F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.60842158400622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.61,
  },
  {
    address: '0x96f4B7BF2d584A7D53d31199DFF8F8F3aD2043b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.59096919734027,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.59,
  },
  {
    address: '0x08d27646ED3A2e9f6d4E5D633C8d332C3C8aE32D',
    minters_reward: 0,
    lp_providers_reward: 27.99,
    swapers_reward: 16.554067671163846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.54,
  },
  {
    address: '0x54883D4a10f35E434784Fb50E30E0E34e8Ea2fB8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.5348488544489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.53,
  },
  {
    address: '0xE291725AAacD6b4a54fD8fECBEA2F7A6fA0d2b19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.50986908991791,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.51,
  },
  {
    address: '0xF9659B5d14E11184BEC30a30708e7e18C9620555',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.50001773580159,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.5,
  },
  {
    address: '0x72EFDb764315c47DeA69029b9fcd01353087FF5F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.4896757883533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.49,
  },
  {
    address: '0xFDd15Ede7748D42aECA8Fedc619b3BDCf3C8751F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.31952639019172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.32,
  },
  {
    address: '0x23561DBC4d22cFb3d0A1310bA92EF38E347E8C81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.293713545683865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.29,
  },
  {
    address: '0xd333459faB540566eC2855C5062a92ef11fA0046',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.279739918050836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.28,
  },
  {
    address: '0x5DA986f6327269DC1A9D7fF360FdeB7B7B2A60E8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.25699189295561,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.26,
  },
  {
    address: '0x542f9a56345Aef94A0658B2d235fbc737c944622',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.24152464155694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.24,
  },
  {
    address: '0xB6B12f72a636e6eE505cBf1D62Ec08b4B22530e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.23497802288578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.23,
  },
  {
    address: '0x56deE6Da6a3E62F44A210992342ad818C8b4b3CE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.22365004582583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.22,
  },
  {
    address: '0x4bb2C2938d983F63B9d804285c8683bf8EE8a9dA',
    minters_reward: 0,
    lp_providers_reward: 14.87,
    swapers_reward: 29.281156977351667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.15,
  },
  {
    address: '0xcBd7b1Bfa9b63EF6b4b5F99F95C6703e734f7BbB',
    minters_reward: 0,
    lp_providers_reward: 44.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.15,
  },
  {
    address: '0x5E012B2001A5Eaf5D43b773989fff6b4d966b2f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.0849601443584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.08,
  },
  {
    address: '0xEb407FB5C944f17AAE668e534F491d186193371b',
    minters_reward: 0,
    lp_providers_reward: 18.02,
    swapers_reward: 26.040231147395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.06,
  },
  {
    address: '0xfD2e75596bd5c94b30051e9F26F0ba3c9C3298c8',
    minters_reward: 0,
    lp_providers_reward: 19.18,
    swapers_reward: 24.877357223879354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.06,
  },
  {
    address: '0xE1ca6194E03F22289EDD06ef10A3EeF02F45e007',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.96843534120892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.97,
  },
  {
    address: '0xbCFe8EBFdDeaD14454761199505b9fd0d5cadef3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.91458228642367,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.91,
  },
  {
    address: '0x6bC2c8Bc2Ff94FEc3d774f965422949eA9848125',
    minters_reward: 0,
    lp_providers_reward: 34.83,
    swapers_reward: 9.044983427518057,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.87,
  },
  {
    address: '0xa633A5dAf55295187c4987026bbC533Fbeb7b3b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.861893572626904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.86,
  },
  {
    address: '0xdc484255450cE654de60C5d66E6DFd8928Abf68A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.71746246800276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.72,
  },
  {
    address: '0x5aE6fD2aD9007d3059Af71a64c58201d6CD24239',
    minters_reward: 0,
    lp_providers_reward: 43.69,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.69,
  },
  {
    address: '0x61c83d2b8b80acC4Bd3f18707bb577318Cd354eB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.65259501762335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.65,
  },
  {
    address: '0x7258a1284eE57D430fF03894B31ca8AD90975185',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.633804303442645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.63,
  },
  {
    address: '0x26211B3ee39C73c1d65d09e73b400b551aBB76de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.47989208601195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.48,
  },
  {
    address: '0x7225a93C074658F5B0a1ce1caCdD2d3e6640AC80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.46349808616046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.46,
  },
  {
    address: '0x24a1fab5eCbFA61fBd7Ea6304f0B02E158991258',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.455875535039375,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.46,
  },
  {
    address: '0x92a9cc8B05Aff8440a66005AdBf7C8ef5085FC4D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.383680411,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.38,
  },
  {
    address: '0xD51824f3D9540B719D420595338e071933A06f48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.35195570223056,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.35,
  },
  {
    address: '0xa84BDF49E4BDD101884Ea2FEf6C33cD8d9e845b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.29493070340566,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.29,
  },
  {
    address: '0x29d7F32b0c09cA1F3BACB840e2aa21f0205b8413',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.20527905031478,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.21,
  },
  {
    address: '0xd710f884Bf16C72a2584879BC4661674213cAD3b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.16633275012923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.17,
  },
  {
    address: '0xDdF976Cc54d997eC6DA52c71Aed2b045342a2e17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.14378379411907,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.14,
  },
  {
    address: '0x67f8948f4E8B53d75cEeB7E66B5d2a7BdB5b22Ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.142398243027074,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.14,
  },
  {
    address: '0xCBB7e6b95428C3427565FFCf877981f4451479b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.12332506228635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.12,
  },
  {
    address: '0x734DEC6ef1d8aa02207746Aa63f52F9f487D7489',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.090278579153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.09,
  },
  {
    address: '0x0385bC7B28BC7873f481d4a7Bc66b76B875746b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.98274486576031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.98,
  },
  {
    address: '0x3955197F21d214b03F07B6D16544105Bd17e5832',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.97440483219815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.97,
  },
  {
    address: '0x8289386d7a1C1Dc005Ca62C2d5084D700ADB5D1d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.89700724037404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.9,
  },
  {
    address: '0x0641954630510c6ee8d0Ae5F02c7180bBE0B3A0f',
    minters_reward: 0,
    lp_providers_reward: 42.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.9,
  },
  {
    address: '0x38d2932Bd58C7fc696817177a09A83Fe1C576E1D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.89451851082748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.89,
  },
  {
    address: '0xa83280f4B52C9050f42F26813109C45EDeab8A48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.88582779541369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.89,
  },
  {
    address: '0xad09CB70884dAc120ACFbb1b56beDDB6427b12D8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.831474262379764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.83,
  },
  {
    address: '0x7af860532A492EcE1bc54C4c748F244843991865',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.80152399795997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.8,
  },
  {
    address: '0xd2146484B25e7a9590A05e77ecC3c6dcf0852C63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.78605639866005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.79,
  },
  {
    address: '0x5aDDB92c541CfE660d20E8792ca7F2152178fF22',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.720594573959886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.72,
  },
  {
    address: '0xccD9Ed94fA3E9a03FB43554779Bae79833dec22e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.68104983065944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.68,
  },
  {
    address: '0x5C6005DfE2622aE4a7e5d9EB8597b4c239d26392',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.671442376196765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.67,
  },
  {
    address: '0xA2548a9F87165aDd7b6E86b83b9a730ee77C8C51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.56899958049891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.57,
  },
  {
    address: '0x130d0B4797e12864255a35CdeD6Bdade1c570055',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.51299377668111,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.51,
  },
  {
    address: '0xed6c942BE6c4eba8C7d6e93acd334b0B06C0bcd4',
    minters_reward: 0,
    lp_providers_reward: 41.11,
    swapers_reward: 1.4003019839525952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.51,
  },
  {
    address: '0x4DD55ACD99e5bB4d25257E9a376b86AaD733Bd72',
    minters_reward: 0,
    lp_providers_reward: 42.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.48,
  },
  {
    address: '0x9c9AC7C3D694edE660C853Dced179064869a5dB9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.423736409835755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.42,
  },
  {
    address: '0x2cA99E26d1fA44B333F25d7847e4A652498cD5C6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.29940755689095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.3,
  },
  {
    address: '0x9c86F73061239E720d159360971e886fc14B7d3B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.27286597932522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.27,
  },
  {
    address: '0x660400a60A948F62f0c6FD3cA16B26B3fFe788a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.26026474794913,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.26,
  },
  {
    address: '0x15B9cd782D91309A8c927Ae6A86D09F492BeE118',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.25329705331081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.25,
  },
  {
    address: '0xd69d7dF4b9d91625E1359fCeE7a94279359E589D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.09450103540108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.09,
  },
  {
    address: '0x9f31B3be992891088Bdf1E57c08133C4f2514bcd',
    minters_reward: 0,
    lp_providers_reward: 33.09,
    swapers_reward: 8.97792524789887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.07,
  },
  {
    address: '0x1f93abF54ab6fF791cF44918bC7b9C7c2C56FEAb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.010891746515135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.01,
  },
  {
    address: '0x9046722f21638e154581e98DE9bFb0836e68C7BE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.00019851920135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42,
  },
  {
    address: '0xfc78F07986381160033383562A0B208CFD00DbA2',
    minters_reward: 0,
    lp_providers_reward: 24.11,
    swapers_reward: 17.872743929406052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.98,
  },
  {
    address: '0x4bfB94B39D5DbfC6642cB90A333d04BFc0BC70dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.97195301403279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.97,
  },
  {
    address: '0xCD00eE8409FEb0B2e0C3630a3A9C8ED4F3c9B1a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.946145686056695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.95,
  },
  {
    address: '0x79584DAC3A464d5D54bFB625f80DC4b790d24B61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.94585380571495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.95,
  },
  {
    address: '0xDAD4d2C549a990B245AA32c7281b172f59414C8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.93059742713602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.93,
  },
  {
    address: '0xd53114452D51f9efF9207bb21332C00913801Bb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.89388689710958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.89,
  },
  {
    address: '0x9776dBe09b8Ef900b0AB2068AAA84b5A0F349B80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.81575289310183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.82,
  },
  {
    address: '0xe4b782A0EFb411A7474a770aC70e7100e5e6ecEc',
    minters_reward: 0,
    lp_providers_reward: 14.1,
    swapers_reward: 27.68173252617259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.78,
  },
  {
    address: '0x57B91Fcc5732B7e7068BE7bDdabA13F33B3961FA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.749177855015176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.75,
  },
  {
    address: '0xD338D23A1c34902254dd68497c9354C930f24bB5',
    minters_reward: 0,
    lp_providers_reward: 41.74,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.74,
  },
  {
    address: '0xc040626dc9869D42c43dF190c2c931e3eD9278E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.71525751571225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.72,
  },
  {
    address: '0x1DC1918803eD25b670cA3FBc53Bc600abd6Ab806',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.66538728382238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.67,
  },
  {
    address: '0x113a6ecE7bA12551730775FDc00035eaf632fBEe',
    minters_reward: 0,
    lp_providers_reward: 3.36,
    swapers_reward: 38.27855771182817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.64,
  },
  {
    address: '0xdadb821DeB8D39a61207d25b39cD8105183107e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.601927333139834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.6,
  },
  {
    address: '0x96f34bC1c814C4CE9f4c1A507E549d948F1017Db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.528256703609124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.53,
  },
  {
    address: '0x7c49Cc6bA8020B1088289023a3f6b5C5Bca05bc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.513425929059174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.51,
  },
  {
    address: '0xcf6Aef19437aBE496F2698f61afd2Ca30e3F2C78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.46694769712967,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.47,
  },
  {
    address: '0x90020d509A7454f7117f0D303Bb4CCa02358E2Dc',
    minters_reward: 0,
    lp_providers_reward: 15.21,
    swapers_reward: 26.25354207398147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.46,
  },
  {
    address: '0xa33FF1944c2F76A8EeFb5b2E6e12874260bD8E97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.40311957627238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.4,
  },
  {
    address: '0x99De31b793CE8c2DD4B44c2e11BcD04584A1E4A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.39450636437431,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.39,
  },
  {
    address: '0x34D8bb91d194B0495d7EB632D12462093FF067fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.330401251678225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.33,
  },
  {
    address: '0x8b47224289e2D1f5CC8aeDA2F8F8143213dCc7bA',
    minters_reward: 0,
    lp_providers_reward: 41.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.32,
  },
  {
    address: '0xAA75D2Dc5E30f883caFA0b4DC48F1945062fB764',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.27260509702426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.27,
  },
  {
    address: '0x1611BFaB3aF0F8537A620959E615E2826d0608E0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.231714976410714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.23,
  },
  {
    address: '0xD0C33Ac480Fcca39B8CF650978B1611787C5c3D8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.16832580326528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.17,
  },
  {
    address: '0xB27cC0EbCa671429De48842097dE3E9f985b153D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.15289631030966,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.15,
  },
  {
    address: '0x283c87be4c9003A0Fa6539D2211a76D5180c37a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.13628827495793,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.14,
  },
  {
    address: '0xb4acFBd7E998Da6892ab70d8abcf4f4A86413758',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.12113657434222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.12,
  },
  {
    address: '0x6fe2c965dbD611BC579e3c9C7e94aca0eF5068f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.04832862461745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.05,
  },
  {
    address: '0x37C0D9f7AEc7cd4781d27bBc80f518c736B38b14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.043194903277524,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.04,
  },
  {
    address: '0xF984B551EC0E45D2F8189aD1DEDc4562F15DBa0E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.03505112106604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.04,
  },
  {
    address: '0x572CDDa5a552007D33CEFBc18a67FDAEb62DB736',
    minters_reward: 0,
    lp_providers_reward: 20.31,
    swapers_reward: 20.731225297234996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.04,
  },
  {
    address: '0x00E318403A4330C1FA916F82B415A32AE19085f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.99427839315424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.99,
  },
  {
    address: '0x4206930AA452b7dF36566576e18Ca8a5AfeC4ab9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.86390532525241,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.86,
  },
  {
    address: '0xA2e411490E03bd17880F5A9D83624Ac3118cC9C4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.8570922265819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.86,
  },
  {
    address: '0xC29A3555180eC6bA86580bBB56510268B0e6C0D9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.801233947250196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.8,
  },
  {
    address: '0xa9F2699926cA83b246b46F7173192e7E1B461643',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.78706017993573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.79,
  },
  {
    address: '0x933061adfF2b28a840A20371789140dbAdb6CE3F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.76407743038595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.76,
  },
  {
    address: '0x7B6B63BA33069D2ab73446B1bec3aA32ce0A663c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.67478353446839,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.67,
  },
  {
    address: '0x11Bf4fC5C71aB90AEF566Dd1f5a435c42E1a0844',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.65487242607496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.65,
  },
  {
    address: '0x858D36289849Cfd6A66166C7F7f222634c1952DA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.63335924005904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.63,
  },
  {
    address: '0xB0f3C0b62a9BAF47Ec3f7A2Ef17701568db8b1F8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.59110327778998,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.59,
  },
  {
    address: '0xFA7cfa07e8cB642117A374f27f24475ACBB36A29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.56615115544824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.57,
  },
  {
    address: '0xf56b77830B4Beb12236AC7aDe20dc7D75D9059c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.5084840782697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.51,
  },
  {
    address: '0x92dc1dA5A554685308BC07Ad1f6245a035ad3E15',
    minters_reward: 0,
    lp_providers_reward: 20.84,
    swapers_reward: 19.668980293232043,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.51,
  },
  {
    address: '0xc9A421f7E7DceBB4ee24DeC6543DA2C5e21b0f5C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.486967043856254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.49,
  },
  {
    address: '0x723908691451D9eB7A51b584C0dD33945E5f12Cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.393689367844914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.39,
  },
  {
    address: '0xdE25a90BEf46208057Ff9Ce8505B4223878D005F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.38145579752523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.38,
  },
  {
    address: '0x205f6448a64Ae37e8bA5D1c44ba888395980e131',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.35717136088042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.36,
  },
  {
    address: '0x7348502EBDA84DF77E22E021bfeB02Cf1616aA9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.27701543968863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.28,
  },
  {
    address: '0xf3116e7aFe780f1335a041891f60107481CecC78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.26903076619464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.27,
  },
  {
    address: '0x527534A0f0fe40BD6B947bb8bd54555B43E77064',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.24512933140216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.25,
  },
  {
    address: '0xf58A24eaA342639f62A2BF7f632362ABc639E4E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.21130104327361,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.21,
  },
  {
    address: '0x2c998CB284294aEa33c3420d5C9c4458dF70c951',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.12521521030829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.13,
  },
  {
    address: '0x7C7B54df335C591fd1030B7DB2738306907eFAA2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.12439592700744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.12,
  },
  {
    address: '0xda8ca7E0a52e1CF737935a4dB9dfb85e13BaEc53',
    minters_reward: 0,
    lp_providers_reward: 15.86,
    swapers_reward: 24.254391635672377,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.11,
  },
  {
    address: '0xDD1baFAb95Ac10f865529D85c072978e28131C82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.076158354596984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.08,
  },
  {
    address: '0x35e13Ee6E374F4E300BC65Be220f293b29C9A503',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.07313486267515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.07,
  },
  {
    address: '0x243ccaB759e815E3F1dBBC13b7a7C9261FEae842',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.036087177309845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.04,
  },
  {
    address: '0xfC8E4B5d1B09948e7397EEDB7Fe831a13859968E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.02612582230571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.03,
  },
  {
    address: '0xdC44B09b504160DB695dE2BbF5c0Fe9B8D9D5AF0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.994444591585186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.99,
  },
  {
    address: '0xd061F4882F6fd16b2C7cE6C59Fd03121F597d92E',
    minters_reward: 0,
    lp_providers_reward: 11.17,
    swapers_reward: 28.76818321337078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.94,
  },
  {
    address: '0xA5aCeE760c5A2971acbb93857D1EDB81c464DFA5',
    minters_reward: 0,
    lp_providers_reward: 39.53,
    swapers_reward: 0.40560578884235876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.94,
  },
  {
    address: '0xf0b0B188588320a9B301B52F04530B739b7Dc113',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.90168870695791,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.9,
  },
  {
    address: '0x29BbB19186b43409c15b11C6F37Cf21b7dBd6a12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.89669002235416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.9,
  },
  {
    address: '0xebd846eE50AD7B8890270641A7849B76271d2568',
    minters_reward: 0,
    lp_providers_reward: 39.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.86,
  },
  {
    address: '0x86FB95f19B94cCf3663c291C2a40e24c2e37f1cD',
    minters_reward: 0,
    lp_providers_reward: 16.91,
    swapers_reward: 22.903072026085816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.81,
  },
  {
    address: '0x48f4A5906cE05f07E5fA699D19a793638b223A74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.790131781627835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.79,
  },
  {
    address: '0x5657b833744E1D52599f923a9D045fcEfd4d0fE2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.73909607962489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.74,
  },
  {
    address: '0x564c91BD9A5620AaC588507Ef4C201053b4060c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.733448853284386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.73,
  },
  {
    address: '0x2C6B4f3D3e16A3fE94B7Dd668Edae547b10797A1',
    minters_reward: 0,
    lp_providers_reward: 10.51,
    swapers_reward: 29.18464588581422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.69,
  },
  {
    address: '0x02CF678e101Ca86c3Ca90f4D39DD74Cf7bF1D25f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.66457254449617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.66,
  },
  {
    address: '0xe34C255a548E5a7CA5b9797FD8231A30bb223F61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.5762508157737,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.58,
  },
  {
    address: '0x29114D76E2636137282949D2F79Af8581F6fF87f',
    minters_reward: 0,
    lp_providers_reward: 6.52,
    swapers_reward: 33.03229768618155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.55,
  },
  {
    address: '0x4BD8080CA0C7f69b64E7478b60BEaDFA59Af43bB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.47486739406037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.47,
  },
  {
    address: '0xAC440044dca365AEE25a86bBCcF7063e721cF79b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.38744783822645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.39,
  },
  {
    address: '0xEf6F495C3d37AEDB7A2368b9ddf45192CB647fB1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.38526765567348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.39,
  },
  {
    address: '0xB4D67397C33bec4439444c0c05a2Bb2765B1878B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.333716112310675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.33,
  },
  {
    address: '0x447d1aeE7e7D999Fceae8B1fCBcE675D5c49caf8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.296444549918334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.3,
  },
  {
    address: '0x54582396Cb2B32E9bCABbef4b7c32BE4ee086E00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.236509795678124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.24,
  },
  {
    address: '0xB4D45DB7D21754585A2731c3541bE4084401069F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.186287985034305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.19,
  },
  {
    address: '0x0D88f0E9633Fa2BC5a7eB5580bFF08b611380D25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.10178668331231,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.1,
  },
  {
    address: '0x165b791Eb34133d188c1336e27d1a19210f7aa83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.05271306592162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.05,
  },
  {
    address: '0x6c5243B5BF4a24D0a6D12054d8E7cFbf207d773B',
    minters_reward: 0,
    lp_providers_reward: 38.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.92,
  },
  {
    address: '0xb21307672d44c87a14A19694F06746406aEE237a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.89607962981102,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.9,
  },
  {
    address: '0x56ae5dc7a93ae2D6CbfF41cDCFC547A89931c717',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.89086020138844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.89,
  },
  {
    address: '0xeA05f980E02FEde57Ed7b2b366A91A9717B32BE1',
    minters_reward: 0,
    lp_providers_reward: 38.87,
    swapers_reward: 0.017766432498931832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.89,
  },
  {
    address: '0x69D983EFBF917A7b2c6971513f90afc36d175d78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.80540976826749,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.81,
  },
  {
    address: '0x041394a55F0e54B490b32cA32487822c8924067e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.64064188062217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.64,
  },
  {
    address: '0x3cAEECde10b871DA4B18708945896B4f0C8D7C4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.59482100170575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.59,
  },
  {
    address: '0xcef5668b0178F41Ddd65bd30075B1F8E2eb3e06a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.5886576957114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.59,
  },
  {
    address: '0xeBf1747a65172D2889675019B097722EAC9464ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.5786171090828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.58,
  },
  {
    address: '0x3E4eD880c9658d1D02FC252F347584402534d651',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.51548896220012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.52,
  },
  {
    address: '0xAD6e772E43BeA53213F91fFBd7b5EAe171Af9C6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.5121844880912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.51,
  },
  {
    address: '0x91BdCe1dab8cf15a42bcF53648fC89c9B175ED20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.48313235429835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.48,
  },
  {
    address: '0x3C2490B21Af11908A3C896420f87B1ab203e7D15',
    minters_reward: 0,
    lp_providers_reward: 20.74,
    swapers_reward: 17.734069140225348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.47,
  },
  {
    address: '0x9D80aB3450F03B1455594dc8bc3C8A85c3F19AA4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.457172642649844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.46,
  },
  {
    address: '0xC2d3fA550C1052e42B36dd199BB483ae38E3ebf0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.45646411427068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.46,
  },
  {
    address: '0x011a53eA78a8675ED9080A29033b85AdDCeBdAA5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.44828140793312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.45,
  },
  {
    address: '0xC1B0d55038C8991960b0cdD5F08dFF84EBf60d92',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.42255234252461,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.42,
  },
  {
    address: '0x3f7957dcdcFabA4794768864156707fdA1E26303',
    minters_reward: 0,
    lp_providers_reward: 24.13,
    swapers_reward: 14.287937387306279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.42,
  },
  {
    address: '0x8906c6FA8850f248C4e68d5799091a36AA3fA24d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.326342628911064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.33,
  },
  {
    address: '0x692B480AcA05c47d27F0d22e82ab6e1Fb6a91665',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.25211712001459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.25,
  },
  {
    address: '0x33118AF30e31eFDbA9a062365f8807b490E7c806',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.23722715265957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.24,
  },
  {
    address: '0xC4fF4fdAAd14244BBbc888f530aB14c0368De9a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.20715227124964,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.21,
  },
  {
    address: '0xCC4CBd591D59A83079Cd4314C993002754B641a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.14630536626202,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.15,
  },
  {
    address: '0x78186230E105b29dA740c0367cCF9Eb2bd168Eb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.11620977580613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.12,
  },
  {
    address: '0x730C2b93b7ECAEa985BEcE210D1B3D6d1e07143F',
    minters_reward: 0,
    lp_providers_reward: 7.21,
    swapers_reward: 30.910181259353017,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.12,
  },
  {
    address: '0xCC90764AA53365F1BD94dD7B492Ca7ed4aFa1552',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.06985192995485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.07,
  },
  {
    address: '0x3fACDdE5E71c7f9544f554dDFDBD36573fc2ACe0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.04914329247807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.05,
  },
  {
    address: '0x27360752c63564928F114d09A73eEb4E2c597372',
    minters_reward: 0,
    lp_providers_reward: 1.4,
    swapers_reward: 36.62324469628962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.02,
  },
  {
    address: '0x607a1bAc2cDdFd5A97be906B0DB4641e611B39b8',
    minters_reward: 0,
    lp_providers_reward: 31.9,
    swapers_reward: 6.120375996087595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.02,
  },
  {
    address: '0xE45117B8da6A243D18964a3F83BeD6d87E06d41B',
    minters_reward: 0,
    lp_providers_reward: 22.64,
    swapers_reward: 15.326708490615898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.97,
  },
  {
    address: '0x020e1783DF2b0F50d848e5BA46Ec96983F765d01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.958179970662215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.96,
  },
  {
    address: '0x6D3da23C592367934C3c1a20901A5d62C0755536',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.91223374418703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.91,
  },
  {
    address: '0x39E4192530368D3E1462d18445811b575e21D582',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.88701424879575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.89,
  },
  {
    address: '0xA516D8024591C306744291d7458aaF2d90598B51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.87602374774711,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.88,
  },
  {
    address: '0x4C842522ca8AD9189bfD043b50Dea6ddD8EeAb45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.78648329629886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.79,
  },
  {
    address: '0xE155b19C385d7a5E2e5027e4Dd9156D45Bd023E0',
    minters_reward: 0,
    lp_providers_reward: 37.78,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.78,
  },
  {
    address: '0x3993c658180E0C177ea458fB0e9c53de2fe37863',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.67502336841443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.68,
  },
  {
    address: '0xFaB70fd177F8f57D1B001bA4aE7CEADaE21E8F01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.6029843198597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.6,
  },
  {
    address: '0x87ca3835Cca1448709876d5029d4FDae93D86483',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.54887879116872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.55,
  },
  {
    address: '0x24aC370b394d9A73bB641873aC9fa8a9E233a6cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.46802405928956,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.47,
  },
  {
    address: '0x64AB8bA6E1484dd21B282103cD41E8d9347123C3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.42626428389413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.43,
  },
  {
    address: '0xaf1E60a869BA0Fda4d2Abf130789413c68ba35d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.382690500854316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.38,
  },
  {
    address: '0x07f2fb2Cb6aA6Ab8A965Dd29F8bc1251bc4654F2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.367407017337534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.37,
  },
  {
    address: '0x9b498C08515035679868f22f8c45e6Cb6eA6eFA2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.359506340396344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.36,
  },
  {
    address: '0x4b084583ed9F6cDFE4768BBE5A2b2199088C7873',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.341916125048954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.34,
  },
  {
    address: '0xd7B52E06729Bd5fdC90a298af3947AFCDe4ccF26',
    minters_reward: 0,
    lp_providers_reward: 37.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.34,
  },
  {
    address: '0xA8715a69B34E24C27cb4DCd34a536a6341582581',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.27535096566944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.28,
  },
  {
    address: '0x43052dB4edA22e9F6b9AEc12e6fD27D4527F8C4D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.162573026836945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.16,
  },
  {
    address: '0x9CF8bFFdD4ce18a5BFEcec919d5bA602bAf74257',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.00766720715482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.01,
  },
  {
    address: '0xbD28a0A8945384bc481CE83de25b646aB200Fb73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.78957595830219,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.79,
  },
  {
    address: '0xAF355d42E8Ec6D38A45EDe7a3d8cFA80B86676D3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.77944929129062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.78,
  },
  {
    address: '0x0D71488e58Bc9a45b072223820a05425e6c3ffdC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.76185542040919,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.76,
  },
  {
    address: '0x3f6a3A637e39d2FbD946E2ad16a218babf840Ff6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.68809481605432,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.69,
  },
  {
    address: '0xCc920e7e20e4F2DfE450599cdC1E75CfCEFdB454',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.57543575410705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.58,
  },
  {
    address: '0x63dEc41642f00C1199a92ec951Bc3DEAb8Bda55B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.569506147021045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.57,
  },
  {
    address: '0xD395DB18A3aC948D5a38fDAF88314Db2124b70a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.56255092210742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.56,
  },
  {
    address: '0xF54d1696D2e047C17c9212a20946D998b1c15A0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.520195963906744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.52,
  },
  {
    address: '0x1C9658e18De3B2a49CD2B26fB2655c2f4088AEc4',
    minters_reward: 0,
    lp_providers_reward: 36.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.42,
  },
  {
    address: '0x65aA213160E9F6F5Eeed25deF9e1EF923e2497EE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.388943890105246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.39,
  },
  {
    address: '0x2bD9db9b0235D945097018ed37585186f1A95161',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.35285135061136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.35,
  },
  {
    address: '0x5716e8FDCd8bE70d3f4c6a9F40F5846ece05ad87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.28452945828682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.28,
  },
  {
    address: '0xbdcE944bD44bab5042296F2f5a1e362514ff337c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.21920869053107,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.22,
  },
  {
    address: '0x22E1d5FE74b733c6795397476bd2B35E43391DDe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.099118157628276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.1,
  },
  {
    address: '0xEB8A424eeD512857037D91f69db5c282ebBc181e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.77587414822275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.78,
  },
  {
    address: '0x0faDA9436828e66c73460819dc7DBB43D59a3BB0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.76728801947952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.77,
  },
  {
    address: '0x54ce1bC21dB656a8968ca13FadFb0c526686A761',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.65582648099022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.66,
  },
  {
    address: '0x0DbEfA011640C2D51359E830b92158B4e33Ec1aC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.49872211750707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.5,
  },
  {
    address: '0x0864052DCFDa43033CC2129dAded95A4824374E1',
    minters_reward: 0,
    lp_providers_reward: 35.5,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.5,
  },
  {
    address: '0x46a97149E3d14a7876e26017cEe888Cc008c22d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.46355343728535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.46,
  },
  {
    address: '0x3F21F81Ec027599eF3Ab6225E105267b979e0939',
    minters_reward: 0,
    lp_providers_reward: 35.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.46,
  },
  {
    address: '0x9D6bA8738Dd587114C895ECD40623fc319e1BB99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.41735573240163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.42,
  },
  {
    address: '0x56113E1a3dB81beE43928f68f540B1A18c836e60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.334986708598414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.33,
  },
  {
    address: '0x1AE322fA297644Ef7499502FeC1A06e18B2b04bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.31145080933246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.31,
  },
  {
    address: '0x01F73dDE03932714854B1bd0b5752722b52F0B3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.30148048400242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.3,
  },
  {
    address: '0x041b71228D496036f13f1b9a19e35f8F3D4EF5c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.2826832650629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.28,
  },
  {
    address: '0x597d47B1E90C66589d2a975Ce66fe1914A43838e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.24217321076445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.24,
  },
  {
    address: '0xf76D57B1efbC9C5d6c3Ffe5466f5A5e7A3C6dD34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.14513250803718,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.15,
  },
  {
    address: '0x7Ad5718aCe87BF1Be2Ed47Be0eDad0a69643E309',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.12490876927122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.12,
  },
  {
    address: '0xE204bD3c8d426aD153DDe35fE152EE49633c294E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.101581180204846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.1,
  },
  {
    address: '0xc2fea627cb59Fb714277d2240ffEfb67770bA9E3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.0544171955378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.05,
  },
  {
    address: '0x42F537Bb3A95845c18265A62c5ede19FDf8784a5',
    minters_reward: 0,
    lp_providers_reward: 14.91,
    swapers_reward: 20.107240638471925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.02,
  },
  {
    address: '0x2b1c48eb05d7D96dd0eA1C0f484207fA26f58F9E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.00002815637364,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35,
  },
  {
    address: '0xd9dA41aE27Cf2a8e36511Bdbb340bD0cf0Eb527f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.91640161282354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.92,
  },
  {
    address: '0xbC740227C292043F5721118c54b1DaB2bb3de899',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.87384203559085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.87,
  },
  {
    address: '0x018D3f14c745c37510f87130C960b9B7Bd980CEa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.862505645174984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.86,
  },
  {
    address: '0x6eF5268F3Ab783D97e14865516a2Cf9abAfC223a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.79666076426565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.8,
  },
  {
    address: '0x0e0806B03f7a60Bf706A8421323fE6122b2fAdf6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.72151590695816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.72,
  },
  {
    address: '0xA1671c8c02e68a1B7c8A8B49783caFCF3D2DdC97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.712790932184625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.71,
  },
  {
    address: '0xbfD6C6A9DA1032FE84a50F1E45360b802069B0A4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.616341952346716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.62,
  },
  {
    address: '0x6FFEAD202823851b92C3bA3336e15aF29eE44213',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.583389642114426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.58,
  },
  {
    address: '0xCaF2Eae018462D5C990676A4Ed38bF59F9C64C52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.52410345398693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.52,
  },
  {
    address: '0x4E637cE5eb8a0D626E2F0ff1B72B831e4Ad3A7bF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.387523972822464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.39,
  },
  {
    address: '0x86E65B6c6117321F451c59Ac9501EafD73565382',
    minters_reward: 0,
    lp_providers_reward: 6.74,
    swapers_reward: 27.594548565138137,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.33,
  },
  {
    address: '0x76Fb6078Dc2537713aA1C3466120B39BBe082798',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.30069943301799,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.3,
  },
  {
    address: '0x6e1355C3b0B55DC8a0c9D7d2B15d671F8F32Eee5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.282664802596585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.28,
  },
  {
    address: '0x6044043BC5aE21f9D62fb0Ea5C12ba92EE891543',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.2468967856199,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.25,
  },
  {
    address: '0xfc254d918d50D849833F42075ffA646E64FE9AFe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.230516172818604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.23,
  },
  {
    address: '0x2a7534b3D9260dC71f884E54bdFDcDedB44e2EaF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.19077428309658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.19,
  },
  {
    address: '0x9F140445def1180c0d172FFb66715A970d828200',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.14302302068263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.14,
  },
  {
    address: '0xE5A533BC565Cd0dED43E0C0e6001AC85Fc2fBE6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.0712655555668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.07,
  },
  {
    address: '0x31879cC9bf075a62c7C4665cD02F1D2c9df5f823',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0xBCa2049f25CDE47D61cB8a2B2484EF6672AD8dEc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0xCfC7A6236D03A5bc790456D0d14193D9E7e58c3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0xf428AA69ce244C0A82A5e08587F34e38957aDb86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0x9D853D6fCB05720AFCaccf7E9d81118ABD0C02D7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0xE8545bffA909Cc1A7F5c7fcAB410523d0d1Cdc45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.94446191042118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.94,
  },
  {
    address: '0xF177cd8AD744bCa4B0DE652A82340c17e4d29ee0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.82614764106554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.83,
  },
  {
    address: '0xE518ffa7309491c5F5c947Fd52011771C36AB146',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.67853759829733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.68,
  },
  {
    address: '0xFB48076ec5726fe0865E7c91Ef0e4077A5040C7A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.676142286852034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.68,
  },
  {
    address: '0x96aa982c950573c39744Eeb1344c04F061028fc6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.675825443386465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.68,
  },
  {
    address: '0x5586735b5bCCA3Ee29934d41336aebB18b48350c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.663893138733876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.66,
  },
  {
    address: '0xFDB312A678fE2CBCdFA4EA473aA03e4435407F42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.50323729082169,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.5,
  },
  {
    address: '0x77561DbF31E98562d7701e4C6cBF0eB61124132e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.447081469246925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.45,
  },
  {
    address: '0x91952E96FaEb1E53A0c4B10eeDC7A65E32926A6D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.442097806329485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.44,
  },
  {
    address: '0x5acd29efdaC786d4f5D9797158E42D2574F0d76E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.43479456431676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.43,
  },
  {
    address: '0x8d52dd9fd1856726deb2feE2cd5331069AEe9c87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.409054544287294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.41,
  },
  {
    address: '0x49c5B4c4509fDf17034147540f5F176bc0830eeb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.37930670367477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.38,
  },
  {
    address: '0x23223534F7851c67A9bCA0cbcCa6D299890Bb775',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.369236148536025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.37,
  },
  {
    address: '0xFE6436D7E01915D783ab177833aC06FE0151BdfB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.34482448232164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.34,
  },
  {
    address: '0x893a5c01e7Bba4526268d39621A6Cf7A91933824',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.32493747361288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.32,
  },
  {
    address: '0x9f1922A36178b3509Dde41D5B0A12Ee9C3ca8261',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.218367240478564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.22,
  },
  {
    address: '0xda0E806d4FeF18bB893740a92Ac777d1C8c4deE8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.15031202300892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.15,
  },
  {
    address: '0xbeEa6faC12378171078A33c4F61869aa21a911a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.014974575401396,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.01,
  },
  {
    address: '0xECb34796BDBE3921443e8838F74Cc48b38648C8b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.98368222319835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.98,
  },
  {
    address: '0xcC622d0b3C08Ef8d74CC2fb8dB68045BCBCf8877',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.883395907333444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.88,
  },
  {
    address: '0x31C4F9C7000fD3f40A280e64091a257db22A7D3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.806220435457135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.81,
  },
  {
    address: '0xf56822Fd9b08DC3CAaecCE29C09ba3B85aD832f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.776318258639726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.78,
  },
  {
    address: '0x478a3bD07A620E89E06B127703219a888BdC9216',
    minters_reward: 0,
    lp_providers_reward: 19.89,
    swapers_reward: 12.865202142917559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.76,
  },
  {
    address: '0x839b46B4CAD8e57380C47a36A26d132b9c8E39B7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.63828730587081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.64,
  },
  {
    address: '0x3b3e4Bc78B1ce1D8E7697534C982F8b981c32aEc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.625257517508736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.63,
  },
  {
    address: '0x6BC4cD5e4E9DF85038b0aC3925bB22a66941D6F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.60026343577911,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.6,
  },
  {
    address: '0x59A01dC6709dBE544B54cB299E50610Ec4dda705',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.565498058105206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.57,
  },
  {
    address: '0xd82A3540FEfebD36C4996f1f62BAc065c0231abb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.5586843984663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.56,
  },
  {
    address: '0x224fA96e58239744b4B5eD22536Ba1d7FFEE24d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.516637550666175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.52,
  },
  {
    address: '0xF390B8C873150F7E4A687d09e9C363e91C0A787f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.49589805346555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.5,
  },
  {
    address: '0xDf24530e05bF65B2305CED15fD36dac09eE7f840',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.48543137510725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.49,
  },
  {
    address: '0x7b9A24AeE10cc23aF28A8E1009C5636E44Da2B6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.48199153959517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.48,
  },
  {
    address: '0x18A16e64456B4cf586bE2BDA3030B81529f5b455',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.47088090855572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.47,
  },
  {
    address: '0x0B43642138f591342dEd8681C52003ce68f8cB7a',
    minters_reward: 0,
    lp_providers_reward: 32.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.42,
  },
  {
    address: '0xef63bA8059C7f322D18c96F34671F4e0E3C80b05',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.396861412355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.4,
  },
  {
    address: '0x4dc245f5ae2A84e25318e06abB2BD5f0EeCa57b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.33676937569475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.34,
  },
  {
    address: '0x8617BbDd536cf7cAA60c3b143BdA74dE6087fcC0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.32744282454172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.33,
  },
  {
    address: '0xA734407cc41102bf652bf7f669DE955C0DC168dC',
    minters_reward: 0,
    lp_providers_reward: 11.06,
    swapers_reward: 21.194713483082943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.25,
  },
  {
    address: '0x7580202d84C0687E89F7427E8B1f3baCd3173b26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.242882672101075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.24,
  },
  {
    address: '0x158BEab07A152424194d6e4e7fE00EF105f46706',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.215224612955716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.22,
  },
  {
    address: '0x3760e45b49Ca00F2dfFbd835801d8B33e22b8C63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.07560792111203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.08,
  },
  {
    address: '0x7E682F89a72390cEbCc2C5A5020eCd389752CA75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.063035282092514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.06,
  },
  {
    address: '0x3afaFF498Ef2F54Cd82220F65bA8927b33d19619',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.054145772132536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.05,
  },
  {
    address: '0xd2533981322e39F07FFDDac5e24FC80F1fF60f9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.988576150141665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.99,
  },
  {
    address: '0xF5CdD1D98041ef766AfBf1f17536E85176C5FCeC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.916059059853676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.92,
  },
  {
    address: '0xD5AaBd3425b88A6ed7EbeDF66877385A20d6fdaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.709108236764425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.71,
  },
  {
    address: '0x322E69D4ef69009e791828450F3574eb595D4c43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.698881888298583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.7,
  },
  {
    address: '0xE221180576aA706574fc37BCB162118151a2457F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.604773481674012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.6,
  },
  {
    address: '0x9042DD571bABA4A778e5FfCD809FC95ab179e2da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.59650153543822,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.6,
  },
  {
    address: '0xB6b0d23660e9bA8BF3aFeD52709825c908288573',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.588874529283355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.59,
  },
  {
    address: '0xEd78Af9bF9a82823511429de2A760567a06bf320',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.57055970795623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.57,
  },
  {
    address: '0x634A2F9B3e1F65EE7BFc2FD4DAdc3784bE764ee9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.470229801505365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.47,
  },
  {
    address: '0xf26a260AE8097B2d6eB6213b77ED631db13286A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.364027819767212,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.36,
  },
  {
    address: '0x7e9793932C531E0C1fa0C90930c5b98C971845FB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.280268196096593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.28,
  },
  {
    address: '0x3653Bf7B606972640a6D3401C16CB9FcF335f1f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.274088223027945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.27,
  },
  {
    address: '0xeeA850728e58FE4B277fBCe1bAbdA9cCbde07A4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.249890719071768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.25,
  },
  {
    address: '0x6Ac54b36e2d09478D3a62f960Ba002def8aebEf1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.21210026171773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.21,
  },
  {
    address: '0xd43c5ec7d644a23eB3E9A99b9290aB3EBa2D9840',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.152242084045263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.15,
  },
  {
    address: '0xF2D0E32F272dec038d5652a84a9aC5CCcBB1FE19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.118551658128343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.12,
  },
  {
    address: '0xeaDCFb7355c854998DD6135c165Ec40bF8F86469',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.018153354957136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.02,
  },
  {
    address: '0xE54367833773619345CDa4004851aB485A8c008F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.987744662585747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.99,
  },
  {
    address: '0x4aa2A436c372850730Cd9F12EcEB4E411DAEfE70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.97615190943031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.98,
  },
  {
    address: '0x4f40e1a5F70cFbc457c1139e0cc6463244d9a6f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.95490766724032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.95,
  },
  {
    address: '0x04c69bc666e6AA720B36C29ADD05f643d1260b85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.94051699048907,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.94,
  },
  {
    address: '0x18833279f8d6c7Fc17faDAdEf31c3c3c166a8049',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.91902659120607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.92,
  },
  {
    address: '0x1e1915897FCF17620fF67282BFc732c3DD726Dce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.918892061441863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.92,
  },
  {
    address: '0x03dD6152d73E00eFcD29DB84934649Fc6dE4a9A9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.84237554245577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.84,
  },
  {
    address: '0x1592Dddc9358AC5648422c058C7b547358b70B0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.800683590718712,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.8,
  },
  {
    address: '0xaFeF2fe5FD64D46fe21bc3e20ED2436366298deb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.79595946878196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.8,
  },
  {
    address: '0xBCA0c799ABB62bb7E2D8eb13fe0B66B697a23296',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.717648445953788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.72,
  },
  {
    address: '0x60Eec6DE164085d41B2CabC5e8A15244B3fa152B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.704466707593895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.7,
  },
  {
    address: '0xb95b8127BeA66944A874B7a2Dec16b735Dd1ACBB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.630838533355394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.63,
  },
  {
    address: '0x11C9254f38464d59723eF94B70D55794D6E71f3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.583014698576932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.58,
  },
  {
    address: '0xcBb769bcB29a68874B97BecD826C9BBE471A7Fb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.542598453947214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.54,
  },
  {
    address: '0xd5204A495A54451Ff5f4CbfF011CE57FfDD740e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.49188002936507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.49,
  },
  {
    address: '0x3686D325a90Ff374cB3A42cc4B0A599fec1634F5',
    minters_reward: 0,
    lp_providers_reward: 30.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.49,
  },
  {
    address: '0xC88F9B820eD5e713aDE666Ba02928593a7A6ad2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.484627883691065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.48,
  },
  {
    address: '0x4aA54E98F545ABcb9ebB55B53caB8F6C7B498B5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.477207089121798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.48,
  },
  {
    address: '0x7EA0D3d9779FDd842c95AefE079ac099735151D3',
    minters_reward: 0,
    lp_providers_reward: 30.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.48,
  },
  {
    address: '0x1Fb44316D2b85cAd5125ee116227F4CE86aeC39c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.44416722665787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.44,
  },
  {
    address: '0x2FcE9Eb441F50b5c34A682afD474C67B8f15036b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.437974054560602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.44,
  },
  {
    address: '0x09Ca26244d9f41297c33f0E4A1D9699F916878Df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.3890275900895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.39,
  },
  {
    address: '0x9b6bD0c85E7736c1c834f932c1D9300C7C21f62B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.35872728074814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.36,
  },
  {
    address: '0xC372c4A596f45abDc4DB19cDe206Ec35864ab133',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.347728610567497,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.35,
  },
  {
    address: '0x277DcD74B00fa3897fa30454fECf098A0F148F8A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.319691026443902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.32,
  },
  {
    address: '0xAaf842ea6cee3fE937c1E84e59D061ACf98929be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.290677254836194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.29,
  },
  {
    address: '0x36aF1C5515EAf65ae27d85c5b3d135C51Ae8d48E',
    minters_reward: 0,
    lp_providers_reward: 25.61,
    swapers_reward: 4.683695764736418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.29,
  },
  {
    address: '0x746d8Bfbb7fC4Ee1ceE35a92c6E6355CcAF51664',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.253783273570022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.25,
  },
  {
    address: '0xd8326B61f0b48a13b60c8008C26bd04C712d846B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.198922702015228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.2,
  },
  {
    address: '0xefDea5DE24669fd4d9da3B0c2F98BdCb456F8af5',
    minters_reward: 0,
    lp_providers_reward: 30.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.19,
  },
  {
    address: '0xAa768F98269Ddc4bdaeBda0E8068ACcE9484BBB8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.992838798482627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.99,
  },
  {
    address: '0x489f82894BD820f375E3687231f93e29cD975b5f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.97433365951757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.97,
  },
  {
    address: '0x3C1AD8E4AA7b5a37616255801a88cb42181e58ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.921311578759912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.92,
  },
  {
    address: '0xda4229D857397A13E8aF17A90841C7BB880B7c7F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.884800230742883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.88,
  },
  {
    address: '0xA0517D7A5D937Ba242Be8Ddc8F3BF56a34A86979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.850420114749042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.85,
  },
  {
    address: '0xaee0B6F79e9C90e16E2f396ec8B602D133742857',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.832910433144757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.83,
  },
  {
    address: '0xE99347c2c966771dFE8B99BC607DF6904ff45E8E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.820280772535558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.82,
  },
  {
    address: '0xCe70A2357CcaeF8D038c1620E64CB442c45F100e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.810042382250245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.81,
  },
  {
    address: '0xb9822c48897BeFB553Fa4593712F4C50622D1bEe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.790766189139813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.79,
  },
  {
    address: '0x051C4Eb4417E7AB9262D55D4A36980E1a7C4C020',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.781703290879232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.78,
  },
  {
    address: '0x7f9cd47E2251b9093494d18d2EbdB80C7cAb2393',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.714331850393894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.71,
  },
  {
    address: '0xc62213Eedf2c9Fd67355709230d12c304c54e22a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.60613376572385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.61,
  },
  {
    address: '0xD7FE34d9faF036518537291B1B9fdC228282b916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.60613376572385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.61,
  },
  {
    address: '0xe7a13027C6661DC07eA52b6b6e9CFaB4a17fCe88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.60613376572385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.61,
  },
  {
    address: '0xa6f9D8296d21A6d13904a62134A25aAA102D7b16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.550366784798307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.55,
  },
  {
    address: '0xfD784F1C588106583B3FE34c49FAF90118718BCB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.479190709231577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.48,
  },
  {
    address: '0x89e2a4D8d52A13Ec6C7522E7eE32fAacf5AaE554',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.47374182785431,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.47,
  },
  {
    address: '0x4f4181bBBB4845104301Ff6237ba09c3389702EE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.46423950840585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.46,
  },
  {
    address: '0x69A411647Aaaea9722e5A5EE9a6750C21f1E8D14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.44460658399592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.44,
  },
  {
    address: '0x1356797E2ff9261a6c4F307ed2b63c692943eE8A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.436839317104713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.44,
  },
  {
    address: '0x410ed13649886A7489b756bbFd9BD531AdbE44F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.408959336315974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.41,
  },
  {
    address: '0x7163c4112d72dee31B99440E4d2dDe504B09cde4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.401250293386482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.4,
  },
  {
    address: '0x766Afa3dEC5EAfb279e461F51e0399DB3E926F26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.400296202754355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.4,
  },
  {
    address: '0xD5A6280c960abe1111E57562cB5e114D276a8207',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.395367425700798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.4,
  },
  {
    address: '0x5e3B72c389f6d8A9047C6913BDF768f268928cDc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.395367425700798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.4,
  },
  {
    address: '0x442FE6E9fb0DDBfB604061a1DE6d9A16bcb3753F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.369938462485525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.37,
  },
  {
    address: '0xc452B46326756D3a573306dAF25E03A625E36FB3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.367565675761778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.37,
  },
  {
    address: '0xC4E29F3C265446a6d8315043e705ED504d64199A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.30974634529763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.31,
  },
  {
    address: '0xB0e216826D098De32139d1c9ec4F9f2288Ca4A2F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.302609316969928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.3,
  },
  {
    address: '0x012Bb4bF03b526298140Cc4039A1D6c8A995C226',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.293888266771667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.29,
  },
  {
    address: '0x1704557C1D8ee7d4FA7471Ba2b6aF1858d57A29A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.25990456024104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.26,
  },
  {
    address: '0xBE65395F8cAe7F28092Fc70095d90589b83fAb02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.250155685818864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.25,
  },
  {
    address: '0xa2Ad002d27de571797b855B26c7C3f8cAcd31f34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.194770196470515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.19,
  },
  {
    address: '0xdEe1715c5bd7B6670Ec74C01231251D887b17771',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.18829638744241,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.19,
  },
  {
    address: '0xb114344c7d88a2c6697CE5B8f38b3f92D7D120b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.17863446901475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.18,
  },
  {
    address: '0x532D72c19bee7f686675BBaC5477B8413247D818',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.156784065604334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.16,
  },
  {
    address: '0x0472a45Ebb948c50a38037e4E6676eE613e959EC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.12081270678091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.12,
  },
  {
    address: '0xFa73D1A2D2dA46EF1AC12141B8b9f564A301D59F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.112542526444788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.11,
  },
  {
    address: '0x696CA5906B0b56b2476BF002F2f0fA92Df3e3781',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.048043048067953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.05,
  },
  {
    address: '0xFCFCF8A8c0cD840F13734D862C300D42a1EF198c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.045415997549778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.05,
  },
  {
    address: '0xbb3a91B8A3fDA620F8A623078644004e403d82b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.013003381296716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.01,
  },
  {
    address: '0xb8f760be906b84D53F21B81D50A60EfA626D2E2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.002100144882462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29,
  },
  {
    address: '0x424e0502796137574c2cD5a6f78E33926D2b650E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.96059487503983,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.96,
  },
  {
    address: '0xF9091f36Af2F0B68e5B6fffE479335ED67739814',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.95411822664175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.95,
  },
  {
    address: '0x0827204394C018c9F4e399E9c2f1Ef4bf8b21C12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.93775999651192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.94,
  },
  {
    address: '0x06Fb387E5Dc337A2630Dae852FcB2d2AE8DDB287',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.912952802733866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.91,
  },
  {
    address: '0x0dad7B2e72122Fb6a4E27B604962f50A01baF462',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.89695803669467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.9,
  },
  {
    address: '0x4de6736c9Dd006B25C0823e736D8E504287bF0Cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.865489852729866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.87,
  },
  {
    address: '0xd233D640d31E11FD7F9859b18E7448071fe4C619',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.827403545583547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.83,
  },
  {
    address: '0x6503aE7778D2256Ce93CD2E5bC938Fdcc17E7a7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.825366409505605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.83,
  },
  {
    address: '0x54B57aCD7993283B060806cA3617b4d2677D25bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.799363474376985,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.8,
  },
  {
    address: '0x58Ce86c6ccC0A47129480d8A0D77eE945298A47b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.79825549291034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.8,
  },
  {
    address: '0x3579594b316531DCf79eAe43733c94760Acd5A5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.788864692194405,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.79,
  },
  {
    address: '0x2FcE3035C5aFE5750aAfd8BB909380e513051298',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.773129014992783,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.77,
  },
  {
    address: '0xcECBB4903c7712bcE0155DFB8dDB06D18fc74699',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.72327954140613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.72,
  },
  {
    address: '0x360a66F407FA40b8DC15b508496243F72162BBfD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.71398477793507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.71,
  },
  {
    address: '0x62C4a598547f66344f590D249a0a5a2bB3Ffb2e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.69279727719853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.69,
  },
  {
    address: '0x6E38Bbda5973E507bcEE3Db5A91164e7f162980b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.62925706557176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.63,
  },
  {
    address: '0x37250B9D07151F84915980FCFB0a5f63589beEb6',
    minters_reward: 0,
    lp_providers_reward: 3.44,
    swapers_reward: 25.180813938938588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.62,
  },
  {
    address: '0x1a31EbFFd2a22b31d9Cc24a7F26D86fB22428647',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.61333282962192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.61,
  },
  {
    address: '0x1dbCF1bbeD221001ad2c69D0ceccfDC9d08DEAb8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.593588668751924,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0xb99c4b4EDc5196a33dA21A38F0ae0e76AD0293Bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.593063435855154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0x7133013448D7db983E4712D1805330bAa51AA4e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.589298854428872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0x16C6c6DAF129d69845d7ca7EeEf600a524e43999',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.589298854428872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0x49b0f8890eBFcDD6B8397EFF545E9483524d13f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.589298854428872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0x27d5ed8C0b749729788B694a8Df0193eBdC266EF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.589298854428872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0xBf32BEB1bc33175417471e4C0437da2999434A99',
    minters_reward: 0,
    lp_providers_reward: 28.48,
    swapers_reward: 0.10543238147894969,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0xA4390F6414A37660D4d461584A47a0455923ccF6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.57409781028625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.57,
  },
  {
    address: '0x3D1038CE20Bf68Dadd4c0FFE4F767FBb5D185bc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.521308095209687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.52,
  },
  {
    address: '0x1e49a5f14C0ebf2b5C67aC12cfdc16b582EE722f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.517508138254808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.52,
  },
  {
    address: '0xe744Dca114A3D590954bc8d61725F979CB8113e8',
    minters_reward: 0,
    lp_providers_reward: 28.51,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.51,
  },
  {
    address: '0xe5A601d8654de42755ce5ee91703e0dF6cea9365',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.46812866322788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.47,
  },
  {
    address: '0xC2c11F9DF3E7c0e5ea42Ee0Eebf7F8A9F86C094F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.456924526100373,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.46,
  },
  {
    address: '0x20F4858c446a5a80539e04477246615fEc76bBe9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.45589045456356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.46,
  },
  {
    address: '0x7F38129387f522A0B5227f9c5c6Aca5e58E25Dd0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.419452836908725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.42,
  },
  {
    address: '0xf178288605d04c33799d50e211bF1dBaA6a85Eaa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.400084786592277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.4,
  },
  {
    address: '0x463F06b718772D38A3B22b48a779Ae1b3cd481e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.3659760552994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.37,
  },
  {
    address: '0x75d72CC8A1aD52B50cad1Ea6294fcF609eE7E7Bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.353953330443787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.35,
  },
  {
    address: '0x7F04dB148B1e44C150d7B47cE92146626c0ED890',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.318698050578583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.32,
  },
  {
    address: '0xA15d5a4E006D7b19614Ae9B5c08C9c3FBCCF3654',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.300408913553934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.3,
  },
  {
    address: '0x7170bEBABFA194820d4569423ed88e1067CbC8a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.26092393507788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.26,
  },
  {
    address: '0xC8665b8a1C5Ea52d2576432d5E79e4D9b3AB4fdf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.128054873868965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.13,
  },
  {
    address: '0x256525C008b098304E5F8eD2B5573688A2Ffc05D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.117886438979088,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.12,
  },
  {
    address: '0xD49970ECD77F79E240ED3e078296A40E638f3166',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.096262752625595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.1,
  },
  {
    address: '0x226ae923715FBa1702E7E3a11e5Fff10b971aE31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.99745527038313,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28,
  },
  {
    address: '0x6c21cDc71766C5715d04a2e6DF6F240e76D23e53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.993275936148944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.99,
  },
  {
    address: '0xf587ba70Fe86bf3c48fcB3F2bE8724659a26aDC8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.986240887752114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.99,
  },
  {
    address: '0xE25a82e6d7a347F93Ad1E9C688Ff08CC718268dD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.98130200935519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.98,
  },
  {
    address: '0x6DF95c1F5AdD9cE98a013AD9809782d10272c6b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.979417273186563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.98,
  },
  {
    address: '0x96af22cBc1693E2bb8FB0eBa10dC665b4E5b3092',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.97614581148802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.98,
  },
  {
    address: '0xcFF69866f2d63Ff28A4ac920De1b32E7DA23CE71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.97306627579675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.97,
  },
  {
    address: '0x13E2d48907cFB0E99A9B1e9F859DcAD99EF340a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.97306627579675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.97,
  },
  {
    address: '0xbCBBeBF1A391462751D346e50D86C217B33f88B4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.96295184253376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.96,
  },
  {
    address: '0x4ACf79c753Cee0B3416a084B0D0F40987C8CD42B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.916800165803615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.92,
  },
  {
    address: '0xc9296c14A59a239A49815052ecBC662F81Fb75AF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.87716859540122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.88,
  },
  {
    address: '0x74B868564F4185E1C3Ca83E9d848f7092818Dc17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.85135254857493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.85,
  },
  {
    address: '0xB7B47Df7291f285d11E22396A14F1008F08929cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.839102507234117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.84,
  },
  {
    address: '0xCe43C2DC32eb7e03E305A27D6976380881881e4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.838772686671657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.84,
  },
  {
    address: '0x9882B09Cd65d30a80CF5BA70Fb2EE4BF04958FE9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.83740153813294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.84,
  },
  {
    address: '0x9C2cd203b44852838351aAF15fDBfcfCc151A4dC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.835816302111024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.84,
  },
  {
    address: '0xc35d4e79a139c2F8f6f11AF5d571fF68BF925B3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.831644650508572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.83,
  },
  {
    address: '0x3E083F453213E7F6a6Ec318B256FaB67D82EA971',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.827412764980725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.83,
  },
  {
    address: '0x7698F70fc6c1d735a168B56A99548bA03F518C6D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.82293488527986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.82,
  },
  {
    address: '0x4a8e19032a8aA4366B6219b5B66A8a3aC97D1185',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.765457976982614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.77,
  },
  {
    address: '0x3765489A593A0d31a479b5cb83001B33AC9b416b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.749890545997907,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.75,
  },
  {
    address: '0x9eDa07D7da1503E76f873ac77fCfDFD0c9b5bbe6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.7326456696053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.73,
  },
  {
    address: '0x0DB2fF0223cf3058d3b6A43db6ecd59aD0572323',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.702253022086737,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.7,
  },
  {
    address: '0x8e8c833f9CA31bFb9d7dF8d2dEEf3F4Af2C11d87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.646393368433916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.65,
  },
  {
    address: '0x2BD6C55a17048085823A79b658B1e2BA581cC956',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.58852026052392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.59,
  },
  {
    address: '0x50c7c0aB882247566Da5a00AEC268541cd5139AB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.587458613578253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.59,
  },
  {
    address: '0xcf43fBA250Df30c8e92ea3d09d58fDecD20C859E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.53081931872348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.53,
  },
  {
    address: '0x31a73De8C617cb117D647479355821c834cf3374',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.47901890717591,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.48,
  },
  {
    address: '0xc40571b08EDdBAED29C0F5761D20eb61EACCBD4E',
    minters_reward: 0,
    lp_providers_reward: 27.44,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.44,
  },
  {
    address: '0x689c23e06DeA58275fB616a197285ba76a493731',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.416108234062243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.42,
  },
  {
    address: '0x65fe4F30E521318806C158636542c4F2940005c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.394629984159003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.39,
  },
  {
    address: '0x7abC86A0B0F1219CD68E5b00E688FDA25447d1C3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.38057016970169,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.38,
  },
  {
    address: '0x83F86369a994579476Ab0318C2bE061226c6C4b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.36956066927419,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.37,
  },
  {
    address: '0xc36FB3Dc19045Ca76Cd20087e43B307D8fc9773d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.352729036283343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.35,
  },
  {
    address: '0xD5D4373052655aDB506A20ffE5bD6a5CD8Baf8F2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.302086549694696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.3,
  },
  {
    address: '0x3433DDE30a25a60B49172CEaB310AF2e6Dfe945C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.25138443966789,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.25,
  },
  {
    address: '0x3126eab5b49Af5D934b04768413c6c2DbF05e614',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.246410224606528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.25,
  },
  {
    address: '0xdFCB5f0eeCbf1465977aE1b9000FecaccbDA0ee0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.18251580247409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.18,
  },
  {
    address: '0xba9ff201aDF2FC0427A60171725bc7B95AE4FBf0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.16168124141668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.16,
  },
  {
    address: '0xF870c46082aD376665f84D83B427B0a8F9E7e338',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.08891657916117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.09,
  },
  {
    address: '0x4A62E1416820d34B810561572A6a794a7DA2Aa46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.088906160039365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.09,
  },
  {
    address: '0x910Febb344B47dA528a4cE7b7aD0711EaB917a94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.088906160039365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.09,
  },
  {
    address: '0x823F24E336193e459C880827FFf2F6087e64E043',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.060005416851084,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.06,
  },
  {
    address: '0x115dEe3599bECd0Eb1eca30E13755768E5591C8A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.0554649607472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.06,
  },
  {
    address: '0xca3d176a5231C24De1a69763883032411E81Eb5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.052983231572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.05,
  },
  {
    address: '0x14301A619835586cfD81e76De4aCA030cFd3b429',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.013257473231477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.01,
  },
  {
    address: '0x463d6E56a91caad2B41cB1B389486AE7751F459A',
    minters_reward: 0,
    lp_providers_reward: 6.65,
    swapers_reward: 20.36280601047361,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.01,
  },
  {
    address: '0x3fAf150B8D5F418FccAD79bA605a8D4b6C0767d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.004152806502603,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27,
  },
  {
    address: '0xcadC4A3909a026c35b34305CBAee7B4320d8E093',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.96719331255258,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.97,
  },
  {
    address: '0x10f6dfA68d3fF1f28dAe1884dfb3A98eAb41bA41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.933000428136445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.93,
  },
  {
    address: '0x2D5f217c91C1e274811bD84Bb131329F31d22212',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.92933204999172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.93,
  },
  {
    address: '0x3E1596A814D70f4E63F7F1487C5807A6bb04B967',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.869516260105854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.87,
  },
  {
    address: '0x45D8b2ce35b218C6a47A85FA5fC0D3849F43D21b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.82900241361668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.83,
  },
  {
    address: '0x38439739cBbd1Ec112333EF8abfE44013727a093',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.808344128080158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.81,
  },
  {
    address: '0xd30c973F0dc4dcbE8E0e689e23c1dD3EcA2A0dE3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.807946406369588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.81,
  },
  {
    address: '0x04bA93aFc239fe80bC7b2D7E88CDD195B82E5ad8',
    minters_reward: 0,
    lp_providers_reward: 26.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.76,
  },
  {
    address: '0x088F0D80038720Ad4244F0586B40f5B985D8381c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.74737919596647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.75,
  },
  {
    address: '0xBfAC8003DcFd6542188d3E7F4303FEeA76158C93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.717161728380148,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.72,
  },
  {
    address: '0xbCaf41B6F6784bceF117fc6640AFaF34027cb9A3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.605761960954073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.61,
  },
  {
    address: '0x506aa5C6E608f563d8D4c88B8fd82C5eea6CEE28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.58368943043306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.58,
  },
  {
    address: '0xB5CF51aD7A013Fa95C3D176cBDfF29Bc039D4eC8',
    minters_reward: 0,
    lp_providers_reward: 26.56,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.56,
  },
  {
    address: '0xfabF58187434c217cCeaDB91cA7d8f0af4338a62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.511435779646515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.51,
  },
  {
    address: '0x819F44e8A20911894F349C307Aef2EaC1d7BAD0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.457588869732355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.46,
  },
  {
    address: '0xc3F833ABB857219304b9dF45166cf3A99F355a9B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.41312789845661,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.41,
  },
  {
    address: '0x1E7eC2A491A4f2d3A57d20A9F44DBc59D52A2ce0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.406766701631355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.41,
  },
  {
    address: '0x4cB50cfF34aa2571D2E458dFe708419D3A1fF64A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.39185551231094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.39,
  },
  {
    address: '0xDe072d7FdE88d51BC091DF67b28c1545952FCAF0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.3738760759485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.37,
  },
  {
    address: '0x58c400bD861a625391B99AC1e60DDC82f971942B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.355730376746198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.36,
  },
  {
    address: '0x8332545194520a59460cFFe2Cb654b27E09EcD19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.290670492941715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.29,
  },
  {
    address: '0x2F31393AeA9EB0B8F3c3995783c142bf97034f32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.26815486631258,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.27,
  },
  {
    address: '0x49FfAfbC9d1684A6f18fcc57ebD832d469F6Bcd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.263531079862467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.26,
  },
  {
    address: '0xFc6C02b76b6a9b9CDCc37E3dCA2198b345a56a61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.213769742023253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.21,
  },
  {
    address: '0x281A7a9B0eD0a4072bCd713916Edda197dFf194E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.198786992223194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.2,
  },
  {
    address: '0x47AC83Df48B7253e69d13B2e89D8c0EdB38Af5aE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.198345838301815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.2,
  },
  {
    address: '0x2Db4BaE24b3300586926c0862d1c229051fa80cE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.16283575480706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.16,
  },
  {
    address: '0x0F993A4524634aaE8A6cB3469AEF917cA8Dd1822',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.155809299032928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.16,
  },
  {
    address: '0xA8336349bfF50D4475266C80bd412C99e107749D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.135885678631656,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.14,
  },
  {
    address: '0xE7CF3B2350E5df65d9e43E15a458B05cf044B46B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.11699983023866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.12,
  },
  {
    address: '0xBcF100381272a7437aacEFCdF8Ff71FCb256E12d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.0604998510666,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.06,
  },
  {
    address: '0x0406d480b7Eebce5B94ED7D63A60E70636980676',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.024231756352563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.02,
  },
  {
    address: '0x1a32297c1F05395FB3a18f10F154C57d58FFd738',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.01590502924263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.02,
  },
  {
    address: '0xa1fb40CB95EEa13e94136318F949eC2bFD991711',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.918538240856133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.92,
  },
  {
    address: '0xeA32f6f22F2492F128c0Cfc117BdeCb67EAE05C2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.797816284346275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.8,
  },
  {
    address: '0x648A6B9C22158FAA95C19f9F4883231Ba85116F8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.711622264889133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.71,
  },
  {
    address: '0xcFB92C1A70EE9c84366B56ECb46B9b0C404d3b76',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.69470776426749,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.69,
  },
  {
    address: '0x7c22c8e8c6e652DF62704F51Ef736182EfE3691E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.669704583048674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.67,
  },
  {
    address: '0xDa55185d94919CE175a7d5c0B05e741Ac3909b21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.659061091025382,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.66,
  },
  {
    address: '0x98690A03135BE924FD7926f360313971999764Cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.64996358710592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.65,
  },
  {
    address: '0xFd4845D35F3890FcA826C05FdBb1923C95ba49cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.64872389551155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.65,
  },
  {
    address: '0x69a0F7ca9ECdB343ffd269806e198006FdCda32E',
    minters_reward: 0,
    lp_providers_reward: 19.17,
    swapers_reward: 6.462214249239553,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.63,
  },
  {
    address: '0x143C42cdC39101261227288ec2De874aA80D03b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.589613096389705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.59,
  },
  {
    address: '0x4BeC18F2256E9F3dBC002935a59BE7BB02a23140',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.586300005104516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.59,
  },
  {
    address: '0x192a75335646c561B513fFf5201236c7C940BF77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.569207356092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.57,
  },
  {
    address: '0x43fA7201384b747cB175351d5F7A20c089a90f71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.559756696395155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.56,
  },
  {
    address: '0x70D512d252D89d903209e20D38fC2f1ae3106b51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.512929589133922,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.51,
  },
  {
    address: '0x27e22501AeB900FDfD0158Cd09748e6f731F4876',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.47797329655682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.48,
  },
  {
    address: '0x48d92467723D390Aa5d88A88cdD2a0e19Bd5E04a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.396249196636223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.4,
  },
  {
    address: '0x6db0A70dC197a2EAf2960A87f973d870A9DDC103',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.394256683880297,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.39,
  },
  {
    address: '0x864E67aE0c447B2573E6CfBAB09dd726Faf87a26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.386529722059358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.39,
  },
  {
    address: '0x8E442180F3eCE517A42fa1C22c4836ac4179133C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.374195200638763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.37,
  },
  {
    address: '0x4AeEa0Ac22dF0F42176b9cC76BCe3151153E0D69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.333697851299277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.33,
  },
  {
    address: '0x093483C9BeEeFF276b105EB8DCa6cd153237544c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.302769636176297,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.3,
  },
  {
    address: '0x3eFba836350E9c4B772Ed1FAD02F92e4086979e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.237782799950352,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.24,
  },
  {
    address: '0x7b6E19eA6b90300Fa5D4eD554074164DE1439278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.185603924916403,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.19,
  },
  {
    address: '0xfdeba4a23eb6922b9446DFb5C81Df0b2dcc1d3B5',
    minters_reward: 0,
    lp_providers_reward: 13.14,
    swapers_reward: 12.013849112002504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.15,
  },
  {
    address: '0x943adB45B547a94B7015507a53C9cE26c97b185f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.093546032214608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.09,
  },
  {
    address: '0xb44dF91F22f4372aBa7e4Cdf62f47129a4f10956',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.02460586650911,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.02,
  },
  {
    address: '0xe94318A6B1768549e4866AF5408a27e9521321c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.999928692492713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25,
  },
  {
    address: '0x073f620553c39e8B5a842e07FFCb6931974056aB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.942813627786474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.94,
  },
  {
    address: '0x17E6991D4c0Ea6Edc02c46834c40D462D9C7a617',
    minters_reward: 0,
    lp_providers_reward: 24.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.92,
  },
  {
    address: '0x9C3Ec50c4ED16543F18364eB9f404603AE82031E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.893622949718132,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.89,
  },
  {
    address: '0x9cDf52dfd8c64e81568a0C95B3a1a1a7985ECCce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.844365495230193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.84,
  },
  {
    address: '0x8DE11564B70AA61f4e7572f37A568A974e7da263',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.83237913382855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.83,
  },
  {
    address: '0xD5d5CF8eba5e84F138409b348F15445F3B344FB1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.743255764970986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.74,
  },
  {
    address: '0x37a92b223825a281Ff7454C38CBa232F698d8b08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.72825178812794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.73,
  },
  {
    address: '0xAD8D7F65309dD116360fd04d171ea5160E134f20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.625056937786788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.63,
  },
  {
    address: '0xAc6B5cAAb501ef4918bA490A56358Ff6A37Aa9ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.617729661589365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.62,
  },
  {
    address: '0x8fA387Bf5BD3C76e804490088E22101F3fC8e499',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.615882083525506,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.62,
  },
  {
    address: '0x00788221F81A0d9bC93eC5bA754c220Fd73ab97e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.612287010444515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.61,
  },
  {
    address: '0x86a3F951313235Dd2869923363Bf88E0eC8d64C9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.536104679949524,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.54,
  },
  {
    address: '0x6e3BF3c7bD91144D694621298cB8dA370479A7bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.536010670738758,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.54,
  },
  {
    address: '0xB3A47e1455080b31E700e5E29DBAC25ecaE07bCd',
    minters_reward: 0,
    lp_providers_reward: 14.49,
    swapers_reward: 10.020017092488391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.51,
  },
  {
    address: '0xCC0E81C65f995a303eaDeB43Be0DfC6DD59e27ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.493521640175445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.49,
  },
  {
    address: '0x2E5711196832B52B1df598894Bc3479B45e7139e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.445970976436296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.45,
  },
  {
    address: '0x4CA2219E83DF28f1059fDf140e3A29068E513487',
    minters_reward: 0,
    lp_providers_reward: 20.78,
    swapers_reward: 3.6665769329591456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.45,
  },
  {
    address: '0xE8716d0e896F9a87F9625AD5442aC5E2C286c601',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.428690713507915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.43,
  },
  {
    address: '0x0197B16F59a9FF17F19faB230B4D0E5FbD084039',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.398572609447314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.4,
  },
  {
    address: '0x8aa0eB46Da7Fb9F6fC5dEE15b0f87fE1ae9545FF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.352635814233786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.35,
  },
  {
    address: '0x13d577F3c883FC859Fc01baaAbB20E6A8695b916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.34252967006208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.34,
  },
  {
    address: '0x567E363d3Ca2548b9523b849d76254E97e8ad444',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.327857501088054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.33,
  },
  {
    address: '0xF58c8eA0E6d392c5Ff9bF87E5c78b39b4216cd06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.32356562130935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.32,
  },
  {
    address: '0x6de6a592b18C985EEDF28705132dB700C053ad7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.31709270967161,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.32,
  },
  {
    address: '0xB9d77378c989036b30c588CF5469C346fF0F0a4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.30295745346756,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.3,
  },
  {
    address: '0xe590C7c73aCaD3c0c7D5974Cac9095bfab93757F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.233131684448804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.23,
  },
  {
    address: '0xF1fAFf2C2DbCe708e982ad407245Cb6259D9cfEb',
    minters_reward: 0,
    lp_providers_reward: 24.13,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.13,
  },
  {
    address: '0xA1186c1D7887fa050546bAfAbeD8D1c0E60A6a81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.10673269864591,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.11,
  },
  {
    address: '0xC3612ac99e936b94EcFdb1833E64d453f9a4fe6E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.094034986291813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.09,
  },
  {
    address: '0x3ec854ff288aA5FE1FBb7903657a09B7aEEA211E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.089627080675495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.09,
  },
  {
    address: '0x2937C1fe9AeeA55E1A091f82d5D63A71D4cceeF2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.078418076754694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.08,
  },
  {
    address: '0x805380e0372031d2a54E761aa9A053aD673B9e26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.037964898903105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.04,
  },
  {
    address: '0x3a6bc9d056265a20b34305Eb58D82150D66343B4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.008533431072504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.01,
  },
  {
    address: '0x1969cBEaE5Bf512e1D9D7aE16e5989be7D4e62d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.002812803815665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24,
  },
  {
    address: '0xEF06242324Bef850526e4910f84F12af58B61dc5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.967416274274843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.97,
  },
  {
    address: '0x908e9C581Af20B97A17A190b4B27d3832d31bde2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.903621384996192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.9,
  },
  {
    address: '0x804A22A5FEB66053a69A13dA04A4CE1530422D07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.888504726876842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.89,
  },
  {
    address: '0xF07B5119dEda3eF60A3924DFaEe511fEa5Eb20d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.74587836982773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.75,
  },
  {
    address: '0x07CaC66771056554BDcDfB99e212F455DD042012',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.736857542655663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.74,
  },
  {
    address: '0xb41A4817268fA59a44c21B59506AEf6B710b3726',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.72701635672843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.73,
  },
  {
    address: '0x4fD3afbcb67b2e5Cb032318e6a264CD12abdee29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.722117834575187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.72,
  },
  {
    address: '0xfD215a081F7FAd11a04503FADCffB0F53Dc4d95f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.664474282427694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.66,
  },
  {
    address: '0x4Fb3E12B7f915cFB46416dADBBb3802BA39373b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.655318636826255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.66,
  },
  {
    address: '0xe61818c83E46AA4977e2ad3c228a9091fBF8F031',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.62472640853331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.62,
  },
  {
    address: '0xE2F4aE60152dEcc4e136a0Cf988A81D813dB8a83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.621968943445694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.62,
  },
  {
    address: '0xEA91CdE7999DE4957EdAfbd6CC410717C3751F57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.517144346370923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.52,
  },
  {
    address: '0xBE7c04d73C44b52904575d1822A6bB71dc6b73D7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.49043268511116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.49,
  },
  {
    address: '0x939E5F4EBB471804B8a11aF944eAf39b72EA04D1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.4519828602141,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.45,
  },
  {
    address: '0xc9E5acC802558957D76b9f925deFdb076286A737',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.4411928049152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.44,
  },
  {
    address: '0x16310F905086eC443853c10B34101eB43446950F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.421284654871965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.42,
  },
  {
    address: '0x67857fcce1a9292b0c2cD80782200f9fFB5Fd94d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.404602296371877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.4,
  },
  {
    address: '0x90C763616cEef16984DED647AC731D6C16376AC6',
    minters_reward: 0,
    lp_providers_reward: 15.5,
    swapers_reward: 7.858275330612227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.36,
  },
  {
    address: '0x66d0a9A9ff17267dc7ac07FA1706f338c0a07472',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.27524378299432,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.28,
  },
  {
    address: '0xd427469aFEa1C02B67e9f5C7b542d6162332a19F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.26361501621785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.26,
  },
  {
    address: '0xcC00908019Af0970102E2345fB120BdC421A4887',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.242523914057045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.24,
  },
  {
    address: '0x1E598f6D0850Dc1932AfBB9AFB46167f437Cc13F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.233445100213583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.23,
  },
  {
    address: '0x91dd49B5fC7Faf08Ec9e85f2Ab0155A6F46CA75D',
    minters_reward: 0,
    lp_providers_reward: 23.23,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.23,
  },
  {
    address: '0x8f444f144D6e0C9de067C2f9a0711b096b1506D6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.224096271492346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.22,
  },
  {
    address: '0x27670fab384743490Ba09d5E851CD3720E8C96Eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.196163878015724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.2,
  },
  {
    address: '0xd897D451EE0397408BD88b8dFd1272BcA94b1B8A',
    minters_reward: 0,
    lp_providers_reward: 15.49,
    swapers_reward: 7.619363506068371,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.11,
  },
  {
    address: '0x38B0A91D81F28f6D8F03eA4706154fa813ADD94f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.088473236011325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.09,
  },
  {
    address: '0xDE51044D8b10C656D4061BC2E7b1263a5B0dd9E9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.083082276266698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.08,
  },
  {
    address: '0x57F63643F8CBC97AcB6Ad201D2E15C9C75aF8fa7',
    minters_reward: 0,
    lp_providers_reward: 19.87,
    swapers_reward: 3.2145537185661093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.08,
  },
  {
    address: '0x62815f4b3DE5A94620D4e81b9f965b148a188a4F',
    minters_reward: 0,
    lp_providers_reward: 23.05,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.05,
  },
  {
    address: '0x4706F6F9945d8665cFE5eC56ae67D00328D6Ff47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.8959330391528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.9,
  },
  {
    address: '0xB1Cc26691026f31a9eA9cEB584d6B44E0E6D0B8b',
    minters_reward: 0,
    lp_providers_reward: 22.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.9,
  },
  {
    address: '0x1b89A5cc6dACAeEf46Bc51521Abb0a8b863034cA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.879462404941126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.88,
  },
  {
    address: '0x854c6B662fE84a6537474DD3C77a52C30726d101',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.87664500400428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.88,
  },
  {
    address: '0x2699d784227e5ecd2eFF6BcE97C5d546ac56e72e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.676289858402825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.68,
  },
  {
    address: '0x5b41445edd946DFf6775016a139EFDEFEb2345AD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.655672295816288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.66,
  },
  {
    address: '0xe37d4eC17Cb2A1C1c4f184Ab15Df5cd45027fC7e',
    minters_reward: 0,
    lp_providers_reward: 15.69,
    swapers_reward: 6.951036378580876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.64,
  },
  {
    address: '0x9a978B46566A771c67EBB6318565AA8cd6d7B436',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.57116391260269,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.57,
  },
  {
    address: '0xf7c9E4Cf61Dd31eF62bBaE04f9Db1aD233554EB1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.561221010329657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.56,
  },
  {
    address: '0x420055E33a3c2a6Aa6e11363D076Efb4ECac1AD2',
    minters_reward: 0,
    lp_providers_reward: 17.85,
    swapers_reward: 4.665044179932622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.52,
  },
  {
    address: '0x9FC84977F65611784bca98D538775cc49963aA3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.444497164857687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.44,
  },
  {
    address: '0x0cfdd6a6e2f24218eDCE116b5260451f7682C83a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.436409625014946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.44,
  },
  {
    address: '0xc738b9649779D98b6d62f335E6fd0D58cd790B82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.42712834742234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.43,
  },
  {
    address: '0x2781014b1B7Da1D0b7Ba799c63eADD5A433071b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.366236300902244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.37,
  },
  {
    address: '0x15E1cf3096FCF88Bf4C5b2C6f6668dD42F54f637',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.242464025406882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.24,
  },
  {
    address: '0xBA2ED04E467987B99521a9ECaB0DE9756C56cCAc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.204600324292894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.2,
  },
  {
    address: '0x289cab8984C350eD03019042528378e8d5A3c888',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.195717454176833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.2,
  },
  {
    address: '0xD926148E9086ec311440Bf4654cEecC3f7E933C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.16885916524566,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.17,
  },
  {
    address: '0x6EFA048f7cC35d520EA3C49fdFDCCD4d7e09207f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.109958625764747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.11,
  },
  {
    address: '0xA090D45f4524d79cfDcC34b0a1077E7494cD42Da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.09361364729116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.09,
  },
  {
    address: '0x1A687F10B4DCC8EffE5d58bB28991FB22F6654Cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.077629487828535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.08,
  },
  {
    address: '0x27Cd07CAd63183663075d3A6D9c5def05f94016C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.05449740473558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.05,
  },
  {
    address: '0xaFa31fF69039d436a80517c6461ce996D5A108dD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.039205637278087,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.04,
  },
  {
    address: '0x7819602d9d698B4ACD7026D4DEe4783845533e0A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.96770408378332,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.97,
  },
  {
    address: '0x0CE159ABa3C592c030789f5EbB8382CD990E4c91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.959191990041713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.96,
  },
  {
    address: '0xe4fb834531f5Ebf9C2431b42FEf7baf37f72843d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.94368372328984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.94,
  },
  {
    address: '0x1F0fd8079F0e1887Cdc4cd4940AcbdFb7d9F130e',
    minters_reward: 0,
    lp_providers_reward: 1.56,
    swapers_reward: 20.34749069072038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.91,
  },
  {
    address: '0x8C4927247ad991F02E489B84cB86797FfEb58D21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.8932642699972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.89,
  },
  {
    address: '0xD9792829505d4aC58D93FF57e5b6f65C2b83289B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.81550942876888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.82,
  },
  {
    address: '0x508C2fa73407B5D2bAbA511Ac420539cA90D071b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.723250026078183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.72,
  },
  {
    address: '0xE9332951B930449A71Eff22d17F5e84942E412a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.71639238474988,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.72,
  },
  {
    address: '0xd1C1457942a6522f465e0949e2486cd7A0bc283c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.654511627747713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.65,
  },
  {
    address: '0xfD9B8FA669CAdA68f15088B86b320EaF4Aa943c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.639583120160975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.64,
  },
  {
    address: '0x067f79772846Bff961027C2b9e13Ac70087Ab2F5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.62948154829222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.63,
  },
  {
    address: '0xb7E1B1b619Ff9bCE2FB642f3FdaD301c8D1cD7Eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.609608078863193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.61,
  },
  {
    address: '0xE47aad4641E2048599e4994c81060a54eD509783',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.449837515683605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.45,
  },
  {
    address: '0x1D73ad505a463A7CC3FA453b0E12C47C53450b62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.415290856142263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.42,
  },
  {
    address: '0x87483e1e193F8A7751837a25Dce210B709070774',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.40532633944122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.41,
  },
  {
    address: '0xa9fC77120c5714265dC50C9F211D445a655629Ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.402817690948176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.4,
  },
  {
    address: '0x61e3B7730F1Dd36FeA4fe79E477f71E9762379A7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.39914309819175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.4,
  },
  {
    address: '0x335ea878801e85CD874d059Ba686ba0cbCCD12E6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.390175337042518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.39,
  },
  {
    address: '0x6BfA628B0d625380F327Fe639a06f34B31304803',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.372784464989596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.37,
  },
  {
    address: '0xA5047309Bb8D9dF47F4345513cECdaE41D479ACD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.36752784355892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.37,
  },
  {
    address: '0x1ef3a4345E2C5126B16147E5fba386359E09A108',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.311930358085284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.31,
  },
  {
    address: '0xfeE5581B1871D3745D1256e0F21abf1620a80F1b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.270223808066817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.27,
  },
  {
    address: '0x0cC2574902176d186d327aD6D5820161F8Fc03Aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.26965682221746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.27,
  },
  {
    address: '0x7e2A26903dA70677bBb07F169bc4a1339A18D1D1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.264228145353094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.26,
  },
  {
    address: '0x26b88B8D5B9EaaB331945235df3163a2e9615850',
    minters_reward: 0,
    lp_providers_reward: 1.6,
    swapers_reward: 19.655921544011672,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.26,
  },
  {
    address: '0x9AcDBb0DEC631E034Bd058c88DB68c5F77a8bC8E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.249760750691824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.25,
  },
  {
    address: '0x80C9B04E42B710290FBEB38BDC9Cd3E23C5D8b07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.214836051919818,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.21,
  },
  {
    address: '0x949891BD4C7108a7A5DDd96415c4288431235f61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.103212709589073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.1,
  },
  {
    address: '0x04eA1059f50e3229E3D26240c82f425d36Bb0Ec3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.087793797692886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.09,
  },
  {
    address: '0x646Cc92EfA51ef7A724d48A9B7DB43bDa7F13323',
    minters_reward: 0,
    lp_providers_reward: 8.66,
    swapers_reward: 12.411851814551612,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.07,
  },
  {
    address: '0x8C6D4beAF291D508575cE5d6b47Ce81A34E9e1EB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.05561646350758,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.06,
  },
  {
    address: '0x363d878321Ec9bD2c0B00b079B75C81f11328A09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.02537047526041,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.03,
  },
  {
    address: '0x86BCF723fA16f19407eC527d193EDd3478a44160',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.946387932987804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.95,
  },
  {
    address: '0x8709672cd6843C0BA4c7c6BF16b460b6bA2C446D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.907460902724164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.91,
  },
  {
    address: '0x3349Dd7cA2f777fBfB9FCD90627a30641e43A80C',
    minters_reward: 0,
    lp_providers_reward: 12,
    swapers_reward: 8.91144445464242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.91,
  },
  {
    address: '0x34168cc87453204CFC62927d048119160a121908',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.89495379404397,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.89,
  },
  {
    address: '0xdD1e39a9fC211AD7A7076Ccb6D962B7F1593ff21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.88394904587523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.88,
  },
  {
    address: '0xD79051a65845A01C2d57cd06FcB0099839803ED0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.870516917057266,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.87,
  },
  {
    address: '0xE1Ec71F29999Ac98751736A05a47ef78076c6709',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.847530229008633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.85,
  },
  {
    address: '0x2F25b52256A34E2141A12d9996D600231B44073C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.790478382490843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.79,
  },
  {
    address: '0x76E1E84a4aD3e81eae9b0663A6c851638A39D82E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.76291564793416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.76,
  },
  {
    address: '0xf4191D3e771e6c2A9107FDbCbE66298d998efDF7',
    minters_reward: 0,
    lp_providers_reward: 15.45,
    swapers_reward: 5.3079173488921265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.76,
  },
  {
    address: '0x580E7176533466424e17EC287D95DEd08c2d3D5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.743111143483617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.74,
  },
  {
    address: '0xa5288dc2D538da0b799F5626F2D3cf07683534F2',
    minters_reward: 0,
    lp_providers_reward: 18.46,
    swapers_reward: 2.1949167913079943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.65,
  },
  {
    address: '0x9d3a28CF2adB9B6F133a6f938386730c289327DB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.63067119751032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.63,
  },
  {
    address: '0x41b903253c88bdb12377E43C33f3Ed75cEe49546',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.622970370487604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.62,
  },
  {
    address: '0x442c8a5965d6635f8B7c1372bA81d6CAfE988899',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.601483183054803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.6,
  },
  {
    address: '0x2EAE18a406C744cb702309e2bb652349fa59551E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.60111929330419,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.6,
  },
  {
    address: '0x721F150D0c8733ACA6AD9Ec9ae324e3663De6d40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.58792531807195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.59,
  },
  {
    address: '0x0Ef7fdC0EA116a75547aA2B28467e4624AeA688A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.575643112270026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.58,
  },
  {
    address: '0x50c0f3516429D6d65930335ED1f45f5c12720153',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.562081175546687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.56,
  },
  {
    address: '0x6832Dd1b88B285cDA0bce506D1846c3337c6CC06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.556115748929695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.56,
  },
  {
    address: '0xf1D5377BF43260362F06d57b8D752AE239EE158B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.530004598630367,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.53,
  },
  {
    address: '0x46ABCc6935bD690ed41F9D7AC0788cA8815F75B6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.525403789877007,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.53,
  },
  {
    address: '0x6F4fc22ff11D183A0Db7551Ac63AEA9a9F54e4dE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.517384655654805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.52,
  },
  {
    address: '0x48ea3B350Eb12770dd3675a6ddb5107BfA6532Ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.50745878075704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.51,
  },
  {
    address: '0xE02CA0d54862FADfffaCCE5bDde976AaC27a6Cd7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.478752428516742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.48,
  },
  {
    address: '0x0c58CfAB87A6aBb54593DA43D955E3a7A35C3012',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.460569949206743,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.46,
  },
  {
    address: '0x91fec737d852F693637dBa1aFbE8aF44E8eC13C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.456411180002817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.46,
  },
  {
    address: '0x878dc6cCb4b8D55Ae729bC40A301e76B23113024',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.402802984400825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.4,
  },
  {
    address: '0x84b8Afdb8A40C78837FC1134E9f764971158F3a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.400332016115723,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.4,
  },
  {
    address: '0x57deDAbe3757cEa7f71C33778b93e3AC7C857907',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.349243261372763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.35,
  },
  {
    address: '0x7cC841087c3fE1F7fF16c59B08d60FEcE1A59B43',
    minters_reward: 0,
    lp_providers_reward: 8.94,
    swapers_reward: 11.385765832218349,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.33,
  },
  {
    address: '0x423E565F107c87C5aB77742120c988eAE043CF5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.297658214967868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.3,
  },
  {
    address: '0x7766Cb6Ae6da8Fc890b28D951F182DF68463E0e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.294302808366446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.29,
  },
  {
    address: '0xCAcC35a84E16e9661123F8b2bD087cF18134b1b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.260387509344937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.26,
  },
  {
    address: '0xCE272228fe22d2F9e34961741DA8Ad010C0aaF52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.244265410005113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.24,
  },
  {
    address: '0x9b0A6B63fbe89d3b1a38F102C9356ADceed54265',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.231974844756873,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.23,
  },
  {
    address: '0x30903202efbB1DCC6EF7B1e9032FB25b507dE09E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.212228961102916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.21,
  },
  {
    address: '0x7a40AA2fb7aD9C06E668184D36256B269F2dE603',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.14845195831223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.15,
  },
  {
    address: '0xCD9fd48c6DC3E043D4797c4dC48F6759641Ff3c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.1379172315642,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.14,
  },
  {
    address: '0x40A67e2E380013E1C0Ff58f2b98F8620bAc0D22f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.065287282117676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.07,
  },
  {
    address: '0x1a973bF36a5849218846E89838d34B608E5130F6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.008711166378024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.01,
  },
  {
    address: '0xa105E2FBCDEa0DD073e1C289C744380f8a1890e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.00323259707216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20,
  },
  {
    address: '0xaE4Db9e9E2e715142E85757A449E064EB64aC96b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.985079016153666,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.99,
  },
  {
    address: '0xe8C3B306610C005226243E334717699fFDCe1498',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.952135186249546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.95,
  },
  {
    address: '0xFd9D6447466B47DF32214DAAdF2Cff2145124935',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.924839450747157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.92,
  },
  {
    address: '0x25CC0052175C88a73F5dc5541E86B00C78aAC8ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.912402104257836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.91,
  },
  {
    address: '0xF7B8400fe8e636f674e48c24BF8Fd220F160ac4B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.859251235068808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.86,
  },
  {
    address: '0xE1B589C2422Be4f6fB770fdB0e407ec4a02F3c5B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.702919024857874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.7,
  },
  {
    address: '0xD70e596FEc1CB98F0eD81b4238ea13d35c819F17',
    minters_reward: 0,
    lp_providers_reward: 19.7,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.7,
  },
  {
    address: '0x94cd56572DD571D050F7266eFFe2609941d6F957',
    minters_reward: 0,
    lp_providers_reward: 6.59,
    swapers_reward: 13.10165188215522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.69,
  },
  {
    address: '0x671a6193Fe2abB63a9B7E70677d506ea568efcf4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.623279820593595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.62,
  },
  {
    address: '0xa3E39dfC2FfF39587B02De2159cC80bdaf311dAF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.62127713034104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.62,
  },
  {
    address: '0xed693c6a0cBaAB0bBA6a28A56D75c9252dAd505F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.615046850448156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.62,
  },
  {
    address: '0xa42C300Ca81414B41bed10415d517A40715FDE29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.59521473542069,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.6,
  },
  {
    address: '0x9b9e663718666B33A38B0e4bE6Ba01A78540DAc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.57956892287696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.58,
  },
  {
    address: '0x22522ce786A5E218BBE5318B95F9306ec6188af3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.577194256148235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.58,
  },
  {
    address: '0xae93e7A6d74afAE4a46e4fBd157D198c7a56c221',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.53502624228341,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.54,
  },
  {
    address: '0x38F3c5E38aE348Bbc2ceF7B429134fDDc8651129',
    minters_reward: 0,
    lp_providers_reward: 19.54,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.54,
  },
  {
    address: '0x4B84cda0a83A88Ff097AbaAd26064a54f1992c60',
    minters_reward: 0,
    lp_providers_reward: 11.84,
    swapers_reward: 7.6678891991379095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.51,
  },
  {
    address: '0x4047090d7C656087Fc940067D318Baba456bD169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.429175846494147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.43,
  },
  {
    address: '0x81ad0d6A89e4344C83901241c485db434DFA9487',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.41674382921298,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.42,
  },
  {
    address: '0xd20c2b3dACCdcD28E5fb98A4d5b748023C5d0f34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.386345846848496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.39,
  },
  {
    address: '0xFd79a625005186B6852Fa9eA8A8fbf21e811c3cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.382107344681547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.38,
  },
  {
    address: '0xAfb16BD542AC252a755DABA144f85ebA4a33d01D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.371760825438518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.37,
  },
  {
    address: '0x94A1f4c8680498F01C2EFf375338867f9a9e426b',
    minters_reward: 0,
    lp_providers_reward: 19.31,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.31,
  },
  {
    address: '0x8B8D0cfECCC1d084432b7FB727a6EFd38355f4f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.296965912413125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.3,
  },
  {
    address: '0x1982555Fa56894821Deb8df29e05cED7a785BDc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.292900311987683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.29,
  },
  {
    address: '0xA8027c9d332EE3C9cb693211641FA393093874ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.251699955878596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.25,
  },
  {
    address: '0xc924D3875EF1BF1DF283Fa4c05E6ea585BCA8Dc2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.241651603134066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.24,
  },
  {
    address: '0x193cb03134Cb1a243D376CcbBC22f9aD540b21B2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.234727297271334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.23,
  },
  {
    address: '0x5605F52441f4f1dEBcE1993533FC6767c0DCaF1E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.20224925604742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.2,
  },
  {
    address: '0x88aA8F097528BB31c8377dc18E19154208a9E299',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.184953099432878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.18,
  },
  {
    address: '0x729fE872e8Bd128601057939DBDE4968519B27Ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.138961389643235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.14,
  },
  {
    address: '0xe55bD96A184C00e951a9d7Cd12d3b2fef6053e25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.133710126708255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.13,
  },
  {
    address: '0x4FA990736F70C549DDFD1251b87A2F8927Ec5091',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.102150792580122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.1,
  },
  {
    address: '0x2602a31775f2DBDdB8001DeCDD9677B18dAf1075',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.081839380978913,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.08,
  },
  {
    address: '0x0087F1a6F492c37c61618673F183b3791677d0d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.980501383822837,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.98,
  },
  {
    address: '0xF061Da638F9e415D3851db667C3E941a73207573',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.962234312027554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.96,
  },
  {
    address: '0xF71C04623EF09a2c539F6257Dc3E43B0Db4A06EC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.954541086906772,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.95,
  },
  {
    address: '0xe854Cba8aEeCe606C1Be96a2d6B022e8BcC133d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.941087265524825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.94,
  },
  {
    address: '0x7F453AE2480F62CB53C0AA30AaFb20CF42DC39b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.912219037112685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.91,
  },
  {
    address: '0x26A106D303a1ED601A350D7bc05984d4e251E408',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.900181906214414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.9,
  },
  {
    address: '0x6D9e61C341B2118f15773Bbe43ed597606afEC91',
    minters_reward: 0,
    lp_providers_reward: 18.88,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.88,
  },
  {
    address: '0x44e58766d552C87F4e2F47882960ffaeD5A90454',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.86872385438102,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.87,
  },
  {
    address: '0x9750E6EE3De102F5e2036BA395d4200c6f84fFc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.82063763280192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.82,
  },
  {
    address: '0xB22386c675be4258C885E31eB6a27E66a65DBcA8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.80213468650791,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.8,
  },
  {
    address: '0x38Ee1Cc584AEBA91F6C86Da3d2774Ad9f6962C00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.787026553618503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.79,
  },
  {
    address: '0xD69B1f67caeCee939BDb43B1a1C6eEb2aB0530Ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.78418270867314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.78,
  },
  {
    address: '0xDf8d968820d5931DeC04d3569E117480050E988E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.76867735147579,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.77,
  },
  {
    address: '0x4e0C6B22bc21b48AA1bD7C53297433a97b3a3c22',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.7068079635524,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.71,
  },
  {
    address: '0xaD67F9Bf3a292885abbe070650B652b5e24466FD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.70285082521197,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.7,
  },
  {
    address: '0x6B4689A2fe719581704f6e6B795aD57AbF3eEc6A',
    minters_reward: 0,
    lp_providers_reward: 18.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.68,
  },
  {
    address: '0x82d6A501485e7f2e7c83E64d5644E7dEC16814B8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.641294665191417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.64,
  },
  {
    address: '0x6Acf93Ca57DA354c1F45F657Fba12895288fD9B1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.595269561437675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.6,
  },
  {
    address: '0xD971a967F5881C04aFC27dd2E914d5dfFA72b15E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.588840496639158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.59,
  },
  {
    address: '0x9734865c105Fd070D182605867633D3796DF2150',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.562799054072418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.56,
  },
  {
    address: '0x4c70e03CffB971C1361fA90C16B4F7419D92084B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.53337326696853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.53,
  },
  {
    address: '0x76898466ee09a1822500B0d88C4959f22Ee14d98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.524553820027272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.52,
  },
  {
    address: '0xC57Afb49A73fD31D2269578C684d92eBb85FD1E0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.497356808260797,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.5,
  },
  {
    address: '0x694Ac6bE6021f01CcB2426c6CDea366dE4A19351',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.457720491211738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.46,
  },
  {
    address: '0x6Dd72A5355f1aCC8d3F0394B45Ea6E72C1a27B35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.443768306782623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.44,
  },
  {
    address: '0x194587C314a280ebc135dC3E01bF74929C7c12D4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.43733128565205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.44,
  },
  {
    address: '0xeBe798198FE49637A55975F8bF39a8F1481259De',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.417602321829044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.42,
  },
  {
    address: '0x65808B61B1621e929A10132a49f1ca9F1D975aB6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.387718113687704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.39,
  },
  {
    address: '0x06b95C401c7089F928d24b00d8658cedE742EDfa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.383142513923143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.38,
  },
  {
    address: '0x921b75Ca1e67C4e05Cc0ea274762c5454FCA323d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.365779853711583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.37,
  },
  {
    address: '0xd3522Aec791D10A6d3FE5A20D2196C8D6205620a',
    minters_reward: 0,
    lp_providers_reward: 18.37,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.37,
  },
  {
    address: '0x6391042e16517e2F05e2C337F19c25E8923A3Aa2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.35411768790276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.35,
  },
  {
    address: '0x65a67FCaBaBe5aC817CB4016669779b61EE3A479',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.327454715125384,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.33,
  },
  {
    address: '0xa7bB78a28c188220E539B130FcAe697a8C116cf1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.32015954109085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.32,
  },
  {
    address: '0x7C9179B277AE7Ab0c74DfbD096f064CE6c8ADBB7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.315376183190846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.32,
  },
  {
    address: '0x1Ca00E336BE41327Fe4eC8FaE220b7b8B117529C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.290478732090893,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.29,
  },
  {
    address: '0xA938aA4811DD185AA5b5C4E69f099B7cac96b278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.26657339476494,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.27,
  },
  {
    address: '0x70220213Fcb66F3aD9b1b4e9F099aA4012DeCf74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.26494964361272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.26,
  },
  {
    address: '0x2fc38bAc806fFd805242B755eF4073de8CD2989b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.169797102634682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.17,
  },
  {
    address: '0x7Cb725b43fe6ea19A9785491bD348d5bf084aA75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.168188681718753,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.17,
  },
  {
    address: '0x50F296e80ab42558F17D2ca870b8d5B45f2C2316',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.135992357053752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.14,
  },
  {
    address: '0x560858580455B2E277dde49E4e907653761DF20c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.128213874334445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.13,
  },
  {
    address: '0x6314077131232f7D8282d07DcB4c31387dDC81DA',
    minters_reward: 0,
    lp_providers_reward: 18.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.11,
  },
  {
    address: '0xD2A23272443f88434B3fFFc79CEe50D812e449A9',
    minters_reward: 0,
    lp_providers_reward: 18.08,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.08,
  },
  {
    address: '0x8773f7143FA04346e40CD174a30A1d6091a0d3aD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.072454741972535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.07,
  },
  {
    address: '0x22C1c08509bA45858011019341349D0E5B23e0c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.070670217182936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.07,
  },
  {
    address: '0x190FF18e41B0ca0Ee78585Ad5EeD0C32Baa60f08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.069620463769894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.07,
  },
  {
    address: '0xe45284e139ec636C5604E714d08295968064fb28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.030534642095727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.03,
  },
  {
    address: '0xd03d70904FEF99088f7d588b96F0A6c1DA6308Ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.02200982593278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.02,
  },
  {
    address: '0x85f8fD1Bd0C95D7Dd2b1731A8a20Ed639a6b80e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.998909683068963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18,
  },
  {
    address: '0x9c0C7A5A2cB49Be6D135Afab319051a52189E126',
    minters_reward: 0,
    lp_providers_reward: 14.47,
    swapers_reward: 3.4987901773094396,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.97,
  },
  {
    address: '0x5bCED5FFb8b5930C4B393066498b53d8E200F050',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.938278389571707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.94,
  },
  {
    address: '0x41737A78CE7055c83b64eeD2EB0b2d80e1884c16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.913637458556348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.91,
  },
  {
    address: '0xbE092634130Fc3E55f9B845865Acf66b5df12f4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.900084105323746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.9,
  },
  {
    address: '0x803c104617e2a4a987d16499313995B0cbA896ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.882425820366976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.88,
  },
  {
    address: '0xbA604eA0825537C457A7f23F585FF6beeaC3D481',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.88234357358872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.88,
  },
  {
    address: '0xEa0f97678dCad0826676CF4a796A65A8BffB6F51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.786719251858575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.79,
  },
  {
    address: '0x91B4A1ca68B547A5E3112008285307d6212212fC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.72372183889044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.72,
  },
  {
    address: '0x5625e613548001D103A58C4D149D4521031332B8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.716967674881495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.72,
  },
  {
    address: '0x2813fca6621e9E5C8d005E1B9c930334945B8AFe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.716463579986595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.72,
  },
  {
    address: '0x19999189f1E48254fFa93c97Ab274454250CcECD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.695984866918174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.7,
  },
  {
    address: '0x7Da0C620b03FCb788dE347bCDB4530f0E1D1C4DF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.693987797100828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.69,
  },
  {
    address: '0x9BBb45e12c4a75f7406Cb38e858Fa6C68A88f30d',
    minters_reward: 0,
    lp_providers_reward: 1.91,
    swapers_reward: 15.76988963391611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.68,
  },
  {
    address: '0x29C9cd9d0aF9faC1b210C175c1f5cfb07598241c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.64126575126617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.64,
  },
  {
    address: '0x1887f6A048E1a7B09a107A920D907C404168493A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.635437575785033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.64,
  },
  {
    address: '0x8E802B99B25c5c247AB6f0BDa39269fBdB6C2389',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.620284264169225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.62,
  },
  {
    address: '0xeb1B89b2625044b63093EC57E11205D3b134622D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.618996808349486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.62,
  },
  {
    address: '0x9b08E6B954Cb9B458a9f43f4cde260aB3C5E838e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.529465951333876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.53,
  },
  {
    address: '0x16A270E8a542cA47cB36E0A7AfD4eE68bBDC5F81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.519171208022343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.52,
  },
  {
    address: '0xb0AAB2F41b5Cea9ead2A74Ba21DCd080d1A91904',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.50329519197506,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.5,
  },
  {
    address: '0x76A680Ed4B07C530d26C017CB94C5BcB8d2cD628',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.484992414630565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.48,
  },
  {
    address: '0x0672D8fFcDBBeC1fe4DF6F397d362BBE633E30Eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.454595150732192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.45,
  },
  {
    address: '0x77311473E842c6976Adc37d4061BC0906E36efC0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.425998741461722,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.43,
  },
  {
    address: '0xf57f513a6fF6835dF164053e42bF0E211De268d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.399232929169788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.4,
  },
  {
    address: '0x90e75294ACc6294c4CA6B259cD487c5924C9011e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.397058339216347,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.4,
  },
  {
    address: '0x2bA4C61b674aB6f242db5A89Aa897a5e57eA6A3a',
    minters_reward: 0,
    lp_providers_reward: 17.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.39,
  },
  {
    address: '0xdE413E4Be09b75F133396e94528a27807f9e6E70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.35965184217166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.36,
  },
  {
    address: '0x86c3B77A9316Eb6aE4Ffab2DEAA24bD7596d4889',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.300277600060916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.3,
  },
  {
    address: '0xE11FF7dC515A83FBF6DE86119d5fe38E2ebA2C5F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.290938860784358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.29,
  },
  {
    address: '0x213ABa11e82a86646c7366614E85982F34173806',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.22784929640685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.23,
  },
  {
    address: '0x517Beb202756345Fef34545DE94DFBd2d5002C7D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.227017650979814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.23,
  },
  {
    address: '0x5223AC29Cd48bfDE494c065C1986f8652Edc813d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.223755162918483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.22,
  },
  {
    address: '0xfe8040CC9E6EC85a0F8fE1015fC9774e289E55F8',
    minters_reward: 0,
    lp_providers_reward: 17.14,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.14,
  },
  {
    address: '0xefa7ab847557f0720893dB90d1A2d11E8D6C9B43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.13465136567678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.13,
  },
  {
    address: '0x123b6c4a6055FE8C2F7bC64F0ac549EE083622F5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.054077429525886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.05,
  },
  {
    address: '0xf51D64a8297C92fE23b532212BfAf987D832162A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.04692386009584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.05,
  },
  {
    address: '0x9526029F1A20c8D288444f8A87b4b1d4c2c87291',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.040551726186507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.04,
  },
  {
    address: '0x03A6926f306092658456462b8f7d9D69151c442C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.028845247636582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.03,
  },
  {
    address: '0x9eB6339d296eA5C2Eb96B88466DCF2d9fE29d8Fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.978310537539205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.98,
  },
  {
    address: '0x7d102C84E4cAd685DF1c7f05A11CeA3d1abc93AF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.964719129825987,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.96,
  },
  {
    address: '0xED107aC00Bc881550313AfFf6bebb914Ed575851',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.926626670714917,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.93,
  },
  {
    address: '0xc19E007347A28B7892eA2D89C8A3F8344C0D3627',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.91170768118029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.91,
  },
  {
    address: '0xAB735123eDeBc8f93e9F607bb126A62c59546AE6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.898349906631193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.9,
  },
  {
    address: '0xDc6e14EEBcADaB476730a93F2B61BfB4765840E7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.878094097145624,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.88,
  },
  {
    address: '0x82DdC01460447C7E0e11853f95df92b329802536',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.867686324113034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.87,
  },
  {
    address: '0x6f0eE05A90efE9001B63c273860dD3c7eB577Ed1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.856230453828786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.86,
  },
  {
    address: '0x6EfB339c5A1788113be8A22223B42733E32616E0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.773750054703267,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.77,
  },
  {
    address: '0x838E7063c0661D0a5D8878984DB3b5b7fD126944',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.764585490045512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.76,
  },
  {
    address: '0x823E64CEBc1E26ce980afC572c8A6FbbF889429A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.71960916217179,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.72,
  },
  {
    address: '0x0B985B14e61bA67890D0F4C61842C146dF6889d1',
    minters_reward: 0,
    lp_providers_reward: 9.67,
    swapers_reward: 7.033982904747784,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.7,
  },
  {
    address: '0xE6886dD748332Dd1159318eE6956082163D94722',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.68412163885061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.68,
  },
  {
    address: '0x75103A045dC96519D129554137375696765a5047',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.66021277389152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.66,
  },
  {
    address: '0xFD7E5dA59f7D7e3Ef0cbf279ed030ad816d8fF58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.637706616040408,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.64,
  },
  {
    address: '0xf27AE4573f7979E2f6dde30564e646f071ECb37d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.631469541627972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.63,
  },
  {
    address: '0x5871288aA00A18F16Dc03B222b5274a72375797D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.61257400403882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.61,
  },
  {
    address: '0xf2b408E850ef264110De9cc760bdcd50e8e462f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.604405048296464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.6,
  },
  {
    address: '0xBA9B217f2D3A9286152b74eD56DF8399775e9899',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.575359886983065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.58,
  },
  {
    address: '0x8c44a22E23d442a23a656cd894f36DDb6c2c090E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.5614179756513,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.56,
  },
  {
    address: '0x7e7564fB1Ea4FEe3F6ED7998Bfd89902Bab7C799',
    minters_reward: 0,
    lp_providers_reward: 5.09,
    swapers_reward: 11.464757203174988,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.55,
  },
  {
    address: '0x9fF331A11DBFf265D80512262068CABe4D85D9C1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.52127031610935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.52,
  },
  {
    address: '0xe2cd94F41BAA8CB8ccB0B8102dE3Cfc629d786d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.48321795882255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.48,
  },
  {
    address: '0x680e79224200d535fAaE7921A0EFc4E4ec03b186',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.48321795882255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.48,
  },
  {
    address: '0xEf937A975495F03Fa610900462078a1e61A65c2a',
    minters_reward: 0,
    lp_providers_reward: 12,
    swapers_reward: 4.483140760847562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.48,
  },
  {
    address: '0xa908B6B5247d83Fc8f4856fAaCb9Cb5df30E7Cbd',
    minters_reward: 0,
    lp_providers_reward: 15.22,
    swapers_reward: 1.260460766208614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.48,
  },
  {
    address: '0xE3D93CfEE72F0123024062421633B5640F399337',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.457039408235413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.46,
  },
  {
    address: '0x7995190c2c2a051763344e24d198958B7b195d0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.411653213614052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.41,
  },
  {
    address: '0x7CeF7d9A646dD80217c0c8c1C693ab77789BC97E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.39202199767214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.39,
  },
  {
    address: '0x80A381Fe8F9919559A1C2479F19998b03a9c1c09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.370806642308033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.37,
  },
  {
    address: '0x20F8A6e05A415367B6C0EBE04246d7eD28451Bb0',
    minters_reward: 0,
    lp_providers_reward: 16.37,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.37,
  },
  {
    address: '0x711A290821ef89dcb9c7f0E21C980b10bEF84078',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.347587477054073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.35,
  },
  {
    address: '0x93ea476BBee39636a41AdbcfC30071B098DbBFe5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.31881860833318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.32,
  },
  {
    address: '0x8f2a089236152Dbd742215a4f234Bc05D2290830',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.31711092706159,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.32,
  },
  {
    address: '0x4D8F793c1ea787736F2772d39B7262271a5a87A3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.316565446732366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.32,
  },
  {
    address: '0x6d4455054Ad598DAC7268ECE70206823F9CD477c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.312322789739223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.31,
  },
  {
    address: '0x23e7EB76Be3Bc78Be5DB2cd53e384706cBeA90e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.273935501147392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.27,
  },
  {
    address: '0x1035d7e291948262D056998a9C4dB1Bbe26185e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.25334369602362,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0x6e48875F48C03b354d07BF2841768ba232136cfB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.253343696023617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0x95Ad1c489451e59699b5a7Be4BcC3b41337faA66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.247949026732776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0xc93f810ACb1A1042A3532A6163553E8025c50a8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.247949026732776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0x9de6B78Ac1Eecc8a0315d359af028eeb69B99e5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.246646065758338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0x6bbD33643842ec50C6ea7a7Bf6B832aD3c7A4BeB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.209200469462804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.21,
  },
  {
    address: '0xcc549813f332f80f909a00007b0aFc261aA9DE2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.194405646344986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.19,
  },
  {
    address: '0xCD5EcaCB1e18F3e3258241d4D74D7CCaE80cC1Cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.164514133174418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.16,
  },
  {
    address: '0x5bAd64437A089A5cb8BB1B5443504700a1d76061',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.12099828067143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.12,
  },
  {
    address: '0x7033Ad22926C57b3275C021dF1260090Cd6d4B37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.102027763050383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.1,
  },
  {
    address: '0x23beC55f896A941443382BC88ECf5b407E6cD48f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.041576798374244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.04,
  },
  {
    address: '0xD2719F661509416Ef6070dA7938792D65D39F36D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.97827751618323,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.98,
  },
  {
    address: '0x683A202fe31213C41e8a1832ecd6678C9846BF02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.925589905902815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.93,
  },
  {
    address: '0x9ED92DC3138fC9bfe8d31f9570bA73419183Cf33',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.894097828105423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.89,
  },
  {
    address: '0x719D291dA2A0d351D086Fe87Ac64089eEC9b2F9C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.845192765372182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.85,
  },
  {
    address: '0x7727Fc3994b67489CDd3A18625Bb8e5E4fA2C0B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.845192765372182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.85,
  },
  {
    address: '0x3B5715c9929E19a4C6908a17222bc9FdF47904BF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.834757344471669,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.83,
  },
  {
    address: '0xB9635eEEd15321e26Ce40f5c38B3455801a15C3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.832258585096424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.83,
  },
  {
    address: '0x1a81A34cf5E44E01535FfFe6fE6e54868e5eAd5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.817087900503072,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.82,
  },
  {
    address: '0x0191bbEf075076Cb25285B06a59780e39Aee9b53',
    minters_reward: 0,
    lp_providers_reward: 0.39,
    swapers_reward: 15.428707079167006,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.82,
  },
  {
    address: '0x541CFf4f8d21F466Deaf24da8732d82B3Bd8546A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.771448743248563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.77,
  },
  {
    address: '0xb5789c442580Ab33AaFfbD98Cf0B698D872338Bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.756659278223589,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.76,
  },
  {
    address: '0x6e28A95A58ebed7a6626cf90421c1b759dB1a260',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.756309919899172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.76,
  },
  {
    address: '0x97bC87eA160cF6368B315AcEa62A47c5a3f1a0E8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.734845840381434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.73,
  },
  {
    address: '0xd1aF79E196e998Dc1F33D05A902Cf6bDDe8413F8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.71407025050456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.71,
  },
  {
    address: '0x7A1dBa7170F4464aE2DFe23BabdEcFD1615ed11A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.695462004146785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.7,
  },
  {
    address: '0xa7C219e5CB52Fb270Cd7f68aaFf9A9be768748af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.67671326779853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.68,
  },
  {
    address: '0x934b036512880967716F5b7E2345a669670d732E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.673187354474512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.67,
  },
  {
    address: '0xafCB64773e3b004809795BB00467A07903BFbc13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.64912962092632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.65,
  },
  {
    address: '0x543Cd5d50C2f4F106a6f6377c628f3abF9F08F75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.559175095940592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.56,
  },
  {
    address: '0x04Fd391576d394D9B7AFb66F775259d21D922490',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.542924756603947,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.54,
  },
  {
    address: '0x995A7bdBbdeD631E802A2d8f86422139437ac83A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.532840042219178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.53,
  },
  {
    address: '0x482eaf5ee7dB72dBeb6129F0701708b246502316',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.507076954729557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.51,
  },
  {
    address: '0x18bA8999f791cEb17637Ed6ee1b522AAeE6d7904',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.49348108357373,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.49,
  },
  {
    address: '0x6BdB627491e468fa859141f29aF5779ede9ceA10',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.49130973073637,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.49,
  },
  {
    address: '0x1947e25872D4AD818a4dAc43c2B5d7f7b5FA1a10',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.467476779422155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.47,
  },
  {
    address: '0x5960cE076E79b3DDF8ACc48115CA405593Ea0B00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.45659949942326,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.46,
  },
  {
    address: '0x117fA696bb31eD607e6E4AE3D5D66f7EA58e3aB6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.381882988220163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.38,
  },
  {
    address: '0x89F5AB50b177d3f024032Ff9F5D2268da4d4a060',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.379373570575893,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.38,
  },
  {
    address: '0x0739784E4Ddb44F515fdABba21BB7c7cC5775F68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.371483669298183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.37,
  },
  {
    address: '0x6A37F82a7D3B768609C09457873Ec6713b3fC179',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.368705610754429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.37,
  },
  {
    address: '0x9675DDCbC4c72CF163c5539AC9B37B58b7Fa00C7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.315115576944327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.32,
  },
  {
    address: '0x1294534aA20da2b60a88a8c02425A5EF6603e970',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.31224400154527,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.31,
  },
  {
    address: '0xF2c2A57b0994F94116BB4d53A322c1eE5A078E11',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.302353392503884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.3,
  },
  {
    address: '0xF656CF5C649243Ae0742D8186bAf5069C5944002',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.284959212000217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.28,
  },
  {
    address: '0x8fE1B719e4B1e3e022cc64465D42d1c37b68BDD9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.236285910667918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.24,
  },
  {
    address: '0x7e20D0F93fb8435E37b7a97FC0254Bc2E1E37047',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.231279634269798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.23,
  },
  {
    address: '0x8F02C514480D95802Bf31b4Eaff45a8731737474',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.206266882771363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.21,
  },
  {
    address: '0x893336eA5FE30e6c7B54806d4Ad0cBc8DFC1F240',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.19770620117123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.2,
  },
  {
    address: '0x65b442F9A0Bac40a498182c54d2d9d83A5c27F74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.16719593704374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.17,
  },
  {
    address: '0x4708b9fdC9799e908D482895697cC96EEDb1982b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.111638917046944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.11,
  },
  {
    address: '0x659fE8eEA755f761F220133312f1b693b6dA8052',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.107835073502066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.11,
  },
  {
    address: '0xfa8E6091Ba9b69DfB7227D9bf700b3368e1e1830',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.089155380530876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.09,
  },
  {
    address: '0x5F94902cC1fEadFA4C71002c4ea74DB0dF8C92C7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.089155380530876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.09,
  },
  {
    address: '0x642f217F395bffaCEbEAf57b8A1e5f143Fa4B90E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.087077226325556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.09,
  },
  {
    address: '0x11aAe49dA2c6ce3609C0254549D274008eeeD2D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.086907709744073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.09,
  },
  {
    address: '0xACA41C8cCF9A2b88C49216e289fFBE12912838d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.023325968882217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.02,
  },
  {
    address: '0x83c6968c579E0d4bd88C267897A153a9Aa83D9E8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.012588113146293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.01,
  },
  {
    address: '0x60d418D75aAC1751ad0b39157cfd24ED3010B5e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.012588113146293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.01,
  },
  {
    address: '0xDB753AFFb9282fB6808b1292D85b4f688Fc5d46b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.012588113146293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.01,
  },
  {
    address: '0xF2f65Eb12EA61571CeFE90459561013f8bD1959E',
    minters_reward: 0,
    lp_providers_reward: 14.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.98,
  },
  {
    address: '0x92502aD18603475f691754c122CEb127Fb7ac6Ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.964558739614608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.96,
  },
  {
    address: '0xEC98edd9cBa2DB1E1567B80600861061BD1B1672',
    minters_reward: 0,
    lp_providers_reward: 0.58,
    swapers_reward: 14.383912252227832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.96,
  },
  {
    address: '0xCf39e6EF02097D13D397577cB11A92892abe78B3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.928366737979328,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.93,
  },
  {
    address: '0xd707086AdBa9C225A38A3A792B85847f1760C4E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.9251732248222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.93,
  },
  {
    address: '0x55A49A19069348C94f52E6Be4055441246CaCfDe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.9124714460807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.91,
  },
  {
    address: '0xEb2A2660c9906b0fE1E77f9eE26a523250F22f43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.910140386267779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.91,
  },
  {
    address: '0xBaBE930523A45F6bd95F9eD072825218912AD559',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.910140386267779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.91,
  },
  {
    address: '0x6f515d5DdCed29c9A5beBA2649C906A94FB9E08F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.893612044455741,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.89,
  },
  {
    address: '0x689e9044ea89b17a40EB19740354140483901bA6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.869522858124046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.87,
  },
  {
    address: '0x4Efa4aebE9f040BCE95149f8BFf8167D213DABD2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.865749200098863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.87,
  },
  {
    address: '0x8795a2Ebc6aF216CD95bA227f5eDA3b5E4e7D773',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.852119120101277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.85,
  },
  {
    address: '0xBB2536AA40fcD45907597145c9441d5e9f32050d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.851710103929888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.85,
  },
  {
    address: '0x5Bc470907961C2dd8bD6a32B5A51F851A92e21cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.835545048552905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.84,
  },
  {
    address: '0x5cDD36a49E1ed26986fca5C3045313F5163815F1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.82755341037587,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.83,
  },
  {
    address: '0x4BDa0f253455a8f9A9053d615d85D75222a85553',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.803066882861925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.8,
  },
  {
    address: '0x8333fcd3ad2680b0D7Cc87BB3Ed0cDE9d17fcBF3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.803066882861925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.8,
  },
  {
    address: '0x3CB27083412c952E5ae4C37cDa8e057127A8b4dC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.800229701490572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.8,
  },
  {
    address: '0xaFA6732514FfE8Cb4f4E180374B30e5c5afff39a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.785977368587188,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.79,
  },
  {
    address: '0xFFFc507514EADd9A122253793EB3D8fD4E557a92',
    minters_reward: 0,
    lp_providers_reward: 9.2,
    swapers_reward: 5.590216446167765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.79,
  },
  {
    address: '0x8690564792943845c767703846a4aCe76dF6e10d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.755340108526733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.76,
  },
  {
    address: '0x98B013A7f3Dd0e4514106C9eBD624cD555e8E704',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.754869460563674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.75,
  },
  {
    address: '0x92480fF8D44bF8bA65DBFc0C9548400Cc9f16326',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.74936484637551,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.75,
  },
  {
    address: '0x62a16BC1364Dd576b34f32D45Ca64224A1C101B4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.742178292561354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.74,
  },
  {
    address: '0x01c4b95326e32d54De70AbE203Ed2c3e34c6E223',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.734840184785732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.73,
  },
  {
    address: '0x909Aab229F8AD8Ca6E113eD8358172757607c099',
    minters_reward: 0,
    lp_providers_reward: 14.73,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.73,
  },
  {
    address: '0x94be72493F0721aBe487dd009062536F01CbFFbF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.718419658552357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.72,
  },
  {
    address: '0xaD107aa182855F19ea8aa802AADef4d7625cBCcC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.691080650397662,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.69,
  },
  {
    address: '0xc7787083EC5f1D5FD6AeCfb021da37a2Ce00a5e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.653509920694669,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.65,
  },
  {
    address: '0x74644e7A6C0A19b2f2234513D06A07B4aBF53bfc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.653444998058117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.65,
  },
  {
    address: '0x79E45dd965D52cefD7145942d8fe9Ed04a0d5Ad4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.643367119232819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.64,
  },
  {
    address: '0xF0f62a092f313C2d1DDde3DA423A91605967EA0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.639144421682337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.64,
  },
  {
    address: '0x47d87C231aAba73644080a2f905F39fE6Bd532Ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.611122379241511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.61,
  },
  {
    address: '0x0b51Be9cf911a305B98f366Ca13Cf909f188f714',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.611122379241511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.61,
  },
  {
    address: '0x13BF4d7f9bc5745CE03855463CeddcC180A8F63c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.595180617812861,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.6,
  },
  {
    address: '0xc48aFdE59352ce5E0241e99E02DfD30a3bd281BE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.585782951344571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.59,
  },
  {
    address: '0xEebad714947915c6Ad6C11a4Bb4Bf7745B434739',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.58478723785829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.58,
  },
  {
    address: '0x6514aA286555449Cf00704f1543a1fEe288603d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.572826305527526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.57,
  },
  {
    address: '0x8f23e54f65B8E293408EAFEFEea2Fd7B5a2Bc96a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.556605287577904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.56,
  },
  {
    address: '0xA72ECA8E67a955E9DB7A4A1b3decF86628857ccC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.553617558549774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.55,
  },
  {
    address: '0x6AfB0E63FC03F77f69138cCFd7407E06D8A87761',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.536365867612089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.54,
  },
  {
    address: '0x4245A5Ee4dB5AB3679F834535b4527a87f3573fA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.522990004486626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.52,
  },
  {
    address: '0x237AE8550cAE9381ef0e3Ada2c4264F447b7b9d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.517503905365238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.52,
  },
  {
    address: '0xeBbA6dDa488acA009674841e7bE39a39A87d2FFf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.508798361327367,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.51,
  },
  {
    address: '0x1A48B5F7334c82e0122551d0ED697623969C987d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.501089645821942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.5,
  },
  {
    address: '0xE6E92BbE059a5608670ABa50e7a27aCFD417A981',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.4797441884676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.48,
  },
  {
    address: '0x1eEDd1f0B821Fc2d94f07821D29C9484425b3d39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.47605269304022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.48,
  },
  {
    address: '0xD753d6aca896b996863d2Fd802DFBA92BE904825',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.47455372276705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.47,
  },
  {
    address: '0x45a0accbAc89C64b5662Ab7fda5b6B10C8F1Ec99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.47310760093314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.47,
  },
  {
    address: '0x049902F2b9dBbfeD423EF34496D5c230DD801F21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.46106828675727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.46,
  },
  {
    address: '0x17694e06845e7619D303B5ED52305c6d91e8C284',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.43514556800271,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.44,
  },
  {
    address: '0xD24eB250B97290A838F19aD7252e6B3bb89A1C99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.432947453375743,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.43,
  },
  {
    address: '0x1499B2e34AcB5501392ff80f2c8CA10De5C5708D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.42226433237981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.42,
  },
  {
    address: '0xA955735A93B00EF8C5974fDba12F331b6a51aC35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.41328364360816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.41,
  },
  {
    address: '0xD9e7e96A5B9Bc1c519DD5287486B641CAB1b6197',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.40510749650474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.41,
  },
  {
    address: '0x7CfBaef2c5214eC1a30f72b4f177FD39032da020',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.399605172180914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.4,
  },
  {
    address: '0xe216cBfB74a6bbdE9805cdB89C677AE1B70Bc065',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.349348919605333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.35,
  },
  {
    address: '0x053422305eA27A39674BBea30C6D125Eb984a554',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.328805799494424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.33,
  },
  {
    address: '0x8B3612B8e4922e4332B3B7Bf9b4dd2eA021774c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.309426321005473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.31,
  },
  {
    address: '0xF3dc62677e343063aeA1C49dE761bd982DCa22E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.302966950293133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.3,
  },
  {
    address: '0xDF830Ce13CE56367Bd03f8c92a13B786AbBA994C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.294649427214436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.29,
  },
  {
    address: '0x0bE3Fd318b07B4F460086B4D47ee5523632C7650',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.243728136864776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.24,
  },
  {
    address: '0xD26D44EcA73d5469dF22C55e58BC738aC104ED8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.234064331613943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.23,
  },
  {
    address: '0x938A03E67E0B7de5f060b88E028E8CA19fE9e731',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.23406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.23,
  },
  {
    address: '0xD46af007c25941d22f68Af5443f9FBa8729B5ECf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.23406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.23,
  },
  {
    address: '0x31bf477251a10Ed0B22eE3F350a05dB86D4db5Cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.211131708298206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.21,
  },
  {
    address: '0x96A5ff085281a5C62255DdAA93196760D83DBcb0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.210628864718215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.21,
  },
  {
    address: '0x1F94F97706254C944dB2fc790674a52143BbD124',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.207226732519795,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.21,
  },
  {
    address: '0x46004D41f2Cd66e8AeAe78c98F7807Fe9b9960E6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x406629F8B74c2666Ede0bf559A231Cd0Da293fD3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0xd056602108a7fCB8064eA6B43591Af5420980136',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0xcf79b206933198655027ED487c6AA1896e31aDd6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x6acC539a80187F30665c9c14fddDCCD65CeDc885',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x802630feCbfD01aBAc0C141f47bD61CA3f65E32A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x08210CDF531BF9433E0172895dd42b380fcdB8fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0xe66EF175F7D55d592b2D7C249372287cFE258553',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.195899429241242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x4b63eab799D31EDDA576D9eA320b3cff371E6C49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.176237035363844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.18,
  },
  {
    address: '0xD96DBe4a3d4ddC711270b6e2288E1cD498bca377',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.176237035363844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.18,
  },
  {
    address: '0x20563d9627E41BF347B4cFEf8c87ddc58524ea96',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.168185368426052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.17,
  },
  {
    address: '0xD8102C196728971840a2bf4AeD978Df223fbCB77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.137010692849422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.14,
  },
  {
    address: '0x4080f840FbAaba731f0AadB1b7f87B1891d1A373',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.127813442837668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.13,
  },
  {
    address: '0x67e8A3F7883ebE681F3131C3eA113CF377328C86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.088294602159886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.09,
  },
  {
    address: '0xA0aDeeE037Bc410784A20E7c89e834e2f55BC088',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.086989077469989,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.09,
  },
  {
    address: '0x11DF641B32eFF9efC0A3cA37a7B31E5D1D9Ec8a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.072018596198001,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.07,
  },
  {
    address: '0xF9109E09efcF536aF79566d72e43A8B2c91c6850',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.06851878476031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.07,
  },
  {
    address: '0xbFB2cD43F6F103c9E6eC7A4aD6813d259489513e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.060662913331488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.06,
  },
  {
    address: '0x40eBed799403ef62f1ba1e150396Db5aE10B2673',
    minters_reward: 0,
    lp_providers_reward: 14.05,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.05,
  },
  {
    address: '0x9B05E04d6a73C01a70ddF773083334e9c218b6Ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.030485405002814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.03,
  },
  {
    address: '0xd42dDbBE129d281Fe60E133126A68716a3f96aF6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.01288531310635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.01,
  },
  {
    address: '0xA53029D25E64f6150e967caee6a30eB114Ede07D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.011743750062475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.01,
  },
  {
    address: '0x46Fb77747487A75Dd7C93bCFdb2f3868E90631aE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.01115166190848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.01,
  },
  {
    address: '0x2412aE253Fe0D1aC3B7dcB7B6F26690c83f67682',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.00301983952595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14,
  },
  {
    address: '0xBec74d77f40CbB40F5B79A31D1c3aFafB5B69d91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.00301983952595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14,
  },
  {
    address: '0x916b99Fd8a9a9Bd62f4876D4FA4d23Aad4BF0746',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.996247742823163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14,
  },
  {
    address: '0xCC18dEEF53b15e11A99bF310b16CA3222CBccd8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.990651004677595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.99,
  },
  {
    address: '0x77ED7f217D6DaCaD4f88e1D9F7939006ff6C3BAE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.990651004677595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.99,
  },
  {
    address: '0xAcBAf31EA1aA8221200a10683A741a284eE7D382',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.990651004677595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.99,
  },
  {
    address: '0xB7a32A428A388E9d48aBF54D4500dC5D92F1778B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.99029473975505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.99,
  },
  {
    address: '0xC55b5A37E0567503A7847DAbE4005bc1B91b553D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.96298783396984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.96,
  },
  {
    address: '0x7723C9b88Ba6F2D6fDE53B6301b41d778A06Ccf2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.958888009351252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.96,
  },
  {
    address: '0x43784716D1b80fD4fe4eC525dc3b544eEf0887b3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x564391f83FBc4B044B4C317fF363e9bE14d3beD6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x5F885705aDbb662d7b7523a44771f6D19b5f93f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x495a6F91Cd7e8Fd1a3D9Fa109f85e68d26fAd0Fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0xc6DeAc50F876Af4De3B075fC190598a042eF3914',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93837696001912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x875bdE4C417E22035C03BaC45Bb953D8000AbA70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.938307268482776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x3a9F3ed8CD3B657512af920C60f3601d476daaa5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93718013232259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x3120212c44F19eE4cf4b63c31E1f0D9EF7BC14F6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93689349841435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0xE25cDAC1887842e55451bDB7f61b4B03C9DD27c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.900937824895806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.9,
  },
  {
    address: '0x71Bc3a5748FDf36CcB99627B1e950318D7D1d3bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.888659158853496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.89,
  },
  {
    address: '0x1AeF83dF5720e822769BcC74cBcab2FF9746C52c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.864748353663472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.86,
  },
  {
    address: '0xf59c8b343A0Ac84FE3f95a98e43Fb1B4c1097634',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.852090849421804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.85,
  },
  {
    address: '0xEA8Cc4f399c89552f8EdA2F02bE4f747fd3cAbA0',
    minters_reward: 0,
    lp_providers_reward: 1.55,
    swapers_reward: 12.298603144023078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.85,
  },
  {
    address: '0x56507403e7d0004F5180B4a244C6A544876fFee5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.798381324364803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.8,
  },
  {
    address: '0xf578258B3079612B64CD5922411262218aCc7530',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.795830528193305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.8,
  },
  {
    address: '0x028B7AAb30Af8ce43C828c542302eB341CEe4Ef0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.785781636215974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.79,
  },
  {
    address: '0x5119935607ec96Df15d53Ab7D37cF9c9185cb2c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.781240423264704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.78,
  },
  {
    address: '0x564eEeFEA2f144Bc8b18c8E707eb5D10dAc203bA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.757007599183293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.76,
  },
  {
    address: '0x667BC76Dc451919fCa56b75d022Bd30CBc66F169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.752272138049198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.75,
  },
  {
    address: '0xb6138056DF5c54Ee8BE49811e377F90aB21Dc10f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.745064106325446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.75,
  },
  {
    address: '0xFf294b5d01c07fEcDEaf76C5cd9bD35462DA8e4C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.724161692077338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.72,
  },
  {
    address: '0xD7f9FeC7159AF8ABb34faF137e8f9645163E0C59',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.722657822843418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.72,
  },
  {
    address: '0x2cc8B4e29F14c5002062868627f69fE5bE2cA76B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.71,
  },
  {
    address: '0x706f16D9554172D35183dd6a556c160030848777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.71,
  },
  {
    address: '0xe81A1fe9ECb3E376F879142b22f8D3cB183952cE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.71,
  },
  {
    address: '0x4AFB88178C35F74a9b6caAF017641963eB3315EE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.686956822895572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.69,
  },
  {
    address: '0x47D5713B73aDEad0b0C90D34826C01bf360E622b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.633984176696895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.63,
  },
  {
    address: '0x0B7C5aDCc4255c6f048B8cf8Af701f7e70472508',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.612694852918676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.61,
  },
  {
    address: '0x2E7957b36507257A4092b82387715B9386f5006b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.591257901237045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.59,
  },
  {
    address: '0x498A1e3d13b5640E2eAe64cc62d185B22D9A9298',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.585072649926815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.59,
  },
  {
    address: '0x456B896183485e4604740D2D4C9413171F49Af4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.57762027541614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.58,
  },
  {
    address: '0x9e0fA34ec90D52E7454878435f5b23e217441802',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.57762027541614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.58,
  },
  {
    address: '0x9be78Ce3A70Cf2231ee60F5361656525522a9fDE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.574892931153505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.57,
  },
  {
    address: '0x794Bf0178C921a8Fa106E85906f2befb64B5112a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.567717947771627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.57,
  },
  {
    address: '0xe1f16671a5bC90ff10bd1E72327E47A7b5563f44',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.544453080019682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.54,
  },
  {
    address: '0x46154b47c6D35C94A955A0D217a00EAeD630a128',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.544453080019682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.54,
  },
  {
    address: '0xE5250E69dAE685298C639ACe8b4e954358fAD441',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.497023830671035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.5,
  },
  {
    address: '0x700818452A814372024d644383B2337a821C877D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.466292535935013,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.47,
  },
  {
    address: '0x789a09BBdF75F655c6B2ca9B0c1C01d2603DBC37',
    minters_reward: 0,
    lp_providers_reward: 5.54,
    swapers_reward: 7.91466936486322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.45,
  },
  {
    address: '0x0290e66E1f711dc5F8680E773cdEc48aaE117460',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.387259496675522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.39,
  },
  {
    address: '0x306600988Cb8CBc9c4C7d35210625A0b98842346',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.385278348059664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.39,
  },
  {
    address: '0x5B82E8347186CEC17D9160b31eA016263Fa3E650',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.381134214061268,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.38,
  },
  {
    address: '0xC6E5380Db09a4cc0551F4C5d69Ddc81D712EB946',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.367443624489848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.37,
  },
  {
    address: '0x17eddF5F30ef6eccc44e9468B1fd7035142319f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.354793128568605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.35,
  },
  {
    address: '0x47003431fb44FdbA2A0A5fD01cC305E5EF62181A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.336745264019584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.34,
  },
  {
    address: '0xEB4B2e9f5F3100dEBba512ee0D95647fB3416062',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.335796951717471,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.34,
  },
  {
    address: '0x3080BD9C9dF314B6eFe7C0A0a0d13aCC23ddFc6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.333019659647364,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.33,
  },
  {
    address: '0x75C26b2b971Ac740D2029575384e82017EcF9062',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.31523145387121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.32,
  },
  {
    address: '0x41e2fA384159F92bd990aBDaC4636c8D102D8354',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.260124809203571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.26,
  },
  {
    address: '0x5dfDb0E9685cF73892691C4D3dae2CE4A9646bbf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.241500206801106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.24,
  },
  {
    address: '0xC164ff4bd893b39D174998e61d079c4c025c0945',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.240800832925313,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.24,
  },
  {
    address: '0x7193E3c9E5A1D587Ef57740B1791212b5A7255f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.237342395858597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.24,
  },
  {
    address: '0x48911F1564331B05264DcFcA6fBea9c54702Eacb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.236255294031185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.24,
  },
  {
    address: '0x33a8c56921FFE1C425DEDE4118F98cA4fD887A4b',
    minters_reward: 0,
    lp_providers_reward: 9.56,
    swapers_reward: 3.655934654402034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.22,
  },
  {
    address: '0x3842a6271c4909939eEcdB9e7AC33D2a5092C907',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.207430024088618,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.21,
  },
  {
    address: '0xFCdf4E821BF425222C5c477395de38dC2104F7b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.206528639804272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.21,
  },
  {
    address: '0xD4386a878b76715537B2f030F11A4ac933906dAF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.199220963624581,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.2,
  },
  {
    address: '0x9cD5fC162bAE2aDF1054B2dD3d8C3646B73fd8c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.18708581466595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.19,
  },
  {
    address: '0x1dCB3192F5F62f84abeA4F5D3cAe046069496613',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.178640575095127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.18,
  },
  {
    address: '0xc4eed93B1d1B0bC0A7De86A9AD5764Fd9a07B1a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.174685203938632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.17,
  },
  {
    address: '0xF4db81BE8b8186cDffCe5F5eA556580f5A5a15e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.157215105154027,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.16,
  },
  {
    address: '0xBF6e4B16B1A3793A19A66849A30cDA656c78d33b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.143090263187766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.14,
  },
  {
    address: '0x9c0D2FA34F919D25299641bE3B689D42CfE31249',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.129920549027748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.13,
  },
  {
    address: '0x7a54Fb2579eD5bbB8784033Ee59eC7ce337635Ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.106215774600882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.11,
  },
  {
    address: '0x4d2caA1d51a2479cDe8A28E691395c3A0eB61949',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.076745478066455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.08,
  },
  {
    address: '0x0F3b34D78c9F377B53D37753676a14e989e0E77d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.07098951470934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.07,
  },
  {
    address: '0xAF643eF70d14a736ded6692B6Ce3eB9904E8785E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.065754821404196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.07,
  },
  {
    address: '0x85dF39349534cA69e928711363964c40bce15433',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.065483083961617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.07,
  },
  {
    address: '0xb7f1bC0dDb2c583d532C2ADB474cb2957d3ACAa5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.062724271220699,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.06,
  },
  {
    address: '0xF767113EA9Cd946fB5264C4ba8c5213325C9f827',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.056047591491932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.06,
  },
  {
    address: '0x1fD23Bd1edb40101E971C46B99628Ea0e8d00A48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.04906646198771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.05,
  },
  {
    address: '0x9225d5430c4057D0E7e6F9287D1F7Be1C75D9076',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.042992564637816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.04,
  },
  {
    address: '0x7E143f5E4C668b6a3C948651b435Ce44679a65b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.032382860420771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.03,
  },
  {
    address: '0xa61b61D0c3d85F6724Af6B259795bDFFdf3Aa284',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.970686998201016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.97,
  },
  {
    address: '0xFf289A34eb119D20E59572f5da7a7b8cdB5e7314',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.959214971809654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.96,
  },
  {
    address: '0x4C61dD60Cd7F1edD98CDA7c1f2aa5D9d0c917cb5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.912311222814417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.91,
  },
  {
    address: '0x1D8DF28AC035dd22595AFEfa0E5890cE730D0c02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.912311222814417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.91,
  },
  {
    address: '0x60DaE6535daf542fEaD19D4fdE90337E716D18eD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.90477573319812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.9,
  },
  {
    address: '0x6D1172E0c3148F537b5b63908C5a29324F85036b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.866521408160619,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.87,
  },
  {
    address: '0x0f0728f3b9386a7808Ee25108e51e58A1c72fFeC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.858811122026827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.86,
  },
  {
    address: '0x3FC62B9f8DAa3A8c559f832fd813a9D708961dD4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.854204498457301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.85,
  },
  {
    address: '0x85dC5cA057C612b9955Da1Bd58323C7Af76657B4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.782622012946582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.78,
  },
  {
    address: '0x20fdF58bc0D511064F80A2aDFB07944EA42df497',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.747040805360912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.75,
  },
  {
    address: '0x20fa5cA22EB2978C267D15Fd9C3b644D95e20237',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.684603170119463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.68,
  },
  {
    address: '0x88528857b50B9332de0bbcD8e03EA106ba8b734A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.670306055483952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.67,
  },
  {
    address: '0x4dC5680a8cd295A5FeFb31e7D0fb7E2A9E6a137A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.661846813009937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.66,
  },
  {
    address: '0x1879609a255ECdF6A2E079a885F291d3cB610aa7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.603639101139764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.6,
  },
  {
    address: '0xEBce4b97F038592AE859eB82fe9633fdA98733C3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.569541949743096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.57,
  },
  {
    address: '0x517499B23f8A778c58e2e4aB2399E639150Ac972',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.569541949743096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.57,
  },
  {
    address: '0xA8DA841e16BA89902EE236291871993bdB320205',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.544725867930548,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.54,
  },
  {
    address: '0x19D29A4E5Fc951cC1363590C03a689AA27688633',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.497504504432527,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.5,
  },
  {
    address: '0x2B47Fb20a7558e38CF4e45f2c200bc39905B8f6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.488908315097953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.49,
  },
  {
    address: '0xDFa93f9d6B89408F79E35eb629444e2BC7194CF2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.453927051529265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.45,
  },
  {
    address: '0xeF90572Ed625c9CF115Ac56A03C00092eED7527a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.43322821952763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.43,
  },
  {
    address: '0xefc0FD59E8B897BF9e38C438aEca231F68Adf19D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.416427470240324,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.42,
  },
  {
    address: '0xFF5DB07e13779EDb918a10b70fb8840747B36039',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.409214082507805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.41,
  },
  {
    address: '0x81ddd2Cf7DCeA027b873885a05826D319CbFdE42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.403173189532096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.4,
  },
  {
    address: '0xC2E02bF8b8f99f5A16f928AB7F6205489aABdc4b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.403173189532096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.4,
  },
  {
    address: '0x8AfeE15fDf261096309E982c2aAb1e70B7a4C42B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.373969065647632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.37,
  },
  {
    address: '0x78971D6A7F3c6f0EC06fd99106709f9BD36c9643',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.342438376202317,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.34,
  },
  {
    address: '0x95144c1C847fdbfd27ff5BCe00Bfa3df41859fF4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.326499424261351,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.33,
  },
  {
    address: '0x3e2EEfd669E9f1B105c0Bc6a604171c458906cf1',
    minters_reward: 0,
    lp_providers_reward: 5.42,
    swapers_reward: 6.890196142190878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.31,
  },
  {
    address: '0x18479aB88A117731e07Db9d15716C38021DbF35D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.275423108249294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.28,
  },
  {
    address: '0xcF1fF5f09C9aE730087c425223580F94c4eFa720',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.272339021452764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.27,
  },
  {
    address: '0xf67D5DE18b4957CD7CBEacaD203FFf8f2E8FE96f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.236082078022628,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.24,
  },
  {
    address: '0xFc1Ea811eFfA6126c940B870556e990Ff73919fA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.190018357940843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.19,
  },
  {
    address: '0x8E38D9fE9df4B1674B84ad19E0c2aB2A8143Cf4D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.148745565725271,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.15,
  },
  {
    address: '0xB4a97Cb53993a3b4AF7d3B817F7578574064758F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.111714305903755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.11,
  },
  {
    address: '0x097C37Ed5a6D4b970734B80ff756765537BeF3D9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.100756310356383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.1,
  },
  {
    address: '0x2180013898144B44fCFDeCA2820aaD6FB5419635',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.062381067990914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.06,
  },
  {
    address: '0x366aA48b7DBb0d764CDE412B2ED6b168C5E0B021',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.048054044914668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.05,
  },
  {
    address: '0x8F626958EC428d258f6481B563126640f8aEf54e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.041254145820577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.04,
  },
  {
    address: '0x6904172bbEf6e828B03B0Ef573347fCfB0f6161B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.040400040594083,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.04,
  },
  {
    address: '0x51A61E6Bed080F19C417310955DafA4C8016C118',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.039843645440847,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.04,
  },
  {
    address: '0xe6F27141911095b5c4fAa333FCF643d474F77d1F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.002217256545725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12,
  },
  {
    address: '0x0D020ac68Ad827D9B945041DD164560DC291062e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.98370830848409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.98,
  },
  {
    address: '0xaDdbA44365254a27378F60899bB142139b6dB020',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.97659828646657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.98,
  },
  {
    address: '0x3087b2EeB34C566d8DB892583deA0f703051979e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.974178454771746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.97,
  },
  {
    address: '0xc43351b66106598AcDd4Ae65CDC70C2Df084Ce5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.951990870966105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.95,
  },
  {
    address: '0x96d61e6d427404F3b6F76df9FD264F49E231d0Bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.93811264974556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.94,
  },
  {
    address: '0x39AB2C8a0ea32D593a179A5EBA1Fcd8b4aDE2505',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.917410619727969,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.92,
  },
  {
    address: '0x1eEAE21b569DD6cd288113671E6aCA2002EBe470',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.817729449443284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.82,
  },
  {
    address: '0xD823BdB2602a05b85c7f815D2a7bF012E2c1C5Ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.791765905438842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.79,
  },
  {
    address: '0x321e3564CA989681D145b7db7a52e2A9FbeCB117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.774735726841886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.77,
  },
  {
    address: '0xc03056470257B9bEcf95e61C16b02AdD521ebb50',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.769869598060474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.77,
  },
  {
    address: '0xF355b11B6778F462cB79760cFE3Fcd578e95EF54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.762682604197632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.76,
  },
  {
    address: '0xE4CFe9434449AEEeAB66AB98D72f538E1937271E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.74631420423259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.75,
  },
  {
    address: '0x5bB6da0c430ca2C00f92945aA756115834CdFa46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.711901330459655,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.71,
  },
  {
    address: '0x39CbE65F883840615b0ACB2eF37786c408f9bE70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.711770092161188,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.71,
  },
  {
    address: '0xD3752050126A82E93Dae505762d2a519B805743c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.702014946038028,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.7,
  },
  {
    address: '0xbA1Bf4309c4C2AD6A9b0F42b6728631461Da57f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.671736236154317,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.67,
  },
  {
    address: '0x5ec963005C1901A25Fa4DD77Ec033eD88230FE71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.635890733093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.64,
  },
  {
    address: '0x8EDD3371C6FC8A5FA618f5Cb4Bf06357450108ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.621163147164173,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.62,
  },
  {
    address: '0x1f06f58E6aef4A5e83B305fa04c4CC5a38DB24FE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.610436135434774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.61,
  },
  {
    address: '0x2B4514FEe73E0808DF63248F4d4aF5f2FF8aba74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.578666036043694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.58,
  },
  {
    address: '0xEeD9ecBf925B621Ff5fD2Ce6828BFB2940f48611',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.578381345209392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.58,
  },
  {
    address: '0x8113cE7e4e62dE62495A7053364dbEB706C392f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.53014656380224,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.53,
  },
  {
    address: '0xa8A5EFA1b33Eb20889fc414240223461BC19709a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.51072248590815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.51,
  },
  {
    address: '0x7887E46384bb81566B8E432Ea0492B701CA7750d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.497864242835693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.5,
  },
  {
    address: '0x6BeD9041b9d7033AC2De2fBF23483758e263Aa64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.497697912746876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.5,
  },
  {
    address: '0xC19fba746656BDB136564b4bFC57346a65C7543B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.418994237966654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.42,
  },
  {
    address: '0x9AE6581CDa4F9f5D516327Ce78a2cDeEC1492717',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.39378443501433,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.39,
  },
  {
    address: '0xA38E66B181803F8e5135625CF5287c7FB7b767c9',
    minters_reward: 0,
    lp_providers_reward: 3.01,
    swapers_reward: 8.35901234010258,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.37,
  },
  {
    address: '0x0f9735B21dd155ec9A2C4DbE6C7411Aa97A388F4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.307902276490749,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.31,
  },
  {
    address: '0xd91d065a1f45B3E51e9DFFAB1586026C605D5931',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.291771353430677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.29,
  },
  {
    address: '0x668cecd7cA16EBF9Fb9C7e2c4304A38b79F9e4d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.255554972795078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.26,
  },
  {
    address: '0x36Aec4013dbc48BE984d4eb080bdAE53AFF0f6e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.253902644830877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.25,
  },
  {
    address: '0x5418Af6Fef00420e5B732bbaD47F0d65BBd87578',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.205899710715475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.21,
  },
  {
    address: '0x2B50867a1002c6A31f2bF3755e74141cD4Af7Ea2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.200003400549802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.2,
  },
  {
    address: '0x519821Cf7D4215E8Aa045b8aF1852c2714510cc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.171778093365408,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.17,
  },
  {
    address: '0xBb28ea42f4Fc4195A71084e3Fd8C204801B80711',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.152141410671609,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.15,
  },
  {
    address: '0x750e83642EDC02866269C5e831A97226FF27132F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.102969416968733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.1,
  },
  {
    address: '0x9c80819dFf82f4ed133491b9eA5b1E82aee03A53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.0232627846378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.02,
  },
  {
    address: '0xd64584F7Ab321d65d95322884bb2ef62A16c64f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.011828881479422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.01,
  },
  {
    address: '0x6A9F9263076803641Da308D909AF1beCAaEE81cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.009794693889063,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.01,
  },
  {
    address: '0x10cEe5b91695048487e10d4583b21e38A3fCf1a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.990656795918902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.99,
  },
  {
    address: '0x3eb73F611f5926d8A78c3893Cb9E759dFBB521c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.986153178516632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.99,
  },
  {
    address: '0xdaC081c2dE597914F23d3AaCd63eb09e2fc93923',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.984433277811489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.98,
  },
  {
    address: '0xd90994646C1a9B541b20F66E4C791a9095b655dB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.960339953149502,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.96,
  },
  {
    address: '0xd5Cdf0821De32D2F426ddd043ACD9037Aa5aacC1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.958958965855905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.96,
  },
  {
    address: '0x94fBb8D5b4888cAD040cE7C8d850e2036bDFb38a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.958219356599534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.96,
  },
  {
    address: '0x71b04995aAe0C2d9c8cAE9a53484b36F18313A98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.952168787504522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.95,
  },
  {
    address: '0x9a15A776e24662e86155ba00149011a5B0f1Ec34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.944792150424865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.94,
  },
  {
    address: '0x4c722bA6df98b09aa1159F97e938b07243910214',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.928562757947702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.93,
  },
  {
    address: '0xE9f51FFB44F98c5B0933d43a123ee08A7beCdA5F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.927305125556197,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.93,
  },
  {
    address: '0x1BFa385EFadc6Bd1a3FCA3De29a8167FDB5FEa15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.914238794261239,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.91,
  },
  {
    address: '0xf4CfD29E3835bDdc99E1330fA5C028B5BfA0f61F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.877741663063745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.88,
  },
  {
    address: '0xEF3c2286202f53ef20E1Bb7ECF4F4a05498af624',
    minters_reward: 0,
    lp_providers_reward: 5.51,
    swapers_reward: 5.347695182523034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.86,
  },
  {
    address: '0x81fCF51540f48D80A39c2019A7aE2909716aF1DB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.82855293833218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.83,
  },
  {
    address: '0xBD7AcE862a9e25F146F8523c66720aA2F3a72Bf4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.811631773798082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.81,
  },
  {
    address: '0x946fC4090c5E7cE361707DC5704970E75583d81c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.80281976641473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.8,
  },
  {
    address: '0xE9EB78A0bD16f148DBffde6Af31bdc1255eBf1F5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.795632769172231,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.8,
  },
  {
    address: '0x012ea0b1dB520463FD14fA9c255CDccB6E91C25D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.759973509567688,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.76,
  },
  {
    address: '0xcdc5A0684C7CFC2418fB54767E583e351A31943e',
    minters_reward: 0,
    lp_providers_reward: 10.55,
    swapers_reward: 0.19230817099049724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.74,
  },
  {
    address: '0x88aE13C3B74ab4E30071b92e974D44B9EA0E17f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.70661831972825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.71,
  },
  {
    address: '0x18593D672659626631449F1679bE25E2CD33d924',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.705864438531002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.71,
  },
  {
    address: '0xE7fb81CbC8228B01BA71F277fC31c7F2DD41d297',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.705139113323286,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.71,
  },
  {
    address: '0x667B02A67dbEE0A81237F0E9B87933e8c5B119b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.681582214885019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.68,
  },
  {
    address: '0x47cb92508279fD2Bb431Eea0Ad82A18cEB63f7B7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.676237626191464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.68,
  },
  {
    address: '0x50f1cb1B0497f45bCbfeA0450ed9d82E6996F60f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.651898975754861,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.65,
  },
  {
    address: '0xf5a1f480df84F48b272e80724A7d755598f8Bddd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.636239468567611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.64,
  },
  {
    address: '0xF2D1b17a69DDe246178B808Ba371da08DAe99Ae8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.629456653465253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.63,
  },
  {
    address: '0x986da69269023Ec89486E6eeCA3301178170f86d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.591076595399054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.59,
  },
  {
    address: '0x62D4177005bed706E5e5272832A30809751329aF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.571001659211827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.57,
  },
  {
    address: '0x4fc8C73f1c8e9988Bd1c83A5cC33Ecb555c02323',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.561542047080557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.56,
  },
  {
    address: '0x133bC5E31952DB7Be708ee6F915dE1539Fc54225',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.561168286053283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.56,
  },
  {
    address: '0xf29c93484F1d13dA2f117c952488b3638088d774',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.557377023819829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.56,
  },
  {
    address: '0x9765D62A07fB0dc3ea7a8726F1ef7A05774Bc4aB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.552213917855555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.55,
  },
  {
    address: '0x368cCB511e9fdb2a0586B438Af91275B6175e7Ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.540662027360503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.54,
  },
  {
    address: '0x5175fe7F02505dbb9e9D1028045569152bd70AD7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.5111528577403,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.51,
  },
  {
    address: '0x8deD9b33cFA04CCB1dacF8C24C8DA600f57f3BaF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.468170564943355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.47,
  },
  {
    address: '0x407AD0f23f5Ded868C5D10569bD73b06261F5556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.465390333512454,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.47,
  },
  {
    address: '0x5a03123E9E5AD9e4A356C11d680b41b47d24002D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.453938223275458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.45,
  },
  {
    address: '0x785ceDe2A5AD7c6f86B562CaE97aa5f206B00eC5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.453797469121586,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.45,
  },
  {
    address: '0x2AF90c6c6adC0adAE2DdaE1925110caE1275b0F1',
    minters_reward: 0,
    lp_providers_reward: 7.35,
    swapers_reward: 3.0498287061168208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.4,
  },
  {
    address: '0x9758e6Ef9bc6e5e485B19C2F897FF10E670eB263',
    minters_reward: 0,
    lp_providers_reward: 10.38,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.38,
  },
  {
    address: '0xdEed3275CCC6F0f81100AFb1a5C47e272f0751bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.353122052352914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.35,
  },
  {
    address: '0x517e00849b78c4Cb487B25422CE29a1d4c415151',
    minters_reward: 0,
    lp_providers_reward: 1.29,
    swapers_reward: 9.048297065178078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.34,
  },
  {
    address: '0x221ceCf21CD059378a2B9758b835F40dca52F803',
    minters_reward: 0,
    lp_providers_reward: 10.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.34,
  },
  {
    address: '0x7c1c586e01Ae41A8c1e8F3E75Bd86F7F154CF6D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.325659005936302,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.33,
  },
  {
    address: '0xC7eb15e102F1FF71056BC8B13E1c0488bCE153cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.28647076414029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.29,
  },
  {
    address: '0x348FB6C1495b9F69F414F1B4BF2370e35C88e5f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.283559858025436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.28,
  },
  {
    address: '0x674c0120977875C8bc3A3d9313033BC8aDC7BC20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.280490901805537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.28,
  },
  {
    address: '0xF9fa3278b32d7f06F5875EbC29c47B9f69803b9F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.24673280192025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.25,
  },
  {
    address: '0x274E7a7808DceeD01BeC0045AF388D6E1Ef8822d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.181947992863314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.18,
  },
  {
    address: '0xa032AD0b4F14977e5F29Cb56557Ad3D62Ed05B04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.181540250428746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.18,
  },
  {
    address: '0xebeAF2E85173586D4E0DBC6C7bDE5edb8Db63bb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.17109987412544,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.17,
  },
  {
    address: '0x48720a7B117e52d13231dEe5B735C11377eF9958',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.148378246385954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.15,
  },
  {
    address: '0x4451B5ddBCE63DF82b96AfA7EE22479151e38C2e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.144905417461645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.14,
  },
  {
    address: '0x1EC9F531A0672679F34c5C9C3a89938313801391',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.130122290831265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.13,
  },
  {
    address: '0x61096c851fE410Be4fBB23fe348fA4C3e99C6AED',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.103332620644741,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.1,
  },
  {
    address: '0x461249db6144d503Ea428000dd0c7b9412749708',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.028542471111518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.03,
  },
  {
    address: '0x53F51B6dEBC2ab4d44884b266F0f78f275eAcE23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.016034153932766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.02,
  },
  {
    address: '0xA52721a34e1EF17F977D4Af583dF123E53f1Fe80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.007402864388405,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.01,
  },
  {
    address: '0x7a481D045e9AB9d079118334f94BC8A965d1cA49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.006254599050106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.01,
  },
  {
    address: '0x6295D41BF8021cf1070130bE5591652324FA5D6A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.003319676809113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10,
  },
  {
    address: '0xa8B3aDC2Af9fA5E0aCfAF2f4351E088d870B2ef3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.989224824641765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.99,
  },
  {
    address: '0x94F3771a416c54269014bc753D1E6D134a8Ed5c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.976304647950935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.98,
  },
  {
    address: '0x3A9A33430efCb0C94F82fb165aAC140B71A1c1DE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.945058712763855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.95,
  },
  {
    address: '0x54C540cBDBAaEB15f881A0433eb1260103C0e1bB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.927650197840025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.93,
  },
  {
    address: '0xB386A4b00c10516B67501402F025424330fD3fba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.914019428038559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.91,
  },
  {
    address: '0x63DC220b050451cE66b8CA9A304998568E0315E2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.912447030021003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.91,
  },
  {
    address: '0x536223085482D17449620EaeBbDb7260bCCdf4ce',
    minters_reward: 0,
    lp_providers_reward: 6.08,
    swapers_reward: 3.818929065706719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.9,
  },
  {
    address: '0x3704b99A87A4b957F78AEFAb1b70e26442b32917',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.849485150127794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.85,
  },
  {
    address: '0x1490297Ff44019121d0D85F91F933cACE5f7DDA7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.829159568620016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.83,
  },
  {
    address: '0x8FD607e6a83F323e6DAb201280727A5379168A81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.823032715512158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.82,
  },
  {
    address: '0xe00F75e96A79583C818039693BB5e35B52B4A8e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.820554138484477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.82,
  },
  {
    address: '0x1714a0f0Aa120d7358c9C40D43DF37BD68C07d8E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.779031366970996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.78,
  },
  {
    address: '0xdD1709343DE72150263186f08259255292F18acF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.753289599796402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.75,
  },
  {
    address: '0x7f7cE3318deB501Fd6Ab823b389592DF7FE83991',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.727927160736389,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.73,
  },
  {
    address: '0xA53ED7212E5AE32eA70BD1df14c613414265d7f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.706492438943517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.71,
  },
  {
    address: '0xC051ee242994F138536fBEbA6c61D4A32CF7cfa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.704914066269026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.7,
  },
  {
    address: '0x8F024D7b2B22a45577D378dB9EBe38C8Acf6d778',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.697828511099067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.7,
  },
  {
    address: '0x5DAec7D73f1A2aAbce8eD5CDdd74B80Be1b35001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.66559223957296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.67,
  },
  {
    address: '0x3f90D0c40cA0e111A8fe93E3EaF8be42804511db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.64061923003866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.64,
  },
  {
    address: '0x4E3f49b5a8A3EfF98B1a3c78800673dBe207F3D6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.622822270287081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.62,
  },
  {
    address: '0x6329d3CCD96a893165395c6A00Ef4d5E4BD70aad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.612169025665693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.61,
  },
  {
    address: '0x15684d42685AdbC9AB59E0B02C01c1d1a28565Fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.610794030218837,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.61,
  },
  {
    address: '0x82Df609a836355Bd89de03d6B0ab8A5ABd9487C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.575317763092984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.58,
  },
  {
    address: '0xB28204B13Ef54AC28C8cCF299657B15C85A4A9d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.573321726695417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.57,
  },
  {
    address: '0xE89490B790EA4e1CE5196a31841dF117a2d4b019',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.554553018125794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.55,
  },
  {
    address: '0x2342a68421CA64142403BCF27A22CC4042Cf0386',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.526967456196846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.53,
  },
  {
    address: '0x559fd97a0bfb9F54b4ce69084a8EE6D7A75fb6a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.525020388945729,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.53,
  },
  {
    address: '0x933068dc9738a3158EF0A3a952801ED1081954e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.503424312660414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.5,
  },
  {
    address: '0xCf55CD2ADc5691EaC3274B675Cb777a07912010D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.481117156013777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.48,
  },
  {
    address: '0xDC98EB63B5777d356F18e933FdFA10455570207A',
    minters_reward: 0,
    lp_providers_reward: 9.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.48,
  },
  {
    address: '0x1e9E62c5E9c982CC783707531387EdF3f538Bff8',
    minters_reward: 0,
    lp_providers_reward: 7.03,
    swapers_reward: 2.404332270871283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.43,
  },
  {
    address: '0x8Cd42a1069e4E4C2701E282FC5D421e11C694052',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.396429990592951,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.4,
  },
  {
    address: '0xdd628184b83D48af7E0B192879ccf22efBEB5247',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.396107178778752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.4,
  },
  {
    address: '0x0FC75B45d19888D7fBDCb5CBc5F43CA8e01308c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.360447929094533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.36,
  },
  {
    address: '0x993A0df8A0711e04c73e114Be41cE27Bf578Ee9C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.359433035195861,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.36,
  },
  {
    address: '0x232a9149d1F72D977c7Ce969eADF176E077EE62F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.358835378019144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.36,
  },
  {
    address: '0x4be7CcEd5d1EFFf718E51b388D7DdBB6Ca1989bD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.339073786061324,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.34,
  },
  {
    address: '0xDa464c5184bbB689f905039f4D715aBcC183Ec58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.339073576570643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.34,
  },
  {
    address: '0xB41Fcc191Cd75f558cFD55C75B39a7Edb42ad105',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.333017591566959,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.33,
  },
  {
    address: '0x094f5812f27d30a7cDD1766f75E012c563e42Ea8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.322439461561869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.32,
  },
  {
    address: '0xb65E824f75CC3cEF85d25Fe75666F375621383a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.30986308067839,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.31,
  },
  {
    address: '0x06fd08A4847d840A1D4cE6B70EFe8344271811ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.299409764004519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.3,
  },
  {
    address: '0xBE6B9E948D4DBD1284e048C50FC0055a863f2C0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.292454315320322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.29,
  },
  {
    address: '0x132B46cd0461CBA55241Eb508258A89aCD5F7Be7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.242043342102166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.24,
  },
  {
    address: '0xa47ddd4C53fB9804CD0B135f4F4f990E541ef11C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.239711005862018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.24,
  },
  {
    address: '0xdaD1C1C28E746cD2e694b27E397dA26aEafDE6fD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.218888697765843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.22,
  },
  {
    address: '0x3728F0352197658A86Ce9FBa036bFA21E006832e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.217277541978511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.22,
  },
  {
    address: '0x2984602e9Bb439Dd2f8072dBe43dC082ec0E5874',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.141977990988302,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.14,
  },
  {
    address: '0x6d3AE21708c006b6C961C55ddE9a365432229490',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.120684141749583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.12,
  },
  {
    address: '0xc031189FdfDAEC31a78416964a50c4e29A7E9a84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.116291620867067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.12,
  },
  {
    address: '0x77321CacaE1c16b8E719543D3CdB876f2892fDD7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.086666433030148,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.09,
  },
  {
    address: '0xcAB96270939B029B073b6EB8459e990405b259B2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.059897553755041,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.06,
  },
  {
    address: '0x8f08cbbeffE245A373C3D56Ff2e1D43d791D66B0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.05701791934408,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.06,
  },
  {
    address: '0xAa8e662E2De09077dB8e7095503cb62EAeF0029E',
    minters_reward: 0,
    lp_providers_reward: 5,
    swapers_reward: 4.037079141331719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.04,
  },
  {
    address: '0x8e6192D579E82946b2012877428e906DDeec3E26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.032368357412757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.03,
  },
  {
    address: '0xda6141F766208efa920aE1DB209F0Fa94CDFABab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.016666414497138,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.02,
  },
  {
    address: '0x8b3F5D205f3f77185AA78F48Bb6a07026eCb29A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.999229422764284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9,
  },
  {
    address: '0x44D337780e4748FE77FC0e2Dc3577e57D39a4c54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.977888757460304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.98,
  },
  {
    address: '0x7AE871976d9c85260c9775b66CC26E5FC9D069D8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.966362733187719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.97,
  },
  {
    address: '0x588DD1D30814380E2356a67cA2c0b9c5C801BaC5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.957693018392975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.96,
  },
  {
    address: '0x8413971a1A746cbf46E9cC03328283603E0c1155',
    minters_reward: 0,
    lp_providers_reward: 8.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.94,
  },
  {
    address: '0x30D7E1239261D3D0156e5e61C3F05fEA1cd28Da5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.932831873759266,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.93,
  },
  {
    address: '0x529Fa3A03d7999a8b36f763a2246A10F6dc92677',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.927459202802206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.93,
  },
  {
    address: '0xe7031f88F3fA396D4B7ab2251c66EF03ABC5E804',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.925925835889519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.93,
  },
  {
    address: '0xeb59F73C85D5b57655aeFa534Cd2ba7787FF53fD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.899771495983249,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.9,
  },
  {
    address: '0x135238e06BeB828b6d1F5e39e62CE02C7F558b35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.887467108938386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.89,
  },
  {
    address: '0xB830c20d271f204b8093494B17Ff15fd06cfD8cC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.881840129717157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.88,
  },
  {
    address: '0xB859eB4DCbdE7A8fa8E95d53671230d599068bF4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.881498442128008,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.88,
  },
  {
    address: '0x620bD8DFd8BCe587b766CBc2b6996521504154b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.88144040952409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.88,
  },
  {
    address: '0x54B1838086f6650548B43F510297685317F5F29B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.870537230001156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.87,
  },
  {
    address: '0xBFE5663e87C42e537e625f653CbA6d4d368f0068',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.870190207770658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.87,
  },
  {
    address: '0xBC246F5Baa3288fD0BEA4B05BA94FeD99859429a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.816678540810738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.82,
  },
  {
    address: '0xb894C98a8ad3050Bc03E92FEF6E34D5eb3a80E4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.813881158700553,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.81,
  },
  {
    address: '0x36C7b80Fc7172cf507623D13C65F947C704d5C67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.804174226502326,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.8,
  },
  {
    address: '0x0e31c28bbb92EF58aAdeD692d67AdB8bc3D03d6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.803894502012792,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.8,
  },
  {
    address: '0xB724CB75c6c51e8c5fDcef37D0215904a6Bf0a00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.800859059484981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.8,
  },
  {
    address: '0xc34d2FbB93484C918fD1b02f34b5e07118766033',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.789996908268114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.79,
  },
  {
    address: '0xFdeE859AF95e5b49f1F31185aC31D706a63Ff250',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.788372146952877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.79,
  },
  {
    address: '0x9249fB36925538850F9670492Ed4c50fe776a8ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.783170524417704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.78,
  },
  {
    address: '0xe1411b8a07FD0c1b7B9d429e62764F331765a3A3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.750682722540429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.75,
  },
  {
    address: '0x8Fa51D459BD4D3d7fDcA66cB102653FF9CC4Ab75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.750184367365827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.75,
  },
  {
    address: '0xdab8d72E76F6AA46919139E08f7ECD5379504a7C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.73219142287939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.73,
  },
  {
    address: '0xcF2B49D2075fF3B6F1E5651b2626F9047c2c992C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.731313378004067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.73,
  },
  {
    address: '0xD94F357b063e78a0F8dcEcf187e1260EA3d7d5d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.713805055492259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.71,
  },
  {
    address: '0x48F7B079980ad740A5429eEa9c19e8ceD9ce918b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.713534819086211,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.71,
  },
  {
    address: '0x44D95F5037082F83E7Fd03DAa73Ba1ABcd3c6B2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.710646422866319,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.71,
  },
  {
    address: '0xc84AECaAe1dea6210513A399E054de16B0B64793',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.668268018616358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.67,
  },
  {
    address: '0xaf9E5AbE565214c7c72CA93C68192a8b96f11d16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.66080483158736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.66,
  },
  {
    address: '0x7D8d9fE08157453C5DB81BE4c1eAFDE0DBb28f4F',
    minters_reward: 0,
    lp_providers_reward: 7.61,
    swapers_reward: 1.0385816355433337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.65,
  },
  {
    address: '0xdc894aA317170296630eAbB0Ac0270fFe63911B0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.623411004781538,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.62,
  },
  {
    address: '0xFAdaF4b85F01C7fC828AE218502AC5FD3F517AAe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.62294790336802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.62,
  },
  {
    address: '0x71F2b9412356c6226079842EE3DD6203DF69b74f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.595729672876807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.6,
  },
  {
    address: '0x74F72500198FC8A4553764208F8EaB54747c9EA7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.593914483592924,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.59,
  },
  {
    address: '0x0040331B1042FeB29D5fda19660fe76C03082980',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.577281105051647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.58,
  },
  {
    address: '0x9Fda00695F137F8b129bd2EfF36E7d6E71516F61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.562874362321402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.56,
  },
  {
    address: '0xce2Ecfa8F627C76b297bAed0e8a087aFbF8b57EE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.558587412451367,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.56,
  },
  {
    address: '0x44c2130c18cce60fC9D3D2F99FCF95301517faD6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.51587272597246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.52,
  },
  {
    address: '0x706A418509cFCD461b12F6bFa0d8d9f160eb712A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.504387391262458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.5,
  },
  {
    address: '0xf37eF7c2A539459C93483030Ea6791bd677e6D9E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.492740633912726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.49,
  },
  {
    address: '0x92AAbECe5960a1e88fd4cf326b36a7D498c2F741',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.44170636032502,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.44,
  },
  {
    address: '0x3629a9F9fDb392331922AD2BBD5f6EE01A9168A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.396303746783977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.4,
  },
  {
    address: '0x3934B57b4C5D857e08cA6252F2E6B1A02C6a8D61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.348929721934129,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.35,
  },
  {
    address: '0x305411d466b49F7E05F84AE12bff8c2F785069A0',
    minters_reward: 0,
    lp_providers_reward: 7.43,
    swapers_reward: 0.9194027266441714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.35,
  },
  {
    address: '0xcE55D9eB38EEeFeCCE2e839392B53D97ec050C59',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.341026677366658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.34,
  },
  {
    address: '0xc8522758875e74e3886d5e4f00d0788b2BF96107',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.306574543547683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.31,
  },
  {
    address: '0x085d952EFE573daA90557BA7bDEAb0B95cF92459',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.301820845814865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.3,
  },
  {
    address: '0x409fc6AD97A7f8f3549C82409e3f5a6Df043F732',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.296906917767442,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.3,
  },
  {
    address: '0x56f6f437d2fb0138652BEc25B3a036B9d1d1ab90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.16456842563509,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.16,
  },
  {
    address: '0x65a723Fc4bF861104474F815E08F2ff3f9aD468b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.159079635254546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.16,
  },
  {
    address: '0x2a9182e771247304c505F26d9A171b9E90aCC9E7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.156104341791178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.16,
  },
  {
    address: '0x754331Bd5FD1b8B0783F4283dF20fbD7232897fA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.136026717680293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.14,
  },
  {
    address: '0x0e3d1b497Ef59b6beb8aC77b264A2463F52f5224',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.123974513366388,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0xA5df93949f0bb173D4227D3f5B7ac9373C6c6fAD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.123974513366388,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0x2FBDE8ceFE5F3A9D3F39aE1c2c10F7Cff72a26A9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.121021736834033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0xD47f25485a002B1626a760a5212e13b64F573aEb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.119428859782545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0x9218f24516f9184554cd24401BdfF34884decb7b',
    minters_reward: 0,
    lp_providers_reward: 2.97,
    swapers_reward: 5.151508396439954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0xe882720d542f01f310a19B66d051E79aBbe90365',
    minters_reward: 0,
    lp_providers_reward: 1.5,
    swapers_reward: 6.603641083448547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.1,
  },
  {
    address: '0x2356B4306900D8055c7fBa0d044FD14C3D8Da14A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.085296578971958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.09,
  },
  {
    address: '0x1c45eBB9b0a715F4ae8B0b6a8310762e3797F51F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.050642505344012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.05,
  },
  {
    address: '0xe62f6Be9E9F853558D3BBe4c978E87fE3C0EB4D3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.042503238424047,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.04,
  },
  {
    address: '0x4d0E05C15138336546ae01e3a1be362634CD5053',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.032355698005315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.03,
  },
  {
    address: '0x69Ec44A0f63f8b03083A3BEb0f3B4f4F713E61aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.016003193667101,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.02,
  },
  {
    address: '0x4b10D37EeD3DA1E3E7F10E4f1d9c3Ab379Cb4A8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.006860993044178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.01,
  },
  {
    address: '0x096Aa187594Ebe957A0e14442823Bc448015fC7D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.992522468861597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.99,
  },
  {
    address: '0x69c1B7DB9D4a03ab6F4aEbFCc47200376b70dA86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.989138758091615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.99,
  },
  {
    address: '0xfD381E6Acc13Be13b985Bff76672E2ddeeC4735B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.989138758091615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.99,
  },
  {
    address: '0x0d377F648646e5f595E10b30F0101f0ad8cDF74e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.988058116376573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.99,
  },
  {
    address: '0x0aD82C230338B52AC4f04d8C9Da371eA3fD1184C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.9791557838789,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.98,
  },
  {
    address: '0x51f4Dfa332CBc232102747d1aAf6865bEb3e2350',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.965354354248009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.97,
  },
  {
    address: '0x6A522994329C3b1640a86809e2E7c2070e14632D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.958098651783528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.96,
  },
  {
    address: '0xf25372B14ad6528818e881324981aE3910E39444',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.952326293371411,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.95,
  },
  {
    address: '0xdFe51f4168e6D0E220BFA140830f3d73d9dD6B68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.94637044729002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.95,
  },
  {
    address: '0x92D0b9c6FD7A6B38085a8834415C6422777cdD62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.93925083214782,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.94,
  },
  {
    address: '0xaFEC395B321aa4BA5BB78d47B88CDAC7707A9df2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.93925083214782,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.94,
  },
  {
    address: '0xF7B50248D2DDFf723d72cF8114816bbeC6db5b9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.93925083214782,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.94,
  },
  {
    address: '0x368F767453491018a62e1176bD277a24706D624b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.901875040623358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.9,
  },
  {
    address: '0x9B8609e6fd7a331FCaf940044113B344a0f2B0CE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.892639926989056,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.89,
  },
  {
    address: '0xa3f8b2f4Bb787D88d58d46A3Be39a34c23Ed7E13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.865892423058122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.87,
  },
  {
    address: '0xEC54B98634d93C995FEd6c638F2349E7D4Fd195a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.855092106983438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.86,
  },
  {
    address: '0x30663cd7615d2C074817489586eb9B995791De28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.847535495729187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.85,
  },
  {
    address: '0x9aa36b54763cE1EB9591619BB2795336a498cAdA',
    minters_reward: 0,
    lp_providers_reward: 5.33,
    swapers_reward: 2.5214513173538546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.85,
  },
  {
    address: '0x93E472865DF3DFE3E70408443DDfa7428157e691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.8418701985875146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.84,
  },
  {
    address: '0x197Ae9Ad4465D160631074329aaFa91bc21088d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.839742068570904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.84,
  },
  {
    address: '0x5674C5321dD7bf9a467577fe9105861103C0913f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.824093741168163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.82,
  },
  {
    address: '0x541BC9AaEEb8a974ff3f3150E506fC3C1908812B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.813451762651659,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.81,
  },
  {
    address: '0x390E57e5DF4bC729De96d99209a9B53aB89AE525',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.812341826877713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.81,
  },
  {
    address: '0x6D6aCbF04AC69d14f580c87fe01650A2ac4D1955',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.761123286315103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.76,
  },
  {
    address: '0x7DCb7608B9321F4b1F1d8881D5dB4A77F66E2F1b',
    minters_reward: 0,
    lp_providers_reward: 7.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.76,
  },
  {
    address: '0xce08E0de81520e6db0ECA8B0E1d38D3909a65847',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.737607686665713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.74,
  },
  {
    address: '0x84d1C6b46957e518501811FB7B94F2398AF137a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.725670865141584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.73,
  },
  {
    address: '0x18D8435D0DA23dDF77D924525aDbB8BBeFFCB6a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.725670865141584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.73,
  },
  {
    address: '0x668Ef87B096Af8945f01c5D9339f5ef82F4829E0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.707201452529363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.71,
  },
  {
    address: '0xED3FeC83D146cC27A804Be1113a745B502C4EAbA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.707035325912009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.71,
  },
  {
    address: '0x20F1EBcb510248eedFd7324fd6823CbFb8D695Aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.703393339993925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.7,
  },
  {
    address: '0x11764e024893d6a4DfD40A10aB8851CB8a5042A5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.696590822570876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.7,
  },
  {
    address: '0x587027eD44B0044bB437AE9a536c85B51c3A4241',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.696057497290201,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.7,
  },
  {
    address: '0x3F9361179d7823108e5A32647db9877b5BFD29F9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.695161666383534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.7,
  },
  {
    address: '0x36AebF0BB9bD11D67dd69D96858e90FA9057c4d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.694214226606433,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.69,
  },
  {
    address: '0xB41357563D81e7085832E4479d5b01E379F0515c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.681310000756658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.68,
  },
  {
    address: '0xb77180c4218a8a0367Dc106680BEfc2408CCdC38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.655662488834706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.66,
  },
  {
    address: '0x90f870B923ee2b8f1FDEdb434f82EE0184302a15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.6528562870154575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.65,
  },
  {
    address: '0x8225aacDF913D48c1Fb8Fb813512E500d13B1700',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.65234969535117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.65,
  },
  {
    address: '0xab58aBc8A46Ba6155748feBad721eA7366403453',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.651626973764214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.65,
  },
  {
    address: '0xf5eA7Eb725Cf2D05c2c01bA873F5c3F996d594dA',
    minters_reward: 0,
    lp_providers_reward: 7.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.62,
  },
  {
    address: '0x7a860DE26fD79c0d756510112d86B3b672935C6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.601354278042366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.6,
  },
  {
    address: '0x1B48E015e0A3F0F89Efe434e3E026574300BCf82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.568182129994051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.57,
  },
  {
    address: '0xD3482F1038F7e80b4228BFDdd48a9F5CD399a66d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.552375643252865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.55,
  },
  {
    address: '0xb430612A48589013AB3E02b9050c7A2829f8F985',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.543561491872912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.54,
  },
  {
    address: '0xFcfee4D319341AFf1E421864F5c126e2Dd44C8A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.536701771783243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.54,
  },
  {
    address: '0xe3E8ddF7C2f90fFF8f1151801130A5fa6850BA4B',
    minters_reward: 0,
    lp_providers_reward: 6.23,
    swapers_reward: 1.3103225548446824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.54,
  },
  {
    address: '0xB4142591f6b167b7901C7130c44ac58457d932A3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.5223075815382465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.52,
  },
  {
    address: '0x892906b5416003b584B2120A8E16ca00Cf27Eab5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.518122101774573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.52,
  },
  {
    address: '0x646fE43F9331e18d41B8cF8702645cf1cEB8ea91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.516237990623039,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.52,
  },
  {
    address: '0x39c1F4Cc285b379b7e1402adc7da62F9CE4EAec6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.506438700234322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.51,
  },
  {
    address: '0x4345ccA77f01B65A21815CfEb9ba366ade74CAaC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.480851818511602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.48,
  },
  {
    address: '0xda58f560943616D19511Dd7f090F526908878aD8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.468743442182858,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.47,
  },
  {
    address: '0xb1Fa084901B179dEc04E6BA0267865AAF9c76C6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.447062379337486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.45,
  },
  {
    address: '0xe8f772032992aaf48Ebe6acD7101762A473a17f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.421192521869764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.42,
  },
  {
    address: '0x302CbfDd443F43874980B03cDFC2126B32702Bc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0xc80b29FD2b8c6270428c8fbEa8c9CE63D298F800',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0x01Cf679D22556f058254EAcf42A2B7b66A3FEbdF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0xa6B89CeeC5624190D4e8B6A8234F80e6dd02797B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0x39C767B6586722862De7D7e7BAA25a01711bb9A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0xab9e4493c7c50ad6157d0Dd5cF566b7428B4a84c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.393995029780165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.39,
  },
  {
    address: '0xAC3598667f7781c9b8fC0Ff40bd1808DcDBC7996',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.388820158994814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.39,
  },
  {
    address: '0x7f72ba7B318FAe3Ef1945B141CdAFa650E206D60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.370291807796477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.37,
  },
  {
    address: '0xabB2A1a32B71784a92294A8D47d033A8863bdAA4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.362654928050409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0x48332886ce0f4e967eF9c10569d18635313152d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.362570105231923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0xFA7C763A8f8f4cdfD21b8Eb932FfEF5E4D46a3b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.359209829276178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0xC9AB941E957bb8C6FeAB314eFBEf0950d0e1AAC6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.3586517979123265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0xe965C21678E2b90F08e56BF3C412757897fd74A3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.355066769386829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0x889EfB9bEe1bF0c82B86207c54e9BFe4681BFa7E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.333153865918291,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.33,
  },
  {
    address: '0x249eE15b61846113442e56cEcd62a987D11893d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.3300965090662915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.33,
  },
  {
    address: '0x2E8EdB53eE02A5029e2Ff7Eaf80849488680684c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.325780288785768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.33,
  },
  {
    address: '0x558f99A4570ef5AF353521C9B4b8b614d3D97d25',
    minters_reward: 0,
    lp_providers_reward: 4.5,
    swapers_reward: 2.8260833430256516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.33,
  },
  {
    address: '0xA8284503efa81664Ae3AE8A0D99783C44787cDb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.323875359071119,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.32,
  },
  {
    address: '0x2DDaD446B93769B5a30cdd39a5301B53022E92b9',
    minters_reward: 0,
    lp_providers_reward: 7.11,
    swapers_reward: 0.2034037621558422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.31,
  },
  {
    address: '0x7eE7D88458Ba2A3C2E64442bA0226f5DFbB5fd73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.300187470309994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.3,
  },
  {
    address: '0x9231475e43BFEA8f34A7BD91D252ee4b7fA91FbD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.297693147403901,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.3,
  },
  {
    address: '0xC3d8B2be67c164Edf7c8E46613042866b3381c2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.287886004704562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.29,
  },
  {
    address: '0x2cC35b9540BaA2ab79aDc8E3688767bB92AD985B',
    minters_reward: 0,
    lp_providers_reward: 5.47,
    swapers_reward: 1.8170306699425671,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.29,
  },
  {
    address: '0xfDA5d7231357ECc7B2aA5E3b7d7407478Fbf3df6',
    minters_reward: 0,
    lp_providers_reward: 7.27,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.27,
  },
  {
    address: '0x78b5DD4E63c5129fF2212129C56542b08f5798AE',
    minters_reward: 0,
    lp_providers_reward: 7.27,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.27,
  },
  {
    address: '0x423e1ee706f2e413F4b9155Ac1eC34757FA0F753',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.263214495302373,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.26,
  },
  {
    address: '0xe400702C8f9578A7A0514e89a2c22B0409069c31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.255727949145401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.26,
  },
  {
    address: '0x3E30fA6dD73930AeDbaFB132Ca0c0F2B12027079',
    minters_reward: 0,
    lp_providers_reward: 5.95,
    swapers_reward: 1.307098951470934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.26,
  },
  {
    address: '0xc7a2a7CEc19a0cde9CA84E8205aC7F14Eae23230',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.213270568050647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.21,
  },
  {
    address: '0xb76bbA464B9A984Cf38a785a95E60F7636830718',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.200117810066388,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.2,
  },
  {
    address: '0x22A8c55cd02F78425392Dcc0b401Db632951BD2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.193307957037948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.19,
  },
  {
    address: '0x89034AD8638Ab8d52D930452A0fb4E2f318D505E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.187213257697998,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.19,
  },
  {
    address: '0xe6C7129eA0441Ef3af8fb7040B746c44473Fb117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.164369077348348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.16,
  },
  {
    address: '0xC0EBE77128c8bC67A0593C2b8c8eFf7C44371fe1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.161467167460301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.16,
  },
  {
    address: '0x560fB826F4Eca4f2A37e6AbB00A8be8Cf29D5538',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.15903485833692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.16,
  },
  {
    address: '0xd7829A895bFe699b0683Da7f17FBa8E940DDdF8F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.147324713607218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.15,
  },
  {
    address: '0xfBAc52CE9FC67281A705d5cd94BF8184d69751A1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.127986369687344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.13,
  },
  {
    address: '0xC3c5513dd1D314E790eCeB8b68b06a44007b33e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11734772953012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x5954E73781bB5EcE885C9432860b2eD41da30eE0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x5Bc8A204a886Bc615E6eb6de961dEA89DEEFf699',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x5fA71cA168343C58AF870014cF4694b89fc7f2F8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x6291374CBdcfB8b6E9447F88e59A38Fa38D23C00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x8594FECa4dc910991bee6Da708ce6700FB145946',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0xdA19669ec1cE838B1b719f4D1A599d2AB9b16dC1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x9469EaFE654bC53c28EdCb9104DC799543cCe237',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11397261364089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.11,
  },
  {
    address: '0x87caC3B3e50B69CE2d888eC67214C8e673ECb0E6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.053704052857598,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.05,
  },
  {
    address: '0x578Dec2528eb30A9ef5329Dc6A3961e5Ab90Fd3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.052824164470417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.05,
  },
  {
    address: '0xeB13e5928061abA7c2FF832d042752eB9ed24692',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.037594369995075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.04,
  },
  {
    address: '0x333a5F760AA7E4Ad6E9D2740E58199a9760F5ee2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.026642991663362,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.03,
  },
  {
    address: '0xCbF1E50cB75C21EC89f925c75629CFF1B27d8f69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.008184860182018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.01,
  },
  {
    address: '0x55DE0E308dCA2CA1bC8a38F5Aaff38D141b1caBC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.001509919762975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7,
  },
  {
    address: '0x7da7958714E9Fd73753196D6444a8fAB9E92bbCe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.980792823916503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.98,
  },
  {
    address: '0x40497b0A04b88A82908Ef318684b7F994169380d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.97470720481185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0x04f6a0EbC81467Cca9A26a3b383369138639a8d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.974691522490831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0x17A09Dd198Bae48837fA72D0C0676cA8792187B1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.969292148850305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0x9E9CB2e83B7b7780D5D4F8cbe3dae0268D35b19c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.968842543644902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0xba9653d1C6AFd39E0Af69c4fE0C85E834825aF55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.968670016909281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0x4ef6E77D997e79c5aa1ecC25c29E8A18DB6a35C7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.9545380720552625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.95,
  },
  {
    address: '0xAeb2981e43Cfbbaf61d8C72843f3d8511554073c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.951036378580876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.95,
  },
  {
    address: '0xB3aB126322098E12f43115e6f1032e4b219C7fA2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.938116121966305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.94,
  },
  {
    address: '0x8f8304772F3877EFE661cc0432623496fD3D06B4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.930499766104321,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.93,
  },
  {
    address: '0xECaf1c43c3b8B095a8C1E09462d3b3d98C6b4775',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.929000236535141,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.93,
  },
  {
    address: '0x65905d647c6F86E0e4143E4a38F0f030E6f8c0c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.9248500553442245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.92,
  },
  {
    address: '0xD98f0379c1D5427dBD0663654C5B7F4d223E58f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.899370537783874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.9,
  },
  {
    address: '0xc7371941A9408F7C881497Ab7223781a22e89403',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.897915264096652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.9,
  },
  {
    address: '0x5817244AdBa94805d248ADfa5b0c5d3F0DEd5AAe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.897915264096652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.9,
  },
  {
    address: '0x24c2De5AA01ffF4fe1A521FaB1288fd3d010D414',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.8482463322818115,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.85,
  },
  {
    address: '0x11Ffd9022b85ac4df51D7B48ac1A8cD56246630D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.812846109916973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.81,
  },
  {
    address: '0x910483C449455667603230765C39434eD890b190',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.806347426459338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.81,
  },
  {
    address: '0x6fCF723CA68Ca6cA1921dceeb700Da17C950d084',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.77934104478824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.78,
  },
  {
    address: '0x6868D84DC2c8E1C902663F423C3c61B946A81D58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.778189866687517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.78,
  },
  {
    address: '0x27E401Bddbf1bc7Bcf3FCF112528D9E4aF9574FC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.772226540009841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.77,
  },
  {
    address: '0xE690A61E3986d4ab739a4C94cF82D061B96aF38c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.772181010147974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.77,
  },
  {
    address: '0x8F0fe7F9A3966D8Eb6DEC75F857048fEf2D9db24',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.771847876103412,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.77,
  },
  {
    address: '0xC0E0DbFB8b9297b1b67B9690297f7BC141a68ae0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.768233202358693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.77,
  },
  {
    address: '0x5C5de1bCB04066816Fb3595B4250770805EF1a91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.756445930344353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.76,
  },
  {
    address: '0x58B727802f1beE135d92D5584E4fA34dF968953A',
    minters_reward: 0,
    lp_providers_reward: 6.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.76,
  },
  {
    address: '0xd42d6AE725Bf1eA1Fa217fB3b7211848250C55fC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.737617529579811,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.74,
  },
  {
    address: '0xC4658e41e326E5642368CD47D28B468bc054C2c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.72485386458326,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.72,
  },
  {
    address: '0x7794e69Ea85B9ca714A6974c4280c169B172a00C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.723639098531311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.72,
  },
  {
    address: '0xb6c491F9b2199Ce132Fd8DFF86939cCf8B25bd4D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.719777830560859,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.72,
  },
  {
    address: '0x30d5CC3E4ed875e36d28537984C61AA5cED652ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.709985925575739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.71,
  },
  {
    address: '0x857158F1fbc8f9D170Db40D1a1dB1641ec3Ea18D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.69956027043957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.7,
  },
  {
    address: '0x721CA0ec3001856958a59744B24407Afb9407a92',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.694693899312207,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.69,
  },
  {
    address: '0xaaD8c941e22BA9b24898D3294aAB709F96a74e94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.692143824697421,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.69,
  },
  {
    address: '0x160401743AE457aF789483fF455794C3ccD64bBF',
    minters_reward: 0,
    lp_providers_reward: 6.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.68,
  },
  {
    address: '0xB58ebB2dcE51EEbe31095e7b57d1Ed5e6254349B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.665655723490182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.67,
  },
  {
    address: '0xF0F05438F3b0e005886F91624B701147223f622b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.663582045283323,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.66,
  },
  {
    address: '0xf968741d868B29FC257789dC8b414C449101d368',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.649454801681495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.65,
  },
  {
    address: '0x59d4CA42555c0e5Bb6cE58921DB306d33faa6B04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.647970338931763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.65,
  },
  {
    address: '0xf0C5a4dD47b3f2c9782e246dF0C18fb2762d4017',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.636782009209644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.64,
  },
  {
    address: '0x6Ae0703B42572b315898465035DcD9F532f8D37b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.633767737126284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.63,
  },
  {
    address: '0x088b722A46f9d957679A593A33A0855965cD5a8E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.607380620359302,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.61,
  },
  {
    address: '0xbBEA6e3E273E77CB0bAf5cDC21E2070eBe1DABee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.600853719570322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.6,
  },
  {
    address: '0x98E844E2FCf80532905D1e9E18ee7906eBB9474C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.589746341078445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.59,
  },
  {
    address: '0xbd860e5bcd35a7904974c22E1FfC65BB5EFd9106',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.584920498404175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.58,
  },
  {
    address: '0x1E4A56711a531A083A0E52cd19c9FFf241D6c3D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.584920498404175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.58,
  },
  {
    address: '0x0f87ceDdB9AE6Fe3Bbd8eea8b6979cF659598765',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.567216212945154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.57,
  },
  {
    address: '0x05900288ec8e8230f357cB6520C3F7b031C0115B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.566717284395073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.57,
  },
  {
    address: '0x2A95D8EB4783749273AB38e380143AE3Ab86c0C8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.5590089203449065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.56,
  },
  {
    address: '0x9E8496D865a76822E15D7521dd6c6b3889b9679E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.551612774223413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.55,
  },
  {
    address: '0x13a76160a98955eD5e212710933cfb7fb9e4335D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.532877410702098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.53,
  },
  {
    address: '0x646e3e02ffEF0A93BD6a5723c9d850EEdB5A25e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.49917961069311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.5,
  },
  {
    address: '0x34Cc30E2609724dE781A4B9bAEB228f7C390CE0E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.48747516847806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.49,
  },
  {
    address: '0xD2eb329F7329D2CD31071ED3143Aeea52dA8EAF3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.49,
  },
  {
    address: '0xD19e4B03c590E0e0aB7507B7A8762c44B43441cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.482839793338879,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.48,
  },
  {
    address: '0x6C68be3CA23d57663F076CE503a14bae872e83b4',
    minters_reward: 0,
    lp_providers_reward: 6.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.48,
  },
  {
    address: '0x3159062dE8534571fe26FA9C1794BfF46E9D8460',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.460800155156827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.46,
  },
  {
    address: '0x721c4BaF70aA541196342f820df445476727a622',
    minters_reward: 0,
    lp_providers_reward: 2.49,
    swapers_reward: 3.9481956419819797,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.44,
  },
  {
    address: '0x94544Bb40D82Ac4207Ec0761B78DE5a5250206e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.432098273875894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.43,
  },
  {
    address: '0x0C82C004D648D5e154AB0edF50749a30a7cFF794',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.418245714157463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.42,
  },
  {
    address: '0x5043AaeA12FEf25ef0F3711Ca646D1e73f37fd6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.385847018051924,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.39,
  },
  {
    address: '0x228664FC47104Aa5cC9c77E9B6C95114C06433F4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.372835815199674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.37,
  },
  {
    address: '0x07cBEBa172D3a3966aEd08f85E15b5A24E743D15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.367610192462932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.37,
  },
  {
    address: '0xc56c45F6b449d834F8Ce1076B68a6E1bC422ABF0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.351728105814303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.35,
  },
  {
    address: '0xCacFF0608d325bAE1947AF692059b4fd501Ff3E2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.346274481025044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.35,
  },
  {
    address: '0x023B9bF246D151CF99cC5eC2cf8c8f23947AE9C1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.328296648186668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.33,
  },
  {
    address: '0x4CE9aa50556c804ed6801C57Bc0aBa5675679d21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.316951557989993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.32,
  },
  {
    address: '0x4185574ff2daA8Cff98988a1386C1608181cea18',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.3015399269069805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.3,
  },
  {
    address: '0x3634AF0b942c44Dd9BbC5B4aa0209e837E74822c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.292648252189679,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x4a662b8A87C18076958EaAb9764410E2f34b4186',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.291311253650892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x02436c0B2e1E0E306b006B89D582C33C45b4064F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.291311253650892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x02D2ECE1a2F727608fFa0E080FD5017A8fcB4407',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.291311253650892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x6b1073Faf198571DfC228927602a7685610D68Ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.291311253650892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x6F4d890F376536b8c451989861F9d9fBd4973fca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.288190900253003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0xd8Cd367c75972811806fDbBFdE72e8709e993f7B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.282581412581667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.28,
  },
  {
    address: '0x7Ce9624a9c68Ed59BAd638c8d702D5996f19c8D6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.27705135513335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.28,
  },
  {
    address: '0x8a2C64f75BB457356ab2f6B20dB559D398d8a357',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.271044589045734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.27,
  },
  {
    address: '0xB872734112b83199033571FEb7817FF3fEA648eF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.269942945253122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.27,
  },
  {
    address: '0x57949CaEe8a86245eEDB1A0988D0d49FeF1412Ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.263238007353893,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.26,
  },
  {
    address: '0x945eAcA2d62ADCBa6741f47552bB2CF51fA11543',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.261726011403208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.26,
  },
  {
    address: '0xB7392a02602d814eADCf811b3EcB1B651a73B6F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.240275797080244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.24,
  },
  {
    address: '0xD7906bf1EECEca95f138871b8EB0Edb86EFD8Bc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.237481032754044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.24,
  },
  {
    address: '0xb4486683beb002b1d27689BC1321843fb3E0998c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.227741001384148,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.23,
  },
  {
    address: '0x6642344A4410cA2fAd520Ef94003b576c423C4b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.227195352853151,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.23,
  },
  {
    address: '0x56BCd440A9D2fBDd92818227883a2d1A77e34677',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.226453269277792,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.23,
  },
  {
    address: '0x96da7872537965c81bfFa982f426ce31B034DE8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.219918723148106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.22,
  },
  {
    address: '0x4ff26a28350C86b38279Bc0F01743c05c1541e0F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.20560095816237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.21,
  },
  {
    address: '0x573b897BE7451730176881A8A858b5ca4F90F694',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.202358706024205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.2,
  },
  {
    address: '0xA7b137d4F72fFF6d3F4d88b95ecbD61029a6b8F2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.180715298740129,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.18,
  },
  {
    address: '0x098eaA4Eba2E6645EDd6FcCEE7a73A0535Ae63Bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.170857671744557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.17,
  },
  {
    address: '0xcD0978f99F7460202a6a077aDCb808b3bA9219a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.165628260729607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.17,
  },
  {
    address: '0x7167353141CAdEDf3E2918996E7C33e71B4f8bcB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.158451190292214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.16,
  },
  {
    address: '0x0AE2D6e0aB57e4Ed4c8a7376b0fe666aA0a9e204',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.155886442058143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.16,
  },
  {
    address: '0x5df9675F388ca626ACeD198A544E9f56EBD36E2b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.15140751351398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.15,
  },
  {
    address: '0x3fEFD7eDe6Cf2cb1834cf39af46Dd4d9e2f8c14F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.149345340010281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.15,
  },
  {
    address: '0x1A1513549FC3900cCC799eF18C89eBe554a64B80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.1284873976967775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.13,
  },
  {
    address: '0x678936aC78b23614461969c4a0C85D8775a8C215',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.10981282856168,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.11,
  },
  {
    address: '0x2b0e43697Cc587670547CC7eF4336E1463CFA5f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.095003886008857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.1,
  },
  {
    address: '0x3670E91CbedA9F5115D447a05C6741b3bE53550E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.070428148290965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.07,
  },
  {
    address: '0xfFbA47473418023fa60A9B1A6C06193e1614A172',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.031877428818035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.03,
  },
  {
    address: '0xD0c94A05d3013239A9D0D6A08F29820b6A0638e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.021173250841428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.02,
  },
  {
    address: '0x124714a5064FCBf367FC897bb49d7980695f53B7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.995817375647077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6,
  },
  {
    address: '0xd61d177276DaafF3f33D0AE38aA2965914EA9c20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.994969386379593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.99,
  },
  {
    address: '0x654F3Cb95b868A5b006405f9fC80E87b3De80124',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.984152789492008,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.98,
  },
  {
    address: '0xbd1f04e00C60d0A4A360F3019B1949d8C61BAE6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.965564953385556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.97,
  },
  {
    address: '0xcaB291443527c44B85a5fD0Ab5B90598d30Aa617',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.955694005207535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.96,
  },
  {
    address: '0x15fd0aC8B9136b802FF96f98Edb1E66b66b04780',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.9335199174951265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.93,
  },
  {
    address: '0xE9d30bd886c69Eab9471f36B78Aa19Ad642d082e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.925811714627021,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.93,
  },
  {
    address: '0x570bb7e6C9Da144Fb6bdBfBb4105bB6255688EB1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.919234099754378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.92,
  },
  {
    address: '0x331bCEff05Ee9f7c786a135f00A8d174C600eE2D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.907098867516232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.91,
  },
  {
    address: '0x2ABff15f66b5721fD8D4905Fb591aEC4373C3CeC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.890663618189847,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.89,
  },
  {
    address: '0x20e978F5D8C8Da5380418e8BFca147629232096F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.884499356395282,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.88,
  },
  {
    address: '0x561432eb9098F2Ad6dE2959F6142d57B2dE4aa64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.876073421964589,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.88,
  },
  {
    address: '0x70eeb7D66D2820075D46c500fa9D06eac1fd16FE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.874455346998663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.87,
  },
  {
    address: '0x18eFdEF35CB58B9a873eade3176A6a59f0147768',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.867450811409279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.87,
  },
  {
    address: '0x6ca7F8504ef13b7e853eDF1E78Bb637C8C9c917d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.8665230927346315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.87,
  },
  {
    address: '0x6462B0fB25C5C06e0F87D145054aC6EfE4c873BE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.859933858208068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.86,
  },
  {
    address: '0x517F411d5f98d1b4C45FCA6aD632fC8B32742D0E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.83889775524515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.84,
  },
  {
    address: '0xdadfB5cAfE3A82167EA473502C23529430C4e583',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.838248903209909,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.84,
  },
  {
    address: '0x44373B14A936cbe1A8fEB580991C45c50389A462',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.837701111189176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.84,
  },
  {
    address: '0x33b9AfD16aCae9A8bDB0286A568C3185DCfC6249',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.815514588758767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.82,
  },
  {
    address: '0xB04860358d3f23E570feAC9f3efd2Fad39E39DD2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.814790668339977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.81,
  },
  {
    address: '0x667166c6953210FF5D9a6C6c681a94a312c40d15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.800502458942253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.8,
  },
  {
    address: '0x13f6019903beFEE2dFBd169914709e0d3F24f940',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.7745773552514015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.77,
  },
  {
    address: '0x2507b82d7c77967138ACC7aA61745e4C8dD60A56',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.765334315529477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.77,
  },
  {
    address: '0x5cF683FaeDF310c8A6c506303aA33becd0C7f7DC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.763677796810739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.76,
  },
  {
    address: '0x938557694499B871c06Cb7CA9247077098f68Daf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.759651098582069,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.76,
  },
  {
    address: '0x0A287Ba835cE90F43b587f4885497f060F06F283',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.7420654411316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.74,
  },
  {
    address: '0xfC1291337ed84DDC2521A9646Cb0E54C6288276f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.739562977106686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.74,
  },
  {
    address: '0x8363Bf123fF3eE6F3f4A8b53e645FA8b1ba91Bae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.735149659619357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.74,
  },
  {
    address: '0x09A051f7C0644C8C5117593C5009d8fE92D92ed0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.734112318146458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.73,
  },
  {
    address: '0x3d6fb4C65cA66288BdA1dB1026aEc3D5AF2F9492',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.711699573087733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.71,
  },
  {
    address: '0xC663779720F6ccF0E6021FC12Fd9312D42f17980',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.710640760824778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.71,
  },
  {
    address: '0xF459199Af58fdC248B83f06ce06EaB0B664956c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.698040547307345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.7,
  },
  {
    address: '0x577E22bF6f5D4035325B9883312DBacB359425AE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.697129748317831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.7,
  },
  {
    address: '0x7deCB7d184E73f404f57067Ac2b08eEad82d9929',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.693661489604173,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.69,
  },
  {
    address: '0xe3B3bC1Ba8071A8f61926b2D367aD7a16Ed3CC46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.691689237843958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.69,
  },
  {
    address: '0x45e3BCBC9F1F80258A7E283B10906de03BDa266a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.677222342829578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.68,
  },
  {
    address: '0x96104536001578aBe18723B7F2F9D4f9A2b24262',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.66916892784022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.67,
  },
  {
    address: '0x510a2F2dfE509d99ED3130bA2C93521D41fEeA89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.668019561011509,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.67,
  },
  {
    address: '0x090C2F6085c6885B8c783f32356213A0e14D0188',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.659461344202536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.66,
  },
  {
    address: '0x489C4b60FC1caA75a8c218f2f0BBc38B61f9A305',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.656236618032687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.66,
  },
  {
    address: '0xd87FcA1c317F7C8aCba1fA50310fa4C0a28bA624',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.654121260707676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.65,
  },
  {
    address: '0xc898699336b984224c67C5bb5A1D4AbDB54eC6A9',
    minters_reward: 0,
    lp_providers_reward: 5.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.62,
  },
  {
    address: '0xF4c0eb95ea850a37AFF3c87Eb6D51bcd5569847B',
    minters_reward: 0,
    lp_providers_reward: 5.61,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.61,
  },
  {
    address: '0x8DA3CA8b18F5693D8FBa55ff7f2DC6445c85F5Cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.6005001857690475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.6,
  },
  {
    address: '0x96239782Fb57332e32Ad7501977B6aA56f4AF6C1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.595281588648699,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.6,
  },
  {
    address: '0xd96B9134Fd153Ea9082b9d77D3495D428833437f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.589435958172522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0x0DD2a34Da59B58725542c186b954a4F2DCA04A2E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.586458666571996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0xCc7ac57A671F9468e1A015DE5aC3c9BF18a691BD',
    minters_reward: 0,
    lp_providers_reward: 5.21,
    swapers_reward: 0.38335853019903005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0xaB4305c223caE1C22e5D282eE51516e1fA815B74',
    minters_reward: 0,
    lp_providers_reward: 5.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0x8355A88bE71936f8E626d090FDd9e76F0E261f0B',
    minters_reward: 0,
    lp_providers_reward: 5.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0xd16Ea17289a487B362b1CF4a16c8a1a340FD1135',
    minters_reward: 0,
    lp_providers_reward: 5.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0x62e219eaD407baF7dC4C162f97e3B264a08cC498',
    minters_reward: 0,
    lp_providers_reward: 5.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0x3bb7e01afBfB2cC8f1789240D9078B5C9a63Cf08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.5771514047181565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.58,
  },
  {
    address: '0xabA4489a76a13bFc9bd6Ee18D7AA928bc9A3a971',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.5754337190802445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.58,
  },
  {
    address: '0x754eD6423108245bD5C7FF5ACFeb8Aa16D803d51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.566153880582748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.57,
  },
  {
    address: '0x6f2f3c2eE38B3f5ed859CA3385d276c857D36e5D',
    minters_reward: 0,
    lp_providers_reward: 5.57,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.57,
  },
  {
    address: '0x594AB9eBa797d5A977747125912B81F4aE8536C7',
    minters_reward: 0,
    lp_providers_reward: 5.56,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.56,
  },
  {
    address: '0xeCF617b273D953e50309342132729Ce70DEF2336',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.543507904553616,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.54,
  },
  {
    address: '0xe3bD9C9e2433E311e612A161c3429062BCAcc5Bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.541141680016434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.54,
  },
  {
    address: '0x22281Fe0ef89643c10BA42e1D1D014ACcC154033',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.538385744362303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.54,
  },
  {
    address: '0x893eEd49af96EFB6e187687e69765618757439c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.519864724290733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.52,
  },
  {
    address: '0x0ACc629BAFc382de295eEA7158F2537493134FaD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.519009306799048,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.52,
  },
  {
    address: '0xA435798dbe89671333b2b3BE5Afd8B834d4f2170',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.503924485809739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.5,
  },
  {
    address: '0x232A181C4061D30a1FAb8610226D95635141Dc08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.489269691339618,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.49,
  },
  {
    address: '0x30d86B4982dC4d85f5dD1d434228248023454006',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.46621378840135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.47,
  },
  {
    address: '0x6C8771a305e8AE504B6f7493122182fb47a74973',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.450654849366457,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.45,
  },
  {
    address: '0x4ee72B91a8A03A273828aE3C3b6c951d8C8AD41D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.448954360480231,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.45,
  },
  {
    address: '0x2a3907fe059DaaD328b86Ca64a74dE215B282ef0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.3835621900091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.38,
  },
  {
    address: '0x7b22bD41BB32D8BcF58954C66c79432941D1E40d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.3806415543258845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.38,
  },
  {
    address: '0xF9bFc269c76147261BB544522d20346BB1B0AE01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.357341818969112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.36,
  },
  {
    address: '0x97a6339827c016b2d4C3A450bE820432a9223d36',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.355451276740496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.36,
  },
  {
    address: '0xBbB1304bCe3769A27AFf85CeCeBd0155F44488B1',
    minters_reward: 0,
    lp_providers_reward: 5.35,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.35,
  },
  {
    address: '0x8B24038B4F2C73285fab6312eeF2EaD983d22c58',
    minters_reward: 0,
    lp_providers_reward: 4.53,
    swapers_reward: 0.7703878270022135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.3,
  },
  {
    address: '0xcd5700088C77d49507fE6d895a3Ced468439BEAf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.267936398723339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.27,
  },
  {
    address: '0x02cE9103696ca0739fa43b154006E0073B6c40e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.266603802697158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.27,
  },
  {
    address: '0x78E239C675786b59531796d144f35a19Aa5a4412',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.245632790153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.25,
  },
  {
    address: '0xB6aFF9F5ad7cFdd26a7ee203Fa5658A76c883256',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.24554276248578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.25,
  },
  {
    address: '0x9dd89C09ab3d42A4688E7793a2E25Feebe3fb784',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.244495481641424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0xa0d183E231879EeBC20cF55e3b02a63a1AEF0F5B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.243130127418944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0xe92B0378E82A735D017add55A850CB45B27738B3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.242177289896284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0x1105490afE48d0Dc743610044487acAcb80FEB1F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.241289606352243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0x434017D499798a2AD9Dc8d46E00BA63CD68AD6aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.239520905953959,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0xe73deAdccE0aEbc29941dBf7d6AbEb643CAA337E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.2263019285616785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.23,
  },
  {
    address: '0x4D2202fF122817Ed5d7E3Cb1a70eB6e415AeF1a1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.2116089909909205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.21,
  },
  {
    address: '0x825Afe309617966Dc7AE73D1a999493F79Fb087d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.197903981154771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.2,
  },
  {
    address: '0x88888b8C9251A39700CE6CC2887802E6E6d7BC5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.190201834283678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.19,
  },
  {
    address: '0x9D58c4FBF830c157443AF1a9d1b39e7451Eca6d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.181073409001675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.18,
  },
  {
    address: '0x707b89C6c7Cbf8D2EEB03C1860e9f6Bc77B5021c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.172456555314292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.17,
  },
  {
    address: '0xC50cd4d3576742312BA2bB552a7c02Ccc1AEa001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.143001798616762,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.14,
  },
  {
    address: '0x22F36FBb2c3EACf8477b656A706f949A5Ab041d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.136554329314787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.14,
  },
  {
    address: '0xCE8E9313800a8DdDE944B5821255153Eb2118ED7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.135790959660514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.14,
  },
  {
    address: '0xa7D71d39C6878ca6113507F3AB13318a9b745aAA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.130136363353765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.13,
  },
  {
    address: '0x1012800B08Be659D02D2e437e96DA032EbacF8B3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.122846308451438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0xcD1742514a78304CE003fbA6530d5da955bCEF5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.1178718956218505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0x37613C1c4a5Ee18d60226efa4309Cb39A998F949',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.1178718956218505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0x0EC0124d4fa2fE2893AEc99f67F3887d3Ab28077',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.1178718956218505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0x99922BDcd5f986D6Bb5fb613f448efc2A8F11BF6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.1178718956218505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0xC125e45885a08Ad5a8F50BfBD6E14F62bc22744F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.095594659311399,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.1,
  },
  {
    address: '0x2772d2B28f64869198811Cd9e12aac195d4901a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.082484870937678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.08,
  },
  {
    address: '0x0ac8E875b9EB4639B106a740fFEd3392D213E4F2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.081943777593085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.08,
  },
  {
    address: '0xEB73F43AF3be3502258E401E02c1f40c916Be065',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.066348286006189,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.07,
  },
  {
    address: '0x76E153F8e30A294A2Ce6e52daA2Ab2f3089141D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.065404223115027,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.07,
  },
  {
    address: '0x7aF3241b8E03B1Eb29b88EDba5650284AF0fbb33',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.0624789377527915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.06,
  },
  {
    address: '0xFb9E27F75a5d89Bd9066f2C0bE6F492246Cc81B5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.033025646246514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.03,
  },
  {
    address: '0xB6F92eC1a181FfABFf51406b425b4FF8399DfE0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.017790616653799,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.02,
  },
  {
    address: '0xDB3c3F901F5Cac8d3ab8500E3B4373619D0E1d7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.015514072370063,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.02,
  },
  {
    address: '0x0e29A16085570d1A1CFCB9A0b056266516FE0900',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.014636888243189,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.01,
  },
  {
    address: '0x672Bc486e1b819BafDe38b4e5e5FF4fC382fa1C6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.014497083984799,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.01,
  },
  {
    address: '0x05c49EbF3d0285Be5ac9Edc1aa85Ec978cf49F67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.9937186096879005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.99,
  },
  {
    address: '0x09c67aAEb8A1Ad31f9fe32F962eB878114AD035E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.970395142316518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.97,
  },
  {
    address: '0xFaE7aE759C3d514397B12e5FF5835AA4552E806B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.969386286095467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.97,
  },
  {
    address: '0xe118E81347bD2d0f4D4a6415a05D6E71eF5f105c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.949443891785733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.95,
  },
  {
    address: '0xF556c08821D3C6414C80F04A88ea5E6bcee798B1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.929765860177044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.93,
  },
  {
    address: '0xbcC6973fCD64b3334FF4f71e26a64B98992CFaEb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.92823600136161,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.93,
  },
  {
    address: '0x9b725e4fBe0D122A45eB66b6813189CfED8e4F48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.924582295993031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.92,
  },
  {
    address: '0x9DdB2bd097876b661Cf0867c9e1893798A4b92E3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.922972262990538,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.92,
  },
  {
    address: '0x52cA1F350d455E3789d1C4e7fD8C859959a87A57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.922747945835526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.92,
  },
  {
    address: '0xc1Bae9F8C544171d05387cfb5f38Fb203aBc7329',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.902784418727958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.9,
  },
  {
    address: '0xbd0F7989ad3555e507882c089370Ed9C06308886',
    minters_reward: 0,
    lp_providers_reward: 4.88,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.88,
  },
  {
    address: '0x494FdBA3F9C78f527180fE310D9b3d28C9263a45',
    minters_reward: 0,
    lp_providers_reward: 3.6,
    swapers_reward: 1.2738008518388315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.87,
  },
  {
    address: '0x00C4CE8f205Caf2032a8F656EeCACFe9CDDa36Db',
    minters_reward: 0,
    lp_providers_reward: 4.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.86,
  },
  {
    address: '0xB88ba2Bc11fDf715a162dCA573e8cF7A5b0159ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.838947099771242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.84,
  },
  {
    address: '0xCd01debEAaE5b9D43879E4d53c7417a2CA0a11C3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.831460636061255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.83,
  },
  {
    address: '0xF97666008ab56e8BE5F5c7E9b1bba14C01BAAf96',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.7937101712376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.79,
  },
  {
    address: '0x49fDEF6995f588954D8e636Ce3A42fC4fc4912E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.78517467164611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.79,
  },
  {
    address: '0xB63623BE8e56EA42B9b31863a15A5a2765490677',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.750679182649894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.75,
  },
  {
    address: '0x25aA3367Ed58E932B661feB0ACCC04FFcFA141AE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.740558578006889,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.74,
  },
  {
    address: '0xaa7Dbf93227e5cf11574986063d038049f87Af8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.732927019791939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.73,
  },
  {
    address: '0x3c684D30F85A8e089A6d2bd3351e35a28262414C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.732744111151602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.73,
  },
  {
    address: '0x54b974456EA2e32B73F07256ad89eA99Fe2BE70A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.7262330955813825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.73,
  },
  {
    address: '0x1BaA29099f7c904583683C793519A7F15775fe7b',
    minters_reward: 0,
    lp_providers_reward: 0.53,
    swapers_reward: 4.204339890938895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.73,
  },
  {
    address: '0x0Ae80Ad3F2501963CbbC06b1071e0e7795745176',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.720921771734841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.72,
  },
  {
    address: '0xfA33B8f27fE963c68d1082a3BFffBf7a3023960a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.7184834402381695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.72,
  },
  {
    address: '0xE90bD9429309a5Bf5c52B6a04CA305D0AbC1C652',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.7184834402381695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.72,
  },
  {
    address: '0x0B43d11Fad0aA0f7d713AA255E1776E14293b15C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.717234310980764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.72,
  },
  {
    address: '0x6aDbB86FB6ed6EF6ea93A85d1ff329c9FDD34c2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.659052624285187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.66,
  },
  {
    address: '0x763EeD44a464a2DD67272580a19C51148e37680b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.652723003243572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.65,
  },
  {
    address: '0x0a009bDE2d90dD1f66c90419Fa1D1945ea006923',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.6393399698076445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.64,
  },
  {
    address: '0x09F0D03cC6d786bc084aD8A35505dA034F63b3d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.628983255128862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.63,
  },
  {
    address: '0x5a24FEF1C55dEd96924C75E7A8f6B140AEe04Ec8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.612733289252446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.61,
  },
  {
    address: '0x0E569fA7AcBf6260c3e6601e44b6Cb20803B49bB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.607316954670693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.61,
  },
  {
    address: '0xb407D12572F08AF66cB3B0bb10F2A403621a7BE2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.59596394685185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.6,
  },
  {
    address: '0x1Eb1ce11F7EE1609Ee70944BE703861766Eef902',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.5777639019057865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.58,
  },
  {
    address: '0xcDb16517861212330a544FCba13e730298831d3D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.573224086843628,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.57,
  },
  {
    address: '0x1Ff5CE8e1D97D6Eb06cfa02B6f31CBE053bc01dB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.573014187491468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.57,
  },
  {
    address: '0x86a1bFf82a5F2676f9A53f29baE6e8C65320546f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.56443516834619,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.56,
  },
  {
    address: '0x100B8a04328D46CC12C367DfF9ecC498AEeB3c3C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.540336478221926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.54,
  },
  {
    address: '0x72052B3Ce7c47C95498Eef83068213581647C3Eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.539310794354018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.54,
  },
  {
    address: '0x43A5F88aEF5d01daF5cB0633c8CCFAB468A958a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.538341865819771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.54,
  },
  {
    address: '0xc38237be6bd3acBCd1F676185f0A101f590D1f86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.510890818413732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.51,
  },
  {
    address: '0x1CdE90D8C28778C1ed4bc031Cf1812e8978735B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.499398032675534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.5,
  },
  {
    address: '0x6ac1E58c8661B7011C7272FDf78c508B7CD7dB57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.491164574729347,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.49,
  },
  {
    address: '0xcfB4CF841e8c98C84A7eBa90e578865733277462',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.487943659495045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.49,
  },
  {
    address: '0xa411efd6Af0029B72BaC26aF7c9952d48C54DDcb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.485363877577723,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.49,
  },
  {
    address: '0x36724C4146Bd4ab560cd6b16fdd73Db178B18Ba7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.473741433824209,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.47,
  },
  {
    address: '0xEF992677c6f07Fa5971C308feC13FF963B5a7D9D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.470802760737663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.47,
  },
  {
    address: '0x5Bf3A59B483171598e49362cd1a25C87F3dcA783',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.463151339084293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.46,
  },
  {
    address: '0xc318aB4F8906c9CBF1475A68CAb6aAd7395AA91d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.4525314968680965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.45,
  },
  {
    address: '0xE11332FFEbC0A1dc9F2a0A2c9aE1568a64c09FCe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.440920064858578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.44,
  },
  {
    address: '0xa545699896D28f45940852dD1b86525a26311355',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.433258691173246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.43,
  },
  {
    address: '0xe78E3A40A88191813C19857D02d700EBa93D0914',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.4313413224364755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.43,
  },
  {
    address: '0xE044AC9664D92ca4C6093F6a21c775920C61519f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.4283651796549375,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.43,
  },
  {
    address: '0xf231F961473D7170a714a6852A8654867059b329',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.423295439244391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.42,
  },
  {
    address: '0x13a4EE327D177d16D0b8d0330A31edA377f1b744',
    minters_reward: 0,
    lp_providers_reward: 2.48,
    swapers_reward: 1.9432836162606724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.42,
  },
  {
    address: '0xF0DA8C924507064494Bbc754a02A39Ca811Bd176',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.406980912887159,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.41,
  },
  {
    address: '0x7dEEC592e2E4fE1279591832353766a405178A3E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.397240089298763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.4,
  },
  {
    address: '0xb61E4a1E52503A55732d06a1A557c27603C6f7FB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.370273865187255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.37,
  },
  {
    address: '0xB25f7b5665c190b586A8cE899E1DD70A99bCD564',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.368861080119738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.37,
  },
  {
    address: '0x2d5Cc38A0CDDcedCbFECbD71522867Fe7846f29D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.334217552148775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.33,
  },
  {
    address: '0x763740B700Ff5B2161DD02316dBE3a078e3b3691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.329338561566468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.33,
  },
  {
    address: '0xBC71CD2bCD54791E15a2Ea58836A6B925BFd15aB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.323120294628608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.32,
  },
  {
    address: '0x3F155C7897fa2014F8c18a146F26c9e000ebeDFf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.3224913096767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.32,
  },
  {
    address: '0x052f1fA632faa5CB1cCDa800409FD8366093B3E6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.319738323936551,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.32,
  },
  {
    address: '0xD0C5844346D244096Cfa7A29C1b3fa7eA1F26f67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.313112288852664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.31,
  },
  {
    address: '0xe0c141B59395273af1C88894B205b8301f343BaF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.301317032927607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.3,
  },
  {
    address: '0xe8C3725CACb8f0D6Cb9A5E56E660d841B877c850',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.299206491642743,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.3,
  },
  {
    address: '0x7a760cfd9BCAdb65ea24D39d401e53a658DB7Bbc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.295912469718563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.3,
  },
  {
    address: '0x021C11F19500815Ba794dCbf6cBE2C861D118De5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.295662397552545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.3,
  },
  {
    address: '0xd8eD1cb91389f052Ff7e3a8F15b61dC712FFf4D7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.294838608869415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.29,
  },
  {
    address: '0xa3923D141e8fb7902B07D4DcE0450bFf0B2BAE7d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.288394828164331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.29,
  },
  {
    address: '0x28eF36E890fA3D4c070Dd22F76B33470F6471D40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.278960528170468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.28,
  },
  {
    address: '0xaFf6eFD928f8099D1BE8e0A003A238315CD0D0B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.2555383585808695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.26,
  },
  {
    address: '0x4DFBe785f20c56220103013bA82A852c67F5E013',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.255072060242903,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.26,
  },
  {
    address: '0xC4414f919EE2F631f905c527211A06dB2d338a7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.251104193351873,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.25,
  },
  {
    address: '0x5aafE9Aa7DdD0e0B4B9951Ff8bf6818E7254f281',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.236138044930237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.24,
  },
  {
    address: '0x69E17350e9Dc9B60387983F0f1fcBAA2644f29BD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.23370730044373,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.23,
  },
  {
    address: '0xf247A6154f1a5aF661104c422cdA0C4d7360310a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.227417606291341,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.23,
  },
  {
    address: '0x03DB410A14CCd2c0046E136fBA23abE7404054aE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.223024278012686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.22,
  },
  {
    address: '0x63e7A71e410914Cc355C93AB9c9DBe43c1a85E38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.213418364447958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.21,
  },
  {
    address: '0xfa6897728Fe3F0a8309725dFDC7Bc1343b4a9A50',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.197195301403279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.2,
  },
  {
    address: '0x0953753af6d4533bb9C5120D355399d52480b316',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.156226088408145,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.16,
  },
  {
    address: '0xe491a169c965B8CEA2EEFd7B082fD01f7dA4777d',
    minters_reward: 0,
    lp_providers_reward: 4.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.15,
  },
  {
    address: '0xF45f487cB2940F3B8DE353890E984dFC805Ed31a',
    minters_reward: 0,
    lp_providers_reward: 3.69,
    swapers_reward: 0.44730421158803335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.14,
  },
  {
    address: '0xE06e1A7B3617505bD30c5961738d9667fC02C351',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.108478864346371,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.11,
  },
  {
    address: '0xc672A3a8955C8c16199662D469c1261f49612712',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.101819685902632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.1,
  },
  {
    address: '0x222c07935eF55c38b55Cf8f45cf94DA434BA96cF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.099214823609355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.1,
  },
  {
    address: '0xD369eE0387c693241711aBD9a36F609310740609',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.060281455967834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.06,
  },
  {
    address: '0x132a6b6Cc8491E28993B0953ACcB5Cf9b82D511D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.047336863962191,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.05,
  },
  {
    address: '0xCFe2cbb3AD47513A6e4Aa6Dc403C8C9CA2C9D49d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.034767860219415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.03,
  },
  {
    address: '0x6B28f5f47c3e98A1eA5F86F88ee780B1dCCf1937',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.021385660932665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.02,
  },
  {
    address: '0xBCBe84a4078Cd311e9310d4F81EF6Ab75E923b5C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.021251619212023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.02,
  },
  {
    address: '0xF7DF1D23Ea5bF1B244255DD4E7db619e23D22045',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.0168362051758635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.02,
  },
  {
    address: '0xd9E86C668BD104df50be91fc3f2bBc4d19B13b7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.014135499704604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.01,
  },
  {
    address: '0x3B8968d29C8d6c23ffBd12890aCd42Fb9a2B72a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.012384361171209,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.01,
  },
  {
    address: '0x444ADEEDdcB0f0f6206D28B3e90AEB5C36f40e47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.008820096368825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.01,
  },
  {
    address: '0x3e799eA01323Ffee03BCD0f2EDFba1B3E116d8Cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.000739085515241,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4,
  },
  {
    address: '0x18363E81880c98a1F357f9b6Ed58488293b6d29B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9940290581882865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.99,
  },
  {
    address: '0xa597BBC5Dee2A6dadD742d0aBEc1b67521ecF184',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9876106621186307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.99,
  },
  {
    address: '0xd26a10bB62F95CC3A8f65d0758Dd0397891F0916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9859145952811676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.99,
  },
  {
    address: '0xbBdb5C05782747b4DDA7BBaEB28c390A4c4DD418',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9764415620468396,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.98,
  },
  {
    address: '0x6331a3C8130C361aa425D1e7245D3657B0470C07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9620907357911,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.96,
  },
  {
    address: '0x93de3829EB378CD7BE07C0dfF9140Bccb00f1273',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9574865820902145,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.96,
  },
  {
    address: '0x785B9256F0F94CedD844D41929562cF1509A9022',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9521050931839046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.95,
  },
  {
    address: '0xfe424315224eca32c4662C8ECdBbC8678aa8C616',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9511618157637654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.95,
  },
  {
    address: '0x40F6718aA1df66184F6AF4e33447a4e51Ad22629',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.946319963494528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.95,
  },
  {
    address: '0xeCad240a148AcfA88Be7bA1E2352217e1EFa2979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9438947534121875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.94,
  },
  {
    address: '0x34C9375bEF607A5b23EE4B3bA20ee1FcfeF7535f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.942651506526905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.94,
  },
  {
    address: '0x19C10f306117d425D29CE25baD7Aa15377342E67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9333716112310677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.93,
  },
  {
    address: '0x98d494cE552a002d4D9B6E004Bccf06166A1528F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.932069533531808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.93,
  },
  {
    address: '0xbaD923AFF7B726700bE27C041e434E6f20DDacC0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9271168496364246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.93,
  },
  {
    address: '0x38E5857a2d802119D79016D4aeb9467Ac29235dF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9204282453553976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0x3b271AD130675FF004550A704fA8eAd593DFecAb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9197264464212584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0xCdE52BddA3df10E0992cFca8e5A1b982fA6bAc1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9197264464212584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0x960dEC349d6Ff942f5aF0a9fdd57b227995cdFB2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9191437681582464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0xf1466d4Fe4BBFe0d2Bb8a010076E9d93d856c56D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9171226850712593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0x6809CDe861143976dBCBb258Adb2d186306fCb0d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.908402998154349,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.91,
  },
  {
    address: '0x714DEe22aF3d7C4Db9bA367321b6Aa4DEcA1a11C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.903634763452884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.9,
  },
  {
    address: '0x7d0D956B95C9d2A4c85D169c520a43cfA63F626F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9034136325298436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.9,
  },
  {
    address: '0x972d6782EA6bF2e75be9f616192F74A684469117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.894929476862665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0xfAC7e487496A0ED09980d649c0dD6D96761c2029',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.894767324258288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0xbeD87290e6d4ec30032076A9A6F8093ba891Ded2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.893321265589073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0x7299e9b335F0ac06F0AbC48E95250d32fD44cD6D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.885285800450077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0x2832B734F4d3b689033e22f87b06c60A49543Bf5',
    minters_reward: 0,
    lp_providers_reward: 3.89,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0x3174a062d6bC22316c8B0BF83f2C1805c90f01AA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.8760021600842136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.88,
  },
  {
    address: '0x88FadfFAa45E04873Ec6792789ff90656E1D4BE6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.8735073491953322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.87,
  },
  {
    address: '0xddd5B3A5B6836Eb9A579F7E6D06A7a0d92c52519',
    minters_reward: 0,
    lp_providers_reward: 3.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.86,
  },
  {
    address: '0x002EE2A9A46BE2b100b9e937809C2Fdf404096f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.8311764382777778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.83,
  },
  {
    address: '0x89d1835EdfeA6Eb211FC35A5fd94b4dB80F42a45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.8175584761641437,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.82,
  },
  {
    address: '0xb8ed58524b18c9C64cc36227D8a0eB0Ee76dE0c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.799900017349261,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.8,
  },
  {
    address: '0xFd7a4A856dAF7bC990997572BD85B54eF6dE2D63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.798554043902981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.8,
  },
  {
    address: '0x0260cd47c468716b6fcA7F4df80765DDFCdCcf4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7860541462240835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.79,
  },
  {
    address: '0x4E8197F9a6689369968F36E49ccc51116612e14a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7844145725859923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.78,
  },
  {
    address: '0x3a604b930Dec1393Dafc26bD74823Bcfe9C5a084',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.78299775724978,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.78,
  },
  {
    address: '0xd75812f9c1244e9F5c5c5232C53FDff617BD575F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.782873973172788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.78,
  },
  {
    address: '0x458FBAaC816d09005d14D1Db91052DF0Bb2a431a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.769971713443429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.77,
  },
  {
    address: '0x95A2e6fc69bb6AC241829A0c873bCDDb0D1e944B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.760614430448369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.76,
  },
  {
    address: '0xC99634B2C0A2fA59bB2e6476645864798c2F4584',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7601683131338497,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.76,
  },
  {
    address: '0xAAEA6b46c1F935567437D5580B9D9dAC5296280D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7493755539160802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.75,
  },
  {
    address: '0x114e88F07585E1e734d1eb96F6D609B0e1Ace6f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7240361491961154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.72,
  },
  {
    address: '0x05916DD858f64142512b73DddbC35f503634815C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7226724742786033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.72,
  },
  {
    address: '0x3Ee3A5d52CADCD456F94e73AF5227A026D418801',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7123523455783283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.71,
  },
  {
    address: '0xBe7ca65E0D7109f59CC93839390648C4f03D0335',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.702076837299298,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.7,
  },
  {
    address: '0xfe987cD1B93C3a4b7EcC2b92fd853872Aa51e7aE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7008567262196244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.7,
  },
  {
    address: '0x15ccaaDd0aba9eeCf8F92114fEE63b6BFc2D4E99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.6665769329591456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.67,
  },
  {
    address: '0x9543C1142e7f1Dc258078bf9aAF77E32443ac65E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.66617013964604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.67,
  },
  {
    address: '0x284eb99f81e89da47AD655f0d5ec25a4BC1ab9D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.65413093613169,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.65,
  },
  {
    address: '0x47D38a59fbCCA4b3Ea6db593F11bc50e9a422a80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.6482189201782185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.65,
  },
  {
    address: '0x8FbaFabB66edcc693ECe1B1DD394988518824B95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.635563970901754,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.64,
  },
  {
    address: '0x4917892bac7e3CB2b4203d7b7DCd60668Bcd249E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.6349867898140746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.63,
  },
  {
    address: '0xf4aD49D93F45A83Efa45E2c9f5faEE717c2D124b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.6058302426568143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.61,
  },
  {
    address: '0x4d82a5e0954426D5CE01ceDceE07fF919609b8dC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.60316390730585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.6,
  },
  {
    address: '0x699b837F99A90E0a55e2ffE4CE7c42fbb6f79e73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.5900188717843973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.59,
  },
  {
    address: '0xD6160dB972E6520ad30D265cAD0409Cf2527FBe2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.5815773817468104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.58,
  },
  {
    address: '0xB175BdE622afff3360854976517AC11802037827',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.5776906400144424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.58,
  },
  {
    address: '0xd2A672bCE1455906C47daBdf30a9078FFAcD806a',
    minters_reward: 0,
    lp_providers_reward: 3.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.58,
  },
  {
    address: '0xA89a82fdB0D0999804DC810222f724E77F3563c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.569839421408891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.57,
  },
  {
    address: '0x83c1C9360Bd9fEb0f11913eAFfFd76A1fEAF38Ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.56689853125703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.57,
  },
  {
    address: '0x5898AfC7d40579e2b1952Db28E52Ce41e62ec0Fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.562004113997116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.56,
  },
  {
    address: '0xDF2A04785284248378de21dd0415Db5BE135a691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.558516082903485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.56,
  },
  {
    address: '0x308a21C7CAAc77fbCA77769064b26e3c3f60F8d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.557851532545518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.56,
  },
  {
    address: '0x5184AF6F86D4A3ff56FA2B83c61CD0D8f4DbA5b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.556200776548588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.56,
  },
  {
    address: '0xbd2700B7504a7469F5Ca98AddC2F0AeA3bf879B6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.551687967145075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.55,
  },
  {
    address: '0x7Ee5a3b784aF19136d4777A12D3B2899a86618Ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.550852603587185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.55,
  },
  {
    address: '0x7A8C81497A7ac1B181f3442ffc21930f7d7e1210',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.514088796671651,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.51,
  },
  {
    address: '0x0F753bcC6a37522038e90724c837fcBd41674EA9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.5138399126835234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.51,
  },
  {
    address: '0x14Ea8818307F6e1120BE9B34Ec8B7838b5c71897',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.495925622102121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.5,
  },
  {
    address: '0xb653eE9A4D041c7Bc56C55c6c11d6a7C0D34ea1C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.492790451927366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.49,
  },
  {
    address: '0x5FC40a675bF33b98AE18a73EBf755E7C0c390002',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.488589612885288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.49,
  },
  {
    address: '0x57B2625eB4C3d6c149C129FC71A391f44B6A99A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4844212718224514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.48,
  },
  {
    address: '0x3B307b27eDc14e1c9E29E52D344c7ac6F8B5ab7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4834428009572918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.48,
  },
  {
    address: '0x9C5DcA2389f3a7EC4A2Cc801b39a263B2A60bfe9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.479846585833957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.48,
  },
  {
    address: '0x85cfeaa4492cF83726237018e082872Ad1A4B7cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.469884748572149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.47,
  },
  {
    address: '0xF28F707811558ea7Cfa9DA5b577de1406a2eD924',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4651711498500677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.47,
  },
  {
    address: '0x825B393A1e707F7629eAE210F5f6cB20A0BdBA60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.453244723490936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.45,
  },
  {
    address: '0x768480082b697A7f2abB2136103e3E2c55f85d64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4484874703035118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.45,
  },
  {
    address: '0x56fef6ea47c63DCBe8E8680c8Fb4f938BEF43121',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4453547905038993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.45,
  },
  {
    address: '0xDD0d73E26c4cF203dC8B8330560C0457a965b0e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4326433729334695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.43,
  },
  {
    address: '0x6fd1c15AC89779d97071d9d17f3D54BE832df49e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4228947638590417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.42,
  },
  {
    address: '0xB46c6645bfedb75B8bEdf323574CACFa3cA5D521',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.417136080965921,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.42,
  },
  {
    address: '0x738bD00e41D443c2FA9eBeB387bB0F80EAb28e7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4098194585131836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.41,
  },
  {
    address: '0x4F54837d343a0A5356Ac906653c50BbDad064555',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.405200420651872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.41,
  },
  {
    address: '0x2648FFBE9CcbbeA33a07105691bbd7a18EEfd588',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4026264725303466,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.4,
  },
  {
    address: '0x3225b62e986152142eFfDfCF659775a69Fa395B8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.394405068854035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.39,
  },
  {
    address: '0xD435ff1FDbe6bbFEC31a3aAABe75fc61eCd652F9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.394405068854035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.39,
  },
  {
    address: '0xc7E301B50C47a009Dc74891cD6bfb05996040236',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.383121827229927,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.38,
  },
  {
    address: '0x9bE483697Bfe9aBaF88bce992c0Faa61379c354b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.34765746529813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.35,
  },
  {
    address: '0x46BDe2F8ebC9D3f1E946eF82C1c79C7bc487778A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.3467621959969067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.35,
  },
  {
    address: '0x86225FD87f69d2F6Ec0f5D9F5035b38C8D31CbEc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.336570559752868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.34,
  },
  {
    address: '0x08b30Ade63c9C1eC3F64A5A84D040eb6823E6870',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.335637571907904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.34,
  },
  {
    address: '0x6e9b385Cb0C12bd6ee5152a332DCE30E332648b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.327974953569856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.33,
  },
  {
    address: '0xC22184F746ac3DC2E3f004DE4bee25fa44AdE6c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.3150312023008928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.32,
  },
  {
    address: '0x0FBed925F9F8a54d6915065377e3d94170Bb4041',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.3127086062147164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.31,
  },
  {
    address: '0xC1Bd40227f65B77D55733Fe47986DD7d2483cde8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.3060347229133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.31,
  },
  {
    address: '0xa7BfbC468583cFa614cc175b71e97cd08d01f812',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.29411692283887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.29,
  },
  {
    address: '0x4d7C5142a6061a5F24Ccb66ddb9edfEE665132AC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.285703793435474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.29,
  },
  {
    address: '0xbe73Ad57cD76e67416E0Cf60099f425ea865eA4E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.276414958217968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.28,
  },
  {
    address: '0x8623A3F0abD896A09c573E42bB214F427f4C7ec3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2746261358455646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x622c335182543fd998A1111c462DB9FC757646a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.274151796920932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x551Eb8fec882Fc308B093Bd4f65FED34d4A998fB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.274151796920932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x42247ab26d7B0432D3486Fc3e767dDf62451F157',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.266370770990404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x5E0c604Ed5678e274a52574672C5AEA9d718ff52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.266259331206857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x72D3D0528e5Ca8fCc27DaeB1FF1ae4ecBe1B85CA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.249589805346555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.25,
  },
  {
    address: '0x976dBFA52197C57af7B8D2166aD9717B96764577',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2493160042184037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.25,
  },
  {
    address: '0x8fa4e9816406dcbcD90d39Ce918B93BEde564CB2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2450966153764997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.25,
  },
  {
    address: '0x6615BD92F0c3185865c3639146D16FA70D45eDF8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2398037429524136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.24,
  },
  {
    address: '0x99D68B6e98fC220092Eb18CdBc345EF03bF4f72B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2303512241135914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.23,
  },
  {
    address: '0x1D43f473DE16a4aB9B379eBCf0A21621795E669A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.229920365123445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.23,
  },
  {
    address: '0x9b793088Ad0c30EB18d7D33179090843230f24a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2259002956655785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.23,
  },
  {
    address: '0x6AbE2A3142bcd6cF6339171ab0Cde41461b14A6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.224104893844184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.22,
  },
  {
    address: '0xd7dBBBE8067BbcAeB59B6CF0767496396D7028Ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.223606260716278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.22,
  },
  {
    address: '0x91b270FE8452181f757b511a63d72122A6892a95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1997120674117836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.2,
  },
  {
    address: '0xA7ED819E2C628CF21CB8c88aE21D32C959470220',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1956555032366465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.2,
  },
  {
    address: '0x8612ab6625437bCC09656eAeea0DefC74EA88C2E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1838144253327885,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.18,
  },
  {
    address: '0x4f41702044934CD5cCa6f6553fa1F4901de1c2c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1798777404166807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.18,
  },
  {
    address: '0x4FDeAD2b66F179426755Fa96D0c48Ddfe4392768',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1771121830937004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.18,
  },
  {
    address: '0xE8B9a78AB1D60e9855f174F8449c817A1F5501E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.176355737016134,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.18,
  },
  {
    address: '0xb3B29d07dedfa59a25a9584C812BCaf99F6d6C2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.163033299423051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.16,
  },
  {
    address: '0x3e4Bbc4A8B8B003396f8160De193bAFEFE7caA5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1617683539072208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.16,
  },
  {
    address: '0x66D0918161a8A6285D08fA20a24327255B70534d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1617219826836096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.16,
  },
  {
    address: '0xd9213Cd1d8eA4cAb34Aa001c0D04bf2f576055E2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.161144011899199,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.16,
  },
  {
    address: '0x298608c5fa4D1a7D53Ee91a8BcE1Bb1684feAcEe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1498478623993402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.15,
  },
  {
    address: '0x3D449f0E0308B0C578b73817526d33c3D7ed08eE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.145085868865778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.15,
  },
  {
    address: '0xb1D5590Dbe10a722AB3f317E378E8a7a07Ac13B6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1438943773654273,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.14,
  },
  {
    address: '0x19d260386Ee278F61559efCf72c7C8b0585Aa5C2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.140859645415899,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.14,
  },
  {
    address: '0x7504f5b626b039e7f627bDf9834583AcCe1303f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.132316039069663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.13,
  },
  {
    address: '0x36a6d3b5c6e04264335986bE8604F29B80522Dd7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0901383766447905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.09,
  },
  {
    address: '0x0a0Cb6b1039d80e06Ca7e774C90DEF3fca823d81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0832022135962434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.08,
  },
  {
    address: '0x24f8deba73cA7640E53fAEB15b8B99b51a89A5f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0828141303648033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.08,
  },
  {
    address: '0xDC6B668F4C2b1662CdF65D0a82cC1b88C287D769',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0813737850169494,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.08,
  },
  {
    address: '0xA83A10f0585305DAAb77bc3a1d74De82385FD0F2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.063531870897553,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.06,
  },
  {
    address: '0x82C16c75C55AcE87360aAE4Fc3aF6C3662A713BB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0570383471301623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.06,
  },
  {
    address: '0x52e8AFe3e29B8D2Cf7eBbc506FCC28FeFc8116f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0562819869583464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.06,
  },
  {
    address: '0xC9895c0dcfeFF7084ef51f4A59CfD98811F4F4DC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.055656072119021,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.06,
  },
  {
    address: '0x29CE0c5d918C91bbaf7C75F120847244630e0026',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0516701347503314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0xA69A6FA9B13B7394fF6E85924Db25DCBaf59f82e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0514635659101073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0x8ae2f5838a4aFD7e477D35C7EE7723B833dB6D13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0495972691625735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0x91185d8d117D7722C2398f149A313D80D504437a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0488191085730056,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0x993A047358C1a98F980E106d519990B7D645EA2f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0483463712031167,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0x1d5168E36B6512512412125C027ad8eBE456E3E2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.037663208400634,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.04,
  },
  {
    address: '0x1CDbcD9E8a37095163E76848271f96eF53c6A0e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0247152629523675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.02,
  },
  {
    address: '0x0eb9b3EF33C478568eF709B1699d18A3a2ed694C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0178310761061744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.02,
  },
  {
    address: '0x4a1e51910e3778bFa19E40eF8609C625C284295A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.000854843764813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3,
  },
  {
    address: '0x61F5c255f5aC0F14AAC0f336B2d4969f79D08f83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.990970261381243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.99,
  },
  {
    address: '0xdA898f4AeAc7e5F7ebad6B5383d7747845E659eD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.983037392195325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.98,
  },
  {
    address: '0x4815E13640C2046b12900De69E5eBBDFa7C96785',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9824942892161395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.98,
  },
  {
    address: '0x14b5e4A4E29329eB288fDC6cE3BBAbe783D560a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.975434226056195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.98,
  },
  {
    address: '0x7cF2ec0ffD3d2bbfC6576fdfA9a167Af92De262D',
    minters_reward: 0,
    lp_providers_reward: 1.7,
    swapers_reward: 1.2719785248729307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.97,
  },
  {
    address: '0x3c5C43EF18028e8fe2Fc7381412DC58358Af9e70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9617159385305354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0x2385173cE86b8891ae5B297c881fdAdf7605A11f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9617159385305354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0x42A6Fddd32Ff6324B17d4C37314e4af9E6C73169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9607266090208926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0x8d84F55F0EbE3de2F71A5fC1888df5b3fE499F88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.960613376572386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0x29BAD0b8ea427dDe4a20ee4958FAAc1831986563',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.959777779798331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0xCa0fDe5Ea8C323d1A70c0bDDE0f59C448e0F51F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9520820341092513,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.95,
  },
  {
    address: '0xC312296d4bf183c216a47fa8313ba14c99Dd2e8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9436839317104715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.94,
  },
  {
    address: '0xd22c97fb093076b49120Ea9034Aa66040BA6cA7b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9423036241116853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.94,
  },
  {
    address: '0x3741c185B1c078F162C08A9Df775B70e8d207648',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9332615463673157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.93,
  },
  {
    address: '0xF95ed17C6D8D388f544f50e80E58585a60f48D9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.927279683929992,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.93,
  },
  {
    address: '0x9EE1838B080561C48F45895AeCACFeb6d77b77A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.926549218453957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.93,
  },
  {
    address: '0x236af103DFf6041BF4bE9eA8491A25605De7De81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9259372866498037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.93,
  },
  {
    address: '0x4E89Bb346a587faFB9969887c027ADb47B0d6a1c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9209666267744354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.92,
  },
  {
    address: '0xb0875718F94fBC4fF19c0EC95C661D67DcFA79E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.920242685722253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.92,
  },
  {
    address: '0x4301dA81e3473e72C38dB999Af6F2eC3d6e7D4bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9198634835248556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.92,
  },
  {
    address: '0xB7fBD026d11e55C772269A3495030a81817986D9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9034981679220833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.9,
  },
  {
    address: '0xd4d40e2AEb9Fde55b948B7ec3CaEd56FdDF8C8e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8968138465865714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.9,
  },
  {
    address: '0xC705ceAD42fe25596b139580Ba230383a8bf5599',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8865894906751484,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.89,
  },
  {
    address: '0x077e2B7998450d6287ED8DB01A32d59f33e24C7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.886063599661325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.89,
  },
  {
    address: '0xf10c21a114ED86f50b802793c24159942Eb90fCC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8857824982825164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.89,
  },
  {
    address: '0xb48299Cb4A6BE041A2eC90d2B7983FAe39a82E34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8844725149027832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.88,
  },
  {
    address: '0x269F7A78fBA5D6207cC5237AbFDE711A9a0fcF00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.881659819661427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.88,
  },
  {
    address: '0x642127774843ccFD57D062580A7C4B5928BE6B73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.88038552351131,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.88,
  },
  {
    address: '0x3C0D41236559DcC06434023E68F9dD2ff867657E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8785793796309185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.88,
  },
  {
    address: '0x65a83f46DFCC51Fce2E9f6457A986F56fc49ADe0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8749576727957726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.87,
  },
  {
    address: '0xD98F7Ba408534ca085D60306481F0C40e34c56c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.872966508916526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.87,
  },
  {
    address: '0xBcd6Adb83b8c8ef93E517eE58371F94d61386170',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8682496474333767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.87,
  },
  {
    address: '0x769f7AE74FBD4E1f41A2df21Bc0E8ab474C691E4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8632214457585725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.86,
  },
  {
    address: '0xD6fAA3d09fa2e44eF926B1611563C90D473Cb909',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.860542893577113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.86,
  },
  {
    address: '0x96753e89b0DAa0d5099A195F247C44bEA7f8C624',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8575825485983253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.86,
  },
  {
    address: '0x9F0b281cb07F55BD68B91a111dD1595C7d9F5eb7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8517559830859875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.85,
  },
  {
    address: '0x92751C25D41E47D98f94C36d0C74585Fe0df162A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8510108702346058,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.85,
  },
  {
    address: '0x907B58e388a1a580F988609EEbC43f88097fA62c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8483345510661144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.85,
  },
  {
    address: '0x9f31426c920e76AE032aFfcf16ee76a7D24cE9d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8410758402550678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.84,
  },
  {
    address: '0x1D715427e9D4B98e7ad8Cd99c64De774F6065e1D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.838063151832664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.84,
  },
  {
    address: '0xfC3630059d42312da37c72329216696e11319A4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8353516106720695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.84,
  },
  {
    address: '0x4dC04aA19DA23F3604e0744141ba8890b858Db1F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.832342665136693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.83,
  },
  {
    address: '0xbE59aB6A2507455d72343fa3384F6a908edb93A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.814445309277583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.81,
  },
  {
    address: '0x91Dc408536D047ACa23b1543074ce06f7c4aC3EA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8125827077437666,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.81,
  },
  {
    address: '0xf4f485D838336a5704ECD7cD6f41d669225A3F66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8008986056648864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.8,
  },
  {
    address: '0x6B19849Be941CF24b088C646ecaD548B34b88DbB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.792867864438875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.79,
  },
  {
    address: '0x8F20ff87F02566F6B8dd6fb3651b0AeD44Ca5c25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.789821948299278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.79,
  },
  {
    address: '0x67CD2F35C38bceA43151dDfDA48FbF4e34E53552',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.78863216041709,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.79,
  },
  {
    address: '0xdbac16E6483b32AdA854208d304a927F9B933f16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7877168595401223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.79,
  },
  {
    address: '0x8b4aB7047Eb1E1f8e1199E5FEb50d038082Cb08D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7790563168872016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.78,
  },
  {
    address: '0x9E71Db4E0981bDf9664BDe35300b305145620404',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7748899171831134,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.77,
  },
  {
    address: '0x9D9A17716b179E68a15C0FE652c40B944b665B20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.75489247012434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.75,
  },
  {
    address: '0xed6Da853c259727d6a36da4F6a4072dA0dEdaa5E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.753939735032643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.75,
  },
  {
    address: '0xD21b907Ad7EaF3980f11F451F3ACc1436343c8C9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7517171023566345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.75,
  },
  {
    address: '0xb1C467734a203D324866bd7300A167f6cdFd3957',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7469066722242776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.75,
  },
  {
    address: '0xA9A52349499eF7da2fa6FA78F1C047fEdbE1EbfD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7416108234062246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.74,
  },
  {
    address: '0x1a936ceddd227a9d4581CB58875af9985C4a7a71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7225389705837353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.72,
  },
  {
    address: '0x7B6C0C922d049901DE868a9940bC8e866c31Da7F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7088906160039365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.71,
  },
  {
    address: '0x2F8819417f4e2093Fe0FEFaf0CF0f4A680c7CB1E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7044722230066487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.7,
  },
  {
    address: '0x50c97d8574Ea56cFC8fE61d088c42DfEF5bFA8C4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.702578372137741,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.7,
  },
  {
    address: '0x59ea8714545111ED47de386f0cAa3D7C49AE22dE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6891428034132674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.69,
  },
  {
    address: '0x1C75e733Cc9b90Bd633cBe9Acaf5c1ebeed9CCD6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.681449699504273,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.68,
  },
  {
    address: '0x63F8dC5cEC58a54E75331E8b74001Dd1412998c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6791373622378925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.68,
  },
  {
    address: '0xf389514e7eaD555C3B09B856d5f236D57cAA63aE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.662838613370038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.66,
  },
  {
    address: '0x3AB4770695c1a0434972b3FA436119de700978b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6611172258292797,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.66,
  },
  {
    address: '0xa8f5aAFf78e89707eaF6Acc04Fa81b231194bE29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6591276397273167,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.66,
  },
  {
    address: '0x692B6Db06Ee021D2c3a9B4cFF961ddD2f6ff05A6',
    minters_reward: 0,
    lp_providers_reward: 2.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.66,
  },
  {
    address: '0x3019c419A72B76B41a7Fbc0E70dC4921bA467Fd1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6489287481128385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.65,
  },
  {
    address: '0x2d4c9292c73258550a91bFE98E1cFb1f33FCf8Da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6479797139372314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.65,
  },
  {
    address: '0xc4bDC5e1b5C1108D62e5C91a362b124760aA0D14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.634766584954105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.63,
  },
  {
    address: '0x10dA42923087e8f7708D0A22cD93026014b66ab3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6339681993616697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.63,
  },
  {
    address: '0x8fC054B59C914E1FFfb93448251ac08A8aB5446e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6283022320484304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.63,
  },
  {
    address: '0x2cC642c0Bf3e5C98c0C36C3415ca7407ac064a01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.622247740820712,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.62,
  },
  {
    address: '0x14cE05c813838E9F45E5F690047fED071De8456a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6131509642808393,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.61,
  },
  {
    address: '0xb403B32AD0BBf8A13CBFdbd4642cbe7dF99E43a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6130966167923235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.61,
  },
  {
    address: '0xbD77b8B39793b355ce60F1fbD9c851aAB584865e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6077420831499585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.61,
  },
  {
    address: '0xF8Ac14515f7e034Ce164FD73b9E4d5aFdD4c43c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5928477220327153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.59,
  },
  {
    address: '0x9CD89A1ee0Da084eB2B589091DA15851F1f967d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5866931508987134,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.59,
  },
  {
    address: '0xE00C227C8dBB8296395d69884AA135B12e5DFD3F',
    minters_reward: 0,
    lp_providers_reward: 1.98,
    swapers_reward: 0.5922329315102921,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.57,
  },
  {
    address: '0x097c99e94Efe24C79Ba434cE15CEcCc788190D38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.557656945429295,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.56,
  },
  {
    address: '0x364434f181801fBb274b1F2EBB0DDe502BEA05EE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.556435898393259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.56,
  },
  {
    address: '0xb41EcB730C204fF8111ce2196BF0f115ad4B7117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.554714034271171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.55,
  },
  {
    address: '0x0119560EC5Ce5990FcB4b935a32c6C1E8679B86B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.549672477420678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.55,
  },
  {
    address: '0x2bE479bA00302e9F64238A4CF17eF10c27f262A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5453968142801866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.55,
  },
  {
    address: '0x07213668F160025c9E35d767Ad1c1B9383a7185A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.545218413060479,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.55,
  },
  {
    address: '0x7632605065be1C33B3D7a24612b8f52Be6639C4E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.532021909926384,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.53,
  },
  {
    address: '0x203b3Db3Cb050781D45eEF6A43Bf6cBc97f96A29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5314324131573818,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.53,
  },
  {
    address: '0x58a565dC9922E00e0659Eaaa9f217eb1984Def1e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5278795223666704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.53,
  },
  {
    address: '0x1eFA143b0ABb0C809C555B0514d7d28c4E58787F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.513492256846706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.51,
  },
  {
    address: '0xEC9760A26E315F5c58b76eb792FbA9Fd0bfeFb40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.506839146151423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.51,
  },
  {
    address: '0x7c46160B618F6c2504e668513375cBFe2cfFAFD4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5042461707710775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.5,
  },
  {
    address: '0xb8cB4c688DCAA2d687D5987279FC0a2EdeA8F3b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.4742782401080636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.47,
  },
  {
    address: '0x508b4D5b0dF2cCCA912B33301ACE05bD3Db4b7c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.4554098363536627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.46,
  },
  {
    address: '0x40e88Ee920D6B3E2E1003D56391F4A2bF7228767',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.4283221427225095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.43,
  },
  {
    address: '0x6C9a32B57F066344798821D022136311651feD42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.4153857368505602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.42,
  },
  {
    address: '0xd2dFD914f07B307929083625E622867BfE5495ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.397511175060077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.4,
  },
  {
    address: '0x8195cAD2A90cb7976452c558be44D3a8eEA1720c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.393714206933487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.39,
  },
  {
    address: '0x3C64aaFF398F50bdE86CA2A6753CB902a118abA8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3872028914931533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.39,
  },
  {
    address: '0x939608b5AA2A73e98c942AD69361fcb21dA06f15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3827475398032254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.38,
  },
  {
    address: '0xac887BDEC6f90AA284ddeF6285688DcB2a50df83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3748729424804385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.37,
  },
  {
    address: '0x526a4Ad7d41cc1535d6fbd95F3fB74aF19ba6A32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3699780616580295,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.37,
  },
  {
    address: '0xb63Fb45DdA7A941488bae5a9BAC47A90091322EC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3690715532263473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.37,
  },
  {
    address: '0xEbfD774c1C2008E56cE40E0a4504Ebecc81b1921',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3633809096889604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.36,
  },
  {
    address: '0x493bB91251582A3ae9A1e6E207FE6fb4f6AD250b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.354789127153574,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.35,
  },
  {
    address: '0xB9c9828CA19044B48197539Aa882b06Cf57418Df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.354712515686095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.35,
  },
  {
    address: '0x377FFD9A9b38F393084fC0FD0BbF91c0b8d8432E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.337790075175711,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.34,
  },
  {
    address: '0x21688a487dE5A26Ca6AfF7e77b9acB6fc82d22e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.319741694686331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.32,
  },
  {
    address: '0xce679f438dd332C4BA455906fcACFBE28Bf63f2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.319741694686331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.32,
  },
  {
    address: '0x6468FBF477bd566DaB1128330458264962a9aA5B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.319741694686331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.32,
  },
  {
    address: '0xff526693bb4De4F32184E5BA6838B34ED03c8FaA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3063110490602505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.31,
  },
  {
    address: '0x81c36D2EE2267f1dcAfF97323f9353635fC9CB2C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.300350724916226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.3,
  },
  {
    address: '0xBF766B5e46C210d78a25bA9eDB0283d5b982E470',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2955496187444813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.3,
  },
  {
    address: '0xD989424603941A9841Bd5C51BF024165962bcC2F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.286641698142834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.29,
  },
  {
    address: '0x3F460d26DC5024A59C1268f0D2a8812e430fE0F5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2774502930582305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.28,
  },
  {
    address: '0xAD2CE56EfC80757a01eA34Ceb44461759B24A415',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2765921418896933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.28,
  },
  {
    address: '0xABe2CE38eB66d7DB334FC59e9ED2a642eD3e8556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2693343441960994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.27,
  },
  {
    address: '0xbC31757e8464820D6f79218796E9A47207f7e1B5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.265364738256375,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.27,
  },
  {
    address: '0xF9E27b0F6EdebEa7b18Ef98FC81068f145b48095',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2542212175646066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.25,
  },
  {
    address: '0x8E7ECf6c6E132404812eA38fe5C46b207E2e4Fb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.250324573301087,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.25,
  },
  {
    address: '0xE1b85b2A34DDb3Ddc859eb68E66135B17789c34D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.24672491799412,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.25,
  },
  {
    address: '0x7515e0829Be41f4917eDFD7674326cb193eA9f0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2461979852124836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.25,
  },
  {
    address: '0xCe015Eb1f121C174786C1b9ea7CDFF3f2aB30A81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2358621261558658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.24,
  },
  {
    address: '0xEa46515d81E7A5F70174D0c7013575f9dF27f6c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.235744216948052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.24,
  },
  {
    address: '0x47c3ec861ceDB867de645C77754E74e89a56B044',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2227388542281608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.22,
  },
  {
    address: '0xB20FA1C62208F9E2a2b4d3Af95428d263c693ADf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.220452021321862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.22,
  },
  {
    address: '0x5d74529c632d27464129a3818f32491AD722a1a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.210696512228285,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.21,
  },
  {
    address: '0x28AF3fE7E324A38ad6d9A2477Ff9e91f6f5fb1fD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2032365550046897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.2,
  },
  {
    address: '0xBA6FAE5236C480f5737a855cFa1385Cd1ab0c91a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2018213272815848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.2,
  },
  {
    address: '0x1aeC6F42697aa824f6DEB1d83e2A649D5Df216dA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1999461597754872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.2,
  },
  {
    address: '0x63d77075F98CB0d3b3f578B9f1c199e6fea8f0f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1922099102376644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.19,
  },
  {
    address: '0x75d6C8bcF117D47036679221CCaf9BB585901822',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.173055396408225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.17,
  },
  {
    address: '0xB44e554D61Fd2650e4a3c2721D69F4400913801B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.166958154375146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.17,
  },
  {
    address: '0x0B98558F9EA02c7338f0B119fdF68a9E6b82C90c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1619024807129206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.16,
  },
  {
    address: '0x902b858163721785cDb414741C7Bd2cD0A43f7c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.156664098140475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.16,
  },
  {
    address: '0x484BbB89CA521004405da5e5c2d8C90D5f04330E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1445378177253636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.14,
  },
  {
    address: '0x4934B15C0909B2Be8b1A8a568B98a9bD70f82A28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.135109649742091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.14,
  },
  {
    address: '0x2a332078B0A6cE3992ed358b6f6ccd110Bf70Dc2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.134170850490092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.13,
  },
  {
    address: '0xCde299174Fc6DcEeA996f3988a12945a023c245C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.129736979755201,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.13,
  },
  {
    address: '0x8BaC05BB9CdE8a0f1fF5651e2eA3655fb854E12B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1289974929553237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.13,
  },
  {
    address: '0x81D48a91D4417bc87145440Ac0439fb2cf1d802e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1238778660749595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.12,
  },
  {
    address: '0xB4cD09D98b35C6c7de953C10Ba44Ea890494ad73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.121619969472571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.12,
  },
  {
    address: '0xce645ea0D31F5d1acd0bFBb24d3847040D793E3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.114555466372238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.11,
  },
  {
    address: '0xd759fEaad7A7863f46503A2EAB403e5127887e95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1108644818588678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.11,
  },
  {
    address: '0xA8413d78a8aDdBB53196C5c487E66CD8CB2b8F17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1091793084627297,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.11,
  },
  {
    address: '0x334C047F1a0867fEc79e3B83feC34942e65B3b13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0986518667799374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.1,
  },
  {
    address: '0x4f60DC8da574DFb8760b796C343FC8fd004990d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0984982799220626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.1,
  },
  {
    address: '0x9CAD199bDAb5d6e43e187847966Eb78523571916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0937007016559916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.09,
  },
  {
    address: '0x279A4f49b512295bEDa028901fD91c68c2FA39F3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.090746090272416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.09,
  },
  {
    address: '0x6fb11B707B9Dbf053AbC8A04E5242177b3ffc4c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.082803958239337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.08,
  },
  {
    address: '0xdBFC63Eb2032A1A991fEB791640954F2b6Ece803',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0799020730666564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.08,
  },
  {
    address: '0xD9B417Bd6af03968E617213b6AC56453f564798F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0721552856598744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.07,
  },
  {
    address: '0x747F14C98841e67B1F7065be5CeB445B2385328b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.064967318802616,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.06,
  },
  {
    address: '0x39940850B5b95cfa3F7966a6383A7b283EA3c3E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.064161169714172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.06,
  },
  {
    address: '0xbd4CdF5672dF0D8f3535E30c9fBDdf0C8De99f54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0601045207539115,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.06,
  },
  {
    address: '0xce5428d8c2800355f85A821A756263BeD212448E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.053921617031775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.05,
  },
  {
    address: '0x13E512527dFF3691f0E16C71C587a2F08D5E1cF0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.048278163550344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.05,
  },
  {
    address: '0x75e94CBfe933A0638D48c827c6ED4e3647323E77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.042919020612019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.04,
  },
  {
    address: '0x5De91F4509e6d16DC0EC057f5319a91604e5De1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0419042279378012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.04,
  },
  {
    address: '0x0E999cd7295714e509629D2693D9eae76347B468',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0238169750349555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.02,
  },
  {
    address: '0x69B6dF29A47B31b1e9F0353Bea252FcdCb0aB96B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.00711624666152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.01,
  },
  {
    address: '0xB487f4E9Af6096dDC39E0305F3Ab8166eD230F3D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9982926886300538,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2,
  },
  {
    address: '0xB535E4F14457b7410D0b3E2debD82dE16598Ef60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9970145290941432,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2,
  },
  {
    address: '0x3010d7222a37143c33C4AB0e19961B904DacA4ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9890295518445684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.99,
  },
  {
    address: '0x8dd549f4d0832612a31a649b85bC7BA3F6fd8692',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9872828426119646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.99,
  },
  {
    address: '0x5aF526c4F1196e13120ad3A4ec004001CD3b7C09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9850742138304243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.99,
  },
  {
    address: '0x1514bf24deD6D9e5Ea5Fc7c919914427A7B08b64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.973159981747264,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.97,
  },
  {
    address: '0x1974659067171F48Bb10522c05043e805A947d9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9729612490700923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.97,
  },
  {
    address: '0x3b7AdfD3169286605f514F55c379647279a1E8D0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.97017114738483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.97,
  },
  {
    address: '0x9509B0A81F19B7689121Cb49cEC93F22624bb4A3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9662923464647417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.97,
  },
  {
    address: '0x247b8fDb66396C778a40265F58EbE6a38E6b1da6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.960648427206401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.96,
  },
  {
    address: '0x3537195aA118EC1761E8B14541c31C02B1923b76',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.957505418829584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.96,
  },
  {
    address: '0x673271c41F56926d29f630ACc7aC96e7348638a1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9568905781301658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.96,
  },
  {
    address: '0xb289aC7ece50e24929268C0ba2250A6eB06656ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.952766265979772,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.95,
  },
  {
    address: '0xC0658EBddCfff897B1F95c20a40Fd5328438B65c',
    minters_reward: 0,
    lp_providers_reward: 1.12,
    swapers_reward: 0.8343136591295824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.95,
  },
  {
    address: '0xDd1fe92225251EE789bFb04e2F188d3a03945133',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.938634584684821,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.94,
  },
  {
    address: '0x09d48Ed00dE80110bfECFD1A3dbc28Cc014Ec991',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9243986947720506,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0x7d20D224796f1013253f999B1c530017a3D264B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9241418475540604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0x0406F50f9DFD42965a4E9E38e259d8F7619B80F6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9177611088373006,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0x39A5786A0Ae8EC4028d61910BBd6DfDC0b43D68c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.916333879033492,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0x8324FA247756a9D2Be9D16884f620e65a142E514',
    minters_reward: 0,
    lp_providers_reward: 1.4,
    swapers_reward: 0.5221612714099848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0xCDbac7Fd279777cA1c6bE90F076e03a77f50D055',
    minters_reward: 0,
    lp_providers_reward: 1.91,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.91,
  },
  {
    address: '0x8D455cE2826CC0a1E900fbA8ab98630532463b72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9013142738758058,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.9,
  },
  {
    address: '0xb82886268EfFC1317b17c0326dF13a32a0290681',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8995401600280717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.9,
  },
  {
    address: '0x22Dd1D8167EE13A6fDc1dAD12ac75385930aa5F3',
    minters_reward: 0,
    lp_providers_reward: 1.87,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.87,
  },
  {
    address: '0x33b76244f1885210b91c2c264Ba0f4904c796ECc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8482713750361521,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.85,
  },
  {
    address: '0x7d1e680E42d6AB6010cF0e1bf1B9151cba48010F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8471513325874682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.85,
  },
  {
    address: '0x239A39aAea600Fe65fDB2213B3A97EA9FeCd0C64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0xa61721C51056909dD2A6A7731a22fe6ee7a35494',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0x247C402A8bb8Fddb4139D12cEaC213A6a5E3576B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0x2AE17eDD21A758Eb70608819166d55A9A877f819',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0x6e0e7f4B0158502173a4B78a29e7c7FF1B3Acf5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0x632Ed853B605d5A6ea624c5214721C606F958D3D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838454267637472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0xdc786cDf761B82918994758329853D208ce94Af1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.836686112469912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0xeCdA2216b7219f0b6C112E3c4A2872a62946Cf62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8325795245650975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.83,
  },
  {
    address: '0xDb82E322d9C59Ae8f1DEB018C0b6004Fd4EAcbD1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.812759188087738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.81,
  },
  {
    address: '0xebed1c7e28bB9D9E1024F8aFb6f8cd31615E4EEf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8110930318889042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.81,
  },
  {
    address: '0x5E4d48284f5F0E1b772ef6d638635D438a25f11c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8000273544359204,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.8,
  },
  {
    address: '0x925115baF5050034209Bb52F3df218125565c30E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7994193717475402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.8,
  },
  {
    address: '0x4f26685Fc3e79d8aADB2D7c256499C53110539b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.797007674308746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.8,
  },
  {
    address: '0x7C573B5cfD0149380C65A94437581A6313eDbe1d',
    minters_reward: 0,
    lp_providers_reward: 1.8,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.8,
  },
  {
    address: '0xd8654bfC0f5b9A9f8E5ABafAf276E27E719D3025',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7779749821346018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.78,
  },
  {
    address: '0xDF6563278B279ef095c8e7790A67885B29CBbEA5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.758164711965855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.76,
  },
  {
    address: '0x3c0D1014bC5f692e3ca1C58cF2D7C523405d0cE5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7478941653220346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.75,
  },
  {
    address: '0x18b58e344B65650d48FE9bFFDa0d91089d73fde7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7471170167844077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.75,
  },
  {
    address: '0xa0d254a39Ea8645FFc79A9353c32f02504c5F3e7',
    minters_reward: 0,
    lp_providers_reward: 1.44,
    swapers_reward: 0.3098164380045622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.75,
  },
  {
    address: '0xd61eAe1c9dCb589dF2f5D3ff738f8ABCAC5EE172',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7352208046625572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.74,
  },
  {
    address: '0x03731a2749f238E98bc28A1C32dCBc602F89A2D9',
    minters_reward: 0,
    lp_providers_reward: 1.74,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.74,
  },
  {
    address: '0xf0f21E63404AEb12df5a52862596000Dc195fab8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.712952160282845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.71,
  },
  {
    address: '0xB80aD6f5C7FFeb9b08cA2cad80F14e8a72B412b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.705884575663981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.71,
  },
  {
    address: '0x952f8e096D1252F7E82ADE41c0741454d4a48D2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7030771330763226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.7,
  },
  {
    address: '0xEd63E75F6Dca2F29cCdaD4FF6304E3B722858676',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6991418228071995,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.7,
  },
  {
    address: '0x206710759F9c6ad87490a131Ab31255b38A423EB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6941221863422504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.69,
  },
  {
    address: '0x3496bc59019A8456a27cC4578976a2288eFcAfB6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6900571323340494,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.69,
  },
  {
    address: '0x698090c535255D6E5089D674368e914FF7710333',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6831881155761894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.68,
  },
  {
    address: '0x1aE321f5dFC22b5A331fe574b1aBe77c54Ac58d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6831751179695444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.68,
  },
  {
    address: '0x79f7DffA96C044ed68951AcB25b49EB268DC020d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6823121012237674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.68,
  },
  {
    address: '0x907CD863b20B6c8952A95a51fD50b3c9656d1a9f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6727312479599954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0x7fbdf7f1E9Cf635B34F0Cd97A3347B94bAe658cB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.672630115724073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0x0e034E3144714FDF8d4b12E23bF37372713d0aEE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6698685977430976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0xe902406F9300288c47961a55D8D8EAFC71dd02B3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6685746080025836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0x06B97A4B433f3c0Aa3D3FfBa37F97A295B7c0Be7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6676605148467067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0x123A6FF772C57d4CB736f87a610Bd8D19A1096EB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6640084026888946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.66,
  },
  {
    address: '0x5d02E4B74b62aBAbbB162afcA4593d5273dc127b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6488861325953459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.65,
  },
  {
    address: '0x0f00c2dCff14A6A27e4904D3D30923C52a647346',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6450632424540326,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.65,
  },
  {
    address: '0x965dfc3694c1bAFeC7cEF645D7a17167C9A5f0f4',
    minters_reward: 0,
    lp_providers_reward: 1.65,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.65,
  },
  {
    address: '0xB863F4d8382cFD099d0A226BB08E38F4B697B585',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.644852393827329,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.64,
  },
  {
    address: '0x089FcBdcC5EfE63af7FB7d12E7866398e98b9c91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6436279702159398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.64,
  },
  {
    address: '0xC03f5f2e85a0d0BE778000090E2588fc2B0AcA10',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.640999878273074,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.64,
  },
  {
    address: '0x31f99AC59C835f028B3Cea1C1570d482d3492e0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6247949026732775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.62,
  },
  {
    address: '0xC8a11192B6D35f176E6281496896F3d6A1C4220D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.619948589080894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.62,
  },
  {
    address: '0xc8D4b3465301e6496eb06241F596AD6Bc3F4DbbD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6152587342460807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.62,
  },
  {
    address: '0x222744f1BDAF5d74f4ED01aa5071472Ca03e5063',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6151200540803383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.62,
  },
  {
    address: '0xcc59Bbb69Baf3D292c3ca0E3D1fE2bC8d48eB126',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.598879226223819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.6,
  },
  {
    address: '0xD3512ad4cc8476723f059931Cdf77506e03E66C4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5954103695741064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.6,
  },
  {
    address: '0x96147761d17aC6d9071f4F3f0e38A079F16C1C35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5948329228601528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.59,
  },
  {
    address: '0x25a4117a24C6283998cD34DD8e1d6CF6f47D275d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5945542594251663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.59,
  },
  {
    address: '0xAB1394286D69143b958d361fd60eA763FcbDb7a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5936694103655646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.59,
  },
  {
    address: '0xe654F7a1d28ac0b18fcEC467E8fC8c3872d29c40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5868137680003018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.59,
  },
  {
    address: '0x716823738954b2f9b2f00882C7745E0A1fD3Eeca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.582840922915976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.58,
  },
  {
    address: '0x235eEfF6a8cD136bd13cECa52EA77f8301b8eFB2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.582074162046667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.58,
  },
  {
    address: '0x59BDD0891f43E466FEdAeeb687Be7D2e2107e91c',
    minters_reward: 0,
    lp_providers_reward: 1.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.58,
  },
  {
    address: '0x1883a4BfFEE2f62145649A799035c3BAE48Ac60d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5734845840381435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0xd042DEea0Fc45bF56cE5674e7238aFE2FBE6e392',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.572827813412723,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0xFE47b1DEc8B0c14e3277Cea44d10D7943D54ca55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5714070250504557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0xe49e4f067C47e7289fc9952E6fb965b70153Fd34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5673187354474512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0x158D27315ba32023Ab46819a266d33C6D05c8c02',
    minters_reward: 0,
    lp_providers_reward: 1.57,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0x337C65289019f6b7d7089B2dc65b2Ca152176852',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5620867097814746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.56,
  },
  {
    address: '0x1372d3C0182C3d21Fe23F0A3Ac93B8C6810aaFb8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.56164418977777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.56,
  },
  {
    address: '0x376d70b51A08AF7f46C00Ae6D19F857806847dD0',
    minters_reward: 0,
    lp_providers_reward: 1.56,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.56,
  },
  {
    address: '0x4c09AEe50693b1cD96a0b587898848F2aEe92aB2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5477219919853284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.55,
  },
  {
    address: '0xA037bfbC67B6be40795E6c1B048568c3c16E8B68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5414070651824017,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.54,
  },
  {
    address: '0xec05f03789178f9F4ED824b5AFE0Df4C0243c7f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.53921149945804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.54,
  },
  {
    address: '0x89ad203f0eA569f15a571d8E9e566F33ef03E281',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.53921149945804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.54,
  },
  {
    address: '0x56d4aAB9c09c790A59d423132274D48e5F67eF43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.538457252489344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.54,
  },
  {
    address: '0xd2DFC551F1584a5f6668db1425cD1eB402F0Fcaa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5290870596818376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.53,
  },
  {
    address: '0xa45be7E9C2a6DD64f26A763D90cb3221e3FcFCB6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5240038303824035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.52,
  },
  {
    address: '0x675205FEbc463b307fCb96B3984fd9eCf796e81D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5176249832366138,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.52,
  },
  {
    address: '0x5429b8Ac8908830276dea4A3e2b5B6f42cF267C2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5114789548790668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.51,
  },
  {
    address: '0xfF7bcb959044c3a2D6f40247A52E0619c6E86547',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5089155380530872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.51,
  },
  {
    address: '0xaC6C2D850eE943222D905EE84d6a61A91e3258C6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.504792546170409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0x59E88DAFfCd84f5dC9a277eb8a9F080BB0369d1C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5023976785707334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0x65f50F451f6BD37B44e387Da622676A55a1E4F64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5001504907028782,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0xA95Dc2b73A06c0B395f0eaCd031387D75e4c1c37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4974171827663392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0xfEF544b49deAD669947C1D812E1eE474Da9AA2eB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4974171827663392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0xee8490570C84A242Ba5502044d7fAE9E33239184',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4912471446080697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.49,
  },
  {
    address: '0x393d348c91c7175DAc2228A353BFdB2Dff317651',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4885187128865716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.49,
  },
  {
    address: '0x13eFf7C7837eaC58C9559aE3841f30978921d63c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4877171130280975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.49,
  },
  {
    address: '0x93C3680a31C830318B060a4C22f17f1142cA509e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4877171130280975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.49,
  },
  {
    address: '0x5A2cF86052ACDFE2efcC48dDe69A8864F947113B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.480306688286193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.48,
  },
  {
    address: '0x7Ebc42F23A677535b3484b94D9eeCc2f901bDF8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.480306688286193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.48,
  },
  {
    address: '0xC031C97F116015859041A08bC3F2b8Df11866fAb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.480306688286193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.48,
  },
  {
    address: '0x354a18e6c65Cac6ef404e0090db203585A03907f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4718419658552357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xd415D07ef06a5e9351Fb3BAbaFdF26B816A15B8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4718419658552357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xF598Bd711Aa703F0c33cf969F51aD994a7d3b25D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4718419658552357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0x9477e7F178ED5cA52b15aeE68B9997160D8653d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4666307731836579,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xc596A98D433dBFE59ED57aF3B4ac8dEd717cD2D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4663839379931851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xE61C0BF161D19926f78a8FB3Cf001749630F2a3F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4650785382449958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xE18779b704a017D87FF5C23298ABf1e72b7970F0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4625351337991213,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.46,
  },
  {
    address: '0xDa223771a24C2333f0D8D9e9a5f824F6126064F2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4603046041210752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.46,
  },
  {
    address: '0x9e0fFf41af45Ff20BF4387EB732AE9499675e297',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.457512788100281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.46,
  },
  {
    address: '0xB3a007D1F2f6bf022Cd1c21654137A48FcBe3ccA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4504412935349351,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.45,
  },
  {
    address: '0x43d48D2bc32B1112f81Aa86C374a3583c8490EC7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4406743211190611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.44,
  },
  {
    address: '0xe6f1aA1A66b00580c343dd9F12e0930497f4d36d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.439925251596806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.44,
  },
  {
    address: '0xbF020B7d6a4a83884a0f28b37D7c46e5286A2777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4399127746455171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.44,
  },
  {
    address: '0xa8BAa16C67085Ab5C11cEEb8E45f7a6891A25348',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4399127746455171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.44,
  },
  {
    address: '0xEB2f117c1429aBE69204C71fe6A0fca0A761Fe3A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4349845459575163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.43,
  },
  {
    address: '0xB6950b14d9B5570d60897Fc27EeE3B9D82133463',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.431324875750687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.43,
  },
  {
    address: '0x26A8EfDA8770828756aDfE796a336c577bb11efB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4294649427214436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.43,
  },
  {
    address: '0x207e490da7580921A81Ce0E09667c81D7609a3F6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4269927428844387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.43,
  },
  {
    address: '0x5D75898279fbAb3ec5A4C70D61eCeA0159E654dC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4236347172778554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0xEE134FfCEd866d4B1Faf4C43b3E169483698F5Bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x42df2b643EFc1de84eD50FB9A561B81cA4Aa310d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x640d25fC1dEe7684892A08A6EdD329AdaB4BE63E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0xd1DB7966C5f23226818e63f456ED7718007d01Ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x26813a45e2814a745616131979eF95624F86b2f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4225395224300954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x2a351CABa6236aA691AFeCE294E531FB16fd1eA9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.421833085108413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x69cEc5F47A5302902605352D25b41EEaB9A382E8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4217603681670083,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0xAD76395624e3eECf092582D9c6761634F689C2f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.420175629792558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x2Ff234f543881A30c6e241730690b06Fe210F540',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4159545345349553,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x4EE83dBe51ce11287dAc5A002662A41c08fc1874',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.412373885477967,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0xC56532b5Ce7C8550688100E9ed0767480ea929Ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4123355322591766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0x04785c85FAb3FaCA559F9894Df526d27727C6E38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4100772178897558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0x898A2a1812f47BEd5C04e2CEe2Cd9C091f77653A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4099597362063554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0xA2aE3726432B1a0614C0A59bE8Abfb33392123De',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4081670785083955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0x88870C0d13e52De28Bf8D13E6Eb72f2Af41ed603',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4070401758948254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0xA3FA6B12a021160fAb607424A670dfcE77338C14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4064981562757288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0x19da70be3dE5C015ff30851EcD61CeF2F6B4FEe5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4055732687535378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0xc036Aa17459CD0bE9B341683F4451031b1A913fB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.403918413974421,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x538EBb9C4B05C21998B595644FC5E7DEf8ACcd56',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4008960169170332,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x5D759cBc617284f6392A323c9e90E18f752dEAe3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4003019839525952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x1Bf6261aAd22758C97A6Ee4Ca2AA729068709530',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3990651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0xd3caea3ec9bC6a302A58cb2f2b45eC608a4cb871',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3990651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x84Da66246B2852D1461DE4b476Bd9640e1410CB1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3990651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0xb42136f57E39278b141Cb4Ccceb50C811CFaA785',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3954050399053892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x1a05f6CB8631ecbAb1002f745aCB887a671Aa53B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3945584873154686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0xae608eA5f4D1c70208330c095A754156577aDa97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3939427066333294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0x0e2ab1714008B21407F6fB6937994Ff66158266F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3938307268482775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0xdE139BE569bbC57a93c33b4E4316e6692C761497',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.391938634333583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0x0e5ce321CF57E95d679e95C8dE5778d500C62738',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3902072757161754,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0x60Fb3Debee97b1eB9778f965e2E584ab14FC9f1A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3823430632151805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.38,
  },
  {
    address: '0xf3Ee2EF64Eda8528c5F78B06a7782ff8A5A8F3b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.382318069275695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.38,
  },
  {
    address: '0x0D4FC2DD92e52D972d30e6A096C685C707355741',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.378433152526794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.38,
  },
  {
    address: '0x8306dd6122f66f38044f269Ce6f17b95251d3970',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3715099184951864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0xb67002233F6e55a54Be610186F51Bc92a43dA4Ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3708054117031123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0xE0F0Ae22B923782602d38DeA4Dc52ef349BDe1AB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3708054117031123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0xBbD492Bbca1d769b94C55bBf89f85012444F11AE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3708054117031123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0x0880D4b708c19A18fCb2A876a1c17f31C480c73b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3708054117031123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0x49885Fb63c9E13eE4Ed4320A7A6e5176fc4F5003',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3689785126885037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0xF8aB6386EFD0966038e352987d92ecCEBBaec2a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3681064967759178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0x04B89f4fA25D59Def275A3a3fdD3bD6Cc67b3001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3584370877457652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.36,
  },
  {
    address: '0xf80cB0C24Fc725a04ba92144F71976ac7dE63E14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3554696209364028,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.36,
  },
  {
    address: '0xBC7c5a6d4cA02e4825B6e203742c42f3202C1B32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3498148210815193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.35,
  },
  {
    address: '0xa0d6847185f0232B397d2dB4b9c4f6e2C71774f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.349799471751993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.35,
  },
  {
    address: '0xD5553CF751c5B89E0b9f3b732ad1C19023Ac1F27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3463075016735226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.35,
  },
  {
    address: '0xE272d722739C4AE10D07c86fA8E7F55128F21586',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3447763212449533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0x00B0689DC3aD7dda50C193b84b9a13CD8fFbd078',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3404172064040079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0x0052a9E0e3D59dA9Bcdf00d9892eab102d7bc96b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3356919185095757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0xAF8B4F915857A0f3094FE2518AAa1fF2e83c2A12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3351086001141605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0x25af3f17C93E57Ee864709D1E67897DB11960FAe',
    minters_reward: 0,
    lp_providers_reward: 1.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0x49F5ba00E8605e3065D2cdFF8B72d3C43dd3C702',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3336906159030644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.33,
  },
  {
    address: '0xc301056E55E0C4c980622f042F61777C27e53551',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.331523145387121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.33,
  },
  {
    address: '0xA2f3b873D355D66e8106D74f09653452b828873E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.326012480920357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.33,
  },
  {
    address: '0x43059Bb9360ad4643683FeC463aA67b87898E949',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3246700479084237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0xa78fc7090cB8b685a7cCdAB316C68dfaBBCa6f07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.323346212709324,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0x99083aCaa2891b525a977eeB99A733fb977CDeA7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3191045801168113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0x41fA7a31EDAeD2BD74205cD0fB7fE0C37fB3021D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3182697306599838,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0xA67F4D6DD924Cd89587FC742365970716f9A8f51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3173832924770525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0x6FF7E62B4d1D143aB8dBed4c9Cba19A3401Fa399',
    minters_reward: 0,
    lp_providers_reward: 1.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0xE556184c1EEb240f3d1dD402fa6acb5BEeB31F4D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3122974872551014,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0xc2184D23E130001258528EFf8e8FA42b95eBa096',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3067660468541813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0x401212ab64E8635a27DaCA31386d051Ddd26DfCc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3065754821404196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0xc5baF35C086EDA0c20219869563CE0923b1C7789',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3065037324827427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0x2475c863669Aa4d9F02c149b3d1afD5116cab631',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3065037324827427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0x3B21875D28cD2e138FD94F2273D098646278b52c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.302480879576632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.3,
  },
  {
    address: '0xAE4217e95F3180591469991634efFAE762b2744B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.300003253871786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.3,
  },
  {
    address: '0x67bd3598cf58e2C24A3Fec73Fe4bea00A3076308',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2987607319104986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.3,
  },
  {
    address: '0xd28889fDA1F9a41c3784b6d81207E85497816AfE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2934386951579155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.29,
  },
  {
    address: '0xb2654Eb8AcC50B55B40a52BE138adC453161d345',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2904775733198122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.29,
  },
  {
    address: '0xC85B1d3d5f2D1C81a13ABbFC7e3954677B3ACAd4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2903601182662314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.29,
  },
  {
    address: '0xA205b7294F05218B9938A1b0625efA1C2A6534b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2844571654115546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0x4671071814D21f30119e6c8231aE5c1Bf90dFF17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2827612317027068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0xb41e3F4E6efA59EdF1391Bd52A55DaaF49aCCd19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2790012314888828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0x122fBC35675ba9672e20519868012D9B297aF6Da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2759325926803455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0x51D3552A5457a350A68faF61074678a07D33fb5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2755879384108275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0x23441b6682991389DF1ad7131a1F3159Bf1E889A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.273898664827841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.27,
  },
  {
    address: '0x8AAfD9af16171F606044e296A19ADD5e1cFCD21a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2654743648758606,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.27,
  },
  {
    address: '0x74f67324F90B6624056bcA96A2257A93DAf84f44',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2654743648758606,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.27,
  },
  {
    address: '0xF0FB3e8De996e1fff04Fd4b6Dd5F6C3849f449cA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2593031014366118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.26,
  },
  {
    address: '0xf42AEbc5f556DF67c94F2dbb51e262ed5ddc0A0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2552255780449988,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.26,
  },
  {
    address: '0x83134CEa937085c0d0e98189722Cb410e86f7331',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2420107358576864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.24,
  },
  {
    address: '0xDa80D68Fe0838B2CF2D2BaE7B7D8DE0092120567',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2407261341982854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.24,
  },
  {
    address: '0x6B4Dbc7089306e260492F0d3BF3D4537054bd849',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2394244924565736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.24,
  },
  {
    address: '0xBFbC368477a54b03e3149Fa058407Cc4ffCa7bf8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.224616866541436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.22,
  },
  {
    address: '0xc1E0719Ecc1d1E2adA144Fc7353E2c54C2e24874',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2204660062586936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.22,
  },
  {
    address: '0x37099c20815606278ee22A6614e443e2858D8d34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.216185121474015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.22,
  },
  {
    address: '0x4626839977A800f0dDD48E879784b1Af9088Bf53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2158618555796727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.22,
  },
  {
    address: '0x5F40d0b597CfDE8598fA14d8759b147982d26Cb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2077213712876902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.21,
  },
  {
    address: '0x512aD903BBA750453f70C10eaE0410fe91ff7870',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1991923612017148,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.2,
  },
  {
    address: '0x6010E43418fd693b37f17a9aB240Ce17A95c48D6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1991775071252053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.2,
  },
  {
    address: '0x1cEF38F71bE7a231777EF317Bb9653912a2af502',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.191960376337471,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.19,
  },
  {
    address: '0x4c4d395F1E28BBa94494d7125C6EF1F682BB9a60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1895673886559843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.19,
  },
  {
    address: '0xF2d0F998dE94aEaD8807E6B2D905D852fb4eE9Ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1849499319460557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.18,
  },
  {
    address: '0xcb3c320B6DCf9855461d3E1BA714F4e0c27978Af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1816761843587493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.18,
  },
  {
    address: '0x72df8696Bd7aBf74A9d00f16108c47D27485E6C1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1786494570267856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.18,
  },
  {
    address: '0xC16ABf46FC5a5f91256274327deB37C7D2e812ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1748026226226111,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.17,
  },
  {
    address: '0xc429842839945e766869e1840a0A89f348b3a803',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.157659614880955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.16,
  },
  {
    address: '0x2A5E0eDb864fA0A47177DB57b3e49b220554d5e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.153295332849958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.15,
  },
  {
    address: '0x4EecfB3e814E866933ea34DA7a72039b6699EBc9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1495773688629294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.15,
  },
  {
    address: '0xB0fFB9F67F814d7EC97E7620e9318adCB8bB0089',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1467658023278708,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.15,
  },
  {
    address: '0xf3e3A179FD07488E1883283C091B67E823c42dDa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1444017298409102,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.14,
  },
  {
    address: '0xb31308F1Eb576c93fdF762b248A7b2bb730f6BEF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1435719541771552,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.14,
  },
  {
    address: '0x850904220A4f3d0755231Bc67B7d7C8c5141BC17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1411296126822668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.14,
  },
  {
    address: '0x4eef633f73C860791d0B584Eac6348aE68D584C2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1387251465291153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.14,
  },
  {
    address: '0x59CcD9ECD73cD9752FA8a46336d0908403daB1aA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.134510727916463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.13,
  },
  {
    address: '0x420F2D3Ceea729DF65B9145DdBFa14B50a44Cb05',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1254702966368295,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.13,
  },
  {
    address: '0xDfFF6c89240B1040BAEDD498fB2E9A2c87Af9079',
    minters_reward: 0,
    lp_providers_reward: 1.13,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.13,
  },
  {
    address: '0x85b1345C0dAB5E57fcefda87E71fD8416CF3f7dD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1211016782474874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.12,
  },
  {
    address: '0x583823fCD9091E7FD1781Bd2d9E55E77BF07D625',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1207679420083996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.12,
  },
  {
    address: '0x7e11ff235c442fa6C5d5DF5A78F33eFB1Ce47deD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1192520803742076,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.12,
  },
  {
    address: '0x23bb1E608c36854AD1a15E98a00D85d0C21B37c4',
    minters_reward: 0,
    lp_providers_reward: 0.56,
    swapers_reward: 0.5604090367761942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.12,
  },
  {
    address: '0x67d449889c131b4404803C43054feC26bDE4041b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1116233568226397,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.11,
  },
  {
    address: '0x768c0E6E4785A7dfA00205D0695e9272F0334DDB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1075072076797066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.11,
  },
  {
    address: '0x0D1Fb7595Ed81983a3b84442F8c9e7381C6D3E21',
    minters_reward: 0,
    lp_providers_reward: 0.82,
    swapers_reward: 0.2867699263431286,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.11,
  },
  {
    address: '0xEE5f520342614BACced80b3362B9B34968F5F249',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0974805930799596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.1,
  },
  {
    address: '0xe53653AfCC6D53Dc7A7072A91f288DbeBC9C4829',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0951681699535638,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.1,
  },
  {
    address: '0x1301e1f669aF3996c6047dEd55313B7267De6979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0899290042673673,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.09,
  },
  {
    address: '0xAD78Ab2AE1C462C99b14951C528Af4cABDB283Ef',
    minters_reward: 0,
    lp_providers_reward: 0.41,
    swapers_reward: 0.6803647920676378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.09,
  },
  {
    address: '0xd5B0E0fd71BA9E3983910Eab3bBD1658AfC13D80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.075165845055972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.08,
  },
  {
    address: '0x400d76a687C5BADee24514fdAbC4B8447dF73DF1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.071096385855444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.07,
  },
  {
    address: '0x3cf0FdEC7292E6fe382eb54EAf4003f5A1C398E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.06907438752813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.07,
  },
  {
    address: '0x0E23694bed466a9932306F6eE591e218B6e0c705',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.066786917292106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.07,
  },
  {
    address: '0xB36D7476275d7dD3897B4D875Fbf68B837d3c7EC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0632018885924488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.06,
  },
  {
    address: '0x853ED418904321F57FAF4e246C8b4487076f0a68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0622406400059825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.06,
  },
  {
    address: '0x3217e9eA08331De1313DFfEEA19E8f191F0117B4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0512525947348383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x68396C51e481fc3cb2b0c35AFbf31833C327534A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0488990963282852,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0xf98Cf6f351e102e3Ef7882667f80e10d9a321e29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0475150288018944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0xcfE864Df0123F728F5C76cf4CCaD632a167Bc8b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0471553437699448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x55B020Faa6d2a0c0d78F14cD43a167d5657b5Cb5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0461962510495892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x2115eACF394E1AaE98fDF435316d4eC56679a461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0452603857123357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x02657bF94645aCd075F9d5627Eb262dedfcF52ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0452391530217209,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x1402Fa71fE7D7860E88332a9E3e39498e808Dd2E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.042305604887258,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.04,
  },
  {
    address: '0xf0Bcf8253CcFCf28b1FEf4d25B9F1035b6cd8ed2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0414019791196685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.04,
  },
  {
    address: '0xC4e97E96a20ee852C7a8D2c4558F5CBA253351AA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0359888290223687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.04,
  },
  {
    address: '0x29Bb4E73eF9CBFD72b49A7e2E86FcD2Edd6b36E2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0343932445182027,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.03,
  },
  {
    address: '0x226390516C3A3BA1Dae954459179A9552b823768',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0300522603769557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.03,
  },
  {
    address: '0x275a6CbE4A0D4bC90eF70dc41a4CC21479195C00',
    minters_reward: 0,
    lp_providers_reward: 1.03,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.03,
  },
  {
    address: '0x79D0320c8c56832cFc1Ab632072809f59AC6848c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0218647498918452,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.02,
  },
  {
    address: '0x399768765877b5Af7737C19BCa1a9fE6607f882C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0161470615536343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.02,
  },
  {
    address: '0x1D0F9eBcbE6966b1B4adDA4fA500723fd2086950',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.001811094039872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1,
  },
  {
    address: '0x3b79251f464ba2e9bf61BD6c3f4C401bCefD54BE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9950759419234291,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1,
  },
  {
    address: '0x2bd886071bA2066B58dF1A2E67c7dF85C02AEB33',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.990661772968406,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.99,
  },
  {
    address: '0x56d0021a8cFB00d5453bbE83502Da85F45292Ce9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9865576293854627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.99,
  },
  {
    address: '0xAc6427a820128FAf1577FA2C469d7c5c22adaC5E',
    minters_reward: 0,
    lp_providers_reward: 0.99,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.99,
  },
  {
    address: '0x47f9Bf60467646AAA332F563ee80E4482B61b3Bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9842049061349205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.98,
  },
  {
    address: '0x6e49458F10a364792646DAE916E28e1f9123946d',
    minters_reward: 0,
    lp_providers_reward: 0.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.98,
  },
  {
    address: '0x087bbddc0891Ae05D68E0E11763F978FD5cC1A45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9747898860532103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0xb22988E23Ce8f799040fa57ee560Be64E3056F38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9723178957622685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0xd4A72518F9354553201f29809258c3E1C4ff2e0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9662266945858995,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0x8A243b0EF5815D7B17c9a6D5BF59Bc1ECd8Cf1F7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9659929749183772,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0x7c72647055cFeec74fAb537843C277D954Abc736',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9652679699660757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0xBB55E65aea5cF1b06261b7Fe03FD303F609D1932',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9608394660262011,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.96,
  },
  {
    address: '0xef213299C376C978aDE8DdF201cBD5aC158890b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9586966509709938,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.96,
  },
  {
    address: '0x352AA8DEe04095E58197c0ADDb72B9E6b9357331',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9491057736568954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.95,
  },
  {
    address: '0xEC394d02683638A54B2C79a6563b51db474d3452',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9481117156013776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.95,
  },
  {
    address: '0xbF9AD2f76c93226731563071e29DCa9AB3E91a43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9437644346000442,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.94,
  },
  {
    address: '0xFba769E7Ce383EB6aBE19A8b1A174C5e75d62929',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9416940540269605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.94,
  },
  {
    address: '0x808B87d1c83EF85B9D48AD4649410CBAce74e001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9324050926800423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.93,
  },
  {
    address: '0xf51D38BD1Bc1E49667502304B6528053C7f779d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9310901040698164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.93,
  },
  {
    address: '0x9408D72756337400EfC4604d9051CDF4C09fe919',
    minters_reward: 0,
    lp_providers_reward: 0.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.93,
  },
  {
    address: '0xcEA29e452595581f20C3482e5a0d6C0E062b9549',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9233191863058599,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.92,
  },
  {
    address: '0x083FEf372f5A936e876A62efDccFe295119c71Cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9221683047339365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.92,
  },
  {
    address: '0xCF2ec5777410808AAeA53e847707399535791B08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9079437141041274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.91,
  },
  {
    address: '0x4e915b2992dbEad3deD9D013e6730F2De68Ce13C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9040969062297094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x464aE967BD9bA3134794Bcd7e09AEF22497F1497',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8989540169392574,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x8f89F7e5D3C500b6342Fcc868259036F67114131',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8979328621918948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x92E93B73949626798f029CA96Adfa91994e0091F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8979328621918948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x8b29b5421d8467ae222890c4eB4FB148798e9c9E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8979328621918948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x16a6F45D6ae0a0473daF4E97CAeDaE99AC80DD60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8971633199089182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x676A9B15cEce1b68eFa8fd5114744b1B6A5482f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8915769378553512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.89,
  },
  {
    address: '0x5065Af8c69fd0646F4e72014a4716E8fF66019b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8866698212801676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.89,
  },
  {
    address: '0x893798E182CAa074BA68EfF50eed9cC1f555d9fA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8791961214640245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.88,
  },
  {
    address: '0xA837Acc2c5BB6f2332306f9AA2904fB1D32aC46D',
    minters_reward: 0,
    lp_providers_reward: 0.59,
    swapers_reward: 0.290552875929541,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.88,
  },
  {
    address: '0x20A52D62b9c23eDd48537a8021E5731148ba51cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8717119115352187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.87,
  },
  {
    address: '0x9A41238ceE6738E91CC5aab982A1fb323a034aA7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8456041320000515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.85,
  },
  {
    address: '0x4A7dfa62df6De322342EF64fab73c8604248017A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8363656239799977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.84,
  },
  {
    address: '0x1814F2caa56d9B06D8195134B31Ef16F14B7d267',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8351461703892474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.84,
  },
  {
    address: '0x0FFd30708515bab7f8958728e2443044f6641f86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8299783927827556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.83,
  },
  {
    address: '0x1E37718AbB5eCEF76de0a3855fCe118C0D624bb7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8248961313819215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.82,
  },
  {
    address: '0xf0928914Eb660d8bAa3F11Afc31aB4b95CDf3CE2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8133046579512112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0xe0f379d6C38Ed1369a94411F656294aCd0EBb42d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8123974513366388,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0xb0661450C5316e2626bC594859a1D0d3F55c59aD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8113416669019947,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0xC336223364d5451a043cBc5C4df2Fc2547569a30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8105707599051202,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0xD880DE2B0BC63375FBb1435aDe1631ED9ac919a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8089190599208055,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0x1Bbd4C3163F0c5Fe4EDBB90464991F6e2fa42A0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8028599805057609,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.8,
  },
  {
    address: '0x2859A82Cf81bcF867879dC4235e66BaE1dF350A9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8026577892759819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.8,
  },
  {
    address: '0xB419E204A85170bBf29ad4BA87741Fb4Ec2c2ADC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7964129178488447,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.8,
  },
  {
    address: '0x7245c0222AD5234475B878EFE207efbd511C3eF7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7954744546226934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.8,
  },
  {
    address: '0x0CE643cF19Ada8813728C002d8292F9107Cdd642',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7934286152271487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.79,
  },
  {
    address: '0x0f0113f1aD223EAB63D04377451780839960681D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7890379141299082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.79,
  },
  {
    address: '0x295bC408D472eaf525d9983E70a476d86E47f01E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7864139067063615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.79,
  },
  {
    address: '0x405dfb656bd295d5621dFb39642B1608672ECacb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7852202061431678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.79,
  },
  {
    address: '0x73AC8357ccfA426b664a5b3631272590bdD119d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7822237382635316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.78,
  },
  {
    address: '0x7B3213b0E4203e7b3AF738bD279712F3aBD4C378',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7761397892129579,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.78,
  },
  {
    address: '0xCA36a4bf0a747E67aa783D04BA347fFF3F29a618',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7666684886483313,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.77,
  },
  {
    address: '0xD2f79690b3491F5ec471c7E4638bAF9ab39f71Fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.765953422026998,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.77,
  },
  {
    address: '0x15104a6D34219f4E3aaBF99d9769A50F59096777',
    minters_reward: 0,
    lp_providers_reward: 0.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.76,
  },
  {
    address: '0x60A15B8681bF7D5350888Ef186C7C0fab3186EaA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7524570320375413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.75,
  },
  {
    address: '0x3A464261E228b8c315A1b51187F67F6f0F5eeF75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.74523373214213,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.75,
  },
  {
    address: '0xc0243A104516D3335Bc841cbA5626e82084aCd4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7383211190855534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.74,
  },
  {
    address: '0xaDdAB4FA9d47516368E21a8dbCec92F92Df3c94b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7383088196484694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.74,
  },
  {
    address: '0x6bd37Df5a87D5dB68144F334d968373B671Bfd03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7354493174282765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.74,
  },
  {
    address: '0x5d0433647ec205A28dafB3585D1B7b92a7181878',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7351094750084868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.74,
  },
  {
    address: '0x19C4f70EecbcFDFB8710aFBC47A5Ea49ea77edbF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7347576262642932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0xB32F12461d3fB14454D74a09fA2A0d7C72ea05C0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.729712638898647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0xe14b9e005A39f419b97FF2A6500b7e6feC64f9F8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7289692160921133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0x409C3Eb77dE80DeAbe5ed47FE3e52274F1A36Eec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7265561625162639,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0x24bF722EE63e5BeB891A43be6A84bC96C4a4Ec7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.725937280912311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0xab9E2671817d3B18EB15552D79bc7FcB6883d7eC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7248910248620992,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.72,
  },
  {
    address: '0x24612c8a84fD00170435d6CaEEfddC1B20f7eCed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7199563873227586,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.72,
  },
  {
    address: '0xE06443e1C1dcBFDB31ccc78B8cf09A3c8Efa4dB5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.711703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0x142182fE9E86495aD1a98d784D9926C9afbeB3Ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.711703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0xaae038B51ec180fe5a66070Dd2B84307A3179d16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7099123265850669,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0xc615e9BC5a84B43e4bd962B6714a90a050FcE59d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7061153237772079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0xA2F84449Db5aB644A73b34D7ac2965AE84F1FCD7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7060422640776229,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0x261E249CF9Ec8fEcf911d8B0BF05Fa4678b02B3A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7019175046610705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x55c22777BEc3c1448F84e464064e044296BAFC83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7019175046610705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0xFE29C044B726975e39e5019177197E37e3e0B8f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7001509919762976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x605e6A7541a9034030EdffB6d8677856F8C0E71c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6969713533166647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0xcC88c39b47F6F8fDb90c1870Ef16d7bC1cb7D7b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6969153634241387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x0A5B7A297e9dF63449FAd36DEBEe9894F4A564a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6969153634241387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x27C7e9D908e9c6B6fCC52439D8Da1848a81f6172',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6969153634241387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x99f72aCf2C7701C1d9ca8B024Ca8abbF78e0af45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6959693171667914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x979A32d0040A9115D8ae9C490420d46fd617C0e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6903488780886292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0x644cf587B7C5786F92174bFd77fE45629c51964D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6893570125187921,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0xcfBe78Cc232f2a362Fb606CD8344337230cC8C2b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.687951239185916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0x726B7532886cE9CBEa5560d742C0cdE9FBa17d15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6854027058515562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0x06fe1df20fFF28F3f27f65870E88d4a6b9241F98',
    minters_reward: 0,
    lp_providers_reward: 0.33,
    swapers_reward: 0.36451586364614613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0x65811EaBEf6e6C085Aa8107747e70345e11271B6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6795628950618522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.68,
  },
  {
    address: '0xCCa3d4607c4529fe88A626e4E239EBDfe738c0Eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6767515815238194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.68,
  },
  {
    address: '0x92d6279323309B269cbb5fFB278160fE324c2A4b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6756445930344352,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.68,
  },
  {
    address: '0xfB4fF8210e1eD41B0D8CA503Ccf0be32C4dF42a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6711505127699108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.67,
  },
  {
    address: '0x3Bc866E02D95F78a159EDa956F66Adfb983c7B9B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.66202109655369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.66,
  },
  {
    address: '0x73d52Bf205839bB5B090aD68D8691A2462492bf1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6556518584029943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.66,
  },
  {
    address: '0xa06f384B72AF85E4FA81156d1cC3F66D765B8DAF',
    minters_reward: 0,
    lp_providers_reward: 0.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.66,
  },
  {
    address: '0x855C07750733A9F1679e67cD15b5de98A8c16Dc1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6547908085471131,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x6f74c089156A3097d8Fc2d53FA9E9449494E97BA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6532877410702098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x6E86730013092CE670103Eb0d0Dada20f5187a6F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6532741541980809,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x4dB714b6476FD14037Fcc1b36D154e6CC112011F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6522692194470852,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x70eF4c91Eb4973D84ec91634A073C8865014B157',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6485707442138762,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x7295d27a71b573c3bB49DC8A32547D41Dc49da9B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.646649748399684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x0F2f1481D79dDb533e34336e7633421ee2a742e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6461984332968354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x82229AcB3470A880C0F85C154Cc5B376c93cEc53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6461609758296382,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0xD13974BE49c43e4a022C381502dC89E448B4f3E6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6459963092897884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x9f2c5318be1AE9B75932E7aEB4F4889AAeBe02d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6451222355784184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0xa88B10d59713995138A30C2CeABEB4833Aa4290D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6402917717934481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.64,
  },
  {
    address: '0x915F0944C2B4192689f4d3170AE8003559622eC9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6398361062973346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.64,
  },
  {
    address: '0x997F9d80d45877b2DDe48df6Ab4b0fEBa64CEb06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6394631986830902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.64,
  },
  {
    address: '0xe91b443897Af80d837aA024dA60C45EA24C207cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6394124536312719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.64,
  },
  {
    address: '0x68Fd5933C05D024Fb3629EB598D1441E467296af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6327371824379303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x0C9DB0571f34aa8AaE823494FE44cc45e9b8F09C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6283286189072381,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x7b5D0654feBc5254d531f24Bf5A8fe9C7C2cfcCC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6283286189072381,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x3C68B1CAa38f36F4c59E3150B8D9ABE8d0714E58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6277306622513984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x329395d2d615382c0DC9F2e786B0AF6CE33eC12B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6259364817088539,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x3F7605fe14296b80aa13869811CEF2CA28C1b48a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6206088122785574,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.62,
  },
  {
    address: '0x6e23D48B3e7Bf316A987424E35Cc1e7Db645300E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6175970727722143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.62,
  },
  {
    address: '0xc5F44180cB2c9610A55c53CA45553d80F8E0E66F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6151828836100701,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.62,
  },
  {
    address: '0xCf1330c33962251cA6beE174250DC9B364074bc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6074372782862636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.61,
  },
  {
    address: '0xb0c2b195673AC5153F64d2311CA940794e982523',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6067005794289033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.61,
  },
  {
    address: '0x6472CcdadB8b03aA327db0be16303220B7D6bAEa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6061160597595131,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.61,
  },
  {
    address: '0x47b8745c3aD60Ce753280601695E056F80D0c596',
    minters_reward: 0,
    lp_providers_reward: 0.61,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.61,
  },
  {
    address: '0xb650C524dAc0Cb87BFa647A6e095ABb138BB6225',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5971290927250786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.6,
  },
  {
    address: '0x4A129340B9aF53e75f0EF487Af0931415Bab8892',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5965036497224205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.6,
  },
  {
    address: '0xDA820061E0Ef2E7d50789D155b29E7488883Fe16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5958195202972043,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.6,
  },
  {
    address: '0x64159C89e835AA12055a0FB8D17A59a3863D5C26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5941464641835981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.59,
  },
  {
    address: '0x186Aa96F50709dc69B4244a0faC0e4D19ccf1106',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5938626141030059,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.59,
  },
  {
    address: '0x81f853bD507E7c42C2bbC8E525cfC20CC9D4d272',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5911767063115481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.59,
  },
  {
    address: '0x54cf82Ae1CD00A3654E04753fB369519bD5C6Ef9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5855341035118264,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.59,
  },
  {
    address: '0xd68d68Fb8eD6C05Abbf0b2DC65167bca3248Df6a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5849199421156359,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x17CC4DBfa6B0351B67c8480542D54252d5020175',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5825482358390889,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x07a97A7B96C481B28dCF0b05F68772199021d6ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5816796871108607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x2e6fA4a2399334a50f5e4566E394cC6d5f106C6A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5778502138004539,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0xe485Fa562F4565cE9fdB3244626602D647f795d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5773196084316152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x3d43B8b323eCD4fFd4CF828388929557646C3F32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5759651098582068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x2fC4a0F2b21eC0115997d6b4CA4ef8310Bb77c38',
    minters_reward: 0,
    lp_providers_reward: 0.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x89e1fBbf5e921B9Ace05DC695d8646ed436E7257',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5740145028164279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x352aC32C9663FB7CAAC363F77B121c34D9ACb420',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5722688094866153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x12179BA9a5CF0A6677Dd6eFbf74684E5F3D6448B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5721294353772652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0xfE0373c9Ca9FDc655BfC4203696135F7411c168d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5696461500932091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0xDa5588428adf67Ca906063A2BcEC00F9730c9697',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5695679179871931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x3eF7eD3e71FFcc038d6AC5B7DFfcD25030171405',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5657759490695745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x89fF54bc79e6c67F0e2400923E5237Cf67EEA884',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5657759490695745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x465c7C4ee9717b0bA39Bf3c02c6c85E25814BF71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5657759490695745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0xd552F9Eb2980829Bd162133A92866BF93e19d56b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5657759490695745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0xBaC60Ff0ACd1DFE6800FA1fB6D7EE0f20141E110',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5604276142676505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.56,
  },
  {
    address: '0x48Cb62Ae080DDf2Aa18b2d69e83410dC3225B6eC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5583806696296978,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.56,
  },
  {
    address: '0xc2f2D58047e16F0FA5378d737938ed8419cf852E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5533388588822389,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.55,
  },
  {
    address: '0xcd24Dbd8806A8b001AFD76D085c43aB3Ecf7C161',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5518332211277321,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.55,
  },
  {
    address: '0xf1F34225cC902261a3E1E4A4CB609C6df75D937c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.550577979511679,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.55,
  },
  {
    address: '0x1f9ecf8fD0cC2F896F8699b626c64b87AD76A876',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.546723549086983,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.55,
  },
  {
    address: '0x68fF0bbD6907f5080f78F08326661c469689430d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.534496675362329,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.53,
  },
  {
    address: '0x38236DE9567Bc527b62fDA208E12045C708CeD3E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5326430186956469,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.53,
  },
  {
    address: '0x873F6D265072f06E1f01dbDc6468Ae60A8b5bB66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5313625941407945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.53,
  },
  {
    address: '0x31c9832fc9173CeCB98c8B4Fe0A56715A35fef4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5236086640800013,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.52,
  },
  {
    address: '0xEB03971589CF35c3335714B2162B4e8B56DcFab3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.522508970848828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.52,
  },
  {
    address: '0xD66F0Cd13A8C5bF8450c02076033228Cf56aC570',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5193841799830081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.52,
  },
  {
    address: '0xB539BDcbaBd7ab95FD1Fad26A3AbC7D467D77777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5143687604830032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0x5013342DCDdf22Ba3B19b405E7B6eB634cC8912a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5128393766328115,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0x5fb0C409A66B8009005EC4b701247f50B2974aE7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5116091090897029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0x4E413E66dBFd0bb8e0b9f01F097B188f224317df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5079078858921221,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0x34df404aB837627969866E8725095d5Ab5f03d5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5063010387706698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0xBC5897A090592946290b3aa188DD6C02ABa26e15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4982872573993709,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.5,
  },
  {
    address: '0xedBf490a193Da7316Ad3F0eeb4a562A9e4cDeF1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4981922516064881,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.5,
  },
  {
    address: '0x9ea6D1ac8563b1ec9E61d24874205311F3653E93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.49023703953594544,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.49,
  },
  {
    address: '0x5f317677e67541a1fA63051957b148F7Cc7A9eAa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.48413117114012666,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0x9512423866DC7f81F0C985D444840548c40057e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.48148365519331865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0x9BaB713c6D35106bCf7921349819588CCc67496b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.47828322685383434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0xa4cDdeF11dDF105F40742B742271cE45aE57c6ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4767144880847588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0xb415705864e0038418a5C7ef2bDe45ebA26836fD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4761026745438824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0x4aA9bD1F225feF649480E11a5042D8F7cc7Fbe37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4752816547779274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0x92493977aA22D68fBe5bdfDB410666264d5FB768',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.47245121644487176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.47,
  },
  {
    address: '0xCB86D67FB5c47Ef81fA5e8cd06f1d217B051D91E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4721793277111353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.47,
  },
  {
    address: '0x09c2deE31044A5b77aE83f5d8aEAc228933209ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.46234469366139835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.46,
  },
  {
    address: '0xf8bCaF5170eE05152E090b00A862C6D67150B756',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4615465138020406,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.46,
  },
  {
    address: '0x4910909a13bfe442C37CcAd8E7Cb1B53501e89a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4573758135987572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.46,
  },
  {
    address: '0xcf2C3fb6F2b6f551Be8E64428961a2Be42fe28e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.45475586094157744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0xB89F8f0F2F284857985b73e616c387C7AF26Cf4B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4499490399359116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0x0569b9b07860824f2C5b288e019229A979e1f03d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4499490399359116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0xA4A70543ee7a373D755Faa713062814deaCd6006',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4485824298409909,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0x59FC382c4Dc918c087Ac0C1Ed7a8248108a1D361',
    minters_reward: 0,
    lp_providers_reward: 0.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0x5FDaAA0f811aa637C807c171cc219e832961e2Db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.43935003005804674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.44,
  },
  {
    address: '0xD311b7CdAF16d30716F73037140cdd15892dC86a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.43719770285585247,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.44,
  },
  {
    address: '0x10cC69ce41A73738c98632293af8600565CbC7c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.42595038703468485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.43,
  },
  {
    address: '0x49F59bad07855Ea5F3daA2e498C9D16eafBeC36d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4191013442987569,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.42,
  },
  {
    address: '0x9bef5A5CcBD7437253e1A7CD10fcA7F8f0F7d308',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.41884756943499024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.42,
  },
  {
    address: '0x0195D3231e02BB86416A1170BEb6a0892C7a4b47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.41884756943499024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.42,
  },
  {
    address: '0xf86172FA0ce6f557d42815fC6c624169007b3eB9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.41453742050678727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.41,
  },
  {
    address: '0x506cfCC0415c3E50f09E608BDb2bF9a39b18b00D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4100363453831882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.41,
  },
  {
    address: '0x8E63fDE07A73fF6054162047F4C83443A8190003',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4063335924005904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.41,
  },
  {
    address: '0xBe7E848Db82C1bE21fDD6AaCdFC51c900B2682d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4053894793924173,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.41,
  },
  {
    address: '0xc5C9eE1172731c310d08041Dc004c8909774e0ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.40495818552417573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0x384224ee2762DF35AE3f956CF15ab568dc0498Bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39859145952811675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xA92425b6FD2feE4Ccf92cccAb1773b2805DdE95b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3985642866230956,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xc5d131bD5a5a69221905143907A380Db7189eDb0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39855380128519036,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xE9825fD47c5D863b1AeCbA3707aBcc7c8B49b88d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39824804208515674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0x11d1c5cd049aF2c9a9cC2765dD0695da5b5A504D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39566491106665674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xC92a432e8160fD877F447eB09BfDDDD3b9B0D7cE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39532330807071353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0x53acF1bcE90D3398d33D1aBF24F68731f383c482',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3950952299042505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xd8A3bFCc74131f28A798B0495E9342dC2a00ABDc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39376088528032904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0x41228EC3B11Fa936Ff2BD01EFD12e270eC855dB0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39361791993316136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0xA0A024009Cd1AB4e6712AdF8223504767df73545',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3908924106731479,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0x54033997648c1AF693492D6c08407615A3fBf0B1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.38540079486788276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0xBE0d76Fb8A4D4856c2D829F9eb51f66aDd4abfD5',
    minters_reward: 0,
    lp_providers_reward: 0.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0xA5456c7084Eebd81052E06582B740fC90249C068',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3831959412567922,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0x41e2BE7dC0262C6bd1F8caa9112c3797D0f5295A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.380288153484476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0xa04DA2f915444e9889C089A885232bFDDd513a3A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.37722888451327186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0x4f95e6323780b14FF498012a1c3b2c2D4f281666',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3769714188751424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0x645Ff23E37C18a1D798E8CD70773C62065dBFee1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.37511261829916953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0x10112800546516ED706FB4397fC9d5D5C9A75c9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3720649979161652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.37,
  },
  {
    address: '0x6C2B02890452762137ca5A87B626aa291A3C99c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.36932742896664356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.37,
  },
  {
    address: '0xD6687C8488ECEcf477700Df10Bf6036794aC31C9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3632422036653856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0xA515Eec85c6055CF10692dabfC7c93F0901BA6A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.36142750135439417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0xdef3A7a8914D6101cd3107844d2cEA27B6075593',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3608474829803375,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0xFE4fbc09aE41C13E2F7480EDfC435F9344B9BC72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3608342357440061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0x7def07C34BCA069Bc56B04166bafD811f7beb525',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.359519985358559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0x89346b64995E5F8493431c821e3EDCFf8759D98B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3558516082903485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0x1A4bD9227948D20A841B09291FF1aE1F92FE9568',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3552654483140461,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0x4897d0997a07A4E0bc6C90382Fdbd8465fB9cc09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3539290522500953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.35,
  },
  {
    address: '0xF23Df8d57890F8493D09B21e229344b370548FF6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.35337830361519573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.35,
  },
  {
    address: '0xeBFA00c7424fdEB3635754210aad38C2BA563011',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.33861132700049207,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.34,
  },
  {
    address: '0x3A7DA32453DFd858834Fe9442182169Be6E8bB36',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.33529734899427166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.34,
  },
  {
    address: '0xb6d7a2c9E84C5561880020FDD550e515041539cC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3303431341383073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.33,
  },
  {
    address: '0x01C8108736DDaa1c63123446dFbd0b711301920d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3267747378677335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.33,
  },
  {
    address: '0xC93ea304831aC7b69A9Dc7171A7885e96C7114fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3266438705351049,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.33,
  },
  {
    address: '0xf57E17d80cB7d1C819f4B4F7Cc6bd9B9dC5ad97C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3157055970795622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.32,
  },
  {
    address: '0x03c9A1F504F75F9B57C0764CAE715FA925E348d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.31346374708949026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0x1C7AeC4F1B53D38AeE4dAB7DBF869D6F37C48b39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3105960571713092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0xe8C99C1F4E6f970d720C3A206CDc7063E4A961A0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.30965426867751783,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0x18B0B9Df9a560B1A334Ba522fa584885bf89B85b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.30686432701683586,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0xaE64b448F8a6fB3Ca9585F1e1D67dBf79F58afce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3053349624738114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0xECCe40003f47593c555A281A413DbFBc735312cC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3043370521025481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x0f5174B9dA6E38c5F35DabF6441993d5565DfDcC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.30411847142936316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x6e22Ee937560AfC294567c6710bDe36038d63BDf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3018451086623474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x3C98c2D722783694815376D033A3778c7cCD78BD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2996575261217227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x3A652d1b1CE20aFfCd4ccE0C085bF49CaBF229bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.297612635384278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x7701571427dB2B7875a9CF04DaCc7762c8a38Caa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2960613376572386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x66B6C2fFd174828cDb5658e7C7F40eb4d081eBA7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.29574659048128976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0xE43D8570fec9D05446730cdb627A5Af367725b5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.29540749334282135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x301e1a7dD927D7BE2eC2511E3343f7FbD9D6f818',
    minters_reward: 0,
    lp_providers_reward: 0.3,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x8976f945f656DFd8983A5b88232D762dE5ff8063',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.29164251769612226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0x4792b8FB7F98745379d1093b61Fad6eD6EbC1fE2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2903145408575962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0xb5Fc69dE6DE620d266c7769167488fd3Bdc94187',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28847603952800593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0x90D3Ed16c5AE53f0505dD3e69812F55B2F844dFf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28825366409505604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0x2b3165B489e6f7886ACb3cF52503e919DEF58987',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2858929885442888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0x678334f53A137c87BE4730C201c870e5890f97e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2858929885442888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0xc3A058F00faB7341884D68cf3E51F99dc41d926d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2850507864885912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0xA80235834c107b31736A74B1e59f9c2C86081461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28414453465039585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x1c8B769c568Eb33d8365396925Ef8D2C44d33e7b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28167618872234157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x62Ffeb71757921DB12f88f566c39b9D3A8Db1ba0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28078583824644177,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0xa1CFEa3F2ee8c429b729cb913C0b9918b45D6101',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2798130200935519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0xeA1cE67599Ccc459dA5668d16dB3a8bD0331c5Ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.27940089619917124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x8BB37b3954587fa44C7c9eEb7d3E9a861737F7A3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.27923171295666016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x4Cc483F2d559B5746BC6fFFda8E101e034b53Ff8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2787716859540122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0xbfD96423E8AfD460C107c2742264Af5C755661fb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2787716859540122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x4C43c8b542E9e8e936Fe949A502caBf271C9a3dF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2783877268667166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x8a9564e132bf245a48aa532aDBf53c2251fCe131',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2741610823406225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0xa8cd4d7fe44FA95E918BAAe15FBbb12F83f51444',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2736212993551835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x0881756a3D760e5a390Ce1a13cD8725a4F4984c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.27216244805035933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0xe32Ba38945d8EDf341967dDa072113d13ccB0190',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2708890616003936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x20B25496E1F6b3691547555B7c025ef88988aD45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.27055694125843455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0xbe839D1F7e5131af51B5B3e69C9Da7A92E42427B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.26932585071870024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x4c998b7F09f46A88e6F7443698c9f404b7016751',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.26735738247952473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0xF5e9fb8D7bAD5a8683Eb08A68bB18C12C30d974D',
    minters_reward: 0,
    lp_providers_reward: 0.27,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x431b5F310349Cb9C144D338C04B4F6765Ebf4aA3',
    minters_reward: 0,
    lp_providers_reward: 0.27,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x26dFA0F121206Df63D62b2d202e055f405c189B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2624149339331234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0x545d04A8Bb4eE874F0C2470A13B1720e6854203f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2613150964280839,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0xfF083771c74B91f618d5fbf63BD26908A8a6ad15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.26130966167923236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0x7854bc146A8500F7D64FaBadB0a36F05a585E676',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2579850331105922,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0x35dEa8B3e4A98CaB50e2A9d44De2d70e5Bfdd9AD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.25713233996456897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0x2Bb8cD8F53d911E32004dCE4D6C6270f7976f1E6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2565522463405414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0xf9cBa47ea410983C1Ddb2Ac243b5bF5D02866489',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.24684794534086846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.25,
  },
  {
    address: '0x00cAb0985BB3ceBcfcF6a76f5c2C84A0dC808E43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2440308924595573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0x155ae4c750D59B3e3eEa657036588fd945f3aebC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.23784106707951916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0xB8F6Ea352B61c4353BF28328253bFe8D59833E9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.23677919780967166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0xeB9d0EfB7497FEd8307b14eD920cbfB15f8aBfe1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.23551874336199655,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0x8766e945574C47B0f08Aa4D9b18947B456445e0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.23544706841731738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0x9Cb7AFf24057a8d1f81093C0c99b0977B6654d8d',
    minters_reward: 0,
    lp_providers_reward: 0.17,
    swapers_reward: 0.05840002691788495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.23,
  },
  {
    address: '0x5f47382E3871E0e2ea1eB30F7995dEeF2f8C2016',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.22148611878202343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.22,
  },
  {
    address: '0x7dA8BEA055c4cf1156746894955A2Bd88DFE21eC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.21973774395458842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.22,
  },
  {
    address: '0x90de232CC59c0a79C35C4aE69d24aC28eFB59388',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2196021374487682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.22,
  },
  {
    address: '0x562c26b394B177a46a561fE2386f2586DF652BAf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.21604320177234457,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.22,
  },
  {
    address: '0xCBeE98c902b1B38B83b72FE841E4f96cE9A12C82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.21471517070969137,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.21,
  },
  {
    address: '0x09c05CE5341E1D371D327b16A2884e6D728770f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2114720018854034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.21,
  },
  {
    address: '0xEb0f5476C524A3158B8Fd8225c9C2A93A4b491cE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2110996184777724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.21,
  },
  {
    address: '0x2f621a46c9ec3FA00BD04F14bD1346481BaA5f90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.20560563495125742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.21,
  },
  {
    address: '0x4AEA39DD7Dbc2D93C88d2444696948b603bbD4CA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2029177059438259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0x75f17E59EF0c97fde04d79F48DCfc444f48885a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.20164736701039532,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0xfDc82816923310AB8A07dceAd537F2f90e54923B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.20146537446735738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0x8A02e2cAE331aE4D77b98e7d0319c0E200636d7E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.20064696350439878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0x92fbFe5B110D125f386AA7839537fbc3c791139a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1993802789566788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0x7c213fa21cdadcC7cEe2c6340f580AbBAb2e65b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.19490447788102716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0xf017E38547dfF4C2b299c632f9B011aAB0aD4D91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1885688430060547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0x875Ba9318926E1634c7D86F492FeCa34D0c4A6AF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.18816912511807768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0xbB80072D6B235F43fE23906CEe8951C85545a5e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1876223815204554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0x4d22C28E4385024b1fBd6f8Ed3827f4eACB554b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.18574815035115103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0xb375245be9AD3defFdfC348bA1F5E79Abd9F8Eac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16847468755569633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.17,
  },
  {
    address: '0xA0F416600eEa83C02eCA0300Bb1d5a601CCEE4ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16788781205613112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.17,
  },
  {
    address: '0xe5c884D3AE937d63295C71aFC81AD82278261586',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16783509592801477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.17,
  },
  {
    address: '0x3A26E439F37318086Ea765c3F922a4E5Ca48FB7F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16703263612002994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.17,
  },
  {
    address: '0x943E92fC2d236a0c6521DAa34626FC7b5E781296',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16338310742397763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x6942bF802D9ca7871b0a07350dC132Ef7235C090',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16036102731134655,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x39DCFA69128E98F24Ddc6D578ADA8F95e80a0790',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.15998930520941582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x3Bf0Cb15daEB771dd995c999FD0C81dF515E3D30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1578527985397811,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x2fBA0b80eCA27Df35091A14C7a2762C6fdb87D19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.15766447757678856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x558697eE4cA24c26fBafaA632FbB9D9e80386319',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1541407065182402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x9c8570e2e3006052e109Bd4B7FC2ffC75c1e69Bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.15384664399200318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x46D6a748B634cd19770a620b535c82A67956eE74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.15089155380530875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0xd24F72E3b0f75F95Bd3D030e64e9423DdeAABFA6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.14898898388021653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x8BA7838C11500038c57816eb51858A22FDB1e0b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1480306688286193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x431afe78527498d5C0dF8Ca8BDDb4A6006972525',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1471841965855236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x018266A46B0E1F194ba8B18a8aD47F18dFF85d6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.14471394726034656,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xa83Cd9876d33D4Fdb9156033004eE6CD14aB9344',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1433849631715643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xF4f5682eF17c58c6E7cA949Fc3251990E1c0E72F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1429464942721444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xa6F9e62EfE564101C7887789Be095bBaC1a7304e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1427994726590743,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0x9ffE58075f9F9DAf34b87b36d0984AFb3161d7fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1427539139134439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xf56AeE5F5D349bC799311F70Fdd3Aed93c724924',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.14207226732519793,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xf121149c25C100E838d5DFCc574aDfb91BE6aEB4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.14002879169302726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xD2242e533dA63fA0f5dF92F0c12A30DD59290194',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1393858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xDeB7d63e3B8401A0e6E2542eF59bC811dE1b62BF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.13724161692077338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xEA90C7C536b5667DBe31689de3e5e92D3Ee30Be9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.13544455788869664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0x2A4ED13BEcB406d3916E2E764EfA0bFd1B22676B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1354445308001968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xa32545fb08b09fb43E6F60cbc26397800f33acC2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.13528046458647053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xae3C14C6A00e0a76B9F1794F8bD582A82136108e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.13527847062921727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0x203D5a98351F9E636af48D633ea3bB7BA0b52FA3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.12808652394864228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.13,
  },
  {
    address: '0x30be9Ff3F35dEcB96bb7919988c828e4270427BC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1273898664827841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.13,
  },
  {
    address: '0x90B8B0Ee6e538f845766c4BB48229B224b954D81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.12591585904209837,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.13,
  },
  {
    address: '0xcb54e9e91ab20Dd9D36aAf94cbB09148B518be27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.12547668450432523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.13,
  },
  {
    address: '0x75066852ebA349c1034E86323cF46E3f5e7AbB15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.12406147064977664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x7611321f11b606Df6Cdc2704Be99893aABebf6e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1169985954054735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0xb9221c0d8B1cac58967368c7B798E089aB3D1F90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1168295450913612,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x8a0b4e05B6b8f80408dbba93600240A33404f9a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.11649816000856332,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x32A1be91984Bc63ad293EcD3578C5e5F46DDAeB2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.11527610066635675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x5A3ACa601ccdf8EfB15b870b0816fe025e7D01AC',
    minters_reward: 0,
    lp_providers_reward: 0.08,
    swapers_reward: 0.041815752893101826,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x3Ad11f6749a2279AF68eEA8b597240541c9F60D2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.11007150121388296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.11,
  },
  {
    address: '0xaE75D395F98AFD88Da5f111A6105d680e54E6D41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.10558596379790032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.11,
  },
  {
    address: '0xaB5B717029CB1fEdC64090c3dE95bacaF9c4443c',
    minters_reward: 0,
    lp_providers_reward: 0.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.11,
  },
  {
    address: '0x1c1a2286d6b95987479251074538961F872Cf932',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.10362146818003348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0xF8F739C1F70D777984D556638B49A9C5D42F7eb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.10302890081381735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x68379333d973f0615a0AA58A357467fC74806D8C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.10029272123716214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x39303389965B1A9b3b71D7853268690b2D1a763D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09938772572190936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x9fc6dE54c2dc599B93669c9c140e89f8fB7c918a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09722061411197448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x132C03b5E084B0f3ca737Bb83E2023fC54AA58dC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09719850982130349,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x53A210C404296D397Ca257a0D74d6D0EA9d986a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09689069580138428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0xE125559f75FF5532346e170ac6503Ae8AC028131',
    minters_reward: 0,
    lp_providers_reward: 0.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x50a27a780e8430178434bCad856891286E321683',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09424912886776653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x6a1a1dD4317bE1B1ebA5Da0cCaf4992EF8a18E95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09372227246549181,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x2064FcCd76C54EE65610c5fE6B9E122361578934',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09215004396675958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0xe00C0FB3078575D6ecDD3AB65d6fdC5B96bB1AF9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09125282283966976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x1BA001C94974bD4781B97c668154CfF13F974f94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09072658787465072,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0xd9b33F9a6c2F3E8e1f37c9fC045838EBd0C2Ae97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09070324578591145,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x02f8bedeE709B9a771C7b02ed16318E2043c8dB0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08827951319452129,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x4C9b411F495A6B80EB9BB5a7c7D689A530a4b3A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0881861022771024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0xabe7d7d429FCcBB5A17677bF27f3Ff6F3663b31c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08434856524811866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0xf6c1D2E4bC3Ab508d7bf69Aa43dA78f6941396f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08366804862009226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0x6D232F1f2a22d75963f1E4363dC042f851414997',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08267176681779588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0xd7afA6Ad574a4dB64e57f45429c79eb677e2ab93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08114224716983026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0x6FD67201fBfc568Aa4986d10c006F7459aaae84f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.07913772700745436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0xb03365882B058CCd919a5FdBd3f26b0952bfB52a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.07370160753456502,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0x8cf88578d84dB3210322D551B263dE418659Ca72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.07286861791669759,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0x98358D1d79aDAd834489F2f801bAe66c626C3DD3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0711703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0xf2f1375D2d4CB894e21B7D1CA584062115302833',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0709912326585067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0xDBd78dDd4e122cbBC376f999A3631b0B358590d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.06959693171667915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0x99A562569702E836F69B6Ba5A67df830497d0462',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0677222654000984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0xc276432ffE0a9EE84c3A96Eec36fA8dbD96d0Bdd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.06460702448227183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0x8D96BdD30bBf9894394FAD91e257C4ab1C0F7eE6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0636394449795929,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0x79ae89c2b90CB49b7747Fc28E0B50259b2Bf42EB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.06283286189072383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0xEf94538Da28c8CdF5EF85564BA84b86e66cCcF3D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.061677147731852226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0xA3eE47797bA6C085aD5cB8936526F93fF827fd0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.060164492763015866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0x51EFD291c46c6b3Dc826a9f2C01370B027bF2551',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.05895196357849535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0x326D3cBAc4F5a104A69aeECa7f1314C6e9Fd6d84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.055122309609885775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0xE2D6cec63b5def091B121Cc8389C3e2ce7AdC372',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.054661102553794916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0xcf3dcC9eE6eeF6D92DE12d081540967058976560',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.05421950006847982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0xc6cb9584caAACAf4a9c4243B2A13dae68Ee66ad9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.051822555549103665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0x2d7BDD1b56560908f059beb3e11626D028D4C798',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.05117569288132949,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0x300Ca9B48C3bfcFbC3194860eD1eb31d37c51d0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.05039781480690953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0x575D4CEDb7dF51a7E6673c6a3EC331Ae66C63770',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.04781007985024077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0xc310a82F5d1CC5d6d522A3bA2Ab486596daD706B',
    minters_reward: 0,
    lp_providers_reward: 0.04,
    swapers_reward: 0.013740717771627883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0xD55fdba085461d5B17AD4E2aff74F01Defc06C68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.043577492151246636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x0ae0bcaB1EDd7e07C8b51cB14d4E1347C3bBd4B9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.043416316109512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x4b7C30D50E53069D4F041f8b77ac63a6cc4481de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0427152720167178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x7fd2C4Ed3A34e0d1CC0Ce85d7C4a93Afd3B3e2c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.036396727778402346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x2d9eA8ff0074aB98Aeae88337b160B92DeBAb194',
    minters_reward: 0,
    lp_providers_reward: 0.04,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x9404bE01769159e513A00c6f48f3Df2AeDF417A1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.03124065533271393,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0xE9918B0A83332b8A7bb9218AE88a128F7F145608',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.029672369182670594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0x415D5016ab270007451150c79Aec97631D6ABdC1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.028834025526456822,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0xD62eD6E3eC421ebB3daB26C1baEF553e9d1Df4A4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0285610441514653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0x86AFc841D7531Bf12cDD9db6F31426D3BA100CB1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.027935548702913263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0x555531d40dDBCC6b70BCfE588692b6521969F6F9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.027615040578836687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0x633D2E18b05B16788e6C39951bE2e26531e59b5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.02312110597773603,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x690ADc78Cc701203B880BdB739d3371c554A7899',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.022077629487828536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xCBF486fd541fb7d89CEeD1CCaC13D28a41Bdb9d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.021250066077003218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xB13A156b1CC2cc331d8653fA6C3f5Ee5a2329172',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01966685805615534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x05db4750F72E352464371297E459C43906A5936F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01966685805615534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x72db9e58487F0bc203F924F9Cc5Def0E4fd65C94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.018311471836373952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x1D0230a9d81cB29D3EFA01523698d4833c2701C0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.018040402254640774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xaE724ca1d5C9aBA287cD9586044A0F38D8E562C2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.017349060593430422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xD76D59a84b82ce67923f4C0116A9789ecb31ABA6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.016288651899390133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xD5A42606eF111cD4f33523a6cCAf5345c1409503',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.015978277516183227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xF5D31489e52C49CCd5bCa6cFEb3c9b1b959dcc89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01593398499468368,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xC3EA6D42bA433f49E820876A5B7B3c40D189008f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01577144874324856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x46ae957c6BDC3bb54373dbbeFbDea91E63B697f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.015061001927194914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x01AB2c974acA11c9D313CAB66FdA5696d4f0b784',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.014910140386267779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xDdEE9899a75F1a73137934B1b3eCa3ceE5465D9B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.014707188748438606,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x2b7da83C350853873eBaff565e1116B647fCa342',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.014522981830845655,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xB209F1c4f9b0449B7654dd0B3676f9931ca950a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.014271275096735333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x32BDf00c07D6DeC33A2102BF72d3AC7acA9Bee53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x55c03cfBB33DCdfCBC89DfE5E7F523280789094e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01398625799400123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x4A747Bd3eE3b5061c1702EF06153AbC4533bAE22',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.013379868602424518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x67dA37c824897e19211EC877a6a979f0cCf317a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.013065754821404197,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x763324eAee99404aE4bC0A7e31FDe3eF21317Ec2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01290477573319812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x5f8a0392E616872D20844DB47f124B9039219Ad0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.012566572378144764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x6272355f955d5b520dA7C3C9C589C9628fDa7B81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.011559982664404216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x1Dec1cDf7AfB512D54db88308B46a83771acaEeC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.011198853310369376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xa444F4E3A88a9b8AAC2eE87715894eaBc5a939C7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.010496636501780982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x3122ad3a93e28B74caEE6F081De8CDf06dC1148D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.008046021046777424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x816cDbeCCEc73066d7aFD7F6A25F2f1036503117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.007099123265850668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x49d06b68A6c1c9657C0417749393aD8E2032Dd05',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0069596931716679146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x93268F1684A331b19CdaFB41A6788d559A9D3FC8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0069596931716679146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xCAE07e3055886177c9ba7F2c48655a3BDd140042',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x86534a5Ecc7a00af5518EA316A0afc52a6513875',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x3405Eaa4E24474aA700671C985a2f4C31328a16a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x3b3F93733947f2FA05b41f62b92D2F32c3b2d61A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xF9e2dC91E4C9b6F9E30B67eb4E5619832A2C056F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.00636949332413925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xAd11CfA315CdCd2798c1fe4D3bd6047b470BA583',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.00636949332413925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x28A321c5e5de740aB78170F761F4d35009cbCc96',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006187253909297213,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x5e7ec1dDecdad30D2b8EfF57e97053fB38f1bd4f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0034775773584901854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xC7CC6FF0F20CcA32bA611b745ACf691b64270100',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.00232321085902755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xD9bE99eADe5573DBa6aD9fB84e82834150f6d10c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0014640438472076362,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xe05CFf8ae6769a2be8ff757eE031E46C0162bACf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0013937685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x6092c8d81Ec78142E347C513f3D89905Fc55E695',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0013065754821404197,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xCeEF340392e6313EA66C87686836EA33e7caC02E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0006369493324139203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x41F555A5460a3ca0B48cB2B8a69f0904AF6989b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0002783852862637416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x53F90c7E62A91044d969F7A2DF59F4F4D942B7a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0000544931219445719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x8D62c1Fdd557D7520977A5384a2A22607eC3de3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.355394775341176e-8,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x3d679E0EafD1D75Cf64DcFb517d566Cd5E563556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.664098698043694e-8,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xbCE030c09D73ac91677E5DF4bEc4CF9ea52eD7F1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.03252445063475e-15,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xE8000BA6c83d377a73D7346F2da1bBeFd019cd04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x551Be5A3de85c9e3EEa712E746590fd95238Be3D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x39084E2C8667bF6790632d546e8aB218ad0DaBB9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
]
